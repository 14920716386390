import React from 'react';

const SortAscIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15.75" viewBox="0 0 18 15.75">
      <path
        d="M10.688,14.625H8.438a.562.562,0,0,0-.562.563v1.125a.563.563,0,0,0,.563.563h2.25a.563.563,0,0,0,.563-.562V15.188A.562.562,0,0,0,10.688,14.625Zm-4.5-2.25H4.5V1.688a.562.562,0,0,0-.562-.562H2.813a.563.563,0,0,0-.562.563V12.375H.563a.563.563,0,0,0-.4.96L2.978,16.71a.563.563,0,0,0,.8,0l2.812-3.375A.563.563,0,0,0,6.188,12.375Zm9-6.75H8.438a.563.563,0,0,0-.562.563V7.313a.562.562,0,0,0,.563.563h6.75a.562.562,0,0,0,.563-.562V6.188A.562.562,0,0,0,15.188,5.625Zm-2.25,4.5h-4.5a.562.562,0,0,0-.562.563v1.125a.562.562,0,0,0,.563.563h4.5a.562.562,0,0,0,.563-.562V10.688A.562.562,0,0,0,12.938,10.125Zm4.5-9h-9a.562.562,0,0,0-.562.563V2.813a.562.562,0,0,0,.563.563h9A.563.563,0,0,0,18,2.813V1.688A.563.563,0,0,0,17.438,1.125Z"
        transform="translate(0 -1.125)"
      />
    </svg>
  );
};

export default SortAscIcon;
