import React from 'react';
import classNames from 'classnames';
import Dropdown, { IDropdownOption } from '../../Dropdown/Dropdown';
import styles from './NormSeason.module.scss';

/**
 * NormSeason is a base type representing a norm season.
 */
export type NormSeasonType = 'Fall' | 'Midyear' | 'Spring';

interface NormSeasonProps {
  className?: string;
  disabled?: boolean;
  onChange?: (season: NormSeasonType) => void;
  options?: NormSeasonType[];
  midpointDate?: Date;
  season?: NormSeasonType;
  variant?: 'text' | 'dropdown';
}

/**
 * getNormSeason returns a norm season corresponding to the passed midpoint date
 * @param {Date} midpointDate Midpoint date to calculate the norm season for
 * @return {NormSeason} Norm season corresponding to the passed midpoint date
 */
export const getNormSeason = (midpointDate: Date): NormSeasonType => {
  const month = midpointDate.getMonth();

  if ([7, 8, 9, 10].includes(month)) {
    return 'Fall';
  } else if ([11, 0, 1].includes(month)) {
    return 'Midyear';
  }

  // month 2, 3, 4, 5, 6
  return 'Spring';
};

const defaultSeasons: NormSeasonType[] = ['Fall', 'Midyear', 'Spring'];

const NormSeason = ({
  className,
  disabled = false,
  onChange,
  options,
  midpointDate,
  season,
  variant = 'text',
}: NormSeasonProps) => {
  if (variant === 'dropdown') {
    return (
      <div className={classNames(styles['norm-season'], className)}>
        <Dropdown
          isDisabled={disabled}
          cssClass={styles['norm-season-dropdown']}
          onChange={(options: IDropdownOption[]) => onChange?.(options[0].value)}
          options={
            options?.map((seasonOption) => ({
              label: `Season: ${seasonOption}`,
              value: seasonOption,
              selected: season === seasonOption,
            })) ||
            defaultSeasons?.map((seasonOption) => ({
              label: `Season: ${seasonOption}`,
              value: seasonOption,
              selected: season === seasonOption,
            }))
          }
          placeholder="Select a norm season"
        />
      </div>
    );
  }

  return (
    <div className={classNames(styles['norm-season'], className)}>
      <div className={classNames(styles['norm-season-text'], className)}>
        Season: {season || (midpointDate && getNormSeason(midpointDate)) || '-'}
      </div>
    </div>
  );
};

export default NormSeason;
