import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import LogHelper, { AdditionalLogData } from '../lib/helper/LogHelper';

export interface AppLoggerInterface {
  debug: (message: unknown, additionalData?: AdditionalLogData) => void;
  info: (message: unknown, additionalData?: AdditionalLogData) => void;
  warning: (message: unknown, additionalData?: AdditionalLogData) => void;
  warn: (message: unknown, additionalData?: AdditionalLogData) => void;
  error: (message: unknown, additionalData?: AdditionalLogData) => void;
  log: (message: unknown, additionalData?: AdditionalLogData) => void;
  forceLog: (message: unknown, additionalData?: AdditionalLogData) => void;
}

/**
 * Returns app logger
 *
 * @param  {string} namespace     Namespace for logger
 *
 * @returns {AppLoggerInterface} App logger interface
 */
const useAppLogger = (namespace = ''): AppLoggerInterface => {
  const config = useConfigContext();
  const loggingConfig = config.loggingOptions;

  const logInterface: AppLoggerInterface = {
    forceLog: (message: unknown, additionalData?: AdditionalLogData): void => {
      LogHelper.forceLoggerEntry(message, namespace, additionalData);
    },
    log: (message: unknown, additionalData?: AdditionalLogData): void => {
      if (loggingConfig.enabled) {
        LogHelper.addLoggerEntry(config, message, 'info', namespace, additionalData);
      }
    },

    debug: (message: unknown, additionalData?: AdditionalLogData): void => {
      if (loggingConfig.enabled && loggingConfig.logLevels.debug) {
        LogHelper.addLoggerEntry(config, message, 'debug', namespace, additionalData);
      }
    },

    info: (message: unknown, additionalData?: AdditionalLogData): void => {
      if (loggingConfig.enabled && loggingConfig.logLevels.info) {
        LogHelper.addLoggerEntry(config, message, 'info', namespace, additionalData);
      }
    },

    warning: (message: unknown, additionalData?: AdditionalLogData): void => {
      if (loggingConfig.enabled && loggingConfig.logLevels.warning) {
        LogHelper.addLoggerEntry(config, message, 'warning', namespace, additionalData);
      }
    },

    warn: (message: unknown, additionalData?: AdditionalLogData): void => {
      if (loggingConfig.enabled && loggingConfig.logLevels.warning) {
        LogHelper.addLoggerEntry(config, message, 'warning', namespace, additionalData);
      }
    },

    error: (message: unknown, additionalData?: AdditionalLogData): void => {
      if (loggingConfig.enabled && loggingConfig.logLevels.error) {
        LogHelper.addLoggerEntry(config, message, 'error', namespace, additionalData);
      }
    },
  };
  return logInterface;
};

export default useAppLogger;
