import styles from './AlphabetSelectBar.module.scss';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { alphabet } from './util';

export interface AlphabetSelectBarProps {
  className?: string;
  onChange?: (letter: string) => void;
  disabled?: boolean;
  disabledLetters?: string[];
}

const AlphabetSelectBar = ({
  className,
  onChange,
  disabled = false,
  disabledLetters = [],
}: AlphabetSelectBarProps) => {
  const [focusedLetter, setFocusedLetter] = useState<string>('');

  useEffect(() => {
    onChange?.(focusedLetter);
  }, [focusedLetter]);

  return (
    <div className={classNames(styles['alphabet-scrollbar'], className)}>
      {alphabet.map((letter) => (
        <button
          className={classNames(styles['letter-button'], {
            [styles['focused']]: focusedLetter === letter,
          })}
          disabled={disabled || disabledLetters.includes(letter)}
          key={letter}
          onClick={() => setFocusedLetter(focusedLetter === letter ? '' : letter)}
        >
          {letter}
        </button>
      ))}
    </div>
  );
};

export default AlphabetSelectBar;
