/*
  Elevate staff user navigation structure

  Documentation for menu entry fields can be found on
  https://gitlab.com/RiversideInsights/platforms/elevate/ui/elevate-react-lib/-/blob/main/src/components/AppMenu/AppMenu.md#appmenuentry-data-type 
*/

import React from 'react';
import { AppMenuEntry } from '@riversideinsights/elevate-react-lib';

import {
  IconLogout,
  IconHome,
  IconAssignments,
  IconProctoring,
  IconReporting,
  IconAdmin,
  IconHelp,
} from '@riversideinsights/elevate-react-lib';

export interface MenuOptions {
  hideCreateTestAssignmentNavigation: boolean;
  hideProctoringNavigation: boolean;
  hideRosteringNavigation: boolean;
}

export const getElevateMenu = ({
  hideCreateTestAssignmentNavigation,
  hideProctoringNavigation,
  hideRosteringNavigation,
}: MenuOptions): AppMenuEntry[] => [
  {
    path: '/administration/user/dashboard/assignments',
    id: 'id_dashboard',
    text: 'Dashboard',
    tooltip: 'Dashboard',
    hidden: false,
    disabled: false,
    className: 'nav-item',
    requiredPermissions: [],

    roles: ['teacher', 'staff', 'district_admin'],
    icon: <IconHome />,
  },
  {
    id: 'id_assignments',
    text: 'Test Assignments',
    tooltip: 'Test Assignments',
    hidden: false,
    disabled: false,
    className: 'nav-item',
    requiredPermissions: [],
    roles: ['teacher', 'staff', 'district_admin'],
    icon: <IconAssignments />,
    children: [
      {
        path: '/assignment/list',
        id: 'id_assignment_list',
        text: 'Test Assignments',
        hidden: false,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['teacher', 'staff', 'district_admin'],
      },
      {
        path: '/assignment/create',
        id: 'id_assignment_create',
        text: 'Create a New Test Assignment',
        hidden: hideCreateTestAssignmentNavigation,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['district_admin'],
      },
    ],
  },
  {
    id: 'id_proctoring',
    text: 'Proctoring',
    tooltip: 'Proctoring',
    hidden: hideProctoringNavigation,
    disabled: false,
    className: 'nav-item',
    requiredPermissions: [],
    roles: ['teacher', 'staff', 'district_admin'],
    icon: <IconProctoring />,
    children: [
      {
        path: '/proctoring/dashboard',
        id: 'id_proctoring_dashboard',
        text: 'Not Started',
        hidden: false,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['teacher', 'staff', 'district_admin'],
      },
      {
        path: '/proctoring/live-tests',
        id: 'id_proctoring_live',
        text: 'In Progress',
        hidden: false,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['teacher', 'staff', 'district_admin'],
      },
      {
        path: '/proctoring/completed',
        id: 'id_proctoring_completed',
        text: 'Completed',
        hidden: false,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['teacher', 'staff', 'district_admin'],
      },
    ],
  },
  {
    id: 'id_reports',
    text: 'Reporting',
    tooltip: 'Reporting',
    hidden: false,
    disabled: false,
    className: 'nav-item',
    requiredPermissions: [],
    roles: ['teacher', 'staff', 'district_admin'],
    icon: <IconReporting />,
    children: [
      {
        path: '/reporting/cogat-dashboard',
        id: 'id_cogat_frame',
        text: 'CogAT Dashboard',
        hidden: false,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['teacher', 'staff', 'district_admin'],
      },
      {
        path: '/reporting/testTaker',
        id: 'id_test_taker',
        text: 'Test Taker Status',
        hidden: false,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['teacher', 'staff', 'district_admin'],
      },
    ],
  },
  {
    id: 'id_admin',
    text: 'Administration',
    tooltip: 'Administration',
    hidden: false,
    disabled: false,
    className: 'nav-item',
    requiredPermissions: [],
    roles: ['teacher', 'staff', 'district_admin'],
    icon: <IconAdmin />,
    children: [
      {
        path: '/administration/clever',
        id: 'id_clever',
        text: 'Clever',
        hidden: false,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['staff', 'district_admin'],
      },
      {
        path: '/administration/rostering',
        id: 'id_rostering',
        text: 'Rostering',
        hidden: hideRosteringNavigation,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['teacher', 'staff', 'district_admin'],
      },
      {
        path: '/administration/user/orders',
        id: 'id_orders',
        text: 'Order History',
        hidden: false,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['district_admin'],
      },
      {
        path: '/administration/user/preferences',
        id: 'id_preferences',
        text: 'Settings And Preferences',
        hidden: false,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['teacher', 'staff', 'district_admin'],
      },
      {
        path: '/administration/user/customize-roles',
        id: 'id_customize_roles',
        text: 'Customize Roles & Rights',
        hidden: false,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['staff', 'district_admin'],
      },
      {
        path: '/administration/user/user-search/student',
        id: 'id_user_search',
        text: 'User Search',
        hidden: false,
        disabled: false,
        liClassName: 'sub-menu-item',
        className: 'sub-nav-item',
        requiredPermissions: [],
        roles: ['teacher', 'staff', 'district_admin'],
      },
    ],
  },
  {
    path: 'http://onlinehelp.riversideinsights.com/Help/Elevate/index.htm',
    id: 'id_help',
    text: 'Help',
    tooltip: 'Help',
    hidden: false,
    disabled: false,
    className: 'nav-item',
    requiredPermissions: [],
    linkAttributes: {
      ['target' as keyof React.HTMLAttributes<HTMLAnchorElement>]: '_blank',
      ['rel' as keyof React.HTMLAttributes<HTMLAnchorElement>]: 'noreferrer',
    },
    roles: ['teacher', 'staff', 'district_admin'],
    icon: <IconHelp />,
  },
  {
    path: '/administration/auth/logout',
    id: 'id_logout',
    text: 'Logout',
    tooltip: 'Logout',
    hidden: false,
    disabled: false,
    className: 'nav-item',
    requiredPermissions: [],
    roles: ['teacher', 'staff', 'district_admin'],
    icon: <IconLogout />,
  },
];
