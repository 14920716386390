import React, { useEffect, useMemo, useRef, useState } from 'react';

// import LinearProgress from '@mui/material/LinearProgress';

// import ErrorIcon from '@mui/icons-material/Error';
import useCachedUser from '../../../../../hooks/useCachedUser';
import { useDispatch } from 'react-redux';
import { ModalDialog } from '@riversideinsights/elevate-react-lib';
import useNotificationHelper from '../../../../../hooks/useNotificationHelper';
import { StaffAuthUser, StaffAuthUserUpdate } from '../../../../../reducers/Auth/authDataTypes';
import UserHelper from '../../../../../lib/helper/UserHelper';
import useAppLogger from '../../../../../hooks/useAppLogger';
import { TbCopy, TbDeviceFloppy, TbX } from 'react-icons/tb';
import { Button } from '@mui/material';
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

export interface AppDebugUserModalProps {
  modalVisible: boolean;
  hideModal?: () => void;
}

const AppDebugUserModal: React.FC<AppDebugUserModalProps> = (props) => {
  const config = useConfigContext();
  const user = useCachedUser();
  const logger = useAppLogger('AppDebugUserModal');
  const dispatch = useDispatch();
  const NotificationHelper = useNotificationHelper();
  const userDataFieldRef = useRef<HTMLTextAreaElement | null>(null);

  const userDataJson = useMemo<string>(() => {
    let userString = '';
    const userCopy: Partial<StaffAuthUser> = UserHelper.cloneStaffUser(user);
    const copyIdentity = userCopy.identity;
    const copyRoles = userCopy.roles;
    const copyCurrentRole = userCopy.currentRole;
    const copyCurrentSchools = userCopy.currentSchools;
    const copyCurrentPermissions = userCopy.currentPermissions;
    delete userCopy.identity;
    delete userCopy.roles;
    delete userCopy.currentRole;
    delete userCopy.currentSchools;
    delete userCopy.currentPermissions;
    userCopy.currentPermissions = copyCurrentPermissions;
    userCopy.currentSchools = copyCurrentSchools;
    userCopy.currentRole = copyCurrentRole;
    userCopy.identity = copyIdentity;
    userCopy.roles = copyRoles;

    // const userCopy = {
    //   ...user,
    //   identity: {
    //     ...user.identity,
    //     // idToken: '',
    //   },
    //   roles: {
    //     ...user.roles,
    //   },
    //   // sections: user.sections,
    // };

    try {
      userString = JSON.stringify(userCopy, null, 4);
    } catch (ex: unknown) {
      const err = ex as Error;
      logger.error(`${err.message}`);
    }
    return userString;
  }, [user]);

  const [newUserJson, setNewUserJson] = useState('');

  const hideModal = () => {
    if (props?.hideModal) {
      props.hideModal();
    }
  };

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const el = event.target;
    if (el) {
      setNewUserJson(el.value);
    }
  };
  const isChanged = useMemo<boolean>(() => {
    if (newUserJson !== userDataJson) {
      return true;
    }
    return false;
  }, [userDataJson, newUserJson]);

  const modalFooter = useMemo<React.ReactNode>(() => {
    return (
      <div className="app-debug-modal-footer">
        <Button
          variant="outlined"
          color="primary"
          endIcon={<TbCopy />}
          onClick={copyUserToClipboard}
        >
          Copy
        </Button>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<TbDeviceFloppy />}
          onClick={overrideUser}
        >
          Update
        </Button>
        <Button variant="contained" color="primary" endIcon={<TbX />} onClick={hideModal}>
          Close
        </Button>
      </div>
    );
  }, [isChanged]);

  const overrideUser = () => {
    if (userDataFieldRef.current !== null) {
      const newUserData: StaffAuthUserUpdate = JSON.parse(
        userDataFieldRef.current.value,
      ) as StaffAuthUserUpdate;
      if (newUserData?.currentSections) {
        delete newUserData?.currentSections;
      }
      if (newUserData?.currentPermissions) {
        delete newUserData?.currentPermissions;
      }
      if (newUserData?.roles) {
        delete newUserData?.roles;
      }
      if (newUserData?.identity) {
        delete newUserData?.identity;
      }
      // newUserData.loadComplete = false;
      UserHelper.updateUser(config, newUserData, dispatch);
      NotificationHelper.add('User data updated', 'info', 0);
      // UserHelper.storeLocalUser(user);
    }
  };

  const copyUserToClipboard = () => {
    navigator.clipboard.writeText(userDataJson).then(
      () => {
        // setUserCopied(true);
        NotificationHelper.add('User data copied to clipboard as JSON', 'info');
        if (props?.hideModal) {
          setTimeout(() => {
            hideModal();
          }, 1000);
        }
      },
      function () {
        NotificationHelper.add('Failed copying user data to clipboard', 'error');
        // setUserCopied(false);
      },
    );
  };
  useEffect(() => {
    if (props.modalVisible) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="app-debug-test-modal app-debug-user-details-modal"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            title="User Details"
            footer={modalFooter}
            body={
              <div className="app-debug-user-modal-contents">
                {userDataJson !== '' && (
                  <textarea
                    ref={userDataFieldRef}
                    className="app-debug-user-data-field"
                    defaultValue={userDataJson}
                    onChange={handleTextareaChange}
                  ></textarea>
                )}
              </div>
            }
            onClose={() => {
              hideModal();
            }}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [props.modalVisible]);
  return <></>;
};

export default AppDebugUserModal;
