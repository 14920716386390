import React, { Suspense, lazy, useEffect } from 'react';

import {
  useLocation,
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  NavLink,
} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { AppLoader } from '@riversideinsights/elevate-react-lib';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import AppHelper from '../../../lib/helper/AppHelper';

// Load hooks
import usePageTitle from '../../../hooks/usePageTitle';
import useAppLogger from '../../../hooks/useAppLogger';
import useStaffUser from '../../../hooks/useStaffUser';
import useAppStatus from '../../../hooks/useAppStatus';

// Load layout elements
import AppNotificationList from '../AppNotification/AppNotificationList';
import AppHeader from '../AppHeader/AppHeader';
import AppDebug from '../AppDebug/AppDebug';
import AppModals from '../AppModals/AppModals';
import ErrorPage from '../../../pages/ErrorPage';
import { Tooltip } from '@mui/material';

import './AppSectionProctoring.scss';
import '../../Proctoring/global-styles.scss';

import ProctorHeaderToolbar from '../../Proctoring/ProctorHeaderToolbar';
import { ReactComponent as StatusNotStarted } from '../../../assets/icons/proctoring/status-not-started.svg';
import { ReactComponent as StatusInProgress } from '../../../assets/icons/proctoring/status-in-progress.svg';
import { ReactComponent as StatusCompleted } from '../../../assets/icons/proctoring/status-completed.svg';
import useWrapperClasses from '../../../hooks/useWrapperClasses';
import SectionLoader from '../AppSection/SectionLoader';
import SectionError from '../AppSection/SectionError';
import { AppState } from '../../../reducers/appDataTypes';
import { BooleanFlags, useBooleanFlagWithConfig } from '../../../flags';

// Load section pages
const ProctorPage = lazy(() => import('../../../pages/proctoring/ProctorPage'));

const AppSectionProctoring: React.FunctionComponent = () => {
  const config = useConfigContext();
  const headerContent = useSelector((state: AppState) => state.proctor.headerContent);
  const students = useSelector((state: AppState) => state.proctor.students);

  // Note that this should be the ONLY call to useStaffUser and it should be invoked only once per (async) page load.
  // Page components should use useCachedUser instead.
  const user = useStaffUser();
  const dispatch = useDispatch();

  const logger = useAppLogger('COMPONENT|AppSectionProctoring');
  const debugAllowed = AppHelper.getCookieValue('elevateDebugEnabled') ? true : config.local;

  const location = useLocation();
  const appStatus = useAppStatus();
  const wrapperClasses = useWrapperClasses(
    'proctoring-only-bootstrap-reboot proctoring-only-global-styles',
  );
  const { isEnabled: testingIsDisabled } = useBooleanFlagWithConfig(BooleanFlags.DisableTesting);

  usePageTitle(
    'Proctoring - ' + (headerContent.activeTabTitle ? headerContent.activeTabTitle : 'Not Started'),
  );

  useEffect(() => {
    logger.debug('Mounting Proctoring Section');
    showHeaderToolbar();
    return () => {
      logger.debug('Unmounting Proctoring Section');
      hideHeaderToolbar();
    };
  }, []);
  useEffect(() => {
    if (location.pathname === '/proctoring/dashboard') {
      if (headerContent.activeTab !== 'dashboard') {
        dispatch({ type: 'CHANGE_ACTIVE_TAB', payload: 'dashboard' });
      }
    } else if (location.pathname === '/proctoring/live-tests') {
      if (headerContent.activeTab !== 'live-tests') {
        dispatch({ type: 'CHANGE_ACTIVE_TAB', payload: 'live-tests' });
      }
    } else if (location.pathname === '/proctoring/completed') {
      if (headerContent.activeTab !== 'completed') {
        dispatch({ type: 'CHANGE_ACTIVE_TAB', payload: 'completed' });
      }
    }
  }, [location.pathname]);

  const additionalNavigation = (
    <div className="header-nav-icon-links proctoring">
      <Tooltip
        title={<div className="app-menu-tooltip-contents">Not Started</div>}
        placement="bottom"
        arrow={true}
        classes={{
          popper: 'elevate-tooltip-wrapper',
        }}
      >
        <NavLink
          to="/proctoring/dashboard"
          className="header-nav-icon-link main-header-button"
          activeClassName="active-header-nav-icon-link"
          onClick={() => {
            dispatch({ type: 'CHANGE_ACTIVE_TAB', payload: 'dashboard' });
          }}
        >
          <StatusNotStarted />
          {students.studentsWaitingApproval.totalCount > 0 && (
            <span className="bubble-number">
              <b>{students.studentsWaitingApproval.totalCount}</b>
            </span>
          )}
        </NavLink>
      </Tooltip>
      <Tooltip
        title={<div className="app-menu-tooltip-contents">In Progress</div>}
        placement="bottom"
        arrow={true}
        classes={{
          popper: 'elevate-tooltip-wrapper',
        }}
      >
        <NavLink
          to="/proctoring/live-tests"
          className="header-nav-icon-link main-header-button"
          activeClassName="active-header-nav-icon-link"
          onClick={() => {
            dispatch({ type: 'CHANGE_ACTIVE_TAB', payload: 'live-tests' });
          }}
        >
          <StatusInProgress />
          {students.studentsInProgress.totalCount > 0 && (
            <span className="bubble-number">
              <b>{students.studentsInProgress.totalCount}</b>
            </span>
          )}
        </NavLink>
      </Tooltip>
      <Tooltip
        title={<div className="app-menu-tooltip-contents">Completed</div>}
        placement="bottom"
        arrow={true}
        classes={{
          popper: 'elevate-tooltip-wrapper',
        }}
      >
        <NavLink
          to="/proctoring/completed"
          className="header-nav-icon-link main-header-button"
          activeClassName="active-header-nav-icon-link"
          onClick={() => {
            dispatch({ type: 'CHANGE_ACTIVE_TAB', payload: 'completed' });
          }}
        >
          <StatusCompleted />
          {students.studentsCompleted.totalCount > 0 && (
            <span className="bubble-number">
              <b>{students.studentsCompleted.totalCount}</b>
            </span>
          )}
        </NavLink>
      </Tooltip>
    </div>
  );

  const showHeaderToolbar = () => {
    dispatch({
      type: 'SET_HEADER_TOOLBAR',
      payload: <ProctorHeaderToolbar />,
    });
    dispatch({ type: 'SET_HEADER_TOOLBAR_VISIBLE', payload: true });
  };

  const hideHeaderToolbar = () => {
    dispatch({
      type: 'SET_HEADER_TOOLBAR',
      payload: null,
    });
    dispatch({ type: 'SET_HEADER_TOOLBAR_VISIBLE', payload: false });
  };

  return (
    <div className={wrapperClasses}>
      <AppModals />
      <div className={`app-layout-root ${appStatus.pageClassName}`}>
        <AppHeader prependContent={additionalNavigation} />
        <Suspense fallback={<AppLoader title="Please Wait" message="Initializing" />}>
          <Switch>
            {/* Redirect to default section page */}
            <Redirect exact from="/proctoring" to="/proctoring/dashboard" push={false} />

            {/* Routes that call API or rely on user data upon render
             * do need user already loaded, so we don't render them
             * until that happens/
             */}
            <Route
              path="/proctoring/dashboard/"
              render={(routeProps: RouteComponentProps) => {
                if (!(user.loggedIn && user.loadComplete)) {
                  return null;
                } else {
                  return testingIsDisabled ? (
                    <Redirect to="/administration/user/dashboard/assignments" />
                  ) : (
                    <ProctorPage {...routeProps} />
                  );
                }
              }}
            />

            <Route
              path="/proctoring/live-tests/"
              render={(routeProps: RouteComponentProps) => {
                if (!(user.loggedIn && user.loadComplete)) {
                  return null;
                } else {
                  return testingIsDisabled ? (
                    <Redirect to="/administration/user/dashboard/assignments" />
                  ) : (
                    <ProctorPage {...routeProps} />
                  );
                }
              }}
            />

            <Route
              path="/proctoring/completed/"
              render={(routeProps: RouteComponentProps) => {
                if (!(user.loggedIn && user.loadComplete)) {
                  return null;
                } else {
                  return testingIsDisabled ? (
                    <Redirect to="/administration/user/dashboard/assignments" />
                  ) : (
                    <ProctorPage {...routeProps} />
                  );
                }
              }}
            />

            <Route render={() => <ErrorPage httpErrorCode={404} />} />
          </Switch>
        </Suspense>

        {(appStatus.appError || appStatus.appErrorFatal) && <SectionError />}
        {!(appStatus.appError || appStatus.appErrorFatal) &&
          (appStatus.loadingUser || appStatus.appBusy) && <SectionLoader />}
      </div>
      <AppNotificationList />

      {debugAllowed && <AppDebug />}
    </div>
  );
};

export default AppSectionProctoring;
