import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import AppDebugHeaderToolbar from './AppDebugHeaderToolbar';

import UserShieldIcon from '../icons/UserShieldIcon';

import { AppNotification, AppState } from '../../../reducers/appDataTypes';
import useNotificationHelper from '../../../hooks/useNotificationHelper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { FaTrashAlt } from 'react-icons/fa';
import AppDebugNotificationItem from './AppDebugNotificationItem';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

const AppUIDebugSection: React.FC = () => {
  const dispatch = useDispatch();
  const NotificationHelper = useNotificationHelper();

  const [notificationType, setNotificationType] = useState('info');
  const [notificationContentsType, setNotificationContentsType] = useState('');
  const [notificationDuration, setNotificationDuration] = useState(3000);
  const [notificationSticky, setNotificationSticky] = useState(false);
  const [notificationPersistent, setNotificationPersistent] = useState(false);
  // const [htmlNotification, setHtmlNotification] = useState(false);
  // const [reactNotification, setReactNotification] = useState(false);
  const [notificationCustomIcon, setNotificationCustomIcon] = useState(false);

  const appStatus = useSelector((state: AppState) => {
    return state.appStatus;
  });

  const showHeaderToolbar = () => {
    dispatch({
      type: 'SET_HEADER_TOOLBAR',
      payload: <AppDebugHeaderToolbar />,
    });
    dispatch({ type: 'SET_HEADER_TOOLBAR_VISIBLE', payload: true });
  };

  const hideHeaderToolbar = () => {
    setTimeout(() => {
      dispatch({
        type: 'SET_HEADER_TOOLBAR',
        payload: null,
      });
    }, 400);
    dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    dispatch({ type: 'SET_HEADER_TOOLBAR_VISIBLE', payload: false });

    // dispatch({type: 'SET_HEADER_TOOLBAR_VISIBLE', payload: false});
  };

  const addCustomNotification = () => {
    const icon: React.ReactNode | React.ReactElement | Element | null = notificationCustomIcon ? (
      <UserShieldIcon />
    ) : null;
    let message: string | React.FunctionComponent | React.ReactNode | React.ReactElement | Element =
      'Custom notification';
    // if (reactNotification) {
    if (notificationContentsType === 'react') {
      message = (
        <div>
          <h3>Custom notification</h3>
          <div>
            <span>Notifications support React components as well.</span>
            <Tooltip title="Custom tooltip">
              <span>Hover this text to see React tooltip.</span>
            </Tooltip>
          </div>
        </div>
      );
      // } else if (htmlNotification) {
    } else if (notificationContentsType === 'html') {
      message = (
        <div>
          <h3>Custom notification</h3>
          <div>HTML contents are displayed in notification.</div>
        </div>
      );
    }
    NotificationHelper.add(
      message,
      notificationType,
      notificationDuration,
      icon,
      notificationSticky,
      notificationPersistent,
      undefined,
    );
  };

  const handleNotificationTypeChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode,
  ) => {
    const target = event.target as HTMLSelectElement;
    setNotificationType(`${target.value}`);
  };
  // const handleNotificationTypeChange = (event: React.SyntheticEvent) => {
  //   const target = event.target as HTMLSelectElement;
  //   setNotificationType(`${target.value}`);
  // };

  const handleNotificationDurationChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    if (target.value === '') {
      setNotificationDuration(-1);
    } else {
      setNotificationDuration(+`${target.value}`);
    }
  };

  const handleNotificationContentsTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setNotificationContentsType(value);
  };
  const handleStickyNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setNotificationSticky(checked);
  };
  const handlePersistentNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setNotificationPersistent(checked);
  };
  const handleCustomIconChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setNotificationCustomIcon(checked);
  };

  const handleClearClick = () => {
    NotificationHelper.clear();
  };

  return (
    <div className="app-debug-ui-section-root">
      <div className="app-debug-drawer-subsection">
        <div className="app-debug-drawer-subsection-title">
          <h3 className="app-debug-drawer-subsection-title-text">Header toolbar</h3>
          <span></span>
        </div>
        <div className="app-debug-drawer-button-row">
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={showHeaderToolbar}
            disabled={appStatus.headerToolbar !== null}
          >
            Show toolbar
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={hideHeaderToolbar}
            disabled={appStatus.headerToolbar === null}
          >
            Hide toolbar
          </Button>
        </div>
      </div>
      <div className="app-debug-drawer-subsection app-api-debug-notifications">
        <div className="app-debug-drawer-subsection-title">
          <h3 className="app-debug-drawer-subsection-title-text">Notifications</h3>
          <span></span>
        </div>
        <div className="app-debug-drawer-subsection-form-row-group">
          <div className="app-debug-drawer-subsection-row">
            <FormControl
              size="small"
              margin="dense"
              color="primary"
              fullWidth={true}
              required={true}
              error={notificationType === ''}
              className="api-debug-select-field"
            >
              <InputLabel>Notification type</InputLabel>

              <Select
                fullWidth={true}
                variant="standard"
                placeholder="Notification type"
                defaultValue={notificationType}
                onChange={handleNotificationTypeChange}
              >
                <MenuItem value="info">Info</MenuItem>
                <MenuItem value="warning">Warning</MenuItem>
                <MenuItem value="error">Error</MenuItem>
              </Select>
              <FormHelperText>
                Type of notifications determines its appearance and its behavior.
              </FormHelperText>
            </FormControl>
          </div>
          <div
            className="app-debug-drawer-subsection-row"
            title="Set duration in milliseconds (0 disables timer completely)"
          >
            <FormControl
              fullWidth={true}
              error={!notificationSticky && notificationDuration === -1}
            >
              <TextField
                required={!notificationSticky}
                label="Notification duration"
                variant="outlined"
                disabled={notificationSticky}
                inputProps={{
                  type: 'number',
                  min: 0,
                }}
                defaultValue={`${notificationDuration}`}
                size="small"
                margin="dense"
                onChange={handleNotificationDurationChange}
              />
              <FormHelperText>
                Duration (in milliseconds) after which notification dissapears.
              </FormHelperText>
            </FormControl>
          </div>
          <div className="app-debug-drawer-row">
            <FormControl
              color="primary"
              size="small"
              margin="dense"
              component="fieldset"
              variant="outlined"
              fullWidth={true}
            >
              <FormLabel component="legend">Notification body</FormLabel>
              <RadioGroup
                className="app-api-notification-body-type-row"
                row={true}
                defaultValue=""
                onChange={handleNotificationContentsTypeChange}
              >
                <Tooltip
                  classes={{
                    popper: 'elevate-tooltip-wrapper',
                  }}
                  title={
                    <div className="elevate-tooltip">
                      Notification body contains plain text only.
                    </div>
                  }
                  arrow={true}
                  placement="top"
                >
                  <FormControlLabel
                    value=""
                    control={<Radio size="small" color="primary" />}
                    label="Plain text"
                  />
                </Tooltip>
                <Tooltip
                  classes={{
                    popper: 'elevate-tooltip-wrapper',
                  }}
                  title={
                    <div className="elevate-tooltip">
                      Notification body display HTML element with children.
                    </div>
                  }
                  arrow={true}
                  placement="top"
                >
                  <FormControlLabel
                    value="html"
                    control={<Radio size="small" color="primary" />}
                    label="HTML"
                  />
                </Tooltip>
                <Tooltip
                  classes={{
                    popper: 'elevate-tooltip-wrapper',
                  }}
                  title={
                    <div className="elevate-tooltip">
                      Notification body uses custom React component.
                    </div>
                  }
                  arrow={true}
                  placement="top"
                >
                  <FormControlLabel
                    value="react"
                    control={<Radio size="small" color="primary" />}
                    label="React"
                  />
                </Tooltip>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="app-debug-drawer-subsection-row">
            <FormControl fullWidth={true} component="fieldset">
              <FormLabel component="legend">Notification options</FormLabel>
              <FormGroup row={true} className="app-ui-debug-options">
                <FormControl variant="outlined">
                  <Tooltip
                    classes={{
                      popper: 'elevate-tooltip-wrapper',
                    }}
                    title={
                      <div className="elevate-tooltip">
                        Sticky notification remain on screen and can&apos;t be removed.
                      </div>
                    }
                    arrow={true}
                    placement="top"
                  >
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          onChange={handleStickyNotificationChange}
                        />
                      }
                      label="Sticky"
                    />
                  </Tooltip>
                </FormControl>
                <FormControl variant="filled">
                  <Tooltip
                    classes={{
                      popper: 'elevate-tooltip-wrapper',
                    }}
                    title={
                      <div className="elevate-tooltip">
                        Shows a custom notification icon instead of default one.
                      </div>
                    }
                    arrow={true}
                    placement="top"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox size="small" color="primary" onChange={handleCustomIconChange} />
                      }
                      label="Custom icon"
                    />
                  </Tooltip>
                </FormControl>
              </FormGroup>
              <FormGroup row={true} className="app-ui-debug-options">
                <FormControl variant="outlined">
                  <Tooltip
                    classes={{
                      popper: 'elevate-tooltip-wrapper',
                    }}
                    title={
                      <div className="elevate-tooltip">
                        Persistent notification remains on screen when switching pages.
                      </div>
                    }
                    arrow={true}
                    placement="top"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          onChange={handlePersistentNotificationChange}
                        />
                      }
                      label="Persistent"
                    />
                  </Tooltip>
                </FormControl>
              </FormGroup>
            </FormControl>
          </div>
        </div>
        <div className="app-debug-drawer-button-row">
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={addCustomNotification}
            disabled={(!notificationSticky && notificationDuration < 0) || notificationType === ''}
          >
            Display Notification
          </Button>
        </div>
        <div className="app-debug-drawer-subsection">
          <div className="app-debug-drawer-subsection-row app-debug-active-notifications">
            <div className="app-debug-active-notifications-title">
              <span>
                Active notifications{' '}
                {NotificationHelper.notifications.length > 0
                  ? `(${NotificationHelper.notifications.length})`
                  : ''}
              </span>
              <IconButton
                title="Remove all active notifications"
                color="secondary"
                size="small"
                disabled={NotificationHelper.notifications.length === 0}
                onClick={handleClearClick}
              >
                <FaTrashAlt size="0.75em" />
              </IconButton>
            </div>
            <ul className="app-debug-active-notifications-list">
              {NotificationHelper.notifications.map((item: AppNotification, index: number) => {
                return <AppDebugNotificationItem key={item.uuid} item={item} index={index} />;
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUIDebugSection;
