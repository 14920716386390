import moment from 'moment';
import {
  CompareAssignmentItem,
  CreateAssignmentRequest,
} from './useCheckForDuplicateAssignment.types';
import { excludeLettersFromString } from '../../lib/util/string.util';

/**
 * Compares the assignment to the new assignment.
 * @param {CompareAssignmentItem} assignment The assignment to compare.
 * @param {Partial<CreateAssignmentRequest>} newAssignment The new assignment to compare.
 * @returns {boolean} Whether the assignments are the same.
 */
function compareAssignment(
  assignment: CompareAssignmentItem,
  newAssignment: Pick<
    CreateAssignmentRequest,
    'batteryDomainIds' | 'form' | 'gradeId' | 'level' | 'startDate' | 'endDate'
  >,
): boolean {
  /**
   * There are two form types for CogAT: Form 7 and Form 8.
   * There are two types of assignments per form type: CogAT Complete and CogAT Screening & Post-Screener
   * (e.g. "CogAT Complete Form 7" and "CogAT Screening & Post-Screener Form 7" ).
   * As of writing this, any Form 7 is considered the same as any other Form 7, and the same for Form 8.
   *
   * Compare the assignmentType to the newAssignment.form to see if they are the same.
   */
  const formIdentifiers = ['Form 7', 'Form 8'];

  for (const formIdentifier of formIdentifiers) {
    const assignmentIsCurrentForm = assignment.assignmentType.includes(formIdentifier);
    const newIsCurrentForm = newAssignment.form?.includes(formIdentifier);

    if (newIsCurrentForm && !assignmentIsCurrentForm) {
      return false;
    }
  }

  // Use startOf('day') to compare only the date and not the time.
  const newStartDate = moment(newAssignment.startDate).startOf('day');
  const newEndDate = moment(newAssignment.endDate).startOf('day');
  const assignmentStartDate = moment(assignment.startDate).startOf('day');
  const assignmentEndDate = moment(assignment.endDate).startOf('day');
  const isStartDateBeforeStartDate = newStartDate.isBefore(assignmentStartDate);

  // Compare if startDate is between assignmentStartDate and assignmentEndDate.
  if (
    !isStartDateBeforeStartDate &&
    !newStartDate.isBetween(assignmentStartDate, assignmentEndDate) &&
    !newStartDate.isSame(assignmentStartDate) &&
    !newStartDate.isSame(assignmentEndDate)
  ) {
    return false;
  }

  // Compare if endDate is between assignmentStartDate and assignmentEndDate,
  // or if the startDate is before the assignmentStartDate and the endDate is after the assignmentEndDate.
  if (
    !newEndDate.isBetween(assignmentStartDate, assignmentEndDate) &&
    !newEndDate.isSame(assignmentEndDate) &&
    !newEndDate.isAfter(assignmentEndDate) &&
    !newEndDate.isSame(assignmentStartDate)
  ) {
    return false;
  }

  return assignment.gradeDomains.some((gradeDomain) => {
    // Compare if grade_id ("2") === newAssignment.gradeId ("G2")
    const isSameGradeId =
      excludeLettersFromString(gradeDomain.grade_id) ===
      excludeLettersFromString(newAssignment.gradeId);

    // Compare if level ("Level 13/14") === newAssignment.level ("L13/14")
    const isSameLevel =
      excludeLettersFromString(gradeDomain.level) === excludeLettersFromString(newAssignment.level);

    // Compare if domain_id (["Q,N,V"]) === newAssignment.batteryDomainIds (["Q,N"])
    const existingDomainIds = gradeDomain.domain_id?.split(',') ?? [];
    const newDomainIds = newAssignment.batteryDomainIds?.split(',') ?? [];
    const isSameDomainId = existingDomainIds.some((id) => newDomainIds.includes(id));

    // Compare if existingDomainIds includes screeningDomainIds.
    // Screening Domain ID "S" means 1 subtest from V/Q/N, so the battery will always match a screener.
    const screeningDomainIds = ['S'];
    const existingDomainIsScreening = existingDomainIds.some((id) =>
      screeningDomainIds.includes(id),
    );
    // Compare if newDomainIds includes screeningDomainIds
    const newDomainIsScreening = newDomainIds.some((id) => screeningDomainIds.includes(id));

    const isSameBatteryDomain = isSameDomainId || existingDomainIsScreening || newDomainIsScreening;

    return isSameGradeId && isSameLevel && isSameBatteryDomain;
  });
}

/**
 * Checks for duplicates in the assignments array.
 * @param {CompareAssignmentItem[]} assignments The assignments to check for duplicates.
 * @param {Partial<CreateAssignmentRequest>} newAssignment The new assignment to compare.
 * @returns {CompareAssignmentItem[]} The duplicate assignments.
 */
export function checkForDuplicates(
  assignments: CompareAssignmentItem[] | null,
  newAssignment: Pick<
    CreateAssignmentRequest,
    'batteryDomainIds' | 'form' | 'gradeId' | 'level' | 'startDate' | 'endDate'
  >,
): CompareAssignmentItem[] {
  return assignments?.filter((assignment) => compareAssignment(assignment, newAssignment)) ?? [];
}
