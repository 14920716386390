import React from 'react';

const LoaderSpinIcon: React.FunctionComponent = () => {
  return (
    <div className="spinner-wrapper">
      <svg
        className="loader-spinner"
        xmlns="http://www.w3.org/2000/svg"
        width="66.125"
        height="64"
        viewBox="0 0 66.125 64"
      >
        <path
          d="M69.656,35.473A33.764,33.764,0,0,0,59.284,11.72a33.519,33.519,0,0,0-11-7.042,33.147,33.147,0,0,0-25.309.493A32.614,32.614,0,0,0,5.722,23.124a32.081,32.081,0,0,0,.492,24.492A31.547,31.547,0,0,0,23.591,64.3a31.014,31.014,0,0,0,23.676-.492A30.48,30.48,0,0,0,63.371,47a29.789,29.789,0,0,0,1.769-6.918c.083,0,.166.008.251.008a4.266,4.266,0,0,0,4.266-4.266c0-.12-.006-.238-.016-.355h.016ZM62.878,46.8A29.414,29.414,0,0,1,46.654,62.328a28.882,28.882,0,0,1-22.042-.493A28.347,28.347,0,0,1,9.662,46.187a27.814,27.814,0,0,1,.493-21.225A27.388,27.388,0,0,1,16.3,16.244a27.112,27.112,0,0,1,8.923-5.656A26.732,26.732,0,0,1,35.531,8.813,26.3,26.3,0,0,1,54.006,17a26.045,26.045,0,0,1,5.425,8.577,25.666,25.666,0,0,1,1.695,9.9h.016q-.015.177-.016.355a4.266,4.266,0,0,0,3.8,4.241A29.415,29.415,0,0,1,62.878,46.8Z"
          transform="translate(-3.531 -2.407)"
          fill="#096da9"
        />
      </svg>
    </div>
  );
};

export default LoaderSpinIcon;
