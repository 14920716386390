import moment from 'moment';
import { StaffAuthUser } from '@riversideinsights/elevate-react-lib';
import AppApiHelper from '../../lib/helper/AppApiHelper';
import { AssignmentResponse, CompareAssignmentItem } from './useCheckForDuplicateAssignment.types';
import LogHelper from '../../lib/helper/LogHelper';

export async function fetchAssignment(
  assignmentId: number,
  apiHelper: AppApiHelper,
): Promise<AssignmentResponse | null> {
  try {
    const result = await apiHelper.apiRequest('assignmentApiUrl', 'GetAssignmentById', {
      assignmentid: assignmentId,
    });
    if (result && result.data) {
      return result.data[0] as AssignmentResponse;
    }
  } catch (error: unknown) {
    LogHelper.error((error as Error).message, 'HOOK|useCheckForDuplicateAssignment');
    throw error;
  }
  return null;
}

const currentYear = moment().year();
const fetchAssignmentsDefaults = {
  pagesize: '999',
  schoolYear: `${currentYear - 1}-${currentYear}`,
  sortingDirection: 'DESC',
  sortingField: 'startdate',
  status: ['Open', 'Scheduled'],
};

export async function fetchAssignmentsByGrade(
  user: Partial<StaffAuthUser>,
  grades: string[],
  apiHelper: AppApiHelper,
): Promise<AssignmentResponse[] | null> {
  try {
    const result = await apiHelper.apiRequest('assignmentApiUrl', 'ViewAssignmentsByGrade', {
      ...fetchAssignmentsDefaults,
      customerId: user.customerId,
      grades,
      userRole: user.role,
    });
    if (result && result.data) {
      return result.data.assignments;
    }
  } catch (error: unknown) {
    LogHelper.error((error as Error).message, 'HOOK|useCheckForDuplicateAssignment');
    throw error;
  }
  return null;
}

// Normalizes the assignment response for comparison.
export function normalizeAssignment(
  assignment: Pick<
    AssignmentResponse,
    'assignmentid' | 'assignmentname' | 'assignmenttype' | 'gradedomains' | 'enddate' | 'startdate'
  > | null,
): CompareAssignmentItem {
  if (!assignment) {
    return {} as CompareAssignmentItem;
  }

  const gradeDomains = assignment.gradedomains && JSON.parse(assignment.gradedomains);
  return {
    assignmentId: assignment.assignmentid,
    assignmentName: assignment.assignmentname,
    assignmentType: assignment.assignmenttype,
    endDate: assignment.enddate,
    gradeDomains,
    startDate: assignment.startdate,
  };
}

/**
 * First fetches assignments by grade, then fetches the assignment data for each assignment.
 * @param {Partial<StaffAuthUser>} user The user to fetch assignments for.
 * @param {string[]} grades The grades to fetch assignments for.
 * @param {AppApiHelper} apiHelper The API helper to use for fetching assignments.
 * @returns {Promise<CompareAssignmentItem[] | null>} The populated assignments or `null` if an error occurred.
 */
export async function fetchPopulatedAssignments(
  user: Partial<StaffAuthUser>,
  grades: string[],
  apiHelper: AppApiHelper,
): Promise<CompareAssignmentItem[]> {
  const assignments = await fetchAssignmentsByGrade(user, grades, apiHelper);

  if (!assignments?.length) {
    return [];
  }

  const fetchPromises = assignments.map((assignment) =>
    fetchAssignment(assignment.assignmentid, apiHelper),
  );
  const assignmentDataList = await Promise.all(fetchPromises).catch((error: unknown) => {
    LogHelper.error((error as Error).message, 'HOOK|useCheckForDuplicateAssignment');
    throw error;
  });

  return assignmentDataList
    ? assignmentDataList.map((assignmentData) => normalizeAssignment(assignmentData))
    : [];
}
