import React, { useState, useEffect } from 'react';
import { ReactComponent as IconCheckbox } from './checkboxIcons/checkbox.svg';
import { ReactComponent as IconCheck } from './checkboxIcons/check.svg';
import './Checkbox.scss';

export interface CheckboxProps {
  checked: boolean;
  border?: boolean;
  radio?: boolean;
  className?: string;
  label?: string;
  handleChange?: (isChecked: boolean) => void;
  disabled?: boolean;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = (props): JSX.Element => {
  const { checked, border, radio, className, label, handleChange, disabled = false } = props;

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onChange = (e: React.ChangeEvent) => {
    setIsChecked(!isChecked);
    handleChange && handleChange(!isChecked);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.nativeEvent.code === 'Enter' || e.nativeEvent.code === 'Space') {
      e.preventDefault();
      setIsChecked(!isChecked);
      handleChange && handleChange(!isChecked);
    }
  };

  return (
    <div
      className={
        'checkbox-custom' +
        (className ? ' ' + className : '') +
        (border && border === true ? ' border' : '') +
        (radio && radio === true ? ' radio' : '') +
        (radio && radio === true && isChecked ? ' radio-checked' : '') +
        (disabled ? ' disabled' : '')
      }
      aria-checked={isChecked}
      role="checkbox"
      tabIndex={0}
      onKeyDown={disabled ? undefined : (e) => onKeyDown(e)}
    >
      <label>
        <span className="icons-wrapper">
          <IconCheckbox />
          {isChecked ? <IconCheck /> : ''}
        </span>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={disabled ? undefined : (e) => onChange(e)}
          readOnly={disabled}
        />
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
