import { StaffAuthUser, StudentAuthUser } from '../../reducers/Auth/authDataTypes';
import * as Ably from 'ably';
import { Types as AblyTypes, Realtime as AblyRealtime } from 'ably';

const delimiter = '-';
export const proctorConnectionPrefix = 'proctor-';
export const studentConnectionPrefix = 'student-';

/**
 * Ably Identifers / Channel Names
 *   The student channel is a 1:1 channel between student:proctor and used for presence/messaging
 *   The proctor channel is a 1:many channel between proctor:students and used to track student presence
 *
 *   The prefix used to build these channel names is defined in the configuration file
 */

/**
 * Retrieve the student channel identifier based on the student user details
 *   {prefix}-{companyid}-{studentid}
 * @param {StudentAuthUser} student the logged in Student user
 * @return {string} the generated student identifier string
 */
export const ablyStudentIdentifier = (student: StudentAuthUser): string => {
  const studentId = student.info?.globalStudentId;
  const customerId = student.info?.customerId;
  return studentId && customerId
    ? `${studentConnectionPrefix}${customerId}${delimiter}${studentId}`
    : '';
};

/**
 * Retrieve the proctor identifier for a Student, based on their linked customer ID and proctor code
 *   {prefix}-{companyid}-{proctorCode}
 * @param {StudentAuthUser} student the logged in Student user
 * @return {string} the generated proctor identifier string for the current student
 */
export const ablyProctorIdentifierForStudent = (student: StudentAuthUser): string => {
  const proctorCode = student.proctorCode;
  const customerId = student.info?.customerId;
  return proctorCode && customerId
    ? `${proctorConnectionPrefix}${customerId}${delimiter}${proctorCode}`
    : '';
};

/**
 * Build the proctor channel identifier based on the staff user details
 *   {prefix}-{companyid}-{proctorCode}
 * @param {StaffAuthUser} staff the logged in Staff user
 * @return {string} the generated proctor identifier string for the current proctor
 */
export const ablyProctorIdentifier = (staff: StaffAuthUser): string => {
  const proctorCode = staff.currentProctorCode;
  const customerId = staff.customerId;
  return proctorCode && customerId
    ? `${proctorConnectionPrefix}${customerId}${delimiter}${proctorCode}`
    : '';
};

/**
 * A Proctor interacts with many students during a proctoring session. This function generates a student channel identifier
 * given a Proctor/Student combo
 *   {prefix}-{companyid}-{studentCode}
 * @param {StaffAuthUser} staff the logged in Staff user
 * @param {string} studentId the ID of the student to generate an ID for
 * @return {string} the generated identifier string for the given student and proctor/customer
 */
export const ablyStudentIdentifierForProctor = (
  staff: StaffAuthUser,
  studentId: string,
): string => {
  const customerId = staff.customerId;
  return customerId && studentId
    ? `${studentConnectionPrefix}${customerId}${delimiter}${studentId}`
    : '';
};

/**
 * Given a student ably identifier (student-org123-stu123), extract only the student ID portion.
 * This allows for matching incoming ably messages to student instances.
 *   {prefix}-{companyid}-{studentCode}
 * @param {string} identifier an ably student identifier
 * @return {string} the extracted student ID
 */
export const studentIdFromAblyStudentIdentifier = (identifier: string): string => {
  const sections = identifier.split(delimiter);
  return sections.length === 3 ? sections[2] : '';
};

// helper to determine if a presence event indicates that another member is "present" or not based on a presence event
/**
 * This helper accepts an Ably PresenceMessage, and returns true if the associated event represents the associated client is "Present"
 * @param {Ably.Types.PresenceMessage} presenceEvent the incoming able presence message
 * @return {boolean} A true value indicates the event is for "present", false indicates "not present"
 */
const presentEventTypes = ['enter', 'present'];
export const isPresentAblyEvent = (presenceEvent: Ably.Types.PresenceMessage) =>
  presentEventTypes.includes(presenceEvent.action);

/**
 * Returns ably client id for all user types
 *
 * @param {string} userId       Cognito user id
 * @param {string} customerId   Customer id
 * @param {string} userType     User type ('staff', 'student' or '')
 *
 * @returns {string}        Client id or empty string if there is no valid user
 */
export const ablyClientId = (
  userId: string,
  customerId: string,
  userType: string | null,
): string => {
  if (userType === null || userId === '' || customerId === '') {
    return '';
  }
  const connectionPrefix =
    userType === 'student'
      ? studentConnectionPrefix
      : userType === 'staff'
        ? proctorConnectionPrefix
        : '';
  return `${connectionPrefix}${customerId}${delimiter}${userId}`;
};

/**
 * This function is a thin wrapper to the AblyRealtime.Promise call to allow for easier unit testing of the Ably SDK and wasn't intended to be tested independently
 *
 * @param {AblyTypes.ClientOptions} options the options to initialize ably with
 *
 * @returns {AblyTypes.RealtimeCallbacks} an instance od the realtime ably client
 */
export const getAblyRealtimeClient = (
  options: AblyTypes.ClientOptions,
): AblyTypes.RealtimeCallbacks => new AblyRealtime(options);
