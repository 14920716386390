import moment from 'moment';

const timeBegin = 30;
const arrNotification = [];
for (let i = 0; i < 8; i++) {
  arrNotification.push({
    id: i,
    datetime: '01/22/2021 - 12:' + (timeBegin + i) + 'pm',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem. Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum sed.',
  });
}

const initState = {
  isActive: false,
  notifications: arrNotification.slice(0),
};

const studentNotificationPanelReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SHOW_STUDENT_NOTIFICATION_PANEL':
      return {
        ...state,
        isActive: true,
      };
    case 'HIDE_STUDENT_NOTIFICATION_PANEL':
      return {
        ...state,
        isActive: false,
      };
    case 'ADD_STUDENT_NEW_NOTIFICATION':
      const newNotificationState = Object.assign({}, state); //clone the state
      const max = Math.max(
        ...newNotificationState.notifications.map(function (notification) {
          return notification.id;
        }),
      );
      /*
      const max = Math.max.apply(
        Math,
        newNotificationState.notifications.map(function (notification) {
          return notification.id;
        }),
      );
*/
      newNotificationState.notifications.push({
        id: max + 1,
        datetime: moment().format('MM/DD/YYYY - HH:mm'),
        text: action.payload,
      });
      return newNotificationState;
    case 'DISMISS_STUDENT_NOTIFICATION':
      const newDismissedNotificationState = Object.assign({}, state);
      // console.log(action.payload);
      state.notifications.map((notification: any, index: number) => {
        if (notification.id === action.payload) {
          newDismissedNotificationState.notifications.splice(index, 1);
        }
      });
      return newDismissedNotificationState;
    case 'DISMISS_ALL_STUDENT_NOTIFICATIONS':
      const newEmptyNotificationState = Object.assign({}, state);
      newEmptyNotificationState.notifications = [];
      return newEmptyNotificationState;
    default:
      return state;
  }
};

export default studentNotificationPanelReducer;
