import React from 'react';

import { AssignmentListItem } from '../../../TestAssignment';
import type { AssignmentListTableColumn } from '../TestAssignmentTrashTable';
import { formatDate } from './renderDatesCell';

const renderTrashDateCell = (item: AssignmentListItem, column: AssignmentListTableColumn) => {
  return (
    <span className="assignment-status-value">
      {item.trashdate ? formatDate(item.trashdate) : 'N/A'}
    </span>
  );
};
export default renderTrashDateCell;
