import React from 'react';

const CloseIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <g transform="translate(-162 -168)">
        <path
          d="M21.5,8.91,20.09,7.5,14.5,13.09,8.91,7.5,7.5,8.91l5.59,5.59L7.5,20.09,8.91,21.5l5.59-5.59,5.59,5.59,1.41-1.41L15.91,14.5Z"
          transform="translate(154.5 160.5)"
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
