import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import styles from './MidpointDate.module.scss';
import { ReactComponent as LockIcon } from '../calendarIcons/lock-solid.svg';

interface MidpointDateProps {
  className?: string;
  locked?: boolean;
  disabled?: boolean;
  lockIconClassName?: string;
  endDateId?: string;
  startDateId?: string;
  midpointDateLocked?: boolean;
  isOutsideRange?: (day: moment.Moment) => boolean;
  midpointDate?: Date | moment.Moment | null;
  endDate?: Date | moment.Moment | null;
  startDate?: Date | moment.Moment | null;
  onDatesChange?: (data: any) => void;
}

const MidpointDate = ({
  className,
  locked = false,
  lockIconClassName,
  midpointDate,
  disabled,
}: MidpointDateProps) => {
  return (
    <div className={classNames(styles['midpoint-date'], className)}>
      {locked && <LockIcon className={classNames(styles['lock-icon'], lockIconClassName)} />}
      Midpoint: {(midpointDate && moment(midpointDate).format('MMM-DD')) || '-'}
    </div>
  );
};

export default MidpointDate;
