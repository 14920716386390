import { useMemo } from 'react';
import useAppStatus from './useAppStatus';
import useCachedUser from './useCachedUser';
import AppHelper from '../lib/helper/AppHelper';
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import { useLocation } from 'react-router';

const useWrapperClasses = (baseClassName = ''): string => {
  const config = useConfigContext();
  const appStatus = useAppStatus();
  const location = useLocation();
  const user = useCachedUser();
  const classNames = useMemo<string>(() => {
    const debugAllowed = AppHelper.getCookieValue('elevateDebugEnabled') ? true : config.local;
    let addClasses = `app-page${baseClassName ? ' ' + baseClassName : ''}`;
    const pathname = location.pathname.replace(/^\//, '');
    const pathChunks = pathname.split('/');

    // if (pathChunks && pathChunks.length > 1 && config.validRootPathnames.indexOf(pathChunks[1]) !== -1) {
    if (pathChunks && pathChunks.length > 1 && pathChunks[1].match(/^[a-zA-Z0-9-]+$/)) {
      addClasses += ` app-page-${pathChunks[1]}`;
    }
    if (appStatus.loadingUser) {
      addClasses += ' app-loading-user';
    }
    if (appStatus.headerVisible) {
      addClasses += ' has-app-header';
    }
    if (appStatus.appError) {
      addClasses += ' has-app-error';
    }
    if (appStatus.appErrorFatal) {
      addClasses += ' has-app-error-fatal';
    }
    if (appStatus.appNotifications.length > 0) {
      addClasses += ' has-app-notification';
      if (appStatus.appNotifications.some((an) => an.sticky)) {
        addClasses += ' has-sticky-app-notification';
      }
    }
    if (appStatus.headerToolbarVisible && appStatus.headerToolbar !== null) {
      addClasses += ' has-app-header-toolbar';
    }
    if (appStatus.overlayVisible || appStatus.currentModal !== null) {
      addClasses += ' app-overlay-visible';
    }
    if (appStatus.debugVisible && debugAllowed) {
      addClasses += ' has-app-debug';
    }
    if (!user.loggedIn) {
      addClasses += ' is-guest-user';
    } else {
      if (user.loadComplete) {
        addClasses += ' user-load-complete';
        if (!(user.termsOfUseAccepted && user.privacyPolicyAccepted)) {
          addClasses += ' terms-not-accepted';
        }
      } else {
        addClasses += ' user-load-incomplete';
      }
    }
    // const wrapperClasses = `app-page${addClasses}`;
    return addClasses;
  }, [
    appStatus.loadingUser,
    appStatus.headerVisible,
    appStatus.headerToolbar,
    appStatus.headerToolbarVisible,
    location.pathname,
    appStatus.currentModal,
    appStatus.debugVisible,
    appStatus.appNotifications.length,
    appStatus.overlayVisible,
    appStatus.appError,
    appStatus.appErrorFatal,
    user.loggedIn,
    user.loadComplete,
    user.termsOfUseAccepted,
    user.privacyPolicyAccepted,
  ]);

  return classNames;
};

export default useWrapperClasses;
