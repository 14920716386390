import React, { useEffect, useState } from 'react';
import useNotificationHelper from '../../../../hooks/useNotificationHelper';
import useCachedUser from '../../../../hooks/useCachedUser';
// import useApiHelper from '../../../../hooks/useApiHelper';

import { Button, Collapse, IconButton } from '@mui/material';
import CopyIcon from '../../icons/CopyIcon';

import { FaTimes } from 'react-icons/fa';

export interface ApiDebugCommonValue {
  name: string;
  value: string;
}

const AppApiDebugCommonParams: React.FC = () => {
  const user = useCachedUser();
  const NotificationHelper = useNotificationHelper();

  const [commonParamsVisible, setCommonParamsVisible] = useState(false);
  const [commonValues, setCommonValues] = useState<ApiDebugCommonValue[]>([]);

  useEffect(() => {
    if (user.customerId) {
      setCommonValues([
        {
          name: 'Customer ID',
          value: user.customerId,
        },
        {
          name: 'User ID',
          value: user.userId,
        },
        {
          name: 'Proctor Code',
          value: user.currentProctorCode,
        },
        {
          name: 'District ID',
          value: user.districtId,
        },
        {
          name: 'District External ID',
          value: `${user?.districtExternalId || ''}`,
        },
        {
          name: 'District Name',
          value: `${user?.districtName || ''}`,
        },
      ]);
    }
  }, [user.customerId]);

  const toggleCommonParamsVisible = () => {
    setCommonParamsVisible(!commonParamsVisible);
  };

  const copyTextToClipboard = async (text: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      navigator.clipboard.writeText(text).then(
        () => {
          NotificationHelper.add('Value copied to clipboard', 'info');
          resolve();
          // setUserCopied(true);
          // NotificationHelper.add('User data copied to clipboard as JSON', 'info');
          // setTimeout(() => {
          //   hideModal();
          // }, 1000);
        },
        () => {
          NotificationHelper.add('Failed copying value to clipboard', 'error');
          reject(new Error('Copy error'));
          // NotificationHelper.add('Failed copying user data to clipboard', 'error');
          // setUserCopied(false);
        },
      );
    });
  };

  return (
    <div className="app-api-debug-row-common-params">
      <Collapse className="common-param-grid-wrapper" in={commonParamsVisible}>
        <div className="common-param-grid">
          <span className="common-param-grid-cell-header">Name</span>
          <span className="common-param-grid-cell-header">Value</span>
          <div className="common-param-grid-cell-header common-param-grid-cell-header-actions">
            <IconButton size="small" onClick={toggleCommonParamsVisible}>
              <FaTimes />
            </IconButton>
          </div>
          {commonValues.map((val: ApiDebugCommonValue, index: number) => {
            return (
              <React.Fragment key={`cp_${index}`}>
                <span className="common-param-grid-cell common-param-grid-cell-name">
                  {val.name}
                </span>
                <output className="common-param-grid-cell common-param-grid-cell-value">
                  {val.value}
                </output>
                <div className="common-param-grid-cell common-param-grid-cell-actions">
                  <IconButton
                    size="small"
                    onClick={() => {
                      copyTextToClipboard(val.value);
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Collapse>
      {!commonParamsVisible && (
        <div className="app-api-debug-row-common-params-toggle">
          <Button
            variant="text"
            color="primary"
            size="small"
            fullWidth={true}
            onClick={toggleCommonParamsVisible}
          >
            Show Common Params
          </Button>
        </div>
      )}
    </div>
  );
};

export default AppApiDebugCommonParams;
