import studentChatPanelReducer from './studentChatPanelReducer';
import studentNotificationPanelReducer from './studentNotificationPanelReducer';
import studentCurrentTestReducer from './studentCurrentTestReducer';
import studentAssignmentListReducer from './studentAssignmentListReducer';
import studentNotificationsReducer from './studentNotificationsReducer';
import studentTutorialReducer from './studentTutorialReducer';
import studentProctorInfoReducer from './studentProctorInfoReducer';
import { combineReducers } from 'redux';

const proctoringReducer = combineReducers({
  studentChatPanel: studentChatPanelReducer,
  studentNotificationPanel: studentNotificationPanelReducer,
  studentCurrentTest: studentCurrentTestReducer,
  studentAssignmentList: studentAssignmentListReducer,
  notifications: studentNotificationsReducer,
  studentTutorial: studentTutorialReducer,
  studentProctorInfo: studentProctorInfoReducer,
});

export default proctoringReducer;
