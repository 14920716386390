export const formatDuration = (value = -1): string => {
  const durationValues = getDurationValues(value);
  let durationText = '';
  if (durationValues.hours > 0) {
    if (durationValues.hours < 10) {
      durationText += '0';
    }
    durationText += `${durationValues.hours} h`;
    durationText += ' ';
  }
  if (durationValues.minutes > 0) {
    if (durationValues.minutes < 10) {
      durationText += '0';
    }
    durationText += `${durationValues.minutes} m`;
    durationText += ' ';
  }
  if (durationValues.seconds < 10) {
    durationText += '0';
  }
  durationText += `${durationValues.seconds} s`;
  // if (durationValues.seconds > 1) {
  //   durationText += 's';
  // }
  return durationText;
};

const getDurationValues = (value = -1) => {
  // let totalSeconds = value;
  // if (value === -1) {
  //   totalSeconds = realValue;
  // }
  const hours = parseInt(`${value / 3600}`, 10);
  const minutes = parseInt(`${(value - hours * 3600) / 60}`, 10);
  const seconds = value % 60;
  return {
    hours,
    minutes,
    seconds,
    totalSeconds: value,
  };
};
