import studentAuthReducer from './studentAuthReducer';
import staffAuthReducer from './staffAuthReducer';
import { Reducer, combineReducers } from 'redux';

import { AuthState } from './authDataTypes';

const authReducer: Reducer<AuthState> = combineReducers<AuthState>({
  studentAuth: studentAuthReducer,
  staffAuth: staffAuthReducer,
});

export default authReducer;
