export const tabsIowaFlexMath = [
  {
    label: 'G K',
    content_id: 187,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'All Domains',
        id: 1,
        standard: 'NA',
        checked: false,
        tooltip:
          'Counting & Cardinality, <br>Operations & Algebraic Thinking, <br>Number & Operations - Base Ten, <br>Measurement & Data, <br>Geometry',
      },
    ],
  },
  {
    label: 'G 1',
    content_id: 188,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'All Domains',
        id: 2,
        standard: 'NA',
        checked: false,
        tooltip:
          'Counting & Cardinality, <br>Operations & Algebraic Thinking, <br>Number & Operations - Base Ten, <br>Measurement & Data, <br>Geometry',
      },
    ],
  },
  {
    label: 'G 2',
    content_id: 189,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'Operations & Algebraic Thinking',
        id: 3,
        standard: 'OA',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Number & Operations - Base Ten',
        id: 4,
        standard: 'NB',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Measurement and Data',
        id: 5,
        standard: 'MD',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Geometry',
        id: 6,
        standard: 'G',
        checked: false,
        tooltip: '',
      },
    ],
  },
  {
    label: 'G 3',
    content_id: 190,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'Operations & Algebraic Thinking',
        id: 7,
        standard: 'OA',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Number & Operations - Base Ten',
        id: 8,
        standard: 'NB',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Number & Operations - Fractions',
        id: 9,
        standard: 'NF',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Measurement and Data',
        standard: 'MD',
        id: 10,
        checked: false,
        tooltip: '',
      },
      {
        text: 'Geometry',
        id: 11,
        standard: 'G',
        checked: false,
        tooltip: '',
      },
    ],
  },
  {
    label: 'G 4',
    content_id: 191,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'Operations & Algebraic Thinking',
        id: 12,
        standard: 'OA',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Number & Operations - Base Ten',
        id: 13,
        standard: 'NB',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Number & Operations - Fractions',
        id: 14,
        standard: 'NF',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Measurement and Data',
        id: 15,
        standard: 'MD',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Geometry',
        id: 16,
        standard: 'G',
        checked: false,
        tooltip: '',
      },
    ],
  },
  {
    label: 'G 5',
    content_id: 192,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'Operations & Algebraic Thinking',
        id: 17,
        standard: 'OA',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Number & Operations - Base Ten',
        id: 18,
        standard: 'NB',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Number & Operations - Fractions',
        id: 19,
        standard: 'NF',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Measurement and Data',
        id: 20,
        standard: 'MD',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Geometry',
        id: 21,
        standard: 'G',
        checked: false,
        tooltip: '',
      },
    ],
  },
  {
    label: 'G 6',
    content_id: 193,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'Ratios & Proportional Relationships',
        id: 22,
        standard: 'RP',
        checked: false,
        tooltip: '',
      },
      {
        text: 'The Number System',
        id: 23,
        standard: 'NS',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Expressions & Equations',
        id: 24,
        standard: 'EE',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Geometry',
        id: 25,
        standard: 'G',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Statistics & Probability',
        id: 26,
        standard: 'SP',
        checked: false,
        tooltip: '',
      },
    ],
  },
  {
    label: 'G 7',
    content_id: 194,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'Ratios & Proportional Relationships',
        id: 27,
        standard: 'RP',
        checked: false,
        tooltip: '',
      },
      {
        text: 'The Number System',
        id: 28,
        standard: 'NS',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Expressions & Equations',
        id: 29,
        standard: 'EE',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Geometry',
        id: 30,
        standard: 'G',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Statistics & Probability',
        id: 31,
        standard: 'SP',
        checked: false,
        tooltip: '',
      },
    ],
  },
  {
    label: 'G 8',
    content_id: 195,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'The Number System',
        id: 32,
        standard: 'NS',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Expressions & Equations',
        id: 33,
        standard: 'EE',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Functions',
        id: 34,
        standard: 'F',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Geometry',
        id: 35,
        standard: 'G',
        checked: false,
        tooltip: '',
      },
      {
        text: 'Statistics & Probability',
        id: 36,
        standard: 'SP',
        checked: false,
        tooltip: '',
      },
    ],
  },
];

export const tabsIowaFlexReading = [
  {
    label: 'G K',
    content_id: 196,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'All Domains',
        id: 37,
        standard: 'NA',
        checked: false,
        tooltip:
          'Key Ideas and Details, <br>Craft and Structure, <br>Integration of Knowledge & Ideas',
      },
    ],
  },
  {
    label: 'G 1',
    content_id: 197,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'All Domains',
        id: 38,
        standard: 'NA',
        checked: false,
        tooltip:
          'Key Ideas and Details, <br>Craft and Structure, <br>Integration of Knowledge & Ideas',
      },
    ],
  },
  {
    label: 'G 2',
    content_id: 198,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'All Domains',
        id: -1,
        standard: 'NA',
        checked: false,
        tooltip:
          'Key Ideas and Details, <br>Craft and Structure, <br>Integration of Knowledge & Ideas',
      },
      /*      {
        text: "Key Ideas and Details",
        id: 39,
        standard: "KID",
        checked: false,
        tooltip: "",
      },
      {
        text: "Craft and Structure",
        id: 40,
        standard: "CS",
        checked: false,
        tooltip: "",
      },
      {
        text: "Integration of Knowledge & Ideas",
        id: 41,
        standard: "IKI",
        checked: false,
        tooltip: "",
      },
*/
    ],
  },
  {
    label: 'G 3',
    content_id: 199,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'All Domains',
        id: -1,
        standard: 'NA',
        checked: false,
        tooltip:
          'Key Ideas and Details, <br>Craft and Structure, <br>Integration of Knowledge & Ideas',
      },
      /*      {
        text: "Key Ideas and Details",
        id: 42,
        standard: "KID",
        checked: false,
        tooltip: "",
      },
      {
        text: "Craft and Structure",
        id: 43,
        standard: "CS",
        checked: false,
        tooltip: "",
      },
*/
    ],
  },
  {
    label: 'G 4',
    content_id: 200,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'All Domains',
        id: -1,
        standard: 'NA',
        checked: false,
        tooltip:
          'Key Ideas and Details, <br>Craft and Structure, <br>Integration of Knowledge & Ideas',
      },
      /*      {
        text: "Key Ideas and Details",
        id: 44,
        standard: "KID",
        checked: false,
        tooltip: "",
      },
      {
        text: "Craft and Structure",
        id: 45,
        standard: "CS",
        checked: false,
        tooltip: "",
      },
*/
    ],
  },
  {
    label: 'G 5',
    content_id: 201,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'All Domains',
        id: -1,
        standard: 'NA',
        checked: false,
        tooltip:
          'Key Ideas and Details, <br>Craft and Structure, <br>Integration of Knowledge & Ideas',
      },
      /*      {
        text: "Key Ideas and Details",
        id: 46,
        standard: "KID",
        checked: false,
        tooltip: "",
      },
      {
        text: "Craft and Structure",
        id: 47,
        standard: "CS",
        checked: false,
        tooltip: "",
      },
*/
    ],
  },
  {
    label: 'G 6',
    content_id: 202,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'All Domains',
        id: -1,
        standard: 'NA',
        checked: false,
        tooltip:
          'Key Ideas and Details, <br>Craft and Structure, <br>Integration of Knowledge & Ideas',
      },
      /*      {
        text: "Key Ideas and Details",
        id: 48,
        standard: "KID",
        checked: false,
        tooltip: "",
      },
      {
        text: "Craft and Structure",
        id: 49,
        standard: "CS",
        checked: false,
        tooltip: "",
      },
*/
    ],
  },
  {
    label: 'G 7',
    content_id: 203,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'All Domains',
        id: -1,
        standard: 'NA',
        checked: false,
        tooltip:
          'Key Ideas and Details, <br>Craft and Structure, <br>Integration of Knowledge & Ideas',
      },
      /*      {
        text: "Key Ideas and Details",
        id: 50,
        standard: "KID",
        checked: false,
        tooltip: "",
      },
      {
        text: "Craft and Structure",
        id: 51,
        standard: "CS",
        checked: false,
        tooltip: "",
      },
*/
    ],
  },
  {
    label: 'G 8',
    content_id: 204,
    checkedDomainNumber: 0,
    domains: [
      {
        text: 'All Domains',
        id: -1,
        standard: 'NA',
        checked: false,
        tooltip:
          'Key Ideas and Details, <br>Craft and Structure, <br>Integration of Knowledge & Ideas',
      },
      /*      {
        text: "Key Ideas and Details",
        id: 52,
        standard: "KID",
        checked: false,
        tooltip: "",
      },
      {
        text: "Craft and Structure",
        id: 53,
        standard: "CS",
        checked: false,
        tooltip: "",
      },
*/
    ],
  },
];

const tabsCogatGrades = [
  { label: 'G K', checkedLevelsNumber: 0 },
  { label: 'G 1', checkedLevelsNumber: 0 },
  { label: 'G 2', checkedLevelsNumber: 0 },
  { label: 'G 3', checkedLevelsNumber: 0 },
  { label: 'G 4', checkedLevelsNumber: 0 },
  { label: 'G 5', checkedLevelsNumber: 0 },
  { label: 'G 6', checkedLevelsNumber: 0 },
  { label: 'G 7', checkedLevelsNumber: 0 },
  { label: 'G 8', checkedLevelsNumber: 0 },
  { label: 'G 9', checkedLevelsNumber: 0 },
  { label: 'G 10', checkedLevelsNumber: 0 },
  { label: 'G 11', checkedLevelsNumber: 0 },
  { label: 'G 12', checkedLevelsNumber: 0 },
];

const tabsCogatLevels = [
  {
    label: 'L 5/6',
    checkedBatteriesNumber: 0,
    batteries: [
      {
        text: 'Verbal',
        id: 1,
        checked: false,
        tooltip: 'Picture Analogies, <br>Sentence Completion, <br>Picture Classification',
      },
      {
        text: 'Alt Verbal',
        id: 2,
        checked: false,
        tooltip: 'Picture Analogies, <br>Picture Classification',
      },
      {
        text: 'Quantitative',
        id: 3,
        checked: false,
        tooltip: 'Number Analogies, <br>Number Puzzles, <br>Number Series',
      },
      {
        text: 'Nonverbal',
        id: 4,
        checked: false,
        tooltip: 'Figure Matrices, <br>Paper Folding, <br>Figure Classification',
      },
    ],
  },
  {
    label: 'L 7',
    checkedBatteriesNumber: 0,
    batteries: [
      {
        text: 'Verbal',
        id: 5,
        checked: false,
        tooltip: 'Picture Analogies, <br>Sentence Completion, <br>Picture Classification',
      },
      {
        text: 'Alt Verbal',
        id: 6,
        checked: false,
        tooltip: 'Picture Analogies, <br>Picture Classification',
      },
      {
        text: 'Quantitative',
        id: 7,
        checked: false,
        tooltip: 'Number Analogies, <br>Number Puzzles, <br>Number Series',
      },
      {
        text: 'Nonverbal',
        id: 8,
        checked: false,
        tooltip: 'Figure Matrices, <br>Paper Folding, <br>Figure Classification',
      },
    ],
  },
  {
    label: 'L 8',
    checkedBatteriesNumber: 0,
    batteries: [
      {
        text: 'Verbal',
        id: 9,
        checked: false,
        tooltip: 'Picture Analogies, <br>Sentence Completion, <br>Picture Classification',
      },
      {
        text: 'Alt Verbal',
        id: 10,
        checked: false,
        tooltip: 'Picture Analogies, <br>Picture Classification',
      },
      {
        text: 'Quantitative',
        id: 11,
        checked: false,
        tooltip: 'Number Analogies, <br>Number Puzzles, <br>Number Series',
      },
      {
        text: 'Nonverbal',
        id: 12,
        checked: false,
        tooltip: 'Figure Matrices, <br>Paper Folding, <br>Figure Classification',
      },
    ],
  },
  {
    label: 'L 9',
    checkedBatteriesNumber: 0,
    batteries: [
      {
        text: 'Verbal',
        id: 13,
        checked: false,
        tooltip: 'Verbal Analogies, <br>Sentence Completion, <br>Verbal Classification',
      },
      {
        text: 'Quantitative',
        id: 14,
        checked: false,
        tooltip: 'Number Analogies, <br>Number Puzzles, <br>Number Series',
      },
      {
        text: 'Nonverbal',
        id: 15,
        checked: false,
        tooltip: 'Figure Matrices, <br>Paper Folding, <br>Figure Classification',
      },
    ],
  },
  {
    label: 'L 10',
    checkedBatteriesNumber: 0,
    batteries: [
      {
        text: 'Verbal',
        id: 16,
        checked: false,
        tooltip: 'Verbal Analogies, <br>Sentence Completion, <br>Verbal Classification',
      },
      {
        text: 'Quantitative',
        id: 17,
        checked: false,
        tooltip: 'Number Analogies, <br>Number Puzzles, <br>Number Series',
      },
      {
        text: 'Nonverbal',
        id: 18,
        checked: false,
        tooltip: 'Figure Matrices, <br>Paper Folding, <br>Figure Classification',
      },
    ],
  },
  {
    label: 'L 11',
    checkedBatteriesNumber: 0,
    batteries: [
      {
        text: 'Verbal',
        id: 19,
        checked: false,
        tooltip: 'Verbal Analogies, <br>Sentence Completion, <br>Verbal Classification',
      },
      {
        text: 'Quantitative',
        id: 20,
        checked: false,
        tooltip: 'Number Analogies, <br>Number Puzzles, <br>Number Series',
      },
      {
        text: 'Nonverbal',
        id: 21,
        checked: false,
        tooltip: 'Figure Matrices, <br>Paper Folding, <br>Figure Classification',
      },
    ],
  },
  {
    label: 'L 12',
    checkedBatteriesNumber: 0,
    batteries: [
      {
        text: 'Verbal',
        id: 22,
        checked: false,
        tooltip: 'Verbal Analogies, <br>Sentence Completion, <br>Verbal Classification',
      },
      {
        text: 'Quantitative',
        id: 23,
        checked: false,
        tooltip: 'Number Analogies, <br>Number Puzzles, <br>Number Series',
      },
      {
        text: 'Nonverbal',
        id: 24,
        checked: false,
        tooltip: 'Figure Matrices, <br>Paper Folding, <br>Figure Classification',
      },
    ],
  },
  {
    label: 'L 13/14',
    checkedBatteriesNumber: 0,
    batteries: [
      {
        text: 'Verbal',
        id: 25,
        checked: false,
        tooltip: 'Verbal Analogies, <br>Sentence Completion, <br>Verbal Classification',
      },
      {
        text: 'Quantitative',
        id: 26,
        checked: false,
        tooltip: 'Number Analogies, <br>Number Puzzles, <br>Number Series',
      },
      {
        text: 'Nonverbal',
        id: 27,
        checked: false,
        tooltip: 'Figure Matrices, <br>Paper Folding, <br>Figure Classification',
      },
    ],
  },
  {
    label: 'L 15/16',
    checkedBatteriesNumber: 0,
    batteries: [
      {
        text: 'Verbal',
        id: 28,
        checked: false,
        tooltip: 'Verbal Analogies, <br>Sentence Completion, <br>Verbal Classification',
      },
      {
        text: 'Quantitative',
        id: 29,
        checked: false,
        tooltip: 'Number Analogies, <br>Number Puzzles, <br>Number Series',
      },
      {
        text: 'Nonverbal',
        id: 30,
        checked: false,
        tooltip: 'Figure Matrices, <br>Paper Folding, <br>Figure Classification',
      },
    ],
  },
  {
    label: 'L 17/18',
    checkedBatteriesNumber: 0,
    batteries: [
      {
        text: 'Verbal',
        id: 31,
        checked: false,
        tooltip: 'Verbal Analogies, <br>Sentence Completion, <br>Verbal Classification',
      },
      {
        text: 'Quantitative',
        id: 32,
        checked: false,
        tooltip: 'Number Analogies, <br>Number Puzzles, <br>Number Series',
      },
      {
        text: 'Nonverbal',
        id: 33,
        checked: false,
        tooltip: 'Figure Matrices, <br>Paper Folding, <br>Figure Classification',
      },
    ],
  },
];

/*
const tabsCogatLevels2 = [
    {
        label: 'L 5/6',
        checkedBatteriesNumber: 0,
        batteries: [
            {
                text: 'Verbal',
                id: 1,
                checked: false,
            },
            {
                text: 'Quantitative',
                id: 3,
                checked: false,
            },
            {
                text: 'Nonverbal',
                id: 4,
                checked: false,
            },
        ],
    },
    {
        label: 'L 7',
        checkedBatteriesNumber: 0,
        batteries: [
            {
                text: 'Verbal',
                id: 5,
                checked: false,
            },
            {
                text: 'Quantitative',
                id: 7,
                checked: false,
            },
            {
                text: 'Nonverbal',
                id: 8,
                checked: false,
            },
        ],
    },
    {
        label: 'L 8',
        checkedBatteriesNumber: 0,
        batteries: [
            {
                text: 'Verbal',
                id: 9,
                checked: false,
            },
            {
                text: 'Quantitative',
                id: 11,
                checked: false,
            },
            {
                text: 'Nonverbal',
                id: 12,
                checked: false,
            },
        ],
    },
    {
        label: 'L 9',
        checkedBatteriesNumber: 0,
        batteries: [
            {
                text: 'Verbal',
                id: 13,
                checked: false,
            },
            {
                text: 'Alt Verbal',
                id: 2,
                checked: false,
            },
            {
                text: 'Quantitative',
                id: 14,
                checked: false,
            },
            {
                text: 'Nonverbal',
                id: 15,
                checked: false,
            },
        ],
    },
    {
        label: 'L 10',
        checkedBatteriesNumber: 0,
        batteries: [
            {
                text: 'Verbal',
                id: 16,
                checked: false,
            },
            {
                text: 'Alt Verbal',
                id: 2,
                checked: false,
            },
            {
                text: 'Quantitative',
                id: 17,
                checked: false,
            },
            {
                text: 'Nonverbal',
                id: 18,
                checked: false,
            },
        ],
    },
    {
        label: 'L 11',
        checkedBatteriesNumber: 0,
        batteries: [
            {
                text: 'Verbal',
                id: 19,
                checked: false,
            },
            {
                text: 'Alt Verbal',
                id: 2,
                checked: false,
            },
            {
                text: 'Quantitative',
                id: 20,
                checked: false,
            },
            {
                text: 'Nonverbal',
                id: 21,
                checked: false,
            },
        ],
    },
    {
        label: 'L 12',
        checkedBatteriesNumber: 0,
        batteries: [
            {
                text: 'Verbal',
                id: 22,
                checked: false,
            },
            {
                text: 'Alt Verbal',
                id: 2,
                checked: false,
            },
            {
                text: 'Quantitative',
                id: 23,
                checked: false,
            },
            {
                text: 'Nonverbal',
                id: 24,
                checked: false,
            },
        ],
    },
    {
        label: 'L 13/14',
        checkedBatteriesNumber: 0,
        batteries: [
            {
                text: 'Verbal',
                id: 25,
                checked: false,
            },
            {
                text: 'Alt Verbal',
                id: 2,
                checked: false,
            },
            {
                text: 'Quantitative',
                id: 26,
                checked: false,
            },
            {
                text: 'Nonverbal',
                id: 27,
                checked: false,
            },
        ],
    },
    {
        label: 'L 15/16',
        checkedBatteriesNumber: 0,
        batteries: [
            {
                text: 'Verbal',
                id: 28,
                checked: false,
            },
            {
                text: 'Alt Verbal',
                id: 2,
                checked: false,
            },
            {
                text: 'Quantitative',
                id: 29,
                checked: false,
            },
            {
                text: 'Nonverbal',
                id: 30,
                checked: false,
            },
        ],
    },
    {
        label: 'L 17/18',
        checkedBatteriesNumber: 0,
        batteries: [
            {
                text: 'Verbal',
                id: 31,
                checked: false,
            },
            {
                text: 'Alt Verbal',
                id: 2,
                checked: false,
            },
            {
                text: 'Quantitative',
                id: 32,
                checked: false,
            },
            {
                text: 'Nonverbal',
                id: 33,
                checked: false,
            },
        ],
    },
];
*/

const optionsScreeningPostScreenerLowerLevel = {
  screening: {
    text: 'Screening',
    checked: false,
    tooltip:
      'Verbal: Picture Analogies, <br>Quantitative: Number Analogies, <br>Nonverbal: Figure Matrices',
    batteries: [
      {
        text: 'Verbal',
        checked: true,
        subtests: 'Picture Analogies',
      },
      {
        text: 'Quantitative',
        checked: true,
        subtests: 'Number Analogies',
      },
      {
        text: 'Nonverbal',
        checked: true,
        subtests: 'Figure Matrices',
      },
    ],
  },
  altScreening: {},
  postScreener: {
    text: 'Post-Screener',
    checked: false,
    tooltip:
      'Verbal: Sentence Completion, Picture Classification, <br>Quantitative: Number Puzzles, Number Series, <br>Nonverbal: Paper Folding, Figure Classification',
    batteries: [
      {
        text: 'Verbal',
        checked: true,
        subtests: 'Picture Classification, Sentence Completion',
      },
      {
        text: 'Quantitative',
        checked: true,
        subtests: 'Number Series, Number Puzzles',
      },
      {
        text: 'Nonverbal',
        checked: true,
        subtests: 'Figure Classification, Paper Folding',
      },
    ],
  },
  altPostScreener: {
    text: 'Alt Post-Screener',
    checked: false,
    tooltip:
      'Verbal: Picture Classification, <br>Quantitative: Number Puzzles, Number Series, <br>Nonverbal: Paper Folding, Figure Classification',
    batteries: [
      {
        text: 'Verbal',
        checked: true,
        subtests: 'Picture Classification',
      },
      {
        text: 'Quantitative',
        checked: true,
        subtests: 'Number Series, Number Puzzles',
      },
      {
        text: 'Nonverbal',
        checked: true,
        subtests: 'Figure Classification, Paper Folding',
      },
    ],
  },
};

const optionsScreeningPostScreenerUpperLevel = {
  screening: {
    text: 'Screening',
    checked: false,
    tooltip:
      'Verbal: Verbal Analogies, <br>Quantitative: Number Analogies, <br>Nonverbal: Figure Matrices',
    batteries: [
      {
        text: 'Verbal',
        checked: true,
        subtests: 'Verbal Analogies',
      },
      {
        text: 'Quantitative',
        checked: true,
        subtests: 'Number Analogies',
      },
      {
        text: 'Nonverbal',
        checked: true,
        subtests: 'Figure Matrices',
      },
    ],
  },
  altScreening: {
    text: 'Alt Screening',
    checked: false,
    tooltip: 'Quantitative: Number Analogies, <br>Nonverbal: Figure Matrices',
    batteries: [
      {
        text: 'Quantitative',
        checked: true,
        subtests: 'Number Analogies',
      },
      {
        text: 'Nonverbal',
        checked: true,
        subtests: 'Figure Matrices',
      },
    ],
  },
  postScreener: {
    text: 'Post-Screener',
    checked: false,
    tooltip:
      'Verbal: Sentence Completion, Verbal Classification, <br>Quantitative: Number Puzzles, Number Series, <br>Nonverbal: Paper Folding, Figure Classification',
    tooltip2:
      'Quantitative: Number Puzzles, Number Series, <br>Nonverbal: Paper Folding, Figure Classification',
    batteries: [
      {
        text: 'Verbal',
        checked: false,
        optional: true,
        subtests: 'Verbal Analogies, Verbal Classification, Sentence Completion',
        //subtests: "Verbal Classification, Sentence Completion",
        tooltip: 'Verbal: Verbal Analogies, Sentence Completion, Verbal Classification',
      },
      {
        text: 'Quantitative',
        checked: true,
        subtests: 'Number Series, Number Puzzles',
      },
      {
        text: 'Nonverbal',
        checked: true,
        subtests: 'Figure Classification, Paper Folding',
      },
    ],
  },
  altPostScreener: {},
};

const tabsCogatScreeningPostScreener = [
  {
    label: 'L 5/6',
    checkedBatteriesNumber: 0,
    batteries: [],
    options: optionsScreeningPostScreenerLowerLevel,
  },
  {
    label: 'L 7',
    checkedBatteriesNumber: 0,
    batteries: [],
    options: optionsScreeningPostScreenerLowerLevel,
  },
  {
    label: 'L 8',
    checkedBatteriesNumber: 0,
    batteries: [],
    options: optionsScreeningPostScreenerLowerLevel,
  },
  {
    label: 'L 9',
    checkedBatteriesNumber: 0,
    batteries: [],
    options: optionsScreeningPostScreenerUpperLevel,
  },
  {
    label: 'L 10',
    checkedBatteriesNumber: 0,
    batteries: [],
    options: optionsScreeningPostScreenerUpperLevel,
  },
  {
    label: 'L 11',
    checkedBatteriesNumber: 0,
    batteries: [],
    options: optionsScreeningPostScreenerUpperLevel,
  },
  {
    label: 'L 12',
    checkedBatteriesNumber: 0,
    batteries: [],
    options: optionsScreeningPostScreenerUpperLevel,
  },
  {
    label: 'L 13/14',
    checkedBatteriesNumber: 0,
    batteries: [],
    options: optionsScreeningPostScreenerUpperLevel,
  },
  {
    label: 'L 15/16',
    checkedBatteriesNumber: 0,
    batteries: [],
    options: optionsScreeningPostScreenerUpperLevel,
  },
  {
    label: 'L 17/18',
    checkedBatteriesNumber: 0,
    batteries: [],
    options: optionsScreeningPostScreenerUpperLevel,
  },
];

export const tabsCogatCompleteForm7and8: any = JSON.parse(JSON.stringify(tabsCogatGrades));
for (let i = 0; i < tabsCogatCompleteForm7and8.length; i++) {
  //tabsCogatCompleteForm7and8[i].levels = tabsCogatLevels.slice(0);
  tabsCogatCompleteForm7and8[i].levels = JSON.parse(JSON.stringify(tabsCogatLevels));
}

export const tabsCogatScreeningFormOther: any = JSON.parse(JSON.stringify(tabsCogatGrades));
for (let i = 0; i < tabsCogatScreeningFormOther.length; i++) {
  //tabsCogatScreeningFormOther[i].levels = JSON.parse(JSON.stringify(tabsCogatLevels2));
  tabsCogatScreeningFormOther[i].levels = JSON.parse(
    JSON.stringify(tabsCogatScreeningPostScreener),
  );
}
