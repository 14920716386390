import { AppReduxAction } from '../../appDataTypes';

export interface StudentProctorInfoState {
  isOnline: boolean;
}

const initState: StudentProctorInfoState = {
  isOnline: false,
};

const studentProctorInfoReducer = (
  state = initState,
  action: AppReduxAction,
): StudentProctorInfoState => {
  switch (action.type) {
    case 'SET_PROCTOR_ONLINE_STATUS':
      return {
        ...state,
        isOnline: action.payload,
      };
    default:
      return state;
  }
};

export default studentProctorInfoReducer;
