import {
  // TestAssignment,
  //   AssignmentListItem,
  //   GradeDomainType,
  //   EditAssignmentType,
  //   CogatLevelType,
  //   LevelOptions,
  // LevelOptionsPostScreening,
  //   LevelOptionsBattery,
  ITextDomainAcronyms,
  ITextSubTestAcronyms,
  ITextBatteryAcronyms,
  //   IIowaFlexDomainInfo,
  //   IowaFlexBatteryType,
  //   IIowaFlexAssignmentInfo,
  DefaultLevelsByGradeType,
  OutOfGradeLevelTestingLevelType,
} from './TestAssignment.d';

export const textDomainAcronyms: ITextDomainAcronyms = {
  NA: 'All Domains',
  OA: 'Operations & Algebraic Thinking',
  NB: 'Number & Operations - Base Ten',
  MD: 'Measurement and Data',
  G: 'Geometry',
  NF: 'Number & Operations - Fractions',
  RP: 'Ratios & Proportional Relationships',
  NS: 'The Number System',
  EE: 'Expressions & Equations',
  SP: 'Statistics & Probability',
  F: 'Functions',
  KID: 'Key Ideas and Details',
  CS: 'Craft and Structure',
  IKI: 'Integration of Knowledge & Ideas',
};

export const textSubTestAcronyms: ITextSubTestAcronyms = {
  PA: 'Picture Analogies',
  SC: 'Sentence Completion',
  PC: 'Picture Classification',
  NA: 'Number Analogies',
  NP: 'Number Puzzles',
  NS: 'Number Series',
  FM: 'Figure Matrices',
  PF: 'Paper Folding',
  FC: 'Figure Classification',
  VA: 'Verbal Analogies',
  VC: 'Verbal Classification',
};

export const numberSubTestAcronyms: ITextSubTestAcronyms = {
  PA: '1',
  SC: '2',
  PC: '3',
  NA: '4',
  NP: '5',
  NS: '6',
  FM: '7',
  PF: '8',
  FC: '9',
  VA: '1',
  VC: '3',
};

export const textBatteryAcronyms: ITextBatteryAcronyms = {
  V: 'Verbal',
  AV: 'Alt Verbal',
  Q: 'Quantitative',
  N: 'Nonverbal',
};

export const defaultLevelByGrade: DefaultLevelsByGradeType = {
  'G K': ['L 5/6', 'L 5/6', 'L 7'],
  'G 1': ['L 7', 'L 7', 'L 7'],
  'G 2': ['L 8', 'L 8', 'L 8'],
  'G 3': ['L 9', 'L 9', 'L 9'],
  'G 4': ['L 10', 'L 10', 'L 10'],
  'G 5': ['L 11', 'L 11', 'L 11'],
  'G 6': ['L 12', 'L 12', 'L 12'],
  'G 7': ['L 13/14', 'L 13/14', 'L 13/14'],
  'G 8': ['L 13/14', 'L 13/14', 'L 13/14'],
  'G 9': ['L 15/16', 'L 15/16', 'L 15/16'],
  'G 10': ['L 15/16', 'L 15/16', 'L 15/16'],
  'G 11': ['L 17/18', 'L 17/18', 'L 17/18'],
  'G 12': ['L 17/18', 'L 17/18', 'L 17/18'],
};

export const outOfGradeLevelTestingLevels: OutOfGradeLevelTestingLevelType[] = [
  {
    grade: 'G K',
    levels: ['L 5/6', 'L 7', 'L 8'],
  },
  {
    grade: 'G 1',
    levels: ['L 5/6', 'L 7', 'L 8', 'L 9'],
  },
  {
    grade: 'G 2',
    levels: ['L 5/6', 'L 7', 'L 8', 'L 9', 'L 10'],
  },
  {
    grade: 'G 3',
    levels: ['L 7', 'L 8', 'L 9', 'L 10', 'L 11'],
  },
  {
    grade: 'G 4',
    levels: ['L 8', 'L 9', 'L 10', 'L 11', 'L 12'],
  },
  {
    grade: 'G 5',
    levels: ['L 9', 'L 10', 'L 11', 'L 12', 'L 13/14'],
  },
  {
    grade: 'G 6',
    levels: ['L 10', 'L 11', 'L 12', 'L 13/14'],
  },
  {
    grade: 'G 7',
    levels: ['L 11', 'L 12', 'L 13/14', 'L 15/16'],
  },
  {
    grade: 'G 8',
    levels: ['L 12', 'L 13/14', 'L 15/16'],
  },
  {
    grade: 'G 9',
    levels: ['L 13/14', 'L 15/16', 'L 17/18'],
  },
  {
    grade: 'G 10',
    levels: ['L 13/14', 'L 15/16', 'L 17/18'],
  },
  {
    grade: 'G 11',
    levels: ['L 15/16', 'L 17/18'],
  },
  {
    grade: 'G 12',
    levels: ['L 15/16', 'L 17/18'],
  },
];

export const arrLevels: string[] = [
  'L 5/6',
  'L 7',
  'L 8',
  'L 9',
  'L 10',
  'L 11',
  'L 12',
  'L 13/14',
  'L 15/16',
  'L 17/18',
];
