import React, { useState, useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';

import { AppNotification } from '../../../reducers/appDataTypes';

import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';

interface AppNotificationState {
  removeTimeoutRef: null | ReturnType<typeof window.setTimeout>;
  tickTimeoutRef: null | ReturnType<typeof window.setTimeout>;
  firstChange: number;
  pauseStart: number;
  pauseDuration: number;
}

interface AppNotificationProps {
  notification: AppNotification;
}

const AppNotificationComponent: React.FunctionComponent<AppNotificationProps> = (
  props: AppNotificationProps,
) => {
  const dispatch = useDispatch();

  const stateRef = useRef<AppNotificationState>({
    tickTimeoutRef: null,
    removeTimeoutRef: null,
    firstChange: 0,
    pauseStart: 0,
    pauseDuration: 0,
  });

  const closeButton = useRef<HTMLButtonElement | null>(null);

  const [isHidden, setIsHidden] = useState(true);
  const [isPaused, setIsPaused] = useState(true);

  const [duration /*, setDuration*/] = useState(
    props.notification.sticky ? 0 : props.notification.duration,
  );
  const [progressPercent, setProgressPercent] = useState(100);

  const removeNotification = () => {
    dispatch({ type: 'REMOVE_APP_NOTIFICATION', payload: props.notification.uuid });
  };

  const handleRemoveClick = () => {
    setIsHidden(true);
    stopTicking();
    if (stateRef.current.removeTimeoutRef !== null) {
      clearTimeout(stateRef.current.removeTimeoutRef);
    }
    stateRef.current.removeTimeoutRef = setTimeout(removeNotification, 400);
  };

  const calculateProgressPercent = (): number => {
    if (!duration) {
      return 100;
    }
    const elapsed =
      new Date().getTime() - stateRef.current.firstChange - stateRef.current.pauseDuration;
    const percent = 100 - Math.ceil((elapsed / duration) * 100);
    return percent;
  };

  const stopTicking = () => {
    if (stateRef.current.tickTimeoutRef !== null) {
      clearTimeout(stateRef.current.tickTimeoutRef);
      stateRef.current.tickTimeoutRef = null;
    }
  };

  const tick = () => {
    stopTicking();
    if (!isPaused && duration) {
      const percent = calculateProgressPercent();
      if (percent <= 0) {
        handleRemoveClick();
      } else {
        if (percent !== progressPercent) {
          setProgressPercent(percent);
        } else if (percent > 0) {
          stateRef.current.tickTimeoutRef = setTimeout(() => {
            tick();
          }, 100);
        }
      }
    }
  };

  const handleMouseLeave = (event: React.SyntheticEvent) => {
    setIsPaused(false);
  };

  const handleMouseEnter = (event: React.SyntheticEvent) => {
    setIsPaused(true);
  };

  useEffect(() => {
    stateRef.current.pauseDuration = 0;
    stateRef.current.pauseStart = 0;
    stateRef.current.firstChange = new Date().getTime();
    setIsHidden(false);
    setIsPaused(false);
    return () => {
      stopTicking();
    };
  }, []);

  useEffect(() => {
    if (!isHidden) {
      if (closeButton.current !== null && closeButton.current.focus) {
        closeButton.current.focus();
      }
    }
  }, [isHidden]);

  useEffect(() => {
    if (progressPercent > 0 && !isPaused && stateRef.current.firstChange) {
      tick();
    }
  }, [progressPercent]);

  useEffect(() => {
    if (progressPercent <= 0 && duration !== 0) {
      handleRemoveClick();
    }
  }, [progressPercent]);

  useEffect(() => {
    if (isPaused && duration > 0) {
      stopTicking();
      stateRef.current.pauseStart = new Date().getTime();
    } else if (!isPaused && duration > 0) {
      stateRef.current.pauseDuration =
        stateRef.current.pauseDuration + (new Date().getTime() - stateRef.current.pauseStart);
      stateRef.current.pauseStart = 0;
      tick();
    }
  }, [isPaused]);

  return (
    <Collapse timeout={350} in={!isHidden}>
      <div
        className={`app-notification app-notification-${props.notification.type}${
          duration === 0 ? ' persistent-app-notification' : ''
        }`}
        role={duration > 0 ? 'alert' : 'alertdialog'}
        aria-atomic={false}
        id={`${props.notification.uuid}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="app-notification-icon">
          {props.notification.icon || (
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
              <circle cx="20" cy="20" r="20" />
              <g transform="translate(7.175 7.14)">
                <path d="M13,26A13,13,0,1,1,26,13,13.038,13.038,0,0,1,13,26ZM13,6.5a1.535,1.535,0,0,0-1.625,1.625A1.535,1.535,0,0,0,13,9.75a1.535,1.535,0,0,0,1.625-1.625A1.535,1.535,0,0,0,13,6.5Zm1.625,4.875h-3.25V19.5h3.25Z" />
              </g>
            </svg>
          )}
        </div>
        <div className="app-notification-message">{props.notification.message}</div>
        <div className="app-notification-tools">
          {!props.notification.sticky && (
            <button
              tabIndex={0}
              ref={closeButton}
              className="icon-button close-notification-button"
              onClick={handleRemoveClick}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                <g transform="translate(-162 -168)">
                  <path
                    d="M21.5,8.91,20.09,7.5,14.5,13.09,8.91,7.5,7.5,8.91l5.59,5.59L7.5,20.09,8.91,21.5l5.59-5.59,5.59,5.59,1.41-1.41L15.91,14.5Z"
                    transform="translate(154.5 160.5)"
                  />
                </g>
              </svg>
            </button>
          )}
          {duration > 0 && (
            <CircularProgress
              role="progress"
              variant="determinate"
              className="app-notification-timeout-progress"
              size="2.4em"
              value={progressPercent}
            />
          )}
        </div>
      </div>
    </Collapse>
  );
};

export default AppNotificationComponent;
