import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  // useRef,
} from 'react';

import { useDispatch } from 'react-redux';

import { useLocation, useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import BugReportIcon from '@mui/icons-material/BugReport';

import { ElevateHeader } from '@riversideinsights/elevate-react-lib';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import AppHelper from '../../../lib/helper/AppHelper';
import UserHelper from '../../../lib/helper/UserHelper';

import useAppLogger from '../../../hooks/useAppLogger';
import useAppStatus from '../../../hooks/useAppStatus';
import useApiHelper from '../../../hooks/useApiHelper';
import useCachedUser from '../../../hooks/useCachedUser';
import useNotificationHelper from '../../../hooks/useNotificationHelper';

import { ModalDialog } from '@riversideinsights/elevate-react-lib';

import { getElevateMenu } from './ElevateMenu';

import TermsAndPrivacyAccept from '../TermsAndPrivacyAccept/TermsAndPrivacyAccept';
import './AppHeader.css';
import useRosteringFlag from '../../../flags/useRosteringFlag/useRosteringFlag';
import { BooleanFlags, useBooleanFlagWithConfig } from '../../../flags';

export interface AppHeaderProps {
  prependContent?:
    | string
    | React.ReactNode
    | React.ReactNode[]
    | React.ReactElement
    | React.ReactElement[]
    | Element
    | Element[];
}

const AppHeader: React.FC<AppHeaderProps> = (props) => {
  const config = useConfigContext();
  const appStatus = useAppStatus();
  const NotificationHelper = useNotificationHelper();
  const logger = useAppLogger('COMPONENT|AppHeader');
  const user = useCachedUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const removeToolbarTimeout = useRef<null | ReturnType<typeof window.setTimeout>>(null);
  const ApiHelper = useApiHelper('COMPONENT|AppHeader');
  const { menuNavigationVisible: rosteringMenuVisible } = useRosteringFlag();

  const [menuVisible, setMenuVisible] = useState(false);

  const [displayTPModal, setDisplayTPModal] = useState(false);
  const [savingTPStatus, setSavingTPStatus] = useState(false);
  const [savingTPMessage, setSavingTPMessage] = useState('Please Wait');
  const [savingTPError, setSavingTPError] = useState('');
  const { isEnabled: testingIsDisabled } = useBooleanFlagWithConfig(BooleanFlags.DisableTesting);

  const setDebugVisible = (visible: boolean) => {
    dispatch({ type: 'SET_DEBUG_VISIBLE', payload: visible });
  };

  const goToPath = (pathname: string) => {
    history.push(pathname);
  };

  const noHeaderPage = useMemo(() => {
    let result = false;
    const pathname = location.pathname.replace(/^\//, '');
    const pathChunks = pathname.split('/');

    if (
      pathChunks &&
      pathChunks.length &&
      config.noHeaderRootPathnames.indexOf(pathChunks[1]) >= 0
    ) {
      result = true;
    }
    return result;
  }, [location.pathname]);

  const toggleMenuVisible = () => {
    setMenuVisible(!menuVisible);
  };

  const declineTerms = () => {
    closeTermsModal();
    goToLogout();
  };

  const goToLogout = () => {
    history.push('/administration/auth/logout');
  };

  const closeTermsModal = () => {
    setDisplayTPModal(false);
    dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
  };

  const acceptTermsAndPrivacy = async (): Promise<boolean> => {
    setSavingTPStatus(true);
    setSavingTPError('Saving');
    const requestData = {
      userId: '' + user.userId,
      termsOfUseAccepted: true,
      privacyPolicyAccepted: true,
    };
    const res = await ApiHelper.apiRequest(
      'profile',
      'SaveTermsOfUseAndPrivacyPolicy',
      requestData,
    );
    // const res = { status: 202 };
    if (res && res.status === 200) {
      logger.debug('User accepted Terms and Privacy, closing the modal');
      const newUser = {
        ...user,
        // loggedIn: true,
        privacyPolicyAccepted: true,
        termsOfUseAccepted: true,
      };
      await UserHelper.loadUser(config, newUser, dispatch, true);
      UserHelper.updateUser(
        config,
        {
          // loggedIn: true,
          loadComplete: true,
          privacyPolicyAccepted: true,
          termsOfUseAccepted: true,
        },
        dispatch,
      );
      // dispatch({type: 'SET_STAFF_USER', payload: newUser});
      setSavingTPError('');
      setSavingTPStatus(false);
      closeTermsModal();
      NotificationHelper.add(
        'You have accepted the Terms and Conditions and Privacy Policy',
        'info',
      );
    } else {
      logger.error('API/network error encountered while trying to accept terms');
      setSavingTPError('We could not process your request. Please try again.');
      setSavingTPStatus(false);
    }
    return true;
  };

  const declineTermsAndPrivacy = async () => {
    NotificationHelper.add(
      'You have to accept the Terms and Conditions and Privacy Policy',
      'warning',
      0,
      undefined,
      false,
      true,
      undefined,
    );
    setSavingTPMessage('Please wait, logging you out');
    setSavingTPStatus(true);
    logger.debug('User declined Terms and Privacy, logout in progress');
    // await AppHelper.logoutStaffUser(dispatch);
    // await wait(1000);
    setSavingTPStatus(false);
    setSavingTPMessage('Please wait');
    closeTermsModal();
    goToLogout();
  };

  useEffect(() => {
    return () => {
      clearToolbarTimeout();
    };
  }, []);

  useEffect(() => {
    if (menuVisible && (appStatus.appBusy || appStatus.overlayVisible)) {
      toggleMenuVisible();
    }
  }, [appStatus.overlayVisible, appStatus.appBusy]);

  useEffect(() => {
    if (menuVisible) {
      toggleMenuVisible();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (user.loadComplete) {
      if (!noHeaderPage) {
        if (!(user.termsOfUseAccepted && user.privacyPolicyAccepted)) {
          logger.debug('User has not accepted Terms and Privacy, showing the modal');
          setDisplayTPModal(true);
        }
      }
    }
  }, [
    user.loadComplete,
    user.termsOfUseAccepted,
    user.privacyPolicyAccepted,
    location.pathname,
    noHeaderPage,
  ]);

  useEffect(() => {
    if (!savingTPStatus && displayTPModal) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="terms-privacy-modal"
            disableClose={true}
            disableCloseOnEsc={true}
            closeOnMaskClick={false}
            focusSelector=".accept-terms-button"
            header={
              <div className="modal-dialog-top">
                <h2 className="modal-dialog-title" id="terms-privacy-modal-title">
                  Riverside Insights Terms of Use and Privacy Policy
                </h2>
              </div>
            }
            footer={
              <div className="terms-privacy-modal-footer">
                <div className="terms-modal-footnote" id="termsFootnote_01">
                  <span className="footnote-index">[1]</span>
                  <span className="footnote-text">
                    If you are a BDI-3 customer and have licensed a BDI-3 subscription, for example,
                    you will be limited to 3 users per subscription license. Each user must consent
                    to and abide by these Terms of Use.
                  </span>
                </div>
                {!savingTPError && (
                  <div className="terms-modal-buttons">
                    <Button
                      className="decline-terms-button"
                      onClick={declineTermsAndPrivacy}
                      variant="outlined"
                    >
                      Decline
                    </Button>

                    <Button
                      className="accept-terms-button"
                      onClick={acceptTermsAndPrivacy}
                      variant="contained"
                      color="primary"
                    >
                      Accept
                    </Button>
                  </div>
                )}
                {savingTPError && (
                  <div className="terms-modal-buttons">
                    <span className="terms-privacy-footer-message">
                      {!savingTPStatus && `${savingTPError}`}
                    </span>
                    <Button
                      className="accept-terms-button"
                      onClick={declineTerms}
                      variant="contained"
                      color="primary"
                    >
                      Logout
                    </Button>
                  </div>
                )}
              </div>
            }
            body={<TermsAndPrivacyAccept />}
            onClose={closeTermsModal}
          />
        ),
      });
    } else if (savingTPStatus && displayTPModal) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="terms-privacy-modal"
            disableClose={true}
            disableCloseOnEsc={true}
            closeOnMaskClick={false}
            header={
              <div className="modal-dialog-top">
                <h2 className="modal-dialog-title" id="terms-privacy-modal-title">
                  Riverside Insights Terms of Use and Privacy Policy
                </h2>
              </div>
            }
            footer={
              <div className="terms-privacy-modal-footer">
                <div className="terms-modal-footnote" id="termsFootnote_01">
                  <span className="footnote-index">[1]</span>
                  <span className="footnote-text">
                    If you are a BDI-3 customer and have licensed a BDI-3 subscription, for example,
                    you will be limited to 3 users per subscription license. Each user must consent
                    to and abide by these Terms of Use.
                  </span>
                </div>
                <div className="terms-modal-buttons">
                  <span className="terms-privacy-footer-message">
                    {savingTPStatus && `${savingTPMessage}`}
                  </span>
                  <CircularProgress
                    variant="indeterminate"
                    size="1.5em"
                    className="light-mui-progress"
                  />
                </div>
              </div>
            }
            body={<TermsAndPrivacyAccept />}
            onClose={closeTermsModal}
          />
        ),
      });
    }
  }, [displayTPModal, savingTPStatus, user.loadComplete]);

  useEffect(() => {
    if (!appStatus.headerToolbarVisible && appStatus.headerToolbar) {
      removeToolbarTimeout.current = setTimeout(() => {
        dispatch({ type: 'SET_HEADER_TOOLBAR', payload: null });
      }, 400);
    } else if (appStatus.headerToolbarVisible && removeToolbarTimeout.current !== null) {
      clearToolbarTimeout();
      // clearTimeout(removeToolbarTimeout.current);
      // removeToolbarTimeout.current = null;
    }
  }, [appStatus.headerToolbarVisible]);

  const clearToolbarTimeout = () => {
    if (removeToolbarTimeout.current !== null) {
      clearTimeout(removeToolbarTimeout.current);
      removeToolbarTimeout.current = null;
    }
  };

  const rightMenuItems = useMemo<
    | React.ReactNode
    | React.ReactNode[]
    | React.ReactElement
    | React.ReactElement[]
    | Element
    | Element[]
  >(() => {
    const items:
      | React.ReactNode
      | React.ReactNode[]
      | React.ReactElement
      | React.ReactElement[]
      | Element
      | Element[] = [];
    if (user.loggedIn) {
      const debugAllowed = AppHelper.getCookieValue('elevateDebugEnabled') ? true : config.local;
      if (debugAllowed) {
        Array.prototype.push.call(
          items,
          <MenuItem
            className="right-menu-entry autohide-on-click"
            key="toggledg"
            title={`${appStatus.debugVisible ? 'Hide' : 'Show'} Debug`}
            onClick={() => {
              setDebugVisible(!appStatus.debugVisible);
            }}
          >
            <span className="right-menu-entry-text show-debug-entry-text">
              {appStatus.debugVisible ? 'Hide' : 'Show'} Debug
            </span>
            <span className="right-menu-entry-icon show-debug-entry-icon">
              <BugReportIcon />
            </span>
          </MenuItem>,
        );
      }
      if (user.canChangeRole) {
        Array.prototype.push.call(
          items,
          <MenuItem
            className="right-menu-entry autohide-on-click"
            title="Update Role"
            key="updateRole"
            onClick={() => goToPath('/administration/user/dashboard/roles')}
          >
            <span className="right-menu-entry-text">Update Role</span>
            <span className="right-menu-entry-icon">
              <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M22.059,13.488a4.239,4.239,0,0,0,0-1.539l.932-.538a.262.262,0,0,0,.119-.307,5.422,5.422,0,0,0-1.2-2.074.264.264,0,0,0-.325-.051l-.932.538a4.242,4.242,0,0,0-1.333-.77V7.671a.263.263,0,0,0-.206-.257,5.452,5.452,0,0,0-2.392,0,.263.263,0,0,0-.206.257V8.748a4.242,4.242,0,0,0-1.333.77l-.932-.538a.264.264,0,0,0-.325.051,5.422,5.422,0,0,0-1.2,2.074.266.266,0,0,0,.119.307l.932.538a4.239,4.239,0,0,0,0,1.539l-.932.538a.262.262,0,0,0-.119.307,5.449,5.449,0,0,0,1.2,2.074.264.264,0,0,0,.325.051l.932-.538a4.242,4.242,0,0,0,1.333.77v1.077a.263.263,0,0,0,.206.257,5.452,5.452,0,0,0,2.392,0,.263.263,0,0,0,.206-.257V16.69a4.242,4.242,0,0,0,1.333-.77l.932.538a.264.264,0,0,0,.325-.051,5.422,5.422,0,0,0,1.2-2.074.266.266,0,0,0-.119-.307Zm-4.137.983a1.752,1.752,0,1,1,1.752-1.752A1.754,1.754,0,0,1,17.922,14.471ZM8.094,9.25A4.625,4.625,0,1,0,3.469,4.625,4.625,4.625,0,0,0,8.094,9.25Zm7.27,8.184c-.083-.043-.166-.094-.246-.141l-.285.166A1.418,1.418,0,0,1,13.08,17.2a6.589,6.589,0,0,1-1.453-2.515,1.413,1.413,0,0,1,.647-1.651l.285-.166q-.005-.141,0-.282l-.285-.166a1.412,1.412,0,0,1-.647-1.651c.033-.1.079-.21.116-.314-.137-.011-.271-.043-.412-.043h-.6a6.29,6.29,0,0,1-5.268,0h-.6A4.858,4.858,0,0,0,0,15.263v1.5A1.735,1.735,0,0,0,1.734,18.5H14.453a1.732,1.732,0,0,0,.983-.307,1.407,1.407,0,0,1-.072-.426Z" />
              </svg>
            </span>
          </MenuItem>,
        );
      }
      Array.prototype.push.call(
        items,
        <MenuItem
          className="right-menu-entry autohide-on-click"
          key="changeAvatar"
          title="Change Avatar"
          onClick={() => goToPath('/administration/user/dashboard/avatar')}
        >
          <span className="right-menu-entry-text">Change Avatar</span>
          <span className="right-menu-entry-icon">
            <svg xmlns="http://www.w3.org/2000/svg">
              <path d="M9,.562a9,9,0,1,0,9,9A9,9,0,0,0,9,.562ZM9,4.046A3.194,3.194,0,1,1,5.806,7.24,3.194,3.194,0,0,1,9,4.046ZM9,16.53a6.954,6.954,0,0,1-5.317-2.475,4.046,4.046,0,0,1,3.575-2.17.888.888,0,0,1,.258.04A4.8,4.8,0,0,0,9,12.175a4.787,4.787,0,0,0,1.484-.25.888.888,0,0,1,.258-.04,4.046,4.046,0,0,1,3.575,2.17A6.954,6.954,0,0,1,9,16.53Z" />
            </svg>
          </span>
        </MenuItem>,
      );
      Array.prototype.push.call(
        items,
        <MenuItem
          className="right-menu-entry autohide-on-click"
          key="logout"
          title="Logout"
          onClick={goToLogout}
        >
          <span className="right-menu-entry-text">Logout</span>
          <span className="right-menu-entry-icon">
            <svg xmlns="http://www.w3.org/2000/svg">
              <path d="M7.125,0V2.375h9.5V14.25h-9.5v2.375H19V0ZM4.75,4.75,0,8.313l4.75,3.563V9.5h9.5V7.125H4.75Z" />
            </svg>
          </span>
        </MenuItem>,
      );
    }
    return items;
  }, [
    user.canChangeRole,
    user.role,
    user.loggedIn,
    user.loadComplete,
    appStatus.loadingUser,
    appStatus.headerVisible,
    appStatus.debugVisible,
  ]);

  const headerContents = (
    <React.Fragment>
      <div key="headerTitle" className="app-header-section app-header-title">
        {typeof props.prependContent !== 'undefined' ? props.prependContent : ''}
        {!appStatus?.pageTitle && <span className="app-header-title-section">Elevate</span>}
        {appStatus?.pageTitle && (
          <span className="app-header-title-section" title={appStatus.pageTitle}>
            {appStatus.pageTitle}
          </span>
        )}
        {!(user.loggedIn && user?.customerId) && (
          <span className="app-header-title-section">Loading</span>
        )}
        {user.loggedIn && user?.districtName && (
          <span
            className="app-header-title-section app-header-district-name"
            title={`Customer ${user?.districtName}`}
          >
            {user?.districtName}
          </span>
        )}
      </div>

      <div key="headerCenter" className="app-header-center-contents">
        {appStatus.headerCenterContents ? appStatus.headerCenterContents : ''}
      </div>
    </React.Fragment>
  );

  return (
    <ElevateHeader
      user={user}
      elevateEnv={config.appEnvironment}
      appBusy={appStatus.appBusy}
      rightMenuItems={rightMenuItems}
      appMenuProps={{
        navItems: getElevateMenu({
          hideCreateTestAssignmentNavigation: testingIsDisabled,
          hideProctoringNavigation: testingIsDisabled,
          hideRosteringNavigation: !rosteringMenuVisible,
        }),
      }}
      headerVisible={appStatus.headerVisible}
      noHeaderPage={noHeaderPage}
      toolbar={appStatus.headerToolbar}
      toolbarVisible={appStatus.headerToolbarVisible}
    >
      {headerContents}
    </ElevateHeader>
  );
};

export default AppHeader;
