import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/appDataTypes';
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import SessionLogoutTimer from './SessionTimeoutModal/SessionLogoutTimer';
import { useHistory } from 'react-router';
import useAppLogger from '../../../hooks/useAppLogger';
import { ModalDialog } from '@riversideinsights/elevate-react-lib';
import { Button } from '@mui/material';
import './SessionTimeoutTimer.css';

const SessionTimeoutTimer = () => {
  const config = useConfigContext();
  const logger = useAppLogger('COMPONENT|SessionTimeoutTimer');
  const dispatch = useDispatch();
  const history = useHistory();
  const studentUser = useSelector((state: AppState) => {
    return state.auth.studentAuth.user;
  });
  const staffUser = useSelector((state: AppState) => {
    return state.auth.staffAuth.user;
  });
  const userLoggedIn = useRef<boolean>(
    staffUser.loggedIn === true || studentUser.loggedIn === true,
  );
  const openInactivityModal = useRef<boolean>(false);
  const checkInterval = useRef<null | ReturnType<typeof window.setTimeout>>(null);
  const [maxInactiveSeconds, setMaxInactiveSeconds] = useState(0);
  const [maxModalSeconds, setMaxModalSeconds] = useState(0);
  const [modalTimeLeft, setModalTimeLeft] = useState(-1);

  useEffect(() => {
    userLoggedIn.current = staffUser.loggedIn === true || studentUser.loggedIn === true;
  }, [staffUser.loggedIn, studentUser.loggedIn]);

  useEffect(() => {
    return () => {
      removeEventListeners();
    };
  }, []);

  useEffect(() => {
    if (userLoggedIn.current === true) {
      // once per login (or full app (re)load while logged in)
      let newMaxLogoutSeconds = 0;
      let newMaxModalSeconds = 0;
      if (studentUser.loggedIn) {
        newMaxLogoutSeconds = config.session.student.inactivityLogoutMinutes * 60;
        newMaxModalSeconds = config.session.student.inactivityModalMinutes * 60;
      } else {
        newMaxLogoutSeconds = config.session.staff.inactivityLogoutMinutes * 60;
        newMaxModalSeconds = config.session.staff.inactivityModalMinutes * 60;
      }
      setModalTimeLeft(newMaxModalSeconds);
      setMaxInactiveSeconds(newMaxLogoutSeconds);
      setMaxModalSeconds(newMaxModalSeconds);
    } else {
      // reset in case user logged out
      setModalTimeLeft(-1);
      setMaxInactiveSeconds(0);
      setMaxModalSeconds(0);
    }
  }, [staffUser.loggedIn, studentUser.loggedIn]);

  useEffect(() => {
    if (maxModalSeconds > 0 && maxInactiveSeconds > 0) {
      const diff = secondsSinceLastActivity();
      logger.debug(
        `Checking for idle session timeout: ${diff}s idle / ${
          maxInactiveSeconds + maxModalSeconds
        }s max`,
      );
      if (diff >= maxInactiveSeconds + maxModalSeconds && userLoggedIn.current) {
        executePopupLogout();
      } else {
        logger.debug(
          `Starting inactivity timer: (${maxInactiveSeconds + maxModalSeconds} seconds)`,
        );
        refreshActive();
        startCheckInterval();
        addEventListeners();
      }
    }
  }, [maxInactiveSeconds, maxModalSeconds]);

  const declinePopupLogout = () => {
    openInactivityModal.current = false;
    dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    refreshActive();
  };

  const executePopupLogout = () => {
    if (userLoggedIn.current) {
      logger.info('Session timeout, redirecting to login');
      openInactivityModal.current = false;
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
      history.push(
        staffUser.loggedIn ? '/administration/auth/logout' : '/administration/auth/logout/student',
      );
      clearCheckInterval();
      removeEventListeners();
    }
  };

  useEffect(() => {
    if (openInactivityModal.current) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="session-timeout-modal"
            disableClose
            disableCloseOnEsc
            closeOnMaskClick={false}
            focusSelector=".accept-inactivity-button"
            header={
              <div className="modal-dialog-top">
                <h2 className="modal-dialog-title">Session time out</h2>
              </div>
            }
            body={
              <div className="session-timeout-modal-body">
                <p>Automatic logout will occur in:</p>
                <SessionLogoutTimer remainingSeconds={modalTimeLeft} />
                <p>Remain logged in, or log out now?</p>
              </div>
            }
            footer={
              <div className="session-timeout-modal-footer">
                <Button
                  className="accept-inactivity-button"
                  onClick={executePopupLogout}
                  variant="outlined"
                  color="primary"
                >
                  Log out now
                </Button>
                <Button onClick={declinePopupLogout} variant="contained" color="primary">
                  Remain logged in
                </Button>
              </div>
            }
          />
        ),
      });
    }
  }, [openInactivityModal.current, modalTimeLeft]);

  const addEventListeners = () => {
    document.addEventListener('mousemove', refreshActive);
    document.addEventListener('keydown', refreshActive);
  };

  const removeEventListeners = () => {
    document.removeEventListener('mousemove', refreshActive);
    document.removeEventListener('keydown', refreshActive);
  };

  const secondsSinceLastActivity = (): number => {
    let lastUserActivity = 0;
    const lastStored = +`${localStorage.getItem(config.session.lastActivityCookie)}`;
    if (lastStored && !isNaN(lastStored) && isFinite(lastStored)) {
      lastUserActivity = lastStored;
    }
    if (lastUserActivity === 0) {
      return 0;
    }
    const currentTs = new Date().getTime();
    return parseInt(`${(currentTs - lastUserActivity) / 1000}`, 10);
  };

  const refreshActive = () => {
    if (!openInactivityModal.current && userLoggedIn.current === true) {
      setActive();
    }
  };

  const clearCheckInterval = () => {
    if (checkInterval.current !== null) {
      clearTimeout(checkInterval.current);
      checkInterval.current = null;
    }
  };

  const startCheckInterval = () => {
    clearCheckInterval();
    if (userLoggedIn.current) {
      checkInterval.current = setTimeout(checkActivity, 1000);
    }
  };

  const checkActivity = () => {
    const diff = secondsSinceLastActivity();
    if (diff > 0 && maxInactiveSeconds > 0 && maxModalSeconds > 0) {
      if (diff >= maxInactiveSeconds && userLoggedIn.current) {
        if (!openInactivityModal.current) {
          openInactivityModal.current = true;
        }
        if (diff < maxInactiveSeconds + maxModalSeconds) {
          setModalTimeLeft(maxInactiveSeconds + maxModalSeconds - diff);
        } else if (diff >= maxInactiveSeconds + maxModalSeconds) {
          executePopupLogout();
        }
      }
    }
    // logger.debug(`checkActivity ${diff} / ${maxInactiveSeconds + maxModalSeconds}`);
    startCheckInterval();
  };

  const setActive = () => {
    const ts = new Date().getTime();
    localStorage.setItem(config.session.lastActivityCookie, `${ts}`);
  };
  return null;
};

export default SessionTimeoutTimer;
