import { AppReduxAction } from '../../appDataTypes';

const initState = {
  startTutorialType: '',
  arrTutorialMenuItemVisible: [],
  //arrTutorialMenuItemVisible: [config.proctoring.tutorial.cogatLowerLevel, config.proctoring.tutorial.cogatUpperLevel],
  //arrTutorialMenuItemVisible: [config.proctoring.tutorial.cogatLowerLevel],
};

const studentTutorialReducer = (state = initState, action: AppReduxAction) => {
  switch (action.type) {
    case 'START_TUTORIAL_TYPE':
      return {
        ...state,
        startTutorialType: action.payload,
      };
    case 'UPDATE_TUTORIAL_LIST':
      return {
        ...state,
        arrTutorialMenuItemVisible: action.payload,
      };
    default:
      return state;
  }
};

export default studentTutorialReducer;
