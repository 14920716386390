export type BooleanFlagState = 'disabled' | 'enabled' | 'control';

export interface BooleanFlagWithConfigResult<T> {
  /**
   * Any configuration for the flag.
   */
  readonly config: T | null;
  /**
   * Whether the flag is enabled, disabled, or control.
   * 'control' means the flag is not enabled and the default behavior should be used.
   */
  readonly enabled: BooleanFlagState;
  /**
   * Whether the flag is enabled or not.
   */
  readonly isEnabled: boolean;
  /**
   * Whether the flag is ready or not.
   */
  readonly isReady: boolean;
}

// Alphabetic order to make it easier to find the flags as this grows.
export enum BooleanFlags {
  CleverSyncAlertModal = 'CleverSync_Alert_Modal',
  CleverSyncCancellingPostFullSync = 'Clever_Sync_Cancelling_Post_Full_Sync',
  DisableTesting = 'Disable_Testing',
  LearnosityLoadTestingMode = 'Learnosity_Load_Testing_Mode',
  LoginAlertBanner = 'Login_Alert_Banner',
  LoginAlertModal = 'Login_Alert_Modal',
  LogRocket_Recording = 'LogRocket_Recording',
  Rostering = 'Rostering',
  StudentLandingCardFilter = 'StudentLanding_Card_Filter',
  StudentTestItemDisabledStateExperimentation = 'Student_Test_Item_Disabled_State_Experimentation',
  StudentTestItemsFetchDebugging = 'Student_Test_Items_Fetch_Debugging',
  StudentTestReloadOnTimeout = 'Student_Test_Reload_On_Timeout',
  TestAssignmentPreventOverlaps = 'Test_Assignment_Prevent_Overlaps',

  // Reserved for unit tests.
  ForTesting = 'ForTesting',
}

export const BooleanFlagsEnabled = new Map<BooleanFlags, string>([
  [BooleanFlags.CleverSyncAlertModal, 'AlertEnabled'],
  [BooleanFlags.CleverSyncCancellingPostFullSync, 'CleverSyncCancellingPostFullSyncEnabled'],
  [BooleanFlags.DisableTesting, 'TestingDisabled'],
  [BooleanFlags.LearnosityLoadTestingMode, 'LearnosityLoadTestingModeOn'],
  [BooleanFlags.LoginAlertBanner, 'AlertEnabled'],
  [BooleanFlags.LoginAlertModal, 'AlertEnabled'],
  [BooleanFlags.LogRocket_Recording, 'RecordingOn'],
  [BooleanFlags.Rostering, 'RosteringEnabled'],
  [BooleanFlags.StudentLandingCardFilter, 'StudentLandingCardFilterEnabled'],
  [BooleanFlags.StudentTestReloadOnTimeout, 'TimeoutModalEnabled'],
  [BooleanFlags.StudentTestItemsFetchDebugging, 'DebuggingEnabled'],
  [
    BooleanFlags.StudentTestItemDisabledStateExperimentation,
    'ItemDisabledStateExperimentationEnabled',
  ],
  [BooleanFlags.TestAssignmentPreventOverlaps, 'TestAssignmentPreventOverlapsEnabled'],

  // Reserved for unit tests.
  [BooleanFlags.ForTesting, 'ForTesting'],
]);
