import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

import useNotificationHelper from '../../../../hooks/useNotificationHelper';
import useCachedUser from '../../../../hooks/useCachedUser';
import useApiHelper from '../../../../hooks/useApiHelper';

import Autocomplete from '@mui/material/Autocomplete';
import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
} from '@mui/material';
import AppApiDebugCommonParams from './AppApiDebugCommonParams';
// import { ApiRequestInstance } from '../../../../lib/helper/ApiHelper.d';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import SpinnerIcon from '../../../shared/icons/LoaderSpinIcon';

import { TbExternalLink, TbCopy, TbBackspace } from 'react-icons/tb';
// import AppApiDebugRequestModal from './AppApiDebugRequestModal';

export interface EndpointData {
  endpointName: string;
  path: string;
  method: string;
  rest: boolean;
  skipAuth: boolean;
}

// export interface ApiDebugCommonValue {
//   name: string;
//   value: string;
// }

const AppDebugApi: React.FC = () => {
  const config = useConfigContext();
  const user = useCachedUser();
  const ApiHelper = useApiHelper('Component|AppDebugApi');

  const apiNameFieldRef = useRef<HTMLDivElement | null>(null);
  const endpointFieldRef = useRef<HTMLDivElement | null>(null);
  const endpointInputRef = useRef<HTMLInputElement | null>(null);
  const pastedUrlInputFieldRef = useRef<HTMLInputElement | null>(null);
  const requestDataFieldRef = useRef<HTMLTextAreaElement | null>(null);

  const NotificationHelper = useNotificationHelper();
  const cancelToken = useRef<CancelTokenSource | null>(null);

  const [apiNameSearch, setApiNameSearch] = useState('');
  const [endpointNameSearch, setEndpointNameSearch] = useState('');

  const [apiNames] = useState<string[]>(Object.keys(config.api.baseUrl).sort());
  const [apiNameIndex, setApiNameIndex] = useState(-1);
  const [endpointNameIndex, setEndpointNameIndex] = useState(-1);

  const [pastedUrl, setPastedUrl] = useState('');
  const [parsingPastedUrl, setParsingPastedUrl] = useState(false);
  const [pastedUrlValid, setPastedUrlValid] = useState(false);

  const [currentRequestParams, setCurrentRequestParams] = useState('');

  const [preventAutoCancel, setPreventAutoCancel] = useState(false);
  const [skipAuth, setSkipAuth] = useState(false);

  const [apiRequestActive, setApiRequestActive] = useState(false);
  const [apiRequestResult, setApiRequestResult] = useState<AxiosResponse | null>(null);

  // const [apiRequestModalVisible, setApiRequestModalVisible] = useState(false);
  // const lastApiRequest = useMemo<ApiRequestInstance|null>(() => {
  //   let value:ApiRequestInstance|null = null;
  //   if (ApiHelper.activeRequests.length > 0) {
  //     value = {...ApiHelper.activeRequests[0]};
  //   // } else if (lastApiRequest !== null) {
  //   //   value = lastApiRequest as ApiRequestInstance|null;
  //   }
  //   return value;
  // }, [ApiHelper.activeRequests.length]);

  const apiName = useMemo<string>(() => {
    if (apiNameIndex >= 0 && apiNames?.length && apiNameIndex < apiNames.length) {
      return `${apiNames[apiNameIndex as keyof typeof apiNames]}`;
    }
    return '';
  }, [apiNames.length, apiNameIndex]);

  // useEffect(() => {
  //   if (endpointInputRef.current !== null) {
  //     endpointInputRef.current.focus();
  //   }
  // }, [endpointInputRef.current]);

  // useEffect(() => {
  //   if (pastedUrlInputFieldRef.current !== null) {
  //     pastedUrlInputFieldRef.current.value = 'https://api.dev.elevate.riverside-insights.com/rostering/roster/usersearch?IsStudent=true&SearchText=a&CustomerId=1109920&DistrictId=107&UserPerformingRole=district_admin&PageNumber=1&PageSize=25&SortingDirection=1&SortingField=8';
  //     pastedUrlInputFieldRef.current.focus();
  //   }
  // }, []);

  useEffect(() => {
    if (requestDataFieldRef.current !== null) {
      requestDataFieldRef.current.value = currentRequestParams;
    }
  }, [currentRequestParams, requestDataFieldRef]);

  useEffect(() => {
    let valid = false;
    if (pastedUrl !== '') {
      try {
        new URL(pastedUrl);
        valid = true;
      } catch (exc: unknown) {
        // console.log(exc);
      }
    }
    setPastedUrlValid(valid);
  }, [pastedUrl]);

  useEffect(() => {
    if (parsingPastedUrl === true) {
      parsePastedUrl();
      setParsingPastedUrl(false);
    }
  }, [parsingPastedUrl]);

  const getApiEndpointsData = (name: string): EndpointData[] => {
    const apiEndpointData: EndpointData[] = [];
    if (
      name !== '' &&
      typeof config.api.endpoints[name as keyof typeof config.api.endpoints] !== 'undefined'
    ) {
      const apiEndpoints = config.api.endpoints[name as keyof typeof config.api.endpoints];
      const newNames = Object.keys(apiEndpoints).map((val) => `${val}`);
      const newCurrentEndpoints = newNames.map((nameOfEndpoint: string) => {
        const ncEp: EndpointData = {
          ...(apiEndpoints[nameOfEndpoint as keyof typeof apiEndpoints] as Partial<EndpointData>),
          endpointName: nameOfEndpoint,
        } as EndpointData;
        return ncEp;
      }) as EndpointData[];
      apiEndpointData.push(...newCurrentEndpoints);
    }
    return apiEndpointData;
  };

  const apiRequestResponseJson = useMemo<string>(() => {
    let json = '';
    if (apiRequestResult !== null) {
      try {
        const jsonData = JSON.stringify(apiRequestResult, null, ' ');
        if (jsonData !== null) {
          json = jsonData;
        }
      } catch (exc: unknown) {
        // console.error(exc);
      }
    }
    return json;
  }, [apiRequestResult]);

  const currentEndpoints = useMemo<EndpointData[]>(() => {
    const endpoints: EndpointData[] = [];
    if (
      apiName !== '' &&
      typeof config.api.endpoints[apiName as keyof typeof config.api.endpoints] !== 'undefined'
    ) {
      const apiEndpoints = config.api.endpoints[apiName as keyof typeof config.api.endpoints];
      const newNames = Object.keys(apiEndpoints).map((val) => `${val}`);
      const newCurrentEndpoints = newNames.map((nameOfEndpoint: string) => {
        const ncEp: EndpointData = {
          ...(apiEndpoints[nameOfEndpoint as keyof typeof apiEndpoints] as Partial<EndpointData>),
          endpointName: nameOfEndpoint,
        } as EndpointData;
        return ncEp;
      }) as EndpointData[];
      endpoints.push(...newCurrentEndpoints);
    }
    return endpoints;
  }, [apiName]);

  const currentEndpointNames = useMemo<string[]>(() => {
    const names: string[] = [];
    if (
      apiName !== '' &&
      typeof config.api.endpoints[apiName as keyof typeof config.api.endpoints] !== 'undefined'
    ) {
      const endpoints = config.api.endpoints[apiName as keyof typeof config.api.endpoints];
      const newNames = Object.keys(endpoints).map((val) => `${val}`);
      names.push(...newNames);
    }
    return names;
  }, [apiName]);

  const endpointName = useMemo<string>(() => {
    if (
      apiName !== '' &&
      endpointNameIndex >= 0 &&
      currentEndpointNames?.length &&
      endpointNameIndex < currentEndpointNames.length
    ) {
      return `${currentEndpointNames[endpointNameIndex as keyof typeof currentEndpointNames]}`;
      // return currentEndpointNames[endpointNameIndex];
    }
    return '';
  }, [currentEndpointNames.length, endpointNameIndex, apiName]);

  const apiBaseUrl = useMemo<string>(() => {
    let url = '';
    if (apiName !== '') {
      url = config.api.baseUrl[apiName as keyof typeof config.api.baseUrl];
    }
    return url;
  }, [apiName]);

  const getApiEndpointPath = (
    nameOfApi: string,
    nameOfEndpoint: string,
    requestDataJson?: string,
  ): string => {
    let apiPath = '';
    if (nameOfApi !== '' && nameOfEndpoint !== '') {
      const endpoints = config.api.endpoints[nameOfApi as keyof typeof config.api.endpoints];
      if (typeof endpoints[nameOfEndpoint as keyof typeof endpoints] === 'object') {
        const endpoint = endpoints[nameOfEndpoint as keyof typeof endpoints] as EndpointData;

        if (endpoint) {
          apiPath = endpoint.path;
        }
      }
    }
    if (apiPath !== '' && typeof requestDataJson === 'string' && requestDataJson) {
    }
    return apiPath;
  };

  const getRequestParamsObj = () => {
    let rd = {};
    try {
      rd = JSON.parse(currentRequestParams);
    } catch (exc: unknown) {
      rd = {};
      // console.log(exc);
    }
    return rd;
  };

  const paramsJsonValid = useMemo<boolean>(() => {
    let result = true;
    if (currentRequestParams !== '') {
      try {
        JSON.parse(currentRequestParams);
      } catch (exc: unknown) {
        result = false;
      }
    }
    return result;
  }, [currentRequestParams]);

  const apiEndpointPath = useMemo<string>(() => {
    let apiPath = '';
    if (apiName !== '' && endpointName !== '') {
      apiPath = getApiEndpointPath(apiName, endpointName);
      const rd = getRequestParamsObj();
      apiPath = ApiHelper.applyRestfulParams(apiPath, rd);
    }
    return apiPath;
  }, [apiName, endpointName, currentRequestParams]);

  const currentUrlObject = useMemo<URL | null>(() => {
    let result: URL | null = null;
    if (apiBaseUrl && apiEndpointPath) {
      try {
        result = new URL(apiBaseUrl + apiEndpointPath);
      } catch (exc: unknown) {
        // console.log(exc);
      }
    }
    return result;
  }, [apiBaseUrl, apiEndpointPath]);

  // const hideApiRequestModal = () => {
  //   setApiRequestModalVisible(false);
  // };

  const togglePreventAutoCancel = () => {
    setPreventAutoCancel(!preventAutoCancel);
  };

  const toggleSkipAuth = () => {
    setSkipAuth(!skipAuth);
  };

  const handleRequestDataChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentRequestParams(event.target.value);
  };

  const handlePastedUrlKeyup = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && pastedUrl !== '' && pastedUrlValid) {
      parsePastedUrl();
    }
  };
  const handlePastedUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPastedUrl(event.target.value);
  };

  const handleApiNameInputChange = (event: React.ChangeEvent<unknown>, value: string | null) => {
    setApiNameSearch(`${value}`);
  };
  const handleApiChange = (event: React.ChangeEvent<unknown>, value: string | null) => {
    let index = value === null ? -1 : apiNames.indexOf(value);
    if (isNaN(index) || !isFinite(index)) {
      index = -1;
    }
    if (apiNameIndex !== index) {
      if (endpointNameIndex !== -1) {
        setEndpointNameIndex(-1);
      }
      setApiNameIndex(index);
    }
  };

  const handleEndpointNameSearchChange = (
    event: React.ChangeEvent<unknown>,
    value: string | null,
  ) => {
    setEndpointNameSearch(`${value}`);
  };

  const handleEndpointChange = (event: React.ChangeEvent<unknown>, value: EndpointData | null) => {
    let index = value === null ? -1 : currentEndpointNames.indexOf(value.endpointName);
    if (isNaN(index) || !isFinite(index)) {
      index = -1;
    }
    setEndpointNameIndex(index);
    if (value !== null) {
      const pth = getApiEndpointPath(apiName, `${value.endpointName}`);
      const pthParts = pth.split('/').filter(Boolean);
      const paramMap = pthParts
        .filter((val) => val[0] === '{' && val[val.length - 1] === '}')
        .map((val) => val.substring(1).replace(/}$/, ''))
        .reduce(
          (acc, val) => {
            if (['customerid', 'customer_id'].indexOf(val.toLowerCase()) >= 0) {
              acc[`${val}`] = `${user.customerId}`;
            } else if (['userid', 'user_id'].indexOf(val.toLowerCase()) >= 0) {
              acc[`${val}`] = `${user.userId}`;
            } else {
              acc[`${val}`] = '';
            }
            return acc;
          },
          {} as {
            [key: string]: string;
          },
        );
      if (Object.keys(paramMap).length > 0) {
        const paramJson = JSON.stringify(paramMap, null, 4);
        setCurrentRequestParams(paramJson);
      } else {
        setCurrentRequestParams('');
      }
    }
  };

  const cancelCurrentCall = () => {
    if (cancelToken.current !== null) {
      cancelToken.current.cancel(config.api.cancelExceptionMessage);
      cancelToken.current = null;
    }
  };

  const executeApiCall = async (callApi: string, callEndpoint: string, data = {}) => {
    setApiRequestActive(true);
    try {
      if (cancelToken.current !== null) {
        cancelCurrentCall();
      }
      cancelToken.current = axios.CancelToken.source();
      const result = await ApiHelper.apiRequest(
        apiName,
        endpointName,
        data,
        {},
        cancelToken.current,
        preventAutoCancel,
        skipAuth,
      );
      if (result) {
        setApiRequestResult(result);
      }
    } catch (exc: unknown) {
      // console.log(exc);
    }
    cancelToken.current = null;
    setApiRequestActive(false);
  };

  const handleCallButtonClick = () => {
    if (apiName !== '' && endpointName !== '') {
      let rd = {};
      try {
        rd = JSON.parse(currentRequestParams);
      } catch (exc: unknown) {
        rd = {};
        // console.log(exc);
      }
      executeApiCall(apiName, endpointName, rd);
      // setApiRequestModalVisible(true);
    }
  };

  const handleCancelButtonClick = () => {
    if (apiRequestActive && cancelToken.current !== null) {
      cancelCurrentCall();
      setApiRequestActive(false);
    }
  };

  const handleParsePastedUrlButtonClick = () => {
    setParsingPastedUrl(true);
  };

  const handleOpenResponseInNewTabClick = () => {
    if (apiRequestResponseJson) {
      openJsonInNewTab(apiRequestResponseJson);
    }
  };

  const handleCopyResponseClick = () => {
    if (apiRequestResponseJson) {
      copyTextToClipboard(apiRequestResponseJson);
    }
  };

  const copyTextToClipboard = async (text: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      navigator.clipboard.writeText(text).then(
        () => {
          // NotificationHelper.add('Value copied to clipboard', 'info');
          resolve();
        },
        () => {
          // NotificationHelper.add('Failed copying value to clipboard', 'error');
          reject(new Error('Copy error'));
        },
      );
    });
  };

  const openJsonInNewTab = (json: string): void => {
    const link = document.createElement('a');
    const blob = new Blob([json], { type: 'text/json;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const parsePastedUrl = () => {
    let parsedName = '';
    // let parsedEndpoint = '';
    let parsedRequestParams = '';
    let parsedApiIndex = -1;
    let parsedEndpointIndex = -1;
    try {
      const url = new URL(pastedUrl);
      // const parsedPath = url.protocol + '//' + url.host + url.pathname;
      const parsedChunks = url.pathname.split('/').filter(Boolean);
      apiNames.forEach((loopApiName, loopApiNameIndex) => {
        const baseUrl = config.api.baseUrl[loopApiName as keyof typeof config.api.baseUrl];
        try {
          const baseUrlObject = new URL(baseUrl);
          if (baseUrlObject.pathname.length > 1) {
            const baseChunks = baseUrlObject.pathname.split('/').filter(Boolean);
            const basePath = baseChunks.join('/');
            const pathChunkMatch = parsedChunks.slice(0, baseChunks.length);
            const parsedMatchPath = pathChunkMatch.join('/');
            if (basePath === parsedMatchPath) {
              parsedApiIndex = loopApiNameIndex;
              parsedName = `${apiNames[loopApiNameIndex as keyof typeof apiNames]}`;
              const epData = getApiEndpointsData(parsedName);
              const endpointPath = `/${parsedChunks.slice(baseChunks.length).join('/')}`;
              epData.forEach((epItem, epItemIndex) => {
                if (endpointPath === epItem.path) {
                  parsedEndpointIndex = epItemIndex;
                  // parsedEndpoint = epItem.endpointName;
                }
              });
            }
          }
        } catch (exc: unknown) {
          // console.log(exc);
        }
        if (parsedApiIndex >= 0 && parsedEndpointIndex >= 0) {
          if (url.search !== '') {
            try {
              parsedRequestParams = JSON.stringify(
                Array.from(url.searchParams).reduce(
                  (acc: { [key: string]: unknown }, paramEntry) => {
                    const paramName = paramEntry[0 as keyof typeof paramEntry];
                    const paramValue = paramEntry[1 as keyof typeof paramEntry];
                    if (!isNaN(+paramValue) && isFinite(+paramValue)) {
                      acc[paramName as keyof typeof acc] = +paramValue;
                    } else {
                      acc[paramName as keyof typeof acc] = `${paramValue}`;
                    }
                    return acc;
                  },
                  {} as { [key: string]: string },
                ),
                null,
                ' ',
              );
            } catch (exc: unknown) {
              // console.log(exc);
            }
          }
        }
      });
    } catch (exc: unknown) {
      // console.log(exc);
    }
    if (parsedApiIndex >= 0) {
      setApiNameIndex(parsedApiIndex);
      if (parsedEndpointIndex >= 0) {
        setEndpointNameIndex(parsedEndpointIndex);
        if (parsedRequestParams) {
          setCurrentRequestParams(parsedRequestParams);
        }

        // console.log({
        //   parsedName,
        //   parsedEndpoint,
        // });
      }
      // NotificationHelper.add('Parsed API URL.', 'info', 3000);
      setPastedUrl('');
      if (pastedUrlInputFieldRef.current !== null) {
        pastedUrlInputFieldRef.current.value = '';
      }
    } else {
      NotificationHelper.add('Could not parse URL.', 'warning', 3000);
    }
  };

  // const copyTextToClipboard = async (text:string):Promise<void> => {
  //   return new Promise((resolve, reject) => {
  //     navigator.clipboard.writeText(text).then(
  //       () => {
  //         NotificationHelper.add('Value copied to clipboard', 'info');
  //         resolve();
  //         // setUserCopied(true);
  //         // NotificationHelper.add('User data copied to clipboard as JSON', 'info');
  //         // setTimeout(() => {
  //         //   hideModal();
  //         // }, 1000);
  //       },
  //       () => {
  //         NotificationHelper.add('Failed copying value to clipboard', 'error');
  //         reject(new Error('Copy error'));
  //         // NotificationHelper.add('Failed copying user data to clipboard', 'error');
  //         // setUserCopied(false);
  //       },
  //     );
  //   });
  // };

  return (
    <React.Fragment>
      <Collapse in={apiRequestResult === null} className="app-api-debug-form">
        <div className="app-api-debug-row-request-paste-url">
          <FormControl>
            <TextField
              size="small"
              variant="outlined"
              id="pasted-url-field"
              onKeyUp={handlePastedUrlKeyup}
              inputRef={pastedUrlInputFieldRef}
              label="Paste URL here"
              fullWidth={true}
              onChange={handlePastedUrlChange}
              inputMode="url"
            />
            {/* <FormHelperText>
              { !paramsJsonValid ? 'Invalid JSON' : 'Request data formatted as JSON' }
            </FormHelperText> */}
          </FormControl>
          <FormControl>
            <Button
              color="primary"
              variant="outlined"
              size="medium"
              disabled={!pastedUrlValid}
              onClick={handleParsePastedUrlButtonClick}
            >
              Parse
            </Button>
          </FormControl>
        </div>
        <div className="app-api-debug-row app-api-debug-dropdowns">
          <div className="app-api-autocomplete-wrapper">
            {!parsingPastedUrl && (
              <Autocomplete
                id="api-name-select"
                size="small"
                blurOnSelect={false}
                value={apiName === '' ? null : apiName}
                onChange={handleApiChange}
                inputValue={apiNameSearch}
                onInputChange={handleApiNameInputChange}
                options={apiNames}
                ref={apiNameFieldRef}
                renderInput={(params) => {
                  return <TextField {...params} label="Select an API" variant="outlined" />;
                }}
              />
            )}
            {parsingPastedUrl && (
              <Autocomplete
                id="api-name-select-placeholder"
                size="small"
                blurOnSelect={false}
                options={[] as string[]}
                renderInput={(params) => {
                  return <TextField {...params} label="Select an API" variant="outlined" />;
                }}
              />
            )}
          </div>
          <div className="app-api-autocomplete-wrapper">
            {apiName === '' && (
              <Autocomplete
                id="endpoint-name-select-placeholder"
                size="small"
                key="endpointAcPlaceholder"
                disabled={true}
                options={currentEndpointNames}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      key="endpointAcInputPlaceholder"
                      label="<-- Pick an API first"
                      variant="outlined"
                    />
                  );
                }}
              />
            )}
            {apiName !== '' && (
              <Autocomplete
                id="endpoint-name-select"
                size="small"
                key={`endpointAc_${apiNameIndex}`}
                disabled={apiRequestActive || apiNameIndex === -1}
                blurOnSelect={true}
                onChange={handleEndpointChange}
                onInputChange={handleEndpointNameSearchChange}
                value={
                  endpointNameIndex < 0
                    ? null
                    : (currentEndpoints[
                        endpointNameIndex as keyof typeof currentEndpoints
                      ] as EndpointData)
                }
                inputValue={endpointNameSearch}
                options={currentEndpoints}
                ref={endpointFieldRef}
                openOnFocus={true}
                // renderOption= {
                //   ({}, val:EndpointData, { selected }:{selected:boolean}) => {
                //     // let label = val.endpointName;
                //     const flags:string[] = [];
                //     if (val.skipAuth === false){
                //       flags.push('AUTH');
                //     }
                //     if (val.rest === true){
                //       flags.push('REST');
                //     }
                //     // if (flags.length) {
                //     //   label += '(' + flags.join('|') + ')';
                //     // }
                //     return (
                //       <div className={ `api-endpoint-option-wrapper api-endpoint-type-${val.method.toLowerCase()}` }>
                //         <span className="api-endpoint-option-name">
                //           <Tooltip
                //             arrow={ true }
                //             placement="left"
                //             classes={
                //               {
                //                 popper: 'elevate-tooltip-wrapper',
                //               }
                //             }
                //             title={
                //               (
                //                 <div className="elevate-tooltip">
                //                   {val.endpointName}
                //                 </div>
                //               )
                //             }
                //           >
                //             <span>
                //               {val.endpointName}
                //             </span>
                //           </Tooltip>
                //         </span>
                //         <span className="api-endpoint-option-flags">
                //           { flags.map((flag) => {
                //             let flagTitle = '';
                //             let flagText = '';
                //             if (flag === 'AUTH') {
                //               flagTitle = 'Requires authentication';
                //               flagText = 'A';
                //             }
                //             if (flag === 'REST') {
                //               flagTitle = 'Restful endpoint';
                //               flagText = 'R';
                //             }
                //             return (
                //               <Tooltip
                //                 arrow={ true }
                //                 placement="left"
                //                 key={`flag_${flag}`}
                //                 classes={
                //                   {
                //                     popper: 'elevate-tooltip-wrapper',
                //                   }
                //                 }
                //                 title={
                //                   (
                //                     <div className="elevate-tooltip">
                //                       { flagTitle }
                //                     </div>
                //                   )
                //                 }
                //               >
                //                 <span className="api-endpoint-option-flag">
                //                   {flagText}
                //                 </span>
                //               </Tooltip>
                //             );
                //           })}
                //         </span>
                //         <span className="api-endpoint-option-info">
                //           <span>
                //             { val.method }
                //           </span>
                //         </span>
                //       </div>
                //     );
                //   }
                // }
                getOptionLabel={(val: EndpointData) => {
                  return val.endpointName;
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputRef={endpointInputRef}
                      key={`endpointAcInput_${apiNameIndex}`}
                      label="Select API Endpoint"
                      variant="outlined"
                    />
                  );
                }}
              />
            )}
          </div>
        </div>

        <div className="app-api-debug-row-request-data">
          <FormControl
            key={`apiParams_${apiNameIndex}_${endpointNameIndex}`}
            fullWidth={true}
            error={!paramsJsonValid}
          >
            <TextField
              disabled={apiRequestActive || !(apiName !== '' && endpointName !== '')}
              size="small"
              variant="outlined"
              id="standard-multiline-flexible"
              label="Request Data"
              fullWidth={true}
              multiline={true}
              minRows={2}
              inputRef={requestDataFieldRef}
              maxRows={8}
              onChange={handleRequestDataChange}
            />
            <FormHelperText>
              {!paramsJsonValid ? 'Invalid JSON' : 'Request data formatted as JSON'}
            </FormHelperText>
          </FormControl>
        </div>

        <AppApiDebugCommonParams />

        <div className="app-api-debug-row-url">
          {currentUrlObject !== null && (
            <div className="api-url-preview">
              <span className="api-url-preview-host">
                {`${currentUrlObject.protocol}//${currentUrlObject.host}${
                  currentUrlObject.port !== '' ? ':' + currentUrlObject.port : ''
                }`}
              </span>
              <span className="api-url-preview-path">{currentUrlObject.pathname}</span>
            </div>
          )}
          {currentUrlObject === null && (
            <div>
              <span>URL will be displayed here</span>
            </div>
          )}
        </div>

        <div className="app-api-debug-row-footer">
          <FormGroup row={true}>
            <FormControl
              className="api-debug-option-checkbox"
              color="primary"
              size="small"
              variant="outlined"
            >
              <FormControlLabel
                label="No auto-cancel"
                labelPlacement="end"
                control={<Checkbox onChange={togglePreventAutoCancel} />}
              />
            </FormControl>
            <FormControl
              className="api-debug-option-checkbox"
              color="primary"
              size="small"
              variant="outlined"
            >
              <FormControlLabel
                label="Skip auth"
                labelPlacement="end"
                control={<Checkbox onChange={toggleSkipAuth} />}
              />
            </FormControl>

            <div className="api-debug-action-buttons">
              {apiRequestActive && <SpinnerIcon />}
              {apiRequestActive && cancelToken.current !== null && (
                <Button
                  onClick={handleCancelButtonClick}
                  variant="contained"
                  size="small"
                  color="secondary"
                  disabled={!apiRequestActive || preventAutoCancel}
                >
                  Cancel API call
                </Button>
              )}
              <Button
                onClick={handleCallButtonClick}
                variant="contained"
                size="small"
                color="primary"
                disabled={
                  !paramsJsonValid ||
                  !(apiRequestActive === false && apiName !== '' && endpointName !== '')
                }
              >
                Execute API Call
              </Button>
            </div>
          </FormGroup>
        </div>
      </Collapse>
      <Collapse in={apiRequestResult !== null} className="app-api-debug-result">
        <div className="app-api-debug-response-wrapper">
          <pre className="app-api-debug-response-data">{apiRequestResponseJson}</pre>
          <div className="app-api-debug-response-footer">
            <Button
              onClick={handleCopyResponseClick}
              variant="contained"
              size="small"
              color="primary"
              endIcon={<TbCopy />}
            >
              Copy response
            </Button>
            <Button
              onClick={handleOpenResponseInNewTabClick}
              variant="contained"
              size="small"
              color="primary"
              endIcon={<TbExternalLink />}
            >
              Open response in new tab
            </Button>
            <Button
              onClick={() => {
                setApiRequestResult(null);
              }}
              variant="contained"
              size="small"
              color="primary"
              endIcon={<TbBackspace />}
            >
              Clear response
            </Button>
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  );
};

export default AppDebugApi;
