import React from 'react';
import { AppNotification } from '../../../reducers/appDataTypes';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { FaTrashAlt, FaStickyNote, FaRegClock } from 'react-icons/fa';

import { useDispatch } from 'react-redux';

export interface AppDebugNotificationItemProps {
  item: AppNotification;
  index: number;
}

const AppDebugNotificationItem: React.FC<AppDebugNotificationItemProps> = (props) => {
  const dispatch = useDispatch();
  const handleRemoveClick = () => {
    dispatch({ type: 'REMOVE_APP_NOTIFICATION', payload: props.item.uuid });
  };

  return (
    <li
      className={`app-debug-active-notification-item notification-item-${props.item.type}`}
      key={props.item.uuid}
      data-uuid={props.item.uuid}
      data-index={props.index}
    >
      <span className="app-notification-index">{`#${props.index + 1}`}</span>
      <span className="app-notification-flags">
        <Tooltip arrow={true} title="Timed notification" placement="top">
          <span
            className={`app-notification-flag${
              props.item.duration > 0 && !props.item.sticky ? ' app-notification-flag-active' : ''
            }`}
          >
            <FaRegClock />
          </span>
        </Tooltip>
        <Tooltip arrow={true} title="Sticky notification" placement="top">
          <span
            className={`app-notification-flag${
              props.item.sticky ? ' app-notification-flag-active' : ''
            }`}
          >
            <FaStickyNote />
          </span>
        </Tooltip>
      </span>
      <span className="app-notification-uuid">
        <Tooltip
          arrow={true}
          title={
            <div className="app-notification-info-tooltip">
              <span>UUID:</span>
              <span>{props.item.uuid}</span>
              <span>Message</span>
              <span>{props.item.message}</span>
            </div>
          }
        >
          <span>{props.item.uuid.substring(0, 8)}</span>
        </Tooltip>
      </span>
      <span className="app-notification-tools">
        <Tooltip arrow={true} title="Remove notification" placement="left">
          <IconButton color="secondary" size="small" onClick={handleRemoveClick}>
            <FaTrashAlt size="0.75em" />
          </IconButton>
        </Tooltip>
      </span>
    </li>
  );
};

export default AppDebugNotificationItem;
