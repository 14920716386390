import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './Toolbar.module.scss';
import Dropdown, { IDropdownOption } from '../../Dropdown/Dropdown';
import { Grade } from '../util';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import DownloadTicketsButton, {
  DownloadTicketsButtonProps,
} from './DownloadTicketsButton/DownloadTicketsButton';

export interface ToolbarProps
  extends Pick<DownloadTicketsButtonProps, 'testTakerTicketsData' | 'onDocumentDownload'> {
  className?: string;
  grades: Grade[];
  disabled?: boolean;
  disabledDownloadTicketsButton?: boolean;
  onChangeSelectedGrades?: (selectedGrades: Grade[]) => void;
}

const Toolbar = ({
  className,
  disabled,
  disabledDownloadTicketsButton,
  grades,
  onChangeSelectedGrades,
  onDocumentDownload,
  testTakerTicketsData,
}: ToolbarProps) => {
  const [gradeOptions, setGradeOptions] = useState<IDropdownOption[]>([]);

  useEffect(() => {
    const currentGrades = gradeOptions.map((gradeOption) => gradeOption.value);

    if (JSON.stringify(grades) !== JSON.stringify(currentGrades)) {
      setGradeOptions(
        grades.map((grade) => ({
          label: grade === 'K' ? 'Kindergarten' : `Grade ${grade}`,
          value: grade,
          selected: true,
        })),
      );
    }
  }, [grades]);

  useEffect(() => {
    onChangeSelectedGrades?.(
      gradeOptions
        .filter((gradeOption) => gradeOption.selected)
        .map((gradeOption) => gradeOption.value),
    );
  }, [gradeOptions, onChangeSelectedGrades]);

  return (
    <div className={classNames(styles['toolbar'], className)}>
      <div className={styles['start-container']}>
        Filter by Grade:
        <Dropdown
          cssClass={styles['grades-dropdown']}
          hasApplyButton={true}
          hasSelectAll={true}
          isDisabled={disabled}
          isMultiselect={true}
          options={gradeOptions.map((gradeOption) => ({ ...gradeOption }))}
          placeholderOneItemSelected="selection"
          placeholderManyItemSelected="selections"
          onChange={(options) => setGradeOptions(options.map((option) => ({ ...option })))}
          minSelected={1}
        />
        <IconButton
          className={styles['refresh-grades-button']}
          disabled={disabled}
          disableRipple
          onClick={() =>
            setGradeOptions(gradeOptions.map((gradeOption) => ({ ...gradeOption, selected: true })))
          }
        >
          <RefreshIcon />
        </IconButton>
      </div>

      <div className={styles['end-container']}>
        <DownloadTicketsButton
          disabled={disabled || disabledDownloadTicketsButton}
          onDocumentDownload={onDocumentDownload}
          testTakerTicketsData={testTakerTicketsData}
        />
      </div>
    </div>
  );
};

export default Toolbar;
