import React, { useEffect, useState, useMemo } from 'react';

import Pagination from '@mui/material/Pagination';
import {
  Button,
  TableFooter,
  Select,
  MenuItem,
  FormControl,
  TableCell,
  TableRow,
  SelectChangeEvent,
} from '@mui/material';

import {
  ElevateTableStatus,
  ElevateTableProps,
  ElevateTableRecord,
} from '../../../shared/ElevateTable/ElevateTable.d';
import useCachedUser from '../../../../hooks/useCachedUser';

import useNotificationHelper from '../../../../hooks/useNotificationHelper';
import useApiHelper from '../../../../hooks/useApiHelper';
import useAppLogger from '../../../../hooks/useAppLogger';
import { AssignmentListItem } from '../../TestAssignment';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { ModalDialog } from '@riversideinsights/elevate-react-lib';

interface RestoreProps {
  assignmentId: number;
  assignmentName: string;
  status: string;
  modifiedById: string;
  modifiedByName: string;
  userRole: string;
}

const TrashTableFooter = <T extends ElevateTableRecord>(
  props: ElevateTableProps<T>,
): React.ReactElement | null => {
  const page = Math.max(
    0,
    Math.floor(props.tableData.status.offset / props.tableData.status.limit),
  );
  const pages = Math.ceil(props.tableData.status?.totalRecords / props.tableData.status.limit);
  const user = useCachedUser();
  const ApiHelper = useApiHelper('Component|TrashTableFooter');
  const NotificationHelper = useNotificationHelper();
  const logger = useAppLogger('COMPONENT|TrashTableFooter');
  const dispatch = useDispatch();
  const [itemsRestored, setItemsRestored] = useState<RestoreProps[]>([]);

  const isPermissionCRUDAssignments = useMemo<boolean>(() => {
    if (user.role !== 'district_admin') return false; //TODO temporary disabled permission for SA and teachers
    return !!user.currentPermissions.includes(72); //permissionName: 'TA-CRUDAssignments'
  }, [user.role, user.currentPermissions]);

  const isItemsEmpty = props.tableData.items == null;
  const isNoneSelected =
    isItemsEmpty ||
    !props.tableData.items?.some((item) => {
      const itemAssignment = item as unknown as AssignmentListItem;
      return itemAssignment.selected === true;
    });

  const handleSetPage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    const newOffset = Math.max(0, (newPage - 1) * props.tableData.status.limit);
    const tableStatusUpdates: ElevateTableStatus = {
      ...props.tableData.status,
      offset: newOffset,
    };
    if (props.onChange) {
      props.onChange(tableStatusUpdates);
    }
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    const newOffset = 0;
    const newLimit = +`${event.target.value}`;
    const ts: ElevateTableStatus = {
      ...props.tableData.status,
      offset: newOffset,
      limit: newLimit,
    };
    if (props.onChange) {
      props.onChange(ts);
    }
  };

  const restore = async () => {
    try {
      const itemsToRestore: RestoreProps[] = [];
      props.tableData.items?.forEach((item) => {
        const itemAssignment = item as unknown as AssignmentListItem;

        if (!itemAssignment.selected) {
          return;
        }

        const itemToRestore: RestoreProps = {
          assignmentId: itemAssignment.assignmentid,
          assignmentName: itemAssignment.assignmentname,
          status: 'Restore',
          modifiedById: user.userId,
          modifiedByName: user.name,
          userRole: user.role,
        };

        itemsToRestore.push(itemToRestore);
      });

      let ts: ElevateTableStatus = {
        ...props.tableData.status,
        loading: true,
      };
      if (props.onChange) {
        props.onChange(ts);
      }

      const result = await ApiHelper.apiRequest(
        'assignmentApiUrl',
        'RestoreAssignments',
        itemsToRestore,
      );
      if (!(result && result.data)) {
        NotificationHelper.add('Error restoring assignments', 'error');
        ts = {
          ...props.tableData.status,
          loading: false,
        };
        if (props.onChange) {
          props.onChange(ts);
        }
      } else {
        NotificationHelper.add('Selected Test Assignments have been restored.', 'warning');
        ts = {
          ...props.tableData.status,
          limit: props.tableData.status.limit === 25 ? 10 : 25,
          loading: false,
        };
        if (props.onChange) {
          props.onChange(ts);
        }
        setItemsRestored(itemsToRestore);
      }
    } catch (exc: unknown) {
      const ex = exc as Error;
      logger.error(ex);
      const ts: ElevateTableStatus = {
        ...props.tableData.status,
        loading: false,
      };
      if (props.onChange) {
        props.onChange(ts);
      }
      NotificationHelper.add('Error restoring assignments', 'error');
    }
  };

  useEffect(() => {
    if (itemsRestored.length > 0) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            key="restore-success-modal"
            className="restore-footer-modal"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            title="Test Assignments Restored"
            body={
              <div className="saved-role-preferences-modal-body">
                <div className="changed-permissions-title">
                  You have successfully restored{' '}
                  {itemsRestored.map((item) => item.assignmentName).join(', ')}. They can be found
                  in the Test Assignments List.
                </div>
              </div>
            }
            footer={
              <RouterLink to="/assignment/list">
                <Button variant="contained" color="primary">
                  Go to Test Assignments List
                </Button>
              </RouterLink>
            }
            onClose={() => setItemsRestored([])}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [itemsRestored]);

  const tableStatus = props.tableData.status;

  const enabledRowsPerPage = props.tableData.options.rowsPerPageValues.filter(
    (val) => val - props.tableData.status.limit < props.tableData.status.totalRecords,
  );

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={props.tableData.columns.length}>
          <div className="elevate-table-pagination">
            <div className="elevate-table-pagination-left">
              <Pagination
                size="large"
                className="elevate-table-pagination-items"
                count={pages}
                page={page + 1}
                variant="outlined"
                shape="rounded"
                onChange={handleSetPage}
                disabled={
                  tableStatus.errorMessage !== '' || pages <= 1 || props.tableData.status.loading
                }
              />
              <div className="elevate-table-pagination-rows">
                <FormControl
                  size="small"
                  margin="none"
                  variant="outlined"
                  disabled={
                    tableStatus.errorMessage !== '' ||
                    enabledRowsPerPage.length === 0 ||
                    props.tableData.status.loading
                  }
                >
                  <Select
                    className="items-per-page-select"
                    labelId="items_per_page_label"
                    id="items_per_page_select"
                    value={props.tableData.status.limit}
                    onChange={handleChangeRowsPerPage}
                  >
                    {props.tableData.options.rowsPerPageValues.map((value) => {
                      return (
                        <MenuItem
                          className="items-per-page-entry"
                          key={`opt_${value}`}
                          value={value}
                        >
                          {value} Items per page
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            {!props.tableData.status.loading && props.tableData.status.totalRecords > 0 && (
              <div className="elevate-table-pagination-right">
                ITEMS{' '}
                {props.tableData.status?.totalRecords ? props.tableData.status.offset + 1 : '0'} -{' '}
                {Math.min(
                  props.tableData.status.offset + props.tableData.status.limit,
                  props.tableData.status?.totalRecords,
                )}
              </div>
            )}
            {props.tableData.status.errorMessage !== '' && (
              <div className="elevate-table-pagination-right">LOAD ERROR</div>
            )}
            {props.tableData.status.errorMessage === '' &&
              !props.tableData.status.loading &&
              props.tableData.status.totalRecords === 0 && (
                <div className="elevate-table-pagination-right">NO ITEMS</div>
              )}
            {props.tableData.status.errorMessage === '' && props.tableData.status.loading && (
              <div className="elevate-table-pagination-right">LOADING</div>
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={props.tableData.columns.length}>
          <div className="elevate-table-restore">
            <div className="elevate-table-restore-left">
              The test assignment will be permanently deleted in 30 days.
            </div>
            <div className="elevate-table-restore-right">
              {(user.role === 'district_admin' || isPermissionCRUDAssignments) && (
                <Button
                  color="primary"
                  variant="contained"
                  className="app-button"
                  onClick={() => restore()}
                  disabled={isNoneSelected}
                >
                  Restore selected
                </Button>
              )}
            </div>
          </div>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default TrashTableFooter;
