import { ConnectionSpeedInfo } from '../../../types/proctoring.types';
export interface IClosedAssignment {
  assignmentId: number;
  assignmentName: string;
  modifiedById: number;
  modifiedByName: string;
}

//legacy config value that needs to be removed later
const isUseApiForUniqueStudentCount = false;
const arrStudentList: any = [];
const arrClosedAssignmentList: IClosedAssignment[] = [];

const countPerPage = 5;
const isPaginationOn = true;
const isInfiniteScroll = true;
const timeCorrection = 1000;
const ghostEventsIgnoreInterval = 3000;
let isGhostEvent = false;

const initState = {
  studentsWaitingApproval: {
    allItems: arrStudentList.slice(0),
    pageItems: arrStudentList.slice(0),
    totalCount: 0,
    currentPage: 1,
    isChanged: false,
    allItemsAfterSearchNum: 0,
    sortingField: 'sortname',
    sortingDirection: 'asc',
    searchText: '',
  },
  studentsInProgress: {
    allItems: arrStudentList.slice(0),
    pageItems: arrStudentList.slice(0),
    totalCount: 0,
    currentPage: 1,
    isChanged: false,
    allItemsAfterSearchNum: 0,
    sortingField: 'sortname',
    sortingDirection: 'asc',
    searchText: '',
    isClientSidePagination: true,
    isPaginationTypeChanging: false,
    isExtendTestingTimeDisabled: true,
  },
  studentsCompleted: {
    pageItems: arrStudentList.slice(0),
    totalCount: 0,
    currentPage: 1,
    isChanged: false,
    sortingField: 'assignmentCompleteDate',
    sortingDirection: 'desc',
    searchText: '',
  },
  countPerPage: countPerPage,
  orderingField: '',
  orderingDirection: '',
  searchText: '',
  isPaginationOn: isPaginationOn,
  isInfiniteScroll: isInfiniteScroll,
  viewType: 'dashboard', //dashboard, live-tests, completed
  lastUpdateDateTime: '...',
  flagUpdatingInProgressStudents: false,
  flagAddingInProgressStudents: false,
  flagAllStudentscompleted: false,
  requestDenyStudent: {},
  arrCardsToPause: [],
  arrCardsToResume: [],
  arrCardsToSaveAndExit: [],
  arrCardsToCancelAndDiscard: [],
  arrStudentSessionGuidToExtendTime: [],
  extendTimeMinutes: 0,
  showDialog: '',
  lastSelectedCard: null,
  isProctorPageRefresh: false,
  lastGhostEventTime: 0,
  changeStatus: {
    user_ids: [],
    sessionStatusId: -1,
  },
  isSpinnerVisible: true,
  timerTickGhostEventsFinished: 0,
  arrPingCurrentProgressStudents: [],
  arrProctorClosedAssignments: arrClosedAssignmentList.slice(0),
  arrProctorClosedAssignmentIds: [],
};

const sortAsc = (arr: any, field: any) => {
  return arr.sort(function (a: any, b: any) {
    if (
      field === 'sortname' &&
      typeof a['studentDetails'] === 'string' &&
      typeof b['studentDetails'] === 'string' &&
      typeof JSON.parse(a['studentDetails']).lastName === 'string' &&
      typeof JSON.parse(b['studentDetails']).lastName === 'string' &&
      typeof JSON.parse(a['studentDetails']).firstName === 'string' &&
      typeof JSON.parse(b['studentDetails']).firstName === 'string'
    ) {
      if (
        JSON.parse(a['studentDetails']).lastName.toLowerCase() +
          JSON.parse(a['studentDetails']).firstName.toLowerCase() >
        JSON.parse(b['studentDetails']).lastName.toLowerCase() +
          JSON.parse(b['studentDetails']).firstName.toLowerCase()
      )
        return 1;
      if (
        JSON.parse(b['studentDetails']).lastName.toLowerCase() +
          JSON.parse(b['studentDetails']).firstName.toLowerCase() >
        JSON.parse(a['studentDetails']).lastName.toLowerCase() +
          JSON.parse(a['studentDetails']).firstName.toLowerCase()
      )
        return -1;
      return 0;
    }
    if (typeof a[field] === 'string' && typeof b[field] === 'string') {
      if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
      if (b[field].toLowerCase() > a[field].toLowerCase()) return -1;
      return 0;
    }
    if (a[field] > b[field]) return 1;
    if (b[field] > a[field]) return -1;
    return 0;
  });
};

const sortDesc = (arr: any, field: any) => {
  return arr.sort(function (a: any, b: any) {
    if (
      field === 'sortname' &&
      typeof a['studentDetails'] === 'string' &&
      typeof b['studentDetails'] === 'string' &&
      typeof JSON.parse(a['studentDetails']).lastName === 'string' &&
      typeof JSON.parse(b['studentDetails']).lastName === 'string' &&
      typeof JSON.parse(a['studentDetails']).firstName === 'string' &&
      typeof JSON.parse(b['studentDetails']).firstName === 'string'
    ) {
      if (
        JSON.parse(a['studentDetails']).lastName.toLowerCase() +
          JSON.parse(a['studentDetails']).firstName.toLowerCase() >
        JSON.parse(b['studentDetails']).lastName.toLowerCase() +
          JSON.parse(b['studentDetails']).firstName.toLowerCase()
      )
        return -1;
      if (
        JSON.parse(b['studentDetails']).lastName.toLowerCase() +
          JSON.parse(b['studentDetails']).firstName.toLowerCase() >
        JSON.parse(a['studentDetails']).lastName.toLowerCase() +
          JSON.parse(a['studentDetails']).firstName.toLowerCase()
      )
        return 1;
      return 0;
    }
    if (typeof a[field] === 'string' && typeof b[field] === 'string') {
      if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
      if (b[field].toLowerCase() > a[field].toLowerCase()) return 1;
      return 0;
    }
    if (a[field] > b[field]) return -1;
    if (b[field] > a[field]) return 1;
    return 0;
  });
};

const resetAllCheckboxesCheckedState = (state: any) => {
  const newState = Object.assign({}, state);
  newState.studentsWaitingApproval.allItems.map((n: any) => (n.checked = false));
  newState.studentsWaitingApproval.pageItems.map((n: any) => (n.checked = false));
  newState.studentsInProgress.pageItems.map((n: any) => (n.checked = false));
  if (newState.studentsInProgress.isClientSidePagination) {
    newState.studentsInProgress.allItems.map((n: any) => (n.checked = false));
  }
  newState.studentsCompleted.pageItems.map((n: any) => (n.checked = false));
  return newState;
};

const convertStudentProgress = (progress: any, student: any) => {
  const result = {
    attemptedQuestions: progress.num_attempted,
    totalQuestions: progress.num_questions,
    //status: progress.status,
  };
  //result.currentItem = progress.num_attempted + additionalEmptyQuestionsNum;
  if (student.assignmentType === 'IowaFlex Math') {
    if (progress.num_attempted <= 3) result.attemptedQuestions = progress.num_attempted + 1;
    else result.attemptedQuestions = progress.num_attempted + 2;
    result.totalQuestions = 8 * JSON.parse(student.standards).length + 4 + 1;
  }
  if (student.assignmentType === 'IowaFlex Reading') {
    if (progress.num_attempted === 0) result.attemptedQuestions = 1;
    else if (progress.num_attempted === 1) result.attemptedQuestions = 3;
    else result.attemptedQuestions = progress.num_attempted + 3;
    result.totalQuestions = 36;
  }
  if (student.assignmentType === 'CogAT') {
    //correction for live items
    if (progress.num_questions >= 4) {
      if (progress.num_questions > student.testItemCount) {
        result.totalQuestions = student.testItemCount;
        result.attemptedQuestions =
          progress.num_attempted - (progress.num_questions - student.testItemCount);
      }
    }
  }
  //console.log(result);
  return result;
};

const checkIsExtendTestingTimeDisabled = (cards: any) => {
  let result = false;
  for (let i = 0; i < cards.length; i++) {
    if (cards[i].checked && cards[i].assignmentType === 'CogAT') {
      if (
        cards[i].assignmentLevel === 'Level 5/6' ||
        cards[i].assignmentLevel === 'Level 7' ||
        cards[i].assignmentLevel === 'Level 8'
      ) {
        result = true;
        break;
      }
      if (cards[i].totalQuestions <= 4) {
        result = true;
        break;
      }
    }
  }
  return result;
};

const studentListReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FLAG_ADDING_IN_PROGRESS_STUDENTS':
      return {
        ...state,
        flagAddingInProgressStudents: action.payload,
      };

    case 'UPDATE_PING_CURRENT_PROGRESS_STUDENTS':
      return {
        ...state,
        arrPingCurrentProgressStudents: action.payload,
      };

    case 'UPDATE_iS_STUDENTS_WAITING_APPROVAL_LIST_CHANGED':
      const newUpdateStudentsWaitingApprovalListChangeState = Object.assign({}, state);
      newUpdateStudentsWaitingApprovalListChangeState.studentsWaitingApproval.isChanged =
        action.payload;
      return newUpdateStudentsWaitingApprovalListChangeState;

    case 'UPDATE_iS_STUDENTS_IN_PROGRESS_LIST_CHANGED':
      const newUpdateStudentsInProgressListChangeState = Object.assign({}, state);
      newUpdateStudentsInProgressListChangeState.studentsInProgress.isChanged = action.payload;
      return newUpdateStudentsInProgressListChangeState;

    case 'ADD_STUDENTS':
      const newAddStudentState = Object.assign({}, state);

      if (action.payload.listType === 'WaitingApproval') {
        newAddStudentState.studentsWaitingApproval.allItems = action.payload.arrStudents;
        newAddStudentState.studentsWaitingApproval.pageItems = action.payload.arrStudents;
        newAddStudentState.studentsWaitingApproval.isChanged = true;
        newAddStudentState.studentsWaitingApproval.totalCount = action.payload.arrStudents.length;
        newAddStudentState.studentsWaitingApproval.currentPage = action.payload.currentPage;
      }
      if (action.payload.listType === 'InProgress') {
        if (newAddStudentState.studentsInProgress.isClientSidePagination) {
          newAddStudentState.studentsInProgress.allItems = action.payload.arrStudents;
          newAddStudentState.studentsInProgress.pageItems = action.payload.arrStudents;
          newAddStudentState.studentsInProgress.isChanged = true;
          newAddStudentState.studentsInProgress.totalCount = action.payload.arrStudents.length;
          newAddStudentState.studentsInProgress.currentPage = action.payload.currentPage;
          newAddStudentState.flagAddingInProgressStudents = true;
        } else {
          newAddStudentState.studentsInProgress.pageItems = action.payload.arrStudents;
          newAddStudentState.studentsInProgress.isChanged = false;
          if (newAddStudentState.studentsInProgress.searchText === '')
            newAddStudentState.studentsInProgress.totalCount = action.payload.totalCount;
          newAddStudentState.studentsInProgress.currentPage = action.payload.currentPage;
          newAddStudentState.flagAddingInProgressStudents = true;
        }
      }
      if (action.payload.listType === 'Completed') {
        newAddStudentState.studentsCompleted.pageItems = action.payload.arrStudents;
        newAddStudentState.studentsCompleted.isChanged = false;
        if (newAddStudentState.studentsCompleted.searchText === '')
          newAddStudentState.studentsCompleted.totalCount = action.payload.totalCount;
        newAddStudentState.studentsCompleted.currentPage = action.payload.currentPage;
      }
      return newAddStudentState;

    case 'STUDENT_REQUEST_APPROVAL':
      const newStudentRequestApprovalState = Object.assign({}, state);
      newStudentRequestApprovalState.studentsWaitingApproval.allItems =
        newStudentRequestApprovalState.studentsWaitingApproval.allItems.filter((n: any) => {
          return n.user_id !== action.payload.user_id;
        });
      newStudentRequestApprovalState.studentsWaitingApproval.pageItems =
        newStudentRequestApprovalState.studentsWaitingApproval.pageItems.filter((n: any) => {
          return n.user_id !== action.payload.user_id;
        });
      newStudentRequestApprovalState.studentsWaitingApproval.allItems.push(action.payload);
      newStudentRequestApprovalState.studentsWaitingApproval.pageItems.push(action.payload);
      newStudentRequestApprovalState.studentsWaitingApproval.totalCount =
        newStudentRequestApprovalState.studentsWaitingApproval.allItems.length;
      newStudentRequestApprovalState.studentsWaitingApproval.isChanged = true;

      if (newStudentRequestApprovalState.studentsInProgress.isClientSidePagination) {
        if (
          newStudentRequestApprovalState.studentsInProgress.allItems.filter(
            (n: any) => n.user_id === action.payload.user_id,
          ).length
        ) {
          newStudentRequestApprovalState.studentsInProgress.allItems =
            newStudentRequestApprovalState.studentsInProgress.allItems.filter((n: any) => {
              return n.user_id !== action.payload.user_id;
            });
          newStudentRequestApprovalState.studentsInProgress.pageItems =
            newStudentRequestApprovalState.studentsInProgress.pageItems.filter((n: any) => {
              return n.user_id !== action.payload.user_id;
            });
          newStudentRequestApprovalState.studentsInProgress.totalCount =
            newStudentRequestApprovalState.studentsInProgress.allItems.length;
          newStudentRequestApprovalState.studentsInProgress.isChanged = true;
        }
      } else {
        if (
          newStudentRequestApprovalState.studentsInProgress.pageItems.filter(
            (n: any) => n.user_id === action.payload.user_id,
          ).length
        ) {
          newStudentRequestApprovalState.studentsInProgress.isChanged = true;
        }
      }

      if (
        newStudentRequestApprovalState.studentsCompleted.pageItems.filter(
          (n: any) => n.user_id === action.payload.user_id,
        ).length
      ) {
        newStudentRequestApprovalState.studentsCompleted.isChanged = true;
      }
      return newStudentRequestApprovalState;

    case 'REMOVE_STUDENT':
      const newRemoveStudentState = Object.assign({}, state);
      newRemoveStudentState.studentsWaitingApproval.allItems =
        newRemoveStudentState.studentsWaitingApproval.allItems.filter((n: any) => {
          return (
            Number(n.id) !== Number(action.payload.id) ||
            n.assignmentId !== action.payload.assignmentId ||
            n.subTestAcronym !== action.payload.subTestAcronym
          );
        });
      newRemoveStudentState.studentsWaitingApproval.pageItems =
        newRemoveStudentState.studentsWaitingApproval.pageItems.filter((n: any) => {
          return (
            Number(n.id) !== Number(action.payload.id) ||
            n.assignmentId !== action.payload.assignmentId ||
            n.subTestAcronym !== action.payload.subTestAcronym
          );
        });
      newRemoveStudentState.studentsWaitingApproval.totalCount =
        newRemoveStudentState.studentsWaitingApproval.allItems.length;
      newRemoveStudentState.studentsWaitingApproval.isChanged = true;

      if (newRemoveStudentState.studentsInProgress.isClientSidePagination) {
        newRemoveStudentState.studentsInProgress.allItems =
          newRemoveStudentState.studentsInProgress.allItems.filter((n: any) => {
            return (
              Number(n.id) !== Number(action.payload.id) ||
              n.assignmentId !== action.payload.assignmentId ||
              n.subTestAcronym !== action.payload.subTestAcronym
            );
          });
        newRemoveStudentState.studentsInProgress.pageItems =
          newRemoveStudentState.studentsInProgress.pageItems.filter((n: any) => {
            return (
              Number(n.id) !== Number(action.payload.id) ||
              n.assignmentId !== action.payload.assignmentId ||
              n.subTestAcronym !== action.payload.subTestAcronym
            );
          });
        newRemoveStudentState.studentsInProgress.totalCount =
          newRemoveStudentState.studentsInProgress.allItems.length;
        newRemoveStudentState.studentsInProgress.isChanged = true;
      } else {
        if (
          newRemoveStudentState.studentsInProgress.pageItems.filter(
            (n: any) =>
              Number(n.id) === Number(action.payload.id) &&
              n.assignmentId === action.payload.assignmentId &&
              n.subTestAcronym === action.payload.subTestAcronym,
          ).length
        ) {
          newRemoveStudentState.studentsInProgress.isChanged = true;
        }
      }

      if (
        newRemoveStudentState.studentsCompleted.pageItems.filter(
          (n: any) =>
            Number(n.id) === Number(action.payload.id) &&
            n.assignmentId === action.payload.assignmentId &&
            n.subTestAcronym === action.payload.subTestAcronym,
        ).length
      ) {
        newRemoveStudentState.studentsCompleted.isChanged = true;
      }
      return newRemoveStudentState;

    case 'REMOVE_STUDENT_BY_USER_ID':
      const newRemoveStudentBysessionGuidState = Object.assign({}, state);
      isGhostEvent = false;
      if (newRemoveStudentBysessionGuidState.isProctorPageRefresh) {
        const currentDateTime = Date.now();
        if (
          currentDateTime - newRemoveStudentBysessionGuidState.lastGhostEventTime <
          ghostEventsIgnoreInterval
        ) {
          newRemoveStudentBysessionGuidState.lastGhostEventTime = currentDateTime;
          // console.log('GHOST EVENT! (reducer REMOVE_STUDENT_BY_USER_ID)');
          isGhostEvent = true;
        } else {
          newRemoveStudentBysessionGuidState.lastGhostEventTime = 0;
          newRemoveStudentBysessionGuidState.isProctorPageRefresh = false;
          //console.log('NORMAL EVENT!');
        }
      }
      if (!isGhostEvent) {
        newRemoveStudentBysessionGuidState.studentsWaitingApproval.allItems =
          newRemoveStudentBysessionGuidState.studentsWaitingApproval.allItems.filter((n: any) => {
            return action.payload.user_ids.indexOf(n.user_id) === -1;
          });
        newRemoveStudentBysessionGuidState.studentsWaitingApproval.pageItems =
          newRemoveStudentBysessionGuidState.studentsWaitingApproval.pageItems.filter((n: any) => {
            return action.payload.user_ids.indexOf(n.user_id) === -1;
          });
        newRemoveStudentBysessionGuidState.studentsWaitingApproval.totalCount =
          newRemoveStudentBysessionGuidState.studentsWaitingApproval.allItems.length;
        newRemoveStudentBysessionGuidState.studentsWaitingApproval.isChanged = true;

        if (newRemoveStudentBysessionGuidState.studentsInProgress.isClientSidePagination) {
          newRemoveStudentBysessionGuidState.studentsInProgress.allItems =
            newRemoveStudentBysessionGuidState.studentsInProgress.allItems.filter((n: any) => {
              return action.payload.user_ids.indexOf(n.user_id) === -1;
            });
          newRemoveStudentBysessionGuidState.studentsInProgress.pageItems =
            newRemoveStudentBysessionGuidState.studentsInProgress.pageItems.filter((n: any) => {
              return action.payload.user_ids.indexOf(n.user_id) === -1;
            });
          newRemoveStudentBysessionGuidState.studentsInProgress.totalCount =
            newRemoveStudentBysessionGuidState.studentsInProgress.allItems.length;
          newRemoveStudentBysessionGuidState.studentsInProgress.isChanged = true;
        } else {
          if (
            newRemoveStudentBysessionGuidState.studentsInProgress.pageItems.filter(
              (n: any) => n.user_id === action.payload.user_id,
            ).length
          ) {
            newRemoveStudentBysessionGuidState.studentsInProgress.isChanged = true;
          }
        }

        if (
          newRemoveStudentBysessionGuidState.studentsCompleted.pageItems.filter(
            (n: any) => n.user_id === action.payload.user_id,
          ).length
        ) {
          newRemoveStudentBysessionGuidState.studentsCompleted.isChanged = true;
        }
      }
      return newRemoveStudentBysessionGuidState;

    case 'CHANGE_STATUS_BY_USER_ID':
      const newChangeStatusByUserIdState = Object.assign({}, state);
      isGhostEvent = false;
      if (newChangeStatusByUserIdState.isProctorPageRefresh) {
        const currentDateTime = Date.now();
        if (
          currentDateTime - newChangeStatusByUserIdState.lastGhostEventTime <
          ghostEventsIgnoreInterval
        ) {
          newChangeStatusByUserIdState.lastGhostEventTime = currentDateTime;
          //if (action.payload.sessionStatusId === 3) {
          //3 - Paused ghost event is not ignored but lastGhostEventTime is not updated to 0
          //} else {
          // console.log('GHOST EVENT! (reducer CHANGE_STATUS_BY_USER_ID)');
          isGhostEvent = true;
          //}
        } else {
          newChangeStatusByUserIdState.lastGhostEventTime = 0;
          newChangeStatusByUserIdState.isProctorPageRefresh = false;
          //console.log('NORMAL EVENT!');
        }
      }
      if (!isGhostEvent) {
        newChangeStatusByUserIdState.changeStatus = {
          user_ids: action.payload.user_ids,
          sessionStatusId: action.payload.sessionStatusId,
        };
      }
      return newChangeStatusByUserIdState;

    case 'LAST_UPDATE_DATETIME':
      return {
        ...state,
        lastUpdateDateTime: action.payload,
      };

    case 'ADD_ADDITIONAL_TIME':
      const newAddTimeState = Object.assign({}, state);
      newAddTimeState.studentsInProgress.pageItems =
        newAddTimeState.studentsInProgress.pageItems.map((n: any) =>
          n.id === action.payload.id &&
          n.assignmentId === action.payload.assignmentId &&
          n.subTestAcronym === action.payload.subTestAcronym
            ? {
                ...n,
                timeRemaining:
                  n.timeRemaining +
                  action.payload.miliseconds +
                  timeCorrection +
                  (Date.now() > n.timeRemaining ? Date.now() - n.timeRemaining : 0),
              }
            : n,
        );
      if (newAddTimeState.studentsInProgress.isClientSidePagination) {
        newAddTimeState.studentsInProgress.allItems =
          newAddTimeState.studentsInProgress.allItems.map((n: any) =>
            n.id === action.payload.id &&
            n.assignmentId === action.payload.assignmentId &&
            n.subTestAcronym === action.payload.subTestAcronym
              ? {
                  ...n,
                  timeRemaining:
                    n.timeRemaining +
                    action.payload.miliseconds +
                    timeCorrection +
                    (Date.now() > n.timeRemaining ? Date.now() - n.timeRemaining : 0),
                }
              : n,
          );
      }
      return newAddTimeState;

    case 'SELECT_OR_UNSELECT_STUDENT':
      const newSelectStudentState = Object.assign({}, state);
      if (action.payload.type === 'dashboard') {
        newSelectStudentState.studentsWaitingApproval.allItems =
          newSelectStudentState.studentsWaitingApproval.allItems.map((n: any) =>
            n.user_id === action.payload.user_id ? { ...n, checked: action.payload.value } : n,
          );
        newSelectStudentState.studentsWaitingApproval.pageItems =
          newSelectStudentState.studentsWaitingApproval.pageItems.map((n: any) =>
            n.user_id === action.payload.user_id ? { ...n, checked: action.payload.value } : n,
          );
      }
      if (action.payload.type === 'live-tests') {
        newSelectStudentState.studentsInProgress.pageItems =
          newSelectStudentState.studentsInProgress.pageItems.map((n: any) =>
            n.user_id === action.payload.user_id ? { ...n, checked: action.payload.value } : n,
          );
        if (newSelectStudentState.studentsInProgress.isClientSidePagination) {
          newSelectStudentState.studentsInProgress.allItems =
            newSelectStudentState.studentsInProgress.allItems.map((n: any) =>
              n.user_id === action.payload.user_id ? { ...n, checked: action.payload.value } : n,
            );
        }

        newSelectStudentState.studentsInProgress.isExtendTestingTimeDisabled =
          checkIsExtendTestingTimeDisabled(newSelectStudentState.studentsInProgress.pageItems);
      }

      if (action.payload.type === 'completed')
        newSelectStudentState.studentsCompleted.pageItems =
          newSelectStudentState.studentsCompleted.pageItems.map((n: any) =>
            n.user_id === action.payload.user_id ? { ...n, checked: action.payload.value } : n,
          );
      return newSelectStudentState;

    case 'SELECT_OR_UNSELECT_ALL_STUDENTS':
      const newSelectAllStudentsState = Object.assign({}, state);
      if (action.payload.type === 'dashboard') {
        newSelectAllStudentsState.studentsWaitingApproval.allItems =
          newSelectAllStudentsState.studentsWaitingApproval.allItems.map((n: any) =>
            n.isOnline ? { ...n, checked: action.payload.value } : n,
          );
        newSelectAllStudentsState.studentsWaitingApproval.pageItems =
          newSelectAllStudentsState.studentsWaitingApproval.pageItems.map((n: any) =>
            n.isOnline ? { ...n, checked: action.payload.value } : n,
          );
        /*        
        newSelectAllStudentsState.studentsWaitingApproval.allItems = newSelectAllStudentsState.studentsWaitingApproval.allItems.map((n: any) =>
          n.sessionStatusId !== 13 ? { ...n, checked: action.payload.value } : n
        );
        newSelectAllStudentsState.studentsWaitingApproval.pageItems = newSelectAllStudentsState.studentsWaitingApproval.pageItems.map((n: any) =>
          n.sessionStatusId !== 13 ? { ...n, checked: action.payload.value } : n
        );
*/
      }
      if (action.payload.type === 'live-tests') {
        newSelectAllStudentsState.studentsInProgress.pageItems =
          newSelectAllStudentsState.studentsInProgress.pageItems.map((n: any) =>
            n.isTestOnline ? { ...n, checked: action.payload.value } : n,
          );
        if (newSelectAllStudentsState.studentsInProgress.isClientSidePagination) {
          newSelectAllStudentsState.studentsInProgress.allItems =
            newSelectAllStudentsState.studentsInProgress.allItems.map((n: any) =>
              n.isTestOnline ? { ...n, checked: action.payload.value } : n,
            );
        }
        newSelectAllStudentsState.studentsInProgress.isExtendTestingTimeDisabled =
          checkIsExtendTestingTimeDisabled(newSelectAllStudentsState.studentsInProgress.pageItems);
      }
      //if (action.payload.type === 'completed') newSelectAllStudentsState.studentsCompleted.pageItems.map((n: any) => (n.checked = action.payload.value));
      return newSelectAllStudentsState;

    case 'CLICK_STUDENT_MUTE_BUTTON':
      const newMuteState = Object.assign({}, state);
      newMuteState.studentsWaitingApproval.allItems =
        newMuteState.studentsWaitingApproval.allItems.map((n: any) =>
          n.id === action.payload.id ? { ...n, isAudioOn: !n.isAudioOn } : n,
        );
      newMuteState.studentsWaitingApproval.pageItems =
        newMuteState.studentsWaitingApproval.pageItems.map((n: any) =>
          n.id === action.payload.id ? { ...n, isAudioOn: !n.isAudioOn } : n,
        );
      newMuteState.studentsInProgress.pageItems = newMuteState.studentsInProgress.pageItems.map(
        (n: any) => (n.id === action.payload.id ? { ...n, isAudioOn: !n.isAudioOn } : n),
      );
      if (newMuteState.studentsInProgress.isClientSidePagination) {
        newMuteState.studentsInProgress.allItems = newMuteState.studentsInProgress.allItems.map(
          (n: any) => (n.id === action.payload.id ? { ...n, isAudioOn: !n.isAudioOn } : n),
        );
      }
      newMuteState.studentsCompleted.pageItems = newMuteState.studentsCompleted.pageItems.map(
        (n: any) => (n.id === action.payload.id ? { ...n, isAudioOn: !n.isAudioOn } : n),
      );
      return newMuteState;

    case 'CLICK_STUDENT_VIDEO_BUTTON':
      const newVideoState = Object.assign({}, state);
      newVideoState.studentsWaitingApproval.allItems =
        newVideoState.studentsWaitingApproval.allItems.map((n: any) =>
          n.id === action.payload.id ? { ...n, isVideoOn: !n.isVideoOn } : n,
        );
      newVideoState.studentsWaitingApproval.pageItems =
        newVideoState.studentsWaitingApproval.pageItems.map((n: any) =>
          n.id === action.payload.id ? { ...n, isVideoOn: !n.isVideoOn } : n,
        );
      newVideoState.studentsInProgress.pageItems = newVideoState.studentsInProgress.pageItems.map(
        (n: any) => (n.id === action.payload.id ? { ...n, isVideoOn: !n.isVideoOn } : n),
      );
      if (newVideoState.studentsInProgress.isClientSidePagination) {
        newVideoState.studentsInProgress.allItems = newVideoState.studentsInProgress.allItems.map(
          (n: any) => (n.id === action.payload.id ? { ...n, isVideoOn: !n.isVideoOn } : n),
        );
      }
      newVideoState.studentsCompleted.pageItems = newVideoState.studentsCompleted.pageItems.map(
        (n: any) => (n.id === action.payload.id ? { ...n, isVideoOn: !n.isVideoOn } : n),
      );
      return newVideoState;

    case 'STUDENT_CARDS_UPDATE_REPORT_ADD_DATE_TIME':
      const newReportAddState = Object.assign({}, state);
      const currentDateTime = Date.now();
      const arrUserIds = action.payload.map((n: any) => n.id).join(',');
      newReportAddState.studentsInProgress.pageItems =
        newReportAddState.studentsInProgress.pageItems.map((n: any) =>
          arrUserIds.indexOf(n.user_id) !== -1
            ? { ...n, learnosityReportAddDateTime: currentDateTime }
            : n,
        );
      newReportAddState.studentsInProgress.allItems =
        newReportAddState.studentsInProgress.allItems.map((n: any) =>
          arrUserIds.indexOf(n.user_id) !== -1
            ? { ...n, learnosityReportAddDateTime: currentDateTime }
            : n,
        );
      return newReportAddState;

    case 'STUDENT_CARDS_APPROVE':
      const newApproveState = Object.assign({}, state);
      action.payload.forEach((student: any) => {
        if (newApproveState.studentsInProgress.isClientSidePagination) {
          const approvedStudents = newApproveState.studentsWaitingApproval.allItems.filter(
            (n: any) => {
              return n.user_id === student.user_id;
            },
          );
          if (approvedStudents.length === 1) {
            const approvedStudent = approvedStudents[0];
            approvedStudent.assignmentStatusId = 1;
            approvedStudent.sessionStatusId = 10;
            approvedStudent.status = 'InProgress';
            //approvedStudent.assignmentStatus = "In Progress";
            newApproveState.studentsInProgress.allItems.push(approvedStudent);
            newApproveState.studentsInProgress.pageItems.push(approvedStudent);
            newApproveState.studentsInProgress.totalCount =
              newApproveState.studentsInProgress.allItems.length;
          }
        }
        newApproveState.studentsWaitingApproval.allItems =
          newApproveState.studentsWaitingApproval.allItems.filter((n: any) => {
            return n.user_id !== student.user_id;
          });
        newApproveState.studentsWaitingApproval.pageItems =
          newApproveState.studentsWaitingApproval.pageItems.filter((n: any) => {
            return n.user_id !== student.user_id;
          });
      });
      newApproveState.studentsWaitingApproval.totalCount =
        newApproveState.studentsWaitingApproval.allItems.length;
      newApproveState.studentsWaitingApproval.isChanged = true;
      newApproveState.studentsInProgress.isChanged = true;
      return newApproveState;

    case 'STUDENT_CARDS_DENY':
      const newDenyState = Object.assign({}, state);
      action.payload.forEach((student: any) => {
        newDenyState.studentsWaitingApproval.allItems =
          newDenyState.studentsWaitingApproval.allItems.filter((n: any) => {
            return n.user_id !== student.user_id;
          });
        newDenyState.studentsWaitingApproval.pageItems =
          newDenyState.studentsWaitingApproval.pageItems.filter((n: any) => {
            return n.user_id !== student.user_id;
          });
      });
      newDenyState.studentsWaitingApproval.totalCount =
        newDenyState.studentsWaitingApproval.allItems.length;
      newDenyState.studentsWaitingApproval.isChanged = true;
      return newDenyState;

    case 'SEARCH_STUDENTS_WAITING_APPROVAL':
      const searchStudentsWaitingApprovalState = Object.assign({}, state);
      searchStudentsWaitingApprovalState.searchText = action.payload;
      searchStudentsWaitingApprovalState.studentsWaitingApproval.searchText = action.payload;
      searchStudentsWaitingApprovalState.studentsWaitingApproval.currentPage = 1;
      searchStudentsWaitingApprovalState.studentsWaitingApproval.isChanged = true;
      return searchStudentsWaitingApprovalState;

    case 'SEARCH_STUDENTS_API':
      const searchStudentsApiState = Object.assign({}, state);
      if (action.payload.type === 'live-tests') {
        searchStudentsApiState.searchText = action.payload.searchText;
        searchStudentsApiState.studentsInProgress.searchText = action.payload.searchText;
        searchStudentsApiState.studentsInProgress.currentPage = 1;
        searchStudentsApiState.studentsInProgress.isChanged = true;
      }
      if (action.payload.type === 'completed') {
        searchStudentsApiState.searchText = action.payload.searchText;
        searchStudentsApiState.studentsCompleted.searchText = action.payload.searchText;
        searchStudentsApiState.studentsCompleted.currentPage = 1;
        searchStudentsApiState.studentsCompleted.isChanged = true;
      }
      return searchStudentsApiState;

    case 'ORDER_STUDENTS':
      const orderedState = Object.assign({}, state);

      orderedState.orderingField = action.payload.field;
      orderedState.orderingDirection = action.payload.direction;

      if (action.payload.type === 'dashboard') {
        orderedState.studentsWaitingApproval.sortingField = action.payload.field;
        orderedState.studentsWaitingApproval.sortingDirection = action.payload.direction;
        orderedState.studentsWaitingApproval.isChanged = true;
      }
      if (action.payload.type === 'live-tests') {
        orderedState.studentsInProgress.sortingField = action.payload.field;
        orderedState.studentsInProgress.sortingDirection = action.payload.direction;
        orderedState.studentsInProgress.isChanged = true;
      }
      if (action.payload.type === 'completed') {
        orderedState.studentsCompleted.sortingField = action.payload.field;
        orderedState.studentsCompleted.sortingDirection = action.payload.direction;
        orderedState.studentsCompleted.isChanged = true;
      }
      return orderedState;

    case 'LOAD_WAITING_APPROVAL_PAGE':
      const waitingApprovalPageState = Object.assign({}, state);
      //sorting phase
      let result: any;
      if (waitingApprovalPageState.orderingDirection === 'asc')
        result = sortAsc(
          state.studentsWaitingApproval.allItems.slice(0),
          waitingApprovalPageState.orderingField,
        );
      else if (waitingApprovalPageState.orderingDirection === 'desc')
        result = sortDesc(
          state.studentsWaitingApproval.allItems.slice(0),
          waitingApprovalPageState.orderingField,
        );
      else result = waitingApprovalPageState.studentsWaitingApproval.allItems;
      //search phase
      result = result.filter((student: any) => {
        return (student.firstName + ' ' + student.lastName)
          .toLowerCase()
          .includes(waitingApprovalPageState.studentsWaitingApproval.searchText.toLowerCase());
      });
      waitingApprovalPageState.studentsWaitingApproval.allItemsAfterSearchNum = result.length;
      //pagination phase
      let pageNumber = action.payload.pageNumber;
      const pageSize = action.payload.pageSize;

      if (result.length && result.length < (pageNumber - 1) * pageSize + 1) {
        pageNumber = Math.floor(result.length / pageSize);
      }
      // let maxPageNumber = Math.floor(result.length / pageSize);
      // if (result.length % pageSize) maxPageNumber++;
      // if (!maxPageNumber) maxPageNumber = 1;
      // if (pageNumber > maxPageNumber) pageNumber = maxPageNumber;
      //waitingApprovalPageState.studentsWaitingApproval.maxPageNumber = maxPageNumber;

      const upperCount = pageSize * pageNumber;
      const lowerCount = upperCount - pageSize;
      waitingApprovalPageState.studentsWaitingApproval.pageItems = result.slice(
        lowerCount,
        upperCount,
      );
      return waitingApprovalPageState;

    case 'LOAD_IN_PROGRESS_PAGE':
      const inProgressPageState = Object.assign({}, state);
      //sorting phase
      let result2: any;
      if (inProgressPageState.orderingDirection === 'asc')
        result2 = sortAsc(
          state.studentsInProgress.allItems.slice(0),
          inProgressPageState.orderingField,
        );
      else if (inProgressPageState.orderingDirection === 'desc')
        result2 = sortDesc(
          state.studentsInProgress.allItems.slice(0),
          inProgressPageState.orderingField,
        );
      else result2 = inProgressPageState.studentsInProgress.allItems;
      //search phase
      result2 = result2.filter((student: any) => {
        return (student.firstName + ' ' + student.lastName)
          .toLowerCase()
          .includes(inProgressPageState.studentsInProgress.searchText.toLowerCase());
      });
      inProgressPageState.studentsInProgress.allItemsAfterSearchNum = result2.length;
      //pagination phase
      let pageNumber2 = action.payload.pageNumber;
      const pageSize2 = action.payload.pageSize;

      if (result2.length && result2.length < (pageNumber2 - 1) * pageSize2 + 1) {
        pageNumber2 = Math.floor(result2.length / pageSize2);
      }
      // let maxPageNumber2 = Math.floor(result2.length / pageSize2);
      // if (result2.length % pageSize2) maxPageNumber2++;
      // if (!maxPageNumber2) maxPageNumber2 = 1;
      // if (pageNumber2 > maxPageNumber2) pageNumber2 = maxPageNumber2;
      //inProgressPageState.studentsInProgress.maxPageNumber2 = maxPageNumber2;

      const upperCount2 = pageSize2 * pageNumber2;
      const lowerCount2 = upperCount2 - pageSize2;
      inProgressPageState.studentsInProgress.pageItems = result2.slice(lowerCount2, upperCount2);
      return inProgressPageState;

    case 'UNCHECK_ALL':
      let uncheckAllState = Object.assign({}, state);
      uncheckAllState = resetAllCheckboxesCheckedState(uncheckAllState);
      return uncheckAllState;

    case 'CHANGE_VIEW_TYPE':
      let orderingField = '';
      let orderingDirection = '';
      let searchText = '';
      if (action.payload === 'dashboard') {
        orderingField = state.studentsWaitingApproval.sortingField;
        orderingDirection = state.studentsWaitingApproval.sortingDirection;
        searchText = state.studentsWaitingApproval.searchText;
      }
      if (action.payload === 'live-tests') {
        orderingField = state.studentsInProgress.sortingField;
        orderingDirection = state.studentsInProgress.sortingDirection;
        searchText = state.studentsInProgress.searchText;
      }
      if (action.payload === 'completed') {
        orderingField = state.studentsCompleted.sortingField;
        orderingDirection = state.studentsCompleted.sortingDirection;
        searchText = state.studentsCompleted.searchText;
      }
      return {
        ...state,
        viewType: action.payload,
        orderingField: orderingField,
        orderingDirection: orderingDirection,
        searchText: searchText,
      };

    case 'CHANGE_PAGE_SIZE':
      const newChangePageSizeState = Object.assign({}, state);
      if (action.payload.countPerPage) {
        newChangePageSizeState.countPerPage = action.payload.countPerPage;
      }
      return newChangePageSizeState;

    case 'FLAG_UPDATING_IN_PROGRESS_STUDENTS':
      return {
        ...state,
        flagUpdatingInProgressStudents: action.payload,
      };

    case 'UPDATE_REQUEST_DENY_STUDENT':
      return {
        ...state,
        requestDenyStudent: action.payload,
      };

    case 'SET_CARDS_LIST_TO_PAUSE':
      const studentPauseState = resetAllCheckboxesCheckedState(state);
      studentPauseState.arrCardsToPause = action.payload;
      return studentPauseState;

    case 'SET_CARDS_LIST_TO_RESUME':
      const studentResumeState = resetAllCheckboxesCheckedState(state);
      studentResumeState.arrCardsToResume = action.payload;
      return studentResumeState;

    case 'SET_CARDS_LIST_TO_SAVE_AND_EXIT':
      const studentSaveAndExitState = resetAllCheckboxesCheckedState(state);
      studentSaveAndExitState.arrCardsToSaveAndExit = action.payload;
      return studentSaveAndExitState;

    case 'SET_CARDS_LIST_TO_CANCEL_AND_DISCARD':
      const studentCancelAndDiscardState = resetAllCheckboxesCheckedState(state);
      studentCancelAndDiscardState.arrCardsToCancelAndDiscard = action.payload;
      return studentCancelAndDiscardState;

    case 'SET_CARDS_LIST_TO_EXTEND_TIME':
      const studentExtendTimeState = resetAllCheckboxesCheckedState(state);
      studentExtendTimeState.arrStudentSessionGuidToExtendTime =
        action.payload.arrCheckedStudentSessionGuid;
      studentExtendTimeState.extendTimeMinutes = action.payload.extendTimeMinutes;
      studentExtendTimeState.studentsInProgress.pageItems =
        studentExtendTimeState.studentsInProgress.pageItems.map((n: any) =>
          action.payload.arrCheckedStudentSessionGuid.indexOf(n.sessionGuid) !== -1
            ? {
                ...n,
                timeRemaining:
                  n.timeRemaining +
                  action.payload.extendTimeMinutes * 60 * 1000 +
                  timeCorrection +
                  (Date.now() > n.timeRemaining ? Date.now() - n.timeRemaining : 0),
              }
            : n,
        );
      if (studentExtendTimeState.studentsInProgress.isClientSidePagination) {
        studentExtendTimeState.studentsInProgress.allItems =
          studentExtendTimeState.studentsInProgress.allItems.map((n: any) =>
            action.payload.arrCheckedStudentSessionGuid.indexOf(n.sessionGuid) !== -1
              ? {
                  ...n,
                  timeRemaining:
                    n.timeRemaining +
                    action.payload.extendTimeMinutes * 60 * 1000 +
                    timeCorrection +
                    (Date.now() > n.timeRemaining ? Date.now() - n.timeRemaining : 0),
                }
              : n,
          );
      }
      return studentExtendTimeState;

    case 'UPDATE_STUDENT_PROGRESS_FROM_LEARNOSITY_CUSTOM_EVENT':
      const newStudentProgressState = Object.assign({}, state);
      isGhostEvent = false;
      if (newStudentProgressState.isProctorPageRefresh) {
        const currentDateTime = Date.now();
        if (
          currentDateTime - newStudentProgressState.lastGhostEventTime <
            ghostEventsIgnoreInterval &&
          (!newStudentProgressState.studentsInProgress.isClientSidePagination ||
            (newStudentProgressState.studentsInProgress.isClientSidePagination &&
              action.payload.totalQuestions === 0))
        ) {
          //console.log("currentDateTime=" + currentDateTime);
          //console.log("lastGhostEventTime=" + newStudentProgressState.lastGhostEventTime);
          //console.log("time difference=" + (currentDateTime - newStudentProgressState.lastGhostEventTime));

          newStudentProgressState.lastGhostEventTime = currentDateTime;
          // console.log('GHOST EVENT! (reducer UPDATE_STUDENT_PROGRESS_FROM_LEARNOSITY_CUSTOM_EVENT)');
          isGhostEvent = true;
        } else {
          newStudentProgressState.lastGhostEventTime = 0;
          newStudentProgressState.isProctorPageRefresh = false;
          //console.log('NORMAL EVENT!');
        }
      }
      if (!isGhostEvent) {
        newStudentProgressState.studentsInProgress.pageItems =
          newStudentProgressState.studentsInProgress.pageItems.map((n: any) =>
            n.user_id === action.payload.user_id
              ? {
                  ...n,
                  currentItem: action.payload.currentItem,
                  totalItems: action.payload.totalItems,
                  attemptedQuestions: action.payload.attemptedQuestions,
                  totalQuestions: action.payload.totalQuestions,
                  timeRemaining:
                    action.payload.timeRemaining === null
                      ? n.timeRemaining
                      : action.payload.timeRemaining + timeCorrection,
                  isTestProgressBarVisible: action.payload.isTestProgressBarVisible,
                  //liveStatus: "",
                  //isLiveStatusChanging: false,
                  isOnline: n.isOnline,
                  isTestOnline: n.isOnline ? true : n.isTestOnline,
                  browserName: action.payload.browserName,
                  browserVersion: action.payload.browserVersion,
                  osName: action.payload.osName,
                  osVersion: action.payload.osVersion,
                  device: action.payload.device,
                  customTimer: action.payload.customTimer,
                  sampleItemCount: action.payload.sampleItemCount,
                  testItemCount: action.payload.testItemCount,
                }
              : n,
          );
        if (newStudentProgressState.studentsInProgress.isClientSidePagination) {
          newStudentProgressState.studentsInProgress.allItems =
            newStudentProgressState.studentsInProgress.allItems.map((n: any) =>
              n.user_id === action.payload.user_id
                ? {
                    ...n,
                    currentItem: action.payload.currentItem,
                    totalItems: action.payload.totalItems,
                    attemptedQuestions: action.payload.attemptedQuestions,
                    totalQuestions: action.payload.totalQuestions,
                    timeRemaining:
                      action.payload.timeRemaining === null
                        ? n.timeRemaining
                        : action.payload.timeRemaining + timeCorrection,
                    isTestProgressBarVisible: action.payload.isTestProgressBarVisible,
                    //liveStatus: "",
                    //isLiveStatusChanging: false,
                    isOnline: n.isOnline,
                    isTestOnline: n.isOnline ? true : n.isTestOnline,
                    browserName: action.payload.browserName,
                    browserVersion: action.payload.browserVersion,
                    osName: action.payload.osName,
                    osVersion: action.payload.osVersion,
                    device: action.payload.device,
                    customTimer: action.payload.customTimer,
                    sampleItemCount: action.payload.sampleItemCount,
                    testItemCount: action.payload.testItemCount,
                  }
                : n,
            );
        }

        newStudentProgressState.studentsWaitingApproval.pageItems =
          newStudentProgressState.studentsWaitingApproval.pageItems.map((n: any) =>
            n.user_id === action.payload.user_id
              ? {
                  ...n,
                  currentItem: action.payload.currentItem,
                  totalItems: action.payload.totalItems,
                  attemptedQuestions: action.payload.attemptedQuestions,
                  totalQuestions: action.payload.totalQuestions,
                  timeRemaining:
                    action.payload.timeRemaining === null
                      ? n.timeRemaining
                      : action.payload.timeRemaining + timeCorrection,
                  isTestProgressBarVisible: action.payload.isTestProgressBarVisible,
                  //liveStatus: "",
                  //isLiveStatusChanging: false,
                  isOnline: n.isOnline,
                  browserName: action.payload.browserName,
                  browserVersion: action.payload.browserVersion,
                  osName: action.payload.osName,
                  osVersion: action.payload.osVersion,
                  device: action.payload.device,
                  customTimer: action.payload.customTimer,
                  sampleItemCount: action.payload.sampleItemCount,
                  testItemCount: action.payload.testItemCount,
                }
              : n,
          );
        newStudentProgressState.studentsWaitingApproval.allItems =
          newStudentProgressState.studentsWaitingApproval.allItems.map((n: any) =>
            n.user_id === action.payload.user_id
              ? {
                  ...n,
                  currentItem: action.payload.currentItem,
                  totalItems: action.payload.totalItems,
                  attemptedQuestions: action.payload.attemptedQuestions,
                  totalQuestions: action.payload.totalQuestions,
                  timeRemaining:
                    action.payload.timeRemaining === null
                      ? n.timeRemaining
                      : action.payload.timeRemaining + timeCorrection,
                  isTestProgressBarVisible: action.payload.isTestProgressBarVisible,
                  //liveStatus: "",
                  //isLiveStatusChanging: false,
                  isOnline: n.isOnline,
                  browserName: action.payload.browserName,
                  browserVersion: action.payload.browserVersion,
                  osName: action.payload.osName,
                  osVersion: action.payload.osVersion,
                  device: action.payload.device,
                  customTimer: action.payload.customTimer,
                  sampleItemCount: action.payload.sampleItemCount,
                  testItemCount: action.payload.testItemCount,
                }
              : n,
          );
        newStudentProgressState.studentsInProgress.isExtendTestingTimeDisabled =
          checkIsExtendTestingTimeDisabled(newStudentProgressState.studentsInProgress.pageItems);

        newStudentProgressState.studentsCompleted.pageItems =
          newStudentProgressState.studentsCompleted.pageItems.map((n: any) =>
            n.user_id === action.payload.user_id
              ? {
                  ...n,
                  isOnline: true,
                }
              : n,
          );
      }
      return newStudentProgressState;

    case 'UPDATE_STUDENTS_PROGRESS_FROM_API':
      const newStudentsProgressState = Object.assign({}, state);
      let convertedProgress: any;
      let foundStudent: any;
      let timeRemaining: any;
      //const additionalEmptyQuestionsNum = 2;
      const additionalEmptyQuestionsNum = 0;

      if (action.payload.length) {
        action.payload.map((studentProgress: any) => {
          if (newStudentsProgressState.studentsInProgress.isClientSidePagination) {
            foundStudent = newStudentsProgressState.studentsInProgress.allItems.find(
              (n: any) => n.user_id === studentProgress.user_id,
            );
          } else {
            foundStudent = newStudentsProgressState.studentsInProgress.pageItems.find(
              (n: any) => n.user_id === studentProgress.user_id,
            );
          }
          if (foundStudent !== undefined) {
            convertedProgress = convertStudentProgress(studentProgress, foundStudent);
            //timeRemaining = Date.now() + studentProgress.session_duration * 60 * 1000 + timeCorrection;

            //console.log(convertedProgress);
            newStudentsProgressState.studentsInProgress.pageItems =
              newStudentsProgressState.studentsInProgress.pageItems.map((n: any) =>
                n.user_id === studentProgress.user_id
                  ? {
                      ...n,
                      attemptedQuestions: convertedProgress.attemptedQuestions,
                      totalQuestions: convertedProgress.totalQuestions,
                      isTestProgressBarVisible: false,
                      //currentItem: n.assignmentType === "IowaFlex Math" || n.assignmentType === "IowaFlex Reading" ? convertedProgress.attemptedQuestions : n.currentItem,
                      //currentItem: convertedProgress.attemptedQuestions,
                      //totalItems: n.totalItems ? n.totalItems : convertedProgress.totalQuestions + additionalEmptyQuestionsNum,
                    }
                  : n,
              );
            if (newStudentsProgressState.studentsInProgress.isClientSidePagination) {
              newStudentsProgressState.studentsInProgress.allItems =
                newStudentsProgressState.studentsInProgress.allItems.map((n: any) =>
                  n.user_id === studentProgress.user_id
                    ? {
                        ...n,
                        attemptedQuestions: convertedProgress.attemptedQuestions,
                        totalQuestions: convertedProgress.totalQuestions,
                        isTestProgressBarVisible: false,
                        //currentItem: n.assignmentType === "IowaFlex Math" || n.assignmentType === "IowaFlex Reading" ? convertedProgress.attemptedQuestions : n.currentItem,
                        //currentItem: convertedProgress.attemptedQuestions,
                        //totalItems: n.totalItems ? n.totalItems : convertedProgress.totalQuestions + additionalEmptyQuestionsNum,
                      }
                    : n,
                );
            }
          }

          foundStudent = newStudentsProgressState.studentsWaitingApproval.allItems.find(
            (n: any) => n.user_id === studentProgress.user_id && n.sessionStatusId === 13,
          );
          if (foundStudent !== undefined) {
            convertedProgress = convertStudentProgress(studentProgress, foundStudent);

            newStudentsProgressState.studentsWaitingApproval.pageItems =
              newStudentsProgressState.studentsWaitingApproval.pageItems.map((n: any) =>
                n.user_id === studentProgress.user_id
                  ? {
                      ...n,
                      attemptedQuestions: convertedProgress.attemptedQuestions,
                      totalQuestions: convertedProgress.totalQuestions,
                      isTestProgressBarVisible: false,
                    }
                  : n,
              );
            newStudentsProgressState.studentsWaitingApproval.allItems =
              newStudentsProgressState.studentsWaitingApproval.allItems.map((n: any) =>
                n.user_id === studentProgress.user_id
                  ? {
                      ...n,
                      attemptedQuestions: convertedProgress.attemptedQuestions,
                      totalQuestions: convertedProgress.totalQuestions,
                      isTestProgressBarVisible: false,
                    }
                  : n,
              );
          }
        });
      }
      //console.log(newStudentsProgressState);
      return newStudentsProgressState;

    case 'UPDATE_STUDENT_IS_LIVE_STATUS_CHANGING':
      const isLiveStatusChangingState = Object.assign({}, state);
      isLiveStatusChangingState.studentsInProgress.pageItems =
        isLiveStatusChangingState.studentsInProgress.pageItems.map((n: any) =>
          action.payload.indexOf(n.sessionGuid) !== -1 ? { ...n, isLiveStatusChanging: true } : n,
        );
      if (isLiveStatusChangingState.studentsInProgress.isClientSidePagination) {
        isLiveStatusChangingState.studentsInProgress.allItems =
          isLiveStatusChangingState.studentsInProgress.allItems.map((n: any) =>
            action.payload.indexOf(n.sessionGuid) !== -1 ? { ...n, isLiveStatusChanging: true } : n,
          );
      }
      return isLiveStatusChangingState;

    case 'UPDATE_STUDENT_LIVE_STATUS':
      const newStudentLiveStatusState = Object.assign({}, state);
      isGhostEvent = false;
      if (newStudentLiveStatusState.isProctorPageRefresh) {
        const currentDateTime = Date.now();
        if (
          currentDateTime - newStudentLiveStatusState.lastGhostEventTime <
          ghostEventsIgnoreInterval
        ) {
          newStudentLiveStatusState.lastGhostEventTime = currentDateTime;
          //console.log("GHOST EVENT! (reducer UPDATE_STUDENT_LIVE_STATUS)");
          isGhostEvent = true;
        } else {
          newStudentLiveStatusState.lastGhostEventTime = 0;
          newStudentLiveStatusState.isProctorPageRefresh = false;
          //console.log("NORMAL EVENT!");
        }
      }
      if (!isGhostEvent) {
        newStudentLiveStatusState.studentsInProgress.pageItems =
          newStudentLiveStatusState.studentsInProgress.pageItems.map((n: any) =>
            action.payload.user_ids.indexOf(n.user_id) !== -1
              ? {
                  ...n,
                  liveStatus: action.payload.liveStatus,
                  isLiveStatusChanging: false,
                  timePaused:
                    action.payload.liveStatus === 'paused' ||
                    action.payload.liveStatus === 'pause_on_submit_popup'
                      ? Date.now()
                      : 0,
                  timeRemaining:
                    n.timeRemaining &&
                    n.timePaused &&
                    (n.liveStatus === 'paused' || n.liveStatus === 'pause_on_submit_popup') &&
                    action.payload.liveStatus === '' &&
                    n.timeRemaining + Date.now() - n.timePaused,
                }
              : n,
          );
        if (newStudentLiveStatusState.studentsInProgress.isClientSidePagination) {
          newStudentLiveStatusState.studentsInProgress.allItems =
            newStudentLiveStatusState.studentsInProgress.allItems.map((n: any) =>
              action.payload.user_ids.indexOf(n.user_id) !== -1
                ? {
                    ...n,
                    liveStatus: action.payload.liveStatus,
                    isLiveStatusChanging: false,
                    timePaused:
                      action.payload.liveStatus === 'paused' ||
                      action.payload.liveStatus === 'pause_on_submit_popup'
                        ? Date.now()
                        : 0,
                    timeRemaining:
                      n.timeRemaining &&
                      n.timePaused &&
                      (n.liveStatus === 'paused' || n.liveStatus === 'pause_on_submit_popup') &&
                      action.payload.liveStatus === '' &&
                      n.timeRemaining + Date.now() - n.timePaused,
                  }
                : n,
            );
        }
      }
      return newStudentLiveStatusState;

    case 'UPDATE_STUDENT_TEST_COMPLETED':
      const newStudentTestCompleted = Object.assign({}, state);
      let beforeCountStudentsInProgress = 0;
      let isFound = false;
      if (action.payload.user_id === undefined) {
        //signallR event from Lambda without user_id
        if (action.payload.studentId !== undefined)
          action.payload.studentid = action.payload.studentId;
        if (action.payload.assignmentId !== undefined)
          action.payload.assignmentid = action.payload.assignmentId;
        if (action.payload.subTestAcronym !== undefined)
          action.payload.subtestacronym = action.payload.subTestAcronym;
        if (action.payload.assignmentType !== undefined)
          action.payload.assignmenttype = action.payload.assignmentType;

        action.payload.user_id = action.payload.studentid + '_' + action.payload.assignmentid;
        if (
          action.payload.assignmenttype === 'IowaFlex Math' ||
          action.payload.assignmenttype === 'IowaFlex Reading'
        )
          action.payload.user_id += '_F_NA';
        else action.payload.user_id += '_C_' + action.payload.subtestacronym;
      }

      if (newStudentTestCompleted.studentsInProgress.isClientSidePagination) {
        beforeCountStudentsInProgress = newStudentTestCompleted.studentsInProgress.allItems.length;
        newStudentTestCompleted.studentsInProgress.allItems =
          newStudentTestCompleted.studentsInProgress.allItems.filter((n: any) => {
            //return !(n.id === action.payload.studentid && n.assignmentId === action.payload.assignmentid && n.subTestAcronym === action.payload.subtestacronym);
            return !(n.user_id === action.payload.user_id);
          });
        if (
          newStudentTestCompleted.studentsInProgress.allItems.length < beforeCountStudentsInProgress
        ) {
          isFound = true;
        }
      }

      beforeCountStudentsInProgress = newStudentTestCompleted.studentsInProgress.pageItems.length;
      newStudentTestCompleted.studentsInProgress.pageItems =
        newStudentTestCompleted.studentsInProgress.pageItems.filter((n: any) => {
          //return !(n.id === action.payload.studentid && n.assignmentId === action.payload.assignmentid && n.subTestAcronym === action.payload.subtestacronym);
          return !(n.user_id === action.payload.user_id);
        });
      if (
        newStudentTestCompleted.studentsInProgress.pageItems.length < beforeCountStudentsInProgress
      ) {
        isFound = true;
      }

      if (isFound) {
        newStudentTestCompleted.studentsInProgress.totalCount =
          newStudentTestCompleted.studentsInProgress.totalCount - 1;
        if (
          !newStudentTestCompleted.studentsWaitingApproval.allItems.length &&
          !newStudentTestCompleted.studentsInProgress.totalCount
        ) {
          newStudentTestCompleted.flagAllStudentscompleted = true;
        }
        newStudentTestCompleted.studentsInProgress.isChanged = true;
        newStudentTestCompleted.studentsCompleted.isChanged = true;
      }

      return newStudentTestCompleted;

    case 'UPDATE_STUDENT_TEST_COMPLETED_JUST_INCREASE_TOTAL_COUNT':
      const newUpdateStudentsTestCompletedTotalCount = Object.assign({}, state);
      newUpdateStudentsTestCompletedTotalCount.studentsCompleted.totalCount =
        newUpdateStudentsTestCompletedTotalCount.studentsCompleted.totalCount + action.payload;
      return newUpdateStudentsTestCompletedTotalCount;

    case 'UPDATE_STUDENT_AUDIO_LANGUAGE':
      const newStudentAudioLanguage = Object.assign({}, state);
      newStudentAudioLanguage.studentsWaitingApproval.pageItems =
        newStudentAudioLanguage.studentsWaitingApproval.pageItems.map((n: any) =>
          n.user_id === action.payload.user_id
            ? {
                ...n,
                testAdminTypeId: action.payload.testAdminTypeId,
                testAdminTypeCode: action.payload.testAdminTypeCode,
                testAdminTypeDesc: action.payload.testAdminTypeDesc,
              }
            : n,
        );
      newStudentAudioLanguage.studentsWaitingApproval.allItems =
        newStudentAudioLanguage.studentsWaitingApproval.allItems.map((n: any) =>
          n.user_id === action.payload.user_id
            ? {
                ...n,
                testAdminTypeId: action.payload.testAdminTypeId,
                testAdminTypeCode: action.payload.testAdminTypeCode,
                testAdminTypeDesc: action.payload.testAdminTypeDesc,
              }
            : n,
        );
      return newStudentAudioLanguage;

    case 'UPDATE_STUDENT_SIGNAL_LEVEL':
      const newStudentSignalLevel = Object.assign({}, state);
      action.payload.forEach(function (item: ConnectionSpeedInfo, index: number) {
        if (item.pingTime && item.pongTime > item.pingTime) {
          newStudentSignalLevel.studentsWaitingApproval.pageItems =
            newStudentSignalLevel.studentsWaitingApproval.pageItems.map((n: any) =>
              n.studentId === item.studentId
                ? {
                    ...n,
                    signalLevel: item.signalLevel,
                    isOnline: true,
                    browserTypeId: item.browserTypeId,
                    browserName: item.browserName,
                    browserVersion: item.browserVersion,
                    osName: item.osName,
                    osVersion: item.osVersion,
                    device: item.device,
                  }
                : n,
            );
          newStudentSignalLevel.studentsWaitingApproval.allItems =
            newStudentSignalLevel.studentsWaitingApproval.allItems.map((n: any) =>
              n.studentId === item.studentId
                ? {
                    ...n,
                    signalLevel: item.signalLevel,
                    isOnline: true,
                    browserTypeId: item.browserTypeId,
                    browserName: item.browserName,
                    browserVersion: item.browserVersion,
                    osName: item.osName,
                    osVersion: item.osVersion,
                    device: item.device,
                  }
                : n,
            );
          newStudentSignalLevel.studentsInProgress.pageItems =
            newStudentSignalLevel.studentsInProgress.pageItems.map((n: any) =>
              n.studentId === item.studentId
                ? {
                    ...n,
                    signalLevel: item.signalLevel,
                    //isOnline: true,
                    browserTypeId: item.browserTypeId,
                    browserName: item.browserName,
                    browserVersion: item.browserVersion,
                    osName: item.osName,
                    osVersion: item.osVersion,
                    device: item.device,
                  }
                : n,
            );
          if (newStudentSignalLevel.studentsInProgress.isClientSidePagination) {
            newStudentSignalLevel.studentsInProgress.allItems =
              newStudentSignalLevel.studentsInProgress.allItems.map((n: any) =>
                n.studentId === item.studentId
                  ? {
                      ...n,
                      signalLevel: item.signalLevel,
                      //isOnline: true,
                      browserTypeId: item.browserTypeId,
                      browserName: item.browserName,
                      browserVersion: item.browserVersion,
                      osName: item.osName,
                      osVersion: item.osVersion,
                      device: item.device,
                    }
                  : n,
              );
          }
          newStudentSignalLevel.studentsCompleted.pageItems =
            newStudentSignalLevel.studentsCompleted.pageItems.map((n: any) =>
              n.studentId === item.studentId
                ? {
                    ...n,
                    signalLevel: item.signalLevel,
                    isOnline: true,
                    browserTypeId: item.browserTypeId,
                    browserName: item.browserName,
                    browserVersion: item.browserVersion,
                    osName: item.osName,
                    osVersion: item.osVersion,
                    device: item.device,
                  }
                : n,
            );
        }
      });
      return newStudentSignalLevel;

    case 'UPDATE_IS_STUDENT_ONLINE':
      const newIsStudentOnline = Object.assign({}, state);
      newIsStudentOnline.studentsWaitingApproval.pageItems =
        newIsStudentOnline.studentsWaitingApproval.pageItems.map((n: any) =>
          Number(n.studentId) === Number(action.payload.studentId)
            ? {
                ...n,
                isOnline: action.payload.isOnline,
                checked: action.payload.isOnline === false ? false : n.checked,
              }
            : n,
        );
      newIsStudentOnline.studentsWaitingApproval.allItems =
        newIsStudentOnline.studentsWaitingApproval.allItems.map((n: any) =>
          Number(n.studentId) === Number(action.payload.studentId)
            ? {
                ...n,
                isOnline: action.payload.isOnline,
                checked: action.payload.isOnline === false ? false : n.checked,
              }
            : n,
        );
      newIsStudentOnline.studentsInProgress.pageItems =
        newIsStudentOnline.studentsInProgress.pageItems.map((n: any) =>
          Number(n.studentId) === Number(action.payload.studentId)
            ? {
                ...n,
                isOnline: action.payload.isOnline,
                isTestOnline: action.payload.isOnline === false ? false : n.isTestOnline,
                checked: action.payload.isOnline === false ? false : n.checked,
                liveStatus:
                  action.payload.isOnline === false && n.liveStatus !== 'paused'
                    ? ''
                    : n.liveStatus,
              }
            : n,
        );
      if (newIsStudentOnline.studentsInProgress.isClientSidePagination) {
        newIsStudentOnline.studentsInProgress.allItems =
          newIsStudentOnline.studentsInProgress.allItems.map((n: any) =>
            Number(n.studentId) === Number(action.payload.studentId)
              ? {
                  ...n,
                  isOnline: action.payload.isOnline,
                  isTestOnline: action.payload.isOnline === false ? false : n.isTestOnline,
                  checked: action.payload.isOnline === false ? false : n.checked,
                  liveStatus:
                    action.payload.isOnline === false && n.liveStatus !== 'paused'
                      ? ''
                      : n.liveStatus,
                }
              : n,
          );
      }
      newIsStudentOnline.studentsCompleted.pageItems =
        newIsStudentOnline.studentsCompleted.pageItems.map((n: any) =>
          Number(n.studentId) === Number(action.payload.studentId)
            ? {
                ...n,
                isOnline: action.payload.isOnline,
              }
            : n,
        );

      return newIsStudentOnline;

    case 'UPDATE_IS_STUDENT_TEST_ONLINE':
      const newIsStudentTestOnline = Object.assign({}, state);
      newIsStudentTestOnline.studentsInProgress.pageItems =
        newIsStudentTestOnline.studentsInProgress.pageItems.map((n: any) =>
          n.user_id === action.payload.userId
            ? {
                ...n,
                isTestOnline: action.payload.isTestOnline,
                liveStatus:
                  action.payload.isTestOnline === false && n.liveStatus !== 'paused'
                    ? ''
                    : n.liveStatus,
              }
            : n,
        );
      if (newIsStudentTestOnline.studentsInProgress.isClientSidePagination) {
        newIsStudentTestOnline.studentsInProgress.allItems =
          newIsStudentTestOnline.studentsInProgress.allItems.map((n: any) =>
            n.user_id === action.payload.userId
              ? {
                  ...n,
                  isTestOnline: action.payload.isTestOnline,
                  liveStatus:
                    action.payload.isTestOnline === false && n.liveStatus !== 'paused'
                      ? ''
                      : n.liveStatus,
                }
              : n,
          );
      }
      return newIsStudentTestOnline;

    case 'UPDATE_INSUFFICIENT_LICENSE_COUNT':
      const newStudentInsufficientLicenseCount = Object.assign({}, state);
      newStudentInsufficientLicenseCount.studentsWaitingApproval.pageItems =
        newStudentInsufficientLicenseCount.studentsWaitingApproval.pageItems.map((n: any) =>
          n.user_id === action.payload.user_id
            ? {
                ...n,
                isInsufficientLicenseCount: action.payload.isInsufficientLicenseCount,
                willBurnLicenseType: action.payload.willBurnLicenseType,
              }
            : n,
        );
      newStudentInsufficientLicenseCount.studentsWaitingApproval.allItems =
        newStudentInsufficientLicenseCount.studentsWaitingApproval.allItems.map((n: any) =>
          n.user_id === action.payload.user_id
            ? {
                ...n,
                isInsufficientLicenseCount: action.payload.isInsufficientLicenseCount,
                willBurnLicenseType: action.payload.willBurnLicenseType,
              }
            : n,
        );
      return newStudentInsufficientLicenseCount;

    case 'SHOW_DIALOG':
      return {
        ...state,
        showDialog: action.payload,
      };

    case 'REMEMBER_LAST_SELECTED_CARD':
      return {
        ...state,
        lastSelectedCard: action.payload,
      };

    case 'SHOW_HIDE_SPINNER':
      return {
        ...state,
        isSpinnerVisible: action.payload,
      };

    case 'UPDATE_FLAG_ALL_STUDENTS_COMPLETED':
      return {
        ...state,
        flagAllStudentscompleted: action.payload,
      };

    case 'UPDATE_FLAG_PROCTOR_REFRESH':
      return {
        ...state,
        isProctorPageRefresh: action.payload,
        //lastGhostEventTime: action.payload ? Date.now() : 0,
      };

    case 'TIMER_TICK_GHOST_EVENTS_FINISHED':
      return {
        ...state,
        timerTickGhostEventsFinished: Date.now(),
      };

    case 'UPDATE_STUDENT_TEST_COMPLETED_IS_CHANGED':
      const newUpdateStudentsTestCompletedIsChanged = Object.assign({}, state);
      newUpdateStudentsTestCompletedIsChanged.studentsCompleted.isChanged = action.payload;
      return newUpdateStudentsTestCompletedIsChanged;

    case 'UPDATE_STUDENTS_IN_PROGRESS_IS_CLIENT_SIDE_PAGINATION':
      const newUpdateStudentsInProgressIsCliendSidePagination = Object.assign({}, state);
      newUpdateStudentsInProgressIsCliendSidePagination.studentsInProgress.isClientSidePagination =
        action.payload;
      newUpdateStudentsInProgressIsCliendSidePagination.studentsInProgress.isPaginationTypeChanging =
        true;
      return newUpdateStudentsInProgressIsCliendSidePagination;

    case 'UPDATE_STUDENTS_IN_PROGRESS_IS_PAGINATION_TYPE_CHANGING':
      const newUpdateStudentsInProgressIsPaginationTypeChanging = Object.assign({}, state);
      newUpdateStudentsInProgressIsPaginationTypeChanging.studentsInProgress.isPaginationTypeChanging =
        action.payload;
      return newUpdateStudentsInProgressIsPaginationTypeChanging;

    case 'UPDATE_PROCTOR_CLOSED_ASSIGNMENTS':
      const newUpdateProctorClosedAssignments = Object.assign({}, state); //clone the state
      const arrProctorClosedAssignmentIds = action.payload.map(
        (n: IClosedAssignment) => n.assignmentId,
      );
      newUpdateProctorClosedAssignments.arrProctorClosedAssignments = action.payload;
      newUpdateProctorClosedAssignments.arrProctorClosedAssignmentIds =
        arrProctorClosedAssignmentIds;
      return newUpdateProctorClosedAssignments;

    case 'RESET_ALL_PROCTORING_DATA_BEFORE_UNMOUNT':
      const newResetData = Object.assign({}, state); //clone the state
      newResetData.studentsWaitingApproval.allItems = [];
      newResetData.studentsWaitingApproval.pageItems = [];
      newResetData.studentsWaitingApproval.totalCount = 0;
      newResetData.studentsInProgress.allItems = [];
      newResetData.studentsInProgress.pageItems = [];
      newResetData.studentsInProgress.totalCount = 0;
      newResetData.studentsCompleted.pageItems = [];
      newResetData.studentsCompleted.totalCount = 0;
      return newResetData;

    default:
      return state;
  }
};

export default studentListReducer;
