import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import usePlatform from '../../../hooks/usePlatform';
import ErrorPage from '../../../pages/ErrorPage';

import UnsupportedBrowser from '../../UnsupportedBrowser/UnsupportedBrowser';
import SessionTimeoutTimer from '../SessionTimeoutTimer/SessionTimeoutTimer';
import useNotificationHelper from '../../../hooks/useNotificationHelper';

import AppSectionAdmin from './AppSectionAdmin';
import AppSectionAssignments from './AppSectionAssignments';
import AppSectionProctoring from './AppSectionProctoring';
import AppSectionStudent from './AppSectionStudent';
import AppLayoutReporting from './AppSectionReporting';
import useLogRocket from '../../../hooks/useLogRocket/useLogRocket';
import useAblyClient from '../../../hooks/ably/useAblyClient';
import { BooleanFlags, useBooleanFlagWithConfig } from '../../../flags';

const AppRoot: React.FunctionComponent = () => {
  const { supported: platformSupported } = usePlatform();
  // Hook instances from child components get unmounted with component.
  // this is here to serve as a 'root' level hook
  // whose useEffects will react to SPA location changes
  // and eventually clear leftover notifications when
  // user navigates to another page in SPA context
  useNotificationHelper();

  useAblyClient({
    initialize: true,
    namespace: 'COMPONENT|AppRoot',
  });

  // Hook gives Logrocket identity to the logged in user
  useLogRocket();

  const { isEnabled: testingIsDisabled } = useBooleanFlagWithConfig(BooleanFlags.DisableTesting);

  if (!platformSupported) {
    return <UnsupportedBrowser />;
  }

  return (
    <React.Fragment>
      <SessionTimeoutTimer />
      {/* <Suspense fallback={<OverlayLoader className="app-loader-root suspense-overlay-loader" title="Please Wait" message="Initializing" />}> */}
      <Switch>
        <Redirect
          exact={true}
          from="/"
          to="/administration/user/dashboard/assignments"
          push={false}
        />

        <Route path="/administration" component={AppSectionAdmin} />
        <Route path="/assignment" component={AppSectionAssignments} />
        <Route path="/proctoring" component={AppSectionProctoring} />
        <Route
          path="/student"
          component={
            testingIsDisabled
              ? () => {
                  window.location.href = 'https://info.riversideinsights.com/elevatetodatamanger';
                  return null;
                }
              : AppSectionStudent
          }
        />
        <Route path="/reporting" component={AppLayoutReporting} />

        <Route render={() => <ErrorPage httpErrorCode={404} />} />
      </Switch>
      {/* </Suspense> */}
    </React.Fragment>
  );
};

export default AppRoot;
