import React, { ReactNode, useEffect, useState } from 'react';
import styles from './DateRangePicker.module.scss';
import classNames from 'classnames';
import { DayPicker, DayPickerRangeProps, ActiveModifiers } from 'react-day-picker';
import { Popper, ClickAwayListener } from '@mui/material';
import moment from 'moment';
// import defaultStyles from 'react-day-picker/dist/style.module.css';
import 'react-day-picker/dist/style.css';
import '../../../../shared/CalendarRangePicker/CalendarRangePicker.css';

export interface DateRangePickerProps {
  className?: string;
  datesLabel?: string;
  dayPickerProps?: DayPickerRangeProps;
  disabled?: boolean;
  error?: boolean;
  initialEndDate?: Date;
  initialStartDate?: Date;
  onChange?: (startDate?: Date, endDate?: Date) => void;
  onFocusChange?: (focusedInput: InputFocus | null) => void;
  startIcon?: ReactNode;
}

export type InputFocus = 'startDate' | 'endDate';

const DateRangePicker = ({
  className,
  datesLabel,
  dayPickerProps,
  disabled,
  error,
  initialEndDate,
  initialStartDate,
  onChange,
  onFocusChange,
  startIcon,
}: DateRangePickerProps) => {
  const [openDayPicker, setOpenDayPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [startDate, setStartDate] = useState<Date | null>(initialStartDate || null);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate || null);

  const [inputFocus, setInputFocus] = useState<InputFocus | null>(null);

  useEffect(() => {
    if (openDayPicker) {
      setInputFocus('startDate');
    } else {
      setInputFocus(null);
    }
  }, [openDayPicker]);

  useEffect(() => {
    if (startDate) {
      setInputFocus('endDate');
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setOpenDayPicker(false);
    }
  }, [endDate]);

  useEffect(() => {
    onChange?.(startDate || undefined, endDate || undefined);
  }, [startDate, endDate, onChange]);

  useEffect(() => {
    onFocusChange?.(inputFocus);
  }, [inputFocus, onFocusChange]);

  useEffect(() => {
    if (startDate && endDate && startDate > endDate) {
      setEndDate(null);
    }
  }, [startDate, endDate]);

  return (
    <ClickAwayListener onClickAway={() => setOpenDayPicker(false)}>
      <div className={className}>
        <button
          className={classNames(styles['change-dates-button'], {
            [styles['error']]: error,
          })}
          disabled={disabled}
          onClick={() => setOpenDayPicker(!openDayPicker)}
          ref={setAnchorEl}
        >
          {startIcon && <div className={styles['start-icon-container']}>{startIcon}</div>}

          <div className={styles['dates']}>
            {datesLabel && `${datesLabel} `}
            <span className={styles['date']}>{`${
              startDate ? moment(startDate).format('MMM-DD-YYYY') : 'Start Date'
            } - ${endDate ? moment(endDate).format('MMM-DD-YYYY') : 'End Date'}`}</span>
          </div>
        </button>

        <Popper className={styles['day-picker-popper']} open={openDayPicker} anchorEl={anchorEl}>
          <DayPicker
            defaultMonth={initialStartDate}
            mode="range"
            numberOfMonths={2}
            onDayClick={(day: Date, activeModifiers: ActiveModifiers, e: React.MouseEvent) => {
              if (inputFocus === 'startDate') {
                setStartDate(day);
              } else if (inputFocus === 'endDate') {
                setEndDate(day);
              }
            }}
            selected={{ from: startDate || undefined, to: endDate || undefined }}
            {...dayPickerProps}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default DateRangePicker;
