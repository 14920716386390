import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import {
  // useLocation,
  useHistory,
} from 'react-router';

import { RouteComponentProps } from 'react-router-dom';

import usePageTitle from '../../hooks/usePageTitle';
import useCachedUser from '../../hooks/useCachedUser';
import useAppLogger from '../../hooks/useAppLogger';

// import useApiHelper from '../../hooks/useApiHelper';
// import useAppStatus from '../../hooks/useAppStatus';

import { AppLoader } from '@riversideinsights/elevate-react-lib';

// import './OrderHistory.css';

const CheckSessionPage: React.FC<RouteComponentProps> = (props) => {
  const staffUser = useCachedUser();
  const dispatch = useDispatch();
  // const location = useLocation();
  const history = useHistory();
  const logger = useAppLogger('Component|CheckSessionPage');

  const [nextUrl, setNextUrl] = useState('');
  // const ApiHelper = useApiHelper('Component|CheckSessionPage');
  // const NotificationHelper = useNotificationHelper();

  // const appStatus = useAppStatus();

  usePageTitle('Please Wait');

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.has('next')) {
      setNextUrl(`${url.searchParams.get('next')}`);
    } else {
      setNextUrl('/administration/user/dashboard/assignments');
    }
    dispatch({ type: 'SET_APP_BUSY', payload: true });
    dispatch({ type: 'SET_APP_BUSY_MESSAGE', payload: 'Redirecting' });
    return () => {
      dispatch({ type: 'SET_APP_BUSY', payload: false });
    };
  }, []);

  useEffect(() => {
    if (staffUser.loggedIn && staffUser.loadComplete && nextUrl) {
      logger.info(`Redirecting to ${nextUrl}`);
      if (nextUrl.substring(0, 1) === '/') {
        dispatch({ type: 'SET_APP_BUSY', payload: false });
        history.replace(nextUrl);
      } else {
        window.location.href = nextUrl;
      }
    }
  }, [staffUser.loggedIn, staffUser.loadComplete, nextUrl]);

  return (
    <div className="page-wrapper order-history-page">
      <AppLoader title="Please Wait" message="Redirecting" />
    </div>
  );
};

export default CheckSessionPage;
