import React, { useEffect, useMemo, useState } from 'react';

// import LinearProgress from '@mui/material/LinearProgress';

// import ErrorIcon from '@mui/icons-material/Error';
// import useCachedUser from '../../../../hooks/useCachedUser';
import { useDispatch } from 'react-redux';
import { ModalDialog } from '@riversideinsights/elevate-react-lib';
// import useNotificationHelper from '../../../../hooks/useNotificationHelper';
// import { StaffAuthUser, StaffAuthUserUpdate } from '../../../../reducers/Auth/authDataTypes';
// import UserHelper from '../../../../lib/helper/UserHelper';
// import useAppLogger from '../../../../hooks/useAppLogger';
import { ApiRequestInstance } from '../../../../lib/helper/ApiHelper.d';
import AppDebugApi from './AppDebugApi';
// import { Button } from '@mui/material';

export interface AppApiDebugRequestModalProps {
  modalVisible: boolean;
  // request: ApiRequestInstance|null;
  hideModal?: () => void;
}

const AppApiDebugRequestModal: React.FC<AppApiDebugRequestModalProps> = (props) => {
  // const user = useCachedUser();
  // const logger = useAppLogger('Component|AppApiDebugRequestModal');
  const dispatch = useDispatch();
  // const NotificationHelper = useNotificationHelper();
  // const userDataFieldRef = useRef<HTMLTextAreaElement | null>(null);

  const hideModal = () => {
    setLocalRequestInstance(null);
    if (props?.hideModal) {
      props.hideModal();
    }
  };

  const [localRequestInstance, setLocalRequestInstance] = useState<ApiRequestInstance | null>(null);

  // useEffect(() => {
  //   if (typeof props.request !== 'undefined' && props.request !== null && localRequestInstance === props.request) {
  //     setLocalRequestInstance(props.request);
  //   }
  // }, [props.request]);

  const modalBody = useMemo<React.ReactNode | null>(() => {
    return (
      <div className="app-debug-api-section-root">
        <AppDebugApi />
        <div>
          {localRequestInstance !== null && (
            <div>
              <div>{localRequestInstance.requestId}</div>
              {typeof localRequestInstance.requestConfig.url !== 'undefined' && (
                <div>{localRequestInstance.requestConfig.url}</div>
              )}
              {typeof localRequestInstance.requestConfig.method !== 'undefined' && (
                <div>{localRequestInstance.requestConfig.method}</div>
              )}
              <div>
                <pre>{JSON.stringify(localRequestInstance.requestConfig, null, ' ')}</pre>
              </div>
            </div>
          )}
        </div>
      </div>
    );
    // if (localRequestInstance !== null) {
    //   return (
    //     <div>
    //       <div>
    //         { localRequestInstance.requestId }
    //       </div>
    //       { typeof localRequestInstance.requestConfig.url !== 'undefined' &&
    //         (
    //           <div>
    //             { localRequestInstance.requestConfig.url }
    //           </div>
    //         )
    //       }
    //       { typeof localRequestInstance.requestConfig.method !== 'undefined' &&
    //         (
    //           <div>
    //             { localRequestInstance.requestConfig.method }
    //           </div>
    //         )
    //       }
    //       <div>
    //         <pre>
    //           { JSON.stringify(localRequestInstance.requestConfig, null, ' ') }
    //         </pre>
    //       </div>
    //     </div>
    //   );
    // }
    // return null;
  }, [localRequestInstance]);

  useEffect(() => {
    if (props.modalVisible) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="app-debug-test-modal app-api-debug-modal"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            title="API Request Info"
            // footer={
            //   <div className="app-debug-modal-footer">
            //     <Button
            //       variant="outlined"
            //       color="primary"
            //       size="small"
            //       onClick={ hideModal }
            //     >
            //       Close
            //     </Button>
            //   </div>
            // }
            body={<div className="app-api-debug-modal-contents">{modalBody}</div>}
            onClose={() => {
              hideModal();
            }}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [props.modalVisible, modalBody]);
  return <></>;
};

export default AppApiDebugRequestModal;
