const initState = {
  isVisible: false,
  arrStudentsToDeny: [],
  arrStudentsToApprove: [],
};

const popupDenyReasonsReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'TOGGLE_POPUP_DENY_REASONS':
      return {
        ...state,
        isVisible: action.payload,
      };
    case 'SET_STUDENT_LIST_TO_DENY':
      return {
        ...state,
        arrStudentsToDeny: action.payload,
      };
    case 'SET_STUDENT_LIST_TO_APPROVE':
      return {
        ...state,
        arrStudentsToApprove: action.payload,
      };
    default:
      return state;
  }
};

export default popupDenyReasonsReducer;
