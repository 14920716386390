import appStatusReducer from './appStatusReducer';
import authReducer from './Auth/authReducer';
import proctorReducer from './Proctoring/Proctor/proctorReducer';
import studentReducer from './Proctoring/Student/studentReducer';
import ablyReducer from './Ably/ablyReducer';
import { Reducer, combineReducers } from 'redux';

import { AppState } from './appDataTypes';

/**
 * Root application store reducer
 * @type {Reducer<AppState>}
 */
const appReducer: Reducer<AppState> = combineReducers<AppState>({
  appStatus: appStatusReducer,
  auth: authReducer,
  proctor: proctorReducer,
  student: studentReducer,
  ably: ablyReducer,
});

export default appReducer;
