import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import useUserType from './useUserType';
import { StaffAuthUser, StudentAuthUser } from '../reducers/Auth/authDataTypes';
import { AppState } from '../reducers/appDataTypes';

/**
 * Gets proctor code for current user no matter the type / role
 * Returns an empty string if there's no valid user.
 *
 * @returns {string} User proctor code if available
 */
const useProctorCode = (): string => {
  const userType = useUserType();
  const studentUser: StudentAuthUser = useSelector((appState: AppState) => {
    return appState.auth.studentAuth.user;
  });
  const staffUser: StaffAuthUser = useSelector((appState: AppState) => {
    return appState.auth.staffAuth.user;
  });

  const [proctorCode, setProctorCode] = useState('');

  useEffect(() => {
    let currentProctorCode = '';
    if (userType === 'student') {
      if (studentUser.loggedIn && studentUser.loadComplete && studentUser.proctorCode) {
        currentProctorCode = studentUser.proctorCode;
      }
    } else if (
      userType === 'staff' &&
      staffUser.loggedIn &&
      staffUser.loadComplete &&
      staffUser.currentProctorCode
    ) {
      currentProctorCode = staffUser.currentProctorCode;
    }
    setProctorCode(currentProctorCode);
  }, [
    userType,
    studentUser.loggedIn,
    studentUser.loadComplete,
    studentUser.proctorCode,
    staffUser.loggedIn,
    staffUser.loadComplete,
    staffUser.currentProctorCode,
  ]);

  return proctorCode;
};

export default useProctorCode;
