import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import useUserType from './useUserType';
import { StaffAuthUser, StudentAuthUser } from '../reducers/Auth/authDataTypes';
import { AppState } from '../reducers/appDataTypes';

/**
 * Gets `customerId` for current user no matter the type / role
 * Returns an empty string if there's no valid user.
 *
 * @returns {string} User customer ID if available
 */
const useCustomerId = (): string => {
  const userType = useUserType();
  const studentUser: StudentAuthUser = useSelector((appState: AppState) => {
    return appState.auth.studentAuth.user;
  });
  const staffUser: StaffAuthUser = useSelector((appState: AppState) => {
    return appState.auth.staffAuth.user;
  });

  const [customerId, setCustomerId] = useState('');

  useEffect(() => {
    let currentCustomerId = '';
    if (
      userType === 'student' &&
      studentUser.loggedIn &&
      studentUser.loadComplete &&
      studentUser.info.customerId
    ) {
      currentCustomerId = studentUser.info.customerId;
    } else if (
      userType === 'staff' &&
      staffUser.loggedIn &&
      staffUser.loadComplete &&
      staffUser.customerId
    ) {
      currentCustomerId = staffUser.customerId;
    }
    setCustomerId(currentCustomerId);
  }, [
    userType,
    studentUser.loggedIn,
    studentUser.loadComplete,
    studentUser.info.customerId,
    staffUser.loggedIn,
    staffUser.loadComplete,
    staffUser.customerId,
  ]);

  return customerId;
};

export default useCustomerId;
