import { useRef, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

import AppHelper from '../lib/helper/AppHelper';
import LogHelper from '../lib/helper/LogHelper';
import AppApiHelper from '../lib/helper/AppApiHelper';

import { AppState } from '../reducers/appDataTypes';

import { ApiRequestInstance } from '../lib/helper/ApiHelper.d';
import { AuthState, AuthStateCognito } from '../reducers/Auth/authDataTypes';

/**
 * Provides ready-to-use instance of ApiHelper throughout the application.
 *
 * @param {string} namespace Namespace under which to log messages from helper instance
 *
 * @returns {AppApiHelper} Instance of AppApiHelper
 */
const useApiHelper = (namespace = 'Unknown|Unknown'): AppApiHelper => {
  const config = useConfigContext();
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * An array of active requests for this helper instance
   */
  const activeRequests = useRef<ApiRequestInstance[]>([]);

  /**
   * App auth state from redux store
   */
  const authState: AuthState = useSelector((state: AppState) => {
    return state.auth;
  });

  /**
   * Staff users cognito state from redux store
   */
  const cognitoState: AuthStateCognito = useSelector((state: AppState) => {
    return state.auth.staffAuth.cognito;
  });

  /**
   * Student users cognito state from redux store
   */
  const studentCognitoState: AuthStateCognito = useSelector((state: AppState) => {
    return state.auth.studentAuth.cognito;
  });

  /**
   * ApiHelper instance that hook sets up and returns for components to use
   *
   * If there's no student nor faculty cognito session to be found in redux, helper is
   * initialized without authorization set up for API calls.
   *
   * Once either cognito session gets initialized, hook will re-instantiate the helper it
   * returns with authorization set up based on session for active user type.
   */
  const [apiHelper, setApiHelper] = useState<AppApiHelper>(
    AppHelper.getCookieValue(config.session.student.idTokenCookie)
      ? new AppApiHelper(config, authState, 'student', dispatch, history, activeRequests.current)
      : new AppApiHelper(config, authState, 'staff', dispatch, history, activeRequests.current),
  );

  /**
   * Internal flag to keep track of sessions
   */
  const [sessionSet, setSessionSet] = useState(false);

  /**
   * Cancels all cancelable active requests in helper instance.
   *
   * @param {boolean} force Force cancelling all requests without regard to their setup
   *
   * @returns {undefined}
   */
  const cancelActiveRequests = (force = false) => {
    const cancellableRequests =
      force === true
        ? activeRequests.current
        : activeRequests.current.filter((val) => !val.preventAutoCancel);
    if (cancellableRequests.length > 0) {
      LogHelper.info(
        config,
        `Canceling${force === true ? ' (forced)' : ''} ${
          cancellableRequests.length
        } active API requests due to component being unmounted.`,
        'useApiHelper|' + namespace,
      );
      apiHelper.removeAllApiRequestEntries(force);
    }
  };

  useEffect(() => {
    if (
      cognitoState.cognitoUserSession === null &&
      studentCognitoState.cognitoUserSession === null
    ) {
      LogHelper.debug(
        config,
        'API Helper hook instance is initialized without cognito session for authorization.',
        'HOOK|useApiHelper',
      );
    }
    return () => {
      cancelActiveRequests();
    };
  }, []);

  useEffect(() => {
    if (cognitoState.cognitoUserSession !== null && !sessionSet) {
      setSessionSet(true);
      LogHelper.info(
        config,
        'This API Helper hook instance is now using staff cognito session for authentication',
        'HOOK|useApiHelper',
      );
      cancelActiveRequests(true);
      const newApiHelper = new AppApiHelper(
        config,
        authState,
        'staff',
        dispatch,
        history,
        activeRequests.current,
      );
      setApiHelper(newApiHelper);
    }
  }, [cognitoState.cognitoUserSession]);

  useEffect(() => {
    if (studentCognitoState.cognitoUserSession !== null && !sessionSet) {
      setSessionSet(true);
      LogHelper.info(
        config,
        'This API Helper hook instance is now using student cognito session for authentication',
        'HOOK|useApiHelper',
      );
      cancelActiveRequests(true);
      const newApiHelper = new AppApiHelper(
        config,
        authState,
        'student',
        dispatch,
        history,
        activeRequests.current,
      );
      setApiHelper(newApiHelper);
    }
  }, [studentCognitoState.cognitoUserSession]);

  return apiHelper;
};

export default useApiHelper;
