import styles from './MidpointDateRangePicker.module.scss';
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
// import '../../../shared/Calendar.scss';
// import { AnchorDirectionShape, DateRangePicker, FocusedInputShape } from 'react-dates';
import React, {
  // this comment prevents prettier from collapsing the brackets
  useEffect,
  useState,
} from 'react';

import MidpointDate from '../Calendar/MidpointDate/MidpointDate';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import InputHelperText from './InputHelperText/InputHelperText';
import DateRangePicker, { DateRangePickerProps } from './DateRangePicker/DateRangePicker';

export interface MidpointDateRangePickerProps {
  className?: string;
  dateRangePickerProps?: DateRangePickerProps;
  disabled?: boolean;
  endDate: Moment; // momentPropTypes.momentObj or null,
  error?: boolean;
  helperText?: string;
  midpointDate?: Moment | null;
  midpointDateLocked?: boolean;
  onDatesChange: ({
    startDate,
    endDate,
    midpointDate,
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
    midpointDate: moment.Moment | null;
  }) => void; // PropTypes.func.isRequired,
  showMidpointDate?: boolean;
  startDate: Moment; // momentPropTypes.momentObj or null,
}

const getMidpointDate = (date1: Date, date2: Date) => {
  return new Date((date1.getTime() + date2.getTime()) / 2);
};

const MidpointDateRangePicker = ({
  className,
  dateRangePickerProps,
  disabled,
  endDate: endDateProp,
  error,
  helperText,
  midpointDate: midpointDateProp,
  midpointDateLocked = false,
  onDatesChange: onDatesChangeProp,
  showMidpointDate = true,
  startDate: startDateProp,
}: MidpointDateRangePickerProps) => {
  const [startDate, setStartDate] = useState(startDateProp);
  const [endDate, setEndDate] = useState(endDateProp);
  const [midpointDate, setMidpointDate] = useState<moment.Moment | null>(null);

  useEffect(() => {
    if (startDateProp) {
      setStartDate(startDateProp);
    }
  }, [startDateProp]);

  useEffect(() => {
    if (endDateProp) {
      setEndDate(endDateProp);
    }
  }, [endDateProp]);

  useEffect(() => {
    if (startDate && endDate) {
      const mDate = moment(
        getMidpointDate(startDate.startOf('day').toDate(), endDate.startOf('day').toDate()),
      );
      setMidpointDate(mDate);
      onDatesChangeProp({ startDate, endDate, midpointDate: mDate });
    }
  }, [startDate, endDate]);

  if (showMidpointDate) {
    return (
      <div
        className={classNames(styles['calendar-container'], className, {
          [styles['error']]: error,
        })}
      >
        <InputHelperText className={styles['helper-text']} error={error} helperText={helperText} />

        <DateRangePicker
          className={styles['calendar-range-picker']}
          disabled={disabled}
          error={error}
          onChange={(newStartDate: Date | undefined, newEndDate: Date | undefined) => {
            if (newStartDate && !moment(newStartDate).isSame(startDate, 'date')) {
              setStartDate(moment(newStartDate));
            }

            if (newEndDate && !moment(newEndDate).isSame(endDate, 'date')) {
              setEndDate(moment(newEndDate));
            }
          }}
          initialStartDate={startDate.toDate()}
          initialEndDate={endDate.toDate()}
          {...dateRangePickerProps}
        />

        <MidpointDate
          className={classNames(
            styles['midpoint-date'],
            midpointDateLocked && styles['midpoint-date-locked'],
          )}
          locked={midpointDateLocked}
          lockIconClassName={styles['midpoint-date-lock-icon']}
          midpointDate={midpointDateProp?.toDate() || midpointDate?.toDate()}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames(styles['calendar-container'], className, {
        [styles['error']]: error,
      })}
    >
      <InputHelperText className={styles['helper-text']} error={error} helperText={helperText} />

      <DateRangePicker
        className={styles['calendar-range-picker']}
        disabled={disabled}
        error={error}
        onChange={(newStartDate: Date | undefined, newEndDate: Date | undefined) => {
          if (newStartDate && !moment(newStartDate).isSame(startDate, 'date')) {
            setStartDate(moment(newStartDate));
          }

          if (newEndDate && !moment(newEndDate).isSame(endDate, 'date')) {
            setEndDate(moment(newEndDate));
          }
        }}
        initialStartDate={startDate.toDate()}
        initialEndDate={endDate.toDate()}
        {...dateRangePickerProps}
      />
    </div>
  );
};

export default MidpointDateRangePicker;
