import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@mui/material/styles';
import { primaryTheme } from '@elevate-ui/react-lib';

import App from './App';
import * as serviceWorker from './serviceWorker';

import ConfigLoader from './components/ConfigLoader/ConfigLoader';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient();

window.ElevatePlatform = {
  lastUserActivity: Infinity,
};

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={primaryTheme}>
        <ConfigLoader>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </ConfigLoader>
      </ThemeProvider>
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

serviceWorker.unregister();
