import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers/appDataTypes';
import LogRocket from 'logrocket';
import { formatProctorCode, getRoleLabel } from '../../lib/util/user.util';
import { BooleanFlags, useBooleanFlagWithConfig } from '../../flags';
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

const useLogRocket = () => {
  const config = useConfigContext();
  const { isEnabled } = useBooleanFlagWithConfig(BooleanFlags.LogRocket_Recording);
  const staffUser = useSelector((state: AppState) => {
    return state.auth.staffAuth.user;
  });
  const studentUser = useSelector((state: AppState) => {
    return state.auth.studentAuth.user;
  });

  const logrocketConfig = {
    console: {
      shouldAggregateConsoleErrors: true, // escalate log.error to the LogRocket issues view
    },
  };

  // once per instantiation of the hook, initialize logrocket
  // if the feature flag is enabled
  useEffect(() => {
    if (isEnabled) {
      LogRocket.init(config.logRocketAppId, logrocketConfig);
    }
  }, [isEnabled]);

  useEffect(() => {
    if (isEnabled) {
      if (staffUser.loggedIn && staffUser.loadComplete) {
        LogRocket.identify(staffUser.userId, {
          'District Name': staffUser.districtName ? staffUser.districtName : '',
          'Proctor Code': formatProctorCode(staffUser.currentProctorCode),
          name: staffUser.name,
          email: staffUser.email,
          'Organization ID': staffUser.customerId,
          role: getRoleLabel(staffUser.role),
        });
      } else if (studentUser.loggedIn && studentUser.loadComplete) {
        LogRocket.identify(studentUser.studentId, {
          'Organization ID': studentUser.info.customerId,
          'Proctor Code': formatProctorCode(studentUser.info.proctorCode),
          name: `${studentUser.info.firstName} ${studentUser.info.lastName}`,
          role: getRoleLabel(studentUser.role),
        });
      }
    }
  }, [isEnabled, staffUser, studentUser]);
};

export default useLogRocket;
