import React from 'react';

import useAppStatus from '../../../hooks/useAppStatus';
import SpinnerIcon from '../icons/SpinnerIcon';
import './SectionLoader.css';

const SectionLoader: React.FunctionComponent = () => {
  const appStatus = useAppStatus();
  return (
    <div className="app-loader-root section-loader-root">
      <h2 className="app-loader-title">Please Wait</h2>
      <div className="app-loader-body">
        <div className="loader-spinner">
          <SpinnerIcon />
        </div>
        <div className="app-loader-message">
          {appStatus.appBusy && `${appStatus.appBusyMessage}`}
          {appStatus.loadingUser && !appStatus.appBusy && 'Authenticating'}
        </div>
      </div>
    </div>
  );
};

export default SectionLoader;
