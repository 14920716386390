import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SplitFactory } from '@splitsoftware/splitio-react';

import '@riversideinsights/elevate-react-lib/dist/elevate-react-lib.css';
import './assets/css/variables.css';
import './assets/css/App.css';
import './assets/css/base.css';
import './assets/css/forms.css';
import './assets/css/responsive.css';

import elevateInfo from './elevate-version';
import useAppLogger from './hooks/useAppLogger';

import AppRoot from './components/shared/AppRoot/AppRoot';

import appStore from './ReduxAppStore';
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import AWS from 'aws-sdk';

const App: React.FunctionComponent = () => {
  const config = useConfigContext();
  const elevateVersionEnvInfo = `Elevate app v${elevateInfo.version}, running on ${config.appEnvironment} environment`;
  const logger = useAppLogger('COMPONENT|App');
  const splitCoreObj = {
    core: {
      authorizationKey: config.splitApiKey,
      key: `elevate-ui-initial-key-${config.appEnvironment}-${elevateInfo.version}`,
    },
  };

  AWS.config.region = config.awsConfig.region;
  logger.forceLog(elevateVersionEnvInfo);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Provider store={appStore}>
        <SplitFactory config={splitCoreObj}>
          <AppRoot />
        </SplitFactory>
      </Provider>
    </Router>
  );
};

export default App;
