import React from 'react';
import classNames from 'classnames';
import styles from './InputHelperText.module.scss';

interface InputHelperTextProps {
  className?: string;
  error?: boolean;
  helperText?: string;
}

const InputHelperText = ({ className, error, helperText }: InputHelperTextProps) => {
  if (helperText) {
    return (
      <div
        className={classNames(styles['helper-text'], className, {
          [styles['error']]: error,
        })}
      >
        {helperText}
      </div>
    );
  }

  return null;
};

export default InputHelperText;
