import React from 'react';

import './TermsAndPrivacyAccept.css';

import TermsAndConditions from '../static/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../static/PrivacyPolicy/PrivacyPolicy';

const TermsAndPrivacyAccept: React.FC = () => {
  return (
    <div className="terms-privacy-accept">
      <TermsAndConditions />
      <PrivacyPolicy />
    </div>
  );
};

export default TermsAndPrivacyAccept;
