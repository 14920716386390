import { AppReduxAction } from '../../appDataTypes';

const initState = {
  activeTab: 'dashboard',
  activeTabTitle: 'Not Started',
};

const headerContentReducer = (state = initState, action: AppReduxAction) => {
  switch (action.type) {
    case 'CHANGE_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload,
        activeTabTitle:
          action.payload === 'dashboard'
            ? 'Not Started'
            : action.payload === 'live-tests'
            ? 'In Progress'
            : 'Completed',
      };
    default:
      return state;
  }
};

export default headerContentReducer;
