import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import PrintTestTakerTickets from '../../components/TestAssignment/PrintTestTakerTickets/PrintTestTakerTickets';

type EditAssignmentRouteParams = {
  assignmentId?: string;
};

const TestAssignmentPrintPage: React.FC<RouteComponentProps> = () => {
  const params = useParams<EditAssignmentRouteParams>();

  const [assignmentId, setAssignmentId] = useState(0);

  useEffect(() => {
    if (params?.assignmentId) {
      setAssignmentId(+params.assignmentId);
    } else if (assignmentId !== 0) {
      setAssignmentId(0);
    }
  }, [params?.assignmentId]);

  return (
    <div className="page-wrapper assignments-page">
      {assignmentId !== 0 && <PrintTestTakerTickets assignmentId={assignmentId} />}
    </div>
  );
};

export default TestAssignmentPrintPage;
