import { AppReduxAction } from '../../appDataTypes';

export interface StudentCurrentTestState {
  isUpdateTestProgressInfo: boolean;
  isStudentLogoutStartedFromTestPage: boolean;
}

const initState: StudentCurrentTestState = {
  isUpdateTestProgressInfo: false,
  isStudentLogoutStartedFromTestPage: false,
};

const studentCurrentTestReducer = (
  state = initState,
  action: AppReduxAction,
): StudentCurrentTestState => {
  switch (action.type) {
    case 'UPDATE_TEST_PROGRESS_INFO':
      return {
        ...state,
        isUpdateTestProgressInfo: action.payload,
      };
    case 'IS_STUDENT_LOGOUT_STARTED_FROM_TEST_PAGE':
      return {
        ...state,
        isStudentLogoutStartedFromTestPage: action.payload,
      };
    default:
      return state;
  }
};

export default studentCurrentTestReducer;
