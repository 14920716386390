import { NormSeasonType } from './NormSeason/NormSeason';

export const MAX_DAYS_TEST_WINDOW_DURATION_COGAT_COMPLETE = 90,
  MAX_DAYS_TEST_WINDOW_DURATION_COGAT_SCREENING = 90,
  MAX_DAYS_TEST_WINDOW_DURATION_COGAT_POST_SCREENER = 60,
  MAX_DAYS_CREATE_TEST_ASSIGNMENT_IN_PAST = 90,
  MAX_DAYS_CREATE_TEST_ASSIGNMENT_IN_FUTURE = 90;

/**
 * getAdjacentNormSeasons returns an array of norm seasons adjacent to the normSeason.
 * @param {NormSeasonType} normSeason Base norm season to calculate adjacency from
 * @return {NormSeasonType[]} An array of norm seasons adjacent to the normSeason
 */
export function getAdjacentNormSeasons(normSeason: NormSeasonType): NormSeasonType[] {
  if (normSeason === 'Fall') {
    return ['Fall', 'Midyear'];
  } else if (normSeason === 'Midyear') {
    return ['Midyear', 'Spring'];
  }

  return ['Spring', 'Fall'];
}

/**
 * isAdjacentNormSeason tests whether potentiallyAdjacentNormSeason is
 * a norm season adjacent to the baseNormSeason.
 * @param {NormSeasonType} baseNormSeason Base norm season to calculate adjacency from
 * @param {NormSeasonType} potentiallyAdjacentNormSeason Potentially adjacent season to the baseNormSeason
 * @return {boolean} Returns true if potentiallyAdjacentNormSeason is adjacent to the baseNormSeason, false otherwise.
 */
export function isAdjacentNormSeason(
  baseNormSeason: NormSeasonType,
  potentiallyAdjacentNormSeason: NormSeasonType,
): boolean {
  return getAdjacentNormSeasons(baseNormSeason).includes(potentiallyAdjacentNormSeason);
}

/**
 * isCogATScreeningAndPostScreenerFormAssignmentType tests whether assignmentType is
 * one of the CogAT Screening & Post-Screener assignment types.
 * @param {NormSeasonType} assignmentType Assignment type.
 * @return {boolean} Returns true if assignmentType is either "CogAT Screening & Post-Screener Form 7"
 * or "CogAT Screening & Post-Screener Form 8", false otherwise.
 */
export function isCogATScreeningAndPostScreenerFormAssignmentType(assignmentType: string) {
  switch (assignmentType) {
    case 'CogAT Screening & Post-Screener Form 7':
    case 'CogAT Screening & Post-Screener Form 8':
      return true;
  }

  return false;
}
