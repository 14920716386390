import React from 'react';

import TableBody from '@mui/material/TableBody';

import { ElevateTableProps, ElevateTableRecord } from './ElevateTable.d';

import ElevateTableRow from './ElevateTableRow';

const ElevateTableBody = <T extends ElevateTableRecord>(
  props: ElevateTableProps<T>,
): React.ReactElement | null => {
  if (props.tableData?.items && props.tableData.items?.length) {
    const TableRow = props.tableData.options.tableComponents?.row
      ? props.tableData.options.tableComponents?.row
      : ElevateTableRow;

    return (
      <TableBody role="rowgroup">
        {props.tableData.items.map((item, index) => {
          return (
            <TableRow
              key={`tr_${index}`}
              index={index}
              item={item}
              tableData={props.tableData}
              onChange={props.onChange}
            />
          );
        })}
      </TableBody>
    );
  } else if (props.tableData.status.loading) {
    return (
      <TableBody role="rowgroup" className="elevate-table-loading-body">
        <tr>
          <td colSpan={props.tableData.columns.length}>
            <div className="elevate-table-loading-body-title">
              {props.tableData.options.loadingTitle || 'Loading'}
            </div>
            <div className="elevate-table-loading-body-message">
              {props.tableData.options.loadingMessage || 'Please wait'}
            </div>
          </td>
        </tr>
      </TableBody>
    );
  } else if (props.tableData.status.errorMessage) {
    return (
      <TableBody role="rowgroup" className="elevate-table-error-body">
        <tr>
          <td colSpan={props.tableData.columns.length}>
            <div className="elevate-table-error-body-title">
              {props.tableData.status.errorTitle}
            </div>
            <div className="elevate-table-error-body-message">
              {props.tableData.status.errorMessage}
            </div>
          </td>
        </tr>
      </TableBody>
    );
  }
  return null;
};

export default ElevateTableBody;
