import React from 'react';

// hooks
import usePageTitle from '../hooks/usePageTitle';
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

const WebReporting = () => {
  const config = useConfigContext();
  usePageTitle('CogAT Dashboard');

  const reportingUrl = `${config.webReportingDomain}/BackDoor/GetElevateWithCookie`;

  return (
    <div className="page-wrapper profile-page">
      <iframe
        style={{
          border: 0,
          height: 'calc(100vh - var(--app-header-height)',
        }}
        title="web-reporting"
        src={reportingUrl}
      />
    </div>
  );
};

export default WebReporting;
