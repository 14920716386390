import React, { useMemo, useEffect } from 'react';

import { Switch, Route, Redirect, RouteComponentProps, NavLink } from 'react-router-dom';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import AppHelper from '../../../lib/helper/AppHelper';

// Load hooks
import useAppLogger from '../../../hooks/useAppLogger';
import useStaffUser from '../../../hooks/useStaffUser';
import useAppStatus from '../../../hooks/useAppStatus';

// Load layout elements
import AppNotificationList from '../AppNotification/AppNotificationList';
import AppHeader from '../AppHeader/AppHeader';
import AppDebug from '../AppDebug/AppDebug';
import AppModals from '../AppModals/AppModals';
import ErrorPage from '../../../pages/ErrorPage';
import { Tooltip } from '@mui/material';

// Load section pages
import TestAssignmentListPage from '../../../pages/assignments/TestAssignmentListPage';
import TestAssignmentTrashPage from '../../../pages/assignments/TestAssignmentTrashPage';
import TestAssignmentCreatePage from '../../../pages/assignments/TestAssignmentCreatePage';
import TestAssignmentPrintPage from '../../../pages/assignments/TestAssignmentPrintPage';
import useWrapperClasses from '../../../hooks/useWrapperClasses';
import SectionLoader from '../AppSection/SectionLoader';
import SectionError from '../AppSection/SectionError';
import { BooleanFlags, useBooleanFlagWithConfig } from '../../../flags';

import './AppSectionAssignments.css';

const AppSectionAssignments: React.FunctionComponent = () => {
  const config = useConfigContext();
  // Note that this should be the ONLY call to useStaffUser and it should be invoked only once per (async) page load.
  // Page components should use useCachedUser instead.
  const user = useStaffUser();

  const logger = useAppLogger('COMPONENT|AppSectionAssignments');
  const debugAllowed = AppHelper.getCookieValue('elevateDebugEnabled') ? true : config.local;

  const appStatus = useAppStatus();
  const { isEnabled: testingIsDisabled } = useBooleanFlagWithConfig(BooleanFlags.DisableTesting);

  const isPermissionCRUDAssignments = useMemo<boolean>(() => {
    if (user.role !== 'district_admin') return false; //TODO temporary disabled permission for SA and teachers
    return !!user.currentPermissions.includes(72); //permissionName: 'TA-CRUDAssignments'
  }, [user.role, user.currentPermissions]);

  useEffect(() => {
    logger.debug('Mounting Assignments section');
    return () => {
      logger.debug('Unmounting Assignments section');
    };
  }, []);

  const wrapperClasses = useWrapperClasses(
    'assignment-only-bootstrap-reboot assignment-only-global-styles',
  );

  const isCreateNewAssignmentAvailable =
    (user.role === 'district_admin' || isPermissionCRUDAssignments) && !testingIsDisabled;

  const additionalNavigation = (
    <div className="header-nav-icon-links assignment">
      <Tooltip
        title={<div className="app-menu-tooltip-contents">Test Assignments</div>}
        placement="bottom"
        arrow={true}
        classes={{
          popper: 'elevate-tooltip-wrapper',
        }}
      >
        <NavLink
          to="/assignment/list"
          className="header-nav-icon-link"
          activeClassName="active-header-nav-icon-link"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="18" viewBox="0 0 13.5 18">
            <path d="M11.813,2.25H9a2.25,2.25,0,0,0-4.5,0H1.688A1.688,1.688,0,0,0,0,3.938V16.313A1.688,1.688,0,0,0,1.688,18H11.813A1.688,1.688,0,0,0,13.5,16.313V3.938A1.688,1.688,0,0,0,11.813,2.25ZM3.375,14.906a.844.844,0,1,1,.844-.844A.842.842,0,0,1,3.375,14.906Zm0-3.375a.844.844,0,1,1,.844-.844A.842.842,0,0,1,3.375,11.531Zm0-3.375a.844.844,0,1,1,.844-.844A.842.842,0,0,1,3.375,8.156ZM6.75,1.406a.844.844,0,1,1-.844.844A.842.842,0,0,1,6.75,1.406Zm4.5,12.938a.282.282,0,0,1-.281.281H5.906a.282.282,0,0,1-.281-.281v-.562a.282.282,0,0,1,.281-.281h5.063a.282.282,0,0,1,.281.281Zm0-3.375a.282.282,0,0,1-.281.281H5.906a.282.282,0,0,1-.281-.281v-.562a.282.282,0,0,1,.281-.281h5.063a.282.282,0,0,1,.281.281Zm0-3.375a.282.282,0,0,1-.281.281H5.906a.282.282,0,0,1-.281-.281V7.031a.282.282,0,0,1,.281-.281h5.063a.282.282,0,0,1,.281.281Z" />
          </svg>
        </NavLink>
      </Tooltip>
      {isCreateNewAssignmentAvailable && (
        <Tooltip
          title={<div className="app-menu-tooltip-contents">Create a New Test Assignment</div>}
          placement="bottom"
          arrow={true}
          classes={{
            popper: 'elevate-tooltip-wrapper',
          }}
        >
          <NavLink
            to="/assignment/create"
            className="header-nav-icon-link"
            activeClassName="active-header-nav-icon-link"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="18" viewBox="0 0 13.5 18">
              <path d="M11.813,2.25H9a2.25,2.25,0,0,0-4.5,0H1.688A1.688,1.688,0,0,0,0,3.938V16.313A1.688,1.688,0,0,0,1.688,18H11.813A1.688,1.688,0,0,0,13.5,16.313V3.938A1.688,1.688,0,0,0,11.813,2.25ZM6.75,1.406a.844.844,0,1,1-.844.844A.842.842,0,0,1,6.75,1.406Zm4.261,8.149L5.984,14.541a.422.422,0,0,1-.6,0l-2.9-2.929a.422.422,0,0,1,0-.6l1-.991a.422.422,0,0,1,.6,0L5.7,11.654l3.727-3.7a.422.422,0,0,1,.6,0l.991,1A.422.422,0,0,1,11.011,9.555Z" />
            </svg>
          </NavLink>
        </Tooltip>
      )}
    </div>
  );

  return (
    <div className={wrapperClasses}>
      <AppModals />
      <div className={`app-layout-root ${appStatus.pageClassName}`}>
        <AppHeader prependContent={additionalNavigation} />
        <Switch>
          {/* Redirect to default section page */}
          <Redirect exact from="/assignment" to="/assignment/list" push={false} />

          {/* Routes that call API or rely on user data upon render
           * do need user already loaded, so we don't render them
           * until that happens/
           */}
          <Route
            path="/assignment/list/:schoolYear?"
            render={(routeProps: RouteComponentProps) => {
              if (!(user.loggedIn && user.loadComplete)) {
                return null;
              } else {
                return <TestAssignmentListPage {...routeProps} />;
              }
            }}
          />

          <Route
            exact
            path="/assignment/trash"
            render={(routeProps: RouteComponentProps) => {
              if (!(user.loggedIn && user.loadComplete)) {
                return null;
              } else {
                return <TestAssignmentTrashPage {...routeProps} />;
              }
            }}
          />

          <Route
            exact
            path="/assignment/create"
            render={(routeProps: RouteComponentProps) => {
              if (!(user.loggedIn && user.loadComplete)) {
                return null;
              } else {
                return testingIsDisabled ? (
                  <Redirect to="/administration/user/dashboard/assignments" />
                ) : (
                  <TestAssignmentCreatePage {...routeProps} />
                );
              }
            }}
          />

          <Route
            exact
            path="/assignment/edit/:assignmentId"
            render={(routeProps: RouteComponentProps) => {
              if (!(user.loggedIn && user.loadComplete)) {
                return null;
              } else {
                return <TestAssignmentCreatePage {...routeProps} />;
              }
            }}
          />

          <Route
            exact
            path="/assignment/print/:assignmentId"
            render={(routeProps: RouteComponentProps) => {
              if (!(user.loggedIn && user.loadComplete)) {
                return null;
              } else {
                return <TestAssignmentPrintPage {...routeProps} />;
              }
            }}
          />

          <Route render={() => <ErrorPage httpErrorCode={404} />} />
        </Switch>

        {(appStatus.appError || appStatus.appErrorFatal) && <SectionError />}
        {!(appStatus.appError || appStatus.appErrorFatal) &&
          (appStatus.loadingUser || appStatus.appBusy) && <SectionLoader />}
      </div>
      <AppNotificationList />

      {debugAllowed && <AppDebug />}
    </div>
  );
};

export default AppSectionAssignments;
