import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers/appDataTypes';
import AppHelperOld from './AppHelperOLD';
import './HelpGuide.scss';
import { HelpGuideInitial } from './HelpGuideInitial';
import { ReactComponent as IconHelpGuide } from '../../assets/icons/proctoring/help-guide.svg';
import { ReactComponent as IconClose } from '../../assets/icons/proctoring/close.svg';
import { ReactComponent as IconSearch } from '../../assets/icons/proctoring/search.svg';

const HelpGuide = () => {
  const [helpGuide, setHelpGuide] = useState(HelpGuideInitial);
  const [searchValue, setSearchValue] = useState('');
  const refInputSearch = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const appStatus = useSelector((state: AppState) => {
    return state.appStatus;
  });

  const filteredHelpGuideInitial = useRef<any>([]);

  useEffect(() => {
    if (appStatus.isHelpGuideVisible) {
      const filteredHelpGuide = JSON.parse(JSON.stringify(HelpGuideInitial));
      for (let i = filteredHelpGuide.length - 1; i >= 0; --i) {
        if (appStatus.helpGuideSectionsVisible.indexOf(filteredHelpGuide[i].label) === -1) {
          filteredHelpGuide.splice(i, 1);
        }
      }
      filteredHelpGuideInitial.current = filteredHelpGuide;
      setHelpGuide(filteredHelpGuideInitial.current);
      setSearchValue('');
      if (refInputSearch.current) refInputSearch.current.focus();
    }
  }, [appStatus.isHelpGuideVisible]);

  const filterByInput = (value: string) => {
    setSearchValue(value);
    const filteredHelpGuide = JSON.parse(JSON.stringify(filteredHelpGuideInitial.current));
    if (value) {
      //filter lists
      filteredHelpGuide.forEach((entitlement: any, i: number) => {
        if (!filteredHelpGuide[i].label.toLowerCase().includes(value.toLowerCase())) {
          if (entitlement.grades !== undefined) {
            filteredHelpGuide[i].grades = filteredHelpGuide[i].grades.filter((grade: any) => {
              return (grade.grade + ' ' + grade.battery + ' ' + grade.number + ' ' + grade.title)
                .toLowerCase()
                .includes(value.toLowerCase());
            });
          }
          if (entitlement.levels !== undefined) {
            filteredHelpGuide[i].levels.forEach((level: any, j: number) => {
              if (
                !filteredHelpGuide[i].levels[j].level.toLowerCase().includes(value.toLowerCase())
              ) {
                filteredHelpGuide[i].levels[j].subtests = filteredHelpGuide[i].levels[
                  j
                ].subtests.filter((subtest: any) => {
                  return (
                    subtest.title +
                    ' ' +
                    subtest.battery +
                    ' ' +
                    subtest.subtest +
                    ' ' +
                    subtest.number
                  )
                    .toLowerCase()
                    .includes(value.toLowerCase());
                });
              }
            });
          }
        }
      });

      //remove empty lists
      for (let i = filteredHelpGuide.length - 1; i >= 0; --i) {
        if (filteredHelpGuide[i].grades !== undefined && !filteredHelpGuide[i].grades.length) {
          filteredHelpGuide.splice(i, 1);
        }
        if (i < filteredHelpGuide.length && filteredHelpGuide[i].levels !== undefined) {
          if (filteredHelpGuide[i].levels.length) {
            for (let j = filteredHelpGuide[i].levels.length - 1; j >= 0; --j) {
              if (!filteredHelpGuide[i].levels[j].subtests.length) {
                filteredHelpGuide[i].levels.splice(j, 1);
              }
            }
          }
          if (!filteredHelpGuide[i].levels.length) {
            filteredHelpGuide.splice(i, 1);
          }
        }
      }

      //highlight search text
      for (let i = 0; i < filteredHelpGuide.length; ++i) {
        filteredHelpGuide[i].label = AppHelperOld.highlightSearchText(
          filteredHelpGuide[i].label,
          value,
        );
        if (filteredHelpGuide[i].grades !== undefined && filteredHelpGuide[i].grades.length) {
          for (let j = 0; j < filteredHelpGuide[i].grades.length; ++j) {
            //filteredHelpGuide[i].grades[j].grade = AppHelperOld.highlightSearchText(filteredHelpGuide[i].grades[j].grade, value);
            filteredHelpGuide[i].grades[j].battery = AppHelperOld.highlightSearchText(
              filteredHelpGuide[i].grades[j].battery,
              value,
            );
            filteredHelpGuide[i].grades[j].number = AppHelperOld.highlightSearchText(
              filteredHelpGuide[i].grades[j].number,
              value,
            );
            filteredHelpGuide[i].grades[j].title = AppHelperOld.highlightSearchText(
              filteredHelpGuide[i].grades[j].title,
              value,
            );
          }
        }
        if (filteredHelpGuide[i].levels !== undefined && filteredHelpGuide[i].levels.length) {
          for (let j = 0; j < filteredHelpGuide[i].levels.length; ++j) {
            filteredHelpGuide[i].levels[j].level = AppHelperOld.highlightSearchText(
              filteredHelpGuide[i].levels[j].level,
              value,
            );
            for (let k = 0; k < filteredHelpGuide[i].levels[j].subtests.length; ++k) {
              filteredHelpGuide[i].levels[j].subtests[k].title = AppHelperOld.highlightSearchText(
                filteredHelpGuide[i].levels[j].subtests[k].title,
                value,
              );
              filteredHelpGuide[i].levels[j].subtests[k].battery = AppHelperOld.highlightSearchText(
                filteredHelpGuide[i].levels[j].subtests[k].battery,
                value,
              );
              filteredHelpGuide[i].levels[j].subtests[k].subtest = AppHelperOld.highlightSearchText(
                filteredHelpGuide[i].levels[j].subtests[k].subtest,
                value,
              );
              filteredHelpGuide[i].levels[j].subtests[k].number = AppHelperOld.highlightSearchText(
                filteredHelpGuide[i].levels[j].subtests[k].number,
                value,
              );
            }
          }
        }
      }
    }
    setHelpGuide(filteredHelpGuide);
  };

  return (
    <>
      {appStatus.isHelpGuideVisible && (
        <div id="help-guide-wrapper">
          <div className="content">
            <div className="header-wrapper">
              <div className="header">
                <i className="icon-header">
                  <IconHelpGuide />
                </i>
                HelpGuide
                <i
                  className="icon-close"
                  onClick={() => {
                    dispatch({ type: 'SET_HELP_GUIDE_VISIBLE', payload: false });
                    setSearchValue('');
                  }}
                  tabIndex={0}
                >
                  <IconClose />
                </i>
              </div>
              <div className="search">
                <input
                  id="input-help-guide-search"
                  value={searchValue}
                  onChange={(e) => {
                    filterByInput(e.target.value);
                  }}
                  ref={refInputSearch}
                  placeholder="Search this guide..."
                  // onBlur={handleInputSearchBlur}
                  type="text"
                />
                <button id="button-help-guide-search">
                  <IconSearch />
                </button>
              </div>
            </div>
            <div className="results">
              <table>
                <tbody>
                  {!helpGuide.length && (
                    <tr>
                      <td className="no-resulsts">
                        No records found. Check your search criteria and try again.
                      </td>
                    </tr>
                  )}
                  {helpGuide.map((entitlement: any, key1: number) => {
                    return entitlement.type === 'IowaFlex' ? (
                      <React.Fragment key={'entitlement' + key1}>
                        <tr key={'assignment-title' + key1} className="assignment-title">
                          <td colSpan={5}>{entitlement.label}</td>
                        </tr>
                        {entitlement.grades.map((grade: any, key2: number) => {
                          return (
                            <React.Fragment key={'grade' + key1 + key2}>
                              <tr
                                key={'grade-level-title' + key1 + key2}
                                className="grade-level-title"
                              >
                                <td colSpan={5}>
                                  {AppHelperOld.highlightSearchText(grade.grade, searchValue)}
                                </td>
                              </tr>
                              <tr
                                key={'grade-level-line' + key1 + key2}
                                className="grade-level-line"
                              >
                                <td colSpan={2}>{grade.battery}</td>
                                <td>{grade.number}</td>
                                <td>{grade.title}</td>
                                <td>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      entitlement.path +
                                      grade.grade.replace('Grade ', '') +
                                      '.svg'
                                    }
                                    alt=""
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={'entitlement' + key1}>
                        <tr key={'assignment-title' + key1} className="assignment-title">
                          <td colSpan={5}>{entitlement.label}</td>
                        </tr>
                        {entitlement.levels.map((level: any, key2: number) => {
                          return (
                            <React.Fragment key={'level' + key1 + key2}>
                              <tr
                                key={'grade-level-title' + key1 + key2}
                                className="grade-level-title"
                              >
                                <td colSpan={5}>{level.level}</td>
                              </tr>
                              {level.subtests.map((subtest: any, key3: number) => {
                                return (
                                  <React.Fragment key={'subtest' + key1 + key2 + key3}>
                                    <tr
                                      key={'grade-level-line' + key1 + key2 + key3}
                                      className="grade-level-line"
                                    >
                                      <td>{subtest.battery}</td>
                                      <td>{subtest.subtest}</td>
                                      <td>{subtest.number}</td>
                                      <td>{subtest.title}</td>
                                      <td>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            level.path +
                                            subtest.acronym +
                                            '.svg'
                                          }
                                          alt=""
                                        />
                                        <i className="spacer"></i>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HelpGuide;
