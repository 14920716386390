class BaseStaticClass {
  // static debugOptions = {
  //     enabled: false,
  //     level: 3,
  //     styled: false,
  //     grouping: false,
  //     collapsedGroups: false,
  // };

  // static getLogName():string[] {
  //     const debugOptions = {
  //         ...this.debugOptions,
  //         ...config.debugOptions,
  //     };
  //     let logName:string[] = [
  //         '[' + this.prototype.constructor.name + ']',
  //     ];
  //     if (debugOptions.styled) {
  //         logName = [
  //             '[%cClass %c' + this.prototype.constructor.name + '%c]',
  //             'color: #333333; font-weight: normal;',
  //             'font-weight: bold; color: #333333;',
  //             'font-weight: normal; color: unset;',
  //         ];
  //     }
  //     return logName;
  // }
  // /* eslint-disable-next-line */
  // static verbose(...params:any[]):void {
  //     const debugOptions = {
  //         ...this.debugOptions,
  //         ...config.debugOptions,
  //     };
  //     if (debugOptions.enabled && debugOptions.level === 1) {
  //         console.log.call(console, ...this.getLogName(),  ...params);
  //     }
  // }
  // /* eslint-disable-next-line */
  // static debug(...params:any[]):void {
  //     const debugOptions = {
  //         ...this.debugOptions,
  //         ...config.debugOptions,
  //     };
  //     if (debugOptions.enabled && debugOptions.level <= 2) {
  //         console.log.call(console, ...this.getLogName(),  ...params);
  //     }
  // }
  // /* eslint-disable-next-line */
  // static info(...params:any[]):void {
  //     const debugOptions = {
  //         ...this.debugOptions,
  //         ...config.debugOptions,
  //     };
  //     if (debugOptions.enabled && debugOptions.level <= 3) {
  //         console.log.call(console, ...this.getLogName(),  ...params);
  //     }
  // }
  // /* eslint-disable-next-line */
  // static warning(...params:any[]):void {
  //     const debugOptions = {
  //         ...this.debugOptions,
  //         ...config.debugOptions,
  //     };
  //     if (debugOptions.enabled && debugOptions.level <= 4) {
  //         console.warn.call(console, ...this.getLogName(),  ...params);
  //     }
  // }
  // /* eslint-disable-next-line */
  // static error(...params:any[]):void {
  //     const debugOptions = {
  //         ...this.debugOptions,
  //         ...config.debugOptions,
  //     };
  //     if (debugOptions.enabled && debugOptions.level <= 5) {
  //         console.error.call(console, ...this.getLogName(),  ...params);
  //     }
  // }
  // /* eslint-disable-next-line */
  // static log(...params:any[]):void {
  //     console.log.call(console, ...this.getLogName(),  ...params);
  // }

  // static logGroup(groupName:string):void {
  //     const debugOptions = {
  //         ...this.debugOptions,
  //         ...config.debugOptions,
  //     };
  //     if (debugOptions.enabled && debugOptions.grouping) {
  //         const name = this.getLogName();
  //         if (debugOptions.collapsedGroups) {
  //             console.groupCollapsed.call(console, ...name, groupName);
  //         } else {
  //             console.group.call(console, ...name, groupName);
  //         }
  //     }
  // }

  // static logGroupEnd(groupName:string):void {
  //     const debugOptions = {
  //         ...this.debugOptions,
  //         ...config.debugOptions,
  //     };
  //     if (debugOptions.enabled && debugOptions.grouping && groupName !== '_') {
  //         // console.groupEnd.call(console, ...this.getLogName(), groupName);
  //         console.groupEnd();
  //     }
  // }

  static wait(duration = 0): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, duration);
    });
  }
}

export default BaseStaticClass;
