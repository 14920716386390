import React from 'react';
import { AppError } from '@elevate-ui/react-lib';
import { Link } from '@mui/material';
import usePageTitle from '../../hooks/usePageTitle';

const UnsupportedBrowser = () => {
  usePageTitle('Browser Not Supported');

  return (
    <AppError
      title="Your browser is not supported."
      message="Please upgrade to the latest version of Chrome, Microsoft Edge Chromium, or Safari."
      iconType="unsupported-browser"
      showBackButton
    >
      <Link
        color="inherit"
        href="https://onlinehelp.riversideinsights.com/Help/Elevate/Topics/010_Overview_etc/System_Requirements.htm"
      >
        Riverside Elevate System Requirements
      </Link>
    </AppError>
  );
};

export default UnsupportedBrowser;
