import React from 'react';
import { AppError } from '@elevate-ui/react-lib';
import usePageTitle from '../../hooks/usePageTitle';

function NotFoundError() {
  usePageTitle('Page Not Found');

  return (
    <AppError
      title="Page Not Found"
      message="The server is unable to find the page for your request."
      showBackButton
      actions={[
        {
          children: 'Home',
          href: '/administration/user/dashboard',
        },
      ]}
    />
  );
}

export default NotFoundError;
