import React from 'react';

import TableCell from '@mui/material/TableCell';

import { ElevateTableCellProps, ElevateTableRecord } from './ElevateTable.d';

const ElevateTableCell = <T extends ElevateTableRecord>(
  props: ElevateTableCellProps<T>,
): React.ReactElement | null => {
  const propKey = props.column.propName as keyof typeof props.item;

  return (
    <TableCell className="elevate-td" role="gridcell">
      {props.column.renderValue && props.column.renderValue(props.item, props.column)}
      {!props.column.renderValue && props.item[propKey]}
    </TableCell>
  );
};

export default ElevateTableCell;
