import React, { useRef, useEffect, useState, useMemo } from 'react';

import { Table, TableProps, TableContainer } from '@mui/material';

import { ElevateTableProps, ElevateTableRecord, ElevateTableStatus } from './ElevateTable.d';

import ElevateTableHead from './ElevateTableHead';
import ElevateTableBody from './ElevateTableBody';
import ElevateTableFooter from './ElevateTableFooter';

import './ElevateTable.css';

export interface ElevateTableContainerStyle {
  height?: number | string;
  minHeight?: string;
}

export const defaultTableStatus: ElevateTableStatus = {
  loading: false,
  errorTitle: 'Error',
  errorMessage: '',
  sortProp: '',
  sortDirection: '',
  offset: 0,
  limit: 25,
  totalRecords: 0,
};
export const defaultTableOptions = {
  rowsPerPageValues: [5, 10, 25],
  loadingTitle: 'Loading',
  loadingMessage: (
    <div className="spinner-wrapper">
      <svg
        className="loader-spinner"
        xmlns="http://www.w3.org/2000/svg"
        width="66.125"
        height="64"
        viewBox="0 0 66.125 64"
      >
        <path
          d="M69.656,35.473A33.764,33.764,0,0,0,59.284,11.72a33.519,33.519,0,0,0-11-7.042,33.147,33.147,0,0,0-25.309.493A32.614,32.614,0,0,0,5.722,23.124a32.081,32.081,0,0,0,.492,24.492A31.547,31.547,0,0,0,23.591,64.3a31.014,31.014,0,0,0,23.676-.492A30.48,30.48,0,0,0,63.371,47a29.789,29.789,0,0,0,1.769-6.918c.083,0,.166.008.251.008a4.266,4.266,0,0,0,4.266-4.266c0-.12-.006-.238-.016-.355h.016ZM62.878,46.8A29.414,29.414,0,0,1,46.654,62.328a28.882,28.882,0,0,1-22.042-.493A28.347,28.347,0,0,1,9.662,46.187a27.814,27.814,0,0,1,.493-21.225A27.388,27.388,0,0,1,16.3,16.244a27.112,27.112,0,0,1,8.923-5.656A26.732,26.732,0,0,1,35.531,8.813,26.3,26.3,0,0,1,54.006,17a26.045,26.045,0,0,1,5.425,8.577,25.666,25.666,0,0,1,1.695,9.9h.016q-.015.177-.016.355a4.266,4.266,0,0,0,3.8,4.241A29.415,29.415,0,0,1,62.878,46.8Z"
          transform="translate(-3.531 -2.407)"
          fill="#096da9"
        />
      </svg>
    </div>
  ),
  // tableComponents: {
  //     cell: CleverSyncTableCell,
  // }
};

const ElevateTable = <T extends ElevateTableRecord>(
  props: ElevateTableProps<T>,
): React.ReactElement | null => {
  const TableHead = props.tableData.options.tableComponents?.header
    ? props.tableData.options.tableComponents?.header
    : ElevateTableHead;
  const TableBody = props.tableData.options.tableComponents?.body
    ? props.tableData.options.tableComponents?.body
    : ElevateTableBody;
  const TableFooter = props.tableData.options.tableComponents?.footer
    ? props.tableData.options.tableComponents?.footer
    : ElevateTableFooter;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerStyle, setContainerStyle] = useState<ElevateTableContainerStyle>({});

  const handleWindowResize = () => {
    updateContainerStyle();
  };

  useEffect(() => {
    updateContainerStyle();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const tProps = useMemo<TableProps>(() => {
    let tProps = props.tableData.tableProps ? props.tableData.tableProps : {};
    const tAttrs = props.tableAttributes ? props.tableAttributes : {};
    tProps = {
      ...tProps,
      ...tAttrs,
    };
    tProps.className = tProps.className ? `elevate-table ${tProps.className}` : 'elevate-table';
    return tProps;
  }, [props.tableData.tableProps, props.tableAttributes, props.tableData.status]);

  useEffect(() => {
    updateContainerStyle();
  }, [props.tableData.status.loading, tProps]);

  const getContainerStyle = (): ElevateTableContainerStyle => {
    const newContainerStyle: ElevateTableContainerStyle = {};
    if (tProps.stickyHeader && containerRef.current) {
      try {
        const top = containerRef.current.getBoundingClientRect().top + window.scrollY;
        const wh = window.innerHeight;
        newContainerStyle.minHeight = '30em';
        newContainerStyle.height = `calc(${wh - top}px - 0.5em)`;
      } catch (ex) {
        // logger.warning(ex);
        tProps.stickyHeader = false;
      }
    }
    return newContainerStyle;
  };

  const updateContainerStyle = () => {
    const newContainerStyle = getContainerStyle();
    setContainerStyle(newContainerStyle);
  };

  const className = `${tProps.className}${
    props.tableData.status.loading ? ' elevate-table-loading' : ''
  }`;

  return (
    <TableContainer style={containerStyle} ref={containerRef}>
      <Table {...tProps} className={className}>
        <TableHead {...props} />
        <TableBody {...props} />
        <TableFooter {...props} />
      </Table>
    </TableContainer>
  );
};

export default ElevateTable;
