import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

import AppHelper from '../lib/helper/AppHelper';
import UserHelper from '../lib/helper/UserHelper';
import LogHelper from '../lib/helper/LogHelper';

import { AppState, AppStatus } from '../reducers/appDataTypes';

import { StaffAuthUser, StaffAuthUserUpdate } from '../reducers/Auth/authDataTypes';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { AxiosError } from 'axios';
import { LocationStateType } from '../components/Auth/TeacherLogin.js';

const useStaffUser = (identifier?: string, password?: string): StaffAuthUser => {
  const config = useConfigContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LocationStateType>();

  const [staffUser, setStaffUser] = useState<StaffAuthUser>(UserHelper.initializeStaffUser());

  const pathname = location.pathname.replace(/^\//, '');
  const pathChunks = pathname.split('/');

  let skipCheck = false;
  const skipAuthPaths = config.skipAuthPaths;

  if (pathChunks.length >= 3) {
    if (config.authPaths.indexOf(pathChunks[1]) >= 0 && skipAuthPaths.indexOf(pathChunks[2]) >= 0) {
      skipCheck = true;
    }
  } else if (pathChunks.length === 0) {
    skipCheck = true;
  }

  const goToLogout = () => {
    history.push('/administration/auth/logout');
  };

  const clearFatalErrorAndLogout = () => {
    dispatch({ type: 'SET_APP_ERROR_FOOTER', payload: null });
    dispatch({ type: 'SET_APP_ERROR_MESSAGE', payload: '' });
    dispatch({ type: 'SET_APP_ERROR_FATAL', payload: false });
    goToLogout();
  };

  const storeUser: StaffAuthUser = useSelector((state: AppState) => {
    return state.auth.staffAuth.user;
  });

  const appStatus: AppStatus = useSelector((state: AppState) => {
    return state.appStatus;
  });

  const validateSession = async (): Promise<CognitoUserSession | null> => {
    let cognitoSession: CognitoUserSession | null = null;
    try {
      cognitoSession = await UserHelper.getCognitoSession(config);
    } catch (exc: unknown) {
      const ex = exc as Error;
      LogHelper.error(ex, 'HOOK|useStaffUser');
    }
    if (cognitoSession !== null && cognitoSession.isValid()) {
      const cookieIdToken = AppHelper.getCookieValue(config.session.staff.idTokenCookie);
      const idToken = cognitoSession.getIdToken().getJwtToken();
      const localUser = UserHelper.getLocalUser(config);
      if (localUser !== null && localUser.identity.idToken !== idToken) {
        LogHelper.warning(config, 'Updating cached user idToken.', 'HOOK|useStaffUser');
        const newUser: StaffAuthUserUpdate = {
          // ...localUser,
          loggedIn: true,
          identity: {
            ...localUser.identity,
            idToken,
          },
        };
        UserHelper.updateUser(config, newUser, dispatch);
      }
      if (cookieIdToken !== idToken) {
        LogHelper.warning(config, 'Updating expired idToken cookie.', 'HOOK|useStaffUser');
        UserHelper.setIdTokenCookie(idToken, config);
      }
      // UserHelper.updateUser(updates, dispatch);
    } else {
    }
    return cognitoSession;
  };

  const setBusy = (value: boolean, message = '') => {
    dispatch({
      type: 'UPDATE_STAFF_USER_STATUS',
      payload: {
        busy: value,
        busyMessage: message,
      },
    });
  };

  const setLoadingUser = (payload: boolean) => {
    dispatch({
      type: 'SET_LOADING_USER',
      payload,
    });
  };

  const setLoginError = (value: boolean, message = 'Error') => {
    dispatch({
      type: 'UPDATE_STAFF_USER_STATUS',
      payload: {
        error: value,
        errorMessage: message,
      },
    });
  };

  const setFatalAppError = (
    error: boolean,
    errorMessage?: string,
    errorFooter?: typeof appStatus.appErrorFooter,
  ) => {
    let message = '';
    let footer: typeof appStatus.appErrorFooter = null;

    if (typeof errorMessage !== 'undefined') {
      message = errorMessage;
    }
    if (typeof errorFooter !== 'undefined') {
      footer = errorFooter;
    }
    dispatch({ type: 'SET_APP_ERROR_FOOTER', payload: footer });
    dispatch({ type: 'SET_APP_ERROR_MESSAGE', payload: message });
    dispatch({ type: 'SET_APP_ERROR_FATAL', payload: error });
  };

  const setLoggedInStaffUser = (newUser: StaffAuthUser) => {
    newUser.loggedIn = true;
    dispatch({ type: 'SET_STAFF_USER', payload: newUser });
  };

  const setStoredStaffUser = (newUser: StaffAuthUser) => {
    dispatch({ type: 'SET_STAFF_USER', payload: newUser });
  };

  const loginStaffUser = async (): Promise<void> => {
    if (typeof identifier !== 'undefined' && identifier && password) {
      LogHelper.info(config, `Authenticating user "${identifier}"`, 'HOOK|useStaffUser');
      setBusy(true, 'Logging you in...');
      setLoadingUser(true);
      try {
        const resUser = await UserHelper.authenticateCognitoUser(identifier, password, config);
        const missingFields = UserHelper.analyzeStaffUserData(resUser).filter(Boolean);
        if (missingFields.length === 0) {
          const cognitoUserSession = await UserHelper.getCognitoSession(config);
          LogHelper.debug(
            config,
            `User "${identifier}" authenticated, loading profile data.`,
            'HOOK|useStaffUser',
          );
          if (cognitoUserSession !== null) {
            setBusy(true, 'Preparing profile...');
            dispatch({ type: 'SET_COGNITO_USER_SESSION', payload: cognitoUserSession });
            resUser.loggedIn = true;
            setLoggedInStaffUser(resUser);
            try {
              const newUser = await UserHelper.loadUser(config, resUser, dispatch);
              if (UserHelper.isValidStaffUser(newUser)) {
                newUser.loadComplete = true;
                LogHelper.info(
                  config,
                  `User "${identifier}" login successful.`,
                  'HOOK|useStaffUser',
                );
                setLoggedInStaffUser(newUser);
              } else {
                setLoginError(true, 'Error loading profile');
                dispatch({ type: 'SET_HEADER_VISIBLE', payload: false });
                // dispatch({type: 'SET_APP_ERROR_FOOTER', payload: null});
                // dispatch({type: 'SET_APP_ERROR_MESSAGE', payload: 'Error loading user data'});
                // dispatch({type: 'SET_APP_ERROR_FATAL', payload: true});
              }
              setLoadingUser(false);
              setBusy(false);
            } catch (exc: unknown) {
              const ex = exc as Error;
              LogHelper.error(
                `Failed loading "${identifier}" user data - "${ex?.message}".`,
                'HOOK|useStaffUser',
              );
              setLoginError(true, 'Error loading profile');
              setBusy(false);
              setLoadingUser(false);
            }
          } else {
            LogHelper.warning(
              config,
              `Got null for cognito session when logging in "${identifier}".`,
              'HOOK|useStaffUser',
            );
            setLoginError(true, 'User login failed.');
            setBusy(false);
            setLoadingUser(false);
          }
        } else {
          LogHelper.warning(
            config,
            `Failed login for "${identifier}", missing token values for "${missingFields.join(
              '", "',
            )}".`,
            'HOOK|useStaffUser',
          );
          setLoginError(true, 'Login failed.');
          setBusy(false);
          setLoadingUser(false);
          setStaffUser(UserHelper.logoutStaffUser(config));
        }
      } catch (exc: unknown) {
        const ex = exc as AxiosError;
        const message = 'Incorrect username or password';
        // let message = ex.response?.data?.message;
        // let message = 'Could not log you in at this time';
        // if (ex.response?.status === 400 || ex.response?.status === 401) {
        // // } else if (!message) {
        // //   message = ex?.message ? ex.message : 'Could not log you in at this time.';
        // }
        LogHelper.warning(
          config,
          `Failed login for "${identifier}: ${ex.message}".`,
          'HOOK|useStaffUser',
        );
        setLoginError(true, message);
        setBusy(false);
        setLoadingUser(false);
      }
    }
  };

  const loadFullUserData = async (appUser: StaffAuthUser): Promise<StaffAuthUser> => {
    setLoadingUser(true);
    setBusy(true, 'Processing session...');
    const cognitoSession: CognitoUserSession | null = await UserHelper.getCognitoSession(config);
    if (
      cognitoSession !== null &&
      cognitoSession.isValid() &&
      cognitoSession.getIdToken().getJwtToken()
    ) {
      setBusy(false);
      const idToken = cognitoSession.getIdToken().getJwtToken();
      UserHelper.populateStaffTokenData(appUser, idToken, config);
      setLoggedInStaffUser(appUser);
      setLoadingUser(true);
      // LogHelper.info(`Found token cookie, resuming session for ${appUser.email}.`, 'HOOK|useStaffUser');
      // dispatch({ type: 'SET_HEADER_VISIBLE', payload: true });
      try {
        const newUser = await UserHelper.loadUser(config, appUser, dispatch);
        newUser.loggedIn = true;
        dispatch({ type: 'SET_HEADER_VISIBLE', payload: true });

        if (UserHelper.isValidStaffUser(newUser)) {
          LogHelper.debug(
            config,
            `Loaded full data for redux store user "${appUser.email}"`,
            'HOOK|useStaffUser',
          );
          newUser.loadComplete = true;
          LogHelper.info(config, `User "${identifier}" login successful.`, 'HOOK|useStaffUser');
          setLoggedInStaffUser(newUser);
        } else {
          // const errorFooter:typeof appStatus.appErrorFooter = (
          //   <div>
          //     <Button
          //       variant="contained"
          //       color="primary"
          //       onClick={ clearFatalErrorAndLogout }
          //     >
          //         Logouts
          //     </Button>
          //   </div>
          // );
          // setFatalAppError(true, 'User validation failed, please log in again.', errorFooter);
          LogHelper.error(`User "${identifier}" data invalid.`, 'HOOK|useStaffUser');
        }
        setLoadingUser(false);
        return newUser;
      } catch (exc: unknown) {
        const ex = exc as Error;
        LogHelper.error(ex.message, 'HOOK|useStaffUser');
        setLoadingUser(false);
        // const errorFooter:typeof appStatus.appErrorFooter = (
        //   <div>
        //     <Button
        //       variant="contained"
        //       color="primary"
        //       onClick={ clearFatalErrorAndLogout }
        //     >
        //         Logouts
        //     </Button>
        //   </div>
        // );
        // setFatalAppError(true, 'Session validation failed, please log in again.');
        return appUser;
      }
    } else {
      // cognito session null or invalid
      LogHelper.warning(config, 'Cognito session not found', 'HOOK|useStaffUser');
      setBusy(false);
      setLoadingUser(false);
      const locState: LocationStateType = {};
      if (location.pathname !== '/' && location.pathname.length > 0) {
        locState.from = `${location.pathname}${location?.search}`;
      }
      history.push('/administration/auth/login/staff', locState);
      return appUser;
      // goToLogout(true);
    }
  };

  useEffect(() => {
    // When store user gets updated, this return result gets changed as well
    setStaffUser(storeUser);
  }, [storeUser]);

  useEffect(() => {
    if (staffUser.loggedIn) {
      dispatch({ type: 'SET_HEADER_VISIBLE', payload: true });
    }
  }, [staffUser.loggedIn]);

  useEffect(() => {
    // Hook is called with username and password - log user in and load all data.
    if (identifier && password) {
      loginStaffUser();
    } else if (!skipCheck && !appStatus.loadingUser) {
      // skipCheck is true only on auth pages (login, logout, create-password)
      const appUser: StaffAuthUser = {
        ...storeUser,
        roles: storeUser.roles,
        // loggedIn: false,
        loggedIn: false,
        loadComplete: false,
      };
      setStoredStaffUser(appUser);
      setLoadingUser(true);
      setBusy(true, 'Preparing session...');

      // use cached user data if possible
      const cachedUser = UserHelper.getLocalUser(config);
      if (cachedUser !== null) {
        // LogHelper.debug(`Using cached user data for "${cachedUser.email}".`, 'HOOK|useStaffUser');
        cachedUser.loadComplete = false;
        cachedUser.loggedIn = false;
        setStoredStaffUser(cachedUser);
        setBusy(true, 'Validating session...');
      }

      // LogHelper.debug('Validating cognito session', 'HOOK|useStaffUser');
      validateSession()
        .then((cs: CognitoUserSession | null) => {
          if (cs !== null) {
            dispatch({ type: 'SET_COGNITO_USER_SESSION', payload: cs });
            if (cs.isValid()) {
              // LogHelper.debug('Cognito session valid', 'HOOK|useStaffUser');
              const localUser = UserHelper.getLocalUser(config);
              const luFull = localUser !== null && UserHelper.isFullStaffUser(localUser);
              if (localUser !== null && luFull) {
                localUser.loadComplete = true;
                localUser.loggedIn = true;
                UserHelper.storeLocalUser(localUser, config);
                setStoredStaffUser(localUser);
                setLoadingUser(false);
                setBusy(false, 'Validating session...');
                LogHelper.info(
                  config,
                  `User "${localUser.email}" loaded and validated`,
                  'HOOK|useStaffUser',
                );
              } else {
                LogHelper.debug(
                  config,
                  `User "${appUser.email}" validated, but additional data needs to be loaded`,
                  'HOOK|useStaffUser',
                );
                if (localUser !== null) {
                  loadFullUserData(localUser);
                } else {
                  loadFullUserData(appUser);
                }
              }
            } else {
              LogHelper.warn(
                config,
                `User "${appUser.email}" data is missing or invalid, loading full data`,
                'HOOK|useStaffUser',
              );
              loadFullUserData(appUser);
            }
          } else {
            LogHelper.warning(config, 'Cognito session not found', 'HOOK|useStaffUser');
            dispatch({ type: 'SET_HEADER_VISIBLE', payload: false });
            setBusy(false);
            setLoadingUser(false);
            UserHelper.logoutStaffUser(config, dispatch);
            const locState: LocationStateType = {};
            if (location.pathname !== '/' && location.pathname.length > 0) {
              locState.from = `${location.pathname}${location?.search}`;
            }
            history.push('/administration/auth/login/staff', locState);
          }
        })
        .catch((ex) => {
          LogHelper.error(ex, 'HOOK|useStaffUser');
        });
    } else if (skipCheck) {
      if (!staffUser.loggedIn) {
        dispatch({ type: 'SET_HEADER_VISIBLE', payload: false });
      }
    }
  }, [identifier, password]);

  return staffUser;
};

export default useStaffUser;
