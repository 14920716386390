import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TbSquare, TbSquareCheckFilled } from 'react-icons/tb';
import { Button, ButtonGroup } from '@mui/material';
import useAblyClient from '../../../../hooks/ably/useAblyClient';
import { AblyState, AppState } from '../../../../reducers/appDataTypes';

const AppDebugAblyClient: React.FC = () => {
  const dispatch = useDispatch();
  const ablyHook = useAblyClient({
    namespace: 'AppDebugAblyClient',
  });
  const ablyState: AblyState = useSelector((state: AppState) => {
    return state.ably;
  });

  const ablyClientId = useMemo<string>(() => {
    if (
      ablyState.ablyRealtime !== null &&
      ablyState.ready === true &&
      typeof ablyState.ablyRealtime.auth !== 'undefined' &&
      typeof ablyState.ablyRealtime.auth.clientId !== 'undefined'
    ) {
      return ablyState.ablyRealtime.auth.clientId;
    }
    return '';
  }, [ablyState.ablyRealtime, ablyState.ready]);

  const renderStateFlag = (stateFlagProp: string, customLabel = '', onClick?: () => void) => {
    const value = ablyState[stateFlagProp as keyof typeof ablyState];
    return (
      <React.Fragment>
        <span
          onClick={() => {
            if (typeof onClick !== 'undefined') {
              onClick();
            }
          }}
        >
          {customLabel === '' ? stateFlagProp : customLabel}
        </span>
        <span
          onClick={() => {
            if (typeof onClick !== 'undefined') {
              onClick();
            }
          }}
        >
          {!!!value ? <TbSquare /> : <TbSquareCheckFilled />}
        </span>
      </React.Fragment>
    );
  };

  const killHook = () => {
    ablyHook.destroy();
  };

  const initHook = () => {
    ablyHook.initialize();
  };

  const onErrorClick = () => {
    dispatch({ type: 'SET_ABLY_ERROR', payload: !ablyState.error });
  };

  return (
    <div className="app-debug-drawer-subsection">
      <div className="app-debug-drawer-subsection-title">
        <h3 className="app-debug-drawer-subsection-title-text">Client</h3>
        <span></span>
      </div>
      <div className="app-debug-drawer-subsection-contents">
        <div className="app-debug-ably-client-basic-info">
          <div>Client ID: {ablyClientId}</div>
        </div>
        <div className="app-debug-inspect-grid">
          <div className="app-debug-inspect-grid-body">
            {renderStateFlag('initializing', 'Initializing')}
            {renderStateFlag('error', 'Error', onErrorClick)}
            {renderStateFlag('ready', 'Ready')}
          </div>
        </div>
        <div className="app-debug-ably-client-buttons-wrapper">
          <ButtonGroup
            className="app-debug-ably-client-buttons"
            color="primary"
            size="small"
            variant="outlined"
            fullWidth={false}
          >
            <Button disabled={!ablyState.ready} onClick={killHook}>
              Disconnect
            </Button>
            <Button
              disabled={ablyState.ready === true || ablyState.initializing === true}
              onClick={initHook}
            >
              Reconnect
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default AppDebugAblyClient;
