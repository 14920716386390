import React, { useState } from 'react';
import useApiHelper from '../../../../../hooks/useApiHelper';
import useAppLogger from '../../../../../hooks/useAppLogger';
import styles from './DownloadTicketsButton.module.scss';
import DownloadTicketsLink, {
  DownloadTicketsLinkProps,
} from './DownloadTicketsLink/DownloadTicketsLink';
import { TestTakerTicketsData } from './DownloadTicketsButton.d';
import { TestTakerTicket } from './DownloadTicketsLink/TestTakerTicketsPDF/TestTakerTicketsPDF.d';
import { OverlayLoader } from '@riversideinsights/elevate-react-lib';

export interface DownloadTicketsButtonProps
  extends Pick<DownloadTicketsLinkProps, 'onDocumentDownload'> {
  disabled?: boolean;
  disabledDownloadTicketsButton?: boolean;
  testTakerTicketsData: TestTakerTicketsData;
}

const DownloadTicketsButton = ({
  disabled,
  disabledDownloadTicketsButton,
  onDocumentDownload,
  testTakerTicketsData,
}: DownloadTicketsButtonProps) => {
  const ApiHelper = useApiHelper('Component|DownloadTicketsButton');
  const [isFetchingTestTakerTickets, setIsFetchingTestTakerTickets] = useState(false);
  const [testTakerTickets, setTestTakerTickets] = useState<TestTakerTicket[]>([]);
  const logger = useAppLogger('Component|DownloadTicketsButton');

  const fetchTestTakerTicketData = () => {
    setIsFetchingTestTakerTickets(true);

    const requestData = {
      customerId: testTakerTicketsData.customerId,
      sectionId: Array.from(new Set(testTakerTicketsData.sectionIds)),
      grades: testTakerTicketsData.grades,
    };

    ApiHelper.apiRequest('rostering', 'testtakertickets', requestData)
      .then((result) => {
        setTestTakerTickets(result.data);
      })
      .catch((ex) => {
        logger.error('Error fetching test taker tickets.', ex);
      })
      .finally(() => {
        setIsFetchingTestTakerTickets(false);
      });
  };

  if (isFetchingTestTakerTickets) {
    return (
      <div className={styles['preparing-document']}>
        <OverlayLoader title="Preparing document, please wait." />
      </div>
    );
  }

  if (testTakerTickets.length) {
    return (
      <DownloadTicketsLink
        assignmentName={testTakerTicketsData.assignmentName}
        assignmentType={testTakerTicketsData.assignmentType}
        organizationId={testTakerTicketsData.organizationId}
        onDocumentDownload={onDocumentDownload}
        tickets={testTakerTickets}
      />
    );
  }

  return (
    <button
      className={styles['download-tickets-button']}
      disabled={
        disabled ||
        disabledDownloadTicketsButton ||
        !testTakerTicketsData.sectionIds.length ||
        isFetchingTestTakerTickets
      }
      onClick={fetchTestTakerTicketData}
    >
      Download Tickets
    </button>
  );
};

export default DownloadTicketsButton;
