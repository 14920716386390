/**
 * Returns safe unique ID string for HTML elements
 *
 * @param  {number} size     Length of id value
 * @param  {string} prepend     Prepend value for id
 * @return {string}        Unique ID value
 */
const useIdentify = (size = 5, prepend = ''): string => {
  let identifier = `${prepend ? prepend + '_' : ''}`;
  for (let i = 0; i < size; i++) {
    identifier += String.fromCharCode(65 + Math.ceil(Math.random() * 25));
  }
  if (document.getElementById(identifier) !== null) {
    identifier += '_';
  }

  while (document.getElementById(identifier) !== null) {
    identifier += `${Math.floor(Math.random() * 10)}`;
  }

  return identifier;
};

export default useIdentify;
