import React, { Suspense, lazy, useEffect } from 'react';

import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { AppLoader } from '@riversideinsights/elevate-react-lib';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import AppHelper from '../../../lib/helper/AppHelper';

import useAppLogger from '../../../hooks/useAppLogger';
import useStaffUser from '../../../hooks/useStaffUser';

import AppNotificationList from '../AppNotification/AppNotificationList';
import AppHeader from '../AppHeader/AppHeader';
import AppDebug from '../AppDebug/AppDebug';
import AppModals from '../AppModals/AppModals';
import ErrorPage from '../../../pages/ErrorPage';
import useWrapperClasses from '../../../hooks/useWrapperClasses';
import SectionLoader from '../AppSection/SectionLoader';
import SectionError from '../AppSection/SectionError';
import useAppStatus from '../../../hooks/useAppStatus';
import WebReporting from '../../../pages/WebReporting';

const TestTaker = lazy(() => import('../../../pages/TestTaker'));

const AppSectionReporting: React.FunctionComponent = () => {
  const config = useConfigContext();
  const user = useStaffUser();
  const logger = useAppLogger('COMPONENT|AppSectionReporting');
  const debugAllowed = AppHelper.getCookieValue('elevateDebugEnabled') ? true : config.local;

  const appStatus = useAppStatus();

  useEffect(() => {
    logger.debug('Mounting Reporting section');
    return () => {
      logger.debug('Unmounting Reporting section');
    };
  }, []);

  const wrapperClasses = useWrapperClasses('app-section-reporting');

  return (
    <div className={wrapperClasses}>
      <AppModals />
      <div className={`app-layout-root ${appStatus.pageClassName}`}>
        <AppHeader />
        <Suspense fallback={<AppLoader title="Please Wait" message="Initializing" />}>
          <Switch>
            <Redirect exact from="/reporting" to="/reporting/testTaker" push={false} />
            <Route
              path="/reporting/testTaker"
              render={(routeProps: RouteComponentProps) => {
                if (!(user.loggedIn && user.loadComplete)) {
                  return null;
                } else {
                  return <TestTaker {...routeProps} />;
                }
              }}
            />
            <Route
              path="/reporting/cogat-dashboard"
              render={() => {
                if (!(user.loggedIn && user.loadComplete)) {
                  return null;
                } else {
                  return <WebReporting />;
                }
              }}
            />
            <Route render={() => <ErrorPage httpErrorCode={404} />} />
          </Switch>
        </Suspense>
        {(appStatus.appError || appStatus.appErrorFatal) && <SectionError />}
        {!(appStatus.appError || appStatus.appErrorFatal) &&
          (appStatus.loadingUser || appStatus.appBusy) && <SectionLoader />}
      </div>
      <AppNotificationList />
      {debugAllowed && <AppDebug />}
    </div>
  );
};

export default AppSectionReporting;
