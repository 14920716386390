import React, { useState, useEffect, useMemo, useRef } from 'react';

// import LinearProgress from '@mui/material/LinearProgress';

// import ErrorIcon from '@mui/icons-material/Error';
// import useCachedUser from '../../../../../hooks/useCachedUser';
import { useDispatch } from 'react-redux';
import { ModalDialog } from '@riversideinsights/elevate-react-lib';
import useNotificationHelper from '../../../../../hooks/useNotificationHelper';
import { StaffAuthUser } from '../../../../../reducers/Auth/authDataTypes';
import UserHelper from '../../../../../lib/helper/UserHelper';
import useAppLogger from '../../../../../hooks/useAppLogger';
import { Button } from '@mui/material';
import { TbCopy } from 'react-icons/tb';

// import { string } from 'prop-types';

export interface AppDebugCookieUserModalProps {
  modalVisible: boolean;
  hideModal?: () => void;
}

const AppDebugCookieUserModal: React.FC<AppDebugCookieUserModalProps> = (props) => {
  // const user = useCachedUser();
  const logger = useAppLogger('COMPONENT|AppDebugCookieUserModal');
  const dispatch = useDispatch();
  const NotificationHelper = useNotificationHelper();
  const userDataFieldRef = useRef<HTMLTextAreaElement | null>(null);

  const [cookieUser, setCookieUser] = useState<StaffAuthUser | null>(null);

  const userDataJson = useMemo<string>(() => {
    let userString = '';
    if (cookieUser === null) {
      return userString;
    }
    const cookieUserCopy: Partial<StaffAuthUser> = UserHelper.cloneStaffUser(cookieUser);
    const copyIdentity = cookieUserCopy.identity;
    const copyRoles = cookieUserCopy.roles;
    const copyCurrentRole = cookieUserCopy.currentRole;
    const copyCurrentSchools = cookieUserCopy.currentSchools;
    const copyCurrentPermissions = cookieUserCopy.currentPermissions;
    delete cookieUserCopy.identity;
    delete cookieUserCopy.roles;
    delete cookieUserCopy.currentRole;
    delete cookieUserCopy.currentSchools;
    delete cookieUserCopy.currentPermissions;
    cookieUserCopy.currentPermissions = copyCurrentPermissions;
    cookieUserCopy.currentSchools = copyCurrentSchools;
    cookieUserCopy.currentRole = copyCurrentRole;
    cookieUserCopy.identity = copyIdentity;
    cookieUserCopy.roles = copyRoles;

    // const userCopy = {
    //   ...user,
    //   identity: {
    //     ...user.identity,
    //     // idToken: '',
    //   },
    //   roles: {
    //     ...user.roles,
    //   },
    //   // sections: user.sections,
    // };

    try {
      userString = JSON.stringify(cookieUserCopy, null, 4);
    } catch (ex: unknown) {
      const err = ex as Error;
      logger.error(`${err.message}`);
    }
    return userString;
  }, [cookieUser]);
  useEffect(() => {
    setCookieUser(UserHelper.getCookieUser());
  }, []);

  const hideModal = () => {
    if (props?.hideModal) {
      props.hideModal();
    }
  };

  // const overrideUser = () => {
  //   if (userDataFieldRef.current !== null) {
  //     const newUserData: StaffAuthUserUpdate = JSON.parse(userDataFieldRef.current.value) as StaffAuthUserUpdate;
  //     if (newUserData?.currentSections) {
  //       delete newUserData?.currentSections;
  //     }
  //     if (newUserData?.currentPermissions) {
  //       delete newUserData?.currentPermissions;
  //     }
  //     if (newUserData?.roles) {
  //       delete newUserData?.roles;
  //     }
  //     if (newUserData?.identity) {
  //       delete newUserData?.identity;
  //     }
  //     // newUserData.loadComplete = false;
  //     UserHelper.updateUser(newUserData, dispatch);
  //     NotificationHelper.add('User data updated', 'info', 0);
  //     // UserHelper.storeLocalUser(user);
  //   }
  // };

  const copyUserToClipboard = () => {
    navigator.clipboard.writeText(userDataJson).then(
      () => {
        // setUserCopied(true);
        NotificationHelper.add('User cookie data copied to clipboard as JSON', 'info');
        if (props?.hideModal) {
          setTimeout(() => {
            hideModal();
          }, 1000);
        }
      },
      function () {
        NotificationHelper.add('Failed copying user cookie data to clipboard', 'error');
        // setUserCopied(false);
      },
    );
  };

  useEffect(() => {
    if (props.modalVisible) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="app-debug-test-modal app-debug-user-details-modal"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            title="Cookie User Data"
            footer={
              <div className="app-debug-modal-footer">
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<TbCopy />}
                  onClick={copyUserToClipboard}
                >
                  Copy
                </Button>
                <Button variant="contained" color="primary" onClick={hideModal}>
                  Close
                </Button>
              </div>
            }
            body={
              <div className="app-debug-user-modal-contents">
                {userDataJson !== '' && (
                  <textarea
                    ref={userDataFieldRef}
                    className="app-debug-user-data-field"
                    readOnly={true}
                    value={userDataJson}
                  ></textarea>
                )}
              </div>
            }
            onClose={() => {
              hideModal();
            }}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [props.modalVisible]);
  return <></>;
};

export default AppDebugCookieUserModal;
