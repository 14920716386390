import BaseStaticClass from './BaseStaticClass';

class HelperBase extends BaseStaticClass {
  // static debugOptions = {
  //     enabled: false,
  //     level: 3,
  //     styled: false,
  //     grouping: false,
  //     collapsedGroups: false,
  // };
  // static getLogName():string[] {
  //     const debugOptions = {
  //         ...this.debugOptions,
  //         ...config.debugOptions,
  //     };
  //     let logName = [
  //         '[Helper ' + this.prototype.constructor.name + ']',
  //     ];
  //     if (debugOptions.styled) {
  //         logName = [
  //             '[%cHelper %c' + this.prototype.constructor.name + '%c]',
  //             'color: #0033CC; font-weight: normal',
  //             'font-weight: bold; color: #0033CC;',
  //             'font-weight: normal; color: unset;',
  //         ];
  //     }
  //     return logName;
  // }
}

export default HelperBase;
