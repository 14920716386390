import React from 'react';

const UserShieldIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="48" viewBox="0 0 60 48">
      <path d="m58.341 25.416-10.8-4.216a2.905 2.905 0 0 0-2.081 0l-10.8 4.219a2.535 2.535 0 0 0-1.66 2.331c0 10.463 6.441 17.7 12.459 20.053a2.909 2.909 0 0 0 2.081 0c4.81-1.875 12.46-8.381 12.46-20.053a2.535 2.535 0 0 0-1.659-2.334zm-11.841 17.934v-17.728l8.953 3.5a16.77 16.77 0 0 1-8.953 14.228zm-25.5-19.35a12 12 0 1 0-12-12 12 12 0 0 0 12 12zm9 3.75a5.325 5.325 0 0 1 .1-.675c-.234-.009-.459-.075-.7-.075h-1.566a16.32 16.32 0 0 1-13.669 0h-1.565a12.6 12.6 0 0 0-12.6 12.6v3.9a4.5 4.5 0 0 0 4.5 4.5h33a4.614 4.614 0 0 0 1.8-.375 25.188 25.188 0 0 1-9.3-19.875z" />
    </svg>
  );
};

export default UserShieldIcon;
