import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as DropdownArrowDown } from '../../../assets/icons/dropdown-arrow-down.svg';
import { ReactComponent as IconCheck } from '../../../assets/icons/check.svg';
import './Dropdown.css';

export interface IDropdownOption {
  label: string;
  //value: string;
  value: any;
  selected: boolean;
  disabled?: boolean;
}

export interface IDropdownProps {
  options: IDropdownOption[];
  isMultiselect?: boolean;
  isOpen?: boolean;
  isPlaceholderStatic?: boolean;
  placeholder?: string;
  placeholderOneItemSelected?: string;
  placeholderManyItemSelected?: string;
  cssClass?: string;
  isDisabled?: boolean;
  ResetDropdownAllSelectedOptions?: number;
  onChange?: (stateOptions: IDropdownOption | IDropdownOption[]) => void;
  minSelected?: number;
  maxSelected?: number;
  showSelectedOptionsHeaderTooltip?: boolean;
  classSelectedOptionsHeaderTooltip?: string;
}

const Dropdown = (props: IDropdownProps) => {
  const {
    options,
    isMultiselect,
    isOpen,
    isPlaceholderStatic,
    placeholder,
    placeholderOneItemSelected,
    placeholderManyItemSelected,
    cssClass,
    isDisabled,
    ResetDropdownAllSelectedOptions,
    onChange,
    minSelected,
    maxSelected,
    showSelectedOptionsHeaderTooltip,
    classSelectedOptionsHeaderTooltip,
  } = props;

  //const [stateOptions, setStateOptions] = useState<IDropdownOption[]>([]);
  const [stateOptions, setStateOptions] = useState(options);
  const [isExpanded, setIsExpanded] = useState(false);
  const [headerSelectedOptionsLabel, setHeaderSelectedOptionsLabel] = useState('');
  const [selectedMultiOptions, setSelectedMultiOptions] = useState<IDropdownOption[]>([]);
  const [selectedMultiTooltipHeader, setSelectedMultiTooltipHeader] = useState('');
  const [dropdownWidth, setDropdownWidth] = useState('');
  const myRef = useRef<HTMLInputElement>(null);
  let maxOptionCharNum = 0;
  let dropdownCssWidth = '';

  useEffect(() => {
    //setHeaderSelectedOptionsLabel('');
  }, [ResetDropdownAllSelectedOptions]);

  useEffect(() => {
    isOpen && setIsExpanded(isOpen);
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setStateOptions(options);
    setSelectedMultiOptions(options.filter((obj) => obj.selected));
    refreshHeaderCaptionAndHeaderTooltip(options);
    countCssWidth();
  }, [options]);

  useEffect(() => {
    isOpen && setIsExpanded(isOpen);
  }, [isOpen]);

  const countCssWidth = () => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].label.length > maxOptionCharNum) {
        maxOptionCharNum = options[i].label.length;
      }
    }
    dropdownCssWidth = Math.floor(Number(maxOptionCharNum) * 7) + 'px';
    setDropdownWidth(dropdownCssWidth);
  };

  const refreshHeaderCaptionAndHeaderTooltip = (stateOptions: IDropdownOption[]) => {
    const arrSelectedOptions: IDropdownOption[] = [];
    let isMultiCaptionAssigned = false;
    let headerLabel = '';
    let headerTooltip = '';
    const countSelected = stateOptions.filter((obj) => obj.selected).length;

    for (let i = 0; i < stateOptions.length; i++) {
      if (isMultiselect) {
        if (stateOptions[i].selected) {
          arrSelectedOptions.push(stateOptions[i]);
          if (!isMultiCaptionAssigned) {
            isMultiCaptionAssigned = true;
            if (placeholderOneItemSelected && placeholderManyItemSelected)
              headerLabel =
                countSelected +
                ' ' +
                (countSelected === 1 ? placeholderOneItemSelected : placeholderManyItemSelected) +
                ' selected';
            else headerLabel = stateOptions[i].label;
          } else {
            headerTooltip += '<br />';
          }
          headerTooltip += stateOptions[i].label;
        }
      } else {
        if (stateOptions[i].selected) {
          headerLabel = stateOptions[i].label;
          break;
        }
      }
    }

    if (placeholder && isPlaceholderStatic) headerLabel = placeholder + headerLabel;
    setHeaderSelectedOptionsLabel(headerLabel);

    if (isMultiselect) {
      setSelectedMultiOptions(arrSelectedOptions);
      setSelectedMultiTooltipHeader(headerTooltip);
    }
  };

  const toggling = () => setIsExpanded(!isExpanded);

  const onOptionClicked = (option: IDropdownOption) => {
    const countSelected = stateOptions.filter((obj) => obj.selected).length;
    if (isMultiselect) {
      if (
        (option.selected === true &&
          (minSelected === undefined ||
            (minSelected !== undefined && countSelected > minSelected))) ||
        (option.selected === false &&
          (maxSelected === undefined || (maxSelected !== undefined && countSelected < maxSelected)))
      ) {
        for (let i = 0; i < stateOptions.length; i++) {
          if (stateOptions[i].value === option.value) {
            stateOptions[i].selected = !stateOptions[i].selected;
            break;
          }
        }
        onChange && onChange(stateOptions);
      }
    } else {
      setIsExpanded(false);
      for (let i = 0; i < stateOptions.length; i++) {
        if (stateOptions[i].value === option.value) {
          if (option.selected === true && minSelected === 0) stateOptions[i].selected = false;
          else stateOptions[i].selected = true;
          onChange && onChange(stateOptions[i]);
        } else stateOptions[i].selected = false;
      }
      //onChange && onChange(option);
    }
    refreshHeaderCaptionAndHeaderTooltip(stateOptions);
  };

  const handleClickOutside = (event: any) => {
    if (!myRef.current?.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  return (
    <div
      className={
        'dropdown-container-custom ' +
        (cssClass ? cssClass : '') +
        (isDisabled ? ' disabled' : '') +
        (showSelectedOptionsHeaderTooltip &&
        isMultiselect &&
        !isExpanded &&
        selectedMultiOptions.length >= 1
          ? ' tooltip-custom'
          : '')
      }
      ref={myRef}
    >
      {showSelectedOptionsHeaderTooltip &&
      isMultiselect &&
      !isExpanded &&
      selectedMultiOptions.length >= 1 ? (
        <span
          className={
            'tooltiptext ' +
            (classSelectedOptionsHeaderTooltip ? classSelectedOptionsHeaderTooltip : '')
          }
          role="tooltip"
          dangerouslySetInnerHTML={{ __html: selectedMultiTooltipHeader }}
        ></span>
      ) : (
        ''
      )}
      <div
        className={'dropdown-header-custom' + (isExpanded ? ' expanded' : '')}
        onClick={toggling}
        style={{ minWidth: dropdownWidth }}
      >
        {headerSelectedOptionsLabel || (
          <span className="placeholder">{placeholder ? placeholder : 'Select option'}</span>
        )}
        <DropdownArrowDown />
      </div>
      {isExpanded && (
        <div className="dropdown-list-container-custom">
          <ul role="list">
            {stateOptions.map((option: IDropdownOption) => (
              <li
                onClick={(e) => onOptionClicked(option)}
                className={option.disabled ? 'disabled' : ''}
                key={Math.random()}
              >
                {option.label}
                {option.selected ? <IconCheck /> : ''}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
