import React from 'react';
export interface ITextDomainAcronyms {
  readonly NA: string;
  readonly OA: string;
  readonly NB: string;
  readonly MD: string;
  readonly G: string;
  readonly NF: string;
  readonly RP: string;
  readonly NS: string;
  readonly EE: string;
  readonly SP: string;
  readonly F: string;
  readonly KID: string;
  readonly CS: string;
  readonly IKI: string;
}

export interface ITextSubTestAcronyms {
  readonly PA: string;
  readonly SC: string;
  readonly PC: string;
  readonly NA: string;
  readonly NP: string;
  readonly NS: string;
  readonly FM: string;
  readonly PF: string;
  readonly FC: string;
  readonly VA: string;
  readonly VC: string;
}

export const textDomainAcronyms: ITextDomainAcronyms = {
  NA: 'All Domains',
  OA: 'Operations & Algebraic Thinking',
  NB: 'Number & Operations - Base Ten',
  MD: 'Measurement and Data',
  G: 'Geometry',
  NF: 'Number & Operations - Fractions',
  RP: 'Ratios & Proportional Relationships',
  NS: 'The Number System',
  EE: 'Expressions & Equations',
  SP: 'Statistics & Probability',
  F: 'Functions',
  KID: 'Key Ideas and Details',
  CS: 'Craft and Structure',
  IKI: 'Integration of Knowledge & Ideas',
};

export const textSubTestAcronyms: ITextSubTestAcronyms = {
  PA: 'Picture Analogies',
  SC: 'Sentence Completion',
  PC: 'Picture Classification',
  NA: 'Number Analogies',
  NP: 'Number Puzzles',
  NS: 'Number Series',
  FM: 'Figure Matrices',
  PF: 'Paper Folding',
  FC: 'Figure Classification',
  VA: 'Verbal Analogies',
  VC: 'Verbal Classification',
};

export const batterySubTestAcronyms: ITextSubTestAcronyms = {
  PA: 'Verbal',
  SC: 'Verbal',
  PC: 'Verbal',
  NA: 'Quantitative',
  NP: 'Quantitative',
  NS: 'Quantitative',
  FM: 'Nonverbal',
  PF: 'Nonverbal',
  FC: 'Nonverbal',
  VA: 'Verbal',
  VC: 'Verbal',
};

class AppHelper {
  static assignmentTestGroupScreeningOrPostScreener = (item: any) => {
    let result = item.assignmentTestGroup;
    const arrScreeningSubTestAcronyms = ['PA', 'NA', 'FM', 'VA'];
    if (
      result === 'CogAT Screening & Post-Screener Form 7' ||
      result === 'CogAT Screening & Post-Screener Form 8'
    ) {
      if (arrScreeningSubTestAcronyms.indexOf(item.subTestAcronym) !== -1) {
        result = 'CogAT Screening' + result.substring(result.lastIndexOf(' Form '));
      } else {
        result = 'CogAT Post-Screener' + result.substring(result.lastIndexOf(' Form '));
      }
    }
    return result;
  };

  static fullTextSubTestAcronym = (item: any) => {
    let battery = batterySubTestAcronyms[item.subTestAcronym as keyof ITextSubTestAcronyms];
    if (
      item.assignmentBattery &&
      JSON.parse(item.assignmentBattery).indexOf('AV') !== -1 &&
      (item.subTestAcronym === 'PA' || item.subTestAcronym === 'PC')
    )
      battery = 'Alt Verbal';
    return battery + ' - ' + textSubTestAcronyms[item.subTestAcronym as keyof ITextSubTestAcronyms];
  };

  static highlightSearchText(text: string, searchText: string): any {
    if (searchText === '') return text;
    const arrSplit = text.toLowerCase().split(searchText.toLowerCase());
    const arrSplitLength = arrSplit.map((item: any, i: any) => {
      return item.length;
    });
    let result = '';
    let strLength = 0;
    for (let i = 0; i < arrSplitLength.length; i++) {
      if (i === 0)
        result +=
          text.substr(0, arrSplitLength[i]) +
          '<mark class="custom-mark">' +
          text.substr(arrSplitLength[i], searchText.length) +
          '</mark>';
      else if (i === arrSplitLength.length - 1) result += text.substr(strLength, arrSplitLength[i]);
      else
        result +=
          text.substr(strLength, arrSplitLength[i]) +
          '<mark class="custom-mark">' +
          text.substr(strLength + arrSplitLength[i], searchText.length) +
          '</mark>';
      strLength += arrSplitLength[i] + searchText.length;
    }
    return <span dangerouslySetInnerHTML={{ __html: result }} />;
  }
}

export default AppHelper;
