import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AppHelper from '../../../lib/helper/AppHelper';
import usePageTitle from '../../../hooks/usePageTitle';
import { ModalDialog } from '@riversideinsights/elevate-react-lib';
import useCachedUser from '../../../hooks/useCachedUser';
import useAppLogger from '../../../hooks/useAppLogger';
import useNotificationHelper from '../../../hooks/useNotificationHelper';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import moment from 'moment';
import axios, { CancelTokenSource } from 'axios';
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import Dropdown, { IDropdownOption } from '../Dropdown/Dropdown';
import Checkbox from '../../shared/Checkbox/Checkbox';
import './TestAssignmentCreate.scss';
import {
  tabsIowaFlexMath,
  tabsIowaFlexReading,
  tabsCogatCompleteForm7and8,
  tabsCogatScreeningFormOther,
} from './TestAssignmentCreateInitial';
import { ReactComponent as IconPrint } from './Calendar/calendarIcons/print.svg';
import { ReactComponent as CalendarIcon } from './Calendar/calendarIcons/calendar.svg';
import TACloseReopenSection from './TACloseReopenSection/TACloseReopenSection';
import classNames from 'classnames';
import NormSeason, { getNormSeason, NormSeasonType } from './NormSeason/NormSeason';
import {
  getAdjacentNormSeasons,
  isAdjacentNormSeason,
  isCogATScreeningAndPostScreenerFormAssignmentType,
  MAX_DAYS_CREATE_TEST_ASSIGNMENT_IN_FUTURE,
  MAX_DAYS_CREATE_TEST_ASSIGNMENT_IN_PAST,
  MAX_DAYS_TEST_WINDOW_DURATION_COGAT_COMPLETE,
  MAX_DAYS_TEST_WINDOW_DURATION_COGAT_POST_SCREENER,
  MAX_DAYS_TEST_WINDOW_DURATION_COGAT_SCREENING,
} from './TestAssignmentCreate.util';

import {
  AssignmentListEditItem,
  GradeDomainType,
  EditAssignmentType,
  CogatLevelType,
  LevelOptions,
  LevelOptionsBattery,
  IIowaFlexDomainInfo,
  IowaFlexBatteryType,
  Battery,
} from '../TestAssignment.d';
import {
  defaultLevelByGrade,
  outOfGradeLevelTestingLevels,
  arrLevels,
} from '../TestAssignmentPrototypes';
import MidpointDateRangePicker from './MidpointDateRangePicker/MidpointDateRangePicker';
import { InputFocus } from './MidpointDateRangePicker/DateRangePicker/DateRangePicker';
import { Matcher } from 'react-day-picker';
import useApiHelper from '../../../hooks/useApiHelper';
import SpinnerIcon from '../../shared/icons/SpinnerIcon';
import {
  CompareAssignmentItem,
  CreateAssignmentRequest,
  NotificationSeverity,
} from '../../../hooks/useCheckForDuplicateAssignment/useCheckForDuplicateAssignment.types';
import { useCheckForDuplicateAssignment } from '../../../hooks/useCheckForDuplicateAssignment/useCheckForDuplicateAssignment';
import { fetchPopulatedAssignments } from '../../../hooks/useCheckForDuplicateAssignment/fetchPopulatedAssignments.util';
import { DuplicateAssignmentAlert } from './DuplicateAssignmentAlert';
import { BooleanFlags, useBooleanFlagWithConfig } from '../../../flags';

let varIsCogat = false;

const month = Number(moment().format('M'));

const dropdownNormativeYearOptionsInitial = [
  { label: 'Season: Fall', value: 'Fall', selected: month >= 8 && month <= 11 }, //  8/1-11/30 = Fall
  { label: 'Season: Mid-Year', value: 'Mid-Year', selected: month >= 12 || month <= 2 }, //  12/1-2/28 or 2/29 = Midyear
  { label: 'Season: Spring', value: 'Spring', selected: month >= 3 && month <= 7 }, //  3/1-7/31 = Spring
];

const isOutOfGradeLevelTestingLevel = (grade: string, level: string): boolean => {
  const outOfLevelGrade = outOfGradeLevelTestingLevels.find((g) => g.grade === grade);
  return !!outOfLevelGrade?.levels.includes(level);
};

type EditAssignmentRouteParams = {
  assignmentId?: string;
};

let editData: AssignmentListEditItem | undefined;
let varEndDateCogatScreening = 30;

let responseEntitlementsByCustomer: any;
let selectedEntitlementType: any;

let cancelTokenSource: CancelTokenSource | null = null;

const TestAssignmentCreate = (props: any) => {
  const config = useConfigContext();
  const ApiHelper = useApiHelper('Component|TestAssignmentCreate');
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useCachedUser();
  const NotificationHelper = useNotificationHelper();
  const logger = useAppLogger('Component|TestAssignmentCreate');
  const params = useParams<EditAssignmentRouteParams>();
  const { isEnabled: testingIsDisabled } = useBooleanFlagWithConfig(BooleanFlags.DisableTesting);

  usePageTitle(params?.assignmentId ? 'Edit Test Assignment' : 'Create a New Test Assignment');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [dropdownTestAssignmentOptions, setDropdownTestAssignmentOptions] = useState<
    IDropdownOption[]
  >([]);
  const [dropdownNormativeYearOptions, setDropdownNormativeYearOptions] = useState(
    dropdownNormativeYearOptionsInitial,
  );
  const [testAssignmentIowaFlex, setTestAssignmentIowaFlex] = useState(tabsIowaFlexMath);
  const [testAssignmentCogat, setTestAssignmentCogat] = useState(tabsCogatCompleteForm7and8);
  const [testAssignmentType, setTestAssignmentType] = useState('IowaFlex Math');
  const [headerText, setHeaderText] = useState('Select Domains for Grades');
  const [isCogat, setIsCogat] = useState(false);
  const [isDoubleCogatCalendar, setIsDoubleCogatCalendar] = useState(false);
  const [checkboxAutoAssigneState, setCheckboxAutoAssigneState] = useState(false);
  const [isAnyDomainChecked, setIsAnyDomainChecked] = useState(false);
  const [isDomainCheckedMin3Max5, setIsDomainCheckedMin3Max5] = useState(true);
  const [isAnyBatteryChecked, setIsAnyBatteryChecked] = useState(false);
  const [testAssignmentName, setTestAssignmentName] = useState('');
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(30, 'd'));
  const [midpointDate, setMidpointDate] = useState<moment.Moment | null>(
    moment(new Date((moment().toDate().getTime() + moment().add(30, 'd').toDate().getTime()) / 2)),
  );
  const [isMidpointDateLocked, setIsMidpointDateLocked] = useState(false);
  const [normSeason, setNormSeason] = useState<NormSeasonType | null>(null);
  const [lockStartDate, setLockStartDate] = useState<moment.Moment | null>(null);
  const [startDateCogatScreening, setStartDateCogatScreening] = useState(moment());
  const [endDateCogatScreening, setEndDateCogatScreening] = useState(
    moment().add(varEndDateCogatScreening, 'd'),
  );
  const [midpointDateCogatScreening, setMidpointDateCogatScreening] = useState<
    moment.Moment | null | undefined
  >(
    moment(
      new Date(
        (moment().toDate().getTime() +
          moment().add(varEndDateCogatScreening, 'd').toDate().getTime()) /
          2,
      ),
    ),
  );
  const [isMidpointDateCogatScreeningLocked, setIsMidpointDateCogatScreeningLocked] =
    useState(false);
  const [normSeasonCogatScreening, setNormSeasonCogatScreening] = useState<NormSeasonType | null>(
    null,
  );
  const [postScreenerLockStartDate, setPostScreenerLockStartDate] = useState<moment.Moment | null>(
    null,
  );
  const [startDateCogatPostScreener, setStartDateCogatPostScreener] = useState(
    moment().add(varEndDateCogatScreening + 1, 'd'),
  );
  const [endDateCogatPostScreener, setEndDateCogatPostScreener] = useState(
    moment().add(varEndDateCogatScreening + 1 + 30, 'd'),
  );
  const [midpointDateCogatPostScreener, setMidpointDateCogatPostScreener] =
    useState<moment.Moment | null>(
      moment(
        new Date(
          (moment()
            .add(varEndDateCogatScreening + 1, 'd')
            .toDate()
            .getTime() +
            moment()
              .add(varEndDateCogatScreening + 1 + 30, 'd')
              .toDate()
              .getTime()) /
            2,
        ),
      ),
    );
  const [isMidpointDateCogatPostScreenerLocked, setIsMidpointDateCogatPostScreenerLocked] =
    useState(false);
  const [normSeasonCogatPostScreenerOptions, setNormSeasonCogatPostScreenerOptions] = useState<
    NormSeasonType[]
  >(['Fall', 'Midyear', 'Spring']);
  const [normSeasonCogatPostScreener, setNormSeasonCogatPostScreener] =
    useState<NormSeasonType | null>(null);
  const [testAssignmentDisabled, setTestAssignmentDisabled] = useState(false);
  const [dropdownSectionOptions, setDropdownSectionOptions] = useState<IDropdownOption[]>([]);
  const [stateDropdownSectionOptions, setStateDropdownSectionOptions] = useState<IDropdownOption[]>(
    [],
  );
  const [dropdownSectionDisabled, setDropdownSectionDisabled] = useState(true);
  const [gradeList, setGradeList] = useState<string[]>([]);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(true);
  const [isPopupOutOfGradeLevelAssignmentVisible, setIsPopupOutOfGradeLevelAssignmentVisible] =
    useState(false);
  const [
    popupOutOfGradeLevelAssignmentSelectedGrade,
    setPopupOutOfGradeLevelAssignmentSelectedGrade,
  ] = useState('');
  const [isPopupPostScreener30DaysVisible, setIsPopupPostScreener30DaysVisible] = useState(false);
  const [
    isPostScreenerScheduledOutOfScreeningFormsRange,
    setIsPostScreenerScheduledOutOfScreeningFormsRange,
  ] = useState(false);
  const [
    isPostScreenerScheduledOutOfScreeningFormsRangePopupVisible,
    setIsPostScreenerScheduledOutOfScreeningFormsRangePopupVisible,
  ] = useState(false);
  const [editAssignment, setEditAssignment] = useState<EditAssignmentType>({
    assignmentName: '',
    assignmentId: -1,
    status: 'Closed',
    startDate: '',
    gradedomains: [],
    assignmentType: '',
    mid_point_date: null,
    season: null,
    ps_mid_point_date: null,
    ps_season: null,
    is_deleteable: 0,
  });
  const [isCorrectScreeningPostScreener, setIsCorrectScreeningPostScreener] = useState(true);
  const [isEntitledCogatPostScreenerForm7, setIsEntitledCogatPostScreenerForm7] = useState(false);
  const [isEntitledCogatPostScreenerForm8, setIsEntitledCogatPostScreenerForm8] = useState(false);
  const [selectedEntitlement, setSelectedEntitlement] = useState('');

  const [dateRangePickerFocusedInput, setDateRangePickerFocusedInput] = useState<InputFocus | null>(
    null,
  );
  const [
    dateRangePickerFocusedInputCogatPostScreener,
    setDateRangePickerFocusedInputCogatPostScreener,
  ] = useState<InputFocus | null>(null);
  const [
    dateRangePickerWindowMaxLengthExceededError,
    setDateRangePickerWindowMaxLengthExceededError,
  ] = useState(false);
  const [dateRangePickerHelperText, setDateRangePickerHelperText] = useState('');
  const [
    dateRangePickerCogatPostScreenerWindowMaxLengthExceededError,
    setDateRangePickerCogatPostScreenerWindowMaxLengthExceededError,
  ] = useState(false);
  const [
    dateRangePickerCogatPostScreenerHelperText,
    setDateRangePickerCogatPostScreenerHelperText,
  ] = useState('');
  const [isEditAssignmentCurrentlyTesting, setIsEditAssignmentCurrentlyTesting] = useState(false);

  const isPermissionEnableOutOfGradeLevelAssignments = useMemo<boolean>(() => {
    return !!user.currentPermissions.includes(56); //permissionName: 'TestAssignmentAdministration-EnableOutOfGradeLevelAssignments'
  }, [user.role, user.currentPermissions]);

  const isPermissionAllowNormSeasonSelection = useMemo<boolean>(() => {
    return !!user.currentPermissions.includes(58); //permissionName: 'TestAssignmentAdministration-AllowNormSeasonSelection'
  }, [user.role, user.currentPermissions]);

  const isPermissionCRUDAssignments = useMemo<boolean>(() => {
    if (user.role !== 'district_admin') return false; //TODO temporary disabled permission for SA and teachers
    return !!user.currentPermissions.includes(72); //permissionName: 'TA-CRUDAssignments'
  }, [user.role, user.currentPermissions]);

  const isPermissionNegativeLicenseNotAllowed = useMemo<boolean>(() => {
    return !!user.currentPermissions.includes(89); //permissionName: 'AdminNegativeLicenseNotAllowed'
  }, [user.role, user.currentPermissions]);

  const isPermissionEnableAltV = useMemo<boolean>(() => {
    return false; //TODO temporary disabled permission
  }, [user.role, user.currentPermissions]);

  const isSelectSectionsDisabled =
    dropdownSectionDisabled ||
    !(user.role === ('district_admin' as typeof user.role) || isPermissionCRUDAssignments) ||
    (props.type === 'edit' && editAssignment.status === 'Closed');

  const deleteAssignment = async () => {
    const assignmentId = params?.assignmentId;
    if (assignmentId && !deleting) {
      setDeleting(true);
      try {
        const result = await ApiHelper.apiRequest('assignmentApiUrl', 'UpdateAssignmentStatus', {
          assignmentId: assignmentId,
          assignmentName: testAssignmentName,
          status: 'Trash',
          modifiedById: user.userId,
          modifiedByName: user.name,
        });
        if (!(result && result.data)) {
          NotificationHelper.add('Error trashing assignment', 'error');
          setShowDeleteModal(false);
          setDeleting(false);
        } else {
          setShowDeleteModal(false);
          setDeleting(false);
          history.push('/assignment/list');
        }
      } catch (exc: unknown) {
        const ex = exc as Error;
        logger.error(ex);
        NotificationHelper.add('Error trashing assignment', 'error');
        setShowDeleteModal(false);
        setDeleting(false);
      }
    }
  };

  useEffect(() => {
    if (!showDeleteModal) {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    } else if (!deleting) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="delete-assignment-modal"
            focusSelector=".default-modal-button"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            title={
              <div className="delete-assignment-message">Move {testAssignmentName} to trash?</div>
            }
            body={'The test assignment will be permanently deleted in 30 days.'}
            footer={
              <div className="button-wrapper">
                <Button
                  variant="outlined"
                  className="default-modal-button"
                  onClick={() => {
                    deleteAssignment();
                  }}
                >
                  Yes, move to trash
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowDeleteModal(false);
                  }}
                >
                  No, do not move to trash
                </Button>
              </div>
            }
            onClose={() => {
              setShowDeleteModal(false);
            }}
          />
        ),
      });
    } else {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="deleting-assignment-modal modal-loader"
            disableClose={true}
            disableCloseOnEsc={true}
            closeOnMaskClick={false}
            body={
              <div className="modal-loader-contents">
                <div className="modal-loader-title">Please Wait</div>
                <div className="modal-loader-spinner">
                  <div className="spinner-wrapper loader-spinner">
                    <SpinnerIcon />
                  </div>
                </div>
                <div className="modal-loader-message">Trashing Assignment</div>
              </div>
            }
            footer={null}
          />
        ),
      });
    }
  }, [showDeleteModal, deleting]);

  useEffect(() => {
    if (isPostScreenerScheduledOutOfScreeningFormsRangePopupVisible) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="post-screener-out-of-screening-forms-range-modal"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            body={
              <div className="post-screener-out-of-screening-forms-range-modal-body">
                {
                  'Post-Screener assignment window must begin within 30 days of Screening Form midpoint.\nTesting beyond this window may affect the validity of student score results.'
                }
              </div>
            }
            footer={
              <div className="button-wrapper">
                <Button
                  className="default-modal-button"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    setIsPostScreenerScheduledOutOfScreeningFormsRangePopupVisible(false)
                  }
                >
                  Ok
                </Button>
              </div>
            }
            onClose={() => {
              setIsPostScreenerScheduledOutOfScreeningFormsRangePopupVisible(false);
            }}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [isPostScreenerScheduledOutOfScreeningFormsRangePopupVisible]);

  useEffect(() => {
    if (isPopupPostScreener30DaysVisible) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="popup-modal-post-screener-30-days"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            body={
              <div className="popup-modal-post-screener-30-days-body">
                <p>
                  Post-Screener should be administered within 30 days of the Screening Form. Testing
                  beyond this window may affect the validity of student score results.
                  <br />
                  <br />
                  Are you sure you want to keep the existing dates selected?
                </p>
              </div>
            }
            footer={
              <div className="button-wrapper">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsPopupPostScreener30DaysVisible(false);
                  }}
                >
                  Yes - keep these dates
                </Button>
                <Button
                  className="default-modal-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsPopupPostScreener30DaysVisible(false);
                    document.getElementById('startDateIdPostScreener')?.focus();
                  }}
                >
                  No - change these dates
                </Button>
              </div>
            }
            onClose={() => {
              setIsPopupPostScreener30DaysVisible(false);
              document.getElementById('startDateIdPostScreener')?.focus();
            }}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [isPopupPostScreener30DaysVisible]);

  useEffect(() => {
    if (isPopupOutOfGradeLevelAssignmentVisible) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="create-assignment-modal"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            body={
              <React.Fragment>
                <p className="create-assignment-modal-warning-text">
                  You have chosen a level which is not typical for this grade. The typical level for
                  Grade {popupOutOfGradeLevelAssignmentSelectedGrade.replace('G ', '')} is{' '}
                  {getDefaultLevel(popupOutOfGradeLevelAssignmentSelectedGrade).replace('L ', '')}.
                </p>
                <p className="create-assignment-modal-warning-text">Do you want to continue?</p>
              </React.Fragment>
            }
            footer={
              <div className="button-wrapper">
                <Button
                  variant="outlined"
                  className="button"
                  onClick={() => {
                    setIsPopupOutOfGradeLevelAssignmentVisible(false);
                  }}
                >
                  Assign this level
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="button primary default-modal-button"
                  onClick={() => {
                    setIsPopupOutOfGradeLevelAssignmentVisible(false);
                    selectDefaultLevel();
                  }}
                >
                  No - select a different level
                </Button>
              </div>
            }
            onClose={() => {
              setIsPopupOutOfGradeLevelAssignmentVisible(false);
              selectDefaultLevel();
            }}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [isPopupOutOfGradeLevelAssignmentVisible]);

  useEffect(() => {
    if (props.type === 'create') {
      loadEntitlementsByCustomer();
      loadGetGrades();
      if (user.role === 'teacher') {
        loadGetSections();
      }
    }
    if (props.type === 'edit' && params?.assignmentId) {
      loadGetAssignmentById(params?.assignmentId);
      loadAssignmentActiveTesting(params?.assignmentId);
    }
  }, []);

  useEffect(() => {
    if (varIsCogat && !midpointDate?.isSame(moment(editData?.mid_point_date), 'date'))
      synchronizeSeasonDropdown();
  }, [isCogat, endDate, endDateCogatPostScreener, isDoubleCogatCalendar, testAssignmentType]);

  useEffect(() => {
    if (varIsCogat && checkboxAutoAssigneState) synchronizeSeasonDropdown();
  }, [checkboxAutoAssigneState]);

  useEffect(() => {
    if (isSpinnerVisible && gradeList.length && dropdownTestAssignmentOptions.length) {
      if (user.role === 'teacher') {
        if (!dropdownSectionDisabled) {
          setIsSpinnerVisible(false);
        }
      } else {
        setIsSpinnerVisible(false);
      }
    }
  }, [gradeList, dropdownTestAssignmentOptions, isSpinnerVisible, user.role]);

  useEffect(() => {
    if (isCogat) {
      if (props.type === 'create') selectCogatLevelTabByDefaultLevel();
      else resetEditValuesToInitial();
    }
  }, [isCogat]);

  useEffect(() => {
    checkIsAutoAssigned();
  }, [testAssignmentIowaFlex, testAssignmentCogat]);

  const loadGetAssignmentById = async (assignmentId: string) => {
    logger.debug('Loading GetAssignmentById');
    try {
      cancelTokenSource = axios.CancelToken.source();
      const result = await ApiHelper.apiRequest('assignmentApiUrl', 'GetAssignmentById', {
        assignmentid: assignmentId,
      });
      cancelTokenSource = null;
      if (result && result.data && Array.isArray(result.data) && result.data[0]) {
        if (result.data[0].gradedomains === null) {
          result.data[0].gradedomains = [];
        } else {
          result.data[0].gradedomains = JSON.parse(result.data[0].gradedomains);
        }

        editData = result.data[0] as AssignmentListEditItem;
        if (editData.sections === null) {
          editData.sections = [];
        }

        if (editData?.lock_start_date) {
          setLockStartDate(moment(editData.lock_start_date));

          if (isCogATScreeningAndPostScreenerFormAssignmentType(editData.assignmenttype)) {
            setIsMidpointDateCogatScreeningLocked(true);

            if (editData?.lock_ps_start_date) {
              setIsMidpointDateCogatPostScreenerLocked(true);
            }
          } else {
            setIsMidpointDateLocked(true);
          }
        }

        if (editData?.lock_ps_start_date) {
          setPostScreenerLockStartDate(moment(editData.lock_ps_start_date));
        }

        setEditAssignment({
          assignmentName: editData.assignmentname,
          assignmentId: Number(assignmentId),
          status: editData.status,
          startDate: `${editData.startdate}`,
          gradedomains: editData.gradedomains.map((gradeDomain: GradeDomainType) => {
            return `${gradeDomain?.grade_id}`;
          }),
          assignmentType: editData.assignmenttype,
          mid_point_date: `${editData?.mid_point_date}`,
          season: editData?.season ? (editData.season as NormSeasonType) : null,
          ps_mid_point_date: `${editData?.ps_mid_point_date}`,
          ps_season: editData?.ps_season ? (editData.ps_season as NormSeasonType) : null,
          is_deleteable: editData?.is_deleteable ? editData.is_deleteable : 0,
        });
        loadEntitlementsByCustomer();
        loadGetGrades();
        if (user.role === 'teacher') loadGetSections();

        logger.debug('Done loading GetAssignmentById');
      } else {
        logger.error('Error loading GetAssignmentById, no data returned');
        NotificationHelper.add('Error loading GetAssignmentById', 'error');
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      if (err.message !== config.api.cancelExceptionMessage) {
        logger.error(err.message);
        NotificationHelper.add('Failed loading GetAssignmentById ' + err.message, 'error');
      }
    }
  };

  const resetEditValuesToInitial = () => {
    if (typeof editData !== 'undefined') {
      setTestAssignmentName(editData.assignmentname);
      if (
        editData.assignmenttype === 'CogAT Screening & Post-Screener Form 7' ||
        editData.assignmenttype === 'CogAT Screening & Post-Screener Form 8'
      ) {
        setStartDateCogatScreening(moment(editData.startdate));
        setEndDateCogatScreening(moment(editData.enddate));
        if (editData.psstartdate) setStartDateCogatPostScreener(moment(editData.psstartdate));
        if (editData.psenddate) setEndDateCogatPostScreener(moment(editData.psenddate));
      } else {
        setStartDate(moment(editData.startdate));
        setEndDate(moment(editData.enddate));
      }

      const isCogat =
        editData.assignmenttype === 'IowaFlex Math' ||
        editData.assignmenttype === 'IowaFlex Reading'
          ? false
          : true;
      let gradeId = '';
      let selectedDomais: any = [];
      let selectedLevel = '';
      let gradeDomain: any;
      let selectedBatteries: any = [];
      let checkedBatteriesNumber = 0;
      let firstGradeWithSelectedDomains = -1;
      let firstGradeWithSelectedBatteries = -1;
      let isfirstLevelWithSelectedBatteriesClicked = false;
      let elementId = '';

      let assignmentStructure: any;
      if (isCogat) {
        if (
          editData.assignmenttype === 'CogAT Complete Form 7' ||
          editData.assignmenttype === 'CogAT Complete Form 8'
        )
          assignmentStructure = tabsCogatCompleteForm7and8;
        else assignmentStructure = tabsCogatScreeningFormOther;
      } else {
        if (editData.assignmenttype === 'IowaFlex Math') assignmentStructure = tabsIowaFlexMath;
        else assignmentStructure = tabsIowaFlexReading;
      }
      const editTestAssignment = JSON.parse(JSON.stringify(assignmentStructure)); //clone

      editTestAssignment.forEach((grade: any, indexGrade: number) => {
        gradeId = grade.label.substring(2);
        if (isCogat) {
          grade.checkedLevelsNumber = 0;
          grade.levels.forEach((level: CogatLevelType) => {
            level.checkedBatteriesNumber = 0;
            if (level.batteries.length) {
              /* CogAT Complete Form 7/8 */
              level.batteries.forEach((battery: IowaFlexBatteryType) => {
                battery.checked = false;
              });
            } else {
              /* CogAT Screening & Post-Screener Form 7/8 */
              level.options.screening.checked = false;
              if (
                typeof level.options.altScreening !== 'undefined' &&
                Object.keys(level.options.altScreening).length
              ) {
                level.options.altScreening.checked = false;
              }
              level.options.postScreener.checked = false;
              if (
                typeof level.options.altPostScreener !== 'undefined' &&
                Object.keys(level.options.altPostScreener).length
              ) {
                level.options.altPostScreener.checked = false;
              }
              level.options.postScreener.batteries.forEach((battery) => {
                if (battery.optional === true) battery.checked = false;
              });
            }
          });
          if (typeof editData !== 'undefined') {
            gradeDomain = editData.gradedomains.filter((n: any) => n.grade_id === gradeId);
            if (gradeDomain.length) {
              if (firstGradeWithSelectedBatteries === -1)
                firstGradeWithSelectedBatteries = indexGrade;
              selectedLevel = gradeDomain[0].level.replace('Level ', 'L ');
              if (gradeDomain[0].domain_id !== null) {
                selectedBatteries = gradeDomain[0].domain_id.split(',');
              } else {
                selectedBatteries = [];
              }
              isfirstLevelWithSelectedBatteriesClicked = false;
              grade.levels.forEach((level: CogatLevelType, indexLevel: number) => {
                if (level.label === selectedLevel) {
                  if (!isfirstLevelWithSelectedBatteriesClicked) {
                    isfirstLevelWithSelectedBatteriesClicked = true;
                    elementId =
                      'test-assignment-tab-grade' +
                      grade.label.replace('G', '').replace('K', '0').replace(' ', '') +
                      '-level' +
                      indexLevel;
                    document.getElementById(elementId)?.click();
                  }
                  if (level.batteries.length) {
                    /* CogAT Complete Form 7/8 */
                    level.batteries.forEach((battery: IowaFlexBatteryType) => {
                      if (
                        selectedBatteries.indexOf(battery.text.substring(0, 1)) !== -1 ||
                        (battery.text === 'Alt Verbal' && selectedBatteries.indexOf('AV') !== -1)
                      )
                        battery.checked = true;
                    });
                    level.checkedBatteriesNumber = selectedBatteries.length;
                    grade.checkedLevelsNumber = selectedBatteries.length;
                  } else {
                    /* CogAT Screening & Post-Screener Form 7/8 */
                    checkedBatteriesNumber = 0;
                    if (gradeDomain[0].domain_id === 'S') {
                      level.options.screening.checked = true;
                      checkedBatteriesNumber += level.options.screening.batteries.length;
                    }
                    if (
                      typeof level.options.altScreening !== 'undefined' &&
                      gradeDomain[0].domain_id === 'AS' &&
                      Object.keys(level.options.altScreening).length
                    ) {
                      level.options.altScreening.checked = true;
                      checkedBatteriesNumber += level.options.altScreening.batteries.length;
                    }
                    if (gradeDomain[0].ps_domain_id && gradeDomain[0].ps_domain_id === 'PS') {
                      level.options.postScreener.checked = true;
                      checkedBatteriesNumber += level.options.postScreener.batteries.length;
                    }
                    if (
                      typeof level.options.altPostScreener !== 'undefined' &&
                      gradeDomain[0].ps_domain_id &&
                      gradeDomain[0].ps_domain_id === 'APS' &&
                      Object.keys(level.options.altPostScreener).length
                    ) {
                      level.options.altPostScreener.checked = true;
                      checkedBatteriesNumber += level.options.altPostScreener.batteries.length;
                    }
                    if (
                      gradeDomain[0].domain_id === 'AS' &&
                      gradeDomain[0].ps_domain_id === 'PSV'
                    ) {
                      level.options.postScreener.checked = true;
                      checkedBatteriesNumber += level.options.postScreener.batteries.length;
                      level.options.postScreener.batteries.forEach((battery) => {
                        if (battery.optional === true) {
                          battery.checked = true;
                        }
                      });
                    }
                    level.checkedBatteriesNumber = checkedBatteriesNumber;
                    grade.checkedLevelsNumber = checkedBatteriesNumber;
                  }
                }
              });
            }
          }
        } else {
          grade.domains.forEach((domain: any) => {
            domain.checked = false;
          });
          if (typeof editData !== 'undefined') {
            selectedDomais = editData.gradedomains.filter((n: any) => n.grade_id === gradeId);
            if (selectedDomais.length) {
              if (firstGradeWithSelectedDomains === -1) firstGradeWithSelectedDomains = indexGrade;
              selectedDomais = selectedDomais[0].domain_id.split(',');
              grade.domains.forEach((domain: any) => {
                if (selectedDomais.indexOf(domain.standard) !== -1) domain.checked = true;
              });
            }
            grade.checkedDomainNumber = selectedDomais.length;
          }
        }
      });

      if (isCogat) {
        setIsAnyBatteryChecked(true);
        setTestAssignmentCogat(filterGrades(editTestAssignment));
        document.getElementById('test-assignment-tab' + firstGradeWithSelectedBatteries)?.click();
      } else {
        setIsAnyDomainChecked(true);
        setIsDomainCheckedMin3Max5(true);
        setTestAssignmentIowaFlex(filterGrades(editTestAssignment));
        document.getElementById('test-assignment-tab' + firstGradeWithSelectedDomains)?.click();
      }
      setIsCogat(isCogat);
      varIsCogat = isCogat;
    }
  };

  const resetCalendarDates = () => {
    setStartDate(moment());
    setEndDate(moment().add(30, 'd'));

    setStartDateCogatScreening(moment());
    varEndDateCogatScreening = 30;
    setEndDateCogatScreening(moment().add(varEndDateCogatScreening, 'd'));

    setStartDateCogatPostScreener(moment().add(varEndDateCogatScreening + 1, 'd'));
    setEndDateCogatPostScreener(moment().add(varEndDateCogatScreening + 1 + 30, 'd'));
  };

  const handleTestAssignmentChange = (options: IDropdownOption[] | IDropdownOption) => {
    const opts: IDropdownOption[] = Array.isArray(options) ? options : [options];
    const option = opts[0];
    selectedEntitlementType = JSON.parse(option.value);
    setSelectedEntitlement(option.label);
    if (
      (option.label === 'CogAT Screening & Post-Screener Form 7' &&
        !isEntitledCogatPostScreenerForm7) ||
      (option.label === 'CogAT Screening & Post-Screener Form 8' &&
        !isEntitledCogatPostScreenerForm8)
    ) {
      NotificationHelper.add('Account not currently entitled for Post-Screener.', 'warning'); // 10000, GP1-11848
    }
    handleResetTestAssignment();
    setTestAssignmentType(option.label);
    resetCalendarDates();
    if (option.label === 'IowaFlex Math' || option.label === 'IowaFlex Reading') {
      if (option.label === 'IowaFlex Math') {
        setTestAssignmentIowaFlex(filterGrades(JSON.parse(JSON.stringify(tabsIowaFlexMath))));
      } else {
        setTestAssignmentIowaFlex(filterGrades(JSON.parse(JSON.stringify(tabsIowaFlexReading))));
      }
      setHeaderText('Select grades and domains:');
      setIsCogat(false);
      varIsCogat = false;
      setIsDoubleCogatCalendar(false);
    } else {
      if (option.label === 'CogAT Complete Form 7' || option.label === 'CogAT Complete Form 8') {
        setTestAssignmentCogat(
          filterGrades(JSON.parse(JSON.stringify(tabsCogatCompleteForm7and8))),
        );
      } else {
        setTestAssignmentCogat(
          filterGrades(JSON.parse(JSON.stringify(tabsCogatScreeningFormOther))),
        );
      }
      setHeaderText('Select grades, levels and batteries for testing:');
      setIsCogat(true);
      varIsCogat = true;
      if (
        option.label === 'CogAT Screening & Post-Screener Form 7' ||
        option.label === 'CogAT Screening & Post-Screener Form 8'
      ) {
        if (
          (option.label === 'CogAT Screening & Post-Screener Form 7' &&
            isEntitledCogatPostScreenerForm7) ||
          (option.label === 'CogAT Screening & Post-Screener Form 8' &&
            isEntitledCogatPostScreenerForm8)
        ) {
          setIsDoubleCogatCalendar(true);
        }
        if (
          typeof editData !== 'undefined' &&
          props.type === 'edit' &&
          editData.psentitlement === '0' &&
          ((option.label === 'CogAT Screening & Post-Screener Form 7' &&
            isEntitledCogatPostScreenerForm7) ||
            (option.label === 'CogAT Screening & Post-Screener Form 8' &&
              isEntitledCogatPostScreenerForm8))
        ) {
          NotificationHelper.add('Account has been entitled for Post-Screener.', 'warning'); // 10000, GP1-11848
        }
      } else {
        setIsDoubleCogatCalendar(false);
      }
      selectCogatLevelTabByDefaultLevel();
    }
    if (props.type === 'edit') {
      resetEditValuesToInitial();
    }
  };

  const numberOfSelectedBatteries = (options: LevelOptions) => {
    const propNames = Array.from(Object.keys(options)).map((key) => key as keyof typeof options);
    const num = propNames.reduce((acc: number, propName): number => {
      const currentOption = options[propName];
      if (currentOption && currentOption.checked) {
        currentOption.batteries.forEach((battery: LevelOptionsBattery) => {
          if (battery.checked) {
            acc++;
          }
          if (
            battery.optional === true &&
            !battery.checked &&
            (options.screening.checked ||
              (!options.screening.checked && !options?.altScreening?.checked))
          ) {
            acc++;
          }
        });
      }
      return acc;
    }, 0);

    return num;
  };

  const checkIsAnyBatteryChecked = (testAssignment: any) => {
    let isAnyBatteryChecked = false;
    for (let i = 0; i < testAssignment.length; i++) {
      for (let j = 0; j < testAssignment[i].levels.length; j++) {
        if (testAssignment[i].levels[j].checkedBatteriesNumber) {
          isAnyBatteryChecked = true;
          break;
        }
      }
      if (isAnyBatteryChecked) break;
    }
    setIsAnyBatteryChecked(isAnyBatteryChecked);
  };

  const checkIsCorrectScreeningPostScreener = (testAssignment: any) => {
    let isCorrect = true;
    let options: any;
    for (let i = 0; i < testAssignment.length; i++) {
      for (let j = 0; j < testAssignment[i].levels.length; j++) {
        options = testAssignment[i].levels[j].options;
        //if both screening AND altScreening unchecked
        if (
          options !== undefined &&
          !options.screening.checked &&
          (!Object.keys(options.altScreening).length ||
            (Object.keys(options.altScreening).length && !options.altScreening.checked))
        ) {
          //if postScreener OR altPostScreener checked
          if (
            options.postScreener.checked ||
            (Object.keys(options.altPostScreener).length && options.altPostScreener.checked)
          ) {
            isCorrect = false;
            break;
          }
        }
      }
      if (!isCorrect) break;
    }
    setIsCorrectScreeningPostScreener(isCorrect);
  };

  const handleScreeningPostScreenerOptionalBatteryChange = (
    isChecked: boolean,
    indexGrade: number,
    indexLevel: number,
    type: string,
    batteryText: string,
  ) => {
    const newTestAssignment = testAssignmentCogat.slice(); //clone
    newTestAssignment[indexGrade].levels[indexLevel].options[type].batteries = newTestAssignment[
      indexGrade
    ].levels[indexLevel].options[type].batteries.map((battery: IowaFlexBatteryType) =>
      battery.text === batteryText ? { ...battery, checked: isChecked } : battery,
    );
    newTestAssignment[indexGrade].levels[indexLevel].checkedBatteriesNumber =
      numberOfSelectedBatteries(newTestAssignment[indexGrade].levels[indexLevel].options);
    newTestAssignment[indexGrade].checkedLevelsNumber =
      newTestAssignment[indexGrade].levels[indexLevel].checkedBatteriesNumber;
    setTestAssignmentCogat(filterGrades(newTestAssignment));
  };

  const handleScreeningPostScreenerTypeChange = (
    isChecked: boolean,
    indexGrade: number,
    indexLevel: number,
    type: string,
  ) => {
    const newTestAssignment = testAssignmentCogat.slice(); //clone
    if (type === 'screening') {
      newTestAssignment[indexGrade].levels[indexLevel].options['screening'].checked = isChecked;
      if (
        Object.keys(newTestAssignment[indexGrade].levels[indexLevel].options['altScreening'])
          .length > 0
      ) {
        if (
          isChecked &&
          newTestAssignment[indexGrade].levels[indexLevel].options['altScreening'].checked
        ) {
          newTestAssignment[indexGrade].levels[indexLevel].options['altScreening'].checked = false;
        }
      }
    }
    if (type === 'altScreening') {
      newTestAssignment[indexGrade].levels[indexLevel].options['altScreening'].checked = isChecked;
      if (
        isChecked &&
        newTestAssignment[indexGrade].levels[indexLevel].options['screening'].checked
      ) {
        newTestAssignment[indexGrade].levels[indexLevel].options['screening'].checked = false;
      }
    }
    if (type === 'postScreener') {
      newTestAssignment[indexGrade].levels[indexLevel].options['postScreener'].checked = isChecked;
      if (
        Object.keys(newTestAssignment[indexGrade].levels[indexLevel].options['altPostScreener'])
          .length > 0
      ) {
        if (
          isChecked &&
          newTestAssignment[indexGrade].levels[indexLevel].options['altPostScreener'].checked
        ) {
          newTestAssignment[indexGrade].levels[indexLevel].options['altPostScreener'].checked =
            false;
        }
      }
    }
    if (type === 'altPostScreener') {
      newTestAssignment[indexGrade].levels[indexLevel].options['altPostScreener'].checked =
        isChecked;
      if (
        isChecked &&
        newTestAssignment[indexGrade].levels[indexLevel].options['postScreener'].checked
      ) {
        newTestAssignment[indexGrade].levels[indexLevel].options['postScreener'].checked = false;
      }
    }
    newTestAssignment[indexGrade].levels[indexLevel].checkedBatteriesNumber =
      numberOfSelectedBatteries(newTestAssignment[indexGrade].levels[indexLevel].options);
    newTestAssignment[indexGrade].checkedLevelsNumber =
      newTestAssignment[indexGrade].levels[indexLevel].checkedBatteriesNumber;

    checkIsAnyBatteryChecked(newTestAssignment);
    checkIsCorrectScreeningPostScreener(newTestAssignment);
    setTestAssignmentCogat(filterGrades(newTestAssignment));
    checkIsAutoAssigned();
  };

  const handleBatteryChange = (
    isChecked: boolean,
    indexGrade: number,
    indexLevel: number,
    indexBattery: number,
    batteryText: string,
  ) => {
    const newTestAssignment = testAssignmentCogat.slice(); //clone

    if (
      batteryText === 'Verbal' &&
      indexBattery === 0 &&
      newTestAssignment[indexGrade].levels[indexLevel].batteries[1].text === 'Alt Verbal'
    ) {
      if (isChecked && newTestAssignment[indexGrade].levels[indexLevel].batteries[1].checked) {
        newTestAssignment[indexGrade].levels[indexLevel].batteries[1].checked = false;
        newTestAssignment[indexGrade].levels[indexLevel].checkedBatteriesNumber--;
        newTestAssignment[indexGrade].checkedLevelsNumber--;
      }
    }
    if (
      batteryText === 'Alt Verbal' &&
      indexBattery === 1 &&
      newTestAssignment[indexGrade].levels[indexLevel].batteries[0].text === 'Verbal'
    ) {
      if (isChecked && newTestAssignment[indexGrade].levels[indexLevel].batteries[0].checked) {
        newTestAssignment[indexGrade].levels[indexLevel].batteries[0].checked = false;
        newTestAssignment[indexGrade].levels[indexLevel].checkedBatteriesNumber--;
        newTestAssignment[indexGrade].checkedLevelsNumber--;
      }
    }

    newTestAssignment[indexGrade].levels[indexLevel].batteries[indexBattery].checked = isChecked;
    if (isChecked) {
      newTestAssignment[indexGrade].levels[indexLevel].checkedBatteriesNumber++;
      newTestAssignment[indexGrade].checkedLevelsNumber++;
    } else {
      newTestAssignment[indexGrade].levels[indexLevel].checkedBatteriesNumber--;
      newTestAssignment[indexGrade].checkedLevelsNumber--;
    }

    // clear non-focused level's batteries, only one level per grade allowed
    newTestAssignment?.[indexGrade].levels.forEach((level: CogatLevelType, levelIndex: number) => {
      if (levelIndex !== indexLevel) {
        level.checkedBatteriesNumber = 0;
        level.batteries.forEach((battery: IowaFlexBatteryType) => {
          if (battery.checked) {
            battery.checked = false;
            newTestAssignment[indexGrade].checkedLevelsNumber--;
          }
        });
      }
    });

    checkIsAnyBatteryChecked(newTestAssignment);
    setTestAssignmentCogat(filterGrades(newTestAssignment));
    checkIsAutoAssigned();
  };

  const handleDomainChange = (isChecked: boolean, indexGrade: number, indexDomain: number) => {
    const newTestAssignment = testAssignmentIowaFlex.slice(); //clone
    newTestAssignment[indexGrade].domains[indexDomain].checked = isChecked;
    if (isChecked) newTestAssignment[indexGrade].checkedDomainNumber++;
    else newTestAssignment[indexGrade].checkedDomainNumber--;

    let isAnyDomainChecked = false;
    let isDomainsCheckedCorrectly = true;
    let checkedDomainsNum: number;
    for (let i = 0; i < newTestAssignment.length; i++) {
      checkedDomainsNum = newTestAssignment[i].checkedDomainNumber;
      if (checkedDomainsNum) {
        isAnyDomainChecked = true;
        if (
          newTestAssignment[i].domains.length > 1 &&
          (checkedDomainsNum < 3 || checkedDomainsNum > 5)
        ) {
          //TODO: validation of 3-5 domains is temporarily disabled
          isDomainsCheckedCorrectly = false;
          break;
        }
      }
    }
    setIsAnyDomainChecked(isAnyDomainChecked);
    setIsDomainCheckedMin3Max5(isDomainsCheckedCorrectly);
    setTestAssignmentIowaFlex(filterGrades(newTestAssignment));
    checkIsAutoAssigned();
  };

  const seasonIndex = () => {
    let seasonIndex = 0;
    for (let i = 0; i < dropdownNormativeYearOptions.length; i++) {
      if (dropdownNormativeYearOptions[i].selected) {
        seasonIndex = i;
        break;
      }
    }
    return seasonIndex;
  };

  const checkIsAutoAssigned = () => {
    let isAutoAssigned = true;

    if (varIsCogat) {
      for (let i = 0; i < testAssignmentCogat.length; i++) {
        for (let j = 0; j < testAssignmentCogat[i].levels.length; j++) {
          /* CogAT Complete Form 7/8 */
          for (let k = 0; k < testAssignmentCogat[i].levels[j].batteries.length; k++) {
            if (
              testAssignmentCogat[i].levels[j].label ===
              defaultLevelByGrade[testAssignmentCogat[i].label][seasonIndex()]
            ) {
              if (testAssignmentCogat[i].levels[j].batteries[k].text !== 'Alt Verbal') {
                if (!testAssignmentCogat[i].levels[j].batteries[k].checked) {
                  isAutoAssigned = false;
                  break;
                }
              } else {
                if (testAssignmentCogat[i].levels[j].batteries[k].checked) {
                  isAutoAssigned = false;
                  break;
                }
              }
            } else {
              if (testAssignmentCogat[i].levels[j].batteries[k].checked) {
                isAutoAssigned = false;
                break;
              }
            }
          }

          /* CogAT Screening & Post-Screener Form 7/8 */
          if (
            testAssignmentCogat[i].levels[j].options !== undefined &&
            Object.keys(testAssignmentCogat[i].levels[j].options).length
          ) {
            if (
              testAssignmentCogat[i].levels[j].label ===
              defaultLevelByGrade[testAssignmentCogat[i].label][seasonIndex()]
            ) {
              for (const key in testAssignmentCogat[i].levels[j].options) {
                if (
                  ((key === 'screening' || key === 'postScreener') &&
                    !testAssignmentCogat[i].levels[j].options[key].checked) ||
                  ((key === 'altScreening' || key === 'altPostScreener') &&
                    Object.keys(testAssignmentCogat[i].levels[j].options[key]).length &&
                    testAssignmentCogat[i].levels[j].options[key].checked === true)
                ) {
                  isAutoAssigned = false;
                  break;
                }
              }
            } else {
              for (const key in testAssignmentCogat[i].levels[j].options) {
                if (
                  Object.keys(testAssignmentCogat[i].levels[j].options[key]).length &&
                  testAssignmentCogat[i].levels[j].options[key].checked === true
                ) {
                  isAutoAssigned = false;
                  break;
                }
              }
            }
          }

          if (!isAutoAssigned) break;
        }
        if (!isAutoAssigned) break;
      }
    } else {
      for (let i = 0; i < testAssignmentIowaFlex.length; i++) {
        for (let j = 0; j < testAssignmentIowaFlex[i].domains.length; j++) {
          if (!testAssignmentIowaFlex[i].domains[j].checked) {
            isAutoAssigned = false;
            break;
          }
        }
        if (!isAutoAssigned) break;
      }
    }

    setCheckboxAutoAssigneState(isAutoAssigned);
  };

  const wasAltVerbal = (grade: string, level: string) => {
    grade = grade.replace('G ', '');
    level = level.replace('L ', 'Level ');
    let result = false;
    if (typeof editData !== 'undefined') {
      const filteredGradeLevel = editData.gradedomains.filter(
        (n: any) => n.grade_id === grade && n.level === level,
      );
      if (
        filteredGradeLevel.length &&
        filteredGradeLevel[0]?.domain_id &&
        typeof filteredGradeLevel[0].domain_id === 'string'
      ) {
        const arrBatteries = filteredGradeLevel[0].domain_id.split(',');
        if (arrBatteries.indexOf('AV') !== -1) {
          result = true;
        }
      }
    }
    return result;
  };

  const wasAltScreening = (grade: string, level: string) => {
    grade = grade.replace('G ', '');
    level = level.replace('L ', 'Level ');
    let result = false;
    if (typeof editData !== 'undefined') {
      const filteredGradeLevel = editData.gradedomains.filter(
        (n: any) => n.grade_id === grade && n.level === level,
      );
      if (filteredGradeLevel.length) {
        if (filteredGradeLevel[0].domain_id === 'AS') result = true;
      }
    }
    return result;
  };

  const wasAltPostScreener = (grade: string, level: string) => {
    grade = grade.replace('G ', '');
    level = level.replace('L ', 'Level ');
    let result = false;
    if (typeof editData !== 'undefined') {
      const filteredGradeLevel = editData.gradedomains.filter(
        (n: any) => n.grade_id === grade && n.level === level,
      );
      if (filteredGradeLevel.length) {
        if (filteredGradeLevel[0].ps_domain_id === 'APS') result = true;
      }
    }
    return result;
  };

  const getDefaultLevel = (grade: string) => {
    return defaultLevelByGrade[grade][seasonIndex()];
  };

  const isDefaultLevel = (grade: string, level: string) => {
    return getDefaultLevel(grade) === level ? true : false;
  };

  const selectDefaultLevel = () => {
    const tabEl: HTMLLabelElement | null = document.querySelector(
      'input[name="tabs"]:checked + label',
    );
    let selectedGrade = 'G ';
    if (tabEl !== null) {
      selectedGrade += tabEl.innerText.trim();
    }
    if (selectedGrade.indexOf('\n') !== -1)
      selectedGrade = selectedGrade.substring(0, selectedGrade.indexOf('\n')).trim();
    const indexDefaultLevelByGrade = arrLevels.indexOf(
      defaultLevelByGrade[selectedGrade][seasonIndex()],
    );
    const elementId =
      'test-assignment-tab-grade' +
      selectedGrade.replace('G', '').replace('K', '0').replace(' ', '') +
      '-level' +
      indexDefaultLevelByGrade;
    document.getElementById(elementId)?.click();
  };

  const isOutOfGradeLevel = () => {
    const permissionOutOfGradeLevel = 56;
    let result = false;
    if (user.role && user.roles) {
      const filteredRoles = user.roles.filter((n: any) => n.name === user.role);
      if (filteredRoles.length) {
        const roleObject = filteredRoles[0];
        if (roleObject.permissions !== undefined) {
          if (roleObject.permissions.indexOf(permissionOutOfGradeLevel) !== -1) result = true;
        }
      }
    }
    return result;
  };

  const selectCogatLevelTabByDefaultLevel = () => {
    let elementId: string;
    testAssignmentCogat.forEach((grade: any) => {
      // Before we try to reset the tab level selection for the grade we verify the property
      // checkedLevelsNumber equals 0, this property tell us how many batteries are selected
      // inside the level tab. Resetting the tab level selection when there are batteries selected
      // can cause poblems when the setting out of level selection is enabled because, if an out of
      // level tab is selected and has batteries selected and we reset the position of the tab to the
      // default position it will show the wrong selected level to the user. The worst case for this
      // problem is when the tests already started for the grade, reseting the position will allow
      // to select and save a different level for the same grade, if the students are allowed to
      // take tests for the same grade at different levels this will cause scoring issues. If the
      // setting out of level selection is disabled this check is harmless since the only level
      // available for each grade is always the default one.
      if (!grade.checkedLevelsNumber) {
        grade.levels.forEach((level: CogatLevelType, indexLevel: number) => {
          if (level.label === defaultLevelByGrade[grade.label][seasonIndex()]) {
            elementId =
              'test-assignment-tab-grade' +
              grade.label.replace('G', '').replace('K', '0').replace(' ', '') +
              '-level' +
              indexLevel;
            document.getElementById(elementId)?.click();
          }
        });
      }
    });
  };

  const handleAutoAssignChange = (isChecked: boolean) => {
    setCheckboxAutoAssigneState(isChecked);
    let newTestAssignment: any;
    let cogatCheckedBatteriesNumber = 0;
    //clone
    if (varIsCogat) newTestAssignment = JSON.parse(JSON.stringify(testAssignmentCogat));
    else newTestAssignment = JSON.parse(JSON.stringify(testAssignmentIowaFlex));

    newTestAssignment.forEach((grade: any) => {
      if (varIsCogat) {
        grade.checkedLevelsNumber = 0;
        grade.levels.forEach((level: CogatLevelType, indexLevel: number) => {
          level.checkedBatteriesNumber = 0;
          const testingBegunBatteries = getTestingBegunBatteries();

          /* reset all checked options CogAT Complete Form 7/8 */
          level.batteries.forEach((battery: IowaFlexBatteryType) => {
            const batteryBegunTesting = !!testingBegunBatteries.find(
              (b) =>
                b.grade === grade.label.replace('G ', '') &&
                b.level === level.label.replace('L ', '') &&
                b.name === battery.text,
            );
            if (batteryBegunTesting) {
              battery.checked = true;
              level.checkedBatteriesNumber++;
              grade.checkedLevelsNumber = level.checkedBatteriesNumber;
            } else {
              battery.checked = false;
            }
          });

          /* reset all checked options CogAT Screening & Post-Screener Form 7/8 */
          if (typeof level.options !== 'undefined' && Object.keys(level.options).length) {
            for (const key in level.options) {
              const propKey = key as keyof typeof level.options;

              if (level?.options && typeof level.options[propKey] === 'object') {
                const lopt = level.options[propKey];
                if (typeof lopt !== 'undefined') {
                  const lKeys = Object.keys(lopt);
                  if (lKeys && lKeys.length && lKeys.includes('checked')) {
                    lopt.checked = false;
                  }
                }
              }
            }
          }

          if (isChecked) {
            cogatCheckedBatteriesNumber = 0;
            if (level.label === defaultLevelByGrade[grade.label][seasonIndex()]) {
              /* CogAT Complete Form 7/8 */
              level.batteries.forEach((battery: IowaFlexBatteryType) => {
                if (battery.text !== 'Alt Verbal') {
                  battery.checked = true;
                  cogatCheckedBatteriesNumber++;
                }
              });

              /* CogAT Screening & Post-Screener Form 7/8 */
              if (typeof level?.options !== 'undefined' && Object.keys(level.options).length) {
                for (const key in level.options) {
                  const propKey = key as keyof typeof level.options;

                  if (level?.options && typeof level.options[propKey] === 'object') {
                    const lopt = level.options[propKey];
                    if (typeof lopt !== 'undefined') {
                      const lKeys = Object.keys(lopt);
                      if (lKeys && lKeys.length) {
                        if (
                          key === 'screening' ||
                          (key === 'postScreener' &&
                            ((selectedEntitlement === 'CogAT Screening & Post-Screener Form 7' &&
                              isEntitledCogatPostScreenerForm7) ||
                              (selectedEntitlement === 'CogAT Screening & Post-Screener Form 8' &&
                                isEntitledCogatPostScreenerForm8)))
                        ) {
                          lopt.checked = true;
                          cogatCheckedBatteriesNumber += lopt.batteries.length;
                        } else {
                          lopt.checked = false;
                        }
                      }
                    }
                  }
                }
              }

              level.checkedBatteriesNumber = cogatCheckedBatteriesNumber;
              grade.checkedLevelsNumber = cogatCheckedBatteriesNumber;
              document
                .getElementById(
                  'test-assignment-tab-grade' +
                    grade.label.replace('G', '').replace('K', '0').replace(' ', '') +
                    '-level' +
                    indexLevel,
                )
                ?.click();
            }
          }
        });
      } else {
        /* IowaFlex */
        grade.domains.forEach((domain: any) => {
          domain.checked = isChecked;
        });
        if (isChecked) grade.checkedDomainNumber = grade.domains.length;
        else grade.checkedDomainNumber = 0;
      }
    });

    if (varIsCogat) {
      if (isChecked) setIsAnyBatteryChecked(true);
      else setIsAnyBatteryChecked(false);
      checkIsCorrectScreeningPostScreener(newTestAssignment);
      setTestAssignmentCogat(filterGrades(newTestAssignment));
    } else {
      if (isChecked) setIsAnyDomainChecked(true);
      else setIsAnyDomainChecked(false);
      setIsDomainCheckedMin3Max5(true);
      setTestAssignmentIowaFlex(filterGrades(newTestAssignment));
    }
  };

  const filterGrades = (list: any) => {
    const filteredList = list.filter((grade: any) => {
      return grade.label === 'G K'
        ? gradeList.includes('G 0')
        : gradeList.indexOf(grade.label) !== -1;
    });
    return filteredList;
  };

  const handleInputChange = (event: any) => {
    setTestAssignmentName(event.target.value);
  };

  const handleResetTestAssignment = () => {
    if (props.type === 'create') {
      handleAutoAssignChange(false);
      setTestAssignmentName('');
      if (gradeList.length) {
        document.getElementById('test-assignment-tab0')?.click();
      }
    } else {
      resetEditValuesToInitial();
    }
  };

  const handleCalendarCogatScreening = (startDate: any, endDate: any) => {
    if (startDate && endDate) {
      varEndDateCogatScreening = Math.round(moment.duration(endDate.diff(moment())).asDays());
    }
  };

  const handleCalendarCogatPostScreener = (startDate: any, endDate: any) => {
    let isEndDateChanged = false;
    if (endDate !== endDateCogatPostScreener) isEndDateChanged = true;
    if (
      startDate !== null &&
      endDate !== null &&
      endDate > startDate &&
      endDateCogatScreening !== null
    ) {
      if (
        startDate.diff(endDateCogatScreening, 'days') > 31 ||
        endDate.diff(endDateCogatScreening, 'days') > 31
      ) {
        if (isEndDateChanged) setIsPopupPostScreener30DaysVisible(true);
      }
    }
  };

  const clearNotDefaultLevelCogatBatteries = () => {
    const newTestAssignment = testAssignmentCogat.slice(); //clone
    let checkedLevelsNumber: number;
    newTestAssignment.forEach((grade: any) => {
      checkedLevelsNumber = 0;
      grade.levels.forEach((level: CogatLevelType) => {
        if (level.label !== defaultLevelByGrade[grade.label][seasonIndex()]) {
          level.checkedBatteriesNumber = 0;
          level.batteries.forEach((battery: IowaFlexBatteryType) => {
            battery.checked = false;
          });
        } else {
          if (level.checkedBatteriesNumber) checkedLevelsNumber += level.checkedBatteriesNumber;
        }
      });
      grade.checkedLevelsNumber = checkedLevelsNumber;
    });
    setTestAssignmentCogat(filterGrades(newTestAssignment));
  };

  const synchronizeSeasonDropdown = () => {
    if (varIsCogat) {
      let date: any;
      if (isDoubleCogatCalendar) date = midpointDateCogatPostScreener;
      else date = midpointDate;
      if (date) {
        const newDropdownNormativeYearOptions = dropdownNormativeYearOptions.slice(); //clone
        newDropdownNormativeYearOptions.forEach((item) => (item.selected = false));
        const month = Number(date.format('M'));
        let index = 0; //Fall
        if (month >= 12 || month <= 2) index = 1; //Mid-Year
        if (month >= 3 && month <= 7) index = 2; //Spring
        newDropdownNormativeYearOptions[index].selected = true;
        setDropdownNormativeYearOptions(newDropdownNormativeYearOptions);

        if (!isOutOfGradeLevel()) {
          clearNotDefaultLevelCogatBatteries();
          selectCogatLevelTabByDefaultLevel();
        }
        checkIsAutoAssigned();
      }
    }
  };

  const handleSaveTestAssignment = () => {
    if (isPostScreenerScheduledOutOfScreeningFormsRange) {
      setIsPostScreenerScheduledOutOfScreeningFormsRangePopupVisible(true);

      return;
    }

    setTestAssignmentDisabled(true);
    loadStudentCountByGrades(getFormDataArray());
  };

  const getFormDataArray = (): CreateAssignmentRequest[] => {
    const obj: any = {};
    let arrDomains: any = [];
    let arrBatteries: any = [];
    let tmpObj: any = {};
    const arrObjCombined: any = [];
    if (props.type === 'edit') obj.assignmentId = editAssignment.assignmentId;
    obj.assignmentName = testAssignmentName.trim().replace(/\s+/g, ' ');
    obj.impersenatorId = '';
    obj.customerId = user.customerId;
    obj.createUserId = user.userId;
    obj.createUserName = user.name;
    obj.psentitlement = 0;
    if (
      selectedEntitlement === 'CogAT Screening & Post-Screener Form 7' ||
      selectedEntitlement === 'CogAT Screening & Post-Screener Form 8'
    ) {
      obj.startDate = startDateCogatScreening;
      obj.endDate = endDateCogatScreening;
      obj.midPointDate = midpointDateCogatScreening;
      obj.season = normSeasonCogatScreening;
      obj.psMidPointDate = midpointDateCogatPostScreener;
      obj.psSeason = normSeasonCogatPostScreener;
      obj.isPsCombined = true;
      if (
        (selectedEntitlement === 'CogAT Screening & Post-Screener Form 7' &&
          isEntitledCogatPostScreenerForm7) ||
        (selectedEntitlement === 'CogAT Screening & Post-Screener Form 8' &&
          isEntitledCogatPostScreenerForm8)
      ) {
        obj.psentitlement = 1;
      }
    } else {
      obj.startDate = startDate;
      obj.endDate = endDate;
      obj.isPsCombined = false;
      if (isCogat) {
        obj.midPointDate = midpointDate;
        obj.season = normSeason;
      }
    }
    const selectedSections = stateDropdownSectionOptions.filter((item: any) => {
      return item.selected;
    });
    obj.sectionIds = Array.prototype.map
      .call(selectedSections, function (item) {
        return item.value;
      })
      .join(',');
    obj.form = dropdownTestAssignmentOptions.filter((obj) => obj.selected)[0].label;
    obj.contentId = JSON.parse(
      dropdownTestAssignmentOptions.filter((obj) => obj.selected)[0].value,
    ).content_id;
    obj.productId = JSON.parse(
      dropdownTestAssignmentOptions.filter((obj) => obj.selected)[0].value,
    ).product_id;
    obj.createRole = user.role;

    if (varIsCogat) {
      testAssignmentCogat.forEach((grade: any) => {
        if (grade.checkedLevelsNumber) {
          grade.levels.forEach((level: CogatLevelType) => {
            if (level.checkedBatteriesNumber) {
              tmpObj = Object.assign({}, obj);
              if (level.batteries.length) {
                /* CogAT Complete Form 7/8 */
                arrBatteries = [];
                level.batteries.forEach((battery: IowaFlexBatteryType) => {
                  if (battery.checked) {
                    arrBatteries.push(battery.text.replace(/[^A-Z]+/, '').replace(/[^A-Z]+$/, ''));
                  }
                });
                tmpObj.batteryDomainIds = arrBatteries.join(',');
              } else {
                /* CogAT Screening & Post-Screener Form 7/8 */
                if (level.options.screening.checked) tmpObj.batteryDomainIds = 'S';
                else if (
                  level?.options?.altScreening &&
                  Object.keys(level?.options?.altScreening).length &&
                  level?.options?.altScreening?.checked
                )
                  tmpObj.batteryDomainIds = 'AS';
                if (level.options.postScreener.checked) {
                  tmpObj.psStartDate = startDateCogatPostScreener;
                  tmpObj.psEndtDate = endDateCogatPostScreener;
                  if (
                    level.options.postScreener.batteries[0].optional === true &&
                    level.options.postScreener.batteries[0].text === 'Verbal' &&
                    level.options.postScreener.batteries[0].checked &&
                    level?.options?.altScreening &&
                    Object.keys(level.options.altScreening).length &&
                    level?.options?.altScreening?.checked
                  ) {
                    tmpObj.psBatteryDomainIds = 'PSV';
                  } else {
                    tmpObj.psBatteryDomainIds = 'PS';
                  }
                  tmpObj.psProductId = JSON.parse(
                    dropdownTestAssignmentOptions.filter((obj) => obj.selected)[0].value,
                  ).ps_product_id;
                } else if (
                  Object.keys(level.options.altPostScreener).length &&
                  level.options.altPostScreener.checked
                ) {
                  tmpObj.psStartDate = startDateCogatPostScreener;
                  tmpObj.psEndtDate = endDateCogatPostScreener;
                  tmpObj.psBatteryDomainIds = 'APS';
                  tmpObj.psProductId = JSON.parse(
                    dropdownTestAssignmentOptions.filter((obj) => obj.selected)[0].value,
                  ).ps_product_id;
                } else {
                  tmpObj.psStartDate = null;
                  tmpObj.psEndtDate = null;
                  tmpObj.psBatteryDomainIds = null;
                  tmpObj.psProductId = null;
                }
              }
              tmpObj.gradeId = grade.label.replace(' ', '');
              tmpObj.level = level.label.replace(' ', '');
              arrObjCombined.push(tmpObj);
            }
          });
        }
      });
    } else {
      testAssignmentIowaFlex.forEach((grade: any) => {
        if (grade.checkedDomainNumber) {
          arrDomains = [];
          grade.domains.forEach((domain: any) => {
            if (domain.checked) {
              arrDomains.push(domain.standard);
            }
          });
          tmpObj = Object.assign({}, obj);
          tmpObj.gradeId = grade.label.replace(' ', '');
          tmpObj.batteryDomainIds = arrDomains.join(',');
          arrObjCombined.push(tmpObj);
          tmpObj.level = '';
        }
      });
    }

    return arrObjCombined;
  };

  const loadGetSections = async () => {
    const apiUrlParams = {
      customerId: user.customerId,
      userId: user.userId,
      userRole: user.role,
    };
    logger.debug('Loading GetSections');
    try {
      cancelTokenSource = axios.CancelToken.source();
      const result = await ApiHelper.apiRequest(
        'rostering',
        'sectiondetails',
        apiUrlParams,
        {},
        cancelTokenSource,
      );
      cancelTokenSource = null;
      if (result && result.data) {
        let json = result.data;
        while (typeof json === 'string') json = JSON.parse(json);
        let sectionsObj: any = [];
        json.forEach((section: any) => {
          sectionsObj.push({
            label: section.name,
            value: section.id,
            selected: true,
          });
        });
        if (props.type === 'edit') {
          sectionsObj = sectionsObj.map((n: any) => {
            if (
              typeof editData?.sections !== 'undefined' &&
              editData.sections.indexOf(n.value) === -1
            ) {
              return {
                ...n,
                selected: false,
              };
            }
            return n;
          });
        }
        setDropdownSectionDisabled(false);
        setDropdownSectionOptions(sectionsObj);
        setStateDropdownSectionOptions(sectionsObj);
        setIsSpinnerVisible(false);
        logger.debug('Done loading GetSections');
      } else {
        logger.error('Error loading GetSections, no data returned');
        NotificationHelper.add('Error loading GetSections', 'error');
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      if (err.message !== config.api.cancelExceptionMessage) {
        logger.error(err.message);
        NotificationHelper.add('Failed loading GetSections ' + err.message, 'error');
      }
    }
  };

  const loadGetGrades = async () => {
    const apiUrlParams = {
      customer_id: user.customerId,
      user_id: user.userId,
      user_role: user.role,
    };
    logger.debug('Loading GetGrades');
    try {
      cancelTokenSource = axios.CancelToken.source();
      const result = await ApiHelper.apiRequest(
        'rostering',
        'getGradesOfUser',
        apiUrlParams,
        {},
        cancelTokenSource,
      );

      cancelTokenSource = null;
      if (result && result.data) {
        let json = result.data;
        while (typeof json === 'string') json = JSON.parse(json);
        if (json.grades === undefined) alert('Empty GetGrades response');
        json.grades = json.grades.map((grade: any) => {
          if (grade === 'Kindergarten') return 'G K';
          else return 'G ' + grade;
        });
        setGradeList(json.grades);
        logger.debug('Done loading GetGrades');
      } else {
        logger.error('Error loading GetGrades, no data returned');
        NotificationHelper.add('Error loading GetGrades', 'error');
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      if (err.message !== config.api.cancelExceptionMessage) {
        logger.error(err.message);
        NotificationHelper.add('Failed loading GetGrades ' + err.message, 'error');
      }
    }
  };

  const loadStudentCountByGrades = async (arrObj: any) => {
    const apiUrlParams = {
      customerId: user.customerId,
      grades: arrObj.map((grade: any) => grade.gradeId.replace('G', '')),
    };

    if (apiUrlParams.grades.includes('K')) {
      apiUrlParams.grades[apiUrlParams.grades.indexOf('K')] = '0';
    }

    logger.debug('Loading StudentCountByGrades');
    try {
      cancelTokenSource = axios.CancelToken.source();
      const result = await ApiHelper.apiRequest(
        'rostering',
        'studentcountbygrades',
        apiUrlParams,
        {},
        cancelTokenSource,
      );

      cancelTokenSource = null;
      if (result && result.data) {
        const json = result.data;
        let insufficientLicenseWarningText = '';
        let isWarning = false;
        let isPsWarning = false;
        let numberOfStudents: number | string = 0;
        let numberOfPsStudents: number | string = 0;
        let numberOfLicenses: number | string = 0;
        let numberOfPsLicenses: number | string = 0;
        const arrPsGrades: string[] = [];
        let assignmentForm = '';
        arrObj.forEach((grade: any) => {
          if (grade.isPsCombined && grade.psBatteryDomainIds)
            arrPsGrades.push(grade.gradeId.replace('G', ''));
          if (!assignmentForm) assignmentForm = grade.form;
        });
        json.forEach((grade: any) => {
          numberOfStudents += grade.studentCount;
          if (arrPsGrades.indexOf(grade.grade) !== -1) numberOfPsStudents += grade.studentCount;
        });
        numberOfLicenses = responseEntitlementsByCustomer.filter(
          (entitlement: any) => entitlement.product_id === selectedEntitlementType.product_id,
        )[0].license_count;
        if (typeof numberOfLicenses === 'string') numberOfLicenses = Number(numberOfLicenses);
        if (numberOfStudents > numberOfLicenses) isWarning = true;
        if (selectedEntitlementType.hasOwnProperty('ps_product_id')) {
          numberOfPsLicenses = responseEntitlementsByCustomer.filter(
            (entitlement: any) => entitlement.product_id === selectedEntitlementType.ps_product_id,
          )[0].license_count;
          if (numberOfPsStudents && numberOfPsStudents > Number(numberOfPsLicenses))
            isPsWarning = true;
        }
        if (isWarning || isPsWarning) {
          const assignmentFormNumber = assignmentForm[assignmentForm.length - 1];
          numberOfStudents = numberOfStudents.toLocaleString();
          numberOfPsStudents = numberOfPsStudents.toLocaleString();
          numberOfLicenses = numberOfLicenses.toLocaleString();
          numberOfPsLicenses = numberOfPsLicenses.toLocaleString();
          let testsAssigned = numberOfStudents + ' ' + assignmentForm;
          let licensesAvailable = numberOfLicenses + ' ' + assignmentForm;
          if (
            assignmentForm !== 'CogAT Complete Form 7' &&
            assignmentForm !== 'CogAT Complete Form 8'
          ) {
            if (isWarning && isPsWarning) {
              testsAssigned =
                numberOfStudents +
                ' CogAT Screening Form ' +
                assignmentFormNumber +
                ' tests and ' +
                numberOfPsStudents +
                ' CogAT Post-Screener Form ' +
                assignmentFormNumber;
              licensesAvailable =
                numberOfLicenses +
                ' CogAT Screening Form ' +
                assignmentFormNumber +
                ' and ' +
                numberOfPsLicenses +
                ' CogAT Post-Screener Form ' +
                assignmentFormNumber;
            } else if (isPsWarning) {
              testsAssigned =
                numberOfPsStudents + ' CogAT Post-Screener Form ' + assignmentFormNumber;
              licensesAvailable =
                numberOfPsLicenses + ' CogAT Post-Screener Form ' + assignmentFormNumber;
            } else if (
              assignmentForm === 'CogAT Screening & Post-Screener Form 7' ||
              assignmentForm === 'CogAT Screening & Post-Screener Form 8'
            ) {
              testsAssigned = numberOfStudents + ' CogAT Screening Form ' + assignmentFormNumber;
              licensesAvailable =
                numberOfLicenses + ' CogAT Screening Form ' + assignmentFormNumber;
            } else {
              testsAssigned = numberOfStudents + ' ' + assignmentForm;
              licensesAvailable = numberOfLicenses + ' ' + assignmentForm;
            }
          }
          insufficientLicenseWarningText =
            'Your test assignment has been saved. You are assigning ' +
            testsAssigned +
            ' tests and you have ' +
            licensesAvailable +
            ' licenses available. ' +
            (isPermissionNegativeLicenseNotAllowed
              ? 'You may only test as many students as licenses that you have purchased, so some students will be unable to start their tests. Please order additional licenses prior to testing.'
              : 'If you proceed with testing all currently assigned students, you will receive an invoice for any students tested that do not have licenses. Your organization can also order additional licenses prior to testing.');
        }
        loadSaveAssignment(arrObj, insufficientLicenseWarningText);
        logger.debug('Done loading StudentCountByGrades');
      } else {
        logger.error('Error loading StudentCountByGrades, no data returned');
        NotificationHelper.add('Error loading StudentCountByGrades', 'error');
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      if (err.message !== config.api.cancelExceptionMessage) {
        logger.error(err.message);
        NotificationHelper.add('Failed loading StudentCountByGrades ' + err.message, 'error');
      }
    }
  };

  const loadEntitlementsByCustomer = async () => {
    logger.debug('Loading EntitlementsByCustomer');
    try {
      const apiRequestParams = {
        customerid: user.customerId,
      };
      const result = await ApiHelper.apiRequest(
        'assignmentApiUrl',
        'EntitlementsByCustomer',
        apiRequestParams,
        {},
        cancelTokenSource,
      );
      cancelTokenSource = null;
      if (result && result.data) {
        let json = result.data;
        while (typeof json === 'string') json = JSON.parse(json);
        responseEntitlementsByCustomer = json;
        convertEntitlementsByCustomerResponse(json);
        setIsSpinnerVisible(false);
        logger.debug('Done loading EntitlementsByCustomer');
      } else {
        logger.error('Error loading EntitlementsByCustomer, no data returned');
        NotificationHelper.add('Error loading EntitlementsByCustomer', 'error');
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      if (err.message !== config.api.cancelExceptionMessage) {
        logger.error(err.message);
        NotificationHelper.add('Failed loading EntitlementsByCustomer ' + err.message, 'error');
      }
    }
  };

  const convertEntitlementsByCustomerResponse = (json: any) => {
    const newDropdownTestAssignmentOptions: IDropdownOption[] = [];
    let filterResult: any;
    let psFilterResult: any;
    let isEditType = false;
    let dropdownTestAssignmentOptionValueObject: any;
    const arrAssignmentTypes = [
      'IowaFlex Math',
      'IowaFlex Reading',
      'CogAT Complete Form 7',
      'CogAT Complete Form 8',
    ];

    arrAssignmentTypes.forEach((assignmentType) => {
      filterResult = json.filter((entitlement: any) => entitlement.description === assignmentType);
      if (filterResult.length) {
        if (props.type === 'edit' && typeof editData?.assignmenttype !== 'undefined') {
          isEditType = filterResult[0].description === editData.assignmenttype;
        }
        dropdownTestAssignmentOptionValueObject = {
          content_id: filterResult[0].content_id,
          product_id: filterResult[0].product_id,
        };
        newDropdownTestAssignmentOptions.push({
          label: assignmentType,
          value: JSON.stringify(dropdownTestAssignmentOptionValueObject),
          selected: props.type === 'edit' ? isEditType : false,
        });
      }
    });

    filterResult = json.filter(
      (entitlement: any) => entitlement.description === 'CogAT Screening Form 7',
    );
    psFilterResult = json.filter(
      (entitlement: any) => entitlement.description === 'CogAT Post-Screener Form 7',
    );
    if (filterResult.length) {
      if (props.type === 'edit' && typeof editData?.assignmenttype !== 'undefined') {
        isEditType = editData.assignmenttype === 'CogAT Screening & Post-Screener Form 7';
      }
      dropdownTestAssignmentOptionValueObject = {
        content_id: filterResult[0].content_id,
        product_id: filterResult[0].product_id,
      };
      if (psFilterResult.length) {
        dropdownTestAssignmentOptionValueObject.ps_product_id = psFilterResult[0].product_id;
        setIsEntitledCogatPostScreenerForm7(true);
      }
      newDropdownTestAssignmentOptions.push({
        label: 'CogAT Screening & Post-Screener Form 7',
        value: JSON.stringify(dropdownTestAssignmentOptionValueObject),
        selected: props.type === 'edit' ? isEditType : false,
      });
    }

    filterResult = json.filter(
      (entitlement: any) => entitlement.description === 'CogAT Screening Form 8',
    );
    psFilterResult = json.filter(
      (entitlement: any) => entitlement.description === 'CogAT Post-Screener Form 8',
    );
    if (filterResult.length) {
      if (props.type === 'edit' && typeof editData?.assignmenttype !== 'undefined') {
        isEditType = editData.assignmenttype === 'CogAT Screening & Post-Screener Form 8';
      }
      dropdownTestAssignmentOptionValueObject = {
        content_id: filterResult[0].content_id,
        product_id: filterResult[0].product_id,
      };
      if (psFilterResult.length) {
        dropdownTestAssignmentOptionValueObject.ps_product_id = psFilterResult[0].product_id;
        setIsEntitledCogatPostScreenerForm8(true);
      }
      newDropdownTestAssignmentOptions.push({
        label: 'CogAT Screening & Post-Screener Form 8',
        value: JSON.stringify(dropdownTestAssignmentOptionValueObject),
        selected: props.type === 'edit' ? isEditType : false,
      });
    }

    setDropdownTestAssignmentOptions(newDropdownTestAssignmentOptions);
  };

  const loadSaveAssignment = async (arrObj: any, insufficientLicenseWarningText: string) => {
    const apiRequestParams = arrObj;
    logger.debug('Loading SaveAssignment');
    try {
      cancelTokenSource = axios.CancelToken.source();
      const result = await ApiHelper.apiRequest(
        'assignmentApiUrl',
        'SaveAssignment',
        apiRequestParams,
        {},
        cancelTokenSource,
      );
      cancelTokenSource = null;
      if (result && result.data) {
        let json = result.data;
        while (typeof json === 'string') json = JSON.parse(json);
        if (json) {
          setTestAssignmentDisabled(false);
          history.push('/assignment/list');
          if (insufficientLicenseWarningText && props.type === 'create') {
            NotificationHelper.add(
              <>
                Insufficient License Balance
                <div className="app-notification-small-text">{insufficientLicenseWarningText}</div>
              </>,
              'warning',
            ); // 10000, GP1-11848
          }
        } else {
          setTestAssignmentDisabled(false);
          alert('Error!');
        }

        logger.debug('Done loading SaveAssignment');
      } else {
        logger.error('Error loading SaveAssignment, no data returned');
        NotificationHelper.add('Error loading SaveAssignment', 'error');
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      if (err.message !== config.api.cancelExceptionMessage) {
        logger.error(err.message);
        NotificationHelper.add('Failed loading SaveAssignment ' + err.message, 'error');
      }
    }
  };

  const loadAssignmentActiveTesting = async (assignmentId: string) => {
    logger.debug('Loading AssignmentActiveTesting');
    try {
      cancelTokenSource = axios.CancelToken.source();
      const apiRequestParams = {
        assignmentId: assignmentId,
      };
      const result = await ApiHelper.apiRequest(
        'proctoringApiUrl',
        'AssignmentActiveTesting',
        apiRequestParams,
        {},
        cancelTokenSource,
      );
      cancelTokenSource = null;

      if (result && result.data) {
        let json = result.data;
        while (typeof json === 'string') json = JSON.parse(json);
        if (json) {
          if (json.length) {
            setIsEditAssignmentCurrentlyTesting(true);
          } else {
            setIsEditAssignmentCurrentlyTesting(false);
          }
        }

        logger.debug('Done loading AssignmentActiveTesting');
      } else {
        logger.error('Error loading AssignmentActiveTesting, no data returned');
        NotificationHelper.add('Error loading AssignmentActiveTesting', 'error');
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      if (err.message !== config.api.cancelExceptionMessage) {
        logger.error(err.message);
        NotificationHelper.add('Failed loading AssignmentActiveTesting ' + err.message, 'error');
      }
    }
  };

  const getMaxBatteryCount = (
    testAssignment: typeof testAssignmentCogat,
    level: string,
  ): number => {
    let maxBatteryCount = 0;

    testAssignment.forEach((grade: any) => {
      const correspondingLevel = grade.levels.find(
        (gLevel: CogatLevelType) => gLevel.label === level,
      );
      if (correspondingLevel.checkedBatteriesNumber > maxBatteryCount) {
        maxBatteryCount = correspondingLevel.checkedBatteriesNumber;
      }
    });

    return maxBatteryCount;
  };

  const [assignments, setAssignments] = useState<CompareAssignmentItem[] | null>([]);
  const {
    config: checkForDuplicatesConfig,
    enabled: isCheckForDuplicatesEnabled,
    isReady: isCheckForDuplicatesReady,
  } = useBooleanFlagWithConfig<{ severity: NotificationSeverity }>(
    BooleanFlags.TestAssignmentPreventOverlaps,
  );
  // When editing an existing assignment, users should still be allowed to create overlapping assignments
  // and the message should be displayed as a warning.
  const editModeActive = props.type === 'edit';
  const duplicateSeverity = editModeActive ? 'warning' : checkForDuplicatesConfig?.severity;
  const newAssignmentsData = selectedEntitlement ? getFormDataArray() : null;
  const { duplicates } = useCheckForDuplicateAssignment(assignments, newAssignmentsData);
  const disabledDueToDuplicates = Boolean(
    !isCheckForDuplicatesReady ||
      (duplicateSeverity === 'error' && duplicates && duplicates.length > 0),
  );

  useEffect(() => {
    if (gradeList.length && dropdownTestAssignmentOptions.length) {
      // Once gradeList is populated, fetch assignments used for duplication check.
      if (isCheckForDuplicatesEnabled) {
        const grades = gradeList.map((g) => g.replace('G ', ''));
        const fetchData = async () => {
          const data = await fetchPopulatedAssignments(user, grades, ApiHelper);
          const editAssignmentId = editModeActive ? editAssignment.assignmentId : null;

          // Set assignments while filtering out the current assignment when in edit mode.
          setAssignments(data.filter((a) => a.assignmentId !== editAssignmentId));
        };

        fetchData().catch((error) => {
          logger.error((error as Error).message);
        });
      }

      if (dropdownTestAssignmentOptions.filter((obj) => obj.selected).length) {
        handleTestAssignmentChange([
          dropdownTestAssignmentOptions.filter((obj) => obj.selected)[0],
        ]);
      }
    }
  }, [gradeList, dropdownTestAssignmentOptions, isCheckForDuplicatesEnabled]);

  useEffect(() => {
    if (editAssignment?.mid_point_date) {
      if (isDoubleCogatCalendar) {
        setMidpointDateCogatScreening(moment(editAssignment.mid_point_date));

        if (editAssignment?.ps_mid_point_date) {
          setMidpointDateCogatPostScreener(moment(editAssignment.ps_mid_point_date));
        }
      } else if (isCogat) {
        setMidpointDate(moment(editAssignment.mid_point_date));
      }
    }
  }, [
    editAssignment.mid_point_date,
    isCogat,
    isDoubleCogatCalendar,
    editAssignment.ps_mid_point_date,
  ]);

  useEffect(() => {
    if (editAssignment?.season) {
      if (isDoubleCogatCalendar) {
        setNormSeasonCogatScreening(editAssignment.season);

        if (editAssignment?.ps_season) {
          setNormSeasonCogatPostScreener(editAssignment.ps_season);
        }
      } else if (isCogat) {
        setNormSeason(editAssignment.season);
      }
    }
  }, [editAssignment.season, isDoubleCogatCalendar, isCogat]);

  useEffect(() => {
    const setNormSeasonOnMidpointDateChange =
      !isSpinnerVisible &&
      midpointDate &&
      !isPermissionAllowNormSeasonSelection &&
      !isMidpointDateLocked;

    if (setNormSeasonOnMidpointDateChange) {
      setNormSeason(
        midpointDate.isSame(moment(editData?.mid_point_date), 'date')
          ? (editData?.season as NormSeasonType)
          : getNormSeason(midpointDate.toDate()),
      );
    }
  }, [
    isSpinnerVisible,
    midpointDate,
    isMidpointDateLocked,
    user.currentPermissions,
    editData?.mid_point_date,
    editData?.season,
  ]);

  useEffect(() => {
    const setNormSeasonOnMidpointDateChange =
      midpointDateCogatScreening &&
      !isPermissionAllowNormSeasonSelection &&
      !isMidpointDateCogatScreeningLocked;

    if (setNormSeasonOnMidpointDateChange && midpointDateCogatScreening !== null) {
      setNormSeasonCogatScreening(getNormSeason(midpointDateCogatScreening.toDate()));
    }
  }, [
    midpointDateCogatScreening,
    isMidpointDateCogatScreeningLocked,
    user.currentPermissions,
    getNormSeason,
  ]);

  useEffect(() => {
    const setNormSeasonOnMidpointDateChange =
      midpointDateCogatPostScreener &&
      !isPermissionAllowNormSeasonSelection &&
      !isMidpointDateCogatPostScreenerLocked;

    if (setNormSeasonOnMidpointDateChange && midpointDateCogatPostScreener !== null) {
      setNormSeasonCogatPostScreener(getNormSeason(midpointDateCogatPostScreener.toDate()));
    }
  }, [
    midpointDateCogatPostScreener,
    isMidpointDateCogatPostScreenerLocked,
    user.currentPermissions,
    getNormSeason,
  ]);

  useEffect(() => {
    if (normSeasonCogatScreening) {
      setNormSeasonCogatPostScreenerOptions(getAdjacentNormSeasons(normSeasonCogatScreening));

      if (!isAdjacentNormSeason(normSeasonCogatScreening, normSeasonCogatScreening)) {
        setNormSeasonCogatPostScreener(null);
      }
    } else {
      setNormSeasonCogatPostScreenerOptions(['Fall', 'Midyear', 'Spring']);
    }
  }, [normSeasonCogatScreening, getAdjacentNormSeasons, isAdjacentNormSeason]);

  useEffect(() => {
    setIsPostScreenerScheduledOutOfScreeningFormsRange(
      startDateCogatPostScreener?.isAfter(midpointDateCogatScreening?.clone().add(30, 'days')) ||
        startDateCogatPostScreener?.isBefore(midpointDateCogatScreening?.clone().add(-30, 'days')),
    );
  }, [startDateCogatPostScreener, midpointDateCogatScreening]);

  useEffect(() => {
    const isDatePickerDeselected =
      dateRangePickerFocusedInput === null && dateRangePickerFocusedInputCogatPostScreener === null;

    if (isDatePickerDeselected) {
      if (isPostScreenerScheduledOutOfScreeningFormsRange) {
        setIsPostScreenerScheduledOutOfScreeningFormsRangePopupVisible(true);
      } else {
        setIsPostScreenerScheduledOutOfScreeningFormsRangePopupVisible(false);
      }
    }
  }, [
    dateRangePickerFocusedInput,
    dateRangePickerFocusedInputCogatPostScreener,
    isPostScreenerScheduledOutOfScreeningFormsRange,
  ]);

  useEffect(() => {
    if (dateRangePickerWindowMaxLengthExceededError) {
      setDateRangePickerHelperText(
        `Test assignment window cannot exceed ${MAX_DAYS_TEST_WINDOW_DURATION_COGAT_COMPLETE} days.`,
      );
    } else {
      setDateRangePickerHelperText('');
    }
  }, [dateRangePickerWindowMaxLengthExceededError]);

  useEffect(() => {
    if (startDate && endDate) {
      if (
        Math.abs(startDate.diff(endDate, 'days')) > MAX_DAYS_TEST_WINDOW_DURATION_COGAT_COMPLETE
      ) {
        setDateRangePickerWindowMaxLengthExceededError(true);
      } else {
        setDateRangePickerWindowMaxLengthExceededError(false);
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (
      startDateCogatScreening &&
      endDateCogatScreening &&
      isCogATScreeningAndPostScreenerFormAssignmentType(selectedEntitlement)
    ) {
      if (
        Math.abs(startDateCogatScreening.diff(endDateCogatScreening, 'days')) >
        MAX_DAYS_TEST_WINDOW_DURATION_COGAT_SCREENING
      ) {
        setDateRangePickerWindowMaxLengthExceededError(true);
      } else {
        setDateRangePickerWindowMaxLengthExceededError(false);
      }
    }
  }, [
    startDateCogatScreening,
    endDateCogatScreening,
    isCogATScreeningAndPostScreenerFormAssignmentType,
    selectedEntitlement,
  ]);

  useEffect(() => {
    if (dateRangePickerCogatPostScreenerWindowMaxLengthExceededError) {
      setDateRangePickerCogatPostScreenerHelperText(
        `Test assignment window cannot exceed ${MAX_DAYS_TEST_WINDOW_DURATION_COGAT_POST_SCREENER} days.`,
      );
    } else {
      setDateRangePickerCogatPostScreenerHelperText('');
    }
  }, [dateRangePickerCogatPostScreenerWindowMaxLengthExceededError]);

  useEffect(() => {
    if (startDateCogatPostScreener && endDateCogatPostScreener) {
      if (
        Math.abs(startDateCogatPostScreener.diff(endDateCogatPostScreener, 'days')) >
        MAX_DAYS_TEST_WINDOW_DURATION_COGAT_POST_SCREENER
      ) {
        setDateRangePickerCogatPostScreenerWindowMaxLengthExceededError(true);
      } else {
        setDateRangePickerCogatPostScreenerWindowMaxLengthExceededError(false);
      }
    }
  }, [startDateCogatPostScreener, endDateCogatPostScreener]);

  useEffect(() => {
    if (lockStartDate) {
      const notificationUUID = AppHelper.getUUID();
      NotificationHelper.add(
        'Students have begun testing in this assignment.',
        'warning',
        0,
        null,
        true,
        false,
        notificationUUID,
      ); // 10000, GP1-11848

      return () => {
        dispatch({ type: 'REMOVE_APP_NOTIFICATION', payload: notificationUUID });
      };
    }
  }, [lockStartDate]);

  const renderCogatCalendar = () => {
    if (
      selectedEntitlement === 'CogAT Screening & Post-Screener Form 7' ||
      selectedEntitlement === 'CogAT Screening & Post-Screener Form 8'
    ) {
      return (
        <React.Fragment>
          <div className="date-picker-container">
            <MidpointDateRangePicker
              className="calendar-container"
              dateRangePickerProps={{
                datesLabel: 'Screening Form\nTest Dates:',
                dayPickerProps: {
                  mode: 'range',
                  disabled: (() => {
                    if (dateRangePickerFocusedInput === 'startDate') {
                      let disabledDays: Matcher | Matcher[] = [
                        {
                          before: moment()
                            .startOf('day')
                            .add(-MAX_DAYS_CREATE_TEST_ASSIGNMENT_IN_PAST, 'days')
                            .toDate(),
                        },
                        {
                          after: moment()
                            .endOf('day')
                            .add(MAX_DAYS_CREATE_TEST_ASSIGNMENT_IN_FUTURE, 'days')
                            .toDate(),
                        },
                      ];

                      if (lockStartDate) {
                        disabledDays = [
                          ...disabledDays,
                          { after: lockStartDate.endOf('day').toDate() },
                        ];
                      }

                      return disabledDays;
                    } else if (dateRangePickerFocusedInput === 'endDate') {
                      return [
                        { before: new Date() },
                        { before: startDateCogatScreening.startOf('day').toDate() },
                        {
                          after: startDateCogatScreening
                            .clone()
                            .endOf('day')
                            .add(MAX_DAYS_TEST_WINDOW_DURATION_COGAT_SCREENING, 'days')
                            .toDate(),
                        },
                      ];
                    }
                  })(),
                },
                onFocusChange: setDateRangePickerFocusedInput,
                startIcon: <CalendarIcon />,
              }}
              disabled={
                !(user.role === 'district_admin' || isPermissionCRUDAssignments) ||
                (props.type === 'edit' && editAssignment.status === 'Closed')
              }
              endDate={endDateCogatScreening}
              error={dateRangePickerWindowMaxLengthExceededError}
              helperText={dateRangePickerHelperText}
              midpointDate={midpointDateCogatScreening}
              midpointDateLocked={isMidpointDateCogatScreeningLocked}
              onDatesChange={({
                startDate: newStartDate,
                endDate: newEndDate,
                midpointDate: newMidpointDate,
              }) => {
                if (newStartDate && !newStartDate.isSame(startDateCogatScreening, 'date')) {
                  setStartDateCogatScreening(newStartDate);

                  // legacy call for possible side effects
                  handleCalendarCogatScreening(newStartDate, newEndDate);
                }

                if (newEndDate && !newEndDate.isSame(endDateCogatScreening, 'date')) {
                  setEndDateCogatScreening(newEndDate);

                  // legacy call for possible side effects
                  handleCalendarCogatScreening(newStartDate, newEndDate);
                }

                if (
                  !isMidpointDateCogatScreeningLocked &&
                  newMidpointDate &&
                  !newMidpointDate.isSame(midpointDateCogatScreening, 'date')
                ) {
                  setMidpointDateCogatScreening(newMidpointDate);
                }
              }}
              startDate={startDateCogatScreening}
            />

            <NormSeason
              className="norm-season"
              disabled={props.type === 'edit' && editAssignment.status === 'Closed'}
              onChange={setNormSeasonCogatScreening}
              season={normSeasonCogatScreening || undefined}
              variant={
                isPermissionAllowNormSeasonSelection && !isMidpointDateCogatScreeningLocked
                  ? 'dropdown'
                  : 'text'
              }
            />
          </div>
          {((selectedEntitlement === 'CogAT Screening & Post-Screener Form 7' &&
            isEntitledCogatPostScreenerForm7) ||
            (selectedEntitlement === 'CogAT Screening & Post-Screener Form 8' &&
              isEntitledCogatPostScreenerForm8)) && (
            <div className="date-picker-container">
              <MidpointDateRangePicker
                className="calendar-container"
                dateRangePickerProps={{
                  datesLabel: 'Post-Screener\nTest Dates:',
                  dayPickerProps: {
                    mode: 'range',
                    disabled: (() => {
                      if (dateRangePickerFocusedInputCogatPostScreener === 'startDate') {
                        let disabledDays: Matcher | Matcher[] = [
                          {
                            before: startDateCogatScreening
                              .clone()
                              .startOf('day')
                              .add(1, 'days')
                              .toDate(),
                          },
                        ];

                        if (midpointDateCogatScreening) {
                          disabledDays = [
                            ...disabledDays,
                            {
                              after: midpointDateCogatScreening
                                .clone()
                                .endOf('day')
                                .add(30, 'days')
                                .toDate(),
                            },
                          ];
                        }

                        if (postScreenerLockStartDate) {
                          disabledDays = [
                            ...disabledDays,
                            { after: postScreenerLockStartDate.endOf('day').toDate() },
                          ];
                        }

                        return disabledDays;
                      } else if (dateRangePickerFocusedInputCogatPostScreener === 'endDate') {
                        return [
                          { before: new Date() },
                          { before: startDateCogatPostScreener.startOf('day').toDate() },
                          {
                            after: startDateCogatPostScreener
                              .clone()
                              .endOf('day')
                              .add(60, 'days')
                              .toDate(),
                          },
                        ];
                      }
                    })(),
                  },
                  onFocusChange: setDateRangePickerFocusedInputCogatPostScreener,
                  startIcon: <CalendarIcon />,
                }}
                disabled={
                  !(user.role === 'district_admin' || isPermissionCRUDAssignments) ||
                  (props.type === 'edit' && editAssignment.status === 'Closed')
                }
                endDate={endDateCogatPostScreener}
                error={dateRangePickerCogatPostScreenerWindowMaxLengthExceededError}
                helperText={dateRangePickerCogatPostScreenerHelperText}
                midpointDate={midpointDateCogatPostScreener}
                midpointDateLocked={isMidpointDateCogatPostScreenerLocked}
                onDatesChange={({
                  startDate: newStartDate,
                  endDate: newEndDate,
                  midpointDate: newMidpointDate,
                }) => {
                  if (newStartDate && !newStartDate.isSame(startDateCogatPostScreener, 'date')) {
                    setStartDateCogatPostScreener(newStartDate);

                    // legacy call for possible side effects
                    handleCalendarCogatPostScreener(newStartDate, newEndDate);
                  }

                  if (newEndDate && !newEndDate.isSame(endDateCogatPostScreener, 'date')) {
                    setEndDateCogatPostScreener(newEndDate);

                    // legacy call for possible side effects
                    handleCalendarCogatPostScreener(newStartDate, newEndDate);
                  }

                  if (
                    !isMidpointDateCogatPostScreenerLocked &&
                    newMidpointDate &&
                    !newMidpointDate.isSame(midpointDateCogatPostScreener, 'date')
                  ) {
                    setMidpointDateCogatPostScreener(newMidpointDate);
                  }
                }}
                startDate={startDateCogatPostScreener}
              />

              <NormSeason
                className="norm-season"
                disabled={props.type === 'edit' && editAssignment.status === 'Closed'}
                onChange={setNormSeasonCogatPostScreener}
                options={normSeasonCogatPostScreenerOptions}
                season={normSeasonCogatPostScreener || undefined}
                variant={
                  isPermissionAllowNormSeasonSelection && !isMidpointDateCogatPostScreenerLocked
                    ? 'dropdown'
                    : 'text'
                }
              />
            </div>
          )}
        </React.Fragment>
      );
    }
    return (
      <div className="date-picker-container">
        <MidpointDateRangePicker
          className="calendar-container"
          dateRangePickerProps={{
            datesLabel: 'Test Dates:',
            dayPickerProps: {
              mode: 'range',
              disabled: isCogat
                ? (() => {
                    if (dateRangePickerFocusedInput === 'startDate') {
                      let disabledDays: Matcher | Matcher[] = [
                        {
                          before: moment()
                            .startOf('day')
                            .add(-MAX_DAYS_CREATE_TEST_ASSIGNMENT_IN_PAST, 'days')
                            .toDate(),
                        },
                        {
                          after: moment()
                            .endOf('day')
                            .add(MAX_DAYS_CREATE_TEST_ASSIGNMENT_IN_FUTURE, 'days')
                            .toDate(),
                        },
                      ];

                      if (lockStartDate) {
                        disabledDays = [
                          ...disabledDays,
                          { after: lockStartDate.endOf('day').toDate() },
                        ];
                      }

                      return disabledDays;
                    } else if (dateRangePickerFocusedInput === 'endDate') {
                      return [
                        { before: moment().startOf('day').toDate() },
                        { before: startDate.startOf('day').toDate() },
                        {
                          after: startDate
                            .clone()
                            .endOf('day')
                            .add(MAX_DAYS_TEST_WINDOW_DURATION_COGAT_COMPLETE, 'days')
                            .toDate(),
                        },
                      ];
                    }

                    return [];
                  })()
                : undefined,
            },
            onFocusChange: setDateRangePickerFocusedInput,
            startIcon: <CalendarIcon />,
          }}
          disabled={
            !(user.role === 'district_admin' || isPermissionCRUDAssignments) ||
            (props.type === 'edit' && editAssignment.status === 'Closed')
          }
          endDate={endDate}
          error={dateRangePickerWindowMaxLengthExceededError}
          helperText={dateRangePickerHelperText}
          midpointDate={midpointDate}
          midpointDateLocked={isMidpointDateLocked}
          onDatesChange={({
            startDate: newStartDate,
            endDate: newEndDate,
            midpointDate: newMidpointDate,
          }) => {
            if (newStartDate && !newStartDate.isSame(startDate, 'date')) {
              setStartDate(newStartDate);
            }

            if (newEndDate && !newEndDate.isSame(endDate, 'date')) {
              setEndDate(newEndDate);
            }

            if (
              !isMidpointDateLocked &&
              newMidpointDate &&
              !newMidpointDate.isSame(midpointDate, 'date')
            ) {
              setMidpointDate(newMidpointDate);
            }
          }}
          startDate={startDate}
        />

        {isCogat && (
          <NormSeason
            className="norm-season"
            disabled={props.type === 'edit' && editAssignment.status === 'Closed'}
            onChange={setNormSeason}
            season={normSeason || undefined}
            variant={
              isPermissionAllowNormSeasonSelection && !isMidpointDateLocked ? 'dropdown' : 'text'
            }
          />
        )}
      </div>
    );
  };

  const handleFilterSectionChange = (options: IDropdownOption[] | IDropdownOption) => {
    const opts: IDropdownOption[] = Array.isArray(options) ? options : [options];
    setStateDropdownSectionOptions(opts);
  };

  const getTestingBegunGrades = (): string[] => {
    const testingBegunGradeDomains = editData?.gradedomains.filter(
      (gradedomain) =>
        !gradedomain.is_nv_editable || !gradedomain.is_q_editable || !gradedomain.is_vav_editable,
    );

    const testingBegunGrades = testingBegunGradeDomains?.map(
      (gradedomain: GradeDomainType) => gradedomain.grade_id,
    );

    return testingBegunGrades || [];
  };

  const getTestingBegunBatteries = (): Battery[] => {
    const testingBegunBatteries: Battery[] = [];

    editData?.gradedomains.forEach((gradedomain) => {
      if (!gradedomain.is_nv_editable) {
        testingBegunBatteries.push({
          grade: gradedomain.grade_id,
          level: gradedomain.level.replace('Level ', ''),
          name: 'Nonverbal',
        });
      }

      if (!gradedomain.is_q_editable) {
        testingBegunBatteries.push({
          grade: gradedomain.grade_id,
          level: gradedomain.level.replace('Level ', ''),
          name: 'Quantitative',
        });
      }

      if (!gradedomain.is_vav_editable) {
        if (gradedomain.domain_id?.includes('AV')) {
          testingBegunBatteries.push({
            grade: gradedomain.grade_id,
            level: gradedomain.level.replace('Level ', ''),
            name: 'Alt Verbal',
          });
        } else {
          testingBegunBatteries.push({
            grade: gradedomain.grade_id,
            level: gradedomain.level.replace('Level ', ''),
            name: 'Verbal',
          });
        }
      }
    });

    return testingBegunBatteries;
  };

  const hasBatteryBegunTesting = (battery: Battery): boolean => {
    return !!getTestingBegunBatteries().find(
      (b) => b.grade === battery.grade && b.level === battery.level && b.name === battery.name,
    );
  };

  const hasGradeBegunTesting = (grade: string): boolean => {
    return getTestingBegunGrades().includes(grade);
  };

  const hasNonDefaultLevelTestingBegun = (): boolean => {
    const testingBegunGradeDomains = editData?.gradedomains.filter(
      (gradedomain) =>
        !gradedomain.is_nv_editable || !gradedomain.is_q_editable || !gradedomain.is_vav_editable,
    );

    if (testingBegunGradeDomains) {
      for (const gradedomain of testingBegunGradeDomains) {
        if (!isDefaultLevel(`G ${gradedomain.grade_id}`, gradedomain.level.replace('evel', ''))) {
          return true;
        }
      }
    }

    return false;
  };

  const showCloseReOpenSection =
    props.type === 'edit' &&
    isCogat &&
    editAssignment.status !== 'Scheduled' &&
    !(testingIsDisabled && editAssignment.status === 'Closed');

  return (
    <div id="site-wrapper" className="test-assignment page-wrapper">
      <div id="site-content" className="page-root">
        <div className={'test-assignment-wrapper' + (testAssignmentDisabled ? ' disabled' : '')}>
          <div className="assignment-type-section">
            {isSpinnerVisible && <h2>Loading Tests</h2>}
            {!isSpinnerVisible && (
              <h2>
                Assign tests to students based on their grade. All grades participating should be
                included in your assignment.
              </h2>
            )}

            <div className="assignment-type-section-row">
              <div className="assignment-type-dropdown-container">
                <Dropdown
                  options={dropdownTestAssignmentOptions}
                  onChange={handleTestAssignmentChange}
                  isDisabled={props.type === 'edit'}
                  icon={
                    isSpinnerVisible || dropdownTestAssignmentOptions.length === 0 ? (
                      <CircularProgress size="2em" />
                    ) : null
                  }
                  placeholder={
                    dropdownTestAssignmentOptions.length === 0
                      ? 'Please wait, loading'
                      : 'Select a test to assign'
                  }
                />

                {user.role === 'teacher' &&
                  dropdownSectionOptions.length > 0 &&
                  !isSelectSectionsDisabled && (
                    <Dropdown
                      options={dropdownSectionOptions}
                      onChange={handleFilterSectionChange}
                      placeholder="Select sections"
                      placeholderOneItemSelected="section"
                      placeholderManyItemSelected="sections"
                      isMultiselect={true}
                      hasSelectAll={true}
                      hasApplyButton={true}
                      showSelectedOptionsHeaderTooltip={true}
                      minSelected={1}
                      icon={
                        dropdownSectionOptions.length === 0 || isSpinnerVisible ? (
                          <CircularProgress size="2em" />
                        ) : null
                      }
                      isDisabled={isSelectSectionsDisabled}
                    />
                  )}
              </div>

              <div className="assignment-testing-dates-container">
                {selectedEntitlement && renderCogatCalendar()}
              </div>
            </div>
          </div>

          {selectedEntitlement && (
            <>
              <div className="test-assignment-tabs-wrapper">
                <h3>{headerText}</h3>
                <div className="tabs-test-assignment">
                  {/* Auto-assign checkbox */}
                  <Checkbox
                    checked={checkboxAutoAssigneState}
                    label="Auto-assign"
                    border={true}
                    handleChange={(isChecked: boolean) => {
                      handleAutoAssignChange(isChecked);
                    }}
                    disabled={
                      !(user.role === 'district_admin' || isPermissionCRUDAssignments) ||
                      (props.type === 'edit' && editAssignment.status === 'Closed') ||
                      hasNonDefaultLevelTestingBegun() ||
                      !!getTestingBegunBatteries().find((battery) => battery.name === 'Alt Verbal')
                    }
                  />
                  <span className="tabs-left-label">Grade</span>
                  {isCogat ? (
                    <React.Fragment>
                      {/* CogAT Grades tab headers */}
                      {testAssignmentCogat.map((grade: any, indexGrade: number) => {
                        return (
                          <React.Fragment key={'fragment' + indexGrade}>
                            <input
                              id={'test-assignment-tab' + indexGrade}
                              type="radio"
                              name="tabs"
                              defaultChecked={indexGrade === 0 ? true : false}
                            />
                            <label
                              className={
                                'cogat-tab-label' +
                                (grade.checkedLevelsNumber ? ' checked' : '') +
                                classNames({
                                  ' disabled':
                                    !(
                                      user.role === 'district_admin' || isPermissionCRUDAssignments
                                    ) ||
                                    (props.type === 'edit' && editAssignment.status === 'Closed'),
                                })
                              }
                              htmlFor={'test-assignment-tab' + indexGrade}
                              role="tab"
                              onClick={() => {
                                if (!grade.checkedLevelsNumber) selectCogatLevelTabByDefaultLevel();
                              }}
                            >
                              {grade.label.replace('G ', '')}
                              {grade.checkedLevelsNumber ? (
                                <span className="bubble-number">
                                  <b>{grade.checkedLevelsNumber}</b>
                                </span>
                              ) : (
                                ''
                              )}
                            </label>
                          </React.Fragment>
                        );
                      })}
                      {testAssignmentCogat.map((grade: any, indexGrade: number) => {
                        return (
                          <React.Fragment key={'fragment2-' + indexGrade}>
                            <section
                              id={'test-assignment-content-tab' + indexGrade}
                              className="cogat-inner-tab"
                              role="tabpanel"
                            >
                              {/* CogAT Levels tab headers */}
                              <div className="tabs-test-assignment-level">
                                <span className="tabs-left-label">Level</span>
                                {grade.levels.map((level: CogatLevelType, indexLevel: number) => {
                                  return (
                                    <React.Fragment key={'fragment3-' + indexLevel}>
                                      <input
                                        id={
                                          'test-assignment-tab-grade' +
                                          grade.label
                                            .replace('G', '')
                                            .replace('K', '0')
                                            .replace(' ', '') +
                                          '-level' +
                                          indexLevel
                                        }
                                        type="radio"
                                        name={'tabs-level-' + indexGrade}
                                        defaultChecked={indexLevel === 0 ? true : false}
                                      />
                                      <label
                                        className={classNames({
                                          checked: level.checkedBatteriesNumber,
                                          disabled:
                                            !(
                                              user.role === 'district_admin' ||
                                              isPermissionCRUDAssignments
                                            ) ||
                                            (props.type === 'edit' &&
                                              editAssignment.status === 'Closed') ||
                                            (checkboxAutoAssigneState &&
                                              !isDefaultLevel(grade.label, level.label)) ||
                                            (isPermissionEnableOutOfGradeLevelAssignments
                                              ? !isOutOfGradeLevelTestingLevel(
                                                  grade.label,
                                                  level.label,
                                                )
                                              : !isDefaultLevel(grade.label, level.label)) ||
                                            hasGradeBegunTesting(
                                              testAssignmentCogat[indexGrade].label.replace(
                                                'G ',
                                                '',
                                              ),
                                            ),
                                        })}
                                        htmlFor={
                                          'test-assignment-tab-grade' +
                                          grade.label
                                            .replace('G', '')
                                            .replace('K', '0')
                                            .replace(' ', '') +
                                          '-level' +
                                          indexLevel
                                        }
                                        onClick={(e) => {
                                          if (!isDefaultLevel(grade.label, level.label)) {
                                            setPopupOutOfGradeLevelAssignmentSelectedGrade(
                                              grade.label,
                                            );
                                            setIsPopupOutOfGradeLevelAssignmentVisible(true);
                                          }
                                        }}
                                        role="tab"
                                      >
                                        {level.label.replace('L ', '')}
                                        {level.checkedBatteriesNumber ? (
                                          <span className="bubble-number">
                                            <b>{level.checkedBatteriesNumber}</b>
                                          </span>
                                        ) : (
                                          ((maxBatteryCount: number) =>
                                            maxBatteryCount ? (
                                              <span className="bubble-number disabled">
                                                <b>{maxBatteryCount}</b>
                                              </span>
                                            ) : null)(
                                            getMaxBatteryCount(testAssignmentCogat, level.label),
                                          )
                                        )}
                                      </label>
                                    </React.Fragment>
                                  );
                                })}

                                {/* CogAT Complete Form 7/8 tab content */}
                                {testAssignmentCogat[indexGrade].levels.length > 0 &&
                                  testAssignmentCogat[indexGrade].levels[0].batteries.length > 0 &&
                                  testAssignmentCogat[indexGrade].levels.map(
                                    (level: CogatLevelType, indexLevel: number) => {
                                      return (
                                        <React.Fragment key={'fragment4-' + indexLevel}>
                                          <section
                                            id={
                                              'test-assignment-level-content-tab-grade' +
                                              grade.label
                                                .replace('G', '')
                                                .replace('K', '0')
                                                .replace(' ', '') +
                                              '-level' +
                                              indexLevel
                                            }
                                            role="tabpanel"
                                          >
                                            <h4>Battery</h4>
                                            {level.batteries.map(
                                              (
                                                battery: IowaFlexBatteryType | IIowaFlexDomainInfo,
                                                indexBattery: number,
                                              ) => {
                                                if (
                                                  ((props.type === 'create' &&
                                                    isPermissionEnableAltV) ||
                                                    (props.type === 'edit' &&
                                                      level.batteries[1].text === 'Alt Verbal' &&
                                                      (level.batteries[1].checked ||
                                                        wasAltVerbal(grade.label, level.label) ||
                                                        isPermissionEnableAltV))) &&
                                                  battery.text === 'Verbal' &&
                                                  indexBattery === 0 &&
                                                  level.batteries[1].text === 'Alt Verbal'
                                                ) {
                                                  return (
                                                    <span
                                                      className={
                                                        battery.tooltip ? 'tooltip-custom' : ''
                                                      }
                                                      key={'fragment6-' + indexBattery}
                                                    >
                                                      <Checkbox
                                                        key={'fragment5-' + indexBattery}
                                                        checked={battery.checked}
                                                        label={battery.text}
                                                        radio={true}
                                                        border={true}
                                                        handleChange={(isChecked: boolean) => {
                                                          handleBatteryChange(
                                                            isChecked,
                                                            indexGrade,
                                                            indexLevel,
                                                            indexBattery,
                                                            battery.text,
                                                          );
                                                        }}
                                                        disabled={
                                                          !(
                                                            user.role === 'district_admin' ||
                                                            isPermissionCRUDAssignments
                                                          ) ||
                                                          (props.type === 'edit' &&
                                                            editAssignment.status === 'Closed') ||
                                                          hasBatteryBegunTesting({
                                                            grade: testAssignmentCogat[
                                                              indexGrade
                                                            ].label.replace('G ', ''),
                                                            level: testAssignmentCogat[
                                                              indexGrade
                                                            ].levels[indexLevel].label.replace(
                                                              'L ',
                                                              '',
                                                            ),
                                                            name: testAssignmentCogat[indexGrade]
                                                              .levels[indexLevel].batteries[
                                                              indexBattery
                                                            ].text,
                                                          }) ||
                                                          hasBatteryBegunTesting({
                                                            grade: testAssignmentCogat[
                                                              indexGrade
                                                            ].label.replace('G ', ''),
                                                            level: testAssignmentCogat[
                                                              indexGrade
                                                            ].levels[indexLevel].label.replace(
                                                              'L ',
                                                              '',
                                                            ),
                                                            name: 'Alt Verbal',
                                                          })
                                                        }
                                                      />
                                                      <span
                                                        className="tooltiptext"
                                                        role="tooltip"
                                                        dangerouslySetInnerHTML={{
                                                          __html: battery.tooltip,
                                                        }}
                                                      ></span>
                                                    </span>
                                                  );
                                                }
                                                if (
                                                  ((props.type === 'create' &&
                                                    isPermissionEnableAltV) ||
                                                    (props.type === 'edit' &&
                                                      level.batteries[1].text === 'Alt Verbal' &&
                                                      (level.batteries[1].checked ||
                                                        wasAltVerbal(grade.label, level.label) ||
                                                        isPermissionEnableAltV))) &&
                                                  battery.text === 'Alt Verbal' &&
                                                  indexBattery === 1 &&
                                                  level.batteries[0].text === 'Verbal'
                                                ) {
                                                  return (
                                                    <React.Fragment
                                                      key={'fragment5-' + indexBattery}
                                                    >
                                                      <span className="or-divider">OR</span>
                                                      <span
                                                        className={
                                                          battery.tooltip ? 'tooltip-custom' : ''
                                                        }
                                                        key={'fragment6-' + indexBattery}
                                                      >
                                                        <Checkbox
                                                          checked={battery.checked}
                                                          label={battery.text}
                                                          radio={true}
                                                          border={true}
                                                          handleChange={(isChecked: boolean) => {
                                                            handleBatteryChange(
                                                              isChecked,
                                                              indexGrade,
                                                              indexLevel,
                                                              indexBattery,
                                                              battery.text,
                                                            );
                                                          }}
                                                          disabled={
                                                            !(
                                                              user.role === 'district_admin' ||
                                                              isPermissionCRUDAssignments
                                                            ) ||
                                                            (props.type === 'edit' &&
                                                              editAssignment.status === 'Closed') ||
                                                            hasBatteryBegunTesting({
                                                              grade: testAssignmentCogat[
                                                                indexGrade
                                                              ].label.replace('G ', ''),
                                                              level: testAssignmentCogat[
                                                                indexGrade
                                                              ].levels[indexLevel].label.replace(
                                                                'L ',
                                                                '',
                                                              ),
                                                              name: testAssignmentCogat[indexGrade]
                                                                .levels[indexLevel].batteries[
                                                                indexBattery
                                                              ].text,
                                                            }) ||
                                                            hasBatteryBegunTesting({
                                                              grade: testAssignmentCogat[
                                                                indexGrade
                                                              ].label.replace('G ', ''),
                                                              level: testAssignmentCogat[
                                                                indexGrade
                                                              ].levels[indexLevel].label.replace(
                                                                'L ',
                                                                '',
                                                              ),
                                                              name: 'Verbal',
                                                            })
                                                          }
                                                        />
                                                        <span
                                                          className="tooltiptext"
                                                          role="tooltip"
                                                          dangerouslySetInnerHTML={{
                                                            __html: battery.tooltip,
                                                          }}
                                                        ></span>
                                                      </span>
                                                      <span className="line-divider"></span>
                                                    </React.Fragment>
                                                  );
                                                }
                                                if (battery.text !== 'Alt Verbal') {
                                                  return (
                                                    <span
                                                      className={
                                                        battery.tooltip ? 'tooltip-custom' : ''
                                                      }
                                                      key={'fragment6-' + indexBattery}
                                                    >
                                                      <Checkbox
                                                        key={'fragment5-' + indexBattery}
                                                        checked={battery.checked}
                                                        label={battery.text}
                                                        border={true}
                                                        handleChange={(isChecked: boolean) => {
                                                          handleBatteryChange(
                                                            isChecked,
                                                            indexGrade,
                                                            indexLevel,
                                                            indexBattery,
                                                            '',
                                                          );
                                                        }}
                                                        disabled={
                                                          !(
                                                            user.role === 'district_admin' ||
                                                            isPermissionCRUDAssignments
                                                          ) ||
                                                          (props.type === 'edit' &&
                                                            editAssignment.status === 'Closed') ||
                                                          hasBatteryBegunTesting({
                                                            grade: testAssignmentCogat[
                                                              indexGrade
                                                            ].label.replace('G ', ''),
                                                            level: testAssignmentCogat[
                                                              indexGrade
                                                            ].levels[indexLevel].label.replace(
                                                              'L ',
                                                              '',
                                                            ),
                                                            name: testAssignmentCogat[indexGrade]
                                                              .levels[indexLevel].batteries[
                                                              indexBattery
                                                            ].text,
                                                          })
                                                        }
                                                      />
                                                      <span
                                                        className="tooltiptext"
                                                        role="tooltip"
                                                        dangerouslySetInnerHTML={{
                                                          __html: battery.tooltip,
                                                        }}
                                                      ></span>
                                                    </span>
                                                  );
                                                }
                                                return null;
                                              },
                                            )}
                                          </section>
                                        </React.Fragment>
                                      );
                                    },
                                  )}

                                {/* CogAT Screening & Post-Screener Form 7/8 tab content */}
                                {testAssignmentCogat[indexGrade].levels.length > 0 &&
                                  testAssignmentCogat[indexGrade].levels[0].batteries.length ===
                                    0 &&
                                  testAssignmentCogat[indexGrade].levels.map(
                                    (level: CogatLevelType, indexLevel: number) => {
                                      return (
                                        <React.Fragment key={'fragment4-' + indexLevel}>
                                          <section
                                            id={
                                              'test-assignment-level-content-tab-grade' +
                                              grade.label
                                                .replace('G', '')
                                                .replace('K', '0')
                                                .replace(' ', '') +
                                              '-level' +
                                              indexLevel
                                            }
                                            role="tabpanel"
                                          >
                                            <h4>Battery</h4>

                                            <span
                                              className={
                                                level?.options?.screening.tooltip
                                                  ? 'tooltip-custom'
                                                  : ''
                                              }
                                            >
                                              <Checkbox
                                                checked={
                                                  level?.options?.screening?.checked || false
                                                }
                                                label={level?.options?.screening.text}
                                                radio={
                                                  level.options?.altScreening &&
                                                  Object.keys(level.options.altScreening).length !==
                                                    0 &&
                                                  ((props.type === 'create' &&
                                                    isPermissionEnableAltV) ||
                                                    (props.type === 'edit' &&
                                                      (level.options.altScreening.checked ||
                                                        wasAltScreening(grade.label, level.label) ||
                                                        isPermissionEnableAltV)))
                                                    ? true
                                                    : false
                                                }
                                                border={true}
                                                handleChange={(isChecked: boolean) => {
                                                  handleScreeningPostScreenerTypeChange(
                                                    isChecked,
                                                    indexGrade,
                                                    indexLevel,
                                                    'screening',
                                                  );
                                                }}
                                              />
                                              <span
                                                className="tooltiptext"
                                                role="tooltip"
                                                dangerouslySetInnerHTML={{
                                                  __html: level.options.screening.tooltip,
                                                }}
                                              ></span>
                                            </span>
                                            {level.options?.altScreening &&
                                              Object.keys(level.options.altScreening).length !==
                                                0 &&
                                              ((props.type === 'create' &&
                                                isPermissionEnableAltV) ||
                                                (props.type === 'edit' &&
                                                  (level.options.altScreening.checked ||
                                                    wasAltScreening(grade.label, level.label) ||
                                                    isPermissionEnableAltV))) && (
                                                <React.Fragment>
                                                  <span className="or-divider">OR</span>
                                                  <span
                                                    className={
                                                      level.options.altScreening.tooltip
                                                        ? 'tooltip-custom'
                                                        : ''
                                                    }
                                                  >
                                                    <Checkbox
                                                      checked={level.options.altScreening.checked}
                                                      label={level.options.altScreening.text}
                                                      radio={true}
                                                      border={true}
                                                      handleChange={(isChecked: boolean) => {
                                                        handleScreeningPostScreenerTypeChange(
                                                          isChecked,
                                                          indexGrade,
                                                          indexLevel,
                                                          'altScreening',
                                                        );
                                                      }}
                                                    />
                                                    <span
                                                      className="tooltiptext"
                                                      role="tooltip"
                                                      dangerouslySetInnerHTML={{
                                                        __html: level.options.altScreening.tooltip,
                                                      }}
                                                    ></span>
                                                  </span>
                                                </React.Fragment>
                                              )}

                                            {((selectedEntitlement ===
                                              'CogAT Screening & Post-Screener Form 7' &&
                                              isEntitledCogatPostScreenerForm7) ||
                                              (selectedEntitlement ===
                                                'CogAT Screening & Post-Screener Form 8' &&
                                                isEntitledCogatPostScreenerForm8)) && (
                                              <>
                                                <span
                                                  className={
                                                    level.options.postScreener.tooltip
                                                      ? 'tooltip-custom'
                                                      : ''
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={level.options.postScreener.checked}
                                                    label={level.options.postScreener.text}
                                                    radio={
                                                      level.options?.altPostScreener &&
                                                      Object.keys(level.options.altPostScreener)
                                                        .length !== 0 &&
                                                      ((props.type === 'create' &&
                                                        isPermissionEnableAltV) ||
                                                        (props.type === 'edit' &&
                                                          (level.options.altPostScreener.checked ||
                                                            wasAltPostScreener(
                                                              grade.label,
                                                              level.label,
                                                            ) ||
                                                            isPermissionEnableAltV)))
                                                        ? true
                                                        : false
                                                    }
                                                    border={true}
                                                    handleChange={(isChecked: boolean) => {
                                                      handleScreeningPostScreenerTypeChange(
                                                        isChecked,
                                                        indexGrade,
                                                        indexLevel,
                                                        'postScreener',
                                                      );
                                                    }}
                                                  />
                                                  <span
                                                    className="tooltiptext"
                                                    role="tooltip"
                                                    dangerouslySetInnerHTML={{
                                                      __html: level.options?.altScreening?.checked
                                                        ? `${level.options.postScreener.tooltip2}`
                                                        : level.options.postScreener.tooltip,
                                                    }}
                                                  ></span>
                                                </span>
                                                {level.options?.altPostScreener &&
                                                  Object.keys(level.options.altPostScreener)
                                                    .length !== 0 &&
                                                  ((props.type === 'create' &&
                                                    isPermissionEnableAltV) ||
                                                    (props.type === 'edit' &&
                                                      (level.options?.altPostScreener?.checked ||
                                                        wasAltPostScreener(
                                                          grade.label,
                                                          level.label,
                                                        ) ||
                                                        isPermissionEnableAltV))) && (
                                                    <>
                                                      <span className="or-divider">OR</span>
                                                      <span
                                                        className={
                                                          level.options.altPostScreener.tooltip
                                                            ? 'tooltip-custom'
                                                            : ''
                                                        }
                                                      >
                                                        <Checkbox
                                                          checked={
                                                            level.options.altPostScreener.checked
                                                          }
                                                          label={level.options.altPostScreener.text}
                                                          radio={true}
                                                          border={true}
                                                          handleChange={(isChecked: boolean) => {
                                                            handleScreeningPostScreenerTypeChange(
                                                              isChecked,
                                                              indexGrade,
                                                              indexLevel,
                                                              'altPostScreener',
                                                            );
                                                          }}
                                                        />
                                                        <span
                                                          className="tooltiptext"
                                                          role="tooltip"
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              level.options.altPostScreener.tooltip,
                                                          }}
                                                        ></span>
                                                      </span>
                                                    </>
                                                  )}
                                                {level.options.postScreener.checked &&
                                                  level.options.postScreener.batteries.map(
                                                    (battery, indexBattery: number) => {
                                                      return battery.optional === true &&
                                                        level.options?.altScreening?.checked ? (
                                                        <span
                                                          className={
                                                            battery.tooltip ? 'tooltip-custom' : ''
                                                          }
                                                          key={'fragment7-' + indexBattery}
                                                        >
                                                          <Checkbox
                                                            key={'fragment5-' + indexBattery}
                                                            checked={
                                                              battery.optional === true &&
                                                              level.options?.altScreening?.checked
                                                                ? battery.checked
                                                                : true
                                                            }
                                                            label={battery.text}
                                                            border={true}
                                                            disabled={
                                                              battery.optional === true &&
                                                              level.options?.altScreening?.checked
                                                                ? false
                                                                : true
                                                            }
                                                            handleChange={(isChecked: boolean) => {
                                                              handleScreeningPostScreenerOptionalBatteryChange(
                                                                isChecked,
                                                                indexGrade,
                                                                indexLevel,
                                                                'postScreener',
                                                                'Verbal',
                                                              );
                                                            }}
                                                          />
                                                          <span
                                                            className="tooltiptext"
                                                            role="tooltip"
                                                            dangerouslySetInnerHTML={{
                                                              __html: `${battery.tooltip}`,
                                                            }}
                                                          ></span>
                                                        </span>
                                                      ) : (
                                                        ''
                                                      );
                                                    },
                                                  )}
                                              </>
                                            )}
                                          </section>
                                        </React.Fragment>
                                      );
                                    },
                                  )}
                              </div>
                            </section>
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {/* IowaFlex Grades tab headers */}
                      {testAssignmentIowaFlex.map((grade, indexGrade) => {
                        return (
                          <React.Fragment key={'fragment' + indexGrade}>
                            <input
                              id={'test-assignment-tab' + indexGrade}
                              type="radio"
                              name="tabs"
                              defaultChecked={indexGrade === 0 ? true : false}
                            />
                            <label
                              className={grade.checkedDomainNumber ? 'checked' : ''}
                              htmlFor={'test-assignment-tab' + indexGrade}
                              role="tab"
                            >
                              {grade.label.replace('G ', '')}
                              {grade.checkedDomainNumber ? (
                                <span className="bubble-number">
                                  <b>{grade.checkedDomainNumber}</b>
                                </span>
                              ) : (
                                ''
                              )}
                            </label>
                          </React.Fragment>
                        );
                      })}
                      {/* IowaFlex tab content */}
                      {testAssignmentIowaFlex.map((grade, indexGrade) => {
                        return (
                          <React.Fragment key={'fragment2-' + indexGrade}>
                            <section
                              id={'test-assignment-content-tab' + indexGrade}
                              role="tabpanel"
                            >
                              <h4>Domain</h4>
                              {grade.domains.map(
                                (domain: IIowaFlexDomainInfo, indexDomain: number) => {
                                  return (
                                    <span
                                      className={domain.tooltip ? 'tooltip-custom' : ''}
                                      key={'fragment3-' + indexDomain}
                                    >
                                      <Checkbox
                                        checked={domain.checked}
                                        label={domain.text}
                                        border={true}
                                        handleChange={(isChecked: boolean) => {
                                          handleDomainChange(isChecked, indexGrade, indexDomain);
                                        }}
                                        disabled={
                                          !(
                                            user.role === 'district_admin' ||
                                            isPermissionCRUDAssignments
                                          ) ||
                                          (props.type === 'edit' &&
                                            editAssignment.status === 'Closed')
                                        }
                                      />
                                      <span
                                        className="tooltiptext"
                                        role="tooltip"
                                        dangerouslySetInnerHTML={{
                                          __html: domain.tooltip,
                                        }}
                                      ></span>
                                    </span>
                                  );
                                },
                              )}
                            </section>
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div className="panel">
                {duplicates?.length && duplicates.length > 0 ? (
                  <DuplicateAssignmentAlert
                    sx={{ marginBottom: 2 }}
                    duplicates={duplicates}
                    severity={duplicateSeverity}
                    actionType={props.type}
                  />
                ) : null}
                <div className="left">
                  <input
                    type="text"
                    id="test-assignment-name"
                    name="test-assignment-name"
                    value={testAssignmentName}
                    maxLength={40}
                    placeholder="Test Assignment Name"
                    onChange={handleInputChange}
                    disabled={
                      !(user.role === 'district_admin' || isPermissionCRUDAssignments) ||
                      (props.type === 'edit' && editAssignment.status === 'Closed')
                    }
                  />
                </div>
                <div className="right">
                  {props.type === 'edit' && params?.assignmentId && (
                    <>
                      <button
                        className="button with-icon tooltip-custom"
                        id="button-print-test-taker-tickets"
                        disabled={props.type === 'edit' && editAssignment.status === 'Closed'}
                        name="button-print-test-taker-tickets"
                        onClick={() => {
                          history.push(`/assignment/print/${params.assignmentId}`);
                        }}
                      >
                        <IconPrint />
                        <span className="tooltiptext middle-aligned-left" role="tooltip">
                          Print Test Taker Tickets
                        </span>
                      </button>
                      <button
                        className="button"
                        id="button-delete"
                        name="button-delete"
                        onClick={() => {
                          setShowDeleteModal(true);
                        }}
                        disabled={
                          !(
                            editAssignment.is_deleteable &&
                            (user.role === 'district_admin' || isPermissionCRUDAssignments) &&
                            props.type === 'edit'
                          )
                        }
                      >
                        Move test assignment to trash
                      </button>
                    </>
                  )}
                  <button
                    className="button"
                    id="button-reset"
                    name="button-reset"
                    disabled={
                      !(user.role === 'district_admin' || isPermissionCRUDAssignments) ||
                      (props.type === 'edit' && editAssignment.status === 'Closed')
                        ? true
                        : (!isCogat && isAnyDomainChecked) ||
                            (isCogat && isAnyBatteryChecked) ||
                            testAssignmentName !== ''
                          ? false
                          : true
                    }
                    onClick={handleResetTestAssignment}
                  >
                    Reset
                  </button>
                  <button
                    className="button primary"
                    id="button-save"
                    name="button-save"
                    disabled={
                      (((!isCogat &&
                        isAnyDomainChecked &&
                        isDomainCheckedMin3Max5 &&
                        startDate !== null &&
                        endDate !== null) ||
                        (isCogat &&
                          !isDoubleCogatCalendar &&
                          isAnyBatteryChecked &&
                          startDate !== null &&
                          endDate !== null) ||
                        (isCogat &&
                          isDoubleCogatCalendar &&
                          isAnyBatteryChecked &&
                          startDateCogatScreening !== null &&
                          endDateCogatScreening !== null &&
                          startDateCogatPostScreener !== null &&
                          endDateCogatPostScreener !== null &&
                          isCorrectScreeningPostScreener)) &&
                      gradeList.length &&
                      testAssignmentName.trim() !== ''
                        ? false
                        : true) ||
                      !(user.role === 'district_admin' || isPermissionCRUDAssignments) ||
                      (props.type === 'edit' && editAssignment.status === 'Closed') ||
                      (isCogat &&
                        !isDoubleCogatCalendar &&
                        (user.role === 'district_admin' || isPermissionCRUDAssignments) &&
                        normSeason === null) ||
                      (isCogat &&
                        isDoubleCogatCalendar &&
                        (user.role === 'district_admin' || isPermissionCRUDAssignments) &&
                        (normSeasonCogatScreening === null ||
                          normSeasonCogatPostScreener === null)) ||
                      dateRangePickerWindowMaxLengthExceededError ||
                      dateRangePickerCogatPostScreenerWindowMaxLengthExceededError ||
                      isPostScreenerScheduledOutOfScreeningFormsRange ||
                      disabledDueToDuplicates
                    }
                    onClick={handleSaveTestAssignment}
                  >
                    Save
                  </button>
                </div>
              </div>
            </>
          )}

          {showCloseReOpenSection && (
            <TACloseReopenSection
              testAssignmentCRUD={user.role === 'district_admin' || isPermissionCRUDAssignments}
              testAssignmentId={editAssignment.assignmentId}
              testAssignmentStatus={editAssignment.status}
              testAssignmentStartDate={editAssignment.startDate}
              testAssignmentName={editAssignment.assignmentName}
              testAssignmentCurrentlyTesting={isEditAssignmentCurrentlyTesting}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TestAssignmentCreate;
