import React from 'react';

import TableRow from '@mui/material/TableRow';

import ElevateTableCell from './ElevateTableCell';

import { ElevateTableRowProps, ElevateTableRecord } from './ElevateTable.d';

const ElevateTableRow = <T extends ElevateTableRecord>(
  props: ElevateTableRowProps<T>,
): React.ReactElement | null => {
  const TableCell = props.tableData.options.tableComponents?.cell
    ? props.tableData.options.tableComponents?.cell
    : ElevateTableCell;

  const rowProps: {
    key: string;
    role: string;
    className: string;
    onClick?: (event: React.SyntheticEvent, item: T) => void;
  } = {
    key: `elevate_tr_${props.index}`,
    role: 'row',
    className: 'elevate-tr',
  };
  if (props.tableData.options?.onRowClick) {
    rowProps.onClick = props.tableData.options.onRowClick;
  }

  return (
    <TableRow
      key={`elevate_tr_${props.index}`}
      role="row"
      className="elevate-tr"
      onClick={(event: React.SyntheticEvent) => {
        if (props.tableData.options?.onRowClick) {
          props.tableData.options.onRowClick(event, props.item);
        }
      }}
    >
      {props.tableData.columns.map((column, columnIndex) => {
        const propKey = column.propName as keyof typeof props.item;
        return (
          <TableCell
            {...props}
            column={column}
            columnIndex={columnIndex}
            key={`row_${props.index}_col_${columnIndex}`}
          >
            {column.renderValue && column.renderValue(props.item, column)}
            {!column.renderValue && props.item[propKey]}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default ElevateTableRow;
