import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';

import { FaTrashAlt } from 'react-icons/fa';

import { TbCirclePlus, TbInfoCircle, TbTrash } from 'react-icons/tb';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

import AppHelper from '../../../../lib/helper/AppHelper';

// import useAppLogger from '../../../../hooks/useAppLogger';

import './AppDebugLogging.css';

export interface LogLevels {
  debug: boolean;
  info: boolean;
  warning: boolean;
  error: boolean;
}

const AppDebugLogging: React.FC = () => {
  const config = useConfigContext();
  // const logger = useAppLogger('COMPONENT|AppDebugLogging');

  const excludeCheckboxRef = useRef<HTMLInputElement | null>(null);
  const newNamespaceInputRef = useRef<HTMLInputElement | null>(null);

  const [addNamespaceButtonDisabled, setAddNamespaceButtonDisabled] = useState(true);

  const filterNamespaceCookie = 'elevateLogNamespaces';
  // const debugAllowedCookie = 'elevateDebugEnabled';
  const filterNamespaceExcludeFlagCookie = 'elevateLogExcludeFlag';
  const logLevelsCookie = 'elevateLogLevels';

  const [runtimeNamespaceFilters, setRuntimeNamespaceFilters] = useState<string[]>([]);
  const [runtimeNamespaceExcludeFlag, setRuntimeNamespaceExcludeFlag] = useState(false);

  const [logLevelDebug, setLogLevelDebug] = useState(false);
  const [logLevelInfo, setLogLevelInfo] = useState(false);
  const [logLevelWarning, setLogLevelWarning] = useState(false);
  const [logLevelError, setLogLevelError] = useState(false);
  const [confirmingResetToDefault, setConfirmingResetToDefault] = useState(false);

  /**
   * Gets list of namespaces to filter from config or local (runtime) cookie override
   *
   * @returns {string[]} Debug filter namespaces
   */
  const getRuntimeNamespaceFilters = (): string[] => {
    let namespaces: string[] = [];
    const namespaceJson = AppHelper.getCookieValue(filterNamespaceCookie);
    if (typeof namespaceJson === 'string' && namespaceJson.length) {
      try {
        const localNamespaces: string[] = JSON.parse(decodeURIComponent(namespaceJson)) as string[];
        namespaces = localNamespaces;
      } catch (exc: unknown) {
        namespaces = [];
      }
    }
    return namespaces;
  };

  const getRuntimeNamespaceExcludeFlag = (): boolean => {
    let result = false;
    const excludeFlagCookieValue = AppHelper.getCookieValue(filterNamespaceExcludeFlagCookie);
    if (excludeFlagCookieValue && excludeFlagCookieValue === '1') {
      result = true;
    }
    return result;
  };

  const saveRuntimeNamespaceExcludeFlag = (newExclude: boolean) => {
    const excludeValue = newExclude ? '1' : '0';
    AppHelper.setCookie(filterNamespaceExcludeFlagCookie, excludeValue, 1, true);
    refreshRuntimeNamespaceExcludeFlag();
  };

  const refreshRuntimeNamespaceExcludeFlag = () => {
    const excludeFlag = getRuntimeNamespaceExcludeFlag();
    setRuntimeNamespaceExcludeFlag(excludeFlag);
  };

  const saveRuntimeNamespaceFilters = (newNamespaces: string[]) => {
    const namespacesString = JSON.stringify(newNamespaces);
    AppHelper.setCookie(filterNamespaceCookie, namespacesString, 1, true);
    refreshRuntimeNamespaceFilters();
  };

  const clearNamespaceFilters = () => {
    saveRuntimeNamespaceFilters([]);
  };

  const refreshRuntimeNamespaceFilters = () => {
    const runtimeNamespaces = getRuntimeNamespaceFilters().map((v) => `${v}`);
    setRuntimeNamespaceFilters(runtimeNamespaces);
  };

  const removeFilterNamespace = (removeNamespace: string) => {
    const newNamespaces = runtimeNamespaceFilters.filter((v) => v !== removeNamespace);
    saveRuntimeNamespaceFilters(newNamespaces);
  };

  const getRuntimeLogLevels = (): LogLevels => {
    const logLevels: LogLevels = {
      debug: config.loggingOptions.logLevels.debug,
      info: config.loggingOptions.logLevels.info,
      warning: config.loggingOptions.logLevels.warning,
      error: config.loggingOptions.logLevels.error,
    };
    const logLevelsJson = AppHelper.getCookieValue(logLevelsCookie);
    if (typeof logLevelsJson === 'string' && logLevelsJson.length) {
      try {
        const localLogLevels = JSON.parse(decodeURIComponent(logLevelsJson));
        if (typeof localLogLevels === 'object' && localLogLevels !== null) {
          if (typeof localLogLevels.debug === 'boolean') {
            logLevels.debug = localLogLevels.debug;
          }
          if (typeof localLogLevels.info === 'boolean') {
            logLevels.info = localLogLevels.info;
          }
          if (typeof localLogLevels.warning === 'boolean') {
            logLevels.warning = localLogLevels.warning;
          }
          if (typeof localLogLevels.error === 'boolean') {
            logLevels.error = localLogLevels.error;
          }
          // hasLocalConfig = true;
        }
      } catch (exc: unknown) {
        // nothing to do here, logging doesn't work.
      }
    }
    return logLevels;
  };

  const logLevelsChanged = useMemo<boolean>(() => {
    if (logLevelDebug !== config.loggingOptions.logLevels.debug) {
      return true;
    }
    if (logLevelInfo !== config.loggingOptions.logLevels.info) {
      return true;
    }
    if (logLevelWarning !== config.loggingOptions.logLevels.warning) {
      return true;
    }
    if (logLevelError !== config.loggingOptions.logLevels.error) {
      return true;
    }
    return false;
  }, [config.loggingOptions, logLevelDebug, logLevelInfo, logLevelWarning, logLevelError]);

  const refreshRuntimeLogLevels = () => {
    const newLogLevels = getRuntimeLogLevels();
    setLogLevelDebug(newLogLevels.debug);
    setLogLevelInfo(newLogLevels.info);
    setLogLevelWarning(newLogLevels.warning);
    setLogLevelError(newLogLevels.error);
  };

  const saveRuntimeLogLevels = (newLogLevels: LogLevels) => {
    // const namespaceJson = AppHelper.getCookieValue(filterNamespaceCookie);
    const logLevelsString = JSON.stringify(newLogLevels);
    AppHelper.setCookie(logLevelsCookie, logLevelsString, 1, true);
    refreshRuntimeLogLevels();
  };

  const clearRuntimeLogLevels = () => {
    AppHelper.removeCookie(logLevelsCookie);
    refreshRuntimeLogLevels();
  };

  useEffect(() => {
    refreshRuntimeNamespaceFilters();
    refreshRuntimeNamespaceExcludeFlag();
    refreshRuntimeLogLevels();
  }, []);

  useEffect(() => {
    if (newNamespaceInputRef.current !== null) {
      validateNamespace(newNamespaceInputRef.current.value);
    }
  }, [newNamespaceInputRef.current]);

  const handleResetToDefaultClick = () => {
    if (!confirmingResetToDefault) {
      setConfirmingResetToDefault(true);
    } else {
      clearRuntimeLogLevels();
      setConfirmingResetToDefault(false);
    }
  };

  const handleAddNewNamespaceClick = () => {
    let newNamespaceValue = '';
    if (newNamespaceInputRef.current !== null) {
      newNamespaceValue = newNamespaceInputRef.current.value;
      addNewNamespaceFilter(newNamespaceValue);
      newNamespaceInputRef.current.value = '';
      setAddNamespaceButtonDisabled(true);
    }
  };

  const addNewNamespaceFilter = (newNamespaceValue: string) => {
    if (newNamespaceValue && runtimeNamespaceFilters.indexOf(newNamespaceValue) < 0) {
      const newNamespaces: typeof runtimeNamespaceFilters = runtimeNamespaceFilters
        .map((v) => `${v}`)
        .concat([newNamespaceValue]);
      saveRuntimeNamespaceFilters(newNamespaces);
    }
  };

  const handleFilterInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    validateNamespace(event.target.value);
  };

  const validateNamespace = (newValue: string) => {
    let isValid = false;
    if (newValue.length > 1) {
      isValid = true;
    }
    setAddNamespaceButtonDisabled(!isValid);
  };

  const handleFilterInputKeyUp = (event: React.SyntheticEvent<HTMLDivElement, KeyboardEvent>) => {
    let isValid = false;
    if (newNamespaceInputRef.current !== null) {
      const newValue = newNamespaceInputRef.current.value;
      if (newValue.length > 2) {
        isValid = true;
      }
      if (event.nativeEvent.key === 'Enter' && isValid) {
        addNewNamespaceFilter(newValue);
        newNamespaceInputRef.current.value = '';
        setAddNamespaceButtonDisabled(true);
      }
    }
  };

  const handleExcludeFlagChange = () => {
    saveRuntimeNamespaceExcludeFlag(!runtimeNamespaceExcludeFlag);
  };

  const handleLogLevelDebugChange = () => {
    const newValue = !logLevelDebug;
    setLogLevelDebug(newValue);
    const newLogLevels: LogLevels = {
      debug: newValue,
      info: logLevelInfo,
      warning: logLevelWarning,
      error: logLevelError,
    };
    saveRuntimeLogLevels(newLogLevels);
    refreshRuntimeLogLevels();
  };

  const handleLogLevelInfoChange = () => {
    const newValue = !logLevelInfo;
    setLogLevelInfo(newValue);
    const newLogLevels: LogLevels = {
      debug: logLevelDebug,
      info: newValue,
      warning: logLevelWarning,
      error: logLevelError,
    };
    saveRuntimeLogLevels(newLogLevels);
    refreshRuntimeLogLevels();
  };

  const handleLogLevelWarningChange = () => {
    const newValue = !logLevelWarning;
    setLogLevelWarning(newValue);
    const newLogLevels: LogLevels = {
      debug: logLevelDebug,
      info: logLevelInfo,
      warning: newValue,
      error: logLevelError,
    };
    saveRuntimeLogLevels(newLogLevels);
    refreshRuntimeLogLevels();
  };

  const handleLogLevelErrorChange = () => {
    const newValue = !logLevelError;
    setLogLevelError(newValue);
    const newLogLevels: LogLevels = {
      debug: logLevelDebug,
      info: logLevelInfo,
      warning: logLevelWarning,
      error: newValue,
    };
    saveRuntimeLogLevels(newLogLevels);
    refreshRuntimeLogLevels();
  };

  return (
    <div className="app-debug-logging-section-root">
      <div className="app-debug-drawer-subsection">
        <div className="log-filter-namespace-tools">
          <div className="app-debug-drawer-subsection-title">
            <h3 className="app-debug-drawer-subsection-title-text">Log levels</h3>
            <span></span>
          </div>
          {/* <div className="app-debug-drawer-intro">
            Namespace filters for log messages can be changed in runtime.
          </div> */}
          <div className="app-debug-drawer-subsection-body">
            <div className="app-debug-logging-section log-filter-tools">
              <FormGroup className="levels-log-form-group" row={true}>
                <FormControl variant="outlined" size="small">
                  <FormLabel>
                    Debug
                    <Switch
                      color="primary"
                      onChange={handleLogLevelDebugChange}
                      checked={logLevelDebug}
                    />
                  </FormLabel>
                </FormControl>

                <FormControl variant="outlined" size="small">
                  <FormLabel>
                    <Switch
                      color="primary"
                      onChange={handleLogLevelInfoChange}
                      checked={logLevelInfo}
                    />
                    Info
                  </FormLabel>
                </FormControl>
              </FormGroup>

              <FormGroup className="levels-log-form-group" row={true}>
                <FormControl variant="outlined" size="small">
                  <FormLabel>
                    Warning
                    <Switch
                      color="primary"
                      onChange={handleLogLevelWarningChange}
                      checked={logLevelWarning}
                    />
                  </FormLabel>
                </FormControl>

                <FormControl variant="outlined" size="small">
                  <FormLabel>
                    <Switch
                      color="primary"
                      onChange={handleLogLevelErrorChange}
                      checked={logLevelError}
                    />
                    Error
                  </FormLabel>
                </FormControl>
              </FormGroup>
              <div className="log-debug-reset-button-wrapper">
                <Button
                  color="secondary"
                  variant={confirmingResetToDefault ? 'contained' : 'outlined'}
                  disabled={!logLevelsChanged}
                  endIcon={<TbTrash />}
                  title="Reset"
                  onClick={handleResetToDefaultClick}
                >
                  {confirmingResetToDefault ? 'Confirm reset?' : 'Reset to default'}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="app-debug-drawer-subsection-title">
          <h3 className="app-debug-drawer-subsection-title-text">
            Namespace Filters {runtimeNamespaceExcludeFlag ? ' (inverted)' : ''}
          </h3>
          <span>
            <IconButton
              size="small"
              color="secondary"
              disabled={runtimeNamespaceFilters.length === 0}
              onClick={clearNamespaceFilters}
            >
              <FaTrashAlt size="0.75em" />
            </IconButton>
          </span>
        </div>
        <div className="app-debug-drawer-subsection-body">
          <div className="app-debug-logging-section log-filter-add">
            <FormGroup row={true}>
              <TextField
                label="Add filter"
                placeholder="Add filter"
                inputRef={newNamespaceInputRef}
                onKeyUp={handleFilterInputKeyUp}
                onChange={handleFilterInputChange}
                variant="outlined"
                size="small"
              />
              <Button
                color="primary"
                variant="contained"
                endIcon={<TbCirclePlus />}
                title="Add"
                disabled={addNamespaceButtonDisabled}
                onClick={handleAddNewNamespaceClick}
              >
                Add
              </Button>
            </FormGroup>
          </div>
          <div className="log-filter-namespaces-wrapper">
            <div className="log-filter-namespaces-title">Active filters</div>
            {runtimeNamespaceFilters.length === 0 && (
              <div className="log-filter-namespaces-empty">No active filters</div>
            )}
            {runtimeNamespaceFilters.length > 0 && (
              <ol className="log-filter-namespaces">
                {runtimeNamespaceFilters.map((val, index) => {
                  return (
                    <li key={`namespace_${val}_${index}`} className="log-filter-namespace-item">
                      <div className="log-filter-namespace-item-name">{val}</div>
                      <div className="log-filter-namespace-item-tools">
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() => {
                            removeFilterNamespace(val);
                          }}
                        >
                          <FaTrashAlt size="0.75em" />
                        </IconButton>
                      </div>
                    </li>
                  );
                })}
              </ol>
            )}
          </div>
          <div className="app-debug-logging-section log-filter-tools">
            <FormGroup className="exclude-log-form-group" row={true}>
              <FormControl variant="outlined" size="small">
                <FormLabel>
                  <Checkbox
                    id="logger-filter-exclude"
                    onChange={handleExcludeFlagChange}
                    checked={runtimeNamespaceExcludeFlag}
                    inputRef={excludeCheckboxRef}
                  />
                  Invert filters
                </FormLabel>
              </FormControl>
              <div className="log-exclude-info">
                <Tooltip
                  className="elevate-tooltip"
                  arrow={true}
                  placement="top"
                  title={
                    <span className="exclude-log-tooltip-text">
                      <p>
                        You can provide full or partial value like &apos;HOOK&apos; or
                        &apos;COMPONENT|AppRoot&apos; that you want to filter by.
                      </p>
                      <p>
                        {runtimeNamespaceFilters.length > 0 &&
                          `Messages with matching namespaces will ${
                            runtimeNamespaceExcludeFlag
                              ? 'be excluded from logs.'
                              : 'be the only ones logged.'
                          }`}
                      </p>
                    </span>
                  }
                >
                  <span className="exclude-log-tooltip-icon">
                    <TbInfoCircle />
                  </span>
                </Tooltip>
              </div>
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDebugLogging;
