import React, { useState, useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { TbExternalLink, TbRefresh, TbTrash } from 'react-icons/tb';

import Button from '@mui/material/Button';

import { AppState } from '../../../../reducers/appDataTypes';
// import { StaffAuthUser } from '../../../../reducers/Auth/authDataTypes';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import UserHelper from '../../../../lib/helper/UserHelper';
import useAppLogger from '../../../../hooks/useAppLogger';

import AppDebugUserModal from './AppDebugSessionModals/AppDebugUserModal';
import AppDebugCookieUserModal from './AppDebugSessionModals/AppDebugCookieUserModal';
import AppDebugIdTokenModal from './AppDebugSessionModals/AppDebugIdTokenModal';

import AppDebugDrawerSection from '../AppDebugDrawerSection';

import { formatDuration } from './AppDebugSession.util';

import './AppDebugSession.css';

export interface AppDebugSessionProps {
  sessionMinimized: boolean;
  toggleSessionMinimized: () => void;
}

const AppDebugSession: React.FC<AppDebugSessionProps> = (props) => {
  const config = useConfigContext();
  const logger = useAppLogger('Component|AppDebugSession');
  // const NotificationHelper = useNotificationHelper();

  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => {
    return state.auth.staffAuth.user;
  });

  const cognitoUserSession = useSelector((state: AppState) => {
    return state.auth.staffAuth.cognito.cognitoUserSession;
  });

  const [sessionExpiresIn, setSessionExpiresIn] = useState<number>(0);
  const [userJsonModalVisible, setUserJsonModalVisible] = useState(false);
  const [idTokenModalVisible, setIdTokenModalVisible] = useState(false);
  const [cookieUserDataModalVisible, setCookieUserDataModalVisible] = useState(false);

  const sessionExpireUpdateInterval = useRef<null | ReturnType<typeof window.setInterval>>(null);

  const [cookieChunkSizes, setCookieChunkSizes] = useState<number[]>([]);
  const [totalCookieSize, setTotalCookieSize] = useState(0);
  // const [cookieUserData, setCookieUserData] = useState<StaffAuthUser | null>(null);
  const [cookieUserJson, setCookieUserJson] = useState('');

  const handleViewCookieCacheClick = () => {
    setCookieUserDataModalVisible(true);
  };
  const handleInputClick = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const el = event.target as HTMLInputElement;
    if (el) {
      el.selectionStart = 0;
      el.selectionEnd = el.value.length;
      // el.focus();
    }
  };

  const handleClearCookieCacheClick = () => {
    UserHelper.clearCookieUser(config);
    initCookieUserData();
  };

  const initCookieUserData = () => {
    const cookieName = 'elevateUser';
    const cookieChunkLength = +UserHelper.getCookieValue(`${cookieName}Chunks`);
    const sizes: number[] = [];
    let userCookieData = '';
    if (!isNaN(cookieChunkLength) && isFinite(cookieChunkLength)) {
      for (let i = 0; i < cookieChunkLength; i++) {
        const chunk = UserHelper.getCookieValue(`${cookieName}Chunk_${i}`);
        userCookieData += chunk;
        sizes.push(chunk.length);
      }
    }
    setCookieChunkSizes(sizes);
    setTotalCookieSize(
      sizes.reduce((acc, val) => {
        acc += val;
        return acc;
      }, 0),
    );
    if (userCookieData) {
      setCookieUserJson(UserHelper.unpackUserJson(userCookieData));
      // setCookieUserData(UserHelper.unpackUser(userCookieData));
    } else {
      setCookieUserJson('');
      // setCookieUserData(null);
    }
  };

  const updateSessionTimer = () => {
    if (cognitoUserSession !== null) {
      const expires = cognitoUserSession.getIdToken().getExpiration() * 1000;
      const currentTimestamp = new Date().getTime();
      const diff = Math.floor((expires - currentTimestamp) / 1000);
      setSessionExpiresIn(diff);
    }
  };

  const clearExpireInterval = () => {
    if (sessionExpireUpdateInterval.current !== null) {
      clearTimeout(sessionExpireUpdateInterval.current);
      sessionExpireUpdateInterval.current = null;
    }
  };

  useEffect(() => {
    initCookieUserData();
    // console.log(UserHelper);
  }, []);
  useEffect(() => {
    if (
      cognitoUserSession !== null &&
      cognitoUserSession.isValid() &&
      sessionExpireUpdateInterval.current === null
    ) {
      sessionExpireUpdateInterval.current = setInterval(() => {
        updateSessionTimer();
      }, 1000);
    }
    // logger.debug('Mounting component');
    return () => {
      clearExpireInterval();
      logger.debug('Unmounting component');
    };
  }, [cognitoUserSession]);

  const handleRefreshTokenClick = () => {
    refreshToken();
  };
  const refreshToken = async () => {
    try {
      await UserHelper.refreshStaffUser(config, user, dispatch, true);
      const fullUser = await UserHelper.loadUser(config, user, dispatch, true, true);
      fullUser.loggedIn = true;
      fullUser.loadComplete = true;
      UserHelper.updateUser(config, fullUser, dispatch);
      initCookieUserData();
      logger.debug('User session refreshed successfully.');
    } catch (ex: unknown) {
      const err = ex as Error;
      logger.error(`Failed refreshing user session - ${err.message}`);
    }
  };

  const showUserJsonModal = () => {
    setUserJsonModalVisible(true);
  };

  const hideUserJsonModal = () => {
    setUserJsonModalVisible(false);
  };

  const displayIdTokenModal = () => {
    setIdTokenModalVisible(true);
  };

  const hideIdTokenModal = () => {
    setIdTokenModalVisible(false);
  };

  const hideCookieUserDataModal = () => {
    setCookieUserDataModalVisible(false);
  };

  return (
    <AppDebugDrawerSection
      minimized={props.sessionMinimized}
      className="app-debug-section-session"
      toggleMinimized={props.toggleSessionMinimized}
      title="Session"
      sectionHeader={
        <div className="app-debug-drawer-section-title session-drawer-section-title">
          <span className="app-debug-drawer-section-title-left">
            {!user.loggedIn || !props.sessionMinimized
              ? ''
              : `${sessionExpiresIn < 0 ? '-' : ''}${formatDuration(Math.abs(sessionExpiresIn))}`}
          </span>
          <span
            className="app-debug-drawer-section-title-text"
            onClick={props.toggleSessionMinimized}
          >
            Session {user.ssoUser ? ' (SSO)' : ''}
          </span>
          <span className="app-debug-drawer-section-title-right">
            <button
              onClick={props.toggleSessionMinimized}
              className="link-button app-debug-drawer-section-window-control"
              title={props.sessionMinimized ? 'Show section' : 'Hide section'}
            >
              {props.sessionMinimized ? '↧' : '↥'}
            </button>
          </span>
        </div>
      }
    >
      <AppDebugUserModal modalVisible={userJsonModalVisible} hideModal={hideUserJsonModal} />
      <AppDebugIdTokenModal modalVisible={idTokenModalVisible} hideModal={hideIdTokenModal} />
      <AppDebugCookieUserModal
        modalVisible={cookieUserDataModalVisible}
        hideModal={hideCookieUserDataModal}
      />
      {!user.loggedIn && <p className="app-debug-section-unavailable">User is not logged in.</p>}
      {user.loggedIn && (
        <React.Fragment>
          <div className="app-debug-drawer-subsection">
            <div className="app-debug-drawer-subsection-title">
              <h3 className="app-debug-drawer-subsection-title-text">User info</h3>
              <span></span>
            </div>
            <div>
              <div className="session-info-grid-table">
                <div className="session-info-grid-cell session-info-grid-cell-heading">Field</div>
                <div className="session-info-grid-cell session-info-grid-cell-heading">Value</div>
                <div className="session-info-grid-cell">Email</div>
                <div className="session-info-grid-cell">
                  <input
                    type="text"
                    className="app-debug-session-ghost-input"
                    readOnly={true}
                    onClick={handleInputClick}
                    value={user.email}
                  />
                </div>

                <div className="session-info-grid-cell">Customer ID</div>
                <div className="session-info-grid-cell">
                  <input
                    type="text"
                    className="app-debug-session-ghost-input"
                    readOnly={true}
                    onClick={handleInputClick}
                    value={user.customerId}
                  />
                </div>

                <div className="session-info-grid-cell">Proctor Code</div>
                <div className="session-info-grid-cell">
                  <input
                    type="text"
                    className="app-debug-session-ghost-input"
                    readOnly={true}
                    onClick={handleInputClick}
                    value={user.currentProctorCode}
                  />
                </div>

                <div className="session-info-grid-cell">User ID</div>
                <div className="session-info-grid-cell">
                  <input
                    type="text"
                    className="app-debug-session-ghost-input"
                    readOnly={true}
                    onClick={handleInputClick}
                    value={user.userId}
                  />
                </div>

                <div className="session-info-grid-cell">District ID</div>
                <div className="session-info-grid-cell">
                  <input
                    type="text"
                    className="app-debug-session-ghost-input"
                    readOnly={true}
                    onClick={handleInputClick}
                    value={user.districtId}
                  />
                </div>

                <div className="session-info-grid-cell">Current role</div>
                <div className="session-info-grid-cell">
                  <input
                    type="text"
                    className="app-debug-session-ghost-input"
                    readOnly={true}
                    onClick={handleInputClick}
                    value={user.role}
                  />
                </div>
              </div>
              <div className="app-debug-session-buttons-row">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={!(user.loggedIn && user.loadComplete)}
                  onClick={showUserJsonModal}
                  endIcon={<TbExternalLink />}
                >
                  View user data
                </Button>
              </div>
            </div>
          </div>

          <div className="app-debug-drawer-subsection">
            <div className="app-debug-drawer-subsection-title">
              <h3 className="app-debug-drawer-subsection-title-text">Amazon Cognito</h3>
              <span></span>
            </div>

            <div className="session-info-block">
              <div className="session-main-info">
                <span>Session type:</span>
                <strong>{user.ssoUser ? 'SSO' : 'Elevate'}</strong>
                <span>Session cache:</span>
                <strong>{config.session.cacheType}</strong>
                {sessionExpiresIn >= 0 && (
                  <React.Fragment>
                    <span>Valid for</span>
                    <strong>{formatDuration(sessionExpiresIn)}</strong>
                  </React.Fragment>
                )}
                {sessionExpiresIn < 0 && (
                  <React.Fragment>
                    <span className="token-invalid-label">Invalid for</span>
                    <strong>{formatDuration(Math.abs(sessionExpiresIn))}</strong>
                  </React.Fragment>
                )}
              </div>
            </div>

            <div
              className={`idtoken-expire-ticker${sessionExpiresIn < 0 ? ' idtoken-expired' : ''}`}
            >
              {sessionExpiresIn >= 0 && (
                <div className="idtoken-expire-ticker-text">
                  idToken expires in <span>{formatDuration(sessionExpiresIn)}</span>.
                </div>
              )}
              {sessionExpiresIn < 0 && (
                <div className="idtoken-expire-ticker-text idtoken-expired-text">
                  idToken expired <span>{formatDuration(Math.abs(sessionExpiresIn))}</span> ago.
                </div>
              )}
              <div className="app-debug-session-buttons-row">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={displayIdTokenModal}
                  endIcon={<TbExternalLink />}
                >
                  View
                </Button>

                <Button
                  variant={sessionExpiresIn < 0 ? 'contained' : 'outlined'}
                  color="primary"
                  size="small"
                  disabled={!user.loggedIn}
                  endIcon={<TbRefresh />}
                  onClick={handleRefreshTokenClick}
                >
                  Refresh
                </Button>
              </div>
            </div>
          </div>

          {config.session.staff.storeInCookies && (
            <div className="app-debug-drawer-subsection">
              <div className="app-debug-drawer-subsection-title">
                <h3 className="app-debug-drawer-subsection-title-text">Cookie cache</h3>
                <span></span>
              </div>
              <div className="idtoken-cookie-info">
                <div className="idtoken-cookie-info-count">
                  <strong>{cookieChunkSizes.length}</strong> cookie chunk(s) cached.
                </div>
                <div className="idtoken-cookie-info-sizes">
                  <div className="idtoken-cookie-info-sizes-table">
                    <div className="idtoken-cookie-info-sizes-cell idtoken-cookie-info-sizes-cell-heading">
                      Cookie total size
                    </div>
                    <div className="idtoken-cookie-info-sizes-cell idtoken-cookie-info-sizes-cell-heading">
                      B
                    </div>
                    <div className="idtoken-cookie-info-sizes-cell">Compressed</div>
                    <div className="idtoken-cookie-info-sizes-cell">
                      <span>{totalCookieSize}</span>
                    </div>
                    <div className="idtoken-cookie-info-sizes-cell">Uncompressed</div>
                    <div className="idtoken-cookie-info-sizes-cell">
                      <span>{cookieUserJson.length}</span>
                    </div>
                    <div className="idtoken-cookie-info-sizes-cell">Saved</div>
                    <div className="idtoken-cookie-info-sizes-cell">
                      <span>{cookieUserJson.length - totalCookieSize}</span>
                    </div>
                  </div>
                  <div className="app-debug-session-buttons-row">
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handleViewCookieCacheClick}
                      endIcon={<TbExternalLink />}
                    >
                      View
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={handleClearCookieCacheClick}
                      endIcon={<TbTrash />}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </AppDebugDrawerSection>
  );
};
export default AppDebugSession;
