import notificationsReducer from './notificationsReducer';
import studentListReducer from './studentListReducer';
import buttonMuteReducer from './buttonMuteReducer';
import buttonSortingOptionsReducer from './buttonSortingOptionsReducer';
import buttonAnalyticsReducer from './buttonAnalyticsReducer';
import notesPanelReducer from './notesPanelReducer';
import chatPanelReducer from './chatPanelReducer';
import popupDenyReasonsReducer from './popupDenyReasonsReducer';
import headerContentReducer from './headerContentReducer';
import { combineReducers } from 'redux';

const proctoringReducer = combineReducers({
  notifications: notificationsReducer,
  buttonMute: buttonMuteReducer,
  buttonSortingOptions: buttonSortingOptionsReducer,
  buttonAnalytics: buttonAnalyticsReducer,
  notesPanel: notesPanelReducer,
  chatPanel: chatPanelReducer,
  students: studentListReducer,
  popupDenyReasons: popupDenyReasonsReducer,
  headerContent: headerContentReducer,
});

export default proctoringReducer;
