import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ReactComponent as DropdownArrowDown } from './dropdownIcons/dropdown-arrow-down.svg';
import { ReactComponent as IconCheck } from './dropdownIcons/check.svg';
import './Dropdown.scss';

export interface IDropdownOption {
  label: string;
  value: any;
  selected: boolean;
  disabled?: boolean;
}

export interface IDropdownProps {
  options: IDropdownOption[];
  isMultiselect?: boolean;
  hasSelectAll?: boolean;
  hasApplyButton?: boolean;
  isOpen?: boolean;
  icon?: React.ReactNode | null;
  isPlaceholderStatic?: boolean;
  placeholder?: string;
  placeholderOneItemSelected?: string;
  placeholderManyItemSelected?: string;
  cssClass?: string;
  isDisabled?: boolean;
  ResetDropdownAllSelectedOptions?: number;
  onChange?: (option: IDropdownOption[]) => void;
  minSelected?: number;
  maxSelected?: number;
  showSelectedOptionsHeaderTooltip?: boolean;
  classSelectedOptionsHeaderTooltip?: string;
}

const Dropdown = (props: IDropdownProps) => {
  const {
    options,
    isMultiselect,
    hasSelectAll,
    hasApplyButton,
    isOpen,
    isPlaceholderStatic,
    placeholder,
    placeholderOneItemSelected,
    placeholderManyItemSelected,
    cssClass,
    isDisabled,
    ResetDropdownAllSelectedOptions,
    onChange,
    minSelected,
    maxSelected,
    showSelectedOptionsHeaderTooltip,
    classSelectedOptionsHeaderTooltip,
  } = props;

  //const [stateOptions, setStateOptions] = useState<IDropdownOption[]>([]);
  const [stateOptions, setStateOptions] = useState(options);
  const [isExpanded, setIsExpanded] = useState(false);
  const [numOptionsSelected, setNumOptionsSelected] = useState(0);
  const [headerSelectedOptionsLabel, setHeaderSelectedOptionsLabel] = useState('');
  const [selectedMultiOptions, setSelectedMultiOptions] = useState<IDropdownOption[]>([]);
  const [selectedMultiTooltipHeader, setSelectedMultiTooltipHeader] = useState('');
  const [dropdownWidth, setDropdownWidth] = useState('');
  let maxOptionCharNum = 0;
  const myRef = useRef<HTMLInputElement>(null);
  let dropdownCssWidth = '';

  const [optionsBeforeExpand, _setOptionsBeforeExpand] = useState<IDropdownOption[]>([]);
  const myStateRef = React.useRef(optionsBeforeExpand);
  const setOptionsBeforeExpand = (data: IDropdownOption[]) => {
    myStateRef.current = data;
    _setOptionsBeforeExpand(data);
  };

  useEffect(() => {
    //setHeaderSelectedOptionsLabel("");
  }, [ResetDropdownAllSelectedOptions]);

  useEffect(() => {
    isOpen && setIsExpanded(isOpen);
    document.addEventListener('mousedown', handleClickOutside);
    setNumOptionsSelected(checkNumSelected(stateOptions));
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setStateOptions(options);
    setSelectedMultiOptions(options.filter((obj) => obj.selected));
    refreshHeaderCaptionAndHeaderTooltip(options);
    countCssWidth();
  }, [options]);

  useEffect(() => {
    isOpen && setIsExpanded(isOpen);
    if (!isExpanded) {
      setOptionsBeforeExpand(JSON.parse(JSON.stringify(stateOptions)));
    }
  }, [isOpen]);

  const countCssWidth = () => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].label.length > maxOptionCharNum) {
        maxOptionCharNum = options[i].label.length;
      }
    }
    dropdownCssWidth = Math.floor(Number(maxOptionCharNum) * 7) + 42 + 'px';
    setDropdownWidth(dropdownCssWidth);
  };

  const refreshHeaderCaptionAndHeaderTooltip = (stateOptions: IDropdownOption[]) => {
    const arrSelectedOptions: IDropdownOption[] = [];
    let isMultiCaptionAssigned = false;
    let headerLabel = '';
    let headerTooltip = '';
    const countSelected = stateOptions.filter((obj) => obj.selected).length;

    for (let i = 0; i < stateOptions.length; i++) {
      if (isMultiselect) {
        if (stateOptions[i].selected) {
          arrSelectedOptions.push(stateOptions[i]);
          if (!isMultiCaptionAssigned) {
            isMultiCaptionAssigned = true;
            if (placeholderOneItemSelected && placeholderManyItemSelected)
              headerLabel =
                countSelected +
                ' ' +
                (countSelected === 1 ? placeholderOneItemSelected : placeholderManyItemSelected) +
                ' selected';
            else headerLabel = stateOptions[i].label;
          } else {
            headerTooltip += '<br />';
          }
          headerTooltip += stateOptions[i].label;
        }
      } else {
        if (stateOptions[i].selected) {
          headerLabel = stateOptions[i].label;
          break;
        }
      }
      setNumOptionsSelected(checkNumSelected(stateOptions));
    }

    if (placeholder && isPlaceholderStatic) {
      headerLabel = placeholder + headerLabel;
    }
    setHeaderSelectedOptionsLabel(headerLabel);

    if (isMultiselect) {
      setHeaderSelectedOptionsLabel(headerTooltip.replaceAll('<br />', ', '));
      setSelectedMultiOptions(arrSelectedOptions);
      setSelectedMultiTooltipHeader(headerTooltip);
    }
  };

  const toggling = () => {
    if (!isExpanded) {
      setOptionsBeforeExpand(JSON.parse(JSON.stringify(stateOptions)));
    }
    setIsExpanded(!isExpanded);
  };

  const onOptionClicked = (option: IDropdownOption) => {
    const countSelected = stateOptions.filter((obj) => obj.selected).length;
    if (isMultiselect) {
      if (
        (option.selected === true &&
          (hasApplyButton ||
            minSelected === undefined ||
            (minSelected !== undefined && countSelected > minSelected))) ||
        (option.selected === false &&
          (hasApplyButton ||
            maxSelected === undefined ||
            (maxSelected !== undefined && countSelected < maxSelected)))
      ) {
        for (let i = 0; i < stateOptions.length; i++) {
          if (stateOptions[i].value === option.value) {
            stateOptions[i].selected = !stateOptions[i].selected;
            break;
          }
        }
        onChange && !hasApplyButton && onChange(stateOptions);
      }
    } else {
      setIsExpanded(false);
      for (let i = 0; i < stateOptions.length; i++) {
        if (stateOptions[i].value === option.value) {
          if (option.selected === true && minSelected === 0) stateOptions[i].selected = false;
          else stateOptions[i].selected = true;
          onChange && !hasApplyButton && onChange([stateOptions[i]]);
        } else stateOptions[i].selected = false;
      }
      //onChange && !hasApplyButton && onChange(option);
    }
    refreshHeaderCaptionAndHeaderTooltip(stateOptions);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (!myRef.current?.contains(e.target as HTMLElement)) {
      setIsExpanded(false);
      if (hasApplyButton && myStateRef.current.length) {
        //console.log(optionsBeforeExpand);
        //console.log(myStateRef.current);
        //setStateOptions(optionsBeforeExpand);
        setStateOptions(myStateRef.current);
        refreshHeaderCaptionAndHeaderTooltip(myStateRef.current);
        setNumOptionsSelected(checkNumSelected(myStateRef.current));
      }
    }
  };

  const checkNumSelected = (options: IDropdownOption[]) => {
    let numSelected = 0;
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) numSelected++;
    }
    return numSelected;
  };

  const handleSelectAll = (e: React.SyntheticEvent) => {
    const isAllSelected = checkNumSelected(stateOptions) === stateOptions.length;
    for (let i = 0; i < stateOptions.length; i++) {
      stateOptions[i].selected = !isAllSelected;
    }
    onChange && onChange(stateOptions);
    refreshHeaderCaptionAndHeaderTooltip(stateOptions);
  };

  const handleApplyButton = (e: React.SyntheticEvent) => {
    setIsExpanded(false);
    setOptionsBeforeExpand(JSON.parse(JSON.stringify(stateOptions)));
    onChange && onChange(stateOptions);
  };

  const wrapperClassName = useMemo<string>(() => {
    return `dropdown-container-custom${cssClass ? ' ' + cssClass : ''}${
      isDisabled ? ' disabled' : ''
    }${
      showSelectedOptionsHeaderTooltip &&
      isMultiselect &&
      !isExpanded &&
      selectedMultiOptions.length >= 1
        ? ' tooltip-custom'
        : ''
    }`;
  }, [
    cssClass,
    isDisabled,
    showSelectedOptionsHeaderTooltip,
    isMultiselect,
    isExpanded,
    selectedMultiOptions.length,
  ]);

  return (
    <div className={wrapperClassName} ref={myRef}>
      {showSelectedOptionsHeaderTooltip &&
      isMultiselect &&
      !isExpanded &&
      selectedMultiOptions.length >= 1 ? (
        <span
          className={
            'tooltiptext ' +
            (classSelectedOptionsHeaderTooltip ? classSelectedOptionsHeaderTooltip : '')
          }
          role="tooltip"
          dangerouslySetInnerHTML={{ __html: selectedMultiTooltipHeader }}
        ></span>
      ) : (
        ''
      )}
      <div
        className={'dropdown-header-custom' + (isExpanded ? ' expanded' : '')}
        onClick={toggling}
        style={{ minWidth: dropdownWidth }}
      >
        {headerSelectedOptionsLabel && (
          <span className="option-label placeholder">{headerSelectedOptionsLabel}</span>
        )}
        {!headerSelectedOptionsLabel && (
          <span className="option-label placeholder">
            {placeholder ? placeholder : 'Select option'}
          </span>
        )}
        {props?.icon && <span className="dropdown-header-custom-icon">{props.icon}</span>}
        {!props?.icon && (
          <span className="dropdown-header-custom-icon">
            <DropdownArrowDown />
          </span>
        )}
      </div>
      {isExpanded && (
        <div className="dropdown-list-container-custom">
          <ul>
            {isMultiselect && hasSelectAll && (
              <li className="select-all" role="button" onClick={handleSelectAll}>
                Select all {numOptionsSelected === stateOptions.length && <IconCheck />}
              </li>
            )}
            {stateOptions.map((option: IDropdownOption, index: number) => (
              <li
                className={hasApplyButton && index === stateOptions.length - 1 ? 'last' : ''}
                onClick={(e) => onOptionClicked(option)}
                key={Math.random()}
              >
                {option.label}
                {option.selected ? <IconCheck /> : ''}
              </li>
            ))}
          </ul>
          {hasApplyButton && (
            <div className="buttons-container">
              {minSelected && minSelected > numOptionsSelected && (
                <div className="messages">
                  Minimum of {minSelected}{' '}
                  {placeholderOneItemSelected && placeholderManyItemSelected
                    ? minSelected > 1
                      ? placeholderManyItemSelected
                      : placeholderOneItemSelected
                    : 'option' + (minSelected > 1 ? 's' : '')}{' '}
                  required
                </div>
              )}
              {hasApplyButton && (
                <div className="buttons">
                  <button
                    className="button primary"
                    disabled={
                      (minSelected !== undefined && numOptionsSelected < minSelected) ||
                      (maxSelected !== undefined && numOptionsSelected > maxSelected)
                    }
                    onClick={handleApplyButton}
                  >
                    Select
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
