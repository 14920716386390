import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers/appDataTypes';
import { useEffect, useMemo, useRef, useState } from 'react';
import useAppLogger from '../useAppLogger';
import * as Ably from 'ably';
import { StudentAuthUser } from '../../reducers/Auth/authDataTypes';
import useIdentify from '../useIdentify';
import {
  ablyProctorIdentifierForStudent,
  ablyStudentIdentifier,
  isPresentAblyEvent,
} from '../../lib/util/ably.util';
import { AblyMessageWithPayload } from '../../types/proctoring.types';

export type StudentChannelInterface = {
  sendCommandToStudentChannel: (message: AblyMessageWithPayload<any>) => void;
  subscribed: boolean;
  ready: boolean;
};

export type StudentChannelOptions = {
  init?: boolean;
  namespace: string;
  onCommand?: (message: any) => void;
};

const useStudentChannel = (options: StudentChannelOptions): StudentChannelInterface => {
  const instanceId = useRef(useIdentify(5, 'com_'));

  const logger = useAppLogger(`HOOK|useStudentChannel-${options.namespace}-${instanceId.current}`);
  const dispatch = useDispatch();
  const [subscribed, setSubscribed] = useState(false);
  const [channelName, setChannelName] = useState('');
  const [proctorIdentifier, setProctorIdentifier] = useState('');
  const [ready, setReady] = useState(false);

  const studentUser: StudentAuthUser = useSelector(
    (state: AppState) => state.auth.studentAuth.user,
  );
  const studentChannel: Ably.Types.RealtimeChannelCallbacks | null = useSelector(
    (state: AppState) => state.ably.studentChannel,
  );

  const ablyState = useSelector((state: AppState) => state.ably);

  const ablyConnection = useMemo<Ably.Types.RealtimeCallbacks | null>(() => {
    return ablyState.ready ? ablyState.ablyRealtime : null;
  }, [ablyState.ablyRealtime, ablyState.ready]);

  // hook is ready when studentChannel is available in state and channelName set based on Student details
  // When hook is destroyed:
  //   - if an onCommand callback was set, unsubscribe
  //   - if the hook was set to init:true, detach the student channel and clear state
  useEffect(() => {
    if (studentChannel && channelName) {
      setReady(true);
    }

    return () => {
      if (studentChannel && channelName && options.onCommand) {
        logger.info(`ABLY: Unsubscribe Listener: ${channelName}`);
        studentChannel.unsubscribe(channelName, options.onCommand);
        setSubscribed(false);
      }

      if (options.init && studentChannel) {
        logger.info(`ABLY: root hook: detach from ${studentChannel.name}`);
        dispatch({ type: 'STUDENT_CHANNEL_CONNECTED', payload: null });
        studentChannel.detach();
      }
    };
  }, [studentChannel, channelName]);

  // Update return value for hook anytime the ready or subscribed state changes
  useEffect(() => {
    setHookState({
      ready,
      subscribed,
      sendCommandToStudentChannel,
    });
  }, [ready, subscribed]);

  // Set the student channel name once the student details have been loaded via auth / cache
  useEffect(() => {
    const identifier = ablyStudentIdentifier(studentUser);
    if (identifier !== '' && channelName === '') {
      logger.info(`ABLY: Set Channel Name ${identifier}`);
      setChannelName(identifier);
    }
  }, [studentUser.info.globalStudentId]);

  // Set the proctor code for ably session once it is available in the the studentUser object
  useEffect(() => {
    const identifier = ablyProctorIdentifierForStudent(studentUser);
    if (identifier !== '') {
      logger.info(`ABLY: Set proctor identifier: ${identifier}`);
      setProctorIdentifier(identifier);
    }
  }, [studentUser.proctorCode]);

  // If init:true is set, join/create the student channel and subscribe to presence
  useEffect(() => {
    if (ablyConnection !== null && options.init && channelName !== '') {
      joinStudentChannel(ablyConnection);
    }
  }, [ablyConnection, options.init, channelName]);

  // If an onCommand callback was set in the hook options, subscribe to callbacks on the student channel
  useEffect(() => {
    if (studentChannel && channelName !== '' && options.onCommand && !subscribed) {
      logger.info(`ABLY: Subscribing to messages on ${channelName} using ${channelName}`);
      studentChannel.subscribe(channelName, options.onCommand);
      setSubscribed(true);
    }
  }, [options.onCommand, subscribed, studentChannel, channelName]);

  const sendCommandToStudentChannel = (command: AblyMessageWithPayload<any>) => {
    if (studentChannel !== null) {
      // adding district ID to all messages via ably allows us to monitor and identify ably traffic
      const districtId = studentUser.info.customerId;
      const publishMessage = { ...command, districtId };
      studentChannel.publish(publishMessage.toUser, publishMessage, (err) => {
        if (err) {
          logger.error(`ABLY: Message failed on ${studentChannel.name}: ${err.message}`);
        } else {
          logger.info(
            `ABLY: Message out|user ${publishMessage.toUser}|channel ${studentChannel.name}|${publishMessage.message}`,
          );
        }
      });
    }
  };

  const [hookState, setHookState] = useState({
    ready,
    subscribed,
    sendCommandToStudentChannel,
  });

  const joinStudentChannel = (connection: Ably.Realtime) => {
    logger.info(`ABLY: join student channel ${channelName}`);
    const studentChannel = connection.channels.get(channelName);
    dispatch({ type: 'STUDENT_CHANNEL_CONNECTED', payload: studentChannel });

    // subscribe to presence events for the proctor and set state accordingly
    studentChannel.presence.subscribe(['enter', 'present', 'leave'], (presenceEvent) => {
      if (presenceEvent.clientId === proctorIdentifier) {
        const present = isPresentAblyEvent(presenceEvent);
        logger.info(
          `ABLY|Proctor online status update|${presenceEvent.clientId}|${channelName}|${presenceEvent.action}|Proctor is online:${present}`,
        );
        dispatch({ type: 'SET_PROCTOR_ONLINE_STATUS', payload: present });
      }
    });

    // enter presence in the student channel - this allows the proctor to monitor student online status
    studentChannel.presence.enter();
  };

  return hookState;
};

export default useStudentChannel;
