import React from 'react';
import {
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { DuplicateAssignmentAlertProps } from './DuplicateAssignmentAlert.types';

function TableCell(props?: TableCellProps) {
  const { sx, ...rest } = props || {};

  return <MuiTableCell sx={{ borderColor: 'rgba(0,0,0, 0.2)', ...sx }} {...rest} />;
}

function formatDateString(dateString: string) {
  return moment(dateString).format('MMM D, YYYY');
}

const createDescription = (
  <>
    Creating this assignment will lead to duplicated subtests being assigned to your students,{' '}
    <strong>which can lead to scoring issues</strong>.
  </>
);
const editDescription = (
  <>
    This assignment contains duplicated subtests that are assigned to your students,{' '}
    <strong>which can lead to scoring issues</strong>.
  </>
);
const warningMessage =
  'Please consider updating the assignment Grade, Level, Battery, or Test Dates to prevent any conflicts.';
const errorMessage = `Conflicts with the assignment Grade, Level, Battery, or Test Dates will prevent the assignment 
from being created. Please review and update the assignment to prevent any conflicts.`;

export function DuplicateAssignmentAlert(props: DuplicateAssignmentAlertProps) {
  const { actionType = 'create', duplicates, severity = 'warning', sx, ...rest } = props;
  const alertType = severity === 'warning' ? 'info' : 'warning';

  if (!duplicates.length) {
    return null;
  }

  return (
    <Alert severity={alertType} sx={{ '& .MuiAlert-message': { flex: 1 }, ...sx }} {...rest}>
      <AlertTitle sx={{ mb: 2 }}>Uh oh! Duplicate assignments detected.</AlertTitle>
      <Typography
        variant="body2"
        paragraph
        data-testid={`alert-message-action-type--${actionType}`}
      >
        {actionType === 'edit' ? editDescription : createDescription}
      </Typography>
      <Typography variant="body2" paragraph data-testid={`alert-message-severity--${severity}`}>
        {severity === 'warning' ? warningMessage : errorMessage}
      </Typography>
      <Table
        aria-label="Duplicate Assignments"
        sx={{ border: '1px solid', borderColor: 'rgba(0,0,0, 0.2)' }}
      >
        <TableHead>
          <TableRow>
            {[
              { label: 'Assignment Name', align: 'left' },
              { label: 'Form Type', align: 'right' },
              { label: 'Grade', align: 'right' },
              { label: 'Level', align: 'right' },
              { label: 'Battery', align: 'right' },
              { label: 'Start Date', align: 'right' },
              { label: 'End Date', align: 'right' },
            ].map((column) => (
              <TableCell
                key={column.label}
                align={column.align as 'left' | 'right'}
                sx={{ fontWeight: '600' }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {duplicates.map((duplicate) => (
            <TableRow key={duplicate.assignmentId}>
              {[
                { value: duplicate.assignmentName, align: 'left' },
                { value: duplicate.assignmentType, align: 'right' },
                {
                  value: duplicate.gradeDomains.map((gd) => gd.grade_id).join(', '),
                  align: 'right',
                },
                {
                  value: duplicate.gradeDomains
                    .map((gd) => gd.level?.replace('Level ', ''))
                    .join(', '),
                  align: 'right',
                },
                {
                  value: duplicate.gradeDomains.map((gd) => gd.domain_id).join(', '),
                  align: 'right',
                },
                { value: formatDateString(duplicate.startDate), align: 'right' },
                { value: formatDateString(duplicate.endDate), align: 'right' },
              ].map((cell, i) => (
                <TableCell key={i} align={cell.align as 'left' | 'right'}>
                  {cell.value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Alert>
  );
}

export default DuplicateAssignmentAlert;
