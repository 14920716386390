import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { ModalDialog } from '@riversideinsights/elevate-react-lib';

import useCachedUser from '../../../hooks/useCachedUser';
import useAppStatus from '../../../hooks/useAppStatus';

const AppDebugHeaderToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const user = useCachedUser();

  const [showModal, setShowModal] = useState(false);

  const appStatus = useAppStatus();

  const displayModal = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const hideHeaderToolbar = () => {
    setTimeout(() => {
      dispatch({
        type: 'SET_HEADER_TOOLBAR',
        payload: null,
      });
    }, 400);
    dispatch({ type: 'SET_HEADER_TOOLBAR_VISIBLE', payload: false });
  };

  useEffect(() => {
    if (showModal) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="app-debug-test-modal"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            title="App Debug Test Modal Header With Additional Text"
            footer={<div>App Debug Test Modal Footer</div>}
            body={<div>Modal dialog</div>}
            onClose={hideModal}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [showModal]);

  useEffect(() => {
    return () => {
      if (showModal) {
        dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
      }
    };
  }, []);

  return (
    <div className="app-debug-header-toolbar">
      <div className="app-header-toolbar-title">Debug Toolbar</div>
      <div className="app-debug-header-toolbar-section">You&apos;re logged in as {user.email}.</div>
      <div className="app-debug-header-toolbar-section">
        <button
          className="app-button app-button-small"
          onClick={displayModal}
          disabled={appStatus.currentModal !== null}
        >
          Open Modal
        </button>
        <button className="app-button app-button-small" onClick={hideHeaderToolbar}>
          Close toolbar
        </button>
      </div>
    </div>
  );
};

export default AppDebugHeaderToolbar;
