import { useSelector } from 'react-redux';
import elevateInfo from '../../elevate-version';
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import { AppState } from '../../reducers/appDataTypes';
import { useEffect, useState } from 'react';
import { StaffAuthUser, StudentAuthUser } from '../../reducers/Auth/authDataTypes';
import { formatProctorCode, getRoleLabel } from '../../lib/util/user.util';
import { FlagAttributesResult } from './types';

const attributesFromStaff = (staff: StaffAuthUser): SplitIO.Attributes => ({
  proctorCode: formatProctorCode(staff.currentProctorCode),
  organizationId: staff.customerId,
  role: getRoleLabel(staff.role),
  sso: staff.ssoUser,
  userId: staff.userId,
});

const attributesFromStudent = (student: StudentAuthUser): SplitIO.Attributes => ({
  proctorCode: formatProctorCode(student.info.proctorCode),
  organizationId: student.info.customerId,
  role: getRoleLabel(student.role),
  sso: student.ssoUser,
  userId: student.studentId,
});

const useFlagAttributes = (): FlagAttributesResult => {
  const config = useConfigContext();
  const staffUser = useSelector((state: AppState) => state.auth.staffAuth.user);
  const studentUser = useSelector((state: AppState) => state.auth.studentAuth.user);
  const [hookState, setHookState] = useState<FlagAttributesResult>({
    splitKey: `elevate-ui-initial-key-${config.appEnvironment}-${elevateInfo.version}`,
    attributes: {},
  });

  useEffect(() => {
    if (staffUser && staffUser.loadComplete) {
      const attributes = attributesFromStaff(staffUser);
      const splitKey = `org-${attributes?.organizationId}`;
      setHookState({
        attributes,
        splitKey,
      });
    }
    if (studentUser && studentUser.loadComplete) {
      const attributes = attributesFromStudent(studentUser);
      const splitKey = `org-${attributes?.organizationId}`;
      setHookState({
        attributes,
        splitKey,
      });
    }
  }, [staffUser, studentUser]);

  return hookState;
};

export default useFlagAttributes;
