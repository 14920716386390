import React, { useMemo, useState, useRef } from 'react';

import TableCell from '@mui/material/TableCell';

import {
  ElevateTableHeaderCellProps,
  ElevateTableRecord,
  ElevateTableStatus,
} from './ElevateTable.d';
import SortAscIcon from './ElevateTableIcons/SortAscIcon';
import SortDescIcon from './ElevateTableIcons/SortDescIcon';
import { Button, TableSortLabel, Popper, ClickAwayListener } from '@mui/material';

import FilterIcon from './ElevateTableIcons/FilterIcon';

const ElevateTableHeaderCell = <T extends ElevateTableRecord>(
  props: ElevateTableHeaderCellProps<T>,
): React.ReactElement | null => {
  const [showFilters, setShowFilters] = useState(false);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const propName = props.column.propName;
  const attributes = props.column.attributes || {};
  let sortDirectionProp: false | 'desc' | 'asc' | undefined;
  if (props.tableData.status.sortProp === propName) {
    // attributes['aria-sort'] = props.tableData.status.sortDirection === 'desc' ? 'descending' : 'ascending';
    sortDirectionProp = props.tableData.status.sortDirection as typeof sortDirectionProp;
  } else {
    sortDirectionProp = false;
    // attributes['aria-sort'] = 'none';
  }

  const handleSortClick = (event: React.SyntheticEvent, newSort: string) => {
    if (!props.tableData.status.loading) {
      if (props.tableData.status.sortProp === newSort) {
        const newOrder = props.tableData.status.sortDirection === 'asc' ? 'desc' : 'asc';
        const newOffset = 0;
        const tableStatusUpdates: ElevateTableStatus = {
          ...props.tableData.status,
          sortDirection: newOrder,
          offset: newOffset,
        };
        if (props.onChange) {
          props.onChange(tableStatusUpdates);
        }
      } else {
        const tableStatusUpdates: ElevateTableStatus = {
          ...props.tableData.status,
          sortProp: newSort,
          sortDirection: 'asc',
          offset: 0,
        };
        if (props.onChange) {
          props.onChange(tableStatusUpdates);
        }
      }
    }
  };

  let direction: 'asc' | 'desc' | undefined;

  const wrapperClasses = useMemo<string>(() => {
    let classes = 'elevate-table-header-cell';
    if (props.column.sortable) {
      classes += ' elevate-table-sortable-header-cell';
    }
    if (props.column.renderFilter) {
      classes += ' elevate-table-filter-header-cell';
    }
    if (props.tableData.status.sortProp === props.column.propName) {
      classes += ' elevate-table-header-cell-sorted';
    }
    if (props.column.attributes?.className) {
      classes += ` ${props.column.attributes.className}`;
    }
    return classes;
  }, [
    props.column.sortable,
    props.column.renderFilter,
    props.column.propName,
    props.tableData.status.sortProp,
    props.column.attributes?.className,
  ]);

  const labelText = useMemo<React.ReactNode>(() => {
    if (props.column.sortable) {
      return (
        <TableSortLabel
          IconComponent={
            props.tableData.status.sortProp === props.column.propName &&
            props.tableData.status.sortDirection === 'desc'
              ? SortDescIcon
              : SortAscIcon
          }
          active={props.tableData.status.sortProp === props.column.propName}
          hideSortIcon={false}
          disabled={
            props.tableData.status.loading === true || props.tableData.status.errorMessage !== ''
          }
          direction={direction}
          onClick={(event: React.SyntheticEvent) => {
            handleSortClick(event, props.column.propName);
          }}
          aria-label={`Sort table by ${props.column.propLabel} ${
            props.tableData.status.sortProp === props.column.propName && direction === 'asc'
              ? 'descending'
              : 'ascending'
          } `}
          className="elevate-table-header-cell-label-text"
        >
          {props.column.propLabel}
        </TableSortLabel>
      );
    } else {
      return <span className="elevate-table-header-cell-label-text">{props.column.propLabel}</span>;
    }
  }, [
    props.column.sortable,
    props.tableData.status.loading,
    props.tableData.status.errorMessage,
    props.tableData.status.sortProp,
    props.tableData.status.sortDirection,
    props.column.propLabel,
    props.column.propName,
  ]);

  const handleClick = () => {
    setShowFilters(!showFilters);
  };

  const filters = useMemo<React.ReactNode | null>(() => {
    if (!props.column.renderFilter) {
      return null;
    } else {
      return (
        <span className="elevate-table-header-cell-tools" onClick={handleClick}>
          {typeof props.column.renderFilter === 'function' &&
            props.column.renderFilter(props.column)}
          {typeof props.column.renderFilter !== 'function' && props.column.renderFilter}
        </span>
      );
    }
  }, [props.column?.renderFilter]);

  return (
    <TableCell
      role="columnheader"
      scope="col"
      key={propName}
      align="left"
      {...attributes}
      sortDirection={sortDirectionProp}
      aria-live="polite"
      className={wrapperClasses}
    >
      <div ref={wrapperRef} className="elevate-table-header-cell-wrapper">
        <div className="elevate-table-header-cell-value">
          <span className="elevate-table-header-cell-label">{labelText}</span>
          {props.column?.filter && (
            <Button
              variant="text"
              className="elevate-table-header-cell-tools"
              disabled={props.tableData.status.loading}
              onClick={handleClick}
            >
              <FilterIcon />
            </Button>
          )}
          {/* { showFilters && typeof props.column.renderFilter === 'function' && props.column.renderFilter(props.column) } */}
          <Popper
            className="elevate-table-header-cell-filters-wrapper"
            anchorEl={wrapperRef.current}
            open={showFilters}
          >
            <span>
              <ClickAwayListener
                onClickAway={() => {
                  setShowFilters(false);
                }}
              >
                <span>{filters}</span>
              </ClickAwayListener>
            </span>
          </Popper>
        </div>
      </div>
    </TableCell>
  );
};

export default ElevateTableHeaderCell;
