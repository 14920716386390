import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useCachedUser from '../../hooks/useCachedUser';

import Dropdown, { IDropdownOption } from '../shared/Dropdown/Dropdown';
import HelpGuide from './HelpGuide';
import { AppState } from '../../reducers/appDataTypes';
import useNotificationHelper from '../../hooks/useNotificationHelper';
import { ModalDialog } from '@riversideinsights/elevate-react-lib';

import './ProctorHeaderToolbar.scss';
import { ReactComponent as IconCheck } from '../../assets/icons/proctoring/check.svg';
import { ReactComponent as IconApprove } from '../../assets/icons/proctoring/approve.svg';
import { ReactComponent as IconDeny } from '../../assets/icons/proctoring/deny.svg';
import { ReactComponent as IconSearch } from '../../assets/icons/proctoring/search.svg';
import { ReactComponent as IconHelpGuide } from '../../assets/icons/proctoring/help-guide.svg';
import { ReactComponent as IconSortingUp } from '../../assets/icons/proctoring/sorting-up.svg';
import { ReactComponent as IconSortingDown } from '../../assets/icons/proctoring/sorting-down.svg';
import { ReactComponent as IconRecheck } from '../../assets/icons/proctoring/recheck.svg';
import { ReactComponent as IconClose } from '../../assets/icons/proctoring/close.svg';

const ProctorHeaderToolbar = () => {
  const students = useSelector((state: any) => state.proctor.students);
  const searchText = useSelector((state: any) => state.proctor.students.searchText);
  const buttonSortingOptions = useSelector(
    (state: any) => state.proctor.buttonSortingOptions.buttonState,
  );
  const [buttonSearchState, setButtonSearchState] = useState(false);
  const refInputSearch = useRef<HTMLInputElement>(null);
  const [isExtendTestingTimePopupVisible, setIsExtendTestingTimePopupVisible] = useState(false);
  const [searchInputText, setSearchInputText] = useState('');
  const headerContent = useSelector((state: any) => state.proctor.headerContent);
  const notifications = useSelector((state: any) => state.proctor.notifications);
  const refInputMinute = useRef<HTMLInputElement>(null);
  const appStatus = useSelector((state: AppState) => {
    return state.appStatus;
  });

  const NotificationHelper = useNotificationHelper();

  const dispatch = useDispatch();

  const user = useCachedUser();

  //const logger = useAppLogger('Component|ProctorHeaderToolbar');

  const searchInputTextWaitingApproval = useRef('');
  const searchInputTextInProgress = useRef('');
  const searchInputTextCompleted = useRef('');
  const addTimeHour = useRef('');
  const addTimeMinute = useRef('');
  const idCancelAndDiscardDialog = useRef('');
  const groupActionOptions = useRef<any>([]);

  useEffect(() => {
    groupActionOptions.current = [
      {
        label: 'Extend Testing Time',
        value: 'extend-testing-time',
        selected: false,
        disabled: true,
      },
      { label: 'Save and Exit', value: 'save-and-exit', selected: false },
      { label: 'Pause', value: 'pause', selected: false },
      { label: 'Resume', value: 'resume', selected: false },
      { label: 'Cancel and Discard', value: 'cancel', selected: false },
    ];
  }, []);

  useEffect(() => {
    //if (refInputSearch.current) refInputSearch.current.value = "";
    if (headerContent.activeTab === 'dashboard') {
      setSearchInputText(searchInputTextWaitingApproval.current);
    }
    if (headerContent.activeTab === 'live-tests') {
      setSearchInputText(searchInputTextInProgress.current);
    }
    if (headerContent.activeTab === 'completed') {
      setSearchInputText(searchInputTextCompleted.current);
    }
  }, [headerContent.activeTab]);

  const clickSortingOptionsButton = () => {
    if (buttonSortingOptions.isActive)
      dispatch({
        type: 'CLICK_SORTING_OPTIONS_BUTTON',
        payload: { tooltipText: 'Open sorting options', isActive: false },
      });
    else {
      dispatch({
        type: 'CLICK_SORTING_OPTIONS_BUTTON',
        payload: { tooltipText: 'Close sorting options', isActive: true },
      });
      dispatch({
        type: 'CLICK_ANALYTICS_BUTTON',
        payload: { tooltipText: 'Analytics', isActive: false },
      });
    }
  };

  const handleSearchKeyup = (e: any) => {
    if (e.keyCode === 13) {
      if (headerContent.activeTab === 'dashboard') {
        searchInputTextWaitingApproval.current = e.target.value;
        dispatch({ type: 'SEARCH_STUDENTS_WAITING_APPROVAL', payload: e.target.value });
      }
      if (headerContent.activeTab === 'live-tests') {
        searchInputTextInProgress.current = e.target.value;
        dispatch({
          type: 'SEARCH_STUDENTS_API',
          payload: { searchText: e.target.value, type: headerContent.activeTab },
        });
      }
      if (headerContent.activeTab === 'completed') {
        searchInputTextCompleted.current = e.target.value;
        dispatch({
          type: 'SEARCH_STUDENTS_API',
          payload: { searchText: e.target.value, type: headerContent.activeTab },
        });
      }
    }
  };

  const handleButtonSearchClick = () => {
    if (buttonSearchState) {
      if (headerContent.activeTab === 'dashboard') {
        searchInputTextWaitingApproval.current = '';
        dispatch({ type: 'SEARCH_STUDENTS_WAITING_APPROVAL', payload: '' });
      }
      if (headerContent.activeTab === 'live-tests') {
        searchInputTextInProgress.current = '';
        dispatch({
          type: 'SEARCH_STUDENTS_API',
          payload: { searchText: '', type: headerContent.activeTab },
        });
      }
      if (headerContent.activeTab === 'completed') {
        searchInputTextCompleted.current = '';
        dispatch({
          type: 'SEARCH_STUDENTS_API',
          payload: { searchText: '', type: headerContent.activeTab },
        });
      }
      setSearchInputText('');
    }
    if (refInputSearch.current && !buttonSearchState) refInputSearch.current.focus();
    setButtonSearchState(!buttonSearchState);
  };

  const handleInputSearchBlur = () => {
    //if (searchText === "") setButtonSearchState(false);
  };

  const handleHeaderApplyButtonClick = () => {
    const arrCheckedStudent: any = [];
    students.studentsWaitingApproval.allItems.forEach((student: any) => {
      if (student.checked && !student.isInsufficientLicenseCount) {
        arrCheckedStudent.push(student);
      }
    });

    dispatch({
      type: 'SET_STUDENT_LIST_TO_APPROVE',
      payload: arrCheckedStudent,
    });
  };

  const handleHeaderDenyButtonClick = () => {
    const arrCheckedStudent: any = [];
    const arrDeny: any[] = [];
    const arrDenyTimeExtension: any[] = [];
    students.studentsWaitingApproval.allItems.forEach((student: any) => {
      if (student.checked && !student.isInsufficientLicenseCount) {
        arrCheckedStudent.push(student);
        if (student.sessionStatusId === 13) arrDenyTimeExtension.push(student);
        else arrDeny.push(student);
      }
    });
    dispatch({ type: 'SET_STUDENT_LIST_TO_DENY', payload: arrCheckedStudent });
    if (arrDeny.length) dispatch({ type: 'TOGGLE_POPUP_DENY_REASONS', payload: true });
  };

  const handleProctorGroupActionChange = (option: IDropdownOption | IDropdownOption[]) => {
    const opt: IDropdownOption = Array.isArray(option) ? option[0] : option;
    //AppHelper.appLogger(option);
    const arrCheckedCards: any = [];
    const arrCheckedStudentSessionGuid: any = [];
    students.studentsInProgress.pageItems.forEach((student: any) => {
      if (student.checked && arrCheckedStudentSessionGuid.indexOf(student.sessionGuid) === -1) {
        arrCheckedCards.push(student);
        arrCheckedStudentSessionGuid.push(student.sessionGuid);
      }
    });
    //AppHelper.appLogger(arrCheckedStudentSessionGuid);
    if (opt.value === 'pause')
      dispatch({
        type: 'SET_CARDS_LIST_TO_PAUSE',
        payload: arrCheckedCards,
      });
    if (opt.value === 'resume')
      dispatch({
        type: 'SET_CARDS_LIST_TO_RESUME',
        payload: arrCheckedCards,
      });
    if (opt.value === 'save-and-exit')
      dispatch({
        type: 'SET_CARDS_LIST_TO_SAVE_AND_EXIT',
        payload: arrCheckedCards,
      });
    if (opt.value === 'cancel') {
      showCancelAndDiscardDialog();
      //dispatch({type: 'SET_CARDS_LIST_TO_CANCEL_AND_DISCARD', payload: arrCheckedCards});
    }
    if (opt.value === 'extend-testing-time') {
      setIsExtendTestingTimePopupVisible(true);
    }
  };

  useEffect(() => {
    if (isExtendTestingTimePopupVisible && refInputMinute.current) {
      refInputMinute.current.focus();
    }
  }, [isExtendTestingTimePopupVisible]);

  const removeCancelAndDiscardDialog = () => {
    NotificationHelper.remove(idCancelAndDiscardDialog.current);
  };

  const showCancelAndDiscardDialog = () => {
    const objCancelAndDiscardDialog = NotificationHelper.add(
      <>
        Cancel and Discard
        <div className="app-notification-small-text">
          Are you sure you want to Cancel and Discard? <br />
          Answers will NOT be saved. Students will be returned to the testing pool and may retake
          this test.
        </div>
        <div className="dialog-buttons-wrapper">
          <button
            className="button small primary"
            onClick={() => {
              removeCancelAndDiscardDialog();
              if (students.lastSelectedCard) {
                dispatch({
                  type: 'REMEMBER_LAST_SELECTED_CARD',
                  payload: '',
                });
                dispatch({ type: 'SHOW_DIALOG', payload: '' });
              }
            }}
          >
            No
          </button>
          <button
            className="button small primary"
            onClick={() => {
              groupActionCancelAndDiscard();
              removeCancelAndDiscardDialog();
            }}
          >
            Yes
          </button>
        </div>
      </>,
      'warning',
    );
    idCancelAndDiscardDialog.current = objCancelAndDiscardDialog.uuid;
  };

  useEffect(() => {
    if (students.showDialog === 'DialogCancelAndDiscard') {
      showCancelAndDiscardDialog();
    }
  }, [students.showDialog]);

  const proctorExtendTime = useMemo<boolean>(() => {
    //return user.role === 'district_admin' || !!user.currentPermissions.includes(74);
    return true; //GP1-13382
  }, [user.role, user.currentPermissions]);

  useEffect(() => {
    if (
      proctorExtendTime &&
      groupActionOptions.current.length &&
      groupActionOptions.current[0].value === 'extend-testing-time'
    ) {
      if (students.studentsInProgress.isExtendTestingTimeDisabled) {
        groupActionOptions.current[0].disabled = true;
      } else {
        groupActionOptions.current[0].disabled = false;
      }
    }
  }, [students.studentsInProgress.isExtendTestingTimeDisabled]);

  const groupActionCancelAndDiscard = () => {
    const arrCheckedCards: any = [];
    const arrCheckedStudentSessionGuid: any = [];
    students.studentsInProgress.pageItems.forEach((student: any) => {
      if (student.checked && arrCheckedStudentSessionGuid.indexOf(student.sessionGuid) === -1) {
        arrCheckedCards.push(student);
        arrCheckedStudentSessionGuid.push(student.sessionGuid);
      }
    });
    if (!arrCheckedStudentSessionGuid.length && students.lastSelectedCard) {
      arrCheckedCards.push(students.lastSelectedCard);
      arrCheckedStudentSessionGuid.push(students.lastSelectedCard.sessionGuid);
      dispatch({
        type: 'REMEMBER_LAST_SELECTED_CARD',
        payload: '',
      });
      dispatch({ type: 'SHOW_DIALOG', payload: '' });
    }
    dispatch({
      type: 'SET_CARDS_LIST_TO_CANCEL_AND_DISCARD',
      payload: arrCheckedCards,
    });
  };

  const handleOnClickAddTimeOk = () => {
    const arrCheckedStudentSessionGuid: any = [];
    students.studentsInProgress.pageItems.forEach((student: any) => {
      if (student.checked && arrCheckedStudentSessionGuid.indexOf(student.sessionGuid) === -1)
        arrCheckedStudentSessionGuid.push(student.sessionGuid);
    });
    dispatch({
      type: 'SET_CARDS_LIST_TO_EXTEND_TIME',
      payload: {
        arrCheckedStudentSessionGuid: arrCheckedStudentSessionGuid,
        extendTimeMinutes: Number(addTimeHour.current) * 60 + Number(addTimeMinute.current),
      },
    });
    addTimeHour.current = '';
    addTimeMinute.current = '';
    setIsExtendTestingTimePopupVisible(false);
  };

  const handleOnClickAddTimeCancel = () => {
    addTimeHour.current = '';
    addTimeMinute.current = '';
    setIsExtendTestingTimePopupVisible(false);
  };

  const validateAdditionalTimeForm = () => {
    const modalHeader = document.querySelector(
      '.popup-bulk-time-extend .modal-dialog-top',
    ) as HTMLInputElement;
    const inputHour = document.getElementById('additional-time-input-hour') as HTMLInputElement;
    const inputMinute = document.getElementById('additional-time-input-minute') as HTMLInputElement;
    const buttonOk = document.getElementById('additional-time-button-ok') as HTMLInputElement;
    if (modalHeader) {
      modalHeader.remove();
    }
    if (inputHour && inputMinute && buttonOk) {
      let value = inputHour.value;
      value = value.replace(/\D/, '');
      //if (value.length < 2) value = '0' + value;
      //if (value.length === '') value = '00';
      //if (Number(value) > 23) value = '23';
      if (Number(value) > 1) value = '1';
      addTimeHour.current = value;
      inputHour.value = value;
      if (inputHour.value) {
        inputHour.className = 'not-zero';
      } else {
        inputHour.className = '';
      }

      value = inputMinute.value;
      if (inputHour.value === '1' && Number(value) > 39) {
        value = '39';
      }
      value = value.replace(/\D/, '');
      if (Number(value) > 59) value = '59';
      addTimeMinute.current = value;
      inputMinute.value = value;
      if (inputMinute.value) {
        inputMinute.className = 'not-zero';
      } else {
        inputMinute.className = '';
      }

      if (inputHour.value || inputMinute.value) {
        buttonOk.disabled = false;
      } else {
        buttonOk.disabled = true;
      }
    }
  };

  useEffect(() => {
    if (appStatus.currentModalOpener) {
      validateAdditionalTimeForm();
    }
  }, [appStatus.currentModalOpener]);

  useEffect(() => {
    if (isExtendTestingTimePopupVisible) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="popup-bulk-time-extend"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            focusSelector="#additional-time-input-minute"
            body={
              <>
                <div className="add-additional-time-wrapper">
                  <b>Enter additional time:</b>
                  <div className="inputs-wrapper">
                    <span className="input-wrapper">
                      <input
                        id="additional-time-input-hour"
                        type="text"
                        onChange={validateAdditionalTimeForm}
                        maxLength={2}
                        placeholder="00"
                        className={
                          addTimeHour.current !== '00' &&
                          addTimeHour.current !== '0' &&
                          addTimeHour.current !== ''
                            ? ' not-zero'
                            : ''
                        }
                      />
                      <span className="text">hr</span>
                    </span>
                    <span className="divider">:</span>
                    <span className="input-wrapper">
                      <input
                        id="additional-time-input-minute"
                        type="text"
                        onChange={validateAdditionalTimeForm}
                        maxLength={2}
                        placeholder="00"
                        className={
                          addTimeMinute.current !== '00' &&
                          addTimeMinute.current !== '0' &&
                          addTimeMinute.current !== ''
                            ? ' not-zero'
                            : ''
                        }
                        ref={refInputMinute}
                      />
                      <span className="text">min</span>
                    </span>
                  </div>
                  <button
                    id="additional-time-button-ok"
                    className="add-time-button-ok tooltip-custom"
                    onClick={handleOnClickAddTimeOk}
                  >
                    <IconCheck />
                    <span className="tooltiptext middle-aligned-left" role="tooltip">
                      Save
                    </span>
                  </button>
                  <button
                    id="additional-time-button-cancel"
                    className="add-time-button-cancel tooltip-custom"
                    onClick={handleOnClickAddTimeCancel}
                  >
                    <IconClose />
                    <span className="tooltiptext middle-aligned-left" role="tooltip">
                      Cancel
                    </span>
                  </button>
                </div>
              </>
            }
            onClose={() => {
              handleOnClickAddTimeCancel();
            }}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [isExtendTestingTimePopupVisible]);

  return (
    <div className="header-toolbar">
      <div id="header-panel2">
        <div className="left">
          {headerContent.activeTab !== 'completed' && (
            <button
              className={
                'main-header-filter-button' +
                ((headerContent.activeTab === 'dashboard' &&
                  students.studentsWaitingApproval.pageItems.length) ||
                (headerContent.activeTab === 'live-tests' &&
                  students.studentsInProgress.pageItems.length) ||
                (headerContent.activeTab === 'completed' &&
                  students.studentsCompleted.pageItems.length)
                  ? ''
                  : ' disabled')
              }
              id="filter-select-all-students"
              onClick={(e) => {
                if (headerContent.activeTab === 'dashboard') {
                  if (
                    students.studentsWaitingApproval.pageItems.length &&
                    students.studentsWaitingApproval.pageItems.filter((n: any) => n.checked)
                      .length === students.studentsWaitingApproval.pageItems.length
                    // && students.studentsWaitingApproval.pageItems.filter((n: any) => n.sessionStatusId !== 13).length &&
                    // students.studentsWaitingApproval.pageItems.filter((n: any) => n.sessionStatusId !== 13).length ===
                    //   students.studentsWaitingApproval.pageItems.filter((n: any) => n.sessionStatusId !== 13 && n.checked).length
                  )
                    dispatch({
                      type: 'SELECT_OR_UNSELECT_ALL_STUDENTS',
                      payload: { type: headerContent.activeTab, value: false },
                    });
                  else
                    dispatch({
                      type: 'SELECT_OR_UNSELECT_ALL_STUDENTS',
                      payload: { type: headerContent.activeTab, value: true },
                    });
                }
                if (headerContent.activeTab === 'live-tests') {
                  if (
                    students.studentsInProgress.pageItems.length &&
                    students.studentsInProgress.pageItems.length ===
                      students.studentsInProgress.pageItems.filter((n: any) => n.checked).length
                  )
                    dispatch({
                      type: 'SELECT_OR_UNSELECT_ALL_STUDENTS',
                      payload: { type: headerContent.activeTab, value: false },
                    });
                  else
                    dispatch({
                      type: 'SELECT_OR_UNSELECT_ALL_STUDENTS',
                      payload: { type: headerContent.activeTab, value: true },
                    });
                }
                if (headerContent.activeTab === 'completed') {
                  if (
                    students.studentsCompleted.pageItems.length &&
                    students.studentsCompleted.pageItems.length ===
                      students.studentsCompleted.pageItems.filter((n: any) => n.checked).length
                  )
                    dispatch({
                      type: 'SELECT_OR_UNSELECT_ALL_STUDENTS',
                      payload: { type: headerContent.activeTab, value: false },
                    });
                  else
                    dispatch({
                      type: 'SELECT_OR_UNSELECT_ALL_STUDENTS',
                      payload: { type: headerContent.activeTab, value: true },
                    });
                }
              }}
            >
              <span>
                {(headerContent.activeTab === 'dashboard' &&
                  students.studentsWaitingApproval.pageItems.length &&
                  students.studentsWaitingApproval.pageItems.length ===
                    students.studentsWaitingApproval.pageItems.filter((n: any) => n.checked)
                      .length) ||
                (headerContent.activeTab === 'live-tests' &&
                  students.studentsInProgress.pageItems.length &&
                  students.studentsInProgress.pageItems.length ===
                    students.studentsInProgress.pageItems.filter((n: any) => n.checked).length) ||
                (headerContent.activeTab === 'completed' &&
                  students.studentsCompleted.pageItems.length &&
                  students.studentsCompleted.pageItems.length ===
                    students.studentsCompleted.pageItems.filter((n: any) => n.checked).length)
                  ? 'Unselect all students'
                  : 'Select all students'}
              </span>
              <IconCheck />
            </button>
          )}
          {headerContent.activeTab === 'dashboard' && (
            <React.Fragment>
              <button
                className={
                  'main-header-filter-button' +
                  (students.studentsWaitingApproval.pageItems.filter((n: any) => n.checked).length
                    ? ''
                    : ' hidden')
                }
                id="approve-students"
                onClick={() => handleHeaderApplyButtonClick()}
              >
                Approve
                <IconApprove />
              </button>
              <button
                className={
                  'main-header-filter-button' +
                  (students.studentsWaitingApproval.pageItems.filter((n: any) => n.checked).length
                    ? ''
                    : ' hidden')
                }
                id="deny-students"
                onClick={() => handleHeaderDenyButtonClick()}
              >
                Deny
                <IconDeny />
              </button>
            </React.Fragment>
          )}
          {headerContent.activeTab === 'live-tests' && (
            <React.Fragment>
              <Dropdown
                cssClass="proctoring-header-dropdown"
                placeholder="Choose an action"
                isDisabled={
                  students.studentsInProgress.pageItems.filter((n: any) => n.checked).length
                    ? false
                    : true
                }
                onChange={handleProctorGroupActionChange}
                options={groupActionOptions.current}
              />
              <button
                className={
                  'main-header-round-button tooltip-custom' +
                  (students.flagUpdatingInProgressStudents ? ' active' : '')
                }
                id="header-button-recheck-status"
                disabled={
                  students.flagUpdatingInProgressStudents ||
                  !students.studentsInProgress.pageItems.length
                }
                onClick={() =>
                  dispatch({
                    type: 'FLAG_UPDATING_IN_PROGRESS_STUDENTS',
                    payload: true,
                  })
                }
              >
                <IconRecheck
                  className={students.flagUpdatingInProgressStudents ? 'loader-spinner' : ''}
                />
                <span className="tooltiptext" role="tooltip">
                  Recheck status
                  <br />
                  Last update:{' '}
                  {students.flagUpdatingInProgressStudents ? '...' : students.lastUpdateDateTime}
                </span>
              </button>
            </React.Fragment>
          )}
        </div>
        <div className="right">
          <input
            id="input-search"
            className={buttonSearchState ? 'active' : ''}
            value={searchInputText}
            onChange={(e) => setSearchInputText(e.target.value)}
            onKeyUp={handleSearchKeyup}
            ref={refInputSearch}
            placeholder="Search by name"
            onBlur={handleInputSearchBlur}
            type="text"
          />
          <button
            className={`main-header-button tooltip-custom ${buttonSearchState ? 'active' : ''}`}
            id="button-search"
            onClick={handleButtonSearchClick}
          >
            <IconSearch />
            <span className="tooltiptext" role="tooltip">
              Search students
            </span>
          </button>
          <button
            className={`main-header-button tooltip-custom ${
              buttonSortingOptions.isActive ? 'active' : ''
            }`}
            id="sorting-options"
            onClick={() => {
              clickSortingOptionsButton();
            }}
          >
            {buttonSortingOptions.isActive ? <IconSortingUp /> : <IconSortingDown />}
            <span className="tooltiptext right-aligned" role="tooltip">
              {buttonSortingOptions.tooltipText}
            </span>
            <i className="tooltiptext-tail"></i>
          </button>
          <button
            className="main-header-button tooltip-custom"
            id="button-help-guide"
            disabled={appStatus.isHelpGuideButtonDisabled}
            onClick={() => dispatch({ type: 'SET_HELP_GUIDE_VISIBLE', payload: true })}
          >
            <IconHelpGuide />
            <span className="tooltiptext right-aligned" role="tooltip">
              Help guide
            </span>
            <i className="tooltiptext-tail"></i>
          </button>
          <HelpGuide />
        </div>
      </div>
    </div>
  );
};

export default ProctorHeaderToolbar;
