import styles from './CheckboxSelect.module.scss';
import Checkbox from '../Checkbox/Checkbox';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import AlphabetSelectBar from './AlphabetScrollbar/AlphabetSelectBar';
import { alphabet } from './AlphabetScrollbar/util';
import { OverlayLoader } from '@riversideinsights/elevate-react-lib';

export interface CheckboxSelectOption {
  label: string;
  value: string;
}

export interface CheckboxSelectProps {
  className?: string;
  defaultChecked?: boolean;
  loading?: boolean;
  options: CheckboxSelectOption[];
  optionTitle?: string;
  onChange?: (options: CheckboxSelectOption[]) => void;
  disabled?: boolean;
}

const CheckboxSelect = ({
  className,
  defaultChecked,
  loading,
  options: optionsProp,
  optionTitle = 'item',
  onChange,
  disabled = false,
}: CheckboxSelectProps) => {
  const [options, setOptions] = useState<CheckboxSelectOption[]>([]);
  const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [startingLetter, setStartingLetter] = useState<string>('');

  useEffect(() => {
    if (JSON.stringify(optionsProp) !== JSON.stringify(options)) {
      setOptions(optionsProp);
      setCheckedOptions(defaultChecked ? optionsProp.map((option) => option.value) : []);
    }
  }, [optionsProp]);

  useEffect(() => {
    onChange?.(options.filter((option) => checkedOptions.includes(option.value)));
  }, [checkedOptions]);

  const getFilteredOptions = () =>
    options
      .filter((option) => option.label.toLocaleUpperCase().startsWith(startingLetter))
      .filter((option) =>
        option.label.toLocaleUpperCase().includes(searchText.trim().toLocaleUpperCase()),
      );

  return (
    <div className={classNames(styles['checkbox-select'], className)}>
      <div className={styles['header']}>
        <div className={styles['search-container']}>
          <div className={styles['search-input-container']} role="search">
            <input
              autoComplete={'off'}
              disabled={disabled}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={`Search and Select ${optionTitle}${
                optionTitle.endsWith('s') ? 'es' : 's'
              }`}
              spellCheck={false}
              type="text"
              value={searchText}
            />

            <div className={styles['search-icon-container']}>
              {searchText ? (
                <CloseIcon
                  className={styles['search-icon-active']}
                  onClick={() => setSearchText('')}
                />
              ) : (
                <SearchIcon />
              )}
            </div>
          </div>

          <Checkbox
            className={disabled ? 'disabled' : undefined}
            disabled={disabled}
            border
            checked={!getFilteredOptions().find((option) => !checkedOptions.includes(option.value))}
            label="Select All"
            handleChange={(checked) =>
              setCheckedOptions(
                checked
                  ? Array.from(
                      new Set([
                        ...checkedOptions,
                        ...getFilteredOptions().map((option) => option.value),
                      ]),
                    )
                  : checkedOptions.filter(
                      (option) => !getFilteredOptions().find((fo) => fo.value === option),
                    ),
              )
            }
          />
        </div>

        <AlphabetSelectBar
          onChange={(letter) => setStartingLetter(letter)}
          disabled={disabled}
          disabledLetters={alphabet.filter(
            (letter) =>
              !options.find((option) => option.label.toLocaleUpperCase().startsWith(letter)),
          )}
        />
      </div>

      <div
        className={classNames(styles['checkboxes'], {
          [styles['center-content']]: !options.length || loading,
        })}
      >
        {loading ? (
          <OverlayLoader
            title={`Loading ${optionTitle}${optionTitle.endsWith('s') ? 'es' : 's'}`}
          />
        ) : options.length ? (
          getFilteredOptions().map((option) => (
            <Checkbox
              border={true}
              className={styles['checkbox-select-checkbox']}
              checked={checkedOptions.includes(option.value)}
              disabled={disabled}
              handleChange={(checked) =>
                setCheckedOptions(
                  checked
                    ? [...checkedOptions, option.value]
                    : checkedOptions.filter((co) => co !== option.value),
                )
              }
              key={option.value}
              label={option.label}
            />
          ))
        ) : (
          `No ${optionTitle}${optionTitle.endsWith('s') ? 'es' : 's'} found.`
        )}
      </div>
    </div>
  );
};

export default CheckboxSelect;
