import React from 'react';
import { useSelector } from 'react-redux';
import AppDebugDrawerSection from '../AppDebugDrawerSection';

import AppDebugAblyClient from './AppDebugAblyClient';
import { AblyState, AppState } from '../../../../reducers/appDataTypes';
import './AppDebugAbly.css';

export interface AppDebugAblyProps {
  sectionMinimized: boolean;
  toggleSectionMinimized: () => void;
}

const AppDebugAbly: React.FC<AppDebugAblyProps> = (props) => {
  const ablyState: AblyState = useSelector((state: AppState) => {
    return state.ably;
  });
  const staffUser = useSelector((state: AppState) => {
    return state.auth.staffAuth.user;
  });

  const studentUser = useSelector((state: AppState) => {
    return state.auth.studentAuth.user;
  });

  return (
    <AppDebugDrawerSection
      minimized={props.sectionMinimized}
      className="app-debug-section-ably"
      toggleMinimized={props.toggleSectionMinimized}
      title="Ably"
      sectionHeader={
        <div className="app-debug-drawer-section-title session-drawer-section-title">
          <span className="app-debug-drawer-section-title-left">
            {ablyState.ready ? 'ready' : 'not ready'}
          </span>
          <span
            className="app-debug-drawer-section-title-text"
            onClick={props.toggleSectionMinimized}
          >
            Ably
          </span>
          <span className="app-debug-drawer-section-title-right">
            <button
              onClick={props.toggleSectionMinimized}
              className="link-button app-debug-drawer-section-window-control"
              title={props.sectionMinimized ? 'Show section' : 'Hide section'}
            >
              {props.sectionMinimized ? '↧' : '↥'}
            </button>
          </span>
        </div>
      }
    >
      {!(staffUser.loggedIn || studentUser.loggedIn) && (
        <p className="app-debug-section-unavailable">User is not logged in.</p>
      )}
      {(staffUser.loggedIn || studentUser.loggedIn) && (
        <div className="app-debug-ably-contents">
          <AppDebugAblyClient />
        </div>
      )}
    </AppDebugDrawerSection>
  );
};
export default AppDebugAbly;
