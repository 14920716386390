import { AppReduxAction } from '../../appDataTypes';

const initState = {
  buttonState: {
    tooltipText: 'Open sorting options',
    isActive: false,
  },
};

const buttonSortingOptionsReducer = (state = initState, action: AppReduxAction) => {
  switch (action.type) {
    case 'CLICK_SORTING_OPTIONS_BUTTON':
      return {
        ...state,
        buttonState: action.payload,
      };
    default:
      return state;
  }
};

export default buttonSortingOptionsReducer;
