import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// base
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

// hooks
import usePageTitle from '../../../hooks/usePageTitle';
import useCachedUser from '../../../hooks/useCachedUser';
import useAppLogger from '../../../hooks/useAppLogger';
import useApiHelper from '../../../hooks/useApiHelper';

import axios, { AxiosError, CancelTokenSource } from 'axios';
import Dropdown, { IDropdownOption } from '../Dropdown/Dropdown';

import { TestAssignmentListProps } from '../TestAssignment';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SpinnerIcon from '../../shared/icons/LoaderSpinIcon';
import WarningIcon from '@mui/icons-material/Warning';

import './TestAssignmentTrash.css';
import AppAction from '../../shared/AppAction/AppAction';
import useNotificationHelper from '../../../hooks/useNotificationHelper';
import { FaClipboardList, FaTrash } from 'react-icons/fa';
import TestAssignmentTrashTable from './TestAssignmentTrashTable/TestAssignmentTrashTable';
import { Tooltip } from '@mui/material';
import { BooleanFlags, useBooleanFlagWithConfig } from '../../../flags';

let cancelTokenSource: CancelTokenSource | null = null;

const TestAssignmentTrash: React.FC<TestAssignmentListProps> = (props) => {
  const config = useConfigContext();
  const user = useCachedUser();
  const ApiHelper = useApiHelper('Component|TestAssignmentTrash');
  const logger = useAppLogger('Component|TestAssignmentTrash');
  const NotificationHelper = useNotificationHelper();
  usePageTitle('Test Assignments - Trash');
  const { isEnabled: testingIsDisabled } = useBooleanFlagWithConfig(BooleanFlags.DisableTesting);

  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  const [error, setError] = useState(false);

  const [filterYear, setFilterYear] = useState('');
  const [userGrades, setUserGrades] = useState<string[]>([]);
  const [loadingYears, setLoadingYears] = useState(false);
  const [testAssignmentListCount, setTestAssignmentListCount] = useState<string>('0');
  const [testAssignmentTrashCount, setTestAssignmentTrashCount] = useState<string>('0');

  const [yearOptions, setYearOptions] = useState<IDropdownOption[]>([]);

  const handleFilterSchoolYearChange = (options: IDropdownOption[] | IDropdownOption) => {
    const opts: IDropdownOption[] = Array.isArray(options) ? options : [options];
    if (opts.length > 0) {
      setFilterYear(opts[0].value);
    }
  };

  const getDefaultGrades = async () => {
    logger.info("Loading user's grades.");
    try {
      const apiRequestParams = {
        customer_id: user.customerId,
        user_id: user.userId,
        user_role: user.role,
      };
      const result = await ApiHelper.apiRequest('rostering', 'getGradesOfUser', apiRequestParams);
      if (result && result.status === 200) {
        setUserGrades(result.data.grades);
      }
      logger.info("User's grades loaded.");
    } catch (ex: unknown) {
      const err = ex as AxiosError;
      logger.error(`Failed loading user's grades - ${err.message}.`);
    }
  };

  useEffect(() => {
    getDefaultGrades();
  }, []);

  const isPermissionCRUDAssignments = useMemo<boolean>(() => {
    if (user.role !== 'district_admin') return false; //TODO temporary disabled permission for SA and teachers
    return !!user.currentPermissions.includes(72); //permissionName: 'TA-CRUDAssignments'
  }, [user.role, user.currentPermissions]);

  const stopCurrentRequest = (abortMessage?: string) => {
    let msg = config.api.cancelExceptionMessage;
    if (typeof abortMessage !== 'undefined') {
      msg = abortMessage;
    }
    if (cancelTokenRef.current !== null && typeof cancelTokenRef.current.cancel === 'function') {
      cancelTokenRef.current.cancel(msg);
    }
    cancelTokenRef.current = null;
  };

  useEffect(() => {
    if (user.loggedIn && user.loadComplete && user.customerId !== '' && yearOptions.length === 0) {
      loadSchoolYearByCustomer(user.customerId);
    }
  }, [user.loggedIn, user.loadComplete, user.customerId]);

  useEffect(() => {
    logger.info('Mount TestAssignmentTrash');

    return () => {
      logger.info('Unmount TestAssignmentTrash');
      stopCurrentRequest();
    };
  }, []);

  useEffect(() => {
    // school dropdown list size changed, if filterYear is not set
    // auto-set it to first value
    if (yearOptions.length > 0 && filterYear === '') {
      setFilterYear(yearOptions[0].value);
    }
  }, [yearOptions.length]);

  const loadSchoolYearByCustomer = async (customerId: string) => {
    logger.debug('Loading SchoolYearByCustomer');
    if (!loadingYears) {
      try {
        setLoadingYears(true);
        cancelTokenSource = axios.CancelToken.source();
        const apiRequestParams = {
          customerid: customerId,
        };
        const result = await ApiHelper.apiRequest(
          'assignmentApiUrl',
          'SchoolYearByCustomer',
          apiRequestParams,
          {},
          cancelTokenSource,
        );
        cancelTokenSource = null;
        if (result && result.data && result.data.length) {
          let currentYear = new Date().getFullYear();
          if (new Date().getMonth() >= 6) {
            currentYear = currentYear + 1;
          }
          let apiYear = 0;
          if (result.data.indexOf('-') !== -1) {
            apiYear = Number(result.data.substring(0, result.data.indexOf('-')));
          }
          if (currentYear - apiYear >= 7) {
            apiYear = currentYear - 6;
          }
          const yearOptions: IDropdownOption[] = [];
          for (let year = currentYear; year > apiYear; year--) {
            yearOptions.push({
              label: year - 1 + '-' + year,
              value: (year - 1).toString(),
              selected: year === currentYear ? true : false,
            });
          }
          setYearOptions(yearOptions);
          setLoadingYears(false);
          logger.debug('Done loading SchoolYearByCustomer');
        } else {
          logger.error('Error loading SchoolYearByCustomer, no data returned');
          NotificationHelper.add('No data returned for school years', 'error');
          setError(true);
          setLoadingYears(false);
        }
      } catch (ex: unknown) {
        cancelTokenSource = null;
        const err = ex as Error;
        if (err.message !== config.api.cancelExceptionMessage) {
          logger.error(err.message);
          setLoadingYears(false);
          NotificationHelper.add('Failed loading school years.', 'error');
          setError(true);
        }
      }
    }
  };

  const isCreateNewAssignmentAvailable =
    (user.role === 'district_admin' || isPermissionCRUDAssignments) && !testingIsDisabled;

  return (
    <div className="page-wrapper page-root test-assignment-page-root">
      <div className="test-assignment-list-wrapper">
        <div className="test-assignment-actions">
          <div className="dropdown-wrapper">
            {!error ? (
              !loadingYears ? (
                <Dropdown
                  cssClass="year-selector-dropdown"
                  options={yearOptions}
                  placeholder="Filter By School Year: "
                  isPlaceholderStatic={true}
                  onChange={handleFilterSchoolYearChange}
                />
              ) : (
                <div className="dropdown-container-custom year-selector-dropdown">
                  <div className="dropdown-header-custom">
                    <div className="option-label placeholder">Loading school years</div>
                    <SpinnerIcon />
                  </div>
                </div>
              )
            ) : (
              <div className="dropdown-container-custom year-selector-dropdown">
                <div className="dropdown-header-custom">
                  <div className="option-label placeholder">Error loading school years</div>
                  <div className="spinner-wrapper">
                    <WarningIcon className="dropdown-warning-icon" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="test-assignment-buttons">
            {isCreateNewAssignmentAvailable && (
              <RouterLink className="create-new-assignment-button-link" to="/assignment/create">
                <Button color="primary" variant="contained" className="app-button">
                  Create New
                  <AddIcon />
                </Button>
              </RouterLink>
            )}
            <div className="toggle-wrapper">
              <Tooltip title="Test Assignments" arrow={true} placement="bottom">
                <RouterLink to="/assignment/list">
                  <div className="toggle-count-wrapper">
                    <Button
                      variant="contained"
                      className="app-button test-assignment-toggle left-toggle untoggled"
                    >
                      <FaClipboardList color="#096da9" size={'1.25em'} />
                    </Button>
                    <span className="assignments-count">{testAssignmentListCount}</span>
                  </div>
                </RouterLink>
              </Tooltip>
              <Tooltip title="Trash" arrow={true} placement="bottom">
                <div className="toggle-count-wrapper">
                  <Button
                    color="primary"
                    variant="contained"
                    className="app-button test-assignment-toggle right-toggle "
                  >
                    <FaTrash color="#d8e1e9" size={'1.25em'} />
                  </Button>
                  <span className="assignments-count">{testAssignmentTrashCount}</span>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="assignments-table-wrapper">
          {!error && userGrades.length > 0 && (
            <TestAssignmentTrashTable
              filterYear={filterYear}
              setTestAssignmentTrashCount={setTestAssignmentTrashCount}
              setTestAssignmentListCount={setTestAssignmentListCount}
              userGrades={userGrades}
            />
          )}
          {error && (
            <AppAction
              className="app-box-wrapper"
              error={true}
              errorTitle="Error"
              errorSubtitle="Loading failed, please reload the page."
              footer={
                <div className="app-action-footer">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Reload
                  </Button>
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TestAssignmentTrash;
