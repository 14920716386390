import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import TestAssignmentCreate from '../../components/TestAssignment/TestAssignmentCreate/TestAssignmentCreate';
// import CleverComponent from '../../components/Clever/Clevers';

// hooks
// import usePageTitle from '../../hooks/usePageTitle';

type EditAssignmentRouteParams = {
  assignmentId?: string;
};

const Page: React.FC<RouteComponentProps> = (props) => {
  const params = useParams<EditAssignmentRouteParams>();
  const [formType, setFormType] = useState('');

  useEffect(() => {
    if (params?.assignmentId) {
      setFormType('edit');
    } else {
      setFormType('create');
    }
  }, [params?.assignmentId]);

  return (
    <div className="page-wrapper assignments-page">
      {formType !== '' && (
        <TestAssignmentCreate
          key={`${formType}${params?.assignmentId}`}
          title="a"
          type={formType}
        />
      )}
    </div>
  );
};

export default Page;
