import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

import useAppLogger from '../../../../hooks/useAppLogger';
import useCachedUser from '../../../../hooks/useCachedUser';
import useApiHelper from '../../../../hooks/useApiHelper';

import Tooltip from '@mui/material/Tooltip';

import ElevateTable from '../../../shared/ElevateTable/ElevateTable';
import {
  ElevateTableData,
  ElevateTableStatus,
  ElevateTableColumn,
} from '../../../shared/ElevateTable/ElevateTable.d';

import SpinnerIcon from '../../../shared/icons/LoaderSpinIcon';

import './TestAssignmentTrashTable.css';

import Dropdown, { IDropdownOption } from '../../Dropdown/Dropdown';

import { AssignmentListItem } from '../../TestAssignment';

import renderDatesCell from './TableHelpers/renderDatesCell';
import renderGradesCell from './TableHelpers/renderGradesCell';
import axios, { CancelTokenSource } from 'axios';
import { useDispatch } from 'react-redux';
import useNotificationHelper from '../../../../hooks/useNotificationHelper';
import renderTrashDateCell from './TableHelpers/renderTrashDateCell';
import TrashTableFooter from './TrashTableFooter';
import Checkbox from '../../Checkbox/Checkbox';
import { ModalDialog } from '@riversideinsights/elevate-react-lib';
import { Button } from '@mui/material';

export type AssignmentListTableData = ElevateTableData<AssignmentListItem>;
export type AssignmentListTableColumn = ElevateTableColumn<AssignmentListItem>;

export interface ReopenAssignmentData {
  assignmentId: number;
  assignmentName: string;
  modifiedById: string;
  modifiedByName: string;
}

export interface TestAssignmentTrashTableProps {
  filterYear: string;
  userGrades: string[];
  setTestAssignmentTrashCount: React.Dispatch<React.SetStateAction<string>>;
  setTestAssignmentListCount: React.Dispatch<React.SetStateAction<string>>;
}

const TestAssignmentTrashTable: React.FC<TestAssignmentTrashTableProps> = (props) => {
  const config = useConfigContext();
  const ApiHelper = useApiHelper('Component|TestAssignmentTrashTable');
  const NotificationHelper = useNotificationHelper();
  const user = useCachedUser();
  const dispatch = useDispatch();
  const logger = useAppLogger('COMPONENT|TestAssignmentTrashTable');
  const [itemRestored, setItemRestored] = useState<AssignmentListItem | null>(null);

  const tableLoadCancelToken = useRef<CancelTokenSource | null>(null);

  const [typeFilterValues, setTypeFilterValues] = useState<string[]>([]);
  const [assessmentTypeFilterOptions, setAssessmentTypeFilterOptions] = useState<IDropdownOption[]>(
    [
      { label: 'IowaFlex Math', value: 'IowaFlex Math', selected: false },
      { label: 'IowaFlex Reading', value: 'IowaFlex Reading', selected: false },
      { label: 'CogAT Complete Form 7', value: 'CogAT Complete Form 7', selected: false },
      { label: 'CogAT Complete Form 8', value: 'CogAT Complete Form 8', selected: false },
      { label: 'CogAT Screening Form 7', value: 'CogAT Screening Form 7', selected: false },
      { label: 'CogAT Screening Form 8', value: 'CogAT Screening Form 8', selected: false },
      { label: 'CogAT Post-Screener Form 7', value: 'CogAT Post-Screener Form 7', selected: false },
      { label: 'CogAT Post-Screener Form 8', value: 'CogAT Post-Screener Form 8', selected: false },
      {
        label: 'CogAT Screening & Post-Screener Form 7',
        value: 'CogAT Screening & Post-Screener Form 7',
        selected: false,
      },
      {
        label: 'CogAT Screening & Post-Screener Form 8',
        value: 'CogAT Screening & Post-Screener Form 8',
        selected: false,
      },
    ],
  );

  const [statusFilterValues, setStatusFilterValues] = useState<string[]>([]);

  const isPermissionCRUDAssignments = useMemo<boolean>(() => {
    if (user.role !== 'district_admin') return false; //TODO temporary disabled permission for SA and teachers
    return !!user.currentPermissions.includes(72); //permissionName: 'TA-CRUDAssignments'
  }, [user.role, user.currentPermissions]);

  useEffect(() => {
    if (itemRestored) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            key="restore-success-modal"
            className="restore-footer-modal"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            title="Test Assignment Restored"
            body={
              <div className="saved-role-preferences-modal-body">
                <div className="changed-permissions-title">
                  You have successfully restored {itemRestored.assignmentname}. It can be found in
                  the Test Assignments List.
                </div>
              </div>
            }
            footer={
              <RouterLink to="/assignment/list">
                <Button variant="contained" color="primary">
                  Go to Test Assignments List
                </Button>
              </RouterLink>
            }
            onClose={() => setItemRestored(null)}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [itemRestored]);

  const restoreAssignment = async (item: AssignmentListItem) => {
    try {
      setTableData((prevTableData) => ({
        ...prevTableData,
        status: {
          ...prevTableData.status,
          loading: true,
        },
      }));
      const result = await ApiHelper.apiRequest('assignmentApiUrl', 'RestoreAssignments', [
        {
          assignmentId: item.assignmentid,
          assignmentName: item.assignmentname,
          status: 'Restore',
          modifiedById: user.userId,
          modifiedByName: user.name,
          userRole: user.role,
        },
      ]);
      if (!(result && result.data)) {
        NotificationHelper.add('Error restoring assignment', 'error');
        setTableLoading(false);
      } else {
        loadTableData();
        setItemRestored(item);
      }
    } catch (exc: unknown) {
      const ex = exc as Error;
      logger.error(ex);
      NotificationHelper.add('Error restoring assignment', 'error');
      setTableLoading(false);
    }
  };

  const renderActionsCell = (item: AssignmentListItem, column: AssignmentListTableColumn) => {
    if (user.role === 'district_admin' || isPermissionCRUDAssignments) {
      return (
        <span className="assignment-actions">
          <Tooltip title="Restore" arrow={true} placement="left">
            <span onClick={() => restoreAssignment(item)}>Restore</span>
          </Tooltip>
        </span>
      );
    } else {
      return <React.Fragment />;
    }
  };

  const renderSelectedCell = (item: AssignmentListItem, column: AssignmentListTableColumn) => {
    return (
      <Checkbox
        className="trash-table-checkbox"
        checked={item.selected}
        handleChange={(isChecked: boolean) => {
          setTableData((prevState) => ({
            ...prevState,
            items:
              prevState.items?.map((el) => {
                if (el.assignmentid === item.assignmentid) {
                  return { ...el, ...{ selected: isChecked } };
                }
                return { ...el };
              }) ?? prevState.items,
          }));
        }}
      />
    );
  };

  const handleTypeFilterChange = (opts: IDropdownOption[]) => {
    if (opts?.length) {
      setAssessmentTypeFilterOptions(opts);
    }
    const assessmentTypes: string[] = opts.filter((opt) => opt.selected).map((opt) => opt.value);
    setTypeFilterValues(assessmentTypes);
  };

  const initialTableData: AssignmentListTableData = {
    status: {
      loading: true,
      errorTitle: 'Error',
      errorMessage: '',
      sortProp: 'startdate',
      sortDirection: 'desc',
      offset: 0,
      limit: 25,
      totalRecords: 0,
    },
    options: {
      rowsPerPageValues: [5, 10, 25, 50],
      loadingTitle: props?.filterYear === '' ? 'Loading School Years' : 'Loading Assignments',
      loadingMessage: <SpinnerIcon />,
      tableComponents: {
        footer: TrashTableFooter,
      },
    },
    tableProps: {
      stickyHeader: true,
    },
    columns: [
      {
        propName: 'selected',
        propLabel: '',
        renderValue: renderSelectedCell,
        attributes: {
          className: 'column-selected',
        },
      },
      {
        propName: 'assignmenttype',
        propLabel: 'Assessment Type',
        sortable: true,
        filter: true,
        renderFilter: (column: AssignmentListTableColumn) => {
          return (
            <Dropdown
              options={assessmentTypeFilterOptions}
              isOpen={true}
              onChange={handleTypeFilterChange}
              isMultiselect={true}
              showSelectedOptionsHeaderTooltip={true}
              classSelectedOptionsHeaderTooltip="top-aligned"
            />
          );
        },
        attributes: {
          className: 'column-assignmenttype',
        },
      },
      {
        propName: 'assignmentname',
        propLabel: 'Assignment Name',
        sortable: true,
        filter: false,
        attributes: {
          className: 'column-assignmentname',
        },
      },
      {
        propName: 'gradedomains',
        propLabel: 'Grades',
        sortable: true,
        filter: false,
        attributes: {
          className: 'column-gradedomains',
        },
        renderValue: renderGradesCell,
      },
      {
        propName: 'creator',
        propLabel: 'Assignment Creator',
        sortable: true,
        filter: false,
        attributes: {
          className: 'column-creator',
        },
      },
      {
        propName: 'startdate',
        propLabel: 'Test Dates',
        sortable: true,
        filter: false,
        attributes: {
          className: 'column-dates',
        },
        renderValue: renderDatesCell,
      },
      {
        propName: 'trashdate',
        propLabel: 'Trash Date',
        sortable: true,
        filter: false,
        attributes: {
          className: 'column-trash',
        },
        renderValue: renderTrashDateCell,
      },
      {
        propName: 'is_editable',
        propLabel: 'Actions',
        sortable: false,
        filter: false,
        attributes: {
          className: 'column-actions',
        },
        renderValue: renderActionsCell,
      },
    ],
    items: null,
  };

  const [tableData, setTableData] = useState<AssignmentListTableData>(initialTableData);

  useEffect(() => {
    if (
      typeof props.filterYear === 'undefined' ||
      (typeof props.filterYear !== 'undefined' && props.filterYear !== '')
    ) {
      loadTableData();
    }
  }, [
    tableData.status.offset,
    tableData.status.limit,
    tableData.status.sortProp,
    tableData.status.sortDirection,
    typeFilterValues,
    statusFilterValues,
    props?.filterYear,
    props?.userGrades,
  ]);

  const sortGrades = (a: string, b: string) => {
    if (a === 'K') return -1;
    if (b === 'K') return 1;
    if (Number(a) > Number(b)) return 1;
    if (Number(b) > Number(a)) return -1;
    return 0;
  };

  const setTableLoading = (loading = false) => {
    const newTd = {
      ...tableData,
      status: {
        ...tableData.status,
        loading,
        errorMessage: '',
      },
    };
    setTableData(newTd);
  };

  const loadTableData = async () => {
    const currentDate = new Date();
    let currentYear =
      currentDate.getMonth() < 6
        ? `${currentDate.getFullYear() - 1}`
        : `${currentDate.getFullYear()}`;
    if (props?.filterYear && props.filterYear !== '') {
      currentYear = props.filterYear;
    }

    const schoolYear = currentYear + '-' + (Number(currentYear) + 1);
    const sortingDirection = `${tableData.status.sortDirection}`.toUpperCase();
    const sortingField = `${tableData.status.sortProp}`;
    const pagesize = `${tableData.status.limit}`;
    const pagenumber =
      tableData.status.offset > 0
        ? `${1 + Math.ceil(tableData.status.offset / tableData.status.limit)}`
        : '1';

    const res = {
      ...tableData,
      status: {
        ...tableData.status,
        loading: true,
        errorMessage: '',
      },
      options: {
        ...tableData.options,
        loadingTitle: `Loading ${schoolYear} Assignments`,
      },
    };
    setTableData(res);
    if (tableLoadCancelToken.current !== null) {
      tableLoadCancelToken.current.cancel(config.api.cancelExceptionMessage);
      tableLoadCancelToken.current = null;
    }

    const apiRequestParams = {
      customerId: user.customerId,
      userRole: user.role,
      schoolYear: schoolYear,
      assessmentType: typeFilterValues,
      status: statusFilterValues,
      pagenumber,
      pagesize,
      sortingField,
      sortingDirection,
      grades: props.userGrades,
    };

    try {
      tableLoadCancelToken.current = axios.CancelToken.source();
      const result = await ApiHelper.apiRequest(
        'assignmentApiUrl',
        'ViewTrashedAssignmentsByGrade',
        apiRequestParams,
        {},
        tableLoadCancelToken.current,
      );
      tableLoadCancelToken.current = null;
      if (result && result.data) {
        const assignmentTrashCount =
          result.data.totalCount > 99 ? '99+' : result.data.totalCount.toString();
        const assignmentViewCount =
          result.data.viewCount > 99 ? '99+' : result.data.viewCount.toString();
        props.setTestAssignmentTrashCount(assignmentTrashCount);
        props.setTestAssignmentListCount(assignmentViewCount);
        let resultAssignments: AssignmentListItem[] = [];
        if (result.data?.trashedAssignments && Array.isArray(result.data.trashedAssignments)) {
          resultAssignments = result.data.trashedAssignments.map((item: unknown) => {
            const entry = item as AssignmentListItem;
            entry.selected = false;
            let gradeDomains: string[] = [];
            if (typeof entry.gradedomains === 'string' && entry.gradedomains) {
              try {
                gradeDomains = JSON.parse(entry.gradedomains);
              } catch (ex) {
                gradeDomains = [];
              }
            }
            gradeDomains.sort(sortGrades);
            entry.gradedomains = gradeDomains;
            entry.dates = {
              startdate: entry?.startdate ? entry.startdate : '',
              enddate: entry?.enddate ? entry.enddate : '',
              startdatePostScreener: entry?.psstartdate ? entry.psstartdate : '',
              enddatePostScreener: entry?.psenddate ? entry?.psenddate : '',
            };
            return entry;
          });
        }

        const items = resultAssignments;
        const totalRecords = result?.data?.totalCount ? result.data.totalCount : 0;
        const td = {
          ...tableData,
          status: {
            ...tableData.status,
            totalRecords,
            loading: false,
          },
          items,
        };
        setTableData(td);
        logger.debug(
          `Done loading ViewAssignments, ${resultAssignments?.length || '0'} records returned`,
        );
      } else {
        logger.error('Error loading ViewAssignments, no data returned');
      }
    } catch (ex: unknown) {
      tableLoadCancelToken.current = null;
      const err = ex as Error;
      if (err.message !== config.api.cancelExceptionMessage) {
        logger.error(err.message);
        const newData: ElevateTableData<AssignmentListItem> = {
          ...tableData,
          status: {
            ...tableData.status,
            errorMessage: 'Could not load assignments.',
            loading: false,
          },
          items: null,
        };
        setTableData(newData);
      }
    }
  };

  const handleTableChange = (tableStatusUpdates: ElevateTableStatus) => {
    if (tableData !== null) {
      const newData: ElevateTableData<AssignmentListItem> = {
        ...tableData,
        status: {
          ...tableData.status,
          ...tableStatusUpdates,
        },
      };
      setTableData(newData);
    }
  };

  return (
    <div className="test-assignment-trash-table elevate-table">
      <ElevateTable tableData={tableData} onChange={handleTableChange} />
    </div>
  );
};

export default TestAssignmentTrashTable;
