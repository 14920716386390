import { useEffect, useState } from 'react';
import { BooleanFlags } from '../useBooleanFlagWithConfig/useBooleanFlagWithConfig.types';
import useBooleanFlagWithConfig from '../useBooleanFlagWithConfig/useBooleanFlagWithConfig';
import { RosteringFlagResult, RosteringFlagConfig } from './types';
import useCachedUser from '../../hooks/useCachedUser';

/**
 * The Rostering feature flag is enabled or disabled, but additional configuration will determine
 * if the Errors and Warning page and the View Hierarchy page are enabled.
 *
 * In addition, we may decide to build treatments specific to customers where one or other of these
 * tabs are enabled.
 *
 * This hook is meant to wrap up the logic of parsing these values from split, so components can
 * just use the resulting true/false values.
 *
 * @returns {RosteringFlagResult} result - the current state of the rostering feature flag
 */
const useRosteringFlag = (): RosteringFlagResult => {
  const [hookState, setHookState] = useState<RosteringFlagResult>({
    enabled: false,
    importEnabled: false,
    errorsEnabled: false,
    hierarchyEnabled: false,
    menuNavigationVisible: false,
  });
  const user = useCachedUser();

  const { isEnabled: flagEnabled, config } = useBooleanFlagWithConfig<RosteringFlagConfig>(
    BooleanFlags.Rostering,
  );

  // TODO: this needs to be improved and be based on permissions, not role
  const hasPermissions = () => user.role === 'district_admin';

  useEffect(() => {
    if (user.loadComplete) {
      const enabled = flagEnabled;
      const importEnabled = hasPermissions() && flagEnabled;
      const errorsEnabled = hasPermissions() && config?.errors === 'true' && flagEnabled;
      const hierarchyEnabled = config?.hierarchy === 'true' && flagEnabled;
      const menuNavigationVisible =
        flagEnabled && (importEnabled || errorsEnabled || hierarchyEnabled);

      setHookState({
        enabled,
        importEnabled,
        errorsEnabled,
        hierarchyEnabled,
        menuNavigationVisible,
      });
    }
  }, [flagEnabled, config, user.loadComplete]);

  return hookState;
};

export default useRosteringFlag;
