import React from 'react';
import { AppError } from '@elevate-ui/react-lib';
import usePageTitle from '../../hooks/usePageTitle';
import useCachedUser from '../../hooks/useCachedUser';

function UnauthorizedError() {
  usePageTitle('Not Authorized');
  const user = useCachedUser();

  return (
    <AppError
      title="Not Authorized"
      message="The request has not been applied because it lacks valid authentication credentials for the target resource."
      iconType="unauthorized"
      showBackButton
      actions={[
        {
          children: 'Log In',
          href: '/administration/auth/logout',
          renderCondition: !user.loggedIn,
        },
      ]}
    />
  );
}

export default UnauthorizedError;
