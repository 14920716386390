import { ICognitoStorage } from 'amazon-cognito-identity-js';

/**
 * Elevate UI storage class for handling Cognito storage
 *
 * @class ElevateStorage
 * @implements {ICognitoStorage}
 *
 */
class ElevateStorage implements ICognitoStorage {
  storageInterface: typeof sessionStorage | typeof localStorage | null = null;
  cacheType = '';

  /**
   * Class constructor
   *
   * Sets up local values relying on arg value and configuration
   *
   * @param {'localStorage'|'sessionStorage'} storageType Type of storage
   *
   * @returns {undefined}
   */
  constructor(storageType: string) {
    if (storageType === 'sessionStorage') {
      this.storageInterface = sessionStorage;
    } else if (storageType === 'localStorage') {
      this.storageInterface = localStorage;
    }
    if (this.storageInterface === null) {
      throw new Error('Failed initializing ElevateStorage');
    }
  }

  /**
   * Returns stored item value
   *
   * @param {string} k Key of stored item
   *
   * @returns {string|null} Value of stored item or null
   */
  getItem(k: string): string | null {
    if (this.storageInterface !== null) {
      return this.storageInterface.getItem(k);
    } else {
      throw new Error('Elevate storage interface invalid.');
    }
  }

  /**
   * Stores value in storage
   *
   * @param {string} k  Storage key
   * @param {string} v  Stored value
   *
   * @returns {undefined}
   */
  setItem(k: string, v: string): void {
    if (this.storageInterface !== null) {
      this.storageInterface.setItem(k, v);
    } else {
      throw new Error('Elevate storage interface invalid.');
    }
  }

  /**
   * Removes item from storage
   *
   * @param {string} k  Item key
   *
   * @returns {undefined}
   */
  removeItem(k: string): void {
    if (this.storageInterface !== null) {
      this.storageInterface.removeItem(k);
    } else {
      throw new Error('Elevate storage interface invalid.');
    }
  }

  /**
   * Clears the storage
   *
   * @returns {undefined}
   */
  clear(): void {
    if (this.storageInterface !== null) {
      this.storageInterface.clear();
    } else {
      throw new Error('Elevate storage interface invalid.');
    }
  }
}

export default ElevateStorage;
