import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: '/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf' },
    { src: '/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf', fontWeight: 700 },
    { src: '/fonts/Open_Sans/static/OpenSans/OpenSans-ExtraBold.ttf', fontWeight: 800 },
  ],
});

export const documentStyles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    paddingVertical: 35,
    paddingHorizontal: 35,
    fontSize: 11,
    fontFamily: 'Open Sans',
  },
  header: {
    width: '100%',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    marginVertical: 10,
  },
  ticketContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '25%',
    width: '33.3%',
    paddingVertical: 10,
  },
  ticket: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '95%',
    height: '100%',
  },
  studentNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 10,
  },
  studentName: {
    fontSize: 12,
    fontWeight: 700,
    marginRight: 4,
  },
  ticketItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 3,
  },
  ticketItemIcon: {
    width: 13,
    height: 13,
    marginRight: 8,
  },
  ticketItemIconSmall: {
    width: 11,
    height: 11,
    marginRight: 10,
  },
  ticketItemText: {
    width: '80%',
    fontSize: 11,
  },
  ticketItemTextPlaceholder: {
    height: '100%',
    width: '50%',
    borderBottom: '1px solid black',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 'auto',
  },
  date: {
    marginLeft: 'auto',
  },
});
