import { CancelTokenSource } from 'axios';

import {
  StaffIdentity,
  StudentAuthUser,
  StudentCognitoIdToken,
  StudentInfo,
} from '../../reducers/Auth/authDataTypes';

import HelperBase from '../HelperBase';
import LogHelper from './LogHelper';
import AppHelper from './AppHelper';

import * as AWS from 'aws-sdk/global';

import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  IAuthenticationDetailsData,
} from 'amazon-cognito-identity-js';

import { AppStatusUpdate } from '../../reducers/appDataTypes';

import ElevateStorage from '../ElevateStorage';
import { Config } from '@elevate-ui/config';

export interface DispatcherAction {
  type: string;
  payload:
    | AppStatusUpdate
    | boolean
    | string
    | StudentAuthUser
    | Partial<StudentAuthUser>
    | CognitoUserSession
    | null;
}

class StudentUserHelper extends HelperBase {
  static cancelTokenSource: CancelTokenSource | null = null;

  /**
   * Initializes and returns empty user with data structure required by types
   *
   * @static
   * @returns {StudentAuthUser} Initialized empty user
   */
  static initializeStudentUser = (): StudentAuthUser => {
    const user: StudentAuthUser = {
      studentId: '',
      proctorCode: '',
      role: '',
      loggedIn: false,
      loadComplete: false,
      ssoUser: false,
      cleverDistrictId: '',
      identity: {
        accessToken: '',
        expirationTime: '',
        idToken: '',
        issuedTime: '',
        refreshToken: '',
      },
      info: {
        adminCode: '',
        customerId: '',
        district: '',
        districtExternalId: '',
        dob: '',
        dobFormat: '',
        firstName: '',
        gender: '',
        globalStudentId: '',
        grade: '',
        hispanicEthnicity: '',
        isKioskBrowserEnabled: false,
        lastName: '',
        middleName: '',
        officeUse: '',
        proctorCode: '',
        proctorGlobalId: '',
        program504: '',
        programCode: null,
        programFree: '',
        programGT: '',
        programIEP: '',
        programMath: '',
        programMigrants: '',
        programOther: '',
        programReading: '',
        programSE: '',
        programT1: null,
        race: null,
        school: '',
        sisId: '',
        externalId: '',
        studentExternalId: '',
        stateId: '',
      },
    };
    return user;
  };

  static getElevateStorage = (config: Config): ElevateStorage => {
    return new ElevateStorage(config.session.cacheType);
  };

  /**
   * Logs student users to cognito, parses idToken and
   * returns user object with populated data.
   *
   *
   * @static
   * @async
   * @param {string} username           Username for login (studentId from login form)
   * @param {string} pass               Password for login (proctorCode from login form)
   * @param {string} organization       Organization for login (organizationId from login form)
   * @param {React.Dispatch} dispatch   React store dispatch
   * @param {Config} config configuration object
   *
   * @returns {StudentAuthUser}         Student user
   */
  static authenticateCognitoUser = async (
    username: string,
    pass: string,
    organization: string,
    dispatch: React.Dispatch<DispatcherAction>,
    config: Config,
  ): Promise<StudentAuthUser> => {
    const proctorCode = pass.replace(/-/g, '');
    let user = StudentUserHelper.initializeStudentUser();
    LogHelper.info(config, `Authenticating student ${username}`, 'HELPER:StudentUserHelper');
    StudentUserHelper.setProctorCodeCookie(proctorCode, config);
    const cognitoUser: CognitoUser = await StudentUserHelper.loginCognitoUser(
      username,
      proctorCode,
      organization,
      config,
    );
    if (cognitoUser) {
      const cognitoUserSession = cognitoUser.getSignInUserSession();
      if (cognitoUserSession !== null) {
        const idToken = `${cognitoUser.getSignInUserSession()?.getIdToken()?.getJwtToken()}`;
        if (idToken) {
          StudentUserHelper.setCognitoSession(cognitoUserSession, dispatch);
          LogHelper.info(
            config,
            `Student ${username} authenticated with Cognito`,
            'HELPER:StudentUserHelper',
          );
          StudentUserHelper.setIdTokenCookie(idToken, config);
          const newIdentity =
            StudentUserHelper.getUserIdentityFromCognitoSession(cognitoUserSession);

          // we're not getting this in idToken, so it's cached locally
          const studentProctorCode = proctorCode;
          // StudentUserHelper.setProctorCodeCookie(studentProctorCode);

          user.studentId = username;
          user.proctorCode = studentProctorCode;
          // user.proctorCode = '';
          user.role = 'student';
          user.loggedIn = true;
          user.identity = newIdentity;
          // user = await StudentUserHelper.populateRosteringData(user);
          user = StudentUserHelper.populateStudentIdTokenData(user);
          user.info.proctorCode = studentProctorCode;
          user.loadComplete = true;
          LogHelper.debug(
            config,
            `Parsing idToken for student ${username}`,
            'HELPER:StudentUserHelper',
          );
          // LogHelper.debug({...user.info}, 'HELPER:StudentUserHelper');
        } else {
          StudentUserHelper.clearProctorCodeCookie(config);
          LogHelper.warning(
            config,
            `Couldn't get idToken for student ${username} from Cognito`,
            'HELPER:StudentUserHelper',
          );
        }
      } else {
        StudentUserHelper.clearProctorCodeCookie(config);
        LogHelper.warning(
          config,
          `Couldn't get cognito session for student ${username}`,
          'HELPER:StudentUserHelper',
        );
      }
    } else {
      StudentUserHelper.clearProctorCodeCookie(config);
    }
    return user;
  };

  /**
   * Calls cognito to log in student user and returns cognitoUser
   * This is not a complete login for Elevate, use authenticateCognitoUser for that.
   *
   * @param {string} studentId    Student id from login form
   * @param {string} proctorCode  Proctor code from login form
   * @param {string} organization Organization id from login form
   * @param {Config} config configuration object
   * @returns {CognitoUser}       Cognito user object
   */
  static loginCognitoUser = async (
    studentId: string,
    proctorCode: string,
    organization: string,
    config: Config,
  ): Promise<CognitoUser> => {
    const customerId = organization;
    const username = `${customerId}-${studentId}`;
    LogHelper.debug(
      config,
      `Calling cognito for student user "${username}" (proctorCode: ${proctorCode})`,
      'HELPER:StudentUserHelper',
    );
    const authenticationData: IAuthenticationDetailsData = {
      Username: username,
      [`${[80, 97, 115, 115, 119, 111, 114, 100].map((v) => String.fromCharCode(v)).join('')}`]:
        config.studentAwsConfig.customField,
      ClientMetadata: {
        proctorCode: `${proctorCode}`,
      },
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userPool: CognitoUserPool = StudentUserHelper.getCognitoUserPool(config);
    const userData = {
      Username: username,
      Pool: userPool,
      Storage: StudentUserHelper.getElevateStorage(config),
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result: CognitoUserSession) {
          LogHelper.debug(
            config,
            `Successful call to cognito for student user "${username}" (proctorCode: ${proctorCode})`,
            'HELPER:StudentUserHelper',
          );
          resolve(cognitoUser);
        },
        onFailure: function (err: Error) {
          LogHelper.warning(
            config,
            `Failed call to cognito for student user "${username}" (proctorCode: ${proctorCode}): "${err.message}"`,
            'HELPER:StudentUserHelper',
          );
          reject(err);
        },
      });
    });
  };

  static initAwsCredentials = (
    config: Config,
    loginId?: string,
  ): AWS.CognitoIdentityCredentials => {
    LogHelper.debug(
      config,
      'Initializing student AWS CognitoIdentityCredentials.',
      'HELPER:StudentUserHelper',
    );
    const credentialOptions: AWS.CognitoIdentityCredentials.CognitoIdentityOptions = {
      IdentityPoolId: config.studentAwsConfig.IdentityPoolId,
      Logins: {
        // Change the key below according to the specific region your user pool is in.
        // [`cognito-idp.${config.studentAwsConfig.region}.amazonaws.com/${config.studentAwsConfig.UserPoolId}`]: result.getIdToken().getJwtToken(),
      },
      // LoginId: `studentUser_${loginId}`,
    };
    if (typeof loginId === 'string' && loginId.length > 0) {
      credentialOptions.LoginId = `studentUser_${loginId}`;
    }
    const credentials = new AWS.CognitoIdentityCredentials(credentialOptions);
    return credentials;
  };

  /**
   * Populates user data with parsed idToken
   *
   * @param {StudentAuthUser} user    User object
   * @param {string}          newIdToken IdToken
   * @returns {StudentAuthUser} User object with data from idToken
   */
  static populateStudentIdTokenData = (
    user: StudentAuthUser,
    newIdToken?: string,
  ): StudentAuthUser => {
    const idToken = typeof newIdToken === 'string' ? newIdToken : user.identity.idToken;
    if (idToken !== '') {
      const studentTokenData: StudentCognitoIdToken | null =
        StudentUserHelper.parseStudentToken(idToken);
      if (studentTokenData !== null) {
        const studentInfo: StudentInfo = {
          customerId: studentTokenData.customerId,
          district: studentTokenData.districtId,
          districtExternalId: studentTokenData.districtExternalId,
          dob: studentTokenData.dob,
          dobFormat: studentTokenData.dobFormat,
          firstName: studentTokenData.firstName,
          gender: studentTokenData.gender,
          globalStudentId: studentTokenData.globalStudentId,
          grade: studentTokenData.grade,
          lastName: studentTokenData.lastName,
          middleName: studentTokenData.middleName,
          proctorCode: user.proctorCode,
          sisId: studentTokenData.externalId,
          externalId: studentTokenData.externalId,
          studentExternalId: studentTokenData.externalId,

          isKioskBrowserEnabled:
            typeof studentTokenData.isRespondus === 'undefined'
              ? false
              : studentTokenData.isRespondus === 'true', // convert string value from backend to boolean
        };
        user.info = studentInfo;
      }

      if (studentTokenData?.['custom:district']) {
        user.cleverDistrictId = studentTokenData['custom:district'];
      }
    }
    return user;
  };

  /**
   * Helper method to get user pool for configuring cognito
   * @static
   * @param {Config} config configuration object
   * @returns {CognitoUserPool} User pool for students
   */
  static getCognitoUserPool = (config: Config): CognitoUserPool => {
    const poolData = {
      UserPoolId: config.studentAwsConfig.UserPoolId,
      ClientId: config.studentAwsConfig.ClientId,
      Storage: StudentUserHelper.getElevateStorage(config),
      // Storage: new CookieStorage({domain: config.local ? 'localhost' : '.elevate.riverside-insights.com'}),
    };
    return new CognitoUserPool(poolData);
  };

  /**
   * Returns currently logged in cognito user
   * @static
   * @param {Config} config configuration object
   * @returns {CognitoUser|null} Currently logged in user or null if none
   */
  static getCognitoUser = (config: Config): CognitoUser | null => {
    const userPool = StudentUserHelper.getCognitoUserPool(config);
    return userPool.getCurrentUser();
  };

  static getIdTokenCookie = (config: Config): string => {
    return AppHelper.getCookieValue(config.session.student.idTokenCookie);
  };

  /**
   * Sets student id token in cookie
   *
   * @param {string} idToken idToken value
   * @param {Config} config configuration object
   * @returns {void}
   */
  static setIdTokenCookie = (idToken: string, config: Config) => {
    AppHelper.setCookie(config.session.student.idTokenCookie, idToken, 0, true, '/');
  };

  /**
   * Clears student id token from cookie
   * @param {Config} config configuration object
   * @returns {void}
   */
  static clearIdTokenCookie = (config: Config) => {
    AppHelper.removeCookie(config.session.student.idTokenCookie, true, '/');
  };

  static getProctorCodeCookie = (config: Config): string => {
    return AppHelper.getCookieValue(config.session.student.proctorCodeCookie);
  };

  /**
   * Sets student id token in cookie
   *
   * @param {string} idToken idToken value
   * @param {Config} config configuration object
   * @returns {void}
   */
  static setProctorCodeCookie = (idToken: string, config: Config) => {
    AppHelper.setCookie(config.session.student.proctorCodeCookie, idToken, 0, true, '/');
  };

  /**
   * Clears student id token from cookie
   * @param {Config} config configuration object
   * @returns {void}
   */
  static clearProctorCodeCookie = (config: Config) => {
    AppHelper.removeCookie(config.session.student.proctorCodeCookie, true, '/');
  };

  /**
   * Parses student id token and returns decoded data or null
   *
   * @static
   * @param {string} token Student idToken
   *
   * @returns {StudentCognitoIdToken|null} Decoded data from student id token or null if none/invalid
   */
  static parseStudentToken = (token: string): StudentCognitoIdToken | null => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } catch (ex) {
      // LogHelper.error(ex, 'AppHelper');
      return null;
    }
  };

  static setCognitoSession = (
    newSession: CognitoUserSession | null,
    dispatch: React.Dispatch<DispatcherAction>,
  ) => {
    dispatch({ type: 'SET_STUDENT_COGNITO_USER_SESSION', payload: newSession });
  };

  /**
   * Returns cognito session (will refresh it if required prior)
   *
   * @static
   * @async
   * @param {Config} config configuration object
   * @returns {CognitoUserSession|null} Cognito session or null if none
   */
  static getCognitoSession = async (config: Config): Promise<CognitoUserSession | null> => {
    const cognitoUser = StudentUserHelper.getCognitoUser(config);
    // const user = StudentUserHelper.initializeStudentUser();
    if (cognitoUser !== null) {
      return new Promise((resolve, reject) => {
        cognitoUser.getSession((err: unknown | null, session: CognitoUserSession) => {
          if (err) {
            reject(err);
          } else {
            // console.log(session);
            resolve(session);
          }
        });
      });
    } else {
      return Promise.resolve(null);
    }
  };

  /**
   * Validates and returns cognito session (will refresh it if required prior)
   *
   * @static
   * @async
   * @param {Config} config configuration object
   * @returns {CognitoUserSession|null} Cognito session or null if none
   */
  static validateStudentCognitoSession = async (
    config: Config,
  ): Promise<CognitoUserSession | null> => {
    const cognitoSession = await StudentUserHelper.getCognitoSession(config);
    return cognitoSession;
  };

  /**
   * Caches user data locally for quick reuse in UI apps
   *
   * @param {StudentAuthUser}   newUser     User to store
   * @param {Config} config configuration object
   * @returns {undefined}
   */
  static storeLocalUser = (newUser: StudentAuthUser, config: Config) => {
    const cookieIdToken = `${AppHelper.getCookieValue(config.session.student.idTokenCookie)}`;
    if (
      newUser.identity.idToken &&
      (!cookieIdToken || (cookieIdToken && cookieIdToken !== newUser.identity.idToken))
    ) {
      LogHelper.info(config, 'Updating student idtoken cookie', 'HELPER:StudentUserHelper');
      StudentUserHelper.setIdTokenCookie(newUser.identity.idToken, config);
      // AppHelper.setCookie(config.session.student.idTokenCookie, newUser.identity.idToken);
    }

    if (!newUser.identity.idToken) {
      if (cookieIdToken) {
        LogHelper.warn(config, 'Updating COOKIE STUDENT idtoken', 'HELPER:StudentUserHelper');
        newUser.identity.idToken = cookieIdToken;
      }
    }
    if (config.session.student.cacheUser) {
      let userJson = '';
      try {
        userJson = JSON.stringify({
          ...newUser,
        });
      } catch (ex: unknown) {
        const err = ex as Error;
        LogHelper.error(
          `Error serializing user cache: "${err.message}"`,
          'HELPER|StudentUserHelper',
        );
        // UserHelper.clearLocalUser();
      }
      if (userJson) {
        LogHelper.debug(
          config,
          'Caching student user data in storage.',
          'HELPER:StudentUserHelper',
        );
        const elevateStorage = StudentUserHelper.getElevateStorage(config);
        elevateStorage.setItem(config.session.student.userCacheKey, userJson);
      }
    }
  };

  static getLocalUser = (config: Config): StudentAuthUser | null => {
    let userString: string | null = null;
    const cacheKey = config.session.student.userCacheKey;
    const elevateStorage = StudentUserHelper.getElevateStorage(config);
    userString = elevateStorage.getItem(cacheKey);

    // if (!userString && config.local) {
    //   userString = decodeURIComponent(AppHelper.getCookieValue(config.session.student.userCacheKey));
    // }

    let result: StudentAuthUser | null = null;
    if (userString && typeof userString === 'string') {
      try {
        result = JSON.parse(userString) as StudentAuthUser;
        if (!result.identity.idToken) {
          const cookieToken = AppHelper.getCookieValue(config.session.student.idTokenCookie);
          if (cookieToken) {
            result.identity.idToken = cookieToken;
          }
        }
      } catch (ex: unknown) {
        const err = ex as Error;
        LogHelper.error(`Error parsing local user: ${err.message}`);
        // UserHelper.clearLocalUser();
      }
    }
    return result;
  };

  /**
   * Clears student user from cookies and cache
   *
   * @static
   * @param {Config} config configuration object
   * @returns {void}
   */
  static clearLocalUser = (config: Config) => {
    const elevateStorage = StudentUserHelper.getElevateStorage(config);
    elevateStorage.removeItem(config.session.student.userCacheKey);
    StudentUserHelper.clearIdTokenCookie(config);
    StudentUserHelper.clearProctorCodeCookie(config);
  };

  static overwriteUser = (
    newUser: StudentAuthUser,
    dispatch: React.Dispatch<DispatcherAction>,
    config: Config,
  ) => {
    StudentUserHelper.storeLocalUser(newUser, config);
    dispatch({ type: 'SET_STUDENT_USER', payload: newUser });
  };

  static updateUser = (
    userUpdates: Partial<StudentAuthUser>,
    dispatch: React.Dispatch<DispatcherAction>,
    config: Config,
  ) => {
    let oldUser = StudentUserHelper.getLocalUser(config);
    if (oldUser === null) {
      oldUser = StudentUserHelper.initializeStudentUser();
    }

    const newUser: StudentAuthUser = {
      ...oldUser,
      ...userUpdates,
    };
    // StudentUserHelper.setStudentUser(newUser);
    StudentUserHelper.storeLocalUser(newUser, config);
    dispatch({ type: 'SET_STUDENT_USER', payload: newUser });
  };

  static logoutUser = (
    config: Config,
    dispatch?: React.Dispatch<DispatcherAction>,
  ): StudentAuthUser => {
    return StudentUserHelper.logoutStudentUser(config, dispatch);
  };

  /**
   * Logs student users out
   *
   * @static
   * @param {Config} config configuration object
   * @param {React.Dispatch} dispatch   React store dispatch
   *
   * @returns {StudentAuthUser} Empty student user
   */
  static logoutStudentUser = (
    config: Config,
    dispatch?: React.Dispatch<DispatcherAction>,
  ): StudentAuthUser => {
    LogHelper.info(config, 'Logging out student user...', 'HELPER:StudentUserHelper');
    const cognitoUser = StudentUserHelper.getCognitoUser(config);
    if (cognitoUser !== null) {
      cognitoUser.signOut();
    }
    StudentUserHelper.clearLocalUser(config);
    const newStudentUser = StudentUserHelper.initializeStudentUser();
    if (dispatch) {
      StudentUserHelper.setCognitoSession(null, dispatch);
      // dispatch({ type: 'SET_STUDENT_COGNITO_USER_SESSION', payload: null });
      dispatch({ type: 'SET_STUDENT_USER', payload: newStudentUser });
    }
    LogHelper.info(config, 'Student user logged out', 'HELPER:StudentUserHelper');
    return newStudentUser;
  };

  static refreshStudentUserToken = async (
    user: StudentAuthUser,
    dispatch: React.Dispatch<DispatcherAction>,
    force = false,
    config: Config,
  ): Promise<CognitoUser> => {
    LogHelper.info(
      config,
      `Refreshing idToken${force === true ? ' (forced)' : ''} for student user ${user.studentId}`,
      'HELPER:StudentUserHelper',
    );
    StudentUserHelper.updateUser({ loadComplete: false }, dispatch, config);

    const credentials = StudentUserHelper.initAwsCredentials(
      config,
      `${user.info.customerId}-${user.studentId}`,
    );
    const cognitoUser = StudentUserHelper.getCognitoUser(config);
    const session = await StudentUserHelper.getCognitoSession(config);
    let newIdToken = '';
    let userUpdates: Partial<StudentAuthUser> | null = null;
    return new Promise((resolve, reject) => {
      if (session !== null && cognitoUser !== null) {
        const refreshToken = session.getRefreshToken(); // receive session from calling cognitoUser.getSession()
        if (force || credentials.needsRefresh()) {
          cognitoUser.refreshSession(
            refreshToken,
            (err: unknown | null, updatedSession: CognitoUserSession) => {
              if (err) {
                reject(err);
              } else {
                newIdToken = updatedSession.getIdToken().getJwtToken();
                if (newIdToken) {
                  // StudentUserHelper.setIdTokenCookie(newIdToken);
                  const newIdentity = {
                    accessToken: updatedSession.getAccessToken().getJwtToken(),
                    expirationTime: updatedSession.getIdToken().getExpiration().toLocaleString(),
                    idToken: newIdToken,
                    issuedTime: updatedSession.getIdToken().getIssuedAt().toLocaleString(),
                    refreshToken: updatedSession.getRefreshToken().getToken(),
                  };
                  userUpdates = {
                    identity: newIdentity,
                    loadComplete: true,
                  };
                  // dispatch({ type: 'SET_STUDENT_COGNITO_USER_SESSION', payload: updatedSession });
                  StudentUserHelper.setCognitoSession(updatedSession, dispatch);
                  if (userUpdates !== null) {
                    StudentUserHelper.updateUser(userUpdates, dispatch, config);
                  } else {
                    StudentUserHelper.updateUser({ loadComplete: true }, dispatch, config);
                  }
                  resolve(cognitoUser);
                } else {
                  reject(new Error('No new id token found'));
                }
              }
            },
          );
        } else {
          resolve(cognitoUser);
        }
      } else {
        reject(new Error('No cognito session to refresh'));
      }
    });
  };

  static refreshStudentUser = async (
    appUser: StudentAuthUser,
    dispatch: React.Dispatch<DispatcherAction>,
    force = false,
    config: Config,
  ): Promise<StudentAuthUser> => {
    const cognitoUser = await StudentUserHelper.refreshStudentUserToken(
      appUser,
      dispatch,
      undefined,
      config,
    );
    if (cognitoUser !== null) {
      return new Promise((resolve, reject) => {
        cognitoUser.getSession((err: unknown | null, session: CognitoUserSession) => {
          if (err) {
            LogHelper.error(err, 'HELPER:StudentUserHelper');
            // console.log(err);
            reject(err);
          } else {
            LogHelper.info(config, 'Retrieved student cognito session', 'HELPER:StudentUserHelper');
            // LogHelper.info(session, 'HELPER:StudentUserHelper');
            const idToken = session.getIdToken().getJwtToken();
            if (idToken) {
              // dispatch({ type: 'SET_STUDENT_COGNITO_USER_SESSION', payload: session });
              StudentUserHelper.setCognitoSession(session, dispatch);
              const initUser = StudentUserHelper.initializeStudentUser();
              initUser.proctorCode = appUser.proctorCode;
              initUser.role = appUser.role;
              initUser.identity.idToken = idToken;
              const newUser = StudentUserHelper.populateStudentIdTokenData(initUser);
              newUser.loggedIn = appUser.loggedIn;
              resolve(newUser);
            } else {
              reject(new Error('Could not refresh sidToken'));
              // resolve(appUser);
            }
          }
        });
      });
    }
    return Promise.resolve(appUser);
  };

  static getUserIdentityFromCognitoSession = (session: CognitoUserSession): StaffIdentity => {
    const newIdentity = {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken(),
      expirationTime: session.getIdToken().getExpiration().toLocaleString(),
      issuedTime: session.getIdToken().getIssuedAt().toLocaleString(),
    };
    return newIdentity;
  };

  static cloneStudentUser = (oldUser: StudentAuthUser): StudentAuthUser => {
    const newUser = {
      ...oldUser,
      info: {
        ...oldUser.info,
      },
      identity: {
        ...oldUser.identity,
      },
    };
    return newUser;
  };
}

export default StudentUserHelper;
