import styles from './TACloseReopenSection.module.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useApiHelper from '../../../../hooks/useApiHelper';
import useAppLogger from '../../../../hooks/useAppLogger';
import useCachedUser from '../../../../hooks/useCachedUser';
import { ModalDialog } from '@riversideinsights/elevate-react-lib';
import { Button } from '@mui/material';
import SpinnerIcon from '../../../shared/icons/SpinnerIcon';
import useNotificationHelper from '../../../../hooks/useNotificationHelper';

interface TACloseReopenProps {
  testAssignmentCRUD: boolean;
  testAssignmentId: number;
  testAssignmentStatus: string;
  testAssignmentStartDate: string;
  testAssignmentName: string;
  testAssignmentCurrentlyTesting: boolean;
}

const TACloseReopenSection = ({
  testAssignmentCRUD,
  testAssignmentId,
  testAssignmentStatus,
  testAssignmentStartDate,
  testAssignmentName,
  testAssignmentCurrentlyTesting,
}: TACloseReopenProps) => {
  const ApiHelper = useApiHelper('Component|TACloseReopenSection');
  const NotificationHelper = useNotificationHelper();
  const logger = useAppLogger('Component|TACloseRepenSection');

  const [showCloseModal, setShowCloseModal] = useState(false);
  const [closingAssignment, setClosingAssignment] = useState(false);

  const [showReopenModal, setShowReopenModal] = useState(false);
  const [reopeningAssignment, setReopeningAssignment] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useCachedUser();

  const closeTestAssignment = async (assignmentId: number, assignmentName: string) => {
    try {
      setClosingAssignment(true);
      //const result = await ApiHelper.apiRequest('assignmentApiUrl', 'CloseAssignmentByAssignmentId', {assignmentId: assignmentId, status: 'Closed'});
      const result = await ApiHelper.apiRequest('assignmentApiUrl', 'UpdateAssignmentStatus', {
        assignmentId: assignmentId,
        assignmentName: assignmentName,
        status: 'Closed',
        modifiedById: '' + user.userId,
        modifiedByName: user.name,
      });
      setShowCloseModal(false);
      setClosingAssignment(false);
      if (result && typeof result.data === 'boolean' && result.data === true) {
        history.push('/assignment/list');
      } else {
        NotificationHelper.add('Your test assignment failed to close.', 'warning'); // 5000, GP1-11848
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      logger.error(err);
      NotificationHelper.add('Error closing your test assignment.', 'warning'); // 5000, GP1-11848
      setShowCloseModal(false);
      setClosingAssignment(false);
    }
  };

  const reopenTestAssignment = async (assignmentId: number, assignmentName: string) => {
    try {
      setReopeningAssignment(true);
      //const result = await ApiHelper.apiRequest('assignmentApiUrl', 'ReopenAssignmentByAssignmentId', {assignmentId: assignmentId, status: 'Open'});
      const result = await ApiHelper.apiRequest('assignmentApiUrl', 'UpdateAssignmentStatus', {
        assignmentId: assignmentId,
        assignmentName: assignmentName,
        status: 'Reopen',
        modifiedById: user.userId,
        modifiedByName: user.name,
      });
      setShowReopenModal(false);
      setReopeningAssignment(false);
      if (result && typeof result.data === 'boolean' && result.data === true) {
        history.push('/assignment/list');
      } else {
        NotificationHelper.add('Test assignment failed to reopen.', 'warning'); // 5000, GP1-11848
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      logger.error(err);
      NotificationHelper.add('Error reopening test assignment.', 'warning'); // 5000, GP1-11848
      setShowReopenModal(false);
      setReopeningAssignment(false);
    }
  };

  const isValidTestAssignmentReopeningDate = (
    assignmentCreationDate: Date,
    assignmentReopeningDate: Date,
  ) => {
    const assignmentSchoolYearStartDate = getAssignmentSchoolYearStartDate(assignmentCreationDate);
    const assignmentSchoolYearEndDate = getAssignmentSchoolYearEndDate(assignmentCreationDate);

    return (
      assignmentSchoolYearStartDate < assignmentReopeningDate &&
      assignmentReopeningDate < assignmentSchoolYearEndDate
    );
  };

  const getAssignmentSchoolYearStartDate = (assignmentCreationDate: Date) => {
    const assignmentCreationMonth = assignmentCreationDate.getMonth();
    const assignmentCreationYear = assignmentCreationDate.getFullYear();

    const schoolYearStartYear =
      assignmentCreationMonth < 6 ? assignmentCreationYear - 1 : assignmentCreationYear;

    return new Date(`July 1, ${schoolYearStartYear}`);
  };

  const getAssignmentSchoolYearEndDate = (assignmentCreationDate: Date) => {
    const assignmentCreationMonth = assignmentCreationDate.getMonth();
    const assignmentCreationYear = assignmentCreationDate.getFullYear();

    const schoolYearEndYear =
      assignmentCreationMonth < 6 ? assignmentCreationYear : assignmentCreationYear + 1;

    return new Date(`June 30, ${schoolYearEndYear}`);
  };

  useEffect(() => {
    if (!showReopenModal) {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    } else if (!reopeningAssignment) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="delete-assignment-modal"
            focusSelector=".default-modal-button"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            body={<div className="delete-assignment-message">Reopen this test assignment?</div>}
            footer={
              <div className="button-wrapper">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowReopenModal(false);
                  }}
                >
                  No - do not reopen
                </Button>
                <Button
                  className="default-modal-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    reopenTestAssignment(testAssignmentId, testAssignmentName);
                  }}
                >
                  Yes - reopen
                </Button>
              </div>
            }
            onClose={() => {
              setShowReopenModal(false);
            }}
          />
        ),
      });
    } else {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="deleting-assignment-modal modal-loader"
            disableClose={true}
            disableCloseOnEsc={true}
            closeOnMaskClick={false}
            body={
              <div className="modal-loader-contents">
                <div className="modal-loader-title">Please Wait</div>
                <div className="modal-loader-spinner">
                  <div className="spinner-wrapper loader-spinner">
                    <SpinnerIcon />
                  </div>
                </div>
                <div className="modal-loader-message">Reopening Assignment</div>
              </div>
            }
            footer={null}
          />
        ),
      });
    }
  }, [showReopenModal, reopeningAssignment]);

  useEffect(() => {
    if (!showCloseModal) {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    } else if (!closingAssignment) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="delete-assignment-modal"
            focusSelector=".default-modal-button"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            body={
              <div className="delete-assignment-message">
                {testAssignmentCurrentlyTesting
                  ? 'Students may be currently testing. Close this test assignment?'
                  : 'Close this test assignment?'}
              </div>
            }
            footer={
              <div className="button-wrapper">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowCloseModal(false);
                  }}
                >
                  No - do not close
                </Button>
                <Button
                  className="default-modal-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    closeTestAssignment(testAssignmentId, testAssignmentName);
                  }}
                >
                  Yes - close
                </Button>
              </div>
            }
            onClose={() => {
              setShowCloseModal(false);
            }}
          />
        ),
      });
    } else {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="deleting-assignment-modal modal-loader"
            disableClose={true}
            disableCloseOnEsc={true}
            closeOnMaskClick={false}
            body={
              <div className="modal-loader-contents">
                <div className="modal-loader-title">Please Wait</div>
                <div className="modal-loader-spinner">
                  <div className="spinner-wrapper loader-spinner">
                    <SpinnerIcon />
                  </div>
                </div>
                <div className="modal-loader-message">Closing Assignment</div>
              </div>
            }
            footer={null}
          />
        ),
      });
    }
  }, [showCloseModal, closingAssignment]);

  return (
    <div className={styles['section']}>
      {testAssignmentStatus === 'Open' ? (
        <button
          className="button primary"
          disabled={!testAssignmentCRUD}
          onClick={() => setShowCloseModal(true)}
        >
          Close this test assignment
        </button>
      ) : (
        <button
          className="button primary"
          disabled={
            !isValidTestAssignmentReopeningDate(
              new Date(Date.parse(testAssignmentStartDate)),
              new Date(),
            ) || !testAssignmentCRUD
          }
          onClick={() => setShowReopenModal(true)}
        >
          Reopen this test assignment
        </button>
      )}
    </div>
  );
};

export default TACloseReopenSection;
