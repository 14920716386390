import React from 'react';

import { AssignmentListItem } from '../../../TestAssignment';
import type { AssignmentListTableColumn } from '../TestAssignmentListTable';

const formatDate = (value: string): string => {
  const date = Date.parse(value);
  let dateString = value;
  if (!isNaN(date) && date) {
    dateString = Intl.DateTimeFormat('en-US').format(new Date(date));
  }
  return dateString;
};

const renderDatesCell = (item: AssignmentListItem, column: AssignmentListTableColumn) => {
  const startDate =
    typeof item.dates.startdate !== 'undefined' && item.dates.startdate
      ? formatDate(item.dates.startdate)
      : 'N/A';
  const endDate =
    typeof item.dates.enddate !== 'undefined' && item.dates.enddate
      ? formatDate(item.dates.enddate)
      : 'N/A';

  const startDatePS =
    typeof item.dates.startdatePostScreener !== 'undefined' && item.dates.startdatePostScreener
      ? formatDate(item.dates.startdatePostScreener)
      : 'N/A';
  const endDatePS =
    typeof item.dates.enddatePostScreener !== 'undefined' && item.dates.enddatePostScreener
      ? formatDate(item.dates.enddatePostScreener)
      : 'N/A';
  return (
    <React.Fragment>
      {item.assignmenttype === 'CogAT Screening & Post-Screener Form 7' ||
      item.assignmenttype === 'CogAT Screening & Post-Screener Form 8' ? (
        <React.Fragment>
          <table>
            <thead>
              <tr>
                <th colSpan={2}>Screening Form:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>From:</td>
                <td>{startDate}</td>
              </tr>
              <tr>
                <td>To:</td>
                <td>{endDate}</td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th colSpan={2}>Post-Screener:</th>
              </tr>
            </thead>
            <tbody>
              {!item.dates.startdatePostScreener && !item.dates.enddatePostScreener ? (
                <tr>
                  <td></td>
                  <td>Not Applicable</td>
                </tr>
              ) : (
                <>
                  <tr>
                    <td>From:</td>
                    <td>{startDatePS}</td>
                  </tr>
                  <tr>
                    <td>To:</td>
                    <td>{endDatePS}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </React.Fragment>
      ) : (
        <table>
          <tbody>
            <tr>
              <td>From:</td>
              <td>{startDate}</td>
            </tr>
            <tr>
              <td>To:</td>
              <td>{endDate}</td>
            </tr>
          </tbody>
        </table>
      )}
    </React.Fragment>
  );
};

export default renderDatesCell;
