import { Config } from '@elevate-ui/config';
import HelperBase from '../HelperBase';
import AppHelper from './AppHelper';

export interface AdditionalLogData {
  [key: string | number]: unknown | undefined;
  metaData?:
    | unknown
    | {
        [key: string | number]: unknown;
      };
}

export interface LogEntry {
  timestamp: number;
  type: string;
  message: unknown;
  additionalData?: AdditionalLogData;
}

const filterNamespaceCookie = 'elevateLogNamespaces';
const filterNamespaceExcludeFlagCookie = 'elevateLogExcludeFlag';
const debugAllowedCookie = 'elevateDebugEnabled';
const logLevelsCookie = 'elevateLogLevels';

class LogHelper extends HelperBase {
  static getLogLevels(config: Config) {
    const logLevels = {
      debug: config.loggingOptions.logLevels.debug,
      info: config.loggingOptions.logLevels.info,
      warning: config.loggingOptions.logLevels.warning,
      error: config.loggingOptions.logLevels.error,
    };
    const debugAllowed =
      config.appEnvironment === 'prod'
        ? false
        : AppHelper.getCookieValue(debugAllowedCookie)
          ? true
          : config.local;
    if (debugAllowed) {
      const logLevelsJson = AppHelper.getCookieValue(logLevelsCookie);
      if (typeof logLevelsJson === 'string' && logLevelsJson.length) {
        try {
          const localLogLevels = JSON.parse(decodeURIComponent(logLevelsJson));
          if (typeof localLogLevels === 'object' && localLogLevels !== null) {
            if (typeof localLogLevels.debug === 'boolean') {
              logLevels.debug = localLogLevels.debug;
            }
            if (typeof localLogLevels.info === 'boolean') {
              logLevels.info = localLogLevels.info;
            }
            if (typeof localLogLevels.warning === 'boolean') {
              logLevels.warning = localLogLevels.warning;
            }
            if (typeof localLogLevels.error === 'boolean') {
              logLevels.error = localLogLevels.error;
            }
            // hasLocalConfig = true;
          }
        } catch (exc: unknown) {
          // nothing to do here, logging doesn't work.
        }
      }
    }

    return logLevels;
  }

  /**
   * Logs message to browser console
   *
   * @param {Config} config configuration object
   * @param {unknown}           message        Value to add to the log
   * @param {string}            type           Log entry type
   * @param {string}            namespace      Optional log namespace
   * @param {AdditionalLogData} additionalData Additional log entry metadata
   * @param {boolean}           force          Force log, ignore configuration
   *
   * @return {undefined}
   */
  static logToConsole(
    config: Config,
    message: unknown,
    type = 'info',
    namespace = 'unknown',
    additionalData?: AdditionalLogData,
    force?: boolean,
  ): void {
    let msg = message;
    const prependStrings = [namespace, type.toUpperCase()];

    const typeStyles = config.loggingOptions.styleConsoleMessages
      ? [
          ...config.loggingOptions.namespaceStyles,
          ...config.loggingOptions.logStyles[type as keyof typeof config.loggingOptions.logStyles],
        ]
      : [];

    const prepend = config.loggingOptions.styleConsoleMessages
      ? `%c${prependStrings[0]}%c:%c${prependStrings[1]}%c - `
      : `${prependStrings[0]}:${prependStrings[1]} - `;

    if (typeof msg !== 'object') {
      msg = `${prepend}${msg}`;
    }

    if (type === 'error' || type === 'ERROR') {
      if (typeof msg !== 'string') {
        console.error(prepend, ...typeStyles, msg);
      } else {
        console.error(msg, ...typeStyles);
      }
    } else if (type === 'warn' || type === 'warning' || type === 'WARN' || type === 'WARNING') {
      if (typeof msg !== 'string') {
        console.warn(prepend, ...typeStyles, msg);
      } else {
        console.warn(msg, ...typeStyles);
      }
    } else {
      if (typeof msg !== 'string') {
        console.log(prepend, ...typeStyles, msg);
      } else {
        console.log(msg, ...typeStyles);
      }
    }
    if (force || config.loggingOptions.logAdditionalDataToConsole) {
      const stackError = new Error('error');
      const rawStackLines: string[] = stackError.stack ? stackError.stack.split(/\n/).slice(2) : [];
      const stackLines = rawStackLines.filter((line) => {
        if (line.match(/^\s+?at\s/)) {
          if (!line.match(/(Function\.debug|Function.addLoggerEntry)/)) {
            return true;
          }
        }
        return false;
      });
      const logDataAddon = {
        timestamp: new Date().getTime(),
        namespace,
        type,
        url: `${window.location}`,
        stack: stackLines.join('\n'),
      };
      const addonData: AdditionalLogData = additionalData
        ? { ...additionalData, ...logDataAddon }
        : { ...logDataAddon };
      if (typeof addonData?.metaData !== 'undefined' && addonData?.metaData !== null) {
        // if (typeof addonData?.metaData === 'object') {
        //   const keys = Object.getOwnPropertyNames(addonData.metaData);
        //   if (keys.length > 0) {
        //     for(let i=0; i<keys.length; i++) {
        //       console.dir(addonData.metaData[keys[i as keyof typeof keys] as keyof typeof addonData.metaData]);
        //       // console.table(addonData.metaData[keys[i] as keyof typeof addonData.metaData]);
        //     }
        //   } else {
        //     console.dir(addonData.metaData);
        //   }
        // } else {
        //   console.dir(addonData.metaData);
        // }
        console.log(addonData.metaData);
      }
    }
  }

  /**
   * Logs message to browser console
   *
   * @async
   *
   * @param {unknown}           message        Value to add to the log
   * @param {string}            type           Log entry type
   * @param {string}            namespace      Optional log namespace
   * @param {AdditionalLogData} additionalData Additional log entry metadata
   *
   * @return {undefined}
   */
  static async logToPersistentLog(
    message: unknown,
    type = 'info',
    namespace = 'unknown',
    additionalData?: AdditionalLogData,
  ): Promise<void> {
    return new Promise((res) => {
      const dur = Math.min(4000, Math.ceil((Math.random() * 100) / 3) * 1000);
      setTimeout(() => {
        res();
        // console.log('end', message, dur);
      }, dur);
    });
  }

  /**
   * Adds an entry to the log if logging is enabled, without regard for log levels
   *
   * @async
   *
   * @param {Config} config configuration object
   * @param {unknown}           message        Value to log
   * @param {string}            type           Log entry type
   * @param {string}            namespace      Optional log namespace
   * @param {AdditionalLogData} additionalData Additional log entry metadata
   *
   * @return {undefined}
   */
  static addLoggerEntry(
    config: Config,
    message: unknown,
    type = 'info',
    namespace = 'unknown',
    additionalData?: AdditionalLogData,
  ): void {
    if (config.loggingOptions.enabled) {
      if (config.loggingOptions.logToConsole || config.loggingOptions.logToPersistentLog) {
        const logLevels = LogHelper.getLogLevels(config);
        const namespaceAllowed = LogHelper.isNamespaceAllowed(namespace, config);
        if (
          namespaceAllowed &&
          typeof logLevels[type as keyof typeof logLevels] !== 'undefined' &&
          logLevels[type as keyof typeof logLevels]
        ) {
          if (config.loggingOptions.logToConsole) {
            LogHelper.logToConsole(config, message, type, namespace, additionalData);
          }
          if (config.loggingOptions.logToPersistentLog) {
            LogHelper.logToPersistentLog(message, type, namespace, additionalData);
          }
        }
      }
    }
  }

  static getFilterNamespaceExcludeFlag(config: Config): boolean {
    let result = false;
    let hasLocalValue = false;

    const debugAllowed =
      config.appEnvironment === 'prod'
        ? false
        : AppHelper.getCookieValue(debugAllowedCookie)
          ? true
          : config.local;
    if (debugAllowed) {
      const excludeFlagCookieValue = AppHelper.getCookieValue(filterNamespaceExcludeFlagCookie);
      if (excludeFlagCookieValue && excludeFlagCookieValue.length > 0) {
        hasLocalValue = true;
        if (excludeFlagCookieValue === '1') {
          result = true;
        }
      }
    }

    if (!hasLocalValue) {
      result = config.loggingOptions.debugFilters.exclude;
    }
    return result;
  }

  /**
   * Gets a list of namespaces to filter from the configuration or local (runtime) cookie override.
   *
   * This method retrieves debug filter namespaces, prioritizing local namespaces stored in a cookie
   * if debugging is allowed, and falls back to predefined debug filters if local namespaces are not available.
   *
   * @returns {RegExp[]} An array of regular expressions representing debug filter namespaces.
   */
  /* eslint-disable security/detect-non-literal-regexp */
  /* eslint eslint-comments/no-use: off */
  static getFilterNamespaces(config: Config): RegExp[] {
    let namespaces: RegExp[] = [];
    let hasLocalNamespaces = false;
    const debugAllowed =
      config.appEnvironment === 'prod'
        ? false
        : AppHelper.getCookieValue(debugAllowedCookie)
          ? true
          : config.local;

    if (debugAllowed) {
      const namespaceJson = AppHelper.getCookieValue(filterNamespaceCookie);
      if (typeof namespaceJson === 'string' && namespaceJson.length) {
        try {
          const localNamespaces: string[] = JSON.parse(decodeURIComponent(namespaceJson));
          namespaces = localNamespaces.map((namespace: string) => new RegExp(namespace));
          hasLocalNamespaces = true;
        } catch (exc: unknown) {
          hasLocalNamespaces = false;
        }
      }
    }

    if (config.loggingOptions.debugFilters.namespaces.length && !hasLocalNamespaces) {
      namespaces = config.loggingOptions.debugFilters.namespaces.map(
        (namespace: string) => new RegExp(namespace),
      );
    }
    return namespaces;
  }
  /* eslint-enable security/detect-non-literal-regexp */

  /**
   * Checks current namespace against filter list from
   * config.loggingOptions.debugFilters or local runtime cookie override
   *
   * @param   {string} namespace  Namespace to check for
   * @param {Config} config configuration object
   * @returns {boolean}           True if namespace is allowed, false otherwise
   */
  static isNamespaceAllowed(namespace: string, config: Config): boolean {
    let result = false;
    let matchFound = false;
    const filterNamespaces = LogHelper.getFilterNamespaces(config);
    const exclude = LogHelper.getFilterNamespaceExcludeFlag(config);
    if (filterNamespaces.length === 0) {
      result = true;
    } else {
      matchFound = filterNamespaces.some((matchItem: string | RegExp) => {
        let result = false;
        if (typeof matchItem === 'string') {
          // result = matchItem.indexOf(namespace) >= 0;
          const regex = AppHelper.createRegex(matchItem, 'ig');
          result = regex.test(namespace);
        } else if (typeof matchItem.test === 'function') {
          result = matchItem.test(namespace);
        }
        return result;
      });
      result = exclude ? !matchFound : matchFound;
    }
    return result;
  }

  /**
   * Adds an entry to the log, disregarding the configuration.
   *
   * @async
   *
   * @param {unknown}           message        Value to log
   * @param {string}            namespace      Optional log namespace
   * @param {AdditionalLogData} additionalData Additional log entry metadata
   *
   * @return {undefined}
   */
  static forceLoggerEntry(
    message: unknown,
    namespace?: string,
    additionalData?: AdditionalLogData,
  ): void {
    const finalMessage = namespace ? `${namespace}|${message}` : message;
    console.info(finalMessage);
    if (additionalData) {
      console.info(additionalData);
    }
  }

  /**
   * Adds an entry to the log if logging is enabled, without regard for log levels
   *
   * @async
   *
   * @param {Config} config configuration object
   * @param {unknown}           message        Value to log
   * @param {string}            namespace      Optional log namespace
   * @param {AdditionalLogData} additionalData Additional log entry metadata
   *
   * @return {undefined}
   */
  static async log(
    config: Config,
    message: unknown,
    namespace = 'unknown',
    additionalData?: AdditionalLogData,
  ): Promise<void> {
    const namespaceAllowed = LogHelper.isNamespaceAllowed(namespace, config);
    if (config.loggingOptions.enabled && namespaceAllowed) {
      if (config.loggingOptions.logToConsole) {
        LogHelper.logToConsole(config, message, 'info', namespace, additionalData);
      }

      if (config.loggingOptions.logToPersistentLog) {
        await LogHelper.logToPersistentLog(message, 'info', namespace, additionalData);
      }
    }
  }

  /**
   * Adds a debug entry to the log if allowed by config
   *
   * @async
   *
   * @param {Config} config configuration object
   * @param {unknown}           message        Value to log
   * @param {string}            namespace      Optional log namespace
   * @param {AdditionalLogData} additionalData Additional log entry metadata
   *
   * @return {undefined}
   */
  static async debug(
    config: Config,
    message: unknown,
    namespace = 'unknown',
    additionalData?: AdditionalLogData,
  ): Promise<void> {
    const logLevels = this.getLogLevels(config);
    if (config.loggingOptions.enabled && logLevels.debug) {
      LogHelper.addLoggerEntry(config, message, 'debug', namespace, additionalData);
    }
  }

  /**
   * Adds an info entry to the log if allowed by config
   *
   * @async
   *
   * @param {Config} config configuration object
   * @param {unknown}           message        Value to log
   * @param {string}            namespace      Optional log namespace
   * @param {AdditionalLogData} additionalData Additional log entry metadata
   *
   * @return {undefined}
   */
  static async info(
    config: Config,
    message: unknown,
    namespace = 'unknown',
    additionalData?: AdditionalLogData,
  ): Promise<void> {
    const logLevels = this.getLogLevels(config);
    if (config.loggingOptions.enabled && logLevels.info) {
      LogHelper.addLoggerEntry(config, message, 'info', namespace, additionalData);
    }
  }

  /**
   * Adds a warning entry to the log if allowed by config
   *
   * @async
   *
   * @param {Config} config configuration object
   * @param {unknown}           message        Value to log
   * @param {string}            namespace      Optional log namespace
   * @param {AdditionalLogData} additionalData Additional log entry metadata
   *
   * @return {undefined}
   */
  static async warn(
    config: Config,
    message: unknown,
    namespace = 'unknown',
    additionalData?: AdditionalLogData,
  ): Promise<void> {
    const logLevels = this.getLogLevels(config);
    if (config.loggingOptions.enabled && logLevels.warning) {
      LogHelper.addLoggerEntry(config, message, 'warning', namespace, additionalData);
    }
  }

  /**
   * Adds a warning entry to the log if allowed by config
   *
   * @async
   *
   * @param {Config} config configuration object
   * @param {unknown}           message        Value to log
   * @param {string}            namespace      Optional log namespace
   * @param {AdditionalLogData} additionalData Additional log entry metadata
   *
   * @return {undefined}
   */
  static async warning(
    config: Config,
    message: unknown,
    namespace = 'unknown',
    additionalData?: AdditionalLogData,
  ): Promise<void> {
    const logLevels = this.getLogLevels(config);
    if (config.loggingOptions.enabled && logLevels.warning) {
      LogHelper.addLoggerEntry(config, message, 'warning', namespace, additionalData);
    }
  }

  /**
   * Adds an error entry to the log if allowed by config
   *
   * @async
   *
   * @param {unknown}           message        Value to log
   * @param {string}            namespace      Optional log namespace
   * @param {AdditionalLogData} additionalData Additional log entry metadata
   *
   * @return {undefined}
   */
  static error(message: unknown, namespace = 'unknown', additionalData?: AdditionalLogData) {
    console.error(message, namespace, additionalData);
  }
}

export default LogHelper;
