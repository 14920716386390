import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AppNotification, AppState } from '../reducers/appDataTypes';

import AppHelper from '../lib/helper/AppHelper';
import { useLocation } from 'react-router';

export interface NotificationHelperInstanceOptions {
  defaultNotificationDuration?: number;
}

export type NotificationMessage =
  | string
  | React.FunctionComponent
  | React.ReactNode
  | React.ReactElement
  | Element;

export type UseNotificationHelperInterface = {
  notifications: AppNotification[];
  add: (
    message: string | React.FunctionComponent | React.ReactNode | React.ReactElement | Element,
    type: string,
    duration?: number,
    icon?: React.ReactNode | React.ReactElement | Element | null,
    sticky?: boolean,
    persistOnLocationChange?: boolean,
    uuid?: string,
  ) => AppNotification;

  remove: (identifier: string | number | AppNotification) => boolean;

  clear: () => void;
};

/**
 * Returns instance of helper that manages Elevate UI application notification operations.
 * Returned object contains:
 *  - 'notifications' property with a list of notifications
 *  - 'clear' method for removing all notifications
 *  - 'remove' method for removing notification by index, id or its AppNotification instance
 *  - 'add' method for displaying notifications in Elevate app
 *
 * @param {NotificationHelperInstanceOptions|undefined} options   Options for notification defaults
 * @returns {UseNotificationHelperInterface}                      Interface for app notifications
 */
const useNotificationHelper = (
  options?: NotificationHelperInstanceOptions,
): UseNotificationHelperInterface => {
  let defaultDuration = 0; // 10000, GP1-11848
  if (typeof options !== 'undefined') {
    if (typeof options.defaultNotificationDuration !== 'undefined') {
      defaultDuration = options.defaultNotificationDuration;
    }
  }

  const dispatch = useDispatch();
  const location = useLocation();
  const notifications = useSelector((state: AppState) => {
    return state.appStatus.appNotifications;
  });

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      const persistedNotifications = notifications.filter(
        (notification) => notification.persistOnLocationChange,
      );
      dispatch({ type: 'SET_APP_NOTIFICATIONS', payload: persistedNotifications });
    }
  }, [location.pathname]);

  return {
    notifications,
    /**
     * Adds (and displays) global app notification and returns it as AppNotification object
     *
     * @param {string|React.FunctionComponent|React.ReactNode|React.ReactElement|Element}   message                     Notification contents
     * @param {string}                                                                      type                        Notification type (info, warning, error)
     * @param {number}                                                                      duration                    Duration for timed notifications (0 to disable timer)
     * @param {React.ReactNode|React.ReactElement|Element|null}                             icon                        Optional custom icon
     * @param {boolean}                                                                     sticky                      Sticky notifications can't be removed by user
     * @param {boolean}                                                                     persistOnLocationChange     Persistent notifications stay when page is changed, but can be removed by user
     * @param {string}                                                                      uuid                        Custom uuid for notification (uuid is autogenerated if no value is passed)
     *
     * @returns {AppNotification}                                                                                       App notification object
     */
    add: (
      message: NotificationMessage,
      type = 'info',
      duration = defaultDuration,
      icon = null,
      sticky = false,
      persistOnLocationChange = false,
      uuid = '',
    ) => {
      const notification = AppHelper.createAppNotification(
        message,
        type,
        duration,
        icon,
        sticky,
        persistOnLocationChange,
        uuid,
      );
      dispatch({ type: 'ADD_APP_NOTIFICATION', payload: notification });
      return notification;
    },
    /**
     *
     * @param {string|number|AppNotification}   identifier  Identifier for notification. Can be passed as AppNotification object, string as uuid or number as its index in notification list
     *
     * @returns {boolean}                                   Remove boolean result
     */
    remove: (identifier: string | number | AppNotification) => {
      let uuid = '';
      if (typeof identifier === 'string') {
        uuid = identifier;
      } else if (typeof identifier === 'number') {
        if (notifications.length > 0 && identifier >= 0 && identifier < notifications.length) {
          uuid = (notifications[identifier as keyof typeof notifications] as AppNotification).uuid;
        }
      } else {
        uuid = identifier.uuid;
      }
      if (uuid !== '') {
        dispatch({ type: 'REMOVE_APP_NOTIFICATION', payload: uuid });
        return true;
      }
      return false;
    },

    /**
     * Clears all active app notifications immediately
     *
     * @returns {undefined}
     */
    clear: () => {
      dispatch({ type: 'CLEAR_APP_NOTIFICATIONS', payload: null });
    },
  };
};

export default useNotificationHelper;
