import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AppState } from '../reducers/appDataTypes';
import {
  // StaffAuthUser,
  // StaffAuthUserUpdate,
  // AuthStateCognito,
  AuthState,
} from '../reducers/Auth/authDataTypes';

// import LogHelper from '../lib/helper/LogHelper';

const useUserType = (): string | null => {
  const authState: AuthState = useSelector((state: AppState) => {
    return state.auth;
  });
  const [cognitoSession, setCognitoSession] = useState<CognitoUserSession | null>(null);
  const [cognitoSessionType, setCognitoSessionType] = useState<string | null>(null);

  const getSSOSession = (): CognitoUserSession | null => {
    if (authState.staffAuth.cognito.cognitoUserSession !== null) {
      return authState.staffAuth.cognito.cognitoUserSession;
    } else {
      return authState.studentAuth.cognito.cognitoUserSession;
    }
  };

  useEffect(() => {
    setCognitoSession(getSSOSession());
  }, []);

  useEffect(() => {
    let type: string | null = null;
    if (cognitoSession !== null) {
      const cleverRole = cognitoSession.getIdToken().payload['custom:user_type'];
      if (cleverRole) {
        if (cleverRole === 'student') {
          type = 'student';
        } else {
          type = 'staff';
        }
      }
    }
    setCognitoSessionType(type);
  }, [cognitoSession]);

  useEffect(() => {
    setCognitoSession(getSSOSession());
  }, [
    authState.staffAuth.cognito.cognitoUserSession,
    authState.studentAuth.cognito.cognitoUserSession,
  ]);

  return cognitoSessionType;
};

export default useUserType;
