import React, { createContext, useContext } from 'react';
import { useLoadConfig, Config } from '@elevate-ui/config';
import { OverlayLoader } from '@riversideinsights/elevate-react-lib';
import { AppError } from '@elevate-ui/react-lib';

export const ConfigContext = createContext<Config | undefined>(undefined);

/**
 * ConfigLoader component for displaying configuration data.
 *
 * @returns {React.ReactNode} The rendered component.
 */
const ConfigLoader: React.FC = ({ children }) => {
  const { config, isLoading, isError } = useLoadConfig();

  if (isLoading) {
    return <OverlayLoader message="Initializing" />;
  }

  if (isError) {
    return (
      <AppError
        message="Error loading configuration. Please try reloading the page."
        title="Loading Error"
        showRetryButton
      />
    );
  }

  if (config) {
    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
  }

  return <OverlayLoader message="Initializing" />;
};

export const useConfigContext = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfigContext must be used within a ConfigLoader');
  }
  return context;
};

export default ConfigLoader;
