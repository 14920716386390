import React from 'react';
import styles from './Header.module.scss';
import CloseIcon from '../../../shared/icons/CloseIcon';
import { IconButton } from '@mui/material';

export interface HeaderProps {
  ticketCount: number;
  onClose: () => void;
}

const Header = ({ ticketCount, onClose }: HeaderProps) => {
  return (
    <div className={styles['header']}>
      <h1>Print Test Taker Tickets ({ticketCount.toLocaleString()} currently selected)</h1>

      <IconButton className={styles['close-button']} disableRipple onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default Header;
