import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import useAppLogger from '../useAppLogger';
import { Types as AblyTypes, ErrorInfo } from 'ably';
import useIdentify from '../useIdentify';
import useTrackMetrics, { TrackMetricsKey } from '../useTrackMetrics/useTrackMetrics';
import useUserType from '../useUserType';
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import useApiHelper from '../useApiHelper';
import { AblyState, AppState } from '../../reducers/appDataTypes';
import { ablyClientId, getAblyRealtimeClient } from '../../lib/util/ably.util';
import useUserId from '../useUserId';
import useCustomerId from '../useCustomerId';
import useProctorCode from '../useProctorCode';

export interface AblyClientHookOptions {
  initialize?: boolean;
  namespace?: string;
}

export type AblyClientHookInterface = {
  instanceId: string;
  ablyRealtime: AblyTypes.RealtimeCallbacks | null;
  ready: boolean;
  error: boolean;

  destroy: () => void;
  initialize: () => void;
};

export interface TrackMetricsAbly {
  responseInMilliseconds: number;
}

/**
 * Returns an interface wrapping Ably Realtime client instance
 *
 * Hook has two modes:
 * - `root` hook is created once per page (with `initialize` option)
 * - `non-root` hook can be used anywhere in the app
 * Both modes return same interface to their parents.
 *
 * With root hook rendered, all components can create their own hook instance, used to call `destroy` and subsequent `initialize` methods from anywhere in the app..
 *
 * @param {AblyClientHookOptions|undefined} options   Hook options
 *
 * @returns {AblyClientHookInterface}                 Ably client wrapper interface
 */
const useAblyClient = (options?: AblyClientHookOptions): AblyClientHookInterface => {
  const config = useConfigContext();
  const userType = useUserType();
  const userId = useUserId();
  const customerId = useCustomerId();
  const proctorCode = useProctorCode();
  const instanceId = useRef(useIdentify(5, 'com_'));
  const apiHelper = useApiHelper('HOOK|useAblyClient');

  const isRootHook =
    typeof options !== 'undefined' && options?.initialize ? options.initialize : false;
  const namespace =
    typeof options !== 'undefined' && options?.namespace ? options.namespace : 'NO_NAMESPACE';

  const logger = useAppLogger(`HOOK|useAblyClient-${namespace}-${instanceId.current}`);

  const { trackMetrics } = useTrackMetrics<TrackMetricsAbly>(TrackMetricsKey.AblyPing);

  const ablyState: AblyState = useSelector((state: AppState) => {
    return state.ably;
  });

  const dispatch = useDispatch();

  // "public" methods
  const destroy = () => {
    dispatch({ type: 'SET_DESTROYING_ABLY', payload: true });
  };
  const initialize = () => {
    dispatch({ type: 'SET_REINITIALIZING_ABLY', payload: true });
  };

  // return value getter
  const getLocalHookState = (): AblyClientHookInterface => {
    return {
      instanceId: instanceId.current,
      ablyRealtime: ablyState.ablyRealtime,
      ready: ablyState.ready,
      error: ablyState.error,
      initialize,
      destroy,
    };
  };

  const [hookState, setHookState] = useState<AblyClientHookInterface>(getLocalHookState());

  // keep state updated when state changes
  useEffect(() => {
    setHookState(getLocalHookState());
  }, [ablyState.ablyRealtime, ablyState.ready, ablyState.error]);

  useEffect(() => {
    const { ablyRealtime, ready } = ablyState;

    if (!ablyRealtime || !ready) return;

    ablyRealtime.connection.ping(
      (error: ErrorInfo | null, responseInMilliseconds: number | undefined) => {
        if (error) {
          logger.error(`Ping error: ${error}`);
        }

        if (responseInMilliseconds) {
          trackMetrics({ responseInMilliseconds });
        }
      },
    );
  }, [ablyState.ablyRealtime, ablyState.ready]);

  /* "private" root hook code start */

  const reinitialize = () => {
    if (ablyState.ablyRealtime === null && !ablyState.initializing) {
      prepareAblyClient();
    }
  };

  const resetAblyState = () => {
    dispatch({ type: 'SET_ABLY_INITIALIZING', payload: false });
    dispatch({ type: 'SET_ABLY_READY', payload: false });
    dispatch({ type: 'SET_ABLY_ERROR', payload: false });
    dispatch({ type: 'SET_ABLY_REALTIME_INSTANCE', payload: null });
    dispatch({ type: 'SET_DESTROYING_ABLY', payload: false });
  };

  /**
   * Loads new auth token from API
   *
   * @async
   * @throws {AxiosError}                Api network error
   * @returns {AblyTokenRequestResponse} Ably auth token
   */
  const loadTokenRequest = async (): Promise<AblyTypes.TokenRequest | null> => {
    const ablyRealtimeClientId = ablyClientId(
      userType === 'student' ? userId : proctorCode,
      customerId,
      userType,
    );
    const apiRequestParams = {
      clientId: ablyRealtimeClientId,
      userType: userType,
    };
    const result = await apiHelper.apiRequest(
      'integrationsApi',
      'ablyTokenRequest',
      apiRequestParams,
    );
    if (result && result.data) {
      return result.data as AblyTypes.TokenRequest;
    } else {
      throw Error('Null or empty Ably token');
    }
  };

  const ablyClientAuthCallback = async (
    tokenParams: AblyTypes.TokenParams,
    callback: (
      error: string | AblyTypes.ErrorInfo | null,
      tokenRequestOrDetails: string | AblyTypes.TokenDetails | AblyTypes.TokenRequest | null,
    ) => void,
  ) => {
    try {
      logger.info(`Calling integrations api to get Ably token for ${userType} user.`);
      const newAuthToken = await loadTokenRequest();
      callback(null, newAuthToken);
    } catch (exc: unknown) {
      const ex = exc as Error;
      logger.error(ex);
      callback(ex.message, null);
      dispatch({ type: 'SET_ABLY_ERROR', payload: true });
    }
  };

  /**
   * Central hub for handling all client connection state changes
   * applies to root hook only
   *
   * @param {Ably.Types.ConnectionStateChange}   stateChange   State changes
   *
   * @returns {void}
   */
  const handleClientStateChange = (stateChange: AblyTypes.ConnectionStateChange): void => {
    dispatch({ type: 'SET_ABLY_CONNECTION_STATE', payload: stateChange.current });
    if (stateChange.current === 'connected') {
      // connection is established and client is initialized and ready
      logger.debug('Ably client is connected');
      dispatch({ type: 'SET_ABLY_INITIALIZING', payload: false });
      dispatch({ type: 'SET_ABLY_READY', payload: true });
      dispatch({ type: 'SET_ABLY_ERROR', payload: false });
    } else if (['suspended', 'failed'].indexOf(stateChange.current) >= 0) {
      // connection is permanently down and can not be recovered
      logger.error('Ably client connection suspended');
      dispatch({ type: 'SET_ABLY_ERROR', payload: true });
    } else if (stateChange.current === 'closed') {
      // we've disconnected intentionally
      afterConnectionClose();
      logger.info('Ably client connection closed');
    }
  };

  const createAblyClient = (): typeof ablyState.ablyRealtime => {
    const options: AblyTypes.ClientOptions = {
      authCallback: ablyClientAuthCallback,
      echoMessages: false,
      transportParams: {
        remainPresentFor: config.proctoring.ably.remainPresentFor,
        heartbeatInterval: config.proctoring.ably.heartbeatInterval,
      },
      /* TODO: enable this when BOTH student and proctor sections migrate to new client hook
      recover: (lastConnectionDetails, recoverCallback) => {
        logger.info(`Recovering Ably client from ${Math.ceil((new Date().getTime() - lastConnectionDetails.disconnectedAt) / 1000)} seconds ago.`, { metaData: { ...lastConnectionDetails } });
        recoverCallback(true);
      },
      */
    };

    logger.debug('Ably hook client setup', { metaData: { ...options, authCallback: null } });

    const instance = getAblyRealtimeClient(options);
    if (instance && typeof instance.connection !== 'undefined') {
      instance.connection.on(handleClientStateChange);
    }
    return instance;
  };

  const afterConnectionClose = () => {
    if (isRootHook) {
      if (ablyState.ablyRealtime !== null) {
        ablyState.ablyRealtime.connection.off(handleClientStateChange);
      }
      resetAblyState();
      logger.info('Ably client disconnected.');
    }
  };
  const destroyHookInstance = (): void => {
    if (ablyState.ablyRealtime !== null && isRootHook) {
      logger.info('Disconnecting Ably client...');
      ablyState.ablyRealtime.connection.close();
    }
  };

  const prepareAblyClient = async (): Promise<void> => {
    if (
      isRootHook &&
      !ablyState.ready &&
      !ablyState.initializing &&
      ablyState.ablyRealtime === null
    ) {
      dispatch({ type: 'SET_ABLY_READY', payload: false });
      dispatch({ type: 'SET_ABLY_ERROR', payload: false });
      dispatch({ type: 'SET_ABLY_INITIALIZING', payload: true });
      const clientInstance = await createAblyClient();
      if (clientInstance !== null) {
        dispatch({ type: 'SET_ABLY_REALTIME_INSTANCE', payload: clientInstance });
      } else {
        dispatch({ type: 'SET_ABLY_ERROR', payload: true });
        dispatch({ type: 'SET_ABLY_INITIALIZING', payload: false });
        logger.error('Ably root hook failed initializing');
      }
    }
  };

  // initialization (on root hook 'mount' or when user is ready)
  useEffect(() => {
    const isAblyReadyToInitialize =
      isRootHook &&
      userId &&
      proctorCode &&
      customerId &&
      userType !== null &&
      !ablyState.initializing &&
      !ablyState.ready;
    if (isAblyReadyToInitialize) {
      initialize();
    }
  }, [userType, userId, proctorCode, customerId]);

  // reinitialization (triggered from 'outside' via `.initialize()`)
  useEffect(() => {
    if (ablyState.reinitializingAbly === true && isRootHook) {
      logger.info('Reinitiaalizing hook instance via "reinitializingAbly" flag.');
      dispatch({ type: 'SET_REINITIALIZING_ABLY', payload: false });
      reinitialize();
    }
  }, [ablyState.reinitializingAbly]);

  // 'manual' destruction (triggered from 'outside' via `.destroy()`)
  useEffect(() => {
    if (ablyState.destroyingAbly === true && isRootHook) {
      logger.info('Destroying hook instance via "destroyingAbly" flag.');
      dispatch({ type: 'SET_DESTROYING_ABLY', payload: false });
      destroyHookInstance();
    }
  }, [ablyState.destroyingAbly]);

  /* "private" root hook code end */

  return hookState;
};

export default useAblyClient;
