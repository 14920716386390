import React from 'react';
import {
  CompareAssignmentItem,
  CreateAssignmentRequest,
  UseCheckForDuplicateAssignment,
} from './useCheckForDuplicateAssignment.types';
import { checkForDuplicates } from './checkForDuplicates.util';

/**
 * A hook to check for duplicate assignments.
 * @returns {UseCheckForDuplicateAssignment} The duplicate assignment hook.
 * @param {CompareAssignmentItem[] | null} assignments The assignments to compare.
 * @param {CreateAssignmentRequest[] | null} newAssignments The new assignments to check for duplicates.
 */
export function useCheckForDuplicateAssignment(
  assignments: CompareAssignmentItem[] | null,
  newAssignments?: CreateAssignmentRequest[] | null,
): UseCheckForDuplicateAssignment {
  const [duplicates, setDuplicates] = React.useState<CompareAssignmentItem[] | null>();
  const assignmentsMemo = React.useMemo(() => assignments, [JSON.stringify(assignments)]);
  const newAssignmentsMemo = React.useMemo(() => newAssignments, [JSON.stringify(newAssignments)]);

  const handleCheckForDuplicates = React.useCallback(
    (newAssignments: CreateAssignmentRequest[]) => {
      const assignmentsCopy = [...(assignments || [])];
      const checkDuplicates = newAssignments.flatMap((newAssignment) =>
        checkForDuplicates(assignmentsCopy, newAssignment),
      );
      const foundDuplicates = checkDuplicates.length ? checkDuplicates : null;
      // Since newAssignments is an array of assignments, we need to filter out any duplicates that are the same.
      const uniqueDuplicates = foundDuplicates
        ? Array.from(new Set(foundDuplicates.map((duplicate) => duplicate.assignmentId)))
            .map((id) => foundDuplicates.find((duplicate) => duplicate.assignmentId === id))
            .filter((item): item is CompareAssignmentItem => item !== undefined)
        : null;

      setDuplicates(uniqueDuplicates);
    },
    [assignments],
  );

  React.useEffect(() => {
    if (newAssignmentsMemo) {
      if (!assignmentsMemo?.length) {
        setDuplicates(null);
      } else {
        handleCheckForDuplicates(newAssignmentsMemo);
      }
    }
  }, [assignmentsMemo, newAssignmentsMemo, handleCheckForDuplicates]);

  return {
    checkForDuplicates: handleCheckForDuplicates,
    duplicates,
  };
}
