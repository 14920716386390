import { batterySubTestAcronyms, textSubTestAcronyms, ITextSubTestAcronyms } from './AppHelperOLD';
import { numberSubTestAcronyms } from '../TestAssignment/TestAssignmentPrototypes';

export const HelpGuideInitial = [
  {
    type: 'IowaFlex',
    label: 'IowaFlex Math',
    path: '/img/assignmentHelpGuide/IowaFlex/Math/',
    grades: [
      {
        grade: 'Grade K',
        battery: 'Math',
        number: '1',
        title: 'Frog',
      },
      {
        grade: 'Grade 1',
        battery: 'Math',
        number: '1',
        title: 'Pail',
      },
      {
        grade: 'Grade 2',
        battery: 'Math',
        number: '1',
        title: 'Handbell',
      },
      {
        grade: 'Grade 3',
        battery: 'Math',
        number: '1',
        title: 'Tic Tac Toe',
      },
      {
        grade: 'Grade 4',
        battery: 'Math',
        number: '1',
        title: 'Egg',
      },
      {
        grade: 'Grade 5',
        battery: 'Math',
        number: '1',
        title: 'Soccer Ball',
      },
      {
        grade: 'Grade 6',
        battery: 'Math',
        number: '1',
        title: 'Tiger',
      },
      {
        grade: 'Grade 7',
        battery: 'Math',
        number: '1',
        title: 'House',
      },
      {
        grade: 'Grade 8',
        battery: 'Math',
        number: '1',
        title: 'Airplane',
      },
    ],
  },
  {
    type: 'IowaFlex',
    label: 'IowaFlex Reading',
    path: '/img/assignmentHelpGuide/IowaFlex/Reading/',
    grades: [
      {
        grade: 'Grade K',
        battery: 'Reading',
        number: '1',
        title: 'Banana',
      },
      {
        grade: 'Grade 1',
        battery: 'Reading',
        number: '1',
        title: 'Ladder',
      },
      {
        grade: 'Grade 2',
        battery: 'Reading',
        number: '1',
        title: 'Plant',
      },
      {
        grade: 'Grade 3',
        battery: 'Reading',
        number: '1',
        title: 'Elephant',
      },
      {
        grade: 'Grade 4',
        battery: 'Reading',
        number: '1',
        title: 'Shapes',
      },
      {
        grade: 'Grade 5',
        battery: 'Reading',
        number: '1',
        title: 'Table',
      },
      {
        grade: 'Grade 6',
        battery: 'Reading',
        number: '1',
        title: 'Watch',
      },
      {
        grade: 'Grade 7',
        battery: 'Reading',
        number: '1',
        title: 'Light Bulb',
      },
      {
        grade: 'Grade 8',
        battery: 'Reading',
        number: '1',
        title: 'Van',
      },
    ],
  },
  {
    type: 'CogAT',
    label: 'CogAT Form 7',
    levels: [
      {
        path: '/img/assignmentHelpGuide/CogAT/Form7/Level5_6/',
        level: 'Level 5/6',
        subtests: [
          {
            acronym: 'PA',
            title: 'Star',
            battery: batterySubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PA'],
          },
          {
            acronym: 'SC',
            title: 'Balloon',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'PC',
            title: 'Feather',
            battery: batterySubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PC'],
          },
          {
            acronym: 'NA',
            title: 'Bike',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Chair',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'T-shirt',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Bird',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Horse',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Apple',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form7/Level7/',
        level: 'Level 7',
        subtests: [
          {
            acronym: 'PA',
            title: 'Cloud',
            battery: batterySubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PA'],
          },
          {
            acronym: 'SC',
            title: 'Book',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'PC',
            title: 'Spoon',
            battery: batterySubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PC'],
          },
          {
            acronym: 'NA',
            title: 'Dinosaur',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Umbrella',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Sailboat',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Flower',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Lemon',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Tree',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form7/Level8/',
        level: 'Level 8',
        subtests: [
          {
            acronym: 'PA',
            title: 'Bell',
            battery: batterySubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PA'],
          },
          {
            acronym: 'SC',
            title: 'Frog',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'PC',
            title: 'Cap',
            battery: batterySubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PC'],
          },
          {
            acronym: 'NA',
            title: 'Fork',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Duck',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Jump Rope',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Kite',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Pear',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Butterfly',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form7/Level9/',
        level: 'Level 9',
        subtests: [
          {
            acronym: 'VA',
            title: 'Flag',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Leaf',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Soccer Ball',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Glasses',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Drum',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Deer',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Table',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Banana',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Clock',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form7/Level10/',
        level: 'Level 10',
        subtests: [
          {
            acronym: 'VA',
            title: 'Foot',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Seal',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Ladybug',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Van',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Horn',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Watch',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Candle',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Strawberry',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Coin',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form7/Level11/',
        level: 'Level 11',
        subtests: [
          {
            acronym: 'VA',
            title: 'Snowflake',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Doll',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Magnifying Glass',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Camel',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Glove',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Envelope',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Rocket',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Pine Tree',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Crown',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form7/Level12/',
        level: 'Level 12',
        subtests: [
          {
            acronym: 'VA',
            title: 'Birdhouse',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Pineapple',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Blocks',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Handbell',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Musical Note',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Pail',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Train',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Mouse',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Carrot',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form7/Level13_14/',
        level: 'Level 13/14',
        subtests: [
          {
            acronym: 'VA',
            title: 'Watering Can',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Owl',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Hammer',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Snail',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Ring',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Scarf',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Key',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Fish',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Cactus',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form7/Level15_16/',
        level: 'Level 15/16',
        subtests: [
          {
            acronym: 'VA',
            title: 'Dice',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Waterfall',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Hand',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Smiley Face',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Maple Leaf',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Conversation Bubble',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Flashlight',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Infinity Symbol',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Sun',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form7/Level17_18/',
        level: 'Level 17/18',
        subtests: [
          {
            acronym: 'VA',
            title: 'Sock',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Barbells',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Dog',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Ruler',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'School Bus',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Whale',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Button',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Salt',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Window',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
    ],
  },
  {
    type: 'CogAT',
    label: 'CogAT Form 8',
    levels: [
      {
        path: '/img/assignmentHelpGuide/CogAT/Form8/Level5_6/',
        level: 'Level 5/6',
        subtests: [
          {
            acronym: 'PA',
            title: 'Heart',
            battery: batterySubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PA'],
          },
          {
            acronym: 'SC',
            title: 'Shoe',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'PC',
            title: 'Grapes',
            battery: batterySubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PC'],
          },
          {
            acronym: 'NA',
            title: 'Comb',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Dolphin',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Sun',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Elephant',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Scissors',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'House',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form8/Level7/',
        level: 'Level 7',
        subtests: [
          {
            acronym: 'PA',
            title: 'Key',
            battery: batterySubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PA'],
          },
          {
            acronym: 'SC',
            title: 'Teddy Bear',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'PC',
            title: 'Boot',
            battery: batterySubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PC'],
          },
          {
            acronym: 'NA',
            title: 'Fish',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Door',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Lamp',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Airplane',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Cat',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Truck',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form8/Level8/',
        level: 'Level 8',
        subtests: [
          {
            acronym: 'PA',
            title: 'Turtle',
            battery: batterySubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PA'],
          },
          {
            acronym: 'SC',
            title: 'Broom',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'PC',
            title: 'Chicken',
            battery: batterySubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PC'],
          },
          {
            acronym: 'NA',
            title: 'Rabbit',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Bed',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Whale',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Car',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Smiley Face',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Mitten',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form8/Level9/',
        level: 'Level 9',
        subtests: [
          {
            acronym: 'VA',
            title: 'Shovel',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Monkey',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Snail',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Giraffe',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Plant',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Sock',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Ladder',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Paintbrush',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Arrow',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form8/Level10/',
        level: 'Level 10',
        subtests: [
          {
            acronym: 'VA',
            title: 'Cactus',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Ring',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Mug',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Gift',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Hanger',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Squirrel',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Pitcher',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Ball',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Scarf',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form8/Level11/',
        level: 'Level 11',
        subtests: [
          {
            acronym: 'VA',
            title: 'Cowboy Hat',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Maple Leaf',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Light Bulb',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Salt',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Button',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Hand',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Bowl',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Dog',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Rose',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form8/Level12/',
        level: 'Level 12',
        subtests: [
          {
            acronym: 'VA',
            title: 'Hammer',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Conversation Bubble',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Kangaroo',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Moon',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Pear',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'School Bus',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Waterfall',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Flashlight',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Dice',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form8/Level13_14/',
        level: 'Level 13/14',
        subtests: [
          {
            acronym: 'VA',
            title: 'Bike',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Pine Tree',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Bell',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Feather',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Coin',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Strawberry',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Birdhouse',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Envelope',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Camel',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form8/Level15_16/',
        level: 'Level 15/16',
        subtests: [
          {
            acronym: 'VA',
            title: 'Flying Bird',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Glasses',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Flower',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Umbrella',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Deer',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Musical Note',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Star',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'T-shirt',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Pineapple',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
      {
        path: '/img/assignmentHelpGuide/CogAT/Form8/Level17_18/',
        level: 'Level 17/18',
        subtests: [
          {
            acronym: 'VA',
            title: 'Crown',
            battery: batterySubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VA'],
          },
          {
            acronym: 'SC',
            title: 'Tree',
            battery: batterySubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['SC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['SC'],
          },
          {
            acronym: 'VC',
            title: 'Eyeball',
            battery: batterySubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['VC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['VC'],
          },
          {
            acronym: 'NA',
            title: 'Horn',
            battery: batterySubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NA' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NA'],
          },
          {
            acronym: 'NP',
            title: 'Wheel',
            battery: batterySubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NP' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NP'],
          },
          {
            acronym: 'NS',
            title: 'Jump Rope',
            battery: batterySubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['NS' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['NS'],
          },
          {
            acronym: 'FM',
            title: 'Cloud',
            battery: batterySubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FM' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FM'],
          },
          {
            acronym: 'PF',
            title: 'Lemon',
            battery: batterySubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['PF' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['PF'],
          },
          {
            acronym: 'FC',
            title: 'Tiger',
            battery: batterySubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            subtest: textSubTestAcronyms['FC' as keyof ITextSubTestAcronyms],
            number: numberSubTestAcronyms['FC'],
          },
        ],
      },
    ],
  },
];
