import React, { useState, useEffect, useMemo } from 'react';

import { ReactComponent as IconCheckbox } from '../../../assets/icons/checkbox.svg';
import { ReactComponent as IconCheck } from '../../../assets/icons/check.svg';

import './Checkbox.css';

export interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  border?: boolean;
  radio?: boolean;
  className?: string;
  label?: string;
  labelText?: string;
  title?: string;
  id?: string;
  name?: string;
  handleChange?: (isChecked: boolean) => void;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = (props): JSX.Element => {
  // const {
  //     checked,
  //     disabled,
  //     border,
  //     radio,
  //     className,
  //     label,
  //     labelText,
  //     id,
  //     name,
  //     handleChange,
  // } = props;

  const [isChecked, setIsChecked] = useState(props.checked);

  useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked]);

  const onChange = (e: React.ChangeEvent) => {
    setIsChecked(!isChecked);
    if (props.handleChange && typeof props.handleChange === 'function') {
      props.handleChange(!isChecked);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
      setIsChecked(!isChecked);
      if (props.handleChange && typeof props.handleChange === 'function') {
        props.handleChange(!isChecked);
      }
    }
  };

  const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
    type: props?.radio ? 'radio' : 'checkbox',
    checked: isChecked,
    onChange: onChange,
  };
  if (props.disabled) {
    inputProps.disabled = !!props.disabled;
  }
  if (props.id) {
    inputProps.id = props.id;
  }
  if (props.name) {
    inputProps.name = props.name;
  }

  const text = typeof props.labelText !== 'undefined' ? props.labelText : props.label;

  const checkboxClassName = useMemo(() => {
    return (
      'checkbox-custom' +
      (props.className ? ' ' + props.className : '') +
      (props.border && props.border === true ? ' border' : '') +
      (props.radio && props.radio === true ? ' radio' : '') +
      (props.disabled && props.disabled === true ? ' el-disabled-input' : '') +
      (props.radio && props.radio === true && isChecked ? ' radio-checked' : '')
    );
  }, [props.className, props.border, props.radio, props.disabled, isChecked]);

  return (
    <div
      className={checkboxClassName}
      role="checkbox"
      aria-checked={isChecked}
      aria-label={props.label}
      title={props.label}
      tabIndex={props.disabled && props.disabled === true ? -1 : 0}
      onKeyDown={onKeyDown}
    >
      <label>
        <span className="icons-wrapper">
          <IconCheckbox />
          {isChecked ? <IconCheck /> : ''}
        </span>
        <input {...inputProps} />
        {text}
      </label>
    </div>
  );
};

export default Checkbox;
