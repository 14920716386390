import React, { useState, useEffect } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import ErrorIcon from '@mui/icons-material/Error';

import './AppAction.css';

export interface AppActionProps {
  inProgress?: boolean;
  progressType?: 'determinate' | 'indeterminate' | 'buffer' | 'query' | undefined;
  progress?: number;
  progressTitle?: string;
  progressSubtitle?: string | React.ReactNode;
  error?: boolean;
  errorTitle?: string | React.ReactNode;
  errorSubtitle?: string | React.ReactNode;
  success?: boolean;
  successTitle?: string | React.ReactNode;
  successSubtitle?: string | React.ReactNode;
  defaultTitle?: string | React.ReactNode;
  defaultSubtitle?: string | React.ReactNode;
  className?: string;
  divAttributes?: React.HTMLAttributes<HTMLDivElement>;
  customBody?: React.ReactNode | null;
  footer?: string | React.ReactNode | React.ReactNode[] | null;
  titleId?: string;
  subtitleId?: string;
}

const AppAction: React.FC<AppActionProps> = (props) => {
  const [title, setTitle] = useState(props.defaultTitle);
  // const [subtitle, setSubtitle] = useState('');

  useEffect(() => {
    if (props.error) {
      setTitle(props.errorTitle);
    } else if (props.inProgress) {
      setTitle(props.progressTitle);
    } else if (props.success) {
      setTitle(props.successTitle);
    } else {
      setTitle(props.defaultTitle);
    }
  }, [props.error, props.inProgress, props.success]);

  const renderDefault = () => {
    const viewTitle = props.defaultTitle;
    const viewSubtitle = props.defaultSubtitle;
    return (
      <div className="app-action-main">
        <h2 className="app-action-title" id={props.titleId}>
          {viewTitle}
        </h2>
        {viewSubtitle !== '' && (
          <div className="app-action-body">
            <div className="app-action-subtitle" id={props.subtitleId}>
              {viewSubtitle}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderError = () => {
    const viewTitle = props.errorTitle;
    const viewSubtitle = props.errorSubtitle;
    return (
      <div className="app-action-main app-action-main-error">
        <h2 className="app-action-title" id={props.titleId}>
          {viewTitle}
        </h2>
        {viewSubtitle !== '' && (
          <div className="app-action-body">
            <div className="app-action-icon">
              <ErrorIcon />
            </div>
            <div className="app-action-subtitle" id={props.subtitleId}>
              {viewSubtitle}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderSuccess = () => {
    const viewTitle = props.successTitle;
    const viewSubtitle = props.successSubtitle;
    return (
      <div className="app-action-main app-action-main-success">
        <h2 className="app-action-title" id={props.titleId}>
          {viewTitle}
        </h2>
        {viewSubtitle !== '' && (
          <div className="app-action-body">
            <div className="app-action-subtitle" id={props.subtitleId}>
              {viewSubtitle}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderProgress = () => {
    const viewTitle = props.progressTitle;
    const viewSubtitle = props.progressSubtitle;
    return (
      <div className="app-action-main app-action-main-progress" aria-live="polite">
        <h2 className="app-action-title">{viewTitle}</h2>
        <div className="app-action-body">
          <LinearProgress
            id={props.titleId}
            className={`app-action-progress app-action-progress-${
              props.progressType ? props.progressType : 'default'
            }`}
            variant={props.progressType}
            value={props?.progress || 0}
          />

          {viewSubtitle !== '' && (
            <div className="app-action-subtitle" id={props.subtitleId}>
              {viewSubtitle}
            </div>
          )}
        </div>
      </div>
    );
  };

  let componentBody: React.ReactNode | null = null;
  if (props.customBody !== null) {
    componentBody = props.customBody;
  } else if (props.error) {
    componentBody = renderError();
  } else if (props.inProgress) {
    componentBody = renderProgress();
  } else if (props.success) {
    componentBody = renderSuccess();
  } else {
    componentBody = renderDefault();
  }
  return (
    <div
      {...props.divAttributes}
      role="region"
      aria-label={`${title}`}
      aria-describedby="app-action-info"
      aria-busy={props.inProgress}
      className={`app-action-wrapper${props.className ? ' ' + props.className : ''}`}
    >
      {componentBody}
      {props.footer !== null && props.footer && (
        <div className="app-action-footer-wrapper">{props.footer}</div>
      )}
    </div>
  );
};

AppAction.defaultProps = {
  inProgress: false,
  progressType: 'indeterminate',
  progress: 0,
  progressTitle: 'Please wait',
  progressSubtitle: '',
  error: false,
  errorTitle: 'Error',
  errorSubtitle: 'An Error Occured',
  success: false,
  successTitle: 'Success',
  successSubtitle: 'Operation Completete',
  defaultTitle: 'Please Wait',
  defaultSubtitle: '',
  className: '',
  divAttributes: {} as React.HTMLAttributes<HTMLDivElement>,
  customBody: null,
  footer: null,
  titleId: 'app-action-title',
  subtitleId: 'app-action-subtitle',
};

export default AppAction;
