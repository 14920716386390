/*

// Usage for hook:

usePageTitle('Title text');

useEffect(() => {
    // Clear title on unmount if necessary
    return () => {
        dispatch({type: 'SET_PAGE_TITLE', payload: null});
    };
}, []);

*/
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

const usePageTitle = (pageTitle: string): void => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'SET_PAGE_TITLE', payload: pageTitle });
  }, [pageTitle]);
};

export default usePageTitle;
