import AppHelper from '../../../components/Proctoring/AppHelperOLD';
import { IClosedAssignment } from '../Proctor/studentListReducer';

const arrStudentAssignmentList: any = [];
const arrClosedAssignmentList: IClosedAssignment[] = [];

const initState = {
  studentAssignmentList: arrStudentAssignmentList.slice(0),
  filteredAfterSearchStudentAssignmentList: arrStudentAssignmentList.slice(0),
  searchText: '',
  arrStudentClosedAssignments: arrClosedAssignmentList.slice(0),
  arrStudentClosedAssignmentIds: [],
};

const studentAssignmentListReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'ADD_STUDENT_ASSIGNMENTS':
      const newAddStudentAssignemtsState = Object.assign({}, state); //clone the state
      newAddStudentAssignemtsState.studentAssignmentList = [];
      newAddStudentAssignemtsState.filteredAfterSearchStudentAssignmentList = [];
      for (const assignment of action.payload.arrAssignments) {
        newAddStudentAssignemtsState.studentAssignmentList.push(assignment);
        newAddStudentAssignemtsState.filteredAfterSearchStudentAssignmentList.push(assignment);
      }
      return newAddStudentAssignemtsState;
    case 'FILTER_STUDENT_ASSIGNMENTS_BY_TEXT':
      const newState = Object.assign({}, state); //clone the state
      const value = action.payload.value;
      //console.log(value);
      if (value) {
        const filteredValues = state.studentAssignmentList.filter((studentAssessment: any) => {
          return (
            studentAssessment.assignmentName +
            ' ' +
            studentAssessment.assignmentType +
            ' ' +
            AppHelper.assignmentTestGroupScreeningOrPostScreener(studentAssessment) +
            ' ' +
            AppHelper.fullTextSubTestAcronym(studentAssessment)
          )
            .toLowerCase()
            .includes(value.toLowerCase());
        });
        newState.filteredAfterSearchStudentAssignmentList = filteredValues;
        newState.searchText = value;
      } else {
        newState.filteredAfterSearchStudentAssignmentList = newState.studentAssignmentList.slice(0);
        newState.searchText = '';
      }
      //console.log(filteredValues);
      return newState;
    case 'UPDATE_STUDENT_CLOSED_ASSIGNMENTS':
      const newUpdateStudentClosedAssignments = Object.assign({}, state); //clone the state
      const arrStudentClosedAssignmentIds = action.payload.map(
        (n: IClosedAssignment) => n.assignmentId,
      );
      newUpdateStudentClosedAssignments.arrStudentClosedAssignments = action.payload;
      newUpdateStudentClosedAssignments.arrStudentClosedAssignmentIds =
        arrStudentClosedAssignmentIds;
      newUpdateStudentClosedAssignments.studentAssignmentList =
        newUpdateStudentClosedAssignments.studentAssignmentList.filter((n: any) => {
          return (
            arrStudentClosedAssignmentIds.indexOf(n.assignmentId) === -1 ||
            (arrStudentClosedAssignmentIds.indexOf(n.assignmentId) !== -1 &&
              (n.assignmentStatusId === '1' ||
                n.assignmentStatusId === '2' ||
                n.assignmentStatusId === '5'))
          );
        });
      newUpdateStudentClosedAssignments.filteredAfterSearchStudentAssignmentList =
        newUpdateStudentClosedAssignments.filteredAfterSearchStudentAssignmentList.filter(
          (n: any) => {
            return (
              arrStudentClosedAssignmentIds.indexOf(n.assignmentId) === -1 ||
              (arrStudentClosedAssignmentIds.indexOf(n.assignmentId) !== -1 &&
                (n.assignmentStatusId === '1' ||
                  n.assignmentStatusId === '2' ||
                  n.assignmentStatusId === '5'))
            );
          },
        );
      return newUpdateStudentClosedAssignments;

    default:
      return state;
  }
};

export default studentAssignmentListReducer;
