import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { AppLoader, ModalDialog } from '@riversideinsights/elevate-react-lib';
import { Button } from '@mui/material';
import * as Ably from 'ably';

// Load hooks
import useStudentUser from '../../../hooks/useStudentUser';
import useAppStatus from '../../../hooks/useAppStatus';
import useAppLogger from '../../../hooks/useAppLogger';
import useStudentChannel from '../../../hooks/ably/useStudentChannel';
import { AppState, PresenceData } from '../../../reducers/appDataTypes';
import useWrapperClasses from '../../../hooks/useWrapperClasses';

// Load layout elements
import AppNotificationList from '../AppNotification/AppNotificationList';
import AppModals from '../AppModals/AppModals';

import ErrorPage from '../../../pages/ErrorPage';
import SectionLoader from '../AppSection/SectionLoader';
import SectionError from '../AppSection/SectionError';
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import useNotificationHelper from '../../../hooks/useNotificationHelper';
import { useSelector, useDispatch } from 'react-redux';
import {
  ablyProctorIdentifierForStudent,
  ablyStudentIdentifier,
} from '../../../lib/util/ably.util';

// Load section pages
const StudentLandingPage = lazy(() => import('../../../pages/proctoring/StudentLandingPage'));
const StudentTestPage = lazy(() => import('../../../pages/proctoring/StudentTestPage'));
const StudentDebugPage = lazy(() => import('../../../pages/students/StudentDebugPage'));

const AppSectionStudent: React.FunctionComponent = () => {
  const config = useConfigContext();
  // Note that this should be the ONLY call to useStudentUser and it should be invoked only once per (async) page load.
  // Page components should use useStudentCachedUser instead.
  const user = useStudentUser({
    noRedirect: false,
    silent: false,
    loginPath: '/administration/auth/login/student',
    logoutPath: '/administration/auth/logout/student',
  });

  const wrapperClasses = useWrapperClasses(
    'proctoring-only-bootstrap-reboot proctoring-only-global-styles',
  );
  const logger = useAppLogger('COMPONENT|AppSectionStudent');
  const appStatus = useAppStatus();
  const NotificationHelper = useNotificationHelper();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const ablyState = useSelector((state: AppState) => {
    return state.ably;
  });

  const ablyGlobalProctorChannel = useRef<Ably.Types.RealtimeChannelCallbacks | null>(null);
  const ablyUserChannel = useRef<Ably.Types.RealtimeChannelCallbacks | null>(null);
  const activeStudentComponent = useRef<string>('');

  const [studentAblyStarted, setStudentAblyStarted] = useState(false);
  const [isPopupAnotherUserDetectedVisible, setIsPopupAnotherUserDetectedVisible] = useState(false);
  const [isPopupInactiveTabVisible, setIsPopupInactiveTabVisible] = useState(false);

  useStudentChannel({
    init: true,
    namespace: 'AppSectionStudent',
  });

  const disconnectAbly = () => {
    logger.info('ABLY: Disconnecting');
    detachChannel(ablyGlobalProctorChannel.current);
    detachChannel(ablyUserChannel.current);
  };

  const detachChannel = (channel: Ably.Types.RealtimeChannelCallbacks | null) => {
    if (channel != null) {
      logger.info(`ABLY: detachChannel ${channel.name}`);
      channel.unsubscribe();
      channel.detach();
    }
  };

  useEffect(() => {
    logger.debug('Mounting Students section');
    return () => {
      logger.debug('Unmounting Students section');
      disconnectAbly();
    };
  }, []);

  useEffect(() => {
    if (ablyState.ready && ablyState.ablyRealtime !== null && !studentAblyStarted) {
      setStudentAblyStarted(true);
      joinProctorChannel();
      joinUserChannel();
    }
  }, [ablyState.ready, ablyState.ablyRealtime]);

  useEffect(() => {
    // This reference supports handling for preventing a student from logging in multiple time
    // location.pathname is not available / updated in the callback, but the logic here needs to handle the StudentTest component slightly differently
    // (i.e. we need to inform learnocity about an inactive tab)
    activeStudentComponent.current = location.pathname;
  }, [location]);

  useEffect(() => {
    if (isPopupAnotherUserDetectedVisible) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="another-student-detected"
            disableClose={true}
            disableCloseOnEsc={true}
            closeOnMaskClick={false}
            title="Session active"
            body={
              <div className="another-student-detected-message">
                You&apos;re already logged in as {user.info.firstName} {user.info.lastName}. Log in
                again?
              </div>
            }
            footer={
              <div className="button-wrapper">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsPopupAnotherUserDetectedVisible(false);
                    logout();
                  }}
                >
                  No
                </Button>
                <Button
                  className="default-modal-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsPopupAnotherUserDetectedVisible(false);
                    sendMessageLogoutOtherStudentInstances();
                  }}
                >
                  Yes
                </Button>
              </div>
            }
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [isPopupAnotherUserDetectedVisible]);

  useEffect(() => {
    if (isPopupInactiveTabVisible) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="another-student-detected"
            disableClose={true}
            disableCloseOnEsc={true}
            closeOnMaskClick={false}
            title="Inactive Tab"
            body={
              <div className="inactive-tab-message">
                This tab was set to inactive because you were working in another tab. Please close
                this tab.
              </div>
            }
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [isPopupInactiveTabVisible]);

  const logout = () => {
    history.push('/administration/auth/logout/student');
  };

  if (!user.loggedIn) {
    return null;
  }

  const getAblyUserId = (): string =>
    config.proctoring.ably.connectionUserPrefix + user.info.globalStudentId;

  const joinProctorChannel = () => {
    if (ablyState.ablyRealtime !== null) {
      const proctorChannelId = ablyProctorIdentifierForStudent(user);
      logger.info(`ABLY: join proctor channel ${proctorChannelId}`);
      const channelOptions = {
        modes: ['PRESENCE'] as Ably.Types.ChannelMode[],
      };
      if (ablyGlobalProctorChannel.current === null) {
        ablyGlobalProctorChannel.current = ablyState.ablyRealtime.channels.get(
          ablyProctorIdentifierForStudent(user),
          channelOptions,
        );
      } else if (['attached', 'attaching'].indexOf(ablyGlobalProctorChannel.current.state) === -1) {
        ablyGlobalProctorChannel.current.setOptions(channelOptions);
        ablyGlobalProctorChannel.current.attach();
      }
      ablyGlobalProctorChannel.current.presence.enter();
    }
  };

  const joinUserChannel = () => {
    // this flag will be true if the district is configured to require students to use a kiosk browser
    const isKioskBrowserRequired =
      user.info.isKioskBrowserEnabled?.toString() === 'true' ||
      user.info.isKioskBrowserEnabled === true;
    // this flag will be true this session is using a kiosk browser
    const isKioskBrowserActive = localStorage.getItem('isKioskBrowser') ? true : false;

    if (ablyState.ablyRealtime !== null) {
      const userChannelId = getAblyUserId();
      logger.info(`ABLY: join user channel ${userChannelId}`);
      const userChannel = ablyState.ablyRealtime.channels.get(userChannelId);
      ablyUserChannel.current = userChannel;

      userChannel.presence.get((_err: any, members: any) => {
        members = members.filter(
          (n: any) => n.data.currentSessionId !== appStatus.currentSessionId,
        );
        if (members.length >= 1) {
          if (isKioskBrowserRequired && isKioskBrowserActive) {
            // if kiosk browser setting is enabled and the current browser is kiosk browser, auto-logout all other users
            // see GP1-14128 for details
            sendMessageLogoutOtherStudentInstances();
          } else if (isKioskBrowserRequired && !isKioskBrowserActive) {
            // if kiosk browser setting is enabled and the current browser is NOT kiosk browser, take no action
            // see GP1-14128 for details
            logger.info('ABLY: duplicate login ignored due to kiosk browser setting');
          } else {
            // if no kiosk browser settings are active, inform user of the duplicate login
            logger.info(
              `ABLY: duplicate login - ${members.length} user(s) present in ${userChannelId}`,
            );
            setIsPopupAnotherUserDetectedVisible(true);
          }
        } else {
          logger.info(`ABLY: no other users present in ${userChannelId}`);
        }
        logger.info(`ABLY: entering presence for ${userChannelId} as ${getAblyUserId()}`);
        const presenceData: PresenceData = {
          currentSessionId: appStatus.currentSessionId,
        };
        userChannel.presence.enterClient(ablyStudentIdentifier(user), presenceData);
      });

      logger.info(`ABLY: Subscribing to messages on ${userChannel.name}`);
      userChannel.subscribe(userChannelId, (message: any) => {
        logger.info(`ABLY: Message in on ${userChannel.name}: ${message.data.message}`);
        handleRemoteEvent(message.data);
      });
    }
  };

  const handleRemoteEvent = (remoteEvent: any) => {
    if (remoteEvent.type === 501 && remoteEvent.message === 'LogoutOtherUserInstances') {
      const remoteConnectionId = remoteEvent.wrappedData.connectionId;
      const currentConnectionId = ablyState.ablyRealtime?.connection.id;
      const isSameBrowser =
        localStorage.getItem('currentWindowId') === remoteEvent.wrappedData.currentWindowId;
      logger.info(
        `ABLY: LogoutOtherUserInstances|remote ${remoteConnectionId}|current ${currentConnectionId}|isSameBrowser ${isSameBrowser}|component ${activeStudentComponent.current}`,
      );
      // If the student is on the test page, we dispatch this event to tell learnosity that the test is offline for this session - student will continue in the other session
      if (activeStudentComponent.current === '/student/test') {
        logger.info('ABLY: Send test offline event to Learnocity');
        dispatch({ type: 'IS_STUDENT_LOGOUT_STARTED_FROM_TEST_PAGE', payload: true });
      }
      if (remoteConnectionId !== currentConnectionId) {
        if (isSameBrowser) {
          doTabInactive();
        } else {
          NotificationHelper.add('New login detected. Logging you out of this session.', 'warning');
          setTimeout(() => {
            logout();
          }, 1000);
        }
      }
    }
  };

  const doTabInactive = () => {
    disconnectAbly();
    setIsPopupInactiveTabVisible(true);
  };

  const sendRemoteEvent = (remoteEvent: any) => {
    const currentWindowId = appStatus.currentWindowId;
    const userChannel = ablyUserChannel.current;
    localStorage.setItem('currentWindowId', currentWindowId);
    if (userChannel !== null) {
      userChannel.publish(remoteEvent.toUser, remoteEvent, (err: any) => {
        if (err) {
          logger.error(`ABLY: Message failed on ${userChannel.name}: ${err.message}`);
        } else {
          logger.info(
            `ABLY: Message out|user ${remoteEvent.toUser}|channel ${userChannel.name}|${remoteEvent.message}`,
          );
        }
      });
    }
  };

  const sendMessageLogoutOtherStudentInstances = () => {
    const currentWindowId = appStatus.currentWindowId;
    const connectionId = ablyState.ablyRealtime?.connection.id;
    const remoteEvent = {
      type: 501,
      user: getAblyUserId(),
      toUser: getAblyUserId(),
      message: 'LogoutOtherUserInstances',
      wrappedData: { connectionId, currentWindowId },
    };
    logger.debug(
      `ABLY: send LogoutOtherUserInstances|connectionId ${connectionId}|currentWindowId ${currentWindowId}`,
    );
    sendRemoteEvent(remoteEvent);
  };

  return (
    <div className={wrapperClasses}>
      <AppModals />
      <div className={`app-layout-root ${appStatus.pageClassName}`}>
        <Suspense fallback={<AppLoader title="Please Wait" message="Initializing" />}>
          <Switch>
            {/* Redirect to default section page */}
            <Redirect exact from="/student" to="/student/landing-page" push={false} />

            {/* Routes that call API or rely on user data upon render
             * do need user already loaded, so we don't render them
             * until that happens/
             */}
            <Route
              path="/student/landing-page"
              render={(routeProps: RouteComponentProps) => {
                if (!user.loggedIn) {
                  return null;
                } else {
                  return <StudentLandingPage {...routeProps} />;
                }
              }}
            />

            <Route
              path="/student/test"
              render={(routeProps: RouteComponentProps) => {
                if (!user.loggedIn) {
                  return null;
                } else {
                  return <StudentTestPage {...routeProps} />;
                }
              }}
            />

            <Route
              path="/student/student-debug"
              render={(routeProps: RouteComponentProps) => {
                return <StudentDebugPage {...routeProps} />;
              }}
            />

            <Route render={() => <ErrorPage httpErrorCode={404} />} />
          </Switch>
        </Suspense>

        {(appStatus.appError || appStatus.appErrorFatal) && <SectionError />}
        {!(appStatus.appError || appStatus.appErrorFatal) &&
          (appStatus.loadingUser || appStatus.appBusy) && <SectionLoader />}
      </div>
      <AppNotificationList />
    </div>
  );
};

export default AppSectionStudent;
