import LogRocket from 'logrocket';
import useAppLogger from '../useAppLogger';

export enum TrackMetricsKey {
  AblyPing = 'Ably ping',
}

// The LogRocket package is not exporting its types, so we need to
// create our own interface here for the "TrackEventProperties" type.
interface TrackMetrics {
  [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined;
}

/**
 * Returns a hook function to help with tracking any metric in our LogRocket tool.
 * @param {TrackMetricsKey} trackMetricsTitle - the identifier for the metric to track
 * @returns {trackMetrics} - a function to track the metric
 */

const useTrackMetrics = <T>(trackMetricsTitle: TrackMetricsKey) => {
  // We're not doing a check for the LogRocket_Recording flag enabled here.
  // If we call LogRocket.track prior to LogRocket.init being called, nothing will happen.

  const logger = useAppLogger('HOOK|useTrackMetric');

  const trackMetrics = (metric: T) => {
    logger.info(`Tracking ${trackMetricsTitle}: ${JSON.stringify(metric)}`);

    // LogRocket wants key/value pairs, but we want an object.
    const convertedTrackMetrics = metric as unknown as TrackMetrics;

    LogRocket.track(trackMetricsTitle, convertedTrackMetrics);
  };

  return { trackMetrics };
};

export default useTrackMetrics;
