import moment from 'moment';

const arrChat = [
  {
    isIncoming: true,
    datetime: '01/22/2021, 15:30',
    text: 'Aliquam pulvinar purus quis sem viverra laoreet. Vestibulum porttitor elit ut diam gravida, id condimentum quam finibus.',
  },
  {
    isIncoming: true,
    datetime: '01/22/2021, 15:31',
    text: 'Aliquam pulvinar purus quis sem viverra laoreet. Vestibulum porttitor elit ut diam gravida, id condimentum quam finibus.',
  },
  {
    isIncoming: false,
    datetime: '01/22/2021, 15:35',
    text: 'Aliquam pulvinar purus quis sem viverra laoreet. Vestibulum porttitor elit ut diam gravida, id condimentum quam finibus. Aliquam pellentesque diam eu congue iaculis.',
  },
  {
    isIncoming: true,
    datetime: '01/22/2021, 15:37',
    text: 'Aliquam pulvinar purus quis sem viverra laoreet. Vestibulum porttitor elit ut diam gravida, id condimentum quam finibus.',
  },
];

const initState = {
  isActive: false,
  isFocusDelayed: false,
  chat: arrChat.slice(0),
};

const studentChatPanelReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'STUDENT_CHAT_PANEL_DELAY':
      return {
        ...state,
        isFocusDelayed: action.payload,
      };
    case 'SHOW_STUDENT_CHAT_PANEL':
      return {
        ...state,
        isActive: true,
      };
    case 'HIDE_STUDENT_CHAT_PANEL':
      return {
        ...state,
        isActive: false,
      };
    case 'ADD_STUDENT_CHAT_MESSAGE_FROM_STUDENT':
      const newMessageFromState = Object.assign({}, state); //clone the state
      newMessageFromState.chat.push({
        isIncoming: false,
        datetime: moment().format('MM/DD/YYYY, HH:mm'),
        text: action.payload,
      });
      return newMessageFromState;
    default:
      return state;
  }
};

export default studentChatPanelReducer;
