import React from 'react';

import useNotificationHelper from '../../../hooks/useNotificationHelper';
import AppNotificationComponent from './AppNotificationComponent';

import './AppNotificationList.css';

const AppNotificationList: React.FunctionComponent = () => {
  const NotificationHelper = useNotificationHelper();

  return (
    <div className="app-notification-list">
      {NotificationHelper.notifications.map((notification) => {
        return (
          <AppNotificationComponent
            key={`app-notification-${notification.uuid}`}
            notification={notification}
          />
        );
      })}
    </div>
  );
};

export default AppNotificationList;
