import React from 'react';

import { AssignmentListItem } from '../../../TestAssignment';
import type { AssignmentListTableColumn } from '../TestAssignmentListTable';

const renderStatusCell = (item: AssignmentListItem, column: AssignmentListTableColumn) => {
  return (
    <span className={`assignment-status-value status-${item.status.toLowerCase()}`}>
      {item.status}
    </span>
  );
};
export default renderStatusCell;
