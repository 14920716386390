import React from 'react';

import TableFooter from '@mui/material/TableFooter';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import Pagination from '@mui/material/Pagination';

import { ElevateTableStatus, ElevateTableProps, ElevateTableRecord } from './ElevateTable.d';

const ElevateTableFooter = <T extends ElevateTableRecord>(
  props: ElevateTableProps<T>,
): React.ReactElement | null => {
  const page = Math.max(
    0,
    Math.floor(props.tableData.status.offset / props.tableData.status.limit),
  );
  const pages = Math.ceil(props.tableData.status?.totalRecords / props.tableData.status.limit);

  const handleSetPage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    const newOffset = Math.max(0, (newPage - 1) * props.tableData.status.limit);
    const tableStatusUpdates: ElevateTableStatus = {
      ...props.tableData.status,
      offset: newOffset,
    };
    if (props.onChange) {
      props.onChange(tableStatusUpdates);
    }
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    const newOffset = 0;
    const newLimit = +`${event.target.value}`;
    const ts: ElevateTableStatus = {
      ...props.tableData.status,
      offset: newOffset,
      limit: newLimit,
    };
    if (props.onChange) {
      props.onChange(ts);
    }
  };

  const tableStatus = props.tableData.status;

  const enabledRowsPerPage = props.tableData.options.rowsPerPageValues.filter(
    (val) => val - props.tableData.status.limit < props.tableData.status.totalRecords,
  );

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={props.tableData.columns.length}>
          <div className="elevate-table-pagination">
            <div className="elevate-table-pagination-left">
              <Pagination
                size="large"
                className="elevate-table-pagination-items"
                count={pages}
                page={page + 1}
                variant="outlined"
                shape="rounded"
                onChange={handleSetPage}
                disabled={
                  tableStatus.errorMessage !== '' || pages <= 1 || props.tableData.status.loading
                }
              />
              <div className="elevate-table-pagination-rows">
                <FormControl
                  size="small"
                  margin="none"
                  variant="outlined"
                  disabled={
                    tableStatus.errorMessage !== '' ||
                    enabledRowsPerPage.length === 0 ||
                    props.tableData.status.loading
                  }
                >
                  <Select
                    className="items-per-page-select"
                    labelId="items_per_page_label"
                    id="items_per_page_select"
                    value={props.tableData.status.limit}
                    onChange={handleChangeRowsPerPage}
                  >
                    {props.tableData.options.rowsPerPageValues.map((value) => {
                      return (
                        <MenuItem
                          className="items-per-page-entry"
                          key={`opt_${value}`}
                          value={value}
                        >
                          {value} Items per page
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            {!props.tableData.status.loading && props.tableData.status.totalRecords > 0 && (
              <div className="elevate-table-pagination-right">
                ITEMS{' '}
                {props.tableData.status?.totalRecords ? props.tableData.status.offset + 1 : '0'} -{' '}
                {Math.min(
                  props.tableData.status.offset + props.tableData.status.limit,
                  props.tableData.status?.totalRecords,
                )}
              </div>
            )}
            {props.tableData.status.errorMessage !== '' && (
              <div className="elevate-table-pagination-right">LOAD ERROR</div>
            )}
            {props.tableData.status.errorMessage === '' &&
              !props.tableData.status.loading &&
              props.tableData.status.totalRecords === 0 && (
                <div className="elevate-table-pagination-right">NO ITEMS</div>
              )}
            {props.tableData.status.errorMessage === '' && props.tableData.status.loading && (
              <div className="elevate-table-pagination-right">LOADING</div>
            )}
          </div>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default ElevateTableFooter;
