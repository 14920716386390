import { AppReduxAction } from '../../appDataTypes';

const initState = {
  isActive: false,
  title: '',
  notes: [],
};

const notesPanelReducer = (state = initState, action: AppReduxAction) => {
  switch (action.type) {
    case 'SHOW_NOTES_PANEL':
      return {
        ...state,
        isActive: true,
        title: 'Notes for ' + action.payload.title,
        notes: action.payload.notes,
      };
    case 'HIDE_NOTES_PANEL':
      return {
        ...state,
        isActive: false,
      };
    default:
      return state;
  }
};

export default notesPanelReducer;
