import React, { useMemo } from 'react';

export interface SessionTimeoutTimerProps {
  remainingSeconds: number;
}

const SessionTimeoutTimer: React.FC<SessionTimeoutTimerProps> = (props) => {
  const getMinutesLeftElement = () => {
    const minutesLeft = Math.floor(props.remainingSeconds / 60);

    if (minutesLeft) {
      return (
        <>
          <span className="time-left">{minutesLeft}</span>
          &nbsp;
          {minutesLeft === 1 ? 'minute' : 'minutes'}
        </>
      );
    }

    return null;
  };

  const getSecondsLeftElement = () => {
    const minutesLeft = Math.floor(props.remainingSeconds / 60);
    const secondsLeft = props.remainingSeconds % 60;

    if (secondsLeft || !minutesLeft) {
      return (
        <>
          <span className="time-left">{secondsLeft}</span>
          &nbsp;
          {secondsLeft === 1 ? 'second' : 'seconds'}
        </>
      );
    }

    return null;
  };

  const timerValue = useMemo<React.ReactNode>(() => {
    return (
      <React.Fragment>
        {getMinutesLeftElement()}
        &nbsp;
        {getSecondsLeftElement()}
      </React.Fragment>
    );
  }, [props.remainingSeconds]);

  return <p>{timerValue}</p>;
};

export default SessionTimeoutTimer;
