import React, { useEffect, useMemo, useRef } from 'react';

// import LinearProgress from '@mui/material/LinearProgress';

// import ErrorIcon from '@mui/icons-material/Error';
import useCachedUser from '../../../../../hooks/useCachedUser';
import { useDispatch } from 'react-redux';
import { ModalDialog } from '@riversideinsights/elevate-react-lib';
import useNotificationHelper from '../../../../../hooks/useNotificationHelper';
// import { StaffAuthUser, StaffAuthUserUpdate } from '../../../../reducers/Auth/authDataTypes';
import UserHelper from '../../../../../lib/helper/UserHelper';
import useAppLogger from '../../../../../hooks/useAppLogger';
import { Button } from '@mui/material';
import { TbCopy, TbExternalLink, TbX } from 'react-icons/tb';
import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

export interface AppDebugIdTokenModalProps {
  modalVisible: boolean;
  hideModal?: () => void;
}

const AppDebugIdTokenModal: React.FC<AppDebugIdTokenModalProps> = (props) => {
  const config = useConfigContext();
  const user = useCachedUser();
  const logger = useAppLogger('AppDebugIdTokenModal');
  const dispatch = useDispatch();
  const NotificationHelper = useNotificationHelper();
  const userDataFieldRef = useRef<HTMLTextAreaElement | null>(null);

  const userDataJson = useMemo<string>(() => {
    let userString = '';
    const idToken = user.identity.idToken;
    if (idToken) {
      const decodedToken = UserHelper.parseStaffToken(idToken, config);
      try {
        userString = JSON.stringify(decodedToken, null, 4);
      } catch (ex: unknown) {
        const err = ex as Error;
        logger.error(`${err.message}`);
      }
    }
    return userString;
  }, [user.identity.idToken]);

  const hideModal = () => {
    if (props?.hideModal) {
      props.hideModal();
    }
  };

  const openJsonInNewTab = (json: string): void => {
    const link = document.createElement('a');
    const blob = new Blob([json], { type: 'text/json;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const openInNewTab = () => {
    openJsonInNewTab(userDataJson);
  };

  const copyUserToClipboard = () => {
    navigator.clipboard.writeText(userDataJson).then(
      () => {
        // setUserCopied(true);
        NotificationHelper.add('User idToken copied to clipboard as JSON', 'info');
        if (props?.hideModal) {
          setTimeout(() => {
            hideModal();
          }, 1000);
        }
      },
      function () {
        NotificationHelper.add('Failed copying idToken to clipboard', 'error');
        // setUserCopied(false);
      },
    );
  };
  useEffect(() => {
    if (props.modalVisible) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="app-debug-test-modal app-debug-user-details-modal"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            title="Decoded user idToken"
            footer={
              <div className="app-debug-modal-footer">
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<TbCopy />}
                  onClick={copyUserToClipboard}
                >
                  Copy
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<TbExternalLink />}
                  onClick={openInNewTab}
                >
                  Open in new tab
                </Button>
                <Button variant="contained" color="primary" endIcon={<TbX />} onClick={hideModal}>
                  Close
                </Button>
              </div>
            }
            body={
              <div className="app-debug-user-modal-contents">
                {userDataJson !== '' && (
                  <textarea
                    ref={userDataFieldRef}
                    className="app-debug-user-data-field"
                    defaultValue={userDataJson}
                  ></textarea>
                )}
              </div>
            }
            onClose={() => {
              hideModal();
            }}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [props.modalVisible]);
  return <></>;
};

export default AppDebugIdTokenModal;
