import React from 'react';

import { useHistory } from 'react-router-dom';

import useAppStatus from '../../../hooks/useAppStatus';
import { BiError } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';

const SectionError: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const appStatus = useAppStatus();

  const goToLogout = () => {
    history.push('/administration/auth/logout');
  };

  const clearFatalErrorAndLogout = () => {
    dispatch({ type: 'SET_APP_ERROR_FOOTER', payload: null });
    dispatch({ type: 'SET_APP_ERROR_MESSAGE', payload: '' });
    dispatch({ type: 'SET_APP_ERROR_FATAL', payload: false });
    goToLogout();
  };

  return (
    <div className="app-loader-root app-error-root">
      <h2 className="app-error-title">{appStatus.appErrorFatal ? 'Fatal' : ''} Error</h2>
      <div className="app-error-body">
        <div className="app-error-icon">
          <BiError size="6rem" color="inherit" />
        </div>
        <div className="app-error-message">
          {appStatus.appErrorMessage || 'Unknown error occured.'}
        </div>
      </div>
      <div className="app-error-footer">
        {appStatus.appErrorFooter !== null && appStatus.appErrorFooter}

        {appStatus.appErrorFooter === null && appStatus.appErrorFatal && (
          <div>
            <Button variant="contained" color="primary" onClick={clearFatalErrorAndLogout}>
              Logout
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionError;
