import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from './Header/Header';
import styles from './PrintTestTakerTickets.module.scss';
import CheckboxSelect from '../CheckboxSelect/CheckboxSelect';
import { useDispatch } from 'react-redux';
import useCachedUser from '../../../hooks/useCachedUser';
import { School } from '../../../reducers/Auth/authDataTypes';
import useApiHelper from '../../../hooks/useApiHelper';
import { AxiosError } from 'axios';
import usePageTitle from '../../../hooks/usePageTitle';
import Toolbar from './Toolbar/Toolbar';
import { Grade, isDownloadableTicketCount, DOWNLOAD_TICKETS_MAX_TICKET_COUNT } from './util';
import useAppLogger from '../../../hooks/useAppLogger';
import { Location } from './PrintTestTakerTickets.d';
import { OverlayLoader } from '@riversideinsights/elevate-react-lib';
import useNotificationHelper from '../../../hooks/useNotificationHelper';

export interface PrintTestTakerTicketsProps {
  assignmentId: number;
}

const PrintTestTakerTickets = ({ assignmentId }: PrintTestTakerTicketsProps) => {
  usePageTitle('Print Test Taker Tickets');
  const ApiHelper = useApiHelper('Component|PrintTestTakerTickets');
  const dispatch = useDispatch();
  const history = useHistory();
  const logger = useAppLogger('Component|PrintTestTakerTickets');
  const user = useCachedUser();
  const NotificationHelper = useNotificationHelper();

  const [fetchingAssignment, setFetchingAssignment] = useState(false);
  const [assignmentName, setAssignmentName] = useState('');
  const [assignmentType, setAssignmentType] = useState('');
  const [grades, setGrades] = useState<Grade[]>([]);
  const [selectedGrades, setSelectedGrades] = useState<Grade[]>([]);

  const [fetchingSchools, setFetchingSchools] = useState(false);
  const [schools, setSchools] = useState<School[]>([]);
  const [selectedSchools, setSelectedSchools] = useState<School[]>([]);
  const [teacherId, setTeacherId] = useState('');

  const [fetchingLocations, setFetchingLocations] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);

  const [fetchingTicketCount, setFetchingTicketCount] = useState(false);
  const [ticketCount, setTicketCount] = useState(0);
  const [userGrades, setUserGrades] = useState<string[]>([]);

  useEffect(() => {
    dispatch({
      type: 'SET_HEADER_VISIBLE',
      payload: false,
    });

    return () => {
      dispatch({
        type: 'SET_HEADER_VISIBLE',
        payload: true,
      });
    };
  }, []);

  useEffect(() => {
    getDefaultGrades();
  }, []);

  useEffect(() => {
    if (userGrades.length > 0) {
      loadAssignment(String(assignmentId));
    }
  }, [userGrades]);

  const loadAssignment = async (assignmentId: string): Promise<void> => {
    try {
      setFetchingAssignment(true);
      const result = await ApiHelper.apiRequest('assignmentApiUrl', 'GetAssignmentById', {
        assignmentid: assignmentId,
      });

      if (result && result.data && result.data.length && result.data[0]) {
        setAssignmentName(result.data[0].assignmentname);
        dispatch({
          type: 'SET_PAGE_TITLE',
          payload: `${result.data[0].assignmentname} | Print Test Taker Tickets`,
        });
        setAssignmentType(result.data[0].assignmenttype);
        const assignmentGrades = JSON.parse(result.data[0].gradedomains).map(
          (gd: any) => gd.grade_id,
        );
        const filteredGrades = assignmentGrades.filter((item: string) => userGrades.includes(item));
        setGrades(filteredGrades);
      }
    } catch (exc: unknown) {
      const ex = exc as AxiosError;
      logger.error(ex);
    } finally {
      setFetchingAssignment(false);
    }
  };

  const getDefaultGrades = async () => {
    logger.info("Loading user's grades.");
    try {
      const apiRequestParams = {
        customer_id: user.customerId,
        user_id: user.userId,
        user_role: user.role,
      };
      const result = await ApiHelper.apiRequest('rostering', 'getGradesOfUser', apiRequestParams);
      if (result && result.status === 200) {
        const modifiedGrades = result.data.grades.map((grade: string) =>
          grade === '0' ? 'K' : grade,
        );
        setUserGrades(modifiedGrades);
      }
      logger.info("User's grades loaded.");
    } catch (ex: unknown) {
      const err = ex as AxiosError;
      logger.error(`Failed loading user's grades - ${err.message}.`);
    }
  };

  useEffect(() => {
    if (user.email) {
      loadSchools();
    }
  }, [user.email]);

  const loadSchools = async (): Promise<void> => {
    const requestPayload = {
      emailId: user.email,
      userRole: user.role,
    };

    try {
      setFetchingSchools(true);
      const result = await ApiHelper.apiRequest('rostering', 'userschools', requestPayload);
      setTeacherId(result.data.userId);
      setSchools(result.data.schools);
    } catch (ex) {
      logger.error(ex);
    } finally {
      setFetchingSchools(false);
    }
  };

  useEffect(() => {
    if (selectedGrades.length && selectedSchools.length) {
      loadLocations();
    } else {
      setLocations([]);
    }
  }, [selectedGrades, selectedSchools]);

  const loadLocations = async (): Promise<void> => {
    setFetchingLocations(true);

    const requestPayload =
      user.role === 'teacher' && teacherId
        ? {
            CustomerId: user.customerId,
            SchoolId: selectedSchools.map((school) => school.id),
            GradeFilter: selectedGrades.map((grade) => (grade === 'K' ? '0' : grade)),
            TeacherId: teacherId,
          }
        : {
            CustomerId: user.customerId,
            SchoolId: selectedSchools.map((school) => school.id),
            GradeFilter: selectedGrades.map((grade) => (grade === 'K' ? '0' : grade)),
          };

    try {
      const result = await ApiHelper.apiRequest(
        'rostering',
        'locationsectiondetails',
        requestPayload,
      );
      if (result && result.data) {
        setLocations(result.data);
      }
    } catch (exc: unknown) {
      const ex = exc as AxiosError;
      logger.error(ex);
    }
    setFetchingLocations(false);
  };

  useEffect(() => {
    if (selectedLocations.length) {
      fetchTestTakerTicketsCount();
    } else {
      setTicketCount(0);
    }
  }, [selectedLocations, selectedGrades]);

  useEffect(() => {
    if (ticketCount > DOWNLOAD_TICKETS_MAX_TICKET_COUNT) {
      NotificationHelper.add(
        'Maximum of ' +
          DOWNLOAD_TICKETS_MAX_TICKET_COUNT.toLocaleString() +
          ' test taker tickets may be printed at a time. ' +
          ticketCount.toLocaleString() +
          ' students are currently selected - please narrow criteria.',
        'warning',
      ); // 10000, GP1-11848
    }
  }, [ticketCount]);

  const fetchTestTakerTicketsCount = () => {
    setFetchingTicketCount(true);

    const requestData = {
      customerId: user.customerId,
      sectionId: selectedLocations.map((location) => location.id),
      grades: selectedGrades.map((grade) => (grade === 'K' ? '0' : grade)),
    };

    ApiHelper.apiRequest('rostering', 'testtakerticketscount', requestData)
      .then((result) => {
        if (result.data) {
          setTicketCount(Number(result.data));
        }
      })
      .catch((exc: unknown) => {
        const ex = exc as AxiosError;
        logger.error(ex);
      })
      .finally(() => {
        setFetchingTicketCount(false);
      });
  };

  const onClose = () => history.goBack();

  return (
    <div className={styles['page']}>
      <Header onClose={onClose} ticketCount={ticketCount} />

      <div className={styles['body']}>
        {fetchingAssignment ? (
          <OverlayLoader title="Loading Assignment" />
        ) : (
          <>
            <Toolbar
              disabledDownloadTicketsButton={!isDownloadableTicketCount(ticketCount)}
              grades={grades.sort((a, b) => (a === 'K' ? -1 : Number(a) - Number(b)))}
              onChangeSelectedGrades={setSelectedGrades}
              onDocumentDownload={onClose}
              testTakerTicketsData={{
                assignmentName: assignmentName,
                assignmentType: assignmentType,
                customerId: user.customerId,
                organizationId: user.customerId,
                sectionIds: selectedLocations.map((location) => location.id),
                grades: selectedGrades.map((grade) => (grade === 'K' ? '0' : grade)),
              }}
            />

            <CheckboxSelect
              defaultChecked
              disabled={fetchingSchools || fetchingLocations || fetchingTicketCount}
              loading={fetchingSchools}
              options={schools
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((school) => ({
                  label: school.name,
                  value: school.id,
                }))}
              optionTitle="School"
              onChange={(options) =>
                setSelectedSchools(
                  schools.filter(
                    (school) =>
                      !!options.find(
                        (option) => school.name === option.label && school.id === option.value,
                      ),
                  ),
                )
              }
            />

            <CheckboxSelect
              defaultChecked
              disabled={fetchingSchools || fetchingLocations || fetchingTicketCount}
              loading={fetchingLocations}
              options={locations
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((location) => ({
                  label: location.name,
                  value: location.id,
                }))}
              optionTitle="Class"
              onChange={(options) =>
                setSelectedLocations(
                  locations.filter(
                    (location) =>
                      !!options.find(
                        (option) => location.name === option.label && location.id === option.value,
                      ),
                  ),
                )
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PrintTestTakerTickets;
