import React from 'react';
import Collapse from '@mui/material/Collapse';

export interface AppDebugDrawerSectionProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  sectionHeader?:
    | React.ReactNode
    | React.ReactNode[]
    | React.ReactElement
    | React.ReactElement[]
    | Element
    | Element[];
  minimized: boolean;
  toggleMinimized: () => void;
}

const AppDebugDrawerSection: React.FC<AppDebugDrawerSectionProps> = (props) => {
  return (
    <div
      className={`app-debug-drawer-middle-section${
        props.minimized ? ' app-debug-drawer-middle-section-minimized' : ''
      }${props?.className ? ' ' + props.className : ''}`}
    >
      {typeof props.sectionHeader === 'undefined' && (
        <div className="app-debug-drawer-section-title">
          <span className="app-debug-drawer-section-title-left"></span>
          <span className="app-debug-drawer-section-title-text" onClick={props.toggleMinimized}>
            {props.title}
          </span>
          <span className="app-debug-drawer-section-title-right">
            <button
              onClick={props.toggleMinimized}
              className="link-button app-debug-drawer-section-window-control"
              title={props.minimized ? 'Show section' : 'Hide section'}
            >
              {props.minimized ? '↧' : '↥'}
            </button>
          </span>
        </div>
      )}
      {typeof props.sectionHeader !== 'undefined' && props.sectionHeader}
      <Collapse in={!props.minimized} collapsedSize={0}>
        <div className="app-debug-drawer-section-body">{props.children}</div>
      </Collapse>
    </div>
  );
};

export default AppDebugDrawerSection;
