import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NotFoundError from '../components/Errors/NotFoundError';
import UnauthorizedError from '../components/Errors/UnauthorizedError';

import '../assets/css/pages/ErrorPage.css';

export interface ErrorPageProps {
  httpErrorCode?: number;
}

function ErrorPage(props: ErrorPageProps) {
  const { httpErrorCode } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof props.httpErrorCode === 'number') {
      dispatch({ type: 'SET_IS_HTTP_ERROR', payload: true });
    }
    dispatch({ type: 'SET_PAGE_CLASS_NAME', payload: 'app-layout-app-error' });
    return () => {
      dispatch({ type: 'SET_IS_HTTP_ERROR', payload: false });
      dispatch({ type: 'SET_PAGE_CLASS_NAME', payload: '' });
    };
  }, []);

  if (httpErrorCode === 401) {
    return <UnauthorizedError />;
  }

  return <NotFoundError />;
}

export default ErrorPage;
