import { useState, useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

import { AppState } from '../reducers/appDataTypes';

import { StudentAuthUser, AuthStateCognito } from '../reducers/Auth/authDataTypes';

// import LogHelper from '../lib/helper/LogHelper';
import StudentUserHelper from '../lib/helper/StudentUserHelper';

import { useHistory, useLocation } from 'react-router';
import useAppLogger from './useAppLogger';
import { LocationStateType } from '../components/Auth/TeacherLogin';
import AppHelper from '../lib/helper/AppHelper';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export interface StudentUserHookOptions {
  noRedirect: boolean;
  silent: boolean;
  loginPath: string;
  logoutPath: string;
}

/**
 * Handles initializing and caching of student user data and session.
 *
 * Makes sure that 'proctorCode' value is always present, even though it's not
 * a part of student token data by caching it locally upon login.
 *
 * Accepts options object with following properties:
 *  - noRedirect (boolean) tells hook if it should prevent redirections to login / logout pages
 *  - silent (boolean) prevents hook from showing notifications if true
 *
 * @param   {StudentUserHookOptions}   hookOptions   Prevent redirects
 *
 * @returns {StudentAuthUser}                           Student user object
 */
const useStudentUser = (
  hookOptions: StudentUserHookOptions = {
    noRedirect: false,
    silent: false,
    loginPath: '/administration/auth/login/student',
    logoutPath: '/administration/auth/logout/student',
  },
): StudentAuthUser => {
  const config = useConfigContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LocationStateType>();
  // const NotificationHelper = useNotificationHelper();
  const logger = useAppLogger('HOOK|useStudentUser');

  const cognitoState: AuthStateCognito = useSelector((state: AppState) => {
    return state.auth.studentAuth.cognito;
  });
  const storeUser: StudentAuthUser = useSelector((state: AppState) => {
    return state.auth.studentAuth.user;
  });

  // Local state value for student user
  const [studentUser, setStudentUser] = useState<StudentAuthUser>(storeUser);

  // One-off flag to indicate we started with initialization
  const [initializingSession, setInitializingSession] = useState<boolean | null>(null);

  const skipCheck = useRef(false);

  const redirectToLogout = () => {
    history.push(hookOptions.logoutPath);
  };

  const redirectToLogin = () => {
    // history.push(hookOptions.loginPath);
    if (!hookOptions.noRedirect) {
      const locState: LocationStateType = {};
      if (
        location.pathname !== '/' &&
        location.pathname.length > 0 &&
        location.pathname !== hookOptions.loginPath
      ) {
        locState.from = `${location.pathname}${location?.search}`;
      }
      history.push(hookOptions.loginPath, locState);
    }
  };

  const cleanupStudentUser = () => {
    // make sure no leftover cookies / cache remains
    StudentUserHelper.clearLocalUser(config);
    // make sure we have a blank user in store
    const newUser = StudentUserHelper.initializeStudentUser();
    dispatch({ type: 'SET_STUDENT_USER', payload: newUser });
    // setStudentUser(newUser);
  };

  const initializeUser = () => {
    if (cognitoState.cognitoUserSession !== null) {
      const session = cognitoState.cognitoUserSession;
      // if (!storeUser.loggedIn && cognitoState.cognitoUserSession !== null) {
      if (!storeUser.loggedIn && !hookOptions.silent) {
        // no logged in user in redux store
        logger.debug('Initializing student user');
      }

      const newIdentity = StudentUserHelper.getUserIdentityFromCognitoSession(session);

      // get idToken from cognito session
      // const idTokenSession = session.getIdToken().getJwtToken();
      // const accessToken = session.getAccessToken().getJwtToken();
      // const refreshToken = session.getRefreshToken().getToken();

      if (!newIdentity.idToken) {
        // no cookie and no store = no user logged in
        if (!hookOptions.silent) {
          logger.info('Student cognito session idToken not found');
        }
        cleanupStudentUser();
        // redirect to logout here if necessary
        if (!hookOptions.noRedirect) {
          redirectToLogout();
        }
      } else {
        // we have Cognito session and its idToken
        // if (!storeUser.info.customerId || !storeUser.loggedIn) {
        if (!storeUser.loggedIn) {
          const proctorCode = StudentUserHelper.getProctorCodeCookie(config);
          // there's no redux user yet (or we have an empty / invalid one to work with)
          const newUser = StudentUserHelper.cloneStudentUser(studentUser);

          // Parse and populate student user in store with idToken data
          // newUser.identity.idToken = idTokenSession;
          newUser.identity = newIdentity;
          StudentUserHelper.populateStudentIdTokenData(newUser, newIdentity.idToken);

          // customerId is loaded from token and it's not present if token is invalid
          if (newUser.info.customerId) {
            // set additional data exptected by app
            newUser.role = 'student';
            newUser.studentId = `${newUser.info.externalId ? newUser.info.externalId : ''}`;
            newUser.proctorCode = proctorCode;
            newUser.info.proctorCode = proctorCode;
            newUser.loggedIn = true;
            if (proctorCode) {
              newUser.loadComplete = true;
            } else {
              newUser.loadComplete = false;
            }
            // StudentUserHelper.storeLocalUser(newUser);
            // dispatch({type: 'SET_STUDENT_USER', payload: newUser});
            StudentUserHelper.updateUser(newUser, dispatch, config);
            // setStudentUser(newUser);
          } else {
            if (!hookOptions.silent) {
              logger.warning('Student token data could not be parsed.');
            }
            cleanupStudentUser();
            if (!hookOptions.noRedirect) {
              redirectToLogout();
            }
          }
        }
      }
    } else {
      if (!hookOptions.silent) {
        logger.warning('Can not init user, no cognito session found.');
      }
    }
  };

  // const cacheCognitoSession = async () => {
  //   logger.warn('Caching student cognito session in redux store');
  //   if (initializingSession === null /*&& cognitoState.cognitoUserSession === null*/) {
  //     setInitializingSession(true);
  //     try {
  //       const session = await StudentUserHelper.getCognitoSession();
  //       if (session && session?.getIdToken()?.getJwtToken()) {
  //         dispatch({type: 'SET_STUDENT_COGNITO_USER_SESSION', payload: session});
  //         logger.info('Student cognito session cached in redux store');
  //         setInitializingSession(false);
  //       } else {
  //         logger.warn('Could not cache student cognito session in redux store');
  //         cleanupStudentUser();
  //         setInitializingSession(false);
  //         if (!hookOptions.noRedirect) {
  //           redirectToLogout();
  //         }
  //       }
  //     } catch(ex:unknown) {
  //       const err = ex as Error;
  //       logger.warning(`Could not fetch student session: "${err.message}`);
  //       cleanupStudentUser();
  //       setInitializingSession(false);
  //       if (!hookOptions.noRedirect) {
  //         redirectToLogout();
  //       }
  //     }
  //   } else {
  //     logger.info('Reusing cached student cognito session');
  //   }
  // };

  // const populateProctorCode = async () => {
  //   if (!studentUser.proctorCode) {
  //     // Proctor code is not part of student token data, users provide it upon login.
  //     // As long as there's no full page reloads, it will remain in redux store
  //     // When user logs in, it's proctor code is cached in a cookie.
  //     // Upon page refresh, value from cookie is used to populate proctorCode in redux.
  //     const proctorCode = StudentUserHelper.getProctorCodeCookie();
  //     if (proctorCode) {
  //       logger.info(`Using proctorCode ${proctorCode} from cookie for student user.`);
  //       await cacheCognitoSession();
  //       const newUser = StudentUserHelper.cloneStudentUser(studentUser);
  //       newUser.proctorCode = proctorCode;
  //       newUser.info.proctorCode = proctorCode;
  //       // we will preventively set loggedIn to false here,
  //       // since we can't trust proctorCode cookie only
  //       newUser.loggedIn = false;
  //       dispatch({type: 'SET_STUDENT_USER', payload: newUser});
  //       // setStudentUser(newUser);

  //     } else {
  //       logger.info(`No student session data found${!hookOptions.noRedirect ? ', redirecting to logout' : ''}.`);
  //       cleanupStudentUser();
  //       if (!hookOptions.noRedirect) {
  //         NotificationHelper.add('Please log in to continue.', 'info'); // 4000, GP1-11848
  //         redirectToLogin();
  //       }
  //     }
  //   } else {
  //     await cacheCognitoSession();
  //   }
  // };

  useEffect(() => {
    if (cognitoState.cognitoUserSession !== null) {
    }
  }, [cognitoState.cognitoUserSession]);

  useEffect(() => {
    if (cognitoState.cognitoUserSession !== null) {
      if (location.state?.from !== hookOptions.loginPath) {
        // Show an error if session exists and we're not coming straight from login
        logger.error(
          'useStudentUser hook initialized, but student cognito session is already present.',
        );
        // throw new Error('useStudentUser hook initialized, but student cognito session is already present.');
      }
    }

    const pathChunks = location.pathname.split('/');
    const skipAuthPaths = config.skipAuthPaths;

    if (pathChunks.length >= 3) {
      if (
        config.authPaths.indexOf(pathChunks[1]) >= 0 &&
        skipAuthPaths.indexOf(pathChunks[2]) >= 0
      ) {
        skipCheck.current = true;
      }
    } else if (pathChunks.length === 0) {
      skipCheck.current = true;
    }

    setInitializingSession(true);
    validateSession().then((cs: CognitoUserSession | null) => {
      if (cs !== null) {
        if (cognitoState.cognitoUserSession === null) {
          dispatch({ type: 'SET_STUDENT_COGNITO_USER_SESSION', payload: cs });
        }
        setInitializingSession(false);
      } else {
        if (!skipCheck.current && !hookOptions.noRedirect) {
          StudentUserHelper.logoutStudentUser(config, dispatch);
          redirectToLogin();
        }
      }
    });
  }, []);

  // useEffect(() => {
  //   logger.info('pc: ' + studentUser.proctorCode);
  //   // logger.info(studentUser);
  //   // if (studentUser.proctorCode) {
  //   //   cacheCognitoSession();
  //   // }
  // }, [studentUser.proctorCode]);

  useEffect(() => {
    // When store user gets updated, this return result gets changed as well
    // so hook consumer always have up-to-date user data
    const newUser = StudentUserHelper.cloneStudentUser(storeUser);
    setStudentUser(newUser);
    // logger.info('change storeuser');
    // logger.warn({...storeUser});
    // setStudentUser(storeUser);
  }, [storeUser]);

  useEffect(() => {
    if (initializingSession === false && cognitoState.cognitoUserSession !== null) {
      initializeUser();
    }
  }, [initializingSession, cognitoState.cognitoUserSession]);

  const validateSession = async (): Promise<CognitoUserSession | null> => {
    let cognitoSession: CognitoUserSession | null = null;
    try {
      cognitoSession = await StudentUserHelper.getCognitoSession(config);
    } catch (exc: unknown) {
      const ex = exc as Error;
      if (!hookOptions.silent) {
        logger.error(ex);
      }
    }
    if (cognitoSession !== null && cognitoSession.isValid()) {
      const cookieIdToken = AppHelper.getCookieValue(config.session.student.idTokenCookie);
      const idToken = cognitoSession.getIdToken().getJwtToken();
      const proctorCodeCookie = StudentUserHelper.getProctorCodeCookie(config);

      if (`${cookieIdToken}` !== `${idToken}`) {
        if (cookieIdToken) {
          if (!hookOptions.silent) {
            logger.warning('Updating student idToken cookie.');
          }
        } else {
          if (!hookOptions.silent) {
            logger.info('Setting student idToken cookie.');
          }
        }
        StudentUserHelper.setIdTokenCookie(idToken, config);
      }

      const localUser = StudentUserHelper.getLocalUser(config);

      const newIdentity = StudentUserHelper.getUserIdentityFromCognitoSession(cognitoSession);
      if (localUser !== null) {
        if (!hookOptions.silent) {
          logger.info('Using student user data from storage.');
        }
        // localUser.identity = newIdentity;
        const newUser: StudentAuthUser = {
          ...localUser,
          role: 'student',
          loggedIn: true,
          identity: newIdentity,
        };
        if (proctorCodeCookie && !(newUser.proctorCode && newUser.info.proctorCode)) {
          newUser.proctorCode = proctorCodeCookie;
          newUser.info.proctorCode = proctorCodeCookie;
        }
        if (newUser.proctorCode && newUser.info.proctorCode) {
          newUser.loadComplete = true;
        } else {
          newUser.loadComplete = false;
          newUser.proctorCode = '';
          newUser.info.proctorCode = '';
        }
        StudentUserHelper.updateUser(newUser, dispatch, config);
      } else {
        if (!hookOptions.silent) {
          logger.info('Populating student user data from token and cookies.');
        }
        // const proctorCodeCookie = StudentUserHelper.getProctorCodeCookie();
        const initUser = StudentUserHelper.initializeStudentUser();
        // initUser.proctorCode = proctorCodeCookie;
        // initUser.info.proctorCode = proctorCodeCookie;
        initUser.role = 'student';
        initUser.identity = newIdentity;
        const newUser = StudentUserHelper.populateStudentIdTokenData(initUser, newIdentity.idToken);
        if (newUser.info.customerId) {
          if (!hookOptions.silent) {
            logger.info('Student token parsed', {
              metaData: { parsedUserData: { ...newUser.info } },
            });
          }
          newUser.loggedIn = true;
          if (proctorCodeCookie) {
            newUser.proctorCode = proctorCodeCookie;
            newUser.info.proctorCode = proctorCodeCookie;
            newUser.loadComplete = true;
          } else {
            newUser.loadComplete = false;
            newUser.proctorCode = '';
            newUser.info.proctorCode = '';
          }
        } else {
          if (!hookOptions.silent) {
            logger.info('Student user failed validating with Cognito');
          }
          newUser.loggedIn = false;
          newUser.loadComplete = false;
        }
        StudentUserHelper.updateUser(newUser, dispatch, config);
      }
      // UserHelper.updateUser(updates, dispatch);
    } else {
      if (!hookOptions.silent) {
        logger.info('Student Cognito session is not present.');
      }
      // redirectToLogin();
      // if (!skipCheck.current) {
      //   redirectToLogout();
      // }
    }
    return cognitoSession;
  };

  // useEffect(() => {
  //   const valid = cognitoState.cognitoUserSession !== null && cognitoState.cognitoUserSession.isValid();
  //   if (valid) {
  //     logger.info('Valid cognito student session found in redux store.');
  //     // initializeUser();
  //   } else if (cognitoState.cognitoUserSession !== null) {
  //     refreshUser();
  //     // @TODO refresh tokens here if page is being reloaded with expired ones
  //   }
  // }, [cognitoState.cognitoUserSession, initializingSession]);

  return studentUser;
};

export default useStudentUser;
