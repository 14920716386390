import { AppReduxAction } from '../../appDataTypes';

const initState = {
  isActive: false,
  idActive: 0,
  assessment: '',
  sumNewChatMessages: 0,
};

const chatPanelReducer = (state = initState, action: AppReduxAction) => {
  switch (action.type) {
    case 'SHOW_CHAT_PANEL':
      return {
        ...state,
        isActive: true,
        idActive: action.payload.id,
        assignmentType: action.payload.assignmentType,
      };
    case 'HIDE_CHAT_PANEL':
      return {
        ...state,
        isActive: false,
      };
    default:
      return state;
  }
};

export default chatPanelReducer;
