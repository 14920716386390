import React from 'react';
import { Page, View, Text, Image } from '@react-pdf/renderer';
import { TestTakerTicket } from './TestTakerTicketsPDF.d';
import StudentIDIcon from './icons/student.png';
import OrganizationIdIcon from './icons/organization.png';
import ProctorCodeIcon from './icons/proctor.png';
import { documentStyles } from './documentStyles';

export const TICKETS_PER_PAGE = 12;

export const areTicketsFromSameClass = (...tickets: TestTakerTicket[]): boolean => {
  return !!tickets.find(
    (t) =>
      t.schoolName !== tickets[0].schoolName ||
      t.name !== tickets[0].name ||
      t.grade !== tickets[0].grade,
  );
};

export const getTicketPage = (
  assignmentName: string,
  assignmentType: string,
  organizationId: string,
  tickets: TestTakerTicket[],
) => (
  <Page key={tickets[0].sisId} size="A4" style={documentStyles['page']}>
    <View style={documentStyles['header']}>
      <Text>
        {`${tickets[0].schoolName}, Grade - ${tickets[0].grade === '0' ? 'K' : tickets[0].grade}, ${
          tickets[0].name
        }`}
      </Text>
    </View>

    <View style={documentStyles['body']}>
      {tickets.slice(0, TICKETS_PER_PAGE).map((ticket, i: number) => (
        <View key={i} style={documentStyles['ticketContainer']}>
          <View style={documentStyles['ticket']}>
            <View style={documentStyles['studentNameContainer']}>
              <Text style={documentStyles['studentName']}>{ticket.studentFirstName}</Text>
              <Text style={documentStyles['studentName']}>{ticket.studentLastName}</Text>
            </View>

            <View style={documentStyles['ticketItemContainer']}>
              <Image style={documentStyles['ticketItemIcon']} src={StudentIDIcon} />
              <Text style={documentStyles['ticketItemText']}>{ticket.sisId}</Text>
            </View>

            <View style={documentStyles['ticketItemContainer']}>
              <Image style={documentStyles['ticketItemIconSmall']} src={OrganizationIdIcon} />
              <Text style={documentStyles['ticketItemText']}>{organizationId}</Text>
            </View>

            <View style={documentStyles['ticketItemContainer']}>
              <Image style={documentStyles['ticketItemIcon']} src={ProctorCodeIcon} />
              <View style={documentStyles['ticketItemTextPlaceholder']} />
            </View>
          </View>
        </View>
      ))}
    </View>

    <View style={documentStyles['footer']}>
      <Text>{`${assignmentName}, ${assignmentType}`}</Text>
      <Text style={documentStyles['date']}>{new Date().toLocaleDateString()}</Text>
    </View>
  </Page>
);
