import AppHelper from '../lib/helper/AppHelper';
import { AppStatus, AppStatusUpdate, AppReduxAction } from './appDataTypes';
import { Reducer } from 'redux';

const currentSessionId = sessionStorage.getItem('currentSessionId')
  ? sessionStorage.getItem('currentSessionId')
  : AppHelper.getUUID();
if (currentSessionId) {
  sessionStorage.setItem('currentSessionId', currentSessionId);
}

/**
 * Initial state for app status  store
 * @type {AppStatus}
 */
const initialState: AppStatus = {
  headerVisible: false,
  headerToolbar: null,
  headerToolbarVisible: false,
  headerCenterContents: null,
  overlayVisible: false,
  currentModal: null,
  currentModalOpener: null,
  appBusy: false,
  sessionReady: false,
  appBusyMessage: '',
  appNotifications: [],
  pageTitle: '',
  pageClassName: '',
  loadingUser: false,
  isHttpError: false,
  isHelpGuideButtonDisabled: true,
  isHelpGuideVisible: false,
  helpGuideSectionsVisible: ['IowaFlex Math', 'IowaFlex Reading', 'CogAT Form 7', 'CogAT Form 8'],

  // displays error below the header
  appError: false,

  // displays error covering the header
  appErrorFatal: false,
  appErrorMessage: 'Error',
  appErrorFooter: null,
  debugVisible: false,
  currentWindowId: AppHelper.getUUID(),
  currentSessionId: currentSessionId,
};

const appStatusReducer: Reducer<AppStatus, AppReduxAction> = (state = initialState, action) => {
  if (action.type === 'UPDATE_APP_STATUS') {
    const scalarValues: AppStatusUpdate = {
      ...action.payload,
    };

    delete scalarValues.headerToolbar;
    delete scalarValues.headerCenterContents;
    delete scalarValues.appNotifications;

    const newState: AppStatus = {
      ...state,
      ...scalarValues,
    };

    if (action.payload?.headerToolbar) {
      newState.headerToolbar = action.payload.headerToolbar;
    } else {
      newState.headerToolbar = state.headerToolbar;
    }
    if (action.payload?.headerCenterContents) {
      newState.headerCenterContents = action.payload.headerCenterContents;
    } else {
      newState.headerCenterContents = state.headerCenterContents;
    }
    if (action.payload?.appNotifications) {
      newState.appNotifications = action.payload.appNotifications;
    } else {
      newState.appNotifications = state.appNotifications;
    }
    return newState;
  } else if (action.type === 'SET_DEBUG_VISIBLE') {
    return {
      ...state,
      debugVisible: action.payload,
    };
  } else if (action.type === 'SET_CURRENT_WINDOW_ID') {
    return {
      ...state,
      currentWindowId: action.payload,
    };
  } else if (action.type === 'SET_HEADER_VISIBLE') {
    return {
      ...state,
      headerVisible: action.payload,
    };
  } else if (action.type === 'SET_HEADER_CENTER_CONTENTS') {
    return {
      ...state,
      headerCenterContents: action.payload,
    };
  } else if (action.type === 'SET_HEADER_TOOLBAR') {
    return {
      ...state,
      headerToolbar: action.payload,
    };
  } else if (action.type === 'SET_OVERLAY_VISIBLE') {
    return {
      ...state,
      overlayVisible: action.payload,
    };
  } else if (action.type === 'SET_HEADER_TOOLBAR_VISIBLE') {
    return {
      ...state,
      headerToolbarVisible: action.payload,
    };
  } else if (action.type === 'SET_CURRENT_MODAL_OPENER') {
    return {
      ...state,
      currentModalOpener: action.payload,
    };
  } else if (action.type === 'SET_CURRENT_MODAL') {
    // Focus control - return focus to element that had it before modal was opened.
    let modalFocusTrigger: HTMLElement | null = null;
    let toFocus: HTMLElement | null = null;
    if (action.payload !== null && document.activeElement !== null) {
      modalFocusTrigger = document.activeElement as HTMLElement;
    } else if (action.payload === null && state.currentModalOpener !== null) {
      if (state.currentModalOpener !== null) {
        toFocus = state.currentModalOpener as HTMLElement;
      }
    }
    if (toFocus && toFocus.focus) {
      setTimeout(() => {
        if (toFocus && toFocus.focus) {
          toFocus.focus();
        }
      }, 0);
    }
    return {
      ...state,
      currentModal: action.payload,
      currentModalOpener: modalFocusTrigger,
      overlayVisible: action.payload !== null,
    };
  } else if (action.type === 'SET_APP_BUSY') {
    return {
      ...state,
      appBusy: action.payload,
    };
  } else if (action.type === 'SET_SESSION_READY') {
    return {
      ...state,
      sessionReady: action.payload,
    };
  } else if (action.type === 'SET_IS_HTTP_ERROR') {
    return {
      ...state,
      isHttpError: action.payload,
    };
  } else if (action.type === 'SET_APP_BUSY_MESSAGE') {
    return {
      ...state,
      appBusyMessage: action.payload,
    };
  } else if (action.type === 'SET_APP_ERROR') {
    return {
      ...state,
      appError: action.payload,
    };
  } else if (action.type === 'SET_APP_ERROR_FATAL') {
    return {
      ...state,
      appErrorFatal: action.payload,
    };
  } else if (action.type === 'SET_APP_ERROR_MESSAGE') {
    return {
      ...state,
      appErrorMessage: action.payload,
    };
  } else if (action.type === 'SET_APP_ERROR_FOOTER') {
    return {
      ...state,
      appErrorFooter: action.payload,
    };
  } else if (action.type === 'SET_PAGE_TITLE') {
    document.title = `Elevate | ${action.payload}`;
    return {
      ...state,
      pageTitle: action.payload,
    };
  } else if (action.type === 'SET_LOADING_USER') {
    return {
      ...state,
      loadingUser: action.payload ? true : false,
    };
  } else if (action.type === 'ADD_APP_NOTIFICATION') {
    return {
      ...state,
      appNotifications: state.appNotifications.concat([action.payload]),
    };
  } else if (action.type === 'SET_APP_NOTIFICATIONS') {
    return {
      ...state,
      appNotifications: action.payload,
    };
  } else if (action.type === 'SET_PAGE_CLASS_NAME') {
    return {
      ...state,
      pageClassName: action.payload,
    };
  } else if (action.type === 'REMOVE_APP_NOTIFICATION') {
    return {
      ...state,
      appNotifications: action.payload
        ? state.appNotifications.filter((an) => an.uuid !== action.payload)
        : state.appNotifications,
    };
  } else if (action.type === 'CLEAR_APP_NOTIFICATIONS') {
    return {
      ...state,
      appNotifications: [],
    };
  } else if (action.type === 'SET_HELP_GUIDE_BUTTON_DISABLED') {
    return {
      ...state,
      isHelpGuideButtonDisabled: action.payload,
    };
  } else if (action.type === 'SET_HELP_GUIDE_VISIBLE') {
    return {
      ...state,
      isHelpGuideVisible: action.payload,
    };
  } else if (action.type === 'SET_HELP_GUIDE_SECTIONS_VISIBLE') {
    return {
      ...state,
      helpGuideSectionsVisible: action.payload,
    };
  }
  return state;
};

export default appStatusReducer;
