import { AblyState, AppReduxAction } from '../appDataTypes';

const initState: AblyState = {
  ablyRealtime: null,
  initializing: false,
  error: false,
  ready: false,
  destroyingAbly: false,
  reinitializingAbly: false,
  studentChannel: null,
};

const ablyReducer = (state = initState, action: AppReduxAction): AblyState => {
  switch (action.type) {
    case 'SET_ABLY_REALTIME_INSTANCE':
      return {
        ...state,
        ablyRealtime: action.payload,
      };

    case 'SET_ABLY_INITIALIZING':
      return {
        ...state,
        initializing: action.payload,
      };

    case 'SET_ABLY_ERROR':
      return {
        ...state,
        error: action.payload,
      };

    case 'SET_ABLY_READY':
      return {
        ...state,
        ready: action.payload,
      };

    case 'SET_DESTROYING_ABLY':
      return {
        ...state,
        destroyingAbly: action.payload,
      };

    case 'SET_REINITIALIZING_ABLY':
      return {
        ...state,
        reinitializingAbly: action.payload,
      };

    case 'STUDENT_CHANNEL_CONNECTED':
      return {
        ...state,
        studentChannel: action.payload,
      };
    default:
      return state;
  }
};

export default ablyReducer;
