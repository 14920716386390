const initState: any = [];

const notificationsReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      const newStateAdd = state.slice(0);
      newStateAdd.push(action.payload);
      return newStateAdd;
    case 'REMOVE_NOTIFICATION':
      const newStateRemove = state.slice(0);
      for (let i = 0; i < newStateRemove.length; i++) {
        if (newStateRemove[i].id === action.payload.id) {
          newStateRemove.splice(i, 1);
          break;
        }
      }
      return newStateRemove;
    default:
      return state;
  }
};

export default notificationsReducer;
