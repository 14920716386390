import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import TestAssignmentList from '../../components/TestAssignment/TestAssignmentList/TestAssignmentList';

const Page: React.FC<RouteComponentProps> = (props) => {
  return <TestAssignmentList title="Assignment List" />;
};

export default Page;
