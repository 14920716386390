import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { AppState } from '../reducers/appDataTypes';
import {
  StaffAuthUser,
  // StaffAuthUserUpdate,
  AuthStateCognito,
} from '../reducers/Auth/authDataTypes';

// import LogHelper from '../lib/helper/LogHelper';

const useCachedUser = (): StaffAuthUser => {
  const cognitoState: AuthStateCognito = useSelector((state: AppState) => {
    return state.auth.staffAuth.cognito;
  });
  const storeUser: StaffAuthUser = useSelector((state: AppState) => {
    return state.auth.staffAuth.user;
  });
  const [staffUser, setStaffUser] = useState<StaffAuthUser>(storeUser);

  const [sessionValid, setSessionValid] = useState(false);

  useEffect(() => {
    const valid =
      cognitoState.cognitoUserSession !== null && cognitoState.cognitoUserSession.isValid();
    if (valid !== sessionValid) {
      // LogHelper.debug(`Cognito session changed to "${valid ? 'valid' : 'invalid'}"`, 'HOOK|useCachedUser');
      setSessionValid(valid);
    }
  }, [cognitoState.cognitoUserSession]);

  useEffect(() => {
    // When store user gets updated, this return result gets changed as well
    // so hook consumer always have up-to-date user data
    const newUser = {
      ...storeUser,
      loggedIn: sessionValid,
    };
    setStaffUser(newUser);
  }, [storeUser, sessionValid]);

  return staffUser;
};

export default useCachedUser;
