import React from 'react';
import { CancelTokenSource } from 'axios';
import pako from 'pako';

import {
  StaffAuthUser,
  StaffAuthUserUpdate,
  StaffUserInfo,
  UserRole,
  StaffInfo,
  Role,
  School,
  TokenPayload,
  TokenPayloadStaff,
  TokenPayloadStudent,
  CognitoTokenResponse,
  UserRoleName,
} from '../../reducers/Auth/authDataTypes';

import { AppStatusUpdate } from '../../reducers/appDataTypes';

import HelperBase from '../HelperBase';
import AppHelper from './AppHelper';
import BaseApiHelper from './BaseApiHelper';
import LogHelper from './LogHelper';

import * as AWS from 'aws-sdk/global';

import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  ICognitoUserSessionData,
} from 'amazon-cognito-identity-js';

import ElevateStorage from '../ElevateStorage';
import { Config } from '@elevate-ui/config';

class UserHelper extends HelperBase {
  static cancelTokenSource: CancelTokenSource | null = null;

  /**
   * Returns empty user object
   *
   * @static
   * @type {Function}
   *
   * @return     {StaffAuthUser} Empty user object
   */
  static initializeStaffUser = (): StaffAuthUser => {
    const user: StaffAuthUser = {
      identity: {
        accessToken: '',
        expirationTime: '',
        idToken: '',
        issuedTime: '',
        refreshToken: '',
      },
      name: '',
      roles: null,
      email: '',
      createdDateTime: '',
      ssoUser: false,
      cleverDistrictId: '',
      userId: '',
      districtId: '',
      districtName: '',
      avatarUrl: '',
      termsOfUseAccepted: false,
      privacyPolicyAccepted: false,
      customerId: '',
      sisId: '',
      role: '',
      loggedIn: false,
      loadComplete: false,
      canChangeRole: false,
      currentProctorSessionId: null,
      currentRole: null,
      currentPermissions: [],
      currentSchools: [],
      currentProctorCode: '',
    };
    return user;
  };

  static getElevateStorage = (config: Config): ElevateStorage => {
    return new ElevateStorage(config.session.cacheType);
  };

  /**
   * Caches user data locally for quick reuse in UI apps
   *
   * @param {StaffAuthUser}   newUser     User to store
   * @param {Config} config configuration object
   * @returns {undefined}
   */
  static storeLocalUser = (newUser: StaffAuthUser, config: Config) => {
    const cookieIdToken = `${AppHelper.getCookieValue(config.session.staff.idTokenCookie)}`;
    if (!newUser.identity.idToken) {
      if (cookieIdToken) {
        newUser.identity.idToken = cookieIdToken;
      }
    }

    if (newUser.identity.idToken && config.session.staff.storeInCookies) {
      UserHelper.storeCookieUser(newUser, config);
    }

    if (newUser.role !== UserHelper.getCurrentStaffRoleName(config)) {
      UserHelper.setCurrentStaffRoleName(newUser.role, config);
    }

    let userJson = '';
    try {
      userJson = JSON.stringify({
        ...newUser,
      });
    } catch (ex: unknown) {
      const err = ex as Error;
      LogHelper.error(`Error serializing user cache: "${err.message}"`, 'HELPER|UserHelper');
      // UserHelper.clearLocalUser();
    }
    if (userJson) {
      if (config.session.staff.cacheUser) {
        const elevateStorage = UserHelper.getElevateStorage(config);
        elevateStorage.setItem(config.session.staff.userCacheKey, userJson);
      }
    }
  };

  static clearLocalUser = (config: Config) => {
    const elevateStorage = UserHelper.getElevateStorage(config);
    elevateStorage.removeItem(config.session.staff.userCacheKey);
    UserHelper.clearIdTokenCookie(config);
    UserHelper.clearCurrentStaffRoleName(config);
    if (config.session.staff.storeInCookies) {
      UserHelper.clearCookieUser(config);
    }
  };

  static getLocalUser = (config: Config): StaffAuthUser | null => {
    let userString: string | null = null;
    const elevateStorage = UserHelper.getElevateStorage(config);
    userString = elevateStorage.getItem(config.session.staff.userCacheKey);

    let result: StaffAuthUser | null = null;
    if (userString && typeof userString === 'string') {
      try {
        result = JSON.parse(userString) as StaffAuthUser;
        if (!result.identity.idToken) {
          const cookieToken = AppHelper.getCookieValue(config.session.staff.idTokenCookie);
          if (cookieToken) {
            result.identity.idToken = cookieToken;
          }
        }
      } catch (ex: unknown) {
        const err = ex as Error;
        LogHelper.error(`Error parsing local user: ${err.message}`);
        // UserHelper.clearLocalUser();
      }
    } else {
      result = UserHelper.getCookieUser();
    }
    return result;
  };

  static validateLocalUser = async (
    config: Config,
    dispatch?: React.Dispatch<{
      type: string;
      payload: AppStatusUpdate | boolean | string | StaffAuthUser | StaffAuthUserUpdate;
    }>,
  ): Promise<StaffAuthUser | null> => {
    await UserHelper.validateStaffCognitoSession(config);
    const user = UserHelper.getLocalUser(config);
    return user;
  };

  // /**
  //  * Authenticates user using Identity API
  //  *
  //  * @static
  //  * @async
  //  * @param  {string}                 email    User email
  //  * @param  {string}                 password User password

  //  * @return {StaffAuthUser}          User with data retrieved from API
  //  */
  static loginCognitoUser = async (
    email: string,
    password: string,
    config: Config,
  ): Promise<CognitoUser> => {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userPool: CognitoUserPool = UserHelper.getCognitoUserPool(config);
    const userData = {
      Username: email,
      Pool: userPool,
      Storage: UserHelper.getElevateStorage(config),
      // Storage: new CookieStorage({domain: config.local ? 'localhost' : '.elevate.riverside-insights.com'}),
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result: CognitoUserSession) {
          const credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.awsConfig.IdentityPoolId,
            // RoleArn: config.awsConfig.RoleArn,
            Logins: {
              // Change the key below according to the specific region your user pool is in.
              [`cognito-idp.${config.awsConfig.region}.amazonaws.com/${config.awsConfig.UserPoolId}`]:
                result.getIdToken().getJwtToken(),
            },
            LoginId: `staffUser_${email}`,
          });
          AWS.config.credentials = credentials;

          resolve(cognitoUser);
        },
        onFailure: function (err: Error) {
          reject(err);
        },
      });
    });
  };

  static getCognitoUserPool = (config: Config): CognitoUserPool => {
    const poolData = {
      UserPoolId: config.awsConfig.UserPoolId,
      ClientId: config.awsConfig.ClientId,
      Storage: UserHelper.getElevateStorage(config),
      // Storage: new CookieStorage({domain: config.local ? 'localhost' : '.elevate.riverside-insights.com'}),
    };
    return new CognitoUserPool(poolData);
  };

  static getCognitoUser = (config: Config): CognitoUser | null => {
    const userPool = UserHelper.getCognitoUserPool(config);
    return userPool.getCurrentUser();
  };

  static authenticateCognitoUser = async (
    username: string,
    pass: string,
    config: Config,
  ): Promise<StaffAuthUser> => {
    const user = UserHelper.initializeStaffUser();
    const cognitoUser: CognitoUser = await UserHelper.loginCognitoUser(username, pass, config);
    if (cognitoUser) {
      const idToken = `${cognitoUser.getSignInUserSession()?.getIdToken()?.getJwtToken()}`;
      if (idToken) {
        UserHelper.setIdTokenCookie(idToken, config);
        UserHelper.populateStaffTokenData(user, idToken, config);
        user.identity = {
          idToken,
          accessToken: '',
          refreshToken: '',
          issuedTime: '',
          expirationTime: '',
        };
      }
    }
    // console.log(cognitoUser);
    return user;
  };

  static getCognitoSession = async (config: Config): Promise<CognitoUserSession | null> => {
    const cognitoUser = UserHelper.getCognitoUser(config);
    if (cognitoUser !== null) {
      return new Promise((resolve, reject) => {
        cognitoUser.getSession((err: unknown | null, session: CognitoUserSession) => {
          if (err) {
            // console.log(err);
            reject(err);
          } else {
            // console.log(session);
            resolve(session);
          }
        });
      });
    } else {
      return Promise.resolve(null);
    }
  };

  static validateStaffCognitoSession = async (
    config: Config,
  ): Promise<CognitoUserSession | null> => {
    const cognitoSession = await UserHelper.getCognitoSession(config);
    return cognitoSession;
  };

  /**
   * Checks whether staff user is valid
   *
   *
   * User is valid if it has basic values set:
   * - email
   * - districtId
   * - customerId
   * - userId
   *
   * @static
   * @type {Function}
   *
   * @param {StaffAuthUser}     appUser    User to check
   *
   * @return {boolean}                     Validation result
   *
   */
  static isValidStaffUser = (appUser: StaffAuthUser): boolean => {
    return !!(
      appUser &&
      appUser.email &&
      appUser.districtId &&
      appUser.userId &&
      appUser.customerId
    );
  };

  /**
   * Checks whether staff user role has all its data fully loaded
   *
   * @param {UserRole}  userRole  User role to check
   * @returns {boolean}           True if role is fully lodaed, false otherwise
   */
  static isFullStaffUserRole = (userRole: UserRole): boolean => {
    return typeof userRole.schools !== 'undefined';
    // TODO keep checking for this except when the user is new, && userRole.schools.length > 0;
  };

  /**
   * Checks whether staff user has all data loaded
   *
   * User is fully loaded if it has:
   * - email
   * - districtId
   * - customerId
   * - userId
   * - name (custom field set by UI on user init, shouldn't be empty)
   * - at least one fully loaded role
   *   - role must have at least one school to be considered fully loaded
   *
   * @static
   * @type {Function}
   *
   * @param {StaffAuthUser}     appUser    User to check
   *
   * @return {boolean}                     Validation result
   *
   */
  static isFullStaffUser = (appUser: StaffAuthUser): boolean => {
    return (
      UserHelper.isValidStaffUser(appUser) &&
      appUser.name !== null &&
      appUser.name.length > 0 &&
      appUser.roles !== null &&
      Array.isArray(appUser.roles) &&
      appUser.roles.length > 0 &&
      appUser.roles.some((userRole) => {
        return this.isFullStaffUserRole(userRole);
      })
    );
  };

  /**
   * Checks whether staff user has all data loaded
   *
   * @static
   * @type {Function}
   *
   * @param {StaffAuthUser}     appUser    User to check
   *
   * @return {boolean}                     Validation result
   *
   */
  static analyzeStaffUserData = (appUser: StaffAuthUser): string[] => {
    const fields: string[] = [];
    if (!appUser) {
      fields.push('User not valid');
    } else {
      if (!appUser.email) {
        fields.push('email');
      }
      if (!appUser.districtId) {
        fields.push('districtId');
      }
      if (!appUser.userId) {
        fields.push('userId');
      }
      if (!appUser.customerId) {
        fields.push('customerId');
      }
    }
    return fields;
  };

  /**
   * Checks whether staff user has all data loaded
   *
   * @static
   * @type {Function}
   *
   * @param {StaffAuthUser}     appUser    User to check
   *
   * @return {boolean}                     Validation result
   *
   */
  static analyzeStaffUserRolesData = (appUser: StaffAuthUser): string[] => {
    const fields: string[] = [];
    if (!appUser) {
      fields.push('User not valid');
    } else {
      if (!appUser.roles) {
        fields.push('Roles are missing');
      } else if (appUser.roles && Array.isArray(appUser.roles)) {
        const invalidRoles = appUser.roles.reduce((acc: string[], userRole) => {
          if (!(userRole?.schools && Array.isArray(userRole.schools))) {
            acc.push(userRole.name);
          }
          return acc;
        }, []);
        if (invalidRoles.length > 0) {
          fields.push(`Invalid roles: "${invalidRoles.join('", "')}"`);
        }
      }
    }
    return fields;
  };

  static parseStaffToken = (token: string, config: Config): StaffInfo | null => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } catch (ex) {
      LogHelper.error('Failed parsing staff token', 'AppHelper', {
        info: {
          thrownError: ex,
          token,
        },
      });
      // console.error(ex);
      return null;
    }
  };

  /**
   * Populates data with token payload for user from argument and returns it.
   *
   * @param {StaffAuthUser}   user    Initial (empty/incomplete) user object
   * @param {string}          idToken IdToken string
   * @param {Config} config configuration object
   * @returns {StaffAuthUser}         User object with populated token data
   */
  static populateStaffTokenData = (
    user: StaffAuthUser,
    idToken: string,
    config: Config,
  ): StaffAuthUser => {
    const userInfo: StaffUserInfo = UserHelper.parseStaffToken(idToken, config) as StaffUserInfo;
    if (userInfo && userInfo.email) {
      user.email = userInfo.email;
      if (userInfo.name) {
        user.name = userInfo.name;
      }
      if (userInfo['custom:user_roles']) {
        try {
          userInfo.rolesInfo = JSON.parse(`${userInfo['custom:user_roles']}`);
        } catch (ex) {
          LogHelper.error(ex, 'HELPER|UserHelper');
        }
        if (userInfo.rolesInfo) {
          if (userInfo.rolesInfo.customerid) {
            user.customerId = userInfo.rolesInfo.customerid;
          } else if (userInfo.rolesInfo.CustomerId) {
            // temporarily added for ssr legacy support
            user.customerId = userInfo.rolesInfo.CustomerId;
          }
          if (userInfo.rolesInfo.districtid) {
            user.districtId = userInfo.rolesInfo.districtid;
          } else if (userInfo.rolesInfo.DistrictId) {
            // temporarily added for ssr legacy support
            user.districtId = userInfo.rolesInfo.DistrictId;
          }
          if (userInfo.rolesInfo.districtexternalid) {
            user.districtExternalId = userInfo.rolesInfo.districtexternalid;
          } else if (userInfo.rolesInfo.DistrictExternalId) {
            // temporarily added for ssr legacy support
            user.districtExternalId = userInfo.rolesInfo.DistrictExternalId;
          }
          if (userInfo.rolesInfo.userid) {
            user.userId = userInfo.rolesInfo.userid;
          } else if (userInfo.rolesInfo.UserId) {
            // temporarily added for ssr legacy support
            user.userId = userInfo.rolesInfo.UserId;
          }
          if (userInfo.rolesInfo.districtname) {
            user.districtName = userInfo.rolesInfo.districtname;
          } else if (userInfo.rolesInfo.DistrictName) {
            // temporarily added for ssr legacy support
            user.districtName = userInfo.rolesInfo.DistrictName;
          }
        }
      }

      if (userInfo['custom:district']) {
        user.cleverDistrictId = userInfo['custom:district'];
      }
    }
    return user;
  };

  static validateStaffUser = async (config: Config): Promise<StaffAuthUser> => {
    const user: StaffAuthUser = UserHelper.initializeStaffUser();
    const cookieVal = AppHelper.getCookieValue(config.session.staff.idTokenCookie);
    if (cookieVal) {
      user.identity.idToken = cookieVal;
      UserHelper.populateStaffTokenData(user, cookieVal, config);
      user.loadComplete = false;
      return Promise.resolve(user);
    } else {
      return await UserHelper._validateStaffUser(config);
    }
  };

  static _validateStaffUser = async (config: Config): Promise<StaffAuthUser> => {
    const apiHelper = new BaseApiHelper(config);
    LogHelper.debug(config, 'Validating staff user on API', 'HELPER|UserHelper');
    const user: StaffAuthUser = UserHelper.initializeStaffUser();
    const res = await apiHelper.apiRequest('auth', 'validate');
    if (res && res !== null && res.data && res.data.idToken) {
      user.identity = { ...res.data };
      if (config.local) {
        AppHelper.setCookie(config.session.staff.accessTokenCookie, res.data.accessToken);
        AppHelper.setCookie(config.session.staff.refreshTokenCookie, res.data.refreshToken);
      }
      user.loadComplete = false;
      UserHelper.populateStaffTokenData(user, res.data.idToken, config);
    }
    return user;
  };

  static updateCurrentRole = async (
    config: Config,
    appUser: StaffAuthUser,
    newRole: string,
  ): Promise<StaffAuthUser> => {
    UserHelper.setCurrentStaffRoleName(newRole, config);
    appUser.role = newRole;
    let roleFound = false;
    if (appUser?.roles?.length) {
      for (let i = 0; i < appUser.roles.length; i++) {
        const auRole = appUser.roles[i];
        if (auRole && auRole.name) {
          if (auRole.name === newRole) {
            roleFound = true;
            appUser.currentRole = auRole;
            appUser.currentSchools = auRole?.schools ? (auRole.schools as School[]) : [];
            appUser.currentProctorCode = auRole.proctorCode.replace(/^[^-]+-/, '');
            appUser.currentPermissions = auRole.permissions as number[];
            appUser.currentProctorSessionId = await UserHelper.loadProctorSessionId(
              appUser,
              config,
            );
          }
        }
      }
      if (!roleFound) {
        appUser.currentRole = null;
        appUser.currentSchools = [];
        appUser.currentProctorCode = '';
        appUser.currentPermissions = [];
        appUser.currentProctorSessionId = null;
      }
    }
    UserHelper.storeLocalUser(appUser, config);
    return appUser;
  };

  static fetchRolesData = async (appUser: StaffAuthUser, config: Config): Promise<UserRole[]> => {
    const apiHelper = new BaseApiHelper(config);
    LogHelper.info(
      config,
      `Starting rostering roles data load for user "${appUser.email}"`,
      'HELPER|UserHelper',
    );
    const cs = await UserHelper.getCognitoSession(config);
    let userRoles: UserRole[] = [];
    try {
      const result = await apiHelper.apiRequest(
        'rostering',
        'rosteringRoles',
        {
          emailId: appUser.email,
          // getSchools: true,
        },
        {
          headers: {
            Authorization: cs?.getIdToken().getJwtToken(),
          },
        },
      );
      if (result && result.data) {
        const val = result.data;
        if (val) {
          if (val.firstName && val.lastName) {
            appUser.name = `${val.firstName} ${val.lastName}`;
          }
          if (val.sisId) {
            appUser.sisId = `${val.sisId}`;
          }

          if (val.roles) {
            userRoles = [];
            for (const roleName in val.roles) {
              if (val.roles[roleName as keyof typeof val.roles]) {
                const roleItem: Role = val.roles[roleName as keyof typeof val.roles];
                let rolePermissions: number[] = [];
                if (
                  typeof roleItem !== 'undefined' &&
                  roleItem !== null &&
                  typeof roleItem.customPermissionsJson !== 'undefined' &&
                  typeof roleItem.customPermissionsJson.permissions !== 'undefined' &&
                  Array.isArray(roleItem.customPermissionsJson.permissions) &&
                  roleItem.customPermissionsJson.permissions.length > 0
                ) {
                  rolePermissions = roleItem.customPermissionsJson.permissions.map((v) => +v);
                } else if (
                  typeof roleItem !== 'undefined' &&
                  roleItem !== null &&
                  typeof roleItem.defaultPermissionsJson !== 'undefined' &&
                  typeof roleItem.defaultPermissionsJson.permissions !== 'undefined' &&
                  Array.isArray(roleItem.defaultPermissionsJson.permissions)
                ) {
                  rolePermissions = roleItem.defaultPermissionsJson.permissions?.map((v) => +v);
                }
                const userRole: UserRole = {
                  name: roleName,
                  schools: typeof roleItem.schools === 'undefined' ? [] : roleItem.schools,
                  proctorCode: roleItem.proctorCode,
                  permissions: rolePermissions,
                  // sections: [],
                };
                if (userRole.permissions && userRole.permissions.sort) {
                  userRole.permissions.sort();
                }
                userRoles.push(userRole);
              }
            }
          }
        }
        LogHelper.info(
          config,
          `Finished rostering roles data load for user "${appUser.email}"`,
          'HELPER|UserHelper',
        );
      }
    } catch (exc: unknown) {
      const ex = exc as Error;
      LogHelper.error(`Could not load rostering roles: ${ex.message}`, 'HELPER|UserHelper');
      // if (!config.local) {
      throw ex;
      // }
    }

    return userRoles;
  };

  /**
   * Fetches staff user profile data from userprofile API
   *
   * @async
   * @static
   * @param {Config} config configuration object
   * @param {StaffAuthUser}   appUser   Staff user
   * @param {React.Dispatch}  dispatch  Dispatcher
   * @param {boolean}         silent    True skips appStatus.appBusy (runs in background)
   *
   * @returns {StaffAuthUserUpdate} Partial user updates
   */
  static fetchProfileData = async (
    config: Config,
    appUser: StaffAuthUser,
    dispatch?: React.Dispatch<{
      type: string;
      payload: AppStatusUpdate | boolean | string | StaffAuthUser | StaffAuthUserUpdate;
    }>,
    silent = false,
  ): Promise<StaffAuthUserUpdate> => {
    const apiHelper = new BaseApiHelper(config);
    LogHelper.info(
      config,
      `Starting profile data load for user ${appUser.email}`,
      'HELPER|UserHelper',
    );
    const cs = await UserHelper.getCognitoSession(config);
    let avatarUrl = '';
    const update: StaffAuthUserUpdate = {};
    const requestSetup = {
      // On login user might still not be fully cached here, hence adding auth header just in case
      headers: {
        Authorization: cs?.getIdToken().getJwtToken(),
      },
    };
    // try {
    const result = await apiHelper.apiRequest(
      'profile',
      'userInfo',
      { userId: `${appUser.userId}` },
      requestSetup,
    );
    if (result && result.data) {
      if (result.data.avatarUrl && result.data.avatarUrl !== null) {
        avatarUrl = result.data.avatarUrl;
      } else {
        avatarUrl = `${process.env.PUBLIC_URL}/img/avatars/default-avatar.svg`;
      }
      if (result.data?.termsOfUseAccepted === 1) {
        update.termsOfUseAccepted = true;
      }
      if (result.data?.privacyPolicyAccepted === 1) {
        update.privacyPolicyAccepted = true;
      }
      if (result.data?.createdDateTime) {
        update.createdDateTime = result.data.createdDateTime;
      }
    } else {
      throw new Error('Failed loading user profile data');
    }
    update.avatarUrl = avatarUrl;
    LogHelper.info(
      config,
      `Finished profile data load for user ${appUser.email}`,
      'HELPER|UserHelper',
    );
    if (typeof dispatch !== 'undefined' && !silent) {
      dispatch({
        type: 'UPDATE_APP_STATUS',
        payload: {
          appBusy: true,
          appBusyMessage: 'Setting up role',
        },
      });
    }
    return update;
  };

  /**
   * Loads proctor session id for current user
   *
   * @async
   * @param {StaffAuthUser} appUser   App user
   * @param {Config} config configuration object
   * @param {BaseApiHelper} apiHelper apiHelper object
   *
   * @returns {number|null}           Session id as number (-1 if none) or null if call fails
   */
  static loadProctorSessionId = async (
    appUser: StaffAuthUser,
    config: Config,
  ): Promise<number | null> => {
    const apiHelper = new BaseApiHelper(config);
    let result: number | null = null;
    const cs = await UserHelper.getCognitoSession(config);
    try {
      const response = await apiHelper.apiRequest(
        'proctoringApiUrl',
        'ProctorSession',
        {
          proctorId: appUser.userId,
          proctorCode: appUser.currentProctorCode,
          customerId: appUser.customerId,
        },
        {
          headers: {
            Authorization: cs?.getIdToken().getJwtToken(),
          },
        },
      );
      if (response && response.data && !isNaN(+response.data) && isFinite(+response.data)) {
        result = +response.data;
      } else {
        result = -1;
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      LogHelper.error(`${err.message}`, 'HELPER|UserHelper');
    }
    return result;
  };

  static loadUser = async (
    config: Config,
    appUser: StaffAuthUser,
    dispatch: React.Dispatch<{
      type: string;
      payload: AppStatusUpdate | boolean | string | StaffAuthUser | StaffAuthUserUpdate;
    }>,
    force = false,
    silent = false,
  ): Promise<StaffAuthUser> => {
    const promises: [Promise<StaffAuthUserUpdate>?, Promise<UserRole[]>?] = [];

    if (appUser.userId && appUser.email && appUser.customerId) {
      if (!silent) {
        dispatch({
          type: 'UPDATE_APP_STATUS',
          payload: {
            appBusy: true,
            appBusyMessage: 'Loading user data',
          },
        });
      }
      if (force || !appUser.avatarUrl || !appUser.createdDateTime) {
        promises.push(UserHelper.fetchProfileData(config, appUser, dispatch, silent));
      } else {
        promises.push(Promise.resolve({} as StaffAuthUserUpdate));
      }
      const rolesLoaded =
        appUser?.roles?.length &&
        !appUser.roles.some((userRole) => {
          return !(userRole.schools.length > 0 && userRole?.permissions?.length);
        });
      if (force || !rolesLoaded) {
        promises.push(UserHelper.fetchRolesData(appUser, config));
      } else {
        promises.push(Promise.resolve([] as UserRole[]));
      }
      try {
        const results = await Promise.all(promises);
        if (results && results.length) {
          for (let i = 0; i < results.length; i++) {
            const rslt = results[i as keyof typeof results];
            if (!Array.isArray(rslt)) {
              const update = rslt as StaffAuthUserUpdate;
              if (update.avatarUrl) {
                appUser.avatarUrl = update.avatarUrl;
              }
              if (update.createdDateTime) {
                appUser.createdDateTime = update.createdDateTime;
              }
              if (typeof update.termsOfUseAccepted !== 'undefined') {
                appUser.termsOfUseAccepted = update.termsOfUseAccepted;
              }
              if (typeof update.privacyPolicyAccepted !== 'undefined') {
                appUser.privacyPolicyAccepted = update.privacyPolicyAccepted;
              }
              // appUser.loggedIn = appUser.loggedIn && appUser.termsOfUseAccepted && appUser.privacyPolicyAccepted;
            } else if (Array.isArray(rslt) && rslt.length > 0) {
              const userRoles = rslt as UserRole[];
              appUser.roles = userRoles;
              if (userRoles.length) {
                const roleName = UserHelper.getCurrentStaffRoleName(config);
                if (roleName && appUser.role !== roleName) {
                  appUser.role = roleName;
                }
                if (!appUser.role) {
                  appUser.role = UserHelper.getHighestStaffRole(appUser);
                }
                UserHelper.setCurrentStaffRoleName(appUser.role, config);
                const roleIndex = userRoles.findIndex((val) => val.name === appUser.role);
                if (roleIndex >= 0) {
                  const ucr = appUser.roles[roleIndex as keyof typeof appUser.roles] as UserRole;
                  appUser.currentRole = ucr;
                  appUser.currentProctorCode = UserHelper.getStaffUserCurrentProctorCode(appUser);
                  appUser.currentPermissions = UserHelper.getStaffRolePermissions(
                    appUser,
                    appUser.role,
                  );
                  appUser.currentSchools = ucr?.schools ? (ucr.schools as School[]) : [];
                  appUser.currentProctorSessionId = await UserHelper.loadProctorSessionId(
                    appUser,
                    config,
                  );
                }
              }
            }
          }
        }
      } catch (ex: unknown) {
        const err = ex as Error;
        LogHelper.error(`${err.message}`, 'HELPER|UserHelper');
      }
      if (!silent) {
        dispatch({
          type: 'UPDATE_APP_STATUS',
          payload: {
            appBusy: false,
            appBusyMessage: 'Please Wait...',
          },
        });
      }
      appUser.canChangeRole = UserHelper.updateCanChangeRole(appUser);
      UserHelper.storeLocalUser(appUser, config);
    }
    return appUser;
  };

  /**
   * getFormattedUserRoleName maps UserRoleName to a corresponding string suitable for displaying.
   * @param {UserRoleName} role Primitive user role to perform mapping from.
   * @return {string} User role display string.
   */
  static getFormattedUserRoleName = (role: UserRoleName) => {
    switch (role) {
      case 'district_admin':
        return 'District Administrator';
      case 'staff':
        return 'School Administrator';
      case 'teacher':
        return 'School Teacher';
      case 'student':
        return 'Student';
    }
  };

  /**
   * getHighestPriorityStaffUserRoleName returns a highest priority staff user role name from an array of role names.
   * Role priority is district_admin > staff > teacher.
   *
   * @param {UserRoleName[]}   roles     An array of user roles.
   * @return {string}                    A highest priority user role found in the array of user roles. Empty string is returned as default.
   */
  static getHighestPriorityStaffUserRoleName = (roles: UserRoleName[]) => {
    if (roles.includes('district_admin')) {
      return 'district_admin';
    } else if (roles.includes('staff')) {
      return 'staff';
    } else if (roles.includes('teacher')) {
      return 'teacher';
    }

    return '';
  };

  static getHighestStaffRole = (staffUser: StaffAuthUser): string => {
    const filteredRoleKeys = staffUser?.roles ? staffUser.roles.map((val) => `${val.name}`) : [];
    const highest = UserHelper.getHighestPriorityStaffUserRoleName(
      filteredRoleKeys as UserRoleName[],
    );

    return highest || 'teacher';
  };

  static setCurrentStaffRoleName = (roleName: string, config: Config): void => {
    const cookieName = config.cookieNames.currentStaffRole;
    if (cookieName) {
      AppHelper.setCookie(
        cookieName,
        roleName,
        0,
        true,
        '/',
        config.local ? 'localhost' : config.rootCookieDomain,
      );
    }
  };

  static clearCurrentStaffRoleName = (config: Config): void => {
    const cookieName = config.cookieNames.currentStaffRole;
    if (cookieName) {
      AppHelper.removeCookie(
        cookieName,
        true,
        '/',
        config.local ? 'localhost' : config.rootCookieDomain,
      );
    }
  };

  static getCurrentStaffRoleName = (config: Config): string => {
    const cookieName = config.cookieNames.currentStaffRole;
    if (cookieName) {
      return AppHelper.getCookieValue(cookieName);
    }
    return '';
  };

  static getStaffUserRole = (user: StaffAuthUser, roleName: string): UserRole | null => {
    let result: UserRole | null = null;
    if (user.roles !== null && user.roles.length) {
      for (let i = 0; i < user.roles.length; i++) {
        const rli = user.roles[i as keyof typeof user.roles] as UserRole;
        if (rli.name === roleName) {
          result = rli;
          break;
        }
      }
    }
    return result;
  };

  static getStaffRolePermissions = (user: StaffAuthUser, roleName: string): number[] => {
    const permissionsSet: Set<number> = new Set();
    if (roleName && user?.roles && user?.roles?.length) {
      const roleData = user.roles.find((val) => val.name === roleName);
      if (roleData && roleData?.permissions) {
        roleData.permissions
          .filter(Boolean)
          .map(Number)
          .forEach((val) => {
            permissionsSet.add(val);
          });
      }
    }
    const permissions: number[] = Array.from(permissionsSet);
    permissions.sort();
    return permissions;
  };

  static updateCanChangeRole = (staffUser: StaffAuthUser): boolean => {
    let result = false;
    if (
      staffUser &&
      staffUser.loggedIn &&
      staffUser.roles &&
      staffUser.roles.length &&
      staffUser.role
    ) {
      if (staffUser.roles.some((userRole) => userRole.schools.length > 0)) {
        const schoolIds: string[] = Array.from(
          new Set(
            staffUser.roles.reduce((acc: string[], val) => {
              val.schools.forEach((school) => {
                acc.push(school.id);
              });
              return acc;
            }, []),
          ),
        );

        if (schoolIds.length >= 1 && staffUser.roles.length > 1) {
          result = true;
        }
      }
    }
    return result;
  };

  static getStaffUserCurrentProctorCode = (staffUser: StaffAuthUser): string => {
    let proctorCode = '';
    if (staffUser && staffUser.role && staffUser.roles && Array.isArray(staffUser.roles)) {
      const userRoleNames: string[] = staffUser.roles.map((suRole) => suRole.name) || [];
      const roleName = staffUser.role;
      if (roleName && userRoleNames.indexOf(roleName) >= 0) {
        const userRole: UserRole = staffUser.roles[userRoleNames.indexOf(roleName)];
        const proctorCodeChunks: string[] = userRole.proctorCode.split('-');
        if (proctorCodeChunks.length > 0) {
          proctorCode = proctorCodeChunks[proctorCodeChunks.length - 1];
        }
      }
    }
    return proctorCode;
  };

  static getUserPermissions(config: Config, userRole?: string): number[] | undefined {
    const idToken = UserHelper.parseStaffToken(
      AppHelper.getCookieValue(config.session.staff.idTokenCookie),
      config,
    );
    if (idToken && typeof idToken['custom:user_roles'] !== 'undefined') {
      try {
        const userRoles = JSON.parse(idToken['custom:user_roles']);

        switch (userRole || UserHelper.getCurrentStaffRoleName(config)) {
          case 'district_admin':
            return userRoles?.Roles?.district_admin?.DefaultPermissionsJson?.Permissions;
          case 'staff':
            return userRoles?.Roles?.Staff?.DefaultPermissionsJson?.Permissions;
          case 'teacher':
            return userRoles?.Roles?.Teacher?.DefaultPermissionsJson?.Permissions;
        }
      } catch (ex: unknown) {
        const err = ex as Error;
        LogHelper.error(
          `Error parsing user idToken to get permissions - ${err.message}`,
          'HELPER|UserHelper',
        );
      }
    }
  }

  static clearStaffUser(config: Config): void {
    AppHelper.removeCookie(
      config.session.staff.idTokenCookie,
      true,
      '/',
      config.local ? 'localhost' : config.rootCookieDomain,
    );
    AppHelper.removeCookie(config.session.staff.accessTokenCookie);
    AppHelper.removeCookie(config.session.staff.refreshTokenCookie);
    UserHelper.clearLocalUser(config);
  }

  static logoutUser = (
    config: Config,
    dispatch?: React.Dispatch<{ type: string; payload: boolean | StaffAuthUser | null }>,
  ): StaffAuthUser => {
    return UserHelper.logoutStaffUser(config, dispatch);
  };

  static logoutStaffUser = (
    config: Config,
    dispatch?: React.Dispatch<{ type: string; payload: boolean | StaffAuthUser | null }>,
  ): StaffAuthUser => {
    const localUser = UserHelper.getLocalUser(config);
    if (localUser?.loggedIn) {
    }
    const cognitoUser = UserHelper.getCognitoUser(config);
    if (cognitoUser !== null) {
      cognitoUser.signOut();
    }
    if (dispatch) {
      dispatch({ type: 'SET_STAFF_LOGGED_IN', payload: false });
      dispatch({ type: 'SET_HEADER_VISIBLE', payload: false });
    }
    UserHelper.clearStaffUser(config);
    const newStaffUser = UserHelper.initializeStaffUser();
    newStaffUser.loggedIn = false;
    if (dispatch) {
      dispatch({ type: 'SET_STAFF_USER', payload: newStaffUser });
      dispatch({ type: 'SET_COGNITO_SESSION', payload: null });
    }
    return newStaffUser;
  };

  static prepareAwsCredentials = async (
    config: Config,
  ): Promise<AWS.CognitoIdentityCredentials> => {
    return new Promise((resolve, reject) => {
      AWS.config.getCredentials((err: unknown | null) => {
        if (err) {
          // const idToken = AppHelper.getCookieValue(config.session.staff.idTokenCookie) || '';
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            // either IdentityPoolId or IdentityId is required
            // See the IdentityPoolId param for AWS.CognitoIdentity.getID (linked below)
            // See the IdentityId param for AWS.CognitoIdentity.getCredentialsForIdentity
            // or AWS.CognitoIdentity.getOpenIdToken (linked below)
            IdentityPoolId: config.awsConfig.IdentityPoolId,
            // IdentityId: config.awsConfig.IdentityId,

            // optional, only necessary when the identity pool is not configured
            // to use IAM roles in the Amazon Cognito Console
            // See the RoleArn param for AWS.STS.assumeRoleWithWebIdentity (linked below)
            // RoleArn: config.awsConfig.RoleArn,

            // optional tokens, used for authenticated login
            // See the Logins param for AWS.CognitoIdentity.getID (linked below)
            Logins: {
              // [`cognito-idp.${config.awsConfig.region}.amazonaws.com/${config.awsConfig.UserPoolId}`]: idToken,
            },

            // optional name, defaults to web-identity
            // See the RoleSessionName param for AWS.STS.assumeRoleWithWebIdentity (linked below)
            // RoleSessionName: 'web',

            // optional, only necessary when application runs in a browser
            // and multiple users are signed in at once, used for caching
            // LoginId: 'example@gmail.com'
            // optionally provide configuration to apply to the underlying service clients
            // if configuration is not provided, then configuration will be pulled from AWS.config

            // region should match the region your identity pool is located in
            // region: config.awsConfig.region,

            // specify timeout options
            // httpOptions: {
            //     timeout: 100
            // }
          });
          resolve(AWS.config.credentials as AWS.CognitoIdentityCredentials);
        } else {
          resolve(AWS.config.credentials as AWS.CognitoIdentityCredentials);
        }
      });
    });
  };

  static refreshStaffUserToken = async (
    config: Config,
    user: StaffAuthUser,
    dispatch: React.Dispatch<{
      type: string;
      payload:
        | AppStatusUpdate
        | boolean
        | string
        | StaffAuthUser
        | StaffAuthUserUpdate
        | CognitoUserSession;
    }>,
    force = false,
  ): Promise<CognitoUser> => {
    if (!AWS.config?.credentials) {
      await UserHelper.prepareAwsCredentials(config);
    }
    const credentials = AWS.config.credentials as AWS.CognitoIdentityCredentials;
    const cognitoUser = UserHelper.getCognitoUser(config);
    const session = await UserHelper.getCognitoSession(config);
    let newIdToken = '';
    let userUpdates: StaffAuthUserUpdate | null = null;
    return new Promise((resolve, reject) => {
      if (session !== null && cognitoUser !== null) {
        const refreshToken = session.getRefreshToken(); // receive session from calling cognitoUser.getSession()
        if (force || credentials.needsRefresh()) {
          UserHelper.updateUser(config, { loadComplete: false }, dispatch);
          cognitoUser.refreshSession(
            refreshToken,
            (err: unknown | null, updatedSession: CognitoUserSession) => {
              if (err) {
                reject(err);
              } else {
                newIdToken = updatedSession.getIdToken().getJwtToken();
                if (newIdToken) {
                  userUpdates = {
                    loadComplete: true,
                    identity: {
                      ...user.identity,
                      idToken: newIdToken,
                    },
                  };
                  UserHelper.setIdTokenCookie(newIdToken, config);
                  dispatch({ type: 'SET_COGNITO_USER_SESSION', payload: updatedSession });
                  UserHelper.updateUser(config, userUpdates, dispatch);
                  resolve(cognitoUser);
                } else {
                  reject(new Error('No new id token found'));
                }
              }
            },
          );
        } else {
          resolve(cognitoUser);
        }
      } else {
        reject(new Error('No cognito session to refresh'));
      }
    });
  };

  static refreshStaffUser = async (
    config: Config,
    appUser: StaffAuthUser,
    dispatch: React.Dispatch<{
      type: string;
      payload:
        | AppStatusUpdate
        | boolean
        | string
        | StaffAuthUser
        | StaffAuthUserUpdate
        | CognitoUserSession;
    }>,
    force = false,
  ): Promise<StaffAuthUser> => {
    const cognitoUser = await UserHelper.refreshStaffUserToken(config, appUser, dispatch);
    if (cognitoUser !== null) {
      return new Promise((resolve, reject) => {
        cognitoUser.getSession((err: unknown | null, session: CognitoUserSession) => {
          if (err) {
            // console.log(err);
            reject(err);
          } else {
            dispatch({ type: 'SET_COGNITO_SESSION', payload: session });
            const idToken = session.getIdToken().getJwtToken();
            if (idToken) {
              const newUser = UserHelper.populateStaffTokenData(
                UserHelper.initializeStaffUser(),
                idToken,
                config,
              );
              resolve(newUser);
              // UserHelper.loadUser(newUser, dispatch, true).then(resolve).catch(reject);
            } else {
              reject(new Error('Could not refresh idToken'));
              // resolve(appUser);
            }
          }
        });
      });
    }
    return Promise.resolve(appUser);
  };

  static overwriteUser = (
    config: Config,
    newUser: StaffAuthUser,
    dispatch: React.Dispatch<{
      type: string;
      payload:
        | AppStatusUpdate
        | boolean
        | string
        | StaffAuthUser
        | StaffAuthUserUpdate
        | CognitoUserSession;
    }>,
  ) => {
    dispatch({ type: 'SET_STAFF_USER', payload: newUser });
    UserHelper.storeLocalUser(newUser, config);
  };

  static updateUser = (
    config: Config,
    userUpdates: StaffAuthUserUpdate,
    dispatch: React.Dispatch<{
      type: string;
      payload: AppStatusUpdate | boolean | string | StaffAuthUser | StaffAuthUserUpdate;
    }>,
  ) => {
    let oldUser = UserHelper.getLocalUser(config);
    if (oldUser === null) {
      oldUser = UserHelper.initializeStaffUser();
    }
    const scalarValues: StaffAuthUserUpdate = {
      ...userUpdates,
    };

    delete scalarValues.roles;
    delete scalarValues.currentRole;
    delete scalarValues.currentSchools;
    delete scalarValues.currentPermissions;
    delete scalarValues.identity;

    const newUser: StaffAuthUser = {
      ...oldUser,
      ...scalarValues,
    };

    if (userUpdates?.roles) {
      newUser.roles = userUpdates.roles;
    } else if (oldUser?.roles) {
      newUser.roles = oldUser.roles;
    }

    if (typeof userUpdates?.currentRole !== 'undefined') {
      newUser.currentRole = userUpdates.currentRole;
    } else if (typeof oldUser?.currentRole !== 'undefined') {
      newUser.currentRole = oldUser.currentRole;
    }

    if (userUpdates?.currentPermissions) {
      newUser.currentPermissions = userUpdates.currentPermissions;
    } else if (oldUser?.currentPermissions) {
      newUser.currentPermissions = oldUser.currentPermissions;
    }

    if (userUpdates?.currentSchools) {
      newUser.currentSchools = userUpdates.currentSchools;
    } else if (oldUser?.currentSchools) {
      newUser.currentSchools = oldUser.currentSchools;
    }

    if (userUpdates?.identity) {
      newUser.identity = userUpdates.identity;
    } else if (oldUser?.identity) {
      newUser.identity = oldUser.identity;
    }

    UserHelper.storeLocalUser(newUser, config);
    dispatch({ type: 'SET_STAFF_USER', payload: newUser });
  };

  static clearSession = (
    config: Config,
    dispatch: React.Dispatch<{ type: string; payload: StaffAuthUser | boolean | null }>,
  ) => {
    UserHelper.clearStaffUser(config);
    const newStaffUser = UserHelper.initializeStaffUser();
    dispatch({ type: 'SET_STAFF_USER', payload: newStaffUser });
    dispatch({ type: 'SET_COGNITO_SESSION', payload: null });
  };

  static splitString = (text: string, limit: number): string[] => {
    let value = text;
    const result: string[] = [];
    while (value.length > limit) {
      let pos = value.substring(0, limit).lastIndexOf(' ');
      pos = pos <= 0 ? limit : pos;
      result.push(value.substring(0, pos));
      let i = value.indexOf(' ', pos) + 1;
      if (i < pos || i > pos + limit) {
        i = pos;
      }
      value = value.substring(i);
    }
    result.push(value);
    return result;
  };

  static clearCookieUser = (config: Config) => {
    const cookieName = config.session.staff.userCacheKey;
    const cookieChunks = +AppHelper.getCookieValue(`${cookieName}Chunks`);
    if (!isNaN(cookieChunks) && isFinite(cookieChunks)) {
      for (let i = 0; i < cookieChunks; i++) {
        AppHelper.removeCookie(
          `${cookieName}Chunk_${i}`,
          true,
          '/',
          config.local ? 'localhost' : config.rootCookieDomain,
        );
      }
      AppHelper.removeCookie(
        `${cookieName}Chunks`,
        true,
        '/',
        config.local ? 'localhost' : config.rootCookieDomain,
      );
    }
  };

  static setIdTokenCookie = (idToken: string, config: Config) => {
    AppHelper.setCookie(
      config.session.staff.idTokenCookie,
      idToken,
      0,
      true,
      '/',
      config.local ? 'localhost' : config.rootCookieDomain,
    );
  };

  static clearIdTokenCookie = (config: Config) => {
    AppHelper.removeCookie(
      config.session.staff.idTokenCookie,
      true,
      '/',
      config.local ? 'localhost' : config.rootCookieDomain,
    );
  };

  /**
   * Gets cookie value
   *
   * @param {string} name Name of the cookie
   *
   * @returns {string} Cookie value
   */
  static getCookieValue = (name: string): string => {
    let value = '';
    const cookies = Object.fromEntries(document.cookie.split(/;\s?/).map((cp) => cp.split('=')));
    if (cookies && cookies[name as keyof typeof cookies]) {
      value = decodeURIComponent(cookies[name as keyof typeof cookies]);
    }
    return value;
  };

  /**
   *
   * Local environment packs user data into multiple cookies sized less than ~4000 B
   * to keep single set-cookie header from getting too long and receiving HTTP 413
   *
   * This method unpacks that data into original user
   *
   * @returns {StaffAuthUser|null} Staff user
   */
  static getCookieUser = (): StaffAuthUser | null => {
    let result: StaffAuthUser | null = null;
    const cookieName = 'elevateUser';
    const cookieChunks = +UserHelper.getCookieValue(`${cookieName}Chunks`);
    let cookieValue = '';
    if (!isNaN(cookieChunks) && isFinite(cookieChunks)) {
      for (let i = 0; i < cookieChunks; i++) {
        cookieValue += UserHelper.getCookieValue(`${cookieName}Chunk_${i}`);
      }
    }
    if (cookieValue) {
      result = UserHelper.unpackUser(cookieValue);
    }
    return result;
  };

  static setCookie = (
    name: string,
    value: string,
    ttlHours = 0,
    secure = true,
    path = '/',
    domain = '',
  ): void => {
    let cookieString = `${name} = ${encodeURIComponent(value)};`;
    if (ttlHours !== 0) {
      if (ttlHours > 0) {
        const date = new Date();
        date.setTime(date.getTime() + ttlHours * 60 * 60 * 1000);
        cookieString += ` expires = ${date.toUTCString()};`;
      } else {
        cookieString += ' expires = -1;';
      }
    }
    if (secure) {
      cookieString += ' secure;';
    }
    if (path) {
      cookieString += ` path = ${path};`;
    }
    if (domain) {
      cookieString += ` domain = ${domain};`;
    }
    cookieString = cookieString.replace(/;$/, '');
    document.cookie = cookieString;
  };

  static storeCookieUser = (newUser: StaffAuthUser, config: Config) => {
    const cookieName = 'elevateUser';
    const userCopy = UserHelper.cloneStaffUser(newUser);
    userCopy.currentSchools = [];
    if (userCopy.roles !== null) {
      for (let i = 0; i < userCopy.roles.length; i++) {
        userCopy.roles[i].schools = [];
      }
    }
    if (userCopy.currentRole !== null) {
      userCopy.currentRole.schools = [];
    }
    const packedString = UserHelper.packUser(userCopy);
    if (packedString) {
      const cookieChunks = UserHelper.splitString(packedString, 1000);
      UserHelper.setCookie(
        `${cookieName}Chunks`,
        `${cookieChunks.length}`,
        0,
        true,
        '/',
        config.local ? 'localhost' : config.rootCookieDomain,
      );
      for (let i = 0; i < cookieChunks.length; i++) {
        const currentCookieChunk = cookieChunks[i as keyof typeof cookieChunks];
        UserHelper.setCookie(
          `${cookieName}Chunk_${i}`,
          `${currentCookieChunk}`,
          0,
          true,
          '/',
          config.local ? 'localhost' : config.rootCookieDomain,
        );
      }
    }
  };

  static packUser = (newUser: StaffAuthUser): string => {
    const userJson = JSON.stringify(newUser);
    let packedString = '';
    // let newResult = '';
    if (userJson) {
      let result = new Uint8Array();
      result = pako.deflate(userJson);
      packedString = Buffer.from(result).toString('base64');
    }
    return packedString;
  };

  static unpackUserJson = (packedString: string): string => {
    let userJson = '';
    const binary_string = window.atob(packedString);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      // bytes[i] = binary_string.charCodeAt(i);
      bytes.set([binary_string.charCodeAt(i)], i);
    }

    userJson = pako.inflate(new Uint8Array(bytes), { to: 'string' });
    return userJson;
  };

  static unpackUser = (packedString: string): StaffAuthUser | null => {
    let result: StaffAuthUser | null = null;
    const userJson = UserHelper.unpackUserJson(packedString);
    result = JSON.parse(userJson) as StaffAuthUser;
    return result;
  };

  static cloneStaffUserRole = (originalRole: UserRole): UserRole => {
    const cloneRole = {
      ...originalRole,
      schools:
        typeof originalRole.schools !== 'undefined' && Array.isArray(originalRole.schools)
          ? originalRole.schools.map((v) => ({ ...v }))
          : [],
      permissions:
        typeof originalRole.permissions === 'undefined'
          ? []
          : originalRole.permissions.map((v) => v),
    };
    return cloneRole;
  };
  static cloneStaffUser = (originalUser: StaffAuthUser): StaffAuthUser => {
    const cloneUser: StaffAuthUser = {
      ...originalUser,
      identity: {
        ...originalUser.identity,
        // idToken: '',
      },
      roles:
        typeof originalUser.roles === 'undefined' || originalUser.roles === null
          ? null
          : originalUser.roles.map((v) => UserHelper.cloneStaffUserRole(v)),
      currentRole:
        originalUser.currentRole === null
          ? null
          : UserHelper.cloneStaffUserRole(originalUser.currentRole),
      currentSchools: originalUser.currentSchools.map((v) => ({ ...v })),
      currentPermissions: originalUser.currentPermissions.map((v) => v),
    };
    return cloneUser;
  };

  static parseJWTToken = (
    token: string,
    config: Config,
  ): TokenPayload | TokenPayloadStaff | TokenPayloadStudent | null => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      const data = JSON.parse(jsonPayload) as TokenPayload;
      return data;
    } catch (ex) {
      LogHelper.error('Failed parsing jwt token', 'UserHelper', {
        info: {
          thrownError: ex,
          token,
        },
      });
      // console.error(ex);
      return null;
    }
  };

  static initializeSSOUserSession = (tokens: CognitoTokenResponse): CognitoUserSession => {
    const AccessToken = new CognitoAccessToken({ AccessToken: tokens.access_token });

    const sessionData: ICognitoUserSessionData = {
      IdToken: new CognitoIdToken({ IdToken: tokens.id_token }),
      AccessToken,
      RefreshToken: new CognitoRefreshToken({ RefreshToken: tokens.refresh_token }),
    };
    const session = new CognitoUserSession(sessionData);
    return session;
  };

  static initializeSSOUser = (session: CognitoUserSession, config: Config): CognitoUser => {
    const userPool = UserHelper.getCognitoUserPool(config);
    const userData = {
      Username: session.getAccessToken().payload.username,
      Pool: userPool,
      Storage: UserHelper.getElevateStorage(config),
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setSignInUserSession(session);
    return cognitoUser;
  };
}

export default UserHelper;
