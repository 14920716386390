import React from 'react';
import { Document } from '@react-pdf/renderer';
import { areTicketsFromSameClass, getTicketPage, TICKETS_PER_PAGE } from './util';
import { TestTakerTicket } from './TestTakerTicketsPDF.d';

export interface TestTakerTicketsPDFProps {
  assignmentName: string;
  assignmentType: string;
  createQRCodes?: boolean;
  organizationId: string;

  // It is assumed tickets coming from the API are already sorted.
  // Therefore, no additional sorting is performed, only per-page chunking.
  tickets: TestTakerTicket[];
}

const TestTakerTicketsPDF = ({
  assignmentName,
  assignmentType,
  createQRCodes,
  organizationId,
  tickets,
}: TestTakerTicketsPDFProps) => {
  const getPages = () => {
    const pages: React.ReactElement[] = [];

    let chunkSize = TICKETS_PER_PAGE;
    for (let i = 0; i < tickets.length; i += chunkSize) {
      let chunk = tickets.slice(i, i + TICKETS_PER_PAGE);

      for (let j = chunk.length; j > 0; j--) {
        if (!areTicketsFromSameClass(chunk[0], chunk[j - 1])) {
          chunkSize = j;
          break;
        }
      }

      chunk = chunk.slice(0, chunkSize);

      pages.push(getTicketPage(assignmentName, assignmentType, organizationId, chunk));
    }

    return pages;
  };

  return <Document>{getPages()}</Document>;
};

export default TestTakerTicketsPDF;
