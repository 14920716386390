import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import TestAssignmentTrash from '../../components/TestAssignment/TestAssignmentTrash/TestAssignmentTrash';

const Page: React.FC<RouteComponentProps> = (props) => {
  return <TestAssignmentTrash title="Assignment Trash" />;
};

export default Page;
