/**
 * Utility functions for working with the application Staff/Student Users
 */

/**
 * Format a Proctor Code for readability - NN-NNNNNN
 *
 * @param {string} proctorCode An unformatted Proctor Code
 * @returns {string} A formatted Proctor Code (NN-NNNNNN)
 */
export const formatProctorCode = (proctorCode: string): string => {
  return proctorCode && proctorCode.length > 2
    ? `${proctorCode.slice(0, 2)}-${proctorCode.slice(2)}`
    : 'N/A';
};

/**
 * Given a role name string, return the human-readable role label
 *
 * @param {string} roleName the system role name
 * @returns {string} a human readable role label
 */
export const getRoleLabel = (roleName: string): string => {
  switch (roleName) {
    case 'student':
      return 'Student';
    case 'teacher':
      return 'School Teacher';
    case 'staff':
      return 'School Administrator';
    case 'district_admin':
      return 'District Administrator';
    default:
      return 'Staff';
  }
};
