import React from 'react';

import './TermsAndConditions.css';

const TermsAndConditions: React.FC = () => {
  return (
    <article className="elevate-static-content elevate-terms">
      <div className="text-content">
        <h2 className="article-title" id="terms-modal-title">
          Riverside Assessments, LLC
          <br />
          dba Riverside Insights
          <br />
          Terms of Use
        </h2>
        <p className="last-content-update">LAST UPDATED: December 19, 2023</p>
        <p>
          Riverside Assessments, LLC dba Riverside Insights (“Riverside,” “We,” “Us,” or “Our”)
          provides content for Our assessments (collectively, the “Products”) and related assessment
          management features via Our web-based platforms, including Riverside Elevate, Riverside
          Score, Riverside DataManager, the WJ IV Interpretation and Instructional Interventions
          Program (WIIIP), BDI-2 DataManager, and BDI-3 Mobile Data Solution (collectively, the
          “Platforms” and, together with the Products, the “Services”).
        </p>
        <p>
          These Terms of Use (the “Terms” or “Terms of Use”) constitute a legal agreement concerning
          Riverside’s Services and are between you, either as an individual or as an authorized
          representative on behalf of an organization, such as a school district, educational
          authority, university, clinic, hospital, or healthcare system (“You” or “Your”), and
          Riverside. Please note that different or additional terms may apply regarding your license
          of the Services if agreed to in writing between You and Riverside. If you are a user of
          the District version of easyCBM (“easyCBM”), please refer to the easyCBM Subscriber
          Agreement included in your easyCBM order form or presented to you at the time you
          completed your order for easyCBM (“Subscriber Agreement”). The Subscriber Agreement
          contains the terms and conditions applicable to your use of easyCBM.
        </p>
        <p>
          PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING, USING, OR DISPLAYING THE SERVICES,
          YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THESE TERMS OF
          USE AND TO THE COLLECTION AND USE OF YOUR INFORMATION AS SET FORTH IN RIVERSIDE’S{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://info.riversideinsights.com/privacy-assessment_policy?hsLang=en"
          >
            ASSESSMENT PRIVACY POLICY
          </a>{' '}
          (THE “PRIVACY POLICY”). DO NOT ACCESS, USE, OR DISPLAY THE SERVICES IF YOU DO NOT AGREE TO
          THESE TERMS AND THE PRIVACY POLICY.
        </p>
        <h3>
          <strong>1. Definitions</strong>
        </h3>
        <ul className="terms-definitions">
          <li>
            <strong>“COPPA“</strong>
            <span>
              means the Children’s Online Privacy Protection Act, 15 U.S.C. §§ 6501-6505, and the
              regulations promulgated thereunder, each as amended.
            </span>
          </li>
          <li>
            <strong>“DFARS”</strong>
            <span>
              means the Department of Defense FAR Supplement, codified at 48 C.F.R. Parts 200-299.
            </span>
          </li>
          <li>
            <strong>“Effective Date”</strong>
            <span>
              means the earlier of (i) the date You accept these Terms of Use (electronically or
              otherwise) or (ii) the date You first begin to use the Services.
            </span>
          </li>
          <li>
            <strong>“FAR”</strong>
            <span>means the Federal Acquisition Regulation, codified at 48 C.F.R. Parts 1-52.</span>
          </li>
          <li>
            <strong>“FERPA”</strong>
            <span>
              means the Family Educational Rights and Privacy Act, 20 U.S.C. § 1232g, and the
              regulations promulgated thereunder, each as amended.
            </span>
          </li>
          <li>
            <strong>“HIPAA”</strong>
            <span>
              means the Health Insurance Portability and Accountability Act of 1996, 42 U.S.C.
              §&nbsp;1320d <em>et seq.</em>, and the regulations promulgated thereunder, each as
              amended.
            </span>
          </li>
          <li>
            <strong>“Term”</strong>
            <span>
              means the term during which these Terms of Use are in effect, which will begin on the
              Effective Date and continue for as long as You have access to the Services, subject to
              the termination and survival provisions of Section 11 (Term and Termination).
            </span>
          </li>
        </ul>
        <h3>
          <strong>2. Access to Licensed Services</strong>
        </h3>
        <p>
          Subject to Your compliance with these Terms of Use and any accompanying user
          documentation, Riverside grants You a personal, limited, nontransferable,
          nonsublicensable, nonexclusive license to access and use the applicable Services during
          the Term. Riverside reserves the right, upon prior written notice to You, to discontinue
          versions of the Services. If a Service is discontinued, Riverside will notify you about
          whether a new version of such Service is available, and, if such version is available, You
          will be required to license the latest version of such Service in order to maintain
          access.
        </p>
        <h3>
          <strong>3. Access to the Platform</strong>
        </h3>
        <h4>
          <strong>3.1 Required Computing Resources</strong>
        </h4>
        <p>
          Use of the Services requires, at a minimum, computing resources needed to access and
          browse the internet. Such computing resources may include, as specified in applicable user
          documentation: (i) a personal computer and/or mobile device; (ii) software, including
          browser software and operating system software; and (iii) other specified client-side
          computing resources (collectively, “Client-Side Computing Resources”). You are responsible
          for ensuring that You (a) have access to requisite Client-Side Computing Resources and (b)
          are sufficiently familiar with and trained regarding such Client-Side Computing Resources.
        </p>
        <p>
          Riverside does not guarantee that the Services will operate with Your specific Client-Side
          Computing Resources. You are advised to carefully review each Service’s posted minimum
          system requirements to ensure compatibility.
        </p>
        <h4>
          <strong>3.2 Enrollment Responsibilities</strong>
        </h4>
        <p>
          Depending on the specific Services You are using and your role with respect to such
          Services, You may need to select the users who will have access to the Services and
          prepare the necessary files to import or manually enroll such users employing features
          provided in the Services. For some Services, We may limit the number of users per
          subscription license. <a href="#termsFootnote_01">[1]</a>
        </p>
        <h4>
          <strong>3.3 Use of Passwords</strong>
        </h4>
        <p>
          Once enrolled, You will have the opportunity to create a password for Your assigned
          username (“Login Credentials”). All account users should have their own Login Credentials.
          Riverside will treat anyone who uses Your Login Credentials as You. Riverside will not be
          responsible for Your sharing or other misuse of Login Credentials, and Riverside will hold
          You responsible for the activities of a person using Your Login Credentials. You agree to
          maintain Your Login Credentials in confidence and to notify Riverside immediately if You
          know or suspect that someone is using Your Login Credentials in an inappropriate manner.
        </p>
        <h3>
          <strong>4. Riverside’s Intellectual Property</strong>
        </h3>
        <p>
          Riverside’s Services, including derived scaled scores based on the number of questions
          answered correctly for a given assessment (“Score Conversions”), reports of assessment
          results (“Reports”), and all related designs, layouts, appearances, and graphics therein,
          as well as the copyrights, trademarks, service marks, wordmarks, and logos contained
          within each of the foregoing, embody intellectual property rights owned by Riverside (or
          its licensors), including any rights under patent law, copyright law, trade secret law,
          and trademark law (“Riverside Intellectual Property”). All rights not expressly granted
          herein are reserved to Riverside or its licensors, as applicable.
        </p>
        <h3>
          <strong>5. Test Security; Use of Assessment Score Reports</strong>
        </h3>
        <p>
          Confidentiality is critical to the integrity, validity, and fairness of the testing
          process. Riverside restricts distribution of certain Products to qualified institutions
          and examiners. Under the <em>Standards for Educational and Psychological Testing</em>{' '}
          (2014) (“SEPT”), published by the American Educational Research Association, American
          Psychological Association, and National Council on Measurement in Education, educators and
          psychologists have a duty to protect the integrity of secured tests by maintaining the
          confidentiality of test questions and answers. Widespread dissemination of test protocols,
          which include substantial portions of the actual test items, would inevitably undermine
          this process. For this reason, Services are distributed only to recipients who agree to
          take appropriate steps to protect the confidentiality of the Services. Providing
          unauthorized third parties, including organizations or individuals providing test
          preparation or tutoring services, access to these Services; notetaking by non
          professionals during test administrations; and the audio or video recording of test
          administrations would compromise test security and violate these Terms of Use. Such
          actions may result in the termination of Your rights to access and use the Services, as
          determined in Riverside’s sole discretion.
        </p>
        <p>
          You must use the Services in accordance with these Terms of Use and applicable federal,
          state, and local laws and regulations. You understand and agree that the Services are
          meant to be used as tools to support Your assessment process and are not intended or
          designed to replace Your professional judgment. You assume all responsibility for the use
          or misuse of the Services. You must use the Services in accordance with Riverside’s{' '}
          <a target="_blank" rel="noreferrer" href="http://riversideinsights.info/test-disclosure">
            Test Disclosure Policy
          </a>{' '}
          and the SEPT (collectively, the “Policies and Standards”).
        </p>
        <h3>
          <strong>6. Grant of Rights in Submitted Data and Feedback; Storage</strong>
        </h3>
        <p>
          By providing information to Riverside directly through Your use of the Services, including
          information about students/examinees and account usage data (“Submitted Data”), You grant
          Riverside a royalty-free, nonexclusive, transferrable, sublicensable, worldwide license to
          use the Submitted Data for all purposes contemplated under these Terms of Use as well as
          any user documentation. You acknowledge and agree that Riverside may use or disclose
          Submitted Data to provide maintenance and support for the Services and for communications
          relevant to your use of the Services, such as product updates, planned outages,
          maintaining sufficient licenses, and renewals. Riverside does not claim ownership in
          Submitted Data and retains only those rights in Submitted Data reasonably necessary or
          otherwise required to provide the Services and as otherwise contemplated under these Terms
          of Use and any user documentation. Submitted Data that Riverside receives from You is
          subject to Section 14 (Riverside’s Use of Submitted Data and Feedback; De-Identified
          Information) regarding use of de-identified data and the{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://info.riversideinsights.com/privacy-assessment_policy?hsLang=en"
          >
            Privacy Policy
          </a>
          .
        </p>
        <p>
          In addition to the license You grant Us with respect to the Submitted Data, You grant
          Riverside a nonexclusive, worldwide, perpetual, royalty-free, irrevocable right to use,
          disclose, reproduce, modify, license, transfer, and otherwise distribute any comments,
          ideas, and suggestions for improvements or developments related to or associated with the
          Services that You provide (“Feedback”) in any manner without compensation to You. Please
          do not submit Feedback if You do not wish to grant Us the rights set forth in this
          Section.
        </p>
        <p>
          By providing Submitted Data and/or Feedback, You represent and warrant that You own such
          Submitted Data and/or Feedback (including intellectual property rights therein), or that
          You have obtained sufficient authority and right to the Submitted Data and/or Feedback in
          order to grant the rights to Riverside contemplated under these Terms of Use and any user
          documentation.
        </p>
        <p>
          YOU ARE ADVISED TO EXPORT AND SAFEGUARD SUBMITTED DATA AND BACK UP IMPORTANT INFORMATION
          FREQUENTLY. If You choose to provide Submitted Data to Riverside via the Services,
          Riverside will periodically back up the Submitted Data and will take reasonable steps to
          securely store said backups. Notwithstanding anything to the contrary, You hereby release
          Riverside from any claim or liability relating to any failure in Riverside’s database
          system and backup practices.
        </p>
        <p>
          After expiration of the Term, Riverside will return or delete Submitted Data, in whole or
          in part, promptly after receiving written request and instruction from You or Your
          authorized designee, unless retention is necessary in Riverside’s determination to provide
          other services to You; fulfill any other obligation it may owe You; or comply with
          applicable laws, regulations, court orders, or other legal processes. Riverside will
          retain all data that is not returned or deleted pursuant to the foregoing process in
          accordance with its standard records retention policy.
        </p>
        <p>
          Notwithstanding anything in this Section, Riverside may retain Submitted Data in
          accordance with its backup or other disaster recovery policies and procedures. You
          acknowledge and agreed that backed-up data cannot be recovered following deletion. You
          unconditionally release, waive, and discharge any right or entitlement, whether by
          contract, under operation of law, or otherwise, to bring any cause of action or claim
          against Riverside now or in the future in connection with any data deletion request You
          make. You assume any and all risk of loss, liability, damage, expenses, or costs that may
          occur as a result of Your data deletion request.
        </p>
        <h3>
          <strong>7. Platform Availability and Errors</strong>
        </h3>
        <p>
          Riverside will use commercially reasonable efforts to make the Services available to You
          without significant interruption. At times the Services may be unavailable due to
          technical errors or for maintenance and support activities. We do not represent, warrant,
          or guarantee that the Services will always be available or are completely free of human or
          technological errors.
        </p>
        <p>
          If a Service experiences a significant interruption that is not due to scheduled downtime,
          Riverside will use timely and commercially reasonable efforts to restore required
          functionality (the “Availability Commitment”). The Availability Commitment does not apply
          to downtime: (i) due to emergencies; (ii) that Riverside otherwise schedules, for example,
          to install software updates and patches; (iii) due to Your violation of these Terms; or
          (iv) due to Your failure to update or upgrade your Services or the equipment you use to
          access the Services when suggested by Riverside.
        </p>
        <p>
          The Services may contain typographical mistakes, inaccuracies, or omissions, and some
          information may not be complete or current. We expressly reserve the right to correct any
          errors, inaccuracies, or omissions and to change or update information at any time without
          prior notice. We do not make any representation or warranty concerning errors, omissions,
          delays, or defects in the Services or any information supplied to You via the Services, or
          that files available through the Services are free of viruses, worms, Trojan horses, or
          other code that include or manifest contaminating or destructive characteristics.
        </p>
        <p>
          You may contact Riverside’s technical support team with questions about the Services at
          the hours listed on our{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.riversideinsights.com/support/contact-us"
          >
            Support Page
          </a>
          . In addition to taking reasonable steps to respond to reproducible errors or bugs in the
          Services commensurate with the severity of the error or bug, technical support may also
          provide You with information regarding Service availability.
        </p>
        <h3>
          <strong>8. Use Restrictions</strong>
        </h3>
        <p>
          You agree not to copy, duplicate, publish, distribute, display, modify, create derivative
          works of, or alter physical or electronic characteristics of the Services. You agree not
          to dismantle or reverse engineer or clone any part of the Services. You will not grant
          sublicenses to, assign, transfer, sell, or rent the Services or any sublicenses thereto
          without Riverside’s prior written consent.
        </p>
        <p>
          Because the Services, including Score Conversions and Reports, are Riverside Intellectual
          Property and are considered confidential information of Riverside, the Services will not
          be disclosed by You in response to requests made by third parties unless otherwise
          required pursuant to applicable law or valid court order, and then only after prior notice
          is provided to Riverside as well as an opportunity to prevent such disclosure. You agree
          that You will not otherwise, directly or indirectly, disclose any confidential information
          of Riverside without Riverside’s prior written consent.
        </p>
        <p>
          Subject to the restrictions in Section 5 (Test Security; Use of Assessment Score Reports),
          You may print, copy, display, and otherwise distribute Reports, but not any other parts of
          the Services, provided that such actions comply with Your obligations under the Policies
          and Standards and are otherwise in compliance with all applicable laws, regulations, and
          professional standards and obligations. You represent and warrant that You have obtained
          the necessary permissions from parents/guardians, students/examinees, and other applicable
          third parties relating to Your use of the Reports. You hereby release Riverside from any
          claim or liability relating to Your use of the Reports.
        </p>
        <p>
          Notwithstanding anything to the contrary, You will not under any circumstance import any
          external content into any Reports or copy, display, or reproduce any test question from
          the Services without Riverside’s prior written consent.
        </p>
        <p>
          Your use of the Services to generate Reports is based on quantities of student
          administrations (record forms, answer documents, other consumable test or response
          booklets, digital administrations, or digital licenses) that You license from Riverside.
          You are only entitled to assess one student/examinee per record form, answer document,
          other consumable test or response booklet, digital administration, or digital license;
          however, multiple different Reports may be generated from a single test administration.
        </p>
        <p>
          You agree that when using the Services, You will not: (i) introduce into the Services any
          virus, rogue program, time bomb, drop dead device, ransomware, back door, Trojan horse,
          worm, or other malicious or destructive code, software routines, denial of service attack,
          or equipment components designed to permit unauthorized access to the Services; (ii)
          otherwise harm other users, Riverside Intellectual Property, or any third parties; or
          (iii) authorize any third parties to perform any of the foregoing actions.
        </p>
        <p>
          You will not use the Services to commit fraud or conduct other unlawful activities. You
          will not access or attempt to access any other person’s account, personal information, or
          content without having the requisite permission or authority.
        </p>
        <p>
          You will not use any bot, spider, or other automatic or manual device or process for the
          purpose of harvesting or compiling information about the Services or any users thereof for
          any reason.
        </p>
        <p>
          You will not decrypt, transfer, frame, display, or translate (except translations for
          limited personal use authorized in writing by Riverside) any part of the Services.
        </p>
        <p>
          You will not connect to or access any Riverside computer system or network without
          authorization.
        </p>
        <p>
          You will not use the information in the Services to create or sell a similar product or
          service, or use the Services for the purpose of soliciting, selling, or offering services,
          merchandise, or products.
        </p>
        <h3>
          <strong>9. Third Party Websites</strong>
        </h3>
        <p>
          The Services may integrate with or provide links to other content, including websites or
          open education resources, on the Internet that We do not control. This content may provide
          opinions, recommendations, or other information from various individuals, organizations,
          or companies. We are not responsible for the nature, quality, or accuracy of such content.
          Inclusion of any linked content in the Services does not imply or express an approval or
          endorsement thereof by Us or of any of the opinions, treatments, information, products, or
          services provided in this content, even if We receive a referral fee in connection with
          Your use of such third-party content.
        </p>
        <h3>
          <strong>10. Limited Warranty</strong>
        </h3>
        <p>
          Riverside warrants that the Services will not infringe any valid United States copyrights
          existing at the time the Services are made available to You, provided that this warranty
          does not extend to any infringement arising out of: (i) the use of the Services in
          combination with systems, equipment, materials, content, or platforms not supplied by
          Riverside or any use of the Services outside of the United States; (ii) Your use of the
          Services in violation of these Terms of Use the user documentation provided by Riverside,
          or any other agreement between You and Riverside; (iii) Your modification of the Services;
          (iv) Your failure to install or implement a released upgrade to the Services that would
          have avoided the infringement; or (v) any Submitted Data or Feedback. If You promptly
          notify Riverside of any such infringement claim brought by a third party of which You have
          knowledge or notice, and accord Riverside the right, at its sole option and expense, to
          handle the defense of the infringement claim, Riverside will defend You against such
          infringement claim and pay any final judgment or settlement thereof. Notwithstanding the
          foregoing, Riverside will not indemnify for any infringement claim that arises out of the
          scenarios set forth in clauses (i)-(v) of this Section. If such an infringement claim
          arises, or if Riverside becomes aware of the possibility of such a claim, then Riverside
          may, at its sole discretion (a) acquire the right for You to continue to use the affected
          Services in accordance with these Terms, (b) furnish You with a non-infringing replacement
          as soon as commercially possible, or (c) terminate these Terms in whole or in part by
          refunding any pre-paid, unused fees You paid for use of the Services. The obligations set
          forth in this Section are Your exclusive remedy and Riverside’s sole obligations with
          respect to any breach of this warranty.
        </p>
        <p>
          EXCEPT AS OTHERWISE EXPRESSLY STATED IN THIS SECTION 10 (LIMITED WARRANTY), THE SERVICES
          ARE PROVIDED “AS IS.” RIVERSIDE MAKES NO WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED BY
          LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE, WITH RESPECT
          TO THE SERVICES, INCLUDING ANY WARRANTY OF MERCHANTABILITY OR WARRANTY OF FITNESS FOR A
          PARTICULAR PURPOSE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, RIVERSIDE DOES NOT
          WARRANT OR MAKE ANY PROMISES REGARDING THE CORRECTNESS, COMPLETENESS, SECURITY,
          USEFULNESS, ACCURACY, AVAILABILITY, OR RELIABILITY OF (I) YOUR USE THE SERVICES OR (II)
          ANY ADVICE YOU GLEAN OR INFER FROM THE SERVICES, WHETHER PROVIDED BY US OR A THIRD PARTY.
          WE DO NOT WARRANT (X) THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, OR FREE OF
          HARMFUL CODE; (Y) THAT THE SERVICES WILL MEET YOUR REQUIREMENTS OR ACHIEVE ANY INTENDED
          RESULT; OR (Z) THAT ANY DEFECTS WITH RESPECT TO THE SERVICES WILL BE CORRECTED.
        </p>
        <h3>
          <strong>11. Term and Termination</strong>
        </h3>
        <p>
          These Terms of Use are effective during the Term, subject to the termination and survival
          provisions of this Section 11 (Term and Termination).
        </p>
        <p>
          Either party will have the right to terminate these Terms of Use if the other party
          breaches any of its obligations thereunder and fails to cure the same within thirty (30)
          days after receipt of written notice of default, except that there will be no cure period
          for Your breach of Riverside’s rights under Section 4 (Riverside’s Intellectual Property);
          Section 5 (Test Security; Use of Assessment Score Reports), Section 6 (Grant of Rights in
          Submitted Data and Feedback; Storage), or Section 8 (Use Restrictions). Upon termination
          of these Terms of Use, any rights You have in the Services will terminate.
        </p>
        <p>
          The provisions of Section 4 (Riverside’s Intellectual Property), Section 5 (Test Security;
          Use of Assessment Score Reports), Section 6 (Grant of Rights in Submitted Data and
          Feedback; Storage), Section 8 (Use Restrictions), Section 10 (Limited Warranty), Section
          12 (Indemnification), Section 13 (Limitation of Liability), Section 14 (Riverside’s Use of
          Submitted Data and Feedback; De-Identified Information), and Section 19 (General) will
          survive any expiration or termination of these Terms of Use. Riverside reserves the right
          to terminate these Terms of Use for convenience by providing You with reasonable notice
          and thereafter allowing You a reasonable opportunity (not to exceed 30 days) to export a
          copy of Your Submitted Data.
        </p>
        <p>
          If these Terms of Use are terminated for any reason, Riverside may make a reasonable
          effort to grant You access to the Services for not more than thirty (30) days for the sole
          purpose of exporting Submitted Data (the “Submitted Data Retrieval Period”). Upon
          conclusion of the Submitted Data Retrieval Period, Riverside may destroy copies of
          Submitted Data in its possession.
        </p>
        <p>
          If these Terms of Use expire, retention of Submitted Data will be governed by Section 6
          (Grant of Rights in Submitted Data and Feedback; Storage).
        </p>
        <h3>
          <strong>12. Indemnification</strong>
        </h3>
        <p>
          EXCEPT TO THE EXTENT PROHIBITED BY APPLICABLE LAW, YOU AGREE TO INDEMNIFY, DEFEND, AND
          HOLD RIVERSIDE HARMLESS AGAINST ALL CLAIMS, ACTIONS, LIABILITIES, LOSSES, DEMANDS,
          DAMAGES, DEFICIENCIES, JUDGEMENTS, SETTLEMENTS, INTEREST, AWARDS, PENALTIES, FINCES,
          COSTS, OR EXPENSES (INCLUDING REASONABLE ATTORNEYS’ FEES AND EXPENSES) ARISING OUT OF OR
          IN CONNECTION WITH: (I) YOUR USE OF THE SERVICES COVERED BY THESE TERMS AND/OR (II) YOUR
          FAILURE TO COMPLY WITH THESE TERMS.
        </p>
        <h3>
          <strong>13. Limitation of Liability</strong>
        </h3>
        <p>
          RIVERSIDE’S TOTAL AGGREGATE LIABILITY FOR LOSSES OR DAMAGES RELATING TO THESE TERMS OF USE
          AND/OR THE SERVICES, OR YOUR USE OR INABILITY TO USE THE SERVICES, REGARDLESS OF THE FORM
          OF ACTION, WILL IN NO EVENT EXCEED THE GREATER OF: (A) ONE THOUSAND U.S. DOLLARS (USD
          $1,000.00) OR (B) THE FEES ACTUALLY PAID BY YOU TO RIVERSIDE IN THE 12 MONTHS PRECEDING
          THE EVENT GIVING RISE TO THE LIABILITY.
        </p>
        <p>
          IN NO EVENT WILL RIVERSIDE BE LIABLE TO YOU OR ANY THIRD PARTY, EITHER IN CONTRACT, TORT,
          OR OTHERWISE, FOR ANY INDIRECT, SPECIAL, PUNITIVE, ENHANCED, EXEMPLARY, INCIDENTAL, OR
          CONSEQUENTIAL DAMAGES, INCLUDING LOSS OF FUTURE REVENUE, INCOME OR PROFITS, LOSS OF DATA,
          OR DIMINUTION IN VALUE, ARISING OUT OF OR RELATING TO YOUR USE OF THE SERVICES OR IN
          CONNECTION WITH ANY BREACH OF THIS AGREEMENT, REGARDLESS OF (X) WHETHER SUCH DAMAGES WERE
          FORESEEABLE, (Y) WHETHER RIVERSIDE WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND (Z)
          THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT, OR OTHERWISE) UPON WHICH THE CLAIM IS
          BASED.
        </p>
        <p>
          THE LIMITATIONS SPECIFIED IN THIS SECTION 13 WILL SURVIVE AND APPLY EVEN IF ANY LIMITED
          REMEDY SPECIFIED IN THESE TERMS OF USE IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
        </p>
        <h3>
          <strong>
            14. Riverside’s Use of Submitted Data and Feedback; De-Identified Information
          </strong>
        </h3>
        <p>
          Riverside may, from time to time, anonymize Submitted Data so that it constitutes
          de-identified Information (“De-Identified Information”). Riverside will only use
          De-Identified Information in accordance with HIPAA and FERPA and for lawful purposes,
          including quality assurance, product research and development, publications relevant to
          our Services and industry, norm development and validation, and other activities to
          develop, evaluate, improve, and demonstrate the effectiveness of our educational and
          clinical Services. The De-Identified Information will not directly identify a person but
          may be linkable to a particular computer, device, operation system, platform, or software
          instance (via a unique device ID or otherwise) (“Usage Information”). You acknowledge and
          agree that Riverside will be free to use De-Identified Information, in compliance with
          HIPAA and FERPA requirements, for the purposes described in these Terms of Use.
        </p>
        <h3>
          <strong>15. Protection of Student Personal Information</strong>
        </h3>
        <p>
          Please see the{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://info.riversideinsights.com/privacy-assessment_policy?hsLang=en"
          >
            Privacy Policy
          </a>{' '}
          governing your license of our Services for information about (i) Our practices related to
          collection, use, and deletion of personal information, including how You can access,
          review, and update personal information, and (ii)&nbsp;the security measures We have in
          place designed to safeguard your information.
        </p>
        <h3>
          <strong>16. Applicability of HIPAA</strong>
        </h3>
        <p>
          If You are a “Covered Entity” as defined under HIPAA, You and Riverside agree that the
          Business Associate Addendum will govern HIPAA-related matters (click{' '}
          <a target="_blank" rel="noreferrer" href="http://riversideinsights.info/addendum">
            here
          </a>{' '}
          to review the Business Associate Addendum). If You are not a Covered Entity, this Section
          does not apply.
        </p>
        <h3>
          <strong>17. Federal Government Terms and Conditions</strong>
        </h3>
        <p>
          If You are the United States Government or any agency, subdivision, or instrumentality
          thereof (the “U.S. Government”), the Services (including any related databases,
          documentation, technical data, and programmer’s tools) delivered to the U.S. Government
          are “commercial computer software” or “commercial technical data” pursuant to the
          applicable FAR, DFARS, or other agency-specific supplemental regulations. As such, the
          use, duplication, disclosure, modification, and adaptation of the Services are subject to
          these Terms of Use, pursuant to FAR 12.212 (Computer Software) and 12.211 (Technical
          Data), as applicable. If You are the U.S. Government and subject to the DFARS, then the
          Services (including any related databases, documentation, technical data, and programmer’s
          tools) is provided subject to DFARS 252.227-7015 (Technical Data—Commercial Items) and
          DFARS 227.7202-3 (Rights in Commercial Computer Software or Computer Software
          Documentation), as applicable. Should the Services be deemed to not constitute “commercial
          computer software” or “commercial technical data,” then they will be given to the U.S.
          Government with “Limited Rights” or “Restricted Rights” (as defined under DFARS), as
          applicable. In all cases, these Terms of Use supersede any conflicting terms or conditions
          in any government order document; provided, any provisions contained herein contrary to
          applicable mandatory federal laws will be treated as provided in FAR 52.212-4(u).
        </p>
        <h3>
          <strong>18. Consent Regarding Students’ Personal Information</strong>
        </h3>
        <p>
          Please note that FERPA requires parental/guardian consent before a service provider, such
          as Riverside, is given access to personal information contained in a student’s/examinee’s
          educational records. Under FERPA, this parental/guardian consent requirement is met where
          the service provider acts as a type of “school official” by performing services for the
          school that would otherwise be performed by the school’s own employees. Riverside fulfills
          FERPA requirements for qualifying as a school official by, among other steps, giving
          schools direct control with respect to the use and maintenance of the educational records
          at issue (including associated personal information) and refraining from re-disclosing or
          using this personal information except for purposes of providing the Services or as
          required by applicable laws, regulations, court orders, or other legal processes.
          Riverside will comply with access requests as required by FERPA.
        </p>
        <h3>
          <strong>19. General</strong>
        </h3>
        <p>
          THESE TERMS WILL BE GOVERNED BY AND INTERPRETED IN ACCORDANCE WITH THE LAWS OF THE STATE
          OF ILLINOIS, WITHOUT GIVING EFFECT TO ANY CHOICE OF LAW OR CONFLICT OF LAW PRINCIPLES OR
          RULES (WHETHER UNDER THE LAWS OF THE STATE OF ILLINOIS OR OF ANY OTHER JURISDICTION) TO
          THE EXTENT SUCH PRINCIPLES OR RULES WOULD REUQIRE OR PERMIT THE APPLICATION OF THE LAWS OF
          ANY JURISDICTION OTHER THAN THOSE OF THE STATE OF ILLINOIS. The foregoing choice of law
          notwithstanding, copyright, trademark, and patent claims are subject only to U.S. Federal
          law and U.S. Federal court interpretation thereof. You agree that any action at law or in
          equity arising out of or relating to these Terms of Use will be filed only in the state or
          federal courts located in the Northern District of Illinois, Eastern Division. These Terms
          will not be assignable by You, either in whole or in part. Riverside reserves the right to
          assign the rights and obligations under these Terms of Use for any reason and in
          Riverside’s sole discretion. The{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://info.riversideinsights.com/privacy-assessment_policy?hsLang=en"
          >
            Privacy Policy
          </a>{' '}
          must be read in conjunction with these Terms of Use, and the provisions of the Privacy
          Policy are incorporated herein. These Terms of Use and the Privacy Policy constitute the
          entire agreement between You and Riverside concerning the Services, your use thereof, and
          any related activities, and supersede all discussions, proposals, bids, understandings,
          agreements, invitations, orders, and other communications, oral or written, on this
          subject. These Terms may not be waived, amended, or modified in any way without the prior
          written permission of Riverside. We may revise and update these Terms of Use from time to
          time and will post the revised terms of use to Our website and may also post links to them
          on Our Platforms. UNLESS OTHERWISE STATED IN THE AMENDED VERSION OF THESE TERMS OF USE,
          ANY CHANGES TO THESE TERMS OF USE WILL APPLY IMMEDIATELY UPON POSTING. We are not
          obligated to provide You with notice of any changes, and any changes to these Terms of Use
          will not apply retroactively to events that occurred prior to such changes. Your continued
          use of the Services will constitute Your agreement to any new provisions within the
          revised terms of use. You may print a copy of these Terms of Use and the Privacy Policy
          for Your records. If any one or more provisions of these Terms of Use are found to be
          illegal or unenforceable, the remaining provisions will be enforced to the maximum extent
          possible. To the extent any licensed order from You conflicts with or amends these Terms
          of Use in any way, these Terms of Use, as unmodified, will prevail. To the extent the
          Privacy Policy conflicts with or amends these Terms of Use in any way, the Privacy Policy
          will prevail.
        </p>
        <p>
          Any license granted under these Terms of Use to You must be expressly provided herein, and
          there will be no licenses or rights implied hereunder, based on any course of conduct or
          other construction or interpretation thereof. All rights and licenses not expressly
          granted to You by Riverside are reserved.
        </p>
        <div className="terms-footnote">
          <div id="termsFootnote_01_in">
            <span className="footnote-index">[1]</span>
            <span className="footnote-text">
              If you are a BDI-3 customer and have licensed a BDI-3 subscription, for example, you
              will be limited to 3 users per subscription license. Each user must consent to and
              abide by these Terms of Use.
            </span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default TermsAndConditions;
