// import React from 'react';
import { StaffAuthState, StaffAuthUserUpdate } from './authDataTypes';

import { AppReduxAction } from '../appDataTypes';

import { Reducer } from 'redux';

// import AppHelper from '../../lib/helper/AppHelper';
import UserHelper from '../../lib/helper/UserHelper';

/**
 * Initial state for staff authentication  store
 * @type {StaffAuthState}
 */
const initialAuthState: StaffAuthState = {
  // loggedIn: false,
  user: UserHelper.initializeStaffUser(),
  cognito: {
    userPool: null,
    cognitoUser: null,
    cognitoUserSession: null,
  },
  userStatus: {
    busy: false,
    busyMessage: '',
    error: false,
    errorMessage: '',
  },
};

export const defaultStaffAuthState: StaffAuthState = {
  ...initialAuthState,
  user: {
    ...initialAuthState.user,
    identity: {
      ...initialAuthState.user.identity,
    },
  },
  userStatus: {
    ...initialAuthState.userStatus,
  },
};

/**
 * Authentication reducer for staff auth store
 *
 * @type {Reducer<StaffAuthState, AppReduxAction>}
 *
 * @param {StaffAuthState}     state      Initial state
 * @param {AppReduxAction}     action     Redux action
 *
 * @return {Reducer} Reducer
 */
const staffAuthReducer: Reducer<StaffAuthState, AppReduxAction> = (
  state = initialAuthState,
  action,
) => {
  switch (action.type) {
    case 'UPDATE_STAFF_USER':
      const scalarValues: StaffAuthUserUpdate = {
        ...action.payload,
      };

      delete scalarValues.roles;
      delete scalarValues.currentRole;
      delete scalarValues.currentSchools;
      delete scalarValues.currentPermissions;
      delete scalarValues.identity;

      const newState: StaffAuthState = {
        ...state,
        user: {
          ...state.user,
          ...scalarValues,
        },
      };

      if (action.payload?.roles) {
        newState.user.roles = action.payload.roles;
      } else {
        newState.user.roles = state.user.roles;
      }

      if (typeof action.payload?.currentRole !== 'undefined') {
        newState.user.currentRole = action.payload.currentRole;
      } else {
        newState.user.currentRole = state.user.currentRole;
      }

      if (action.payload?.currentPermissions) {
        newState.user.currentPermissions = action.payload.currentPermissions;
      } else {
        newState.user.currentPermissions = state.user.currentPermissions;
      }

      if (action.payload?.currentSchools) {
        newState.user.currentSchools = action.payload.currentSchools;
      } else {
        newState.user.currentSchools = state.user.currentSchools;
      }

      if (action.payload?.identity) {
        newState.user.identity = action.payload.identity;
      } else {
        newState.user.identity = state.user.identity;
      }
      return newState;

    case 'SET_COGNITO_USER_POOL':
      return {
        ...state,
        cognito: {
          ...state.cognito,
          userPool: action.payload,
        },
      };
    case 'SET_COGNITO_USER':
      return {
        ...state,
        cognito: {
          ...state.cognito,
          cognitoUser: action.payload,
        },
      };
    case 'SET_COGNITO_USER_SESSION':
      return {
        ...state,
        cognito: {
          ...state.cognito,
          cognitoUserSession: action.payload,
        },
      };
    case 'SET_STAFF_LOGGED_IN':
      return {
        ...state,
        user: {
          ...state.user,
          loggedIn: false,
          loadComplete: false,
        },
        // loggedIn: action.payload,
        // user: action.payload === false ? UserHelper.initializeStaffUser() : state.user,
      };
    case 'SET_STAFF_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case 'SET_STAFF_ROLES':
      return {
        ...state,
        user: {
          ...state.user,
          roles: action.payload,
        },
      };
    case 'SET_STAFF_AVATAR_URL':
      return {
        ...state,
        user: {
          ...state.user,
          avatarUrl: action.payload,
        },
      };
    case 'UPDATE_STAFF_USER_STATUS':
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default staffAuthReducer;
