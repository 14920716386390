import React from 'react';

import { AssignmentListItem } from '../../../TestAssignment';
import type { AssignmentListTableColumn } from '../TestAssignmentTrashTable';

const renderGradesCell = (item: AssignmentListItem, column: AssignmentListTableColumn) => {
  return (
    <div className="grade-domain-items">
      {item.gradedomains.map((grade, index) => {
        return (
          <span className="grade-domain-item" key={`span${index}`}>
            {grade}
          </span>
        );
      })}
    </div>
  );
};

export default renderGradesCell;
