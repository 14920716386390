import React, { useState, useEffect, useMemo, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useLocation, useHistory } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import HouseIcon from '@mui/icons-material/House';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import AppUIDebugSection from './AppUIDebugSection';
import AppDebugDrawerSection from './AppDebugDrawerSection';

import { AppState, AppStatusUpdate } from '../../../reducers/appDataTypes';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import useAppLogger from '../../../hooks/useAppLogger';

import { TbArrowDown, TbArrowUp, TbLayoutSidebarRightExpand, TbX } from 'react-icons/tb';

import './AppDebug.css';

import AppApiDebugSection from './AppApiDebugSection/AppApiDebugSection';
import AppDebugSession from './AppDebugSession/AppDebugSession';
import AppDebugInspect from './AppDebugInspect';
import useNotificationHelper from '../../../hooks/useNotificationHelper';
import AppDebugAbly from './AppDebugAbly/AppDebugAbly';
import AppDebugLogging from './AppDebugLogging/AppDebugLogging';

const AppDebug: React.FC = () => {
  const config = useConfigContext();
  const logger = useAppLogger('COMPONENT|AppDebug');
  const NotificationHelper = useNotificationHelper();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const storeState = useSelector((state: AppState) => {
    return state;
  });
  const user = useSelector((state: AppState) => {
    return state.auth.staffAuth.user;
  });

  const [ablyStatusMinimized, setAblyStatusMinimized] = useState(true);
  const [sessionStatusMinimized, setSessionStatusMinimized] = useState(false);
  const [appStatusMinimized, setAppStatusMinimized] = useState(true);
  const [headerToolbarMinimized, setHeaderToolbarMinimized] = useState(true);
  const [apiDebugMinimized, setApiDebugMinimized] = useState(true);
  const [loggingMinimized, setLoggingMinimized] = useState(false);
  const [tabsDebugMinimized, setTabsDebugMinimized] = useState(true);
  const [headerMiscMinimized, setHeaderMiscMinimized] = useState(false);

  const [statusMenuActive, setStatusMenuActive] = useState(false);

  const [pathnameChunks, setPathnameChunks] = useState<string[]>([]);

  const [wideDebug, setWideDebug] = useState(false);
  const [originalDebugWidth, setOriginalDebugWidth] = useState('');

  const allMinimized = useMemo<boolean>(() => {
    return (
      sessionStatusMinimized &&
      tabsDebugMinimized &&
      appStatusMinimized &&
      headerToolbarMinimized &&
      apiDebugMinimized &&
      loggingMinimized &&
      headerMiscMinimized
    );
  }, [
    tabsDebugMinimized,
    sessionStatusMinimized,
    appStatusMinimized,
    headerToolbarMinimized,
    loggingMinimized,
    apiDebugMinimized,
    headerMiscMinimized,
  ]);

  const drawerMiddleRef = useRef<HTMLDivElement | null>(null);
  const statusMenuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const appStatus = useSelector((state: AppState) => {
    return state.appStatus;
  });

  const [windowId, setWindowId] = useState(appStatus.currentWindowId);

  useEffect(() => {
    const dw = getDebugWidth();
    setOriginalDebugWidth(dw);
  }, []);

  useEffect(() => {
    setWindowId(appStatus.currentWindowId);
  }, [appStatus.currentWindowId]);

  useEffect(() => {
    if (wideDebug && originalDebugWidth) {
      logger.debug('Setting wide debug on');
      setDebugWidth('50vw');
    } else if (!wideDebug && originalDebugWidth) {
      logger.debug('Setting wide debug off');
      resetDebugWidth();
    }
  }, [wideDebug]);

  const getDebugWidth = () => {
    let val = '';
    const rootEl = document.querySelector(':root');
    if (rootEl !== null) {
      const rootStyle = getComputedStyle(rootEl);
      val = rootStyle.getPropertyValue('--app-debug-width').replace(/\s+?/g, '');
    }
    return val;
  };

  const setDebugWidth = (width: string) => {
    const newWidth = width === '' ? originalDebugWidth : width;
    const rootEl = document.querySelector(':root');
    if (rootEl !== null && rootEl instanceof HTMLHtmlElement) {
      rootEl.style.setProperty('--app-debug-width', newWidth);
    }
  };

  const resetDebugWidth = () => {
    const newWidth = originalDebugWidth ? originalDebugWidth : '30ch';
    const rootEl = document.querySelector(':root');
    if (rootEl !== null && rootEl instanceof HTMLHtmlElement) {
      rootEl.style.setProperty('--app-debug-width', newWidth);
    }
  };

  const toggleAllMinimized = () => {
    const newValue = !allMinimized;
    setAppStatusMinimized(newValue);
    setSessionStatusMinimized(newValue);
    setHeaderToolbarMinimized(newValue);
    setHeaderMiscMinimized(newValue);
    setApiDebugMinimized(newValue);
    setLoggingMinimized(newValue);
  };

  const toggleTabsDebugMinimized = () => {
    setTabsDebugMinimized(!tabsDebugMinimized);
  };

  const toggleLoggingMinimized = () => {
    setLoggingMinimized(!loggingMinimized);
  };

  const toggleAppStatusMinimized = () => {
    setAppStatusMinimized(!appStatusMinimized);
  };

  const toggleSessionStatusMinimized = () => {
    setSessionStatusMinimized(!sessionStatusMinimized);
  };

  const toggleAblyStatusMinimized = () => {
    setAblyStatusMinimized(!ablyStatusMinimized);
  };

  const toggleHeaderToolbarMinimized = () => {
    setHeaderToolbarMinimized(!headerToolbarMinimized);
  };

  const toggleHeaderMiscMinimized = () => {
    setHeaderMiscMinimized(!headerMiscMinimized);
  };

  const toggleApiDebugMinimized = () => {
    setApiDebugMinimized(!apiDebugMinimized);
  };

  const toggleWideDebug = () => {
    setWideDebug(!wideDebug);
  };

  const toggleAppError = () => {
    const oldValue = appStatus.appError;
    const appFooter = oldValue ? null : (
      <div>
        <button onClick={handleResetError}>Home</button>
      </div>
    );
    const payload: AppStatusUpdate = {
      appError: !oldValue,
      appErrorMessage: 'Simulated Error',
      appErrorFooter: appFooter,
    };
    dispatch({ type: 'UPDATE_APP_STATUS', payload });
  };

  const toggleAppErrorFatal = () => {
    const oldValue = appStatus.appErrorFatal;
    const appFooter = oldValue ? null : (
      <div>
        <button onClick={handleResetError}>Home</button>
      </div>
    );
    const payload: AppStatusUpdate = {
      appErrorFatal: !oldValue,
      appErrorMessage: 'Simulated Error',
      appErrorFooter: appFooter,
    };
    dispatch({ type: 'UPDATE_APP_STATUS', payload });
  };

  const resetError = () => {
    const payload: AppStatusUpdate = {
      appError: false,
      appErrorFatal: false,
      appErrorMessage: 'Error',
      appErrorFooter: null,
    };
    dispatch({ type: 'UPDATE_APP_STATUS', payload });
  };

  const handleResetError = () => {
    resetError();
    history.push('/administration/user/dashboard/assignments');
  };

  useEffect(() => {
    const chunks = `${location.pathname}`.split('/').filter(Boolean);
    setPathnameChunks(chunks);
  }, [location.pathname]);

  const logConfigToConsole = () => {
    logger.forceLog(config);
    NotificationHelper.clear();
    NotificationHelper.add('Configuration logged to browser console', 'info', 3000);
  };
  const logStoreToConsole = () => {
    logger.forceLog(storeState);
    NotificationHelper.clear();
    NotificationHelper.add('Configuration logged to browser console', 'info', 3000);
  };

  const setDebugVisible = (visible: boolean) => {
    dispatch({ type: 'SET_DEBUG_VISIBLE', payload: visible });
  };

  const hideDebug = () => {
    setDebugVisible(false);
  };

  const toggleAppStatusValue = (valueName: string) => {
    const oldValue = appStatus[valueName as keyof typeof appStatus];
    const payload: AppStatusUpdate = {
      [valueName as keyof AppStatusUpdate]: !oldValue,
    };
    if (`${valueName}` === 'appBusy') {
      payload.appBusyMessage = 'App debug busy';
    }
    dispatch({ type: 'UPDATE_APP_STATUS', payload });
  };

  const toggleUserStatusValue = (userStatusProp: string) => {
    const propName = userStatusProp as keyof typeof user;
    const newUser = {
      ...user,
      roles: user.roles,
      identity: {
        ...user.identity,
      },
      [propName]: !user[propName as keyof typeof user],
    };
    dispatch({ type: 'SET_STAFF_USER', payload: newUser });
  };

  const renderAppStatusItem = (
    appStatusProp: string,
    text: string,
    toggleValue: (appStatusProp: string) => void,
  ) => {
    const active = appStatus[appStatusProp as keyof typeof appStatus] === true;
    return (
      <li
        className={`app-status-toggle${active ? ' app-status-toggle-active' : ''}`}
        key={`prop_${appStatusProp.toLowerCase()}`}
      >
        <button
          className="link-button app-status-toggle-link"
          onClick={(event: React.SyntheticEvent) => {
            toggleValue(appStatusProp);
          }}
        >
          {text || `${appStatusProp}`}
        </button>
      </li>
    );
  };

  const renderUserStatusItem = (
    userStatusProp: string,
    text: string,
    toggleValue: (userStatusProp: string) => void,
  ) => {
    const active = user[userStatusProp as keyof typeof user] === true;
    return (
      <li
        className={`app-status-toggle${active ? ' app-status-toggle-active' : ''}`}
        key={`prop_user_${userStatusProp.toLowerCase()}`}
      >
        <button
          className="link-button app-status-toggle-link"
          onClick={(event: React.SyntheticEvent) => {
            toggleUserStatusValue(userStatusProp);
          }}
        >
          {text || `${userStatusProp}`}
        </button>
      </li>
    );
  };

  const appStatusItems = useMemo<React.ReactNode[]>(() => {
    const items: React.ReactNode[] = [];
    items.push(renderAppStatusItem('headerVisible', 'Header visible', toggleAppStatusValue));
    items.push(renderAppStatusItem('appBusy', 'App Busy', toggleAppStatusValue));
    items.push(renderAppStatusItem('appError', 'App Error', toggleAppError));
    items.push(renderAppStatusItem('appErrorFatal', 'Fatal Error', toggleAppErrorFatal));
    // items.push(renderAppStatusItem('isHttpError', 'HTTP error', toggleAppStatusValue));
    return items;
  }, [appStatus]);

  const userStatusItems = useMemo<React.ReactNode[]>(() => {
    const items: React.ReactNode[] = [];
    items.push(renderUserStatusItem('loggedIn', 'User logged in', toggleUserStatusValue));
    items.push(renderAppStatusItem('loadingUser', 'Loading User', toggleAppStatusValue));
    items.push(renderUserStatusItem('loadComplete', 'User load complete', toggleUserStatusValue));
    return items;
  }, [user.loadComplete, appStatus.loadingUser, user.loggedIn]);

  const handleStatusMenuClose = () => {
    setStatusMenuActive(!statusMenuActive);
  };

  return (
    <Drawer
      className={`app-debug-drawer${wideDebug ? ' app-debug-drawer-wide' : ''}`}
      variant="persistent"
      anchor="right"
      open={appStatus.debugVisible}
      transitionDuration={300}
      SlideProps={{
        direction: 'left',
        timeout: 300,
      }}
    >
      <div className="app-debug-drawer-header">
        <div className="app-debug-drawer-header-section app-debug-drawer-header-left">
          <IconButton
            size="small"
            className="link-button"
            title="Close App Debug"
            onClick={toggleWideDebug}
          >
            <TbLayoutSidebarRightExpand />
          </IconButton>
        </div>
        <div className="app-debug-drawer-header-section app-debug-drawer-header-center">Debug</div>
        <div className="app-debug-drawer-header-section app-debug-drawer-header-right">
          <IconButton
            size="small"
            className="link-button"
            title="Toggle minimzed"
            onClick={toggleAllMinimized}
          >
            {allMinimized && <TbArrowDown />}
            {!allMinimized && <TbArrowUp />}
          </IconButton>
          <IconButton
            size="small"
            className="link-button"
            title="Close App Debug"
            onClick={hideDebug}
          >
            <TbX />
          </IconButton>
        </div>
      </div>
      <div className="app-debug-drawer-middle" ref={drawerMiddleRef}>
        <AppDebugSession
          sessionMinimized={sessionStatusMinimized}
          toggleSessionMinimized={toggleSessionStatusMinimized}
        />

        <AppDebugDrawerSection
          minimized={appStatusMinimized}
          toggleMinimized={toggleAppStatusMinimized}
          title="Status"
        >
          <div className="app-debug-drawer-subsection">
            <div className="app-debug-drawer-subsection-title">
              <HouseIcon className="app-debug-drawer-subsection-title-icon" />
              <h3 className="app-debug-drawer-subsection-title-text">Application</h3>
              <span>
                <IconButton
                  ref={statusMenuAnchorRef}
                  color="primary"
                  size="small"
                  onClick={handleStatusMenuClose}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="debug-app-menu"
                  anchorEl={statusMenuAnchorRef.current}
                  open={statusMenuActive}
                  onClose={handleStatusMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      logStoreToConsole();
                      handleStatusMenuClose();
                    }}
                  >
                    Log redux store
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      logConfigToConsole();
                      handleStatusMenuClose();
                    }}
                  >
                    Log app config
                  </MenuItem>
                </Menu>
              </span>
            </div>
            <div className="app-debug-drawer-row">
              <ul className="app-debug-app-status-control-list">{appStatusItems}</ul>
            </div>
          </div>
          <div className="app-debug-drawer-subsection">
            <div className="app-debug-drawer-subsection-title">
              <AssignmentIndIcon className="app-debug-drawer-subsection-title-icon" />
              <h3 className="app-debug-drawer-subsection-title-text">User</h3>
              <span></span>
            </div>
            <div className="app-debug-drawer-user-info">
              <ul className="app-debug-app-status-control-list">{userStatusItems}</ul>
            </div>
          </div>
        </AppDebugDrawerSection>

        <AppDebugAbly
          sectionMinimized={ablyStatusMinimized}
          toggleSectionMinimized={toggleAblyStatusMinimized}
        />

        <AppDebugDrawerSection
          minimized={headerToolbarMinimized}
          toggleMinimized={toggleHeaderToolbarMinimized}
          title="UI"
        >
          {!headerToolbarMinimized && <AppUIDebugSection />}
        </AppDebugDrawerSection>
        <AppDebugDrawerSection
          minimized={apiDebugMinimized}
          toggleMinimized={toggleApiDebugMinimized}
          title="API"
        >
          {!user.loggedIn && (
            <p className="app-debug-section-unavailable">User is not logged in.</p>
          )}
          {user.loggedIn && !apiDebugMinimized && <AppApiDebugSection />}
        </AppDebugDrawerSection>

        <AppDebugDrawerSection
          minimized={loggingMinimized}
          toggleMinimized={toggleLoggingMinimized}
          title="Logging"
        >
          <AppDebugLogging />
        </AppDebugDrawerSection>

        <AppDebugDrawerSection
          minimized={headerMiscMinimized}
          toggleMinimized={toggleHeaderMiscMinimized}
          title="Inspect"
        >
          <AppDebugInspect />
        </AppDebugDrawerSection>
      </div>
      <div className="app-debug-drawer-footer">
        <div className="app-debug-drawer-footer-row">
          <div className="app-debug-window-id">
            <div>Window ID</div>
            <div>{windowId}</div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AppDebug;
