import React from 'react';

import { useSelector } from 'react-redux';

import { AppState } from '../../../reducers/appDataTypes';

const AppModals: React.FunctionComponent = () => {
  const currentModal = useSelector((state: AppState) => {
    return state.appStatus.currentModal;
  });

  return <React.Fragment>{currentModal}</React.Fragment>;
};

export default AppModals;
