import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosInstance,
  CancelTokenSource,
  AxiosError,
} from 'axios';

import LogHelper from './LogHelper';
import AppHelper from './AppHelper';

import { ApiRequestMethod, ApiRequestInstance } from './ApiHelper.d';
import { Config } from '@elevate-ui/config';

/**
 * Sets up and executes API calls
 */
class BaseApiHelper {
  static loggerNamespace = 'HELPER|ApiHelper';
  activeRequests: ApiRequestInstance[];
  config: Config;

  /**
   * Helper constructor, called with app configuration
   *
   * @param {Config} config configuration object
   * @param {ApiRequestInstance[]} activeRequestsPointer Pointer to external active api request instance objects
   *
   * @returns {undefined} void
   */
  constructor(config: Config, activeRequestsPointer?: ApiRequestInstance[]) {
    this.config = config;
    if (typeof activeRequestsPointer !== 'undefined') {
      this.activeRequests = activeRequestsPointer;
    } else {
      this.activeRequests = [] as ApiRequestInstance[];
    }
  }

  /**
   * Returns url with path to requeested API endpoint
   *
   * @param  {string} apiName      Name of the API (i.e. 'rostering' or 'clever'). Should correspond to existing key under 'api.baseUrl' and 'api.endpoints' from configuration
   * @param  {string} endpointName Name of the endpoint (i.e. 'rostering' or 'clever'). Should correspond to existing key under 'api.endpoints[apiName]' from config
   *
   * @return {string}              Url address of requeested API endpoint
   */
  getEndpointPath(apiName: string, endpointName: string): string {
    const name = apiName as keyof typeof this.config.api.baseUrl;
    const endpointApiName = apiName as keyof typeof this.config.api.endpoints;
    if (
      typeof this.config.api.baseUrl[name as keyof typeof this.config.api.baseUrl] === 'undefined'
    ) {
      return '';
    } else if (
      typeof this.config.api.endpoints[
        endpointApiName as keyof typeof this.config.api.endpoints
      ] === 'undefined'
    ) {
      return '';
    }
    const epBaseUrl = this.config.api.baseUrl[name as keyof typeof this.config.api.baseUrl];
    const apiEndpoints =
      this.config.api.endpoints[endpointApiName as keyof typeof this.config.api.endpoints];
    const epName = endpointName as keyof typeof apiEndpoints;
    if (typeof apiEndpoints[epName as keyof typeof apiEndpoints] === 'undefined') {
      return '';
    }
    const epInfo = apiEndpoints[epName as keyof typeof apiEndpoints];
    const epPath = epInfo['path' as keyof typeof epInfo];

    const url = `${epBaseUrl}${epPath}`;
    return url;
  }

  /**
   * Returns endpoint method (i.e. 'GET', 'POST' etc.)
   *
   * @param  {string} apiName      Name of the API (i.e. 'rostering' or 'clever'). Should correspond to existing key under 'api.baseUrl' and 'api.endpoints' from configuration
   * @param  {string} endpointName Name of the endpoint (i.e. 'rostering' or 'clever'). Should correspond to existing key under 'api.endpoints[apiName]' from configuration
   *
   * @return {string}              Method of API request
   */
  getEndpointMethod(apiName: string, endpointName: string): string {
    const endpointApiName = apiName as keyof typeof this.config.api.endpoints;
    const apiEndpoints =
      this.config.api.endpoints[endpointApiName as keyof typeof this.config.api.endpoints];
    const epName = endpointName as keyof typeof apiEndpoints;
    const epInfo = apiEndpoints[epName as keyof typeof apiEndpoints];
    const epMethod = epInfo['method' as keyof typeof epInfo];
    if (epMethod) {
      return `${epMethod}`;
    }
    return 'GET';
  }

  /**
   * Returns true if API url should interpolate REST params
   *
   * @param  {string} apiName      Name of the API (i.e. 'rostering' or 'clever'). Should correspond to existing key under 'api.baseUrl' and 'api.endpoints' from configuration
   * @param  {string} endpointName Name of the endpoint (i.e. 'rostering' or 'clever'). Should correspond to existing key under 'api.endpoints[apiName]' from configuration
   *
   * @return {boolean}             True if REST, false otherwise
   */
  isRestEndpoint(apiName: string, endpointName: string): boolean {
    const endpointApiName = apiName as keyof typeof this.config.api.endpoints;
    const apiEndpoints =
      this.config.api.endpoints[endpointApiName as keyof typeof this.config.api.endpoints];
    const epName = endpointName as keyof typeof apiEndpoints;
    const epInfo = apiEndpoints[epName as keyof typeof apiEndpoints];
    if (typeof epInfo['rest'] === 'boolean') {
      return !!epInfo['rest'];
    }
    return false;
  }

  /**
   * Prepares query string from argument data
   *
   * Wrapper around URLSearchParams logic that forces array values to be
   * passed properly (i.e. val=1&val=2) instead of default behavior (val=1,2)
   *
   * @param {unknown}     data    Url params name>value object
   * @returns {string}            Encoded query string
   */
  getQueryString(data: unknown): string {
    let queryString = '';
    if (typeof data === 'object') {
      const params = Object.entries({ ...data });
      if (params && params.length) {
        const sp = new URLSearchParams();
        for (let i = 0; i < params.length; i++) {
          const entry = params[i as keyof typeof params];
          if (Array.isArray(entry) && entry.length > 1) {
            if (Array.isArray(entry[1])) {
              for (let j = 0; j < entry[1].length; j++) {
                sp.append(entry[0], `${entry[1][j as keyof (typeof entry)[1]]}`);
              }
            } else {
              sp.append(entry[0], `${entry[1]}`);
            }
          }
        }
        queryString = sp.toString();
      }
    } else if (typeof data === 'string') {
      const sp = new URLSearchParams(data);
      queryString = sp.toString();
    }
    return queryString;
  }

  /**
   * Processes RESTful URL by interpolating request params
   *
   * @param {string}  sourceUrl   Source URL
   * @param {unknown} data        Request params
   *
   * @returns {string}            Processed URL with REST vars replaced
   */
  applyRestfulParams(sourceUrl: string, data = {}): string {
    let url = sourceUrl;
    const dataProps = Object.keys(data).map((val) => `${val}`);
    for (let i = 0; i < dataProps.length; i++) {
      const propName = dataProps[i as keyof typeof dataProps] as keyof typeof data;
      const rgO = RegExp;
      const propStringRegex = new rgO(`{${propName}}`, 'g');
      if (url.match(propStringRegex)) {
        url = url.replace(propStringRegex, data[propName as keyof typeof data]);
        delete data[propName as keyof typeof data];
      }
    }
    return url;
  }

  /**
   * Performs asynchronous API request
   *
   * @param  {string}                     apiName              Name of the API (i.e. 'rostering' or 'clever'). Should correspond to existing key under 'api.baseUrl' from '../../config.json' and 'api.endpoints' from '../../base-config.json'
   * @param  {string}                     endpointName         Name of the endpoint (i.e. 'rostering' or 'clever'). Should correspond to existing key under 'api.endpoints[apiName]' from '../../base-config.json'
   * @param  {unknown}                    data                 Request parameters (optional)
   * @param  {AxiosRequestConfig}         requestSetup         Request setup (optional)
   * @param  {CancelTokenSource|null}     cancelTokenSource    Axios cancel token source (optional)
   * @param  {boolean}                    preventAutoCancel    Controls whether request can be automatically cancelled (optional)
   * @param  {boolean}                    skipAuth             Controls whether request contains authorization headers (optional)
   *
   * @throws {Error}
   *
   * @return {Promise<AxiosResponse>}     Request response
   */
  async apiRequest(
    apiName: string,
    endpointName: string,
    data = {},
    requestSetup: AxiosRequestConfig = {},
    cancelTokenSource: CancelTokenSource | null = null,
    preventAutoCancel = false,
    skipAuth = false,
  ): Promise<AxiosResponse> {
    const endpointPath = this.getEndpointPath(apiName, endpointName);
    if (!endpointPath) {
      throw new Error(`There is no base url for api named "${apiName}".`);
    }
    const endpointMethod = this.getEndpointMethod(apiName, endpointName).toLowerCase();
    let url = endpointPath;
    const restful = this.isRestEndpoint(apiName, endpointName);
    if (restful) {
      url = this.applyRestfulParams(url, data);
      const missedRestParams = url.match(/\{([^}]+)\}/g);
      if (missedRestParams?.length) {
        const message =
          '"' + missedRestParams.map((val) => val.replace(/[{}]/g, '')).join('", "') + '"';
        LogHelper.warning(
          this.config,
          `REST URL for ${apiName} > ${endpointName} is missing ${missedRestParams.length} params: ${message}.`,
          BaseApiHelper.loggerNamespace,
        );
      }
    }
    let result: AxiosResponse = {} as AxiosResponse;
    try {
      LogHelper.debug(
        this.config,
        `Executing API call '${apiName}' > '${endpointName}'.`,
        BaseApiHelper.loggerNamespace,
      );
      result = await this.apiUrlRequest(
        url,
        endpointMethod,
        data,
        requestSetup,
        cancelTokenSource,
        preventAutoCancel,
        skipAuth,
      );
    } catch (exc: unknown) {
      const ex = exc as AxiosError;
      if (ex.message !== this.config.api.cancelExceptionMessage) {
        throw ex;
      }
    }
    return result;
    // return await this.apiUrlRequest(url, endpointMethod, data, requestSetup, cancelTokenSource);
  }

  /**
   * Method to override axios initialization
   * in extended classes
   *
   * @async
   *
   * @param {bool} skipAuth Skip authentication flag
   *
   * @returns {AxiosInstance} Axios instance
   */
  async getAxiosInstance(skipAuth = false): Promise<AxiosInstance> {
    return await this._getAxiosInstance(skipAuth);
  }

  /**
   * Initializes axios instance for API call
   *
   * @async
   *
   * @param {bool} skipAuth Skip authentication flag
   *
   * @returns {AxiosInstance} Axios instance
   */
  async _getAxiosInstance(skipAuth = false): Promise<AxiosInstance> {
    return Promise.resolve(axios.create());
  }

  /**
   * Performs a custom asynchronous API request
   *
   * Takes custom full URL and escapes additional params if method is get
   *
   * @param  {string}                     url                  Url to call (without query string for GET methods)
   * @param  {ApiRequestMethod|string}    endpointMethod       Method to use for  request
   * @param  {unknown}                    data                 Request parameters
   * @param  {AxiosRequestConfig}         requestSetup         Request setup
   * @param  {CancelTokenSource|null}     cancelTokenSource    Axios cancel token source (optional)
   * @param  {boolean}                    preventAutoCancel    Controls whether request can be automatically cancelled (optional)
   * @param  {boolean}                    skipAuth             Controls whether request contains authorization headers (optional)
   *
   * @throws {Error}
   *
   * @return {Promise<AxiosResponse>}     Request response
   */
  async apiUrlRequest(
    url: string,
    endpointMethod: ApiRequestMethod | string = 'GET',
    data = {},
    requestSetup: AxiosRequestConfig = {},
    cancelTokenSource: CancelTokenSource | null = null,
    preventAutoCancel = false,
    skipAuth = false,
  ): Promise<AxiosResponse> {
    return await this._apiUrlRequest(
      url,
      endpointMethod,
      data,
      requestSetup,
      cancelTokenSource,
      preventAutoCancel,
      skipAuth,
    );
  }

  /**
   * Gets active request instances
   *
   * @returns {ApiRequestInstance[]} Requests for this helper
   */
  getApiRequestInstances() {
    return this.activeRequests;
  }

  /**
   * Sets active request instances
   *
   * @param {ApiRequestInstance[]} instances New instances
   *
   * @returns {undefined}
   */
  setApiRequestInstances(instances: ApiRequestInstance[]) {
    this.activeRequests = instances;
  }

  /**
   * Cancels and removes all active request instances
   *
   * @param {boolean} force   Force cancelling requests that are marked not auto-cancellable
   *
   * @returns {undefined}
   */
  removeAllApiRequestEntries(force = false) {
    const instances = this.getApiRequestInstances();
    if (instances?.length) {
      LogHelper.debug(
        this.config,
        `Cleaning up ${instances.length} API requests.`,
        BaseApiHelper.loggerNamespace,
      );
      const ids = instances.map((val) => val.requestId);
      ids.forEach((id) => {
        this.removeApiRequestEntry(id);
      });
    }
  }
  /**
   * Cancels a particular request by its id
   *
   * @param {string}  id      Request id
   * @param {boolean} force   Force cancelling requests that are marked not auto-cancellable
   *
   * @returns {undefined}
   */
  removeApiRequestEntry(id: string, force = false) {
    const instances = this.getApiRequestInstances();
    if (instances?.length) {
      const entryIndex = instances.findIndex((val) => val.requestId === id);
      if (entryIndex >= 0) {
        // const entry = instances.find(val => val.requestId === id);
        const entry = instances[entryIndex as keyof typeof instances] as ApiRequestInstance;
        if (entry && (force || !entry.preventAutoCancel) && entry.cancelTokenSource !== null) {
          entry.cancelTokenSource.cancel(this.config.api.cancelExceptionMessage);
          entry.cancelTokenSource = null;
        }
        instances.splice(entryIndex, 1);
      }
    }
  }

  /**
   * Gets a particular request by its id
   *
   * @param {string} id Request id
   *
   * @returns {ApiRequestInstance|null} Request or null if none
   */
  getApiRequestEntry(id: string): ApiRequestInstance | null {
    const instances = this.getApiRequestInstances();
    if (instances?.length) {
      const entry = instances.find((val) => val.requestId === id);
      if (entry) {
        return entry;
      }
    }
    return null;
  }
  /**
   * Adds an API request instance
   *
   * @param {ApiRequestInstance} entry New request instance
   *
   * @returns {ApiRequestInstance}     Instance from local array
   */
  addApiRequestEntry(entry: ApiRequestInstance): ApiRequestInstance {
    let instances = this.getApiRequestInstances();
    if (typeof instances === 'undefined') {
      instances = [] as ApiRequestInstance[];
    }
    // LogHelper.debug(`Queueing API request ${entry.requestId}.`, 'Helper|ApiHelper', { metaData: { entry }});
    instances.push(entry);
    return instances[instances.length - 1];
  }

  /**
   * Gets authorize header value for API calls
   *
   * @returns {string} Value for 'Authorize' header
   */
  getAuthorizeHeaderValue(): string {
    // if (AppHelper.getCookieValue(config.session.student.idTokenCookie)) {
    //   return AppHelper.getCookieValue(config.session.student.idTokenCookie);
    // } else if (AppHelper.getCookieValue(config.session.staff.idTokenCookie)) {
    //   return AppHelper.getCookieValue(config.session.staff.idTokenCookie);
    // }
    return '';
  }

  /**
   * Performs a custom asynchronous API request
   *
   * Takes custom full URL and escapes additional params if method is get
   *
   * @param  {string}                     url                  Url to call (without query string for GET methods)
   * @param  {ApiRequestMethod|string}    endpointMethod       Method to use for  request
   * @param  {unknown}                    data                 Request parameters
   * @param  {AxiosRequestConfig}         requestSetup         Request setup
   * @param  {CancelTokenSource|null}     cancelTokenSource    Axios cancel token source (optional)
   * @param  {boolean}                    preventAutoCancel    Controls whether request can be automatically cancelled (optional)
   * @param  {boolean}                    skipAuth             Controls whether request contains authorization headers (optional)
   *
   * @throws {Error}
   *
   * @return {Promise<AxiosResponse>}     Request response
   */
  async _apiUrlRequest(
    url: string,
    endpointMethod: ApiRequestMethod | string = 'GET',
    data = {},
    requestSetup: AxiosRequestConfig = {},
    cancelTokenSource: CancelTokenSource | null = null,
    preventAutoCancel = false,
    skipAuth = false,
  ): Promise<AxiosResponse> {
    const requestId = AppHelper.getUUID();
    const cts: CancelTokenSource =
      cancelTokenSource !== null ? cancelTokenSource : axios.CancelToken.source();
    const epMethod: string = `${endpointMethod}`.toLowerCase();
    const instance: AxiosInstance = await this.getAxiosInstance(skipAuth);
    if (epMethod === 'get') {
      const queryString = this.getQueryString(data);
      if (queryString) {
        url += `?${queryString}`;
      }
    }
    const method: ApiRequestMethod = epMethod.toUpperCase() as ApiRequestMethod;

    const idToken = this.getAuthorizeHeaderValue();
    const requestConfig: AxiosRequestConfig = {
      ...this.config.api.defaultRequestSetup,
      ...requestSetup,
      headers: {
        ...{},
        ...this.config.api.defaultRequestSetup.headers,
        ...requestSetup?.headers,
      },
      url,
      method,
    };
    if (cts && cts.token) {
      requestConfig.cancelToken = cts.token;
    }

    if (epMethod !== 'get') {
      requestConfig.data = data;
    }

    if (
      !skipAuth &&
      idToken &&
      idToken.length > 0 &&
      typeof requestConfig.headers.Authorization === 'undefined'
    ) {
      requestConfig.headers.Authorization = idToken;
    }

    const apiRequest = this.addApiRequestEntry({
      requestId,
      cancelTokenSource: cts,
      skipAuth,
      preventAutoCancel,
      started: false,
      finished: false,
      response: null,
      requestConfig,
    });

    return new Promise((resolve, reject) => {
      LogHelper.debug(this.config, `API calling "${url}"...`, BaseApiHelper.loggerNamespace);
      apiRequest.started = true;
      instance
        .request(requestConfig)
        .then((response) => {
          // LogHelper.debug(`Got ${response.status} from API call to "${url}".`, BaseApiHelper.loggerNamespace);
          apiRequest.response = response;
          apiRequest.finished = true;
          resolve(response);
        })
        .catch((exc: unknown) => {
          const ex = exc as Error;
          if (ex.message !== this.config.api.cancelExceptionMessage) {
            LogHelper.warning(
              this.config,
              `API call failed -  "${ex.message}", url "${url}".`,
              BaseApiHelper.loggerNamespace,
            );
            apiRequest.finished = true;
            reject(exc);
          }
        })
        .finally(() => {
          this.removeApiRequestEntry(requestId);
        });
    });
  }
}

export default BaseApiHelper;
