import React from 'react';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ElevateTableHeaderCell from './ElevateTableHeaderCell';

import { ElevateTableColumn, ElevateTableProps, ElevateTableRecord } from './ElevateTable.d';

const ElevateTableHead = <T extends ElevateTableRecord>(
  props: ElevateTableProps<T>,
): React.ReactElement | null => {
  const renderHeaderCell = (cellSetup: ElevateTableColumn<T>, columnIndex: number) => {
    return (
      <ElevateTableHeaderCell
        key={`eth_${columnIndex}`}
        tableData={props.tableData}
        columnIndex={columnIndex}
        column={cellSetup}
        onChange={props?.onChange}
      />
    );
  };

  return (
    <TableHead role="rowgroup">
      <TableRow role="row">
        {props.tableData.columns.map((cell, index) => {
          return renderHeaderCell(cell, index);
        })}
      </TableRow>
    </TableHead>
  );
};

export default ElevateTableHead;
