import React, { Suspense, lazy, useEffect } from 'react';

import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { AppLoader } from '@riversideinsights/elevate-react-lib';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import AppHelper from '../../../lib/helper/AppHelper';

import useAppLogger from '../../../hooks/useAppLogger';
import useStaffUser from '../../../hooks/useStaffUser';
import useAppStatus from '../../../hooks/useAppStatus';

import AppNotificationList from '../AppNotification/AppNotificationList';
import AppHeader from '../AppHeader/AppHeader';
import AppDebug from '../AppDebug/AppDebug';
import AppModals from '../AppModals/AppModals';

import SectionError from '../AppSection/SectionError';
import SectionLoader from '../AppSection/SectionLoader';

import ErrorPage from '../../../pages/ErrorPage';
import CheckSessionPage from '../../../pages/administration/CheckSessionPage';
import useWrapperClasses from '../../../hooks/useWrapperClasses';

const CleverPage = lazy(() => import('../../../pages/administration/CleverPage'));
const UserPage = lazy(() => import('../../../pages/administration/UserPage'));
const RosteringPage = lazy(() => import('../../../pages/administration/RosteringPage'));
const StudentPage = lazy(() => import('../../../pages/administration/StudentPage'));
const AuthPage = lazy(() => import('../../../pages/administration/AuthPage'));

const AppSectionAdmin: React.FunctionComponent = () => {
  const config = useConfigContext();
  // Note that this should be the ONLY call to useStaffUser and it should be invoked only once per (async) page load.
  // Page components should use useCachedUser instead.
  const user = useStaffUser();

  const logger = useAppLogger('COMPONENT|AppSectionAdmin');
  const debugAllowed = AppHelper.getCookieValue('elevateDebugEnabled') ? true : config.local;

  // const location = useLocation();
  const appStatus = useAppStatus();

  useEffect(() => {
    logger.debug('Mounting admin section');
    return () => {
      logger.debug('Unmounting admin section');
    };
  }, []);

  const wrapperClasses = useWrapperClasses('app-section-administration');

  return (
    <div className={wrapperClasses}>
      <AppModals />
      <div className={`app-layout-root ${appStatus.pageClassName}`}>
        <AppHeader />
        <Suspense fallback={<AppLoader title="Please Wait" message="Initializing" />}>
          <Switch>
            <Redirect
              exact
              from="/administration"
              to="/administration/user/dashboard/assignments"
              push={false}
            />
            {/* Most auth pages don't depend on user session
             * so they can be rendered immediately
             */}
            <Route path="/administration/auth" component={AuthPage} />

            {/* Routes that call API or rely on user data upon render
             * need to make sure that user is fully loaded before
             * calling any API call that requires authorization.
             */}
            <Route
              path="/administration/check-session"
              render={(routeProps: RouteComponentProps) => {
                if (!(user.loggedIn && user.loadComplete)) {
                  return null;
                } else {
                  return <CheckSessionPage {...routeProps} />;
                }
              }}
            />
            <Route
              path="/administration/user"
              render={(routeProps: RouteComponentProps) => {
                if (!user.loggedIn) {
                  return null;
                } else {
                  return <UserPage {...routeProps} />;
                }
              }}
            />
            <Route
              path="/administration/rostering"
              render={(routeProps: RouteComponentProps) => {
                if (!(user.loggedIn && user.loadComplete)) {
                  return null;
                } else {
                  return <RosteringPage />;
                }
              }}
            />
            <Route
              path="/administration/student"
              render={(routeProps: RouteComponentProps) => {
                if (!(user.loggedIn && user.loadComplete)) {
                  return null;
                } else {
                  return <StudentPage {...routeProps} />;
                }
              }}
            />
            <Route
              path="/administration/clever/:offsetParam?/:limitParam?/:sortPropParam?/:sortOrderParam?"
              render={(routeProps: RouteComponentProps) => {
                if (!(user.loggedIn && user.loadComplete)) {
                  return null;
                } else {
                  return <CleverPage {...routeProps} />;
                }
              }}
            />

            <Route render={() => <ErrorPage httpErrorCode={404} />} />
          </Switch>
        </Suspense>
        {/**
         * Non fatal (recoverable) and fatal (non-recoverable) app errors are rendered if needed
         * Fatal errors overlap header and page, while non-fatal ones don't affect the header.
         */}
        {(appStatus.appError || appStatus.appErrorFatal) && <SectionError />}
        {!(appStatus.appError || appStatus.appErrorFatal) &&
          (appStatus.loadingUser || appStatus.appBusy) && <SectionLoader />}
      </div>
      <AppNotificationList />
      {debugAllowed && <AppDebug />}
    </div>
  );
};

export default AppSectionAdmin;
