import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';

import useAppLogger from '../../../../hooks/useAppLogger';
import useCachedUser from '../../../../hooks/useCachedUser';
import useApiHelper from '../../../../hooks/useApiHelper';

import { ReactComponent as IconEdit } from '../../../shared/CustomTable/tableIcons/edit.svg';
import { ReactComponent as IconDelete } from '../../../shared/CustomTable/tableIcons/delete.svg';
import { ReactComponent as IconPrint } from '../../../shared/CustomTable/tableIcons/print.svg';
import { ReactComponent as ArrowRight } from '../../../shared/CustomTable/tableIcons/arrow-right.svg';
import { ReactComponent as IconReopen } from '../../../shared/CustomTable/tableIcons/reopen.svg';

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import { ModalDialog } from '@riversideinsights/elevate-react-lib';

import ElevateTable from '../../../shared/ElevateTable/ElevateTable';
import {
  ElevateTableData,
  ElevateTableStatus,
  ElevateTableColumn,
} from '../../../shared/ElevateTable/ElevateTable.d';

import SpinnerIcon from '../../../shared/icons/LoaderSpinIcon';

import './TestAssignmentListTable.css';

import Dropdown, { IDropdownOption } from '../../Dropdown/Dropdown';

import { AssignmentListItem } from '../../../TestAssignment/TestAssignment.d';

import renderDatesCell from './TableHelpers/renderDatesCell';
import renderStatusCell from './TableHelpers/renderStatusCell';
import renderGradesCell from './TableHelpers/renderGradesCell';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import useNotificationHelper from '../../../../hooks/useNotificationHelper';
import { BooleanFlags, useBooleanFlagWithConfig } from '../../../../flags';

export type AssignmentListTableData = ElevateTableData<AssignmentListItem>;
export type AssignmentListTableColumn = ElevateTableColumn<AssignmentListItem>;

export interface ReopenAssignmentData {
  assignmentId: number;
  assignmentName: string;
  modifiedById: string;
  modifiedByName: string;
}

export interface TestAssignmentListTableProps {
  filterYear: string;
  setTestAssignmentListCount: React.Dispatch<React.SetStateAction<string>>;
  setTestAssignmentTrashCount: React.Dispatch<React.SetStateAction<string>>;
}

const TestAssignmentListTable: React.FC<TestAssignmentListTableProps> = (props) => {
  const config = useConfigContext();
  const ApiHelper = useApiHelper('Component|TestAssignmentListTable');
  const NotificationHelper = useNotificationHelper();
  const user = useCachedUser();
  const dispatch = useDispatch();
  const logger = useAppLogger('COMPONENT|TestAssignmentListTable');

  const tableLoadCancelToken = useRef<CancelTokenSource | null>(null);

  const headerWrappperRef = useRef<HTMLDivElement | null>(null);

  const [deleteAssignmentId, setDeleteAssignmentId] = useState<number | AssignmentListItem>(0);

  const [typeFilterValues, setTypeFilterValues] = useState<string[]>([]);
  const [assessmentTypeFilterOptions, setAssessmentTypeFilterOptions] = useState<IDropdownOption[]>(
    [
      { label: 'IowaFlex Math', value: 'IowaFlex Math', selected: false },
      { label: 'IowaFlex Reading', value: 'IowaFlex Reading', selected: false },
      { label: 'CogAT Complete Form 7', value: 'CogAT Complete Form 7', selected: false },
      { label: 'CogAT Complete Form 8', value: 'CogAT Complete Form 8', selected: false },
      { label: 'CogAT Screening Form 7', value: 'CogAT Screening Form 7', selected: false },
      { label: 'CogAT Screening Form 8', value: 'CogAT Screening Form 8', selected: false },
      { label: 'CogAT Post-Screener Form 7', value: 'CogAT Post-Screener Form 7', selected: false },
      { label: 'CogAT Post-Screener Form 8', value: 'CogAT Post-Screener Form 8', selected: false },
      {
        label: 'CogAT Screening & Post-Screener Form 7',
        value: 'CogAT Screening & Post-Screener Form 7',
        selected: false,
      },
      {
        label: 'CogAT Screening & Post-Screener Form 8',
        value: 'CogAT Screening & Post-Screener Form 8',
        selected: false,
      },
    ],
  );

  const [statusFilterValues, setStatusFilterValues] = useState<string[]>([]);
  const [statusFilterOptions, setStatusFilterOptions] = useState<IDropdownOption[]>([
    { label: 'Open', value: 'Open', selected: false },
    { label: 'Closed', value: 'Closed', selected: false },
    { label: 'Scheduled', value: 'Scheduled', selected: false },
  ]);

  const [openAssignmentReopenModal, setOpenAssignmentReopenModal] = useState(false);
  const [reopeningAssignment, setReopeningAssignment] = useState(false);
  const [reopenAssignmentData, setReopenAssignmentData] = useState<ReopenAssignmentData | null>(
    null,
  );
  const [userGrades, setUserGrades] = useState<string[]>([]);

  const isPermissionCRUDAssignments = useMemo<boolean>(() => {
    if (user.role !== 'district_admin') return false; //TODO temporary disabled permission for SA and teachers
    return !!user.currentPermissions.includes(72); //permissionName: 'TA-CRUDAssignments'
  }, [user.role, user.currentPermissions]);

  const { isEnabled: testingIsDisabled, isReady: disableTestingIsReady } = useBooleanFlagWithConfig(
    BooleanFlags.DisableTesting,
  );

  const renderNameCell = (item: AssignmentListItem, column: AssignmentListTableColumn) => {
    const disabledActions = false;
    return (
      <RouterLink
        tabIndex={disabledActions ? -1 : 0}
        className={`assignment-table-item-name-link${disabledActions ? ' disabled-link' : ''}`}
        to={`/assignment/edit/${item.assignmentid}`}
      >
        {item.assignmentname}
        <span className="assignment-table-item-name-arrow">
          <ArrowRight />
        </span>
      </RouterLink>
    );
  };

  const renderActionsCell = (item: AssignmentListItem, column: AssignmentListTableColumn) => {
    if (item.status === 'Closed') {
      if (user.role === 'district_admin' || isPermissionCRUDAssignments) {
        return (
          <span className="assignment-actions">
            <Tooltip title="Trash" arrow={true} placement="bottom">
              <span
                className={`assignment-action${
                  item.is_deleteable &&
                  (isPermissionCRUDAssignments || user.role === 'district_admin')
                    ? ''
                    : ' disabled'
                }`}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    if (
                      item.is_deleteable &&
                      (isPermissionCRUDAssignments || user.role === 'district_admin')
                    ) {
                      handleDeleteAssignment(item);
                    }
                  }}
                >
                  <IconDelete />
                </IconButton>
              </span>
            </Tooltip>
            {!testingIsDisabled && (
              <Tooltip title="Reopen" arrow={true} placement="left">
                <IconButton
                  aria-label="reopen"
                  className="assignment-action-button"
                  disableRipple
                  onClick={() => {
                    setReopenAssignmentData({
                      assignmentId: item.assignmentid,
                      assignmentName: item.assignmentname,
                      modifiedById: user.userId,
                      modifiedByName: user.name,
                    });
                    setOpenAssignmentReopenModal(true);
                  }}
                  size="small"
                >
                  <IconReopen />
                </IconButton>
              </Tooltip>
            )}
          </span>
        );
      } else {
        return <React.Fragment />;
      }
    }

    return (
      <span className="assignment-actions">
        {(user.role === 'district_admin' || isPermissionCRUDAssignments) && (
          <React.Fragment>
            <Tooltip title="Edit" arrow={true} placement="left">
              <RouterLink
                className="assignment-action"
                to={`/assignment/edit/${item.assignmentid}`}
              >
                <IconEdit />
              </RouterLink>
            </Tooltip>

            <Tooltip title="Trash" arrow={true} placement="bottom">
              <span
                className={`assignment-action${
                  item.is_deleteable &&
                  (isPermissionCRUDAssignments || user.role === 'district_admin')
                    ? ''
                    : ' disabled'
                }`}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    if (
                      item.is_deleteable &&
                      (isPermissionCRUDAssignments || user.role === 'district_admin')
                    ) {
                      handleDeleteAssignment(item);
                    }
                  }}
                >
                  <IconDelete />
                </IconButton>
              </span>
            </Tooltip>
          </React.Fragment>
        )}

        <Tooltip title="Print Test Taker Tickets" arrow={true} placement="bottom">
          <RouterLink
            className="assignment-action"
            to={{
              pathname: `/assignment/print/${item.assignmentid}`,
            }}
          >
            <IconPrint />
          </RouterLink>
        </Tooltip>
      </span>
    );
  };

  const handleTypeFilterChange = (opts: IDropdownOption[]) => {
    if (opts?.length) {
      setAssessmentTypeFilterOptions(opts);
    }
    const assessmentTypes: string[] = opts.filter((opt) => opt.selected).map((opt) => opt.value);
    setTypeFilterValues(assessmentTypes);
  };

  const handleStatusFilterChange = (opts: IDropdownOption[]) => {
    if (opts?.length) {
      setStatusFilterOptions(opts);
    }
    const statuses: string[] = opts.filter((opt) => opt.selected).map((opt) => opt.value);
    setStatusFilterValues(statuses);
  };

  const initialTableData: AssignmentListTableData = {
    status: {
      loading: true,
      errorTitle: 'Error',
      errorMessage: '',
      sortProp: 'startdate',
      sortDirection: 'desc',
      offset: 0,
      limit: 25,
      totalRecords: 0,
    },
    options: {
      rowsPerPageValues: [5, 10, 25, 50],
      loadingTitle: props?.filterYear === '' ? 'Loading School Years' : 'Loading Assignments',
      loadingMessage: <SpinnerIcon />,
    },
    tableProps: {
      stickyHeader: true,
    },
    columns: [
      {
        propName: 'assignmenttype',
        propLabel: 'Assessment Type',
        sortable: true,
        filter: true,
        renderFilter: (column: AssignmentListTableColumn) => {
          return (
            <Dropdown
              options={assessmentTypeFilterOptions}
              isOpen={true}
              onChange={handleTypeFilterChange}
              isMultiselect={true}
              showSelectedOptionsHeaderTooltip={true}
              classSelectedOptionsHeaderTooltip="top-aligned"
            />
          );
        },
        attributes: {
          className: 'column-assignmenttype',
        },
      },
      {
        propName: 'assignmentname',
        propLabel: 'Assignment Name',
        sortable: true,
        filter: false,
        attributes: {
          className: 'column-assignmentname',
        },
        renderValue: renderNameCell,
      },
      {
        propName: 'gradedomains',
        propLabel: 'Grades',
        sortable: true,
        filter: false,
        attributes: {
          className: 'column-gradedomains',
        },
        renderValue: renderGradesCell,
      },
      {
        propName: 'creator',
        propLabel: 'Assignment Creator',
        sortable: true,
        filter: false,
        attributes: {
          className: 'column-creator',
        },
      },
      {
        propName: 'startdate',
        propLabel: 'Test Dates',
        sortable: true,
        filter: false,
        attributes: {
          className: 'column-dates',
        },
        renderValue: renderDatesCell,
      },
      {
        propName: 'status',
        propLabel: 'Status',
        sortable: true,
        filter: true,
        attributes: {
          className: 'column-status',
        },
        renderValue: renderStatusCell,
        renderFilter: (column: AssignmentListTableColumn) => {
          return (
            <Dropdown
              options={statusFilterOptions}
              isOpen={true}
              onChange={handleStatusFilterChange}
              isMultiselect={true}
              showSelectedOptionsHeaderTooltip={true}
              classSelectedOptionsHeaderTooltip="top-aligned"
            />
          );
        },
      },
      {
        propName: 'is_editable',
        propLabel: 'Actions',
        sortable: false,
        filter: false,
        attributes: {
          className: 'column-actions',
        },
        renderValue: renderActionsCell,
      },
    ],
    items: null,
  };

  const [tableData, setTableData] = useState<AssignmentListTableData>(initialTableData);

  // Needed as path of least resistance to reset the table data when the flag ready.
  useEffect(() => {
    if (disableTestingIsReady) {
      setTableData(initialTableData);
    }
  }, [disableTestingIsReady]);

  useEffect(() => {
    if (user.role && userGrades.length === 0) {
      getDefaultGrades();
    }
  }, [user.role]);

  useEffect(() => {
    if (
      typeof props.filterYear === 'undefined' ||
      (typeof props.filterYear !== 'undefined' && props.filterYear !== '')
    ) {
      loadTableData();
    }
  }, [
    tableData.status.offset,
    tableData.status.limit,
    tableData.status.sortProp,
    tableData.status.sortDirection,
    typeFilterValues,
    statusFilterValues,
    props?.filterYear,
    userGrades,
  ]);

  const deleteAssignment = async (assignment: AssignmentListItem) => {
    setTableLoading(true);
    setDeleteAssignmentId(0);
    try {
      const result = await ApiHelper.apiRequest('assignmentApiUrl', 'UpdateAssignmentStatus', {
        assignmentId: assignment.assignmentid,
        assignmentName: assignment.assignmentname,
        status: 'Trash',
        modifiedById: user.userId,
        modifiedByName: user.name,
      });
      if (!(result && result.data)) {
        NotificationHelper.add('Error trashing assignment', 'error');
        setTableLoading(false);
      } else {
        loadTableData();
      }
    } catch (exc: unknown) {
      const ex = exc as Error;
      logger.error(ex);
      NotificationHelper.add('Error trashing assignment', 'error');
      setTableLoading(false);
    }
  };

  useEffect(() => {
    if (typeof deleteAssignmentId !== 'number') {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="delete-assignment-modal"
            focusSelector=".default-modal-button"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            title={
              <div className="delete-assignment-message">
                Move {deleteAssignmentId.assignmentname} to trash?
              </div>
            }
            body={'The test assignment will be permanently deleted in 30 days.'}
            footer={
              <div className="button-wrapper">
                <Button
                  variant="outlined"
                  className="default-modal-button"
                  onClick={() => {
                    deleteAssignment(deleteAssignmentId);
                  }}
                >
                  Yes, move to trash
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setDeleteAssignmentId(0);
                  }}
                >
                  No, do not move to trash
                </Button>
              </div>
            }
            onClose={() => {
              setDeleteAssignmentId(0);
            }}
          />
        ),
      });
    } else {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    }
  }, [deleteAssignmentId]);

  const sortGrades = (a: string, b: string) => {
    if (a === 'K') return -1;
    if (b === 'K') return 1;
    if (Number(a) > Number(b)) return 1;
    if (Number(b) > Number(a)) return -1;
    return 0;
  };

  const setTableLoading = (loading = false) => {
    const newTd = {
      ...tableData,
      status: {
        ...tableData.status,
        loading,
        errorMessage: '',
      },
    };
    setTableData(newTd);
  };

  const getDefaultGrades = async () => {
    logger.info("Loading user's grades.");
    try {
      const apiRequestParams = {
        customer_id: user.customerId,
        user_id: user.userId,
        user_role: user.role,
      };
      const result = await ApiHelper.apiRequest('rostering', 'getGradesOfUser', apiRequestParams);
      if (result && result.status === 200) {
        setUserGrades(result.data.grades);
      }
      logger.info("User's grades loaded.");
    } catch (ex: unknown) {
      const err = ex as AxiosError;
      logger.error(`Failed loading user's grades - ${err.message}.`);
    }
  };

  const loadTableData = async () => {
    const currentDate = new Date();
    let currentYear =
      currentDate.getMonth() < 6
        ? `${currentDate.getFullYear() - 1}`
        : `${currentDate.getFullYear()}`;
    if (props?.filterYear && props.filterYear !== '') {
      currentYear = props.filterYear;
    }

    const schoolYear = currentYear + '-' + (Number(currentYear) + 1);
    const sortingDirection = `${tableData.status.sortDirection}`.toUpperCase();
    const sortingField = `${tableData.status.sortProp}`;
    const pagesize = `${tableData.status.limit}`;
    const pagenumber =
      tableData.status.offset > 0
        ? `${1 + Math.ceil(tableData.status.offset / tableData.status.limit)}`
        : '1';

    const res = {
      ...tableData,
      status: {
        ...tableData.status,
        loading: true,
        errorMessage: '',
      },
      options: {
        ...tableData.options,
        loadingTitle: `Loading ${schoolYear} Assignments`,
      },
    };
    setTableData(res);
    if (tableLoadCancelToken.current !== null) {
      tableLoadCancelToken.current.cancel(config.api.cancelExceptionMessage);
      tableLoadCancelToken.current = null;
    }

    const apiRequestParams = {
      customerId: user.customerId,
      userRole: user.role,
      schoolYear: schoolYear,
      assessmentType: typeFilterValues,
      status: statusFilterValues,
      pagenumber,
      pagesize,
      sortingField,
      sortingDirection,
      grades: userGrades,
    };

    try {
      tableLoadCancelToken.current = axios.CancelToken.source();
      const result = await ApiHelper.apiRequest(
        'assignmentApiUrl',
        'ViewAssignmentsByGrade',
        apiRequestParams,
        {},
        tableLoadCancelToken.current,
      );
      tableLoadCancelToken.current = null;
      if (result && result.data) {
        const assignmentTrashCount =
          result.data.trashCount > 99 ? '99+' : result.data.trashCount.toString();
        const assignmentViewCount =
          result.data.totalCount > 99 ? '99+' : result.data.totalCount.toString();
        props.setTestAssignmentTrashCount(assignmentTrashCount);
        props.setTestAssignmentListCount(assignmentViewCount);
        let resultAssignments: AssignmentListItem[] = [];
        if (result.data?.assignments && Array.isArray(result.data.assignments)) {
          resultAssignments = result.data.assignments.map((item: unknown) => {
            const entry = item as AssignmentListItem;
            let gradeDomains: string[] = [];
            if (typeof entry.gradedomains === 'string' && entry.gradedomains) {
              try {
                gradeDomains = JSON.parse(entry.gradedomains);
              } catch (ex) {
                gradeDomains = [];
              }
            }
            gradeDomains.sort(sortGrades);
            entry.gradedomains = gradeDomains;
            entry.dates = {
              startdate: entry?.startdate ? entry.startdate : '',
              enddate: entry?.enddate ? entry.enddate : '',
              startdatePostScreener: entry?.psstartdate ? entry.psstartdate : '',
              enddatePostScreener: entry?.psenddate ? entry?.psenddate : '',
            };
            return entry;
          });
        }

        const items = resultAssignments;
        const totalRecords = result?.data?.totalCount ? result.data.totalCount : 0;
        const td = {
          ...tableData,
          status: {
            ...tableData.status,
            totalRecords,
            loading: false,
          },
          items,
        };
        setTableData(td);

        logger.debug(
          `Done loading ViewAssignments, ${resultAssignments?.length || '0'} records returned`,
        );
      } else {
        logger.error('Error loading ViewAssignments, no data returned');
      }
    } catch (ex: unknown) {
      tableLoadCancelToken.current = null;
      const err = ex as Error;
      if (err.message !== config.api.cancelExceptionMessage) {
        logger.error(err.message);
        const newData: ElevateTableData<AssignmentListItem> = {
          ...tableData,
          status: {
            ...tableData.status,
            errorMessage: 'Could not load assignments.',
            loading: false,
          },
          items: null,
        };
        setTableData(newData);
      }
    }
  };

  const handleTableChange = (tableStatusUpdates: ElevateTableStatus) => {
    if (tableData !== null) {
      const newData: ElevateTableData<AssignmentListItem> = {
        ...tableData,
        status: {
          ...tableData.status,
          ...tableStatusUpdates,
        },
      };
      setTableData(newData);
    }
  };

  const handleDeleteAssignment = (assignmentId: AssignmentListItem) => {
    setDeleteAssignmentId(assignmentId);
  };

  useEffect(() => {
    if (!openAssignmentReopenModal) {
      dispatch({ type: 'SET_CURRENT_MODAL', payload: null });
    } else if (!reopeningAssignment) {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="delete-assignment-modal"
            focusSelector=".default-modal-button"
            disableClose={false}
            disableCloseOnEsc={false}
            closeOnMaskClick={true}
            body={<div className="delete-assignment-message">Reopen this test assignment?</div>}
            footer={
              <div className="button-wrapper">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenAssignmentReopenModal(false);
                  }}
                >
                  No - do not reopen
                </Button>
                <Button
                  className="default-modal-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (reopenAssignmentData) {
                      reopenTestAssignment(
                        reopenAssignmentData.assignmentId,
                        reopenAssignmentData.assignmentName,
                        reopenAssignmentData.modifiedById,
                        reopenAssignmentData.modifiedByName,
                      );
                    } else {
                      NotificationHelper.add('Error reopening test assignment.', 'warning'); // 5000, GP1-11848
                      setOpenAssignmentReopenModal(false);
                      setReopeningAssignment(false);
                    }
                  }}
                >
                  Yes - reopen
                </Button>
              </div>
            }
            onClose={() => {
              setOpenAssignmentReopenModal(false);
            }}
          />
        ),
      });
    } else {
      dispatch({
        type: 'SET_CURRENT_MODAL',
        payload: (
          <ModalDialog
            className="deleting-assignment-modal modal-loader"
            disableClose={true}
            disableCloseOnEsc={true}
            closeOnMaskClick={false}
            body={
              <div className="modal-loader-contents">
                <div className="modal-loader-title">Please Wait</div>
                <div className="modal-loader-spinner">
                  <SpinnerIcon />
                </div>
                <div className="modal-loader-message">Reopening Assignment</div>
              </div>
            }
            footer={null}
            onClose={() => {
              setOpenAssignmentReopenModal(false);
            }}
          />
        ),
      });
    }
  }, [openAssignmentReopenModal, reopeningAssignment]);

  const reopenTestAssignment = async (
    assignmentId: number,
    assignmentName: string,
    modifiedById: string,
    modifiedByName: string,
  ) => {
    try {
      setReopeningAssignment(true);
      const result = await ApiHelper.apiRequest('assignmentApiUrl', 'UpdateAssignmentStatus', {
        assignmentId: assignmentId,
        assignmentName: assignmentName,
        status: 'Reopen',
        modifiedById: modifiedById,
        modifiedByName: modifiedByName,
      });
      setOpenAssignmentReopenModal(false);
      setReopeningAssignment(false);
      if (result && typeof result.data === 'boolean' && result.data === true) {
        setTableLoading(true);
        Promise.resolve(loadTableData());
        setTableLoading(false);
      } else {
        NotificationHelper.add('Test assignment failed to reopen.', 'warning'); // 5000, GP1-11848
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      logger.error(err);
      NotificationHelper.add('Error reopening test assignment.', 'warning'); // 5000, GP1-11848
      setOpenAssignmentReopenModal(false);
      setReopeningAssignment(false);
    }
  };

  return (
    <div className="test-assignment-list-table elevate-table" ref={headerWrappperRef}>
      <ElevateTable tableData={tableData} onChange={handleTableChange} />
    </div>
  );
};

export default TestAssignmentListTable;
