import { browserName } from 'react-device-detect';

const usePlatform = () => {
  const disallowedBrowsers = ['Firefox', 'Opera'];
  const supported = !disallowedBrowsers.includes(browserName);

  return { supported } as const;
};

export default usePlatform;
