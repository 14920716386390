import React from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonGroup } from '@mui/material';

import { useConfigContext } from '@components/ConfigLoader/ConfigLoader';
import { AppState } from '../../../reducers/appDataTypes';

import UserHelper from '../../../lib/helper/UserHelper';
import StudentUserHelper from '../../../lib/helper/StudentUserHelper';
import AppHelper from '../../../lib/helper/AppHelper';

import useNotificationHelper from '../../../hooks/useNotificationHelper';
import useAppLogger from '../../../hooks/useAppLogger';
import useApiHelper from '../../../hooks/useApiHelper';

export interface CookieMap {
  [key: string]: string;
}

const AppDebugInspect: React.FC = () => {
  const config = useConfigContext();
  const logger = useAppLogger('COMPONENT|AppDebugInspect');
  const apiHelper = useApiHelper('COMPONENT|AppDebugInspect');
  const NotificationHelper = useNotificationHelper();

  const storeState = useSelector((state: AppState) => {
    return state;
  });
  const user = useSelector((state: AppState) => {
    return state.auth.staffAuth.user;
  });

  const parseCookieMap = (): CookieMap => {
    const newCookieMap = document.cookie.split(';').reduce((acc, val) => {
      const valChunks = val.split('=');
      // acc[`${valChunks[0]}`] = AppHelper.getCookieValue(valChunks[0]);
      acc[`${valChunks[0]}`] = decodeURIComponent(`${valChunks[1]}`);
      return acc;
    }, {} as CookieMap);
    return newCookieMap;
  };

  const logObjectToConsole = (intro: string, data: unknown, notificationText?: string) => {
    if (intro !== '') {
      logger.forceLog(intro, { metaData: data });
    } else {
      logger.forceLog(data);
    }
    if (typeof notificationText !== 'undefined' && notificationText.length > 0) {
      NotificationHelper.clear();
      NotificationHelper.add(notificationText, 'info', 3000);
    }
  };

  const logCognitoSession = async () => {
    const session = await UserHelper.getCognitoSession(config);
    logObjectToConsole('Cognito session: ', session, 'Cognito session logged to browser console');
  };
  const logCognitoUser = () => {
    logObjectToConsole(
      'Cognito User:',
      UserHelper.getCognitoUser(config),
      'Cognito user logged to browser console',
    );
  };
  const logConfigToConsole = () => {
    logObjectToConsole(
      'Application configuration:',
      config,
      'Configuration logged to browser console',
    );
  };
  const logStoreToConsole = () => {
    logObjectToConsole('Redux Store State:', storeState, 'Configuration logged to browser console');
  };
  const logStaffUser = () => {
    logObjectToConsole(
      'Staff User Object:',
      storeState.auth.staffAuth.user,
      'Staff user logged to browser console',
    );
  };
  const logAppStatus = () => {
    logObjectToConsole(
      'Application Status:',
      storeState.appStatus,
      'App status logged to browser console',
    );
  };
  const logTokenData = () => {
    logObjectToConsole(
      'Cognito ID Token Data:',
      UserHelper.parseStaffToken(user.identity?.idToken, config),
      'ID Token data logged to browser console',
    );
  };
  const logUserHelper = () => {
    logObjectToConsole(
      'UserHelper static class:',
      UserHelper,
      'UserHelper class logged to browser console',
    );
  };
  const logStudentUserHelper = () => {
    logObjectToConsole(
      'StudentUserHelper static class:',
      StudentUserHelper,
      'StudentUserHelper class logged to browser console',
    );
  };
  const logAppHelper = () => {
    logObjectToConsole(
      'AppHelper static class:',
      AppHelper,
      'AppHelper class logged to browser console',
    );
  };
  const logApiHelper = () => {
    logObjectToConsole(
      'ApiHelper instance:',
      apiHelper,
      'AppHelper instance logged to browser console',
    );
  };
  const logCookieMap = () => {
    logObjectToConsole('Cookie Map:', parseCookieMap(), 'Cookie map logged to browser console');
  };

  return (
    <div className="app-debug-misc-section-root">
      <div className="app-debug-drawer-intro">
        Use buttons below to log and inspect application in browser console.
      </div>
      <div className="app-debug-drawer-subsection">
        <div className="app-debug-drawer-subsection-title">
          <h3 className="app-debug-drawer-subsection-title-text">Application Data</h3>
          <span></span>
        </div>
        <div className="app-debug-drawer-subsection-body">
          <ButtonGroup
            className="app-debug-drawer-button-group"
            variant="outlined"
            orientation="vertical"
            size="small"
            fullWidth={true}
          >
            <Button onClick={logConfigToConsole}>Application Configuration</Button>
            <Button onClick={logAppStatus}>Application Status</Button>
            <Button onClick={logStoreToConsole}>Redux Store Data</Button>
            <Button onClick={logCookieMap}>Cookies</Button>
          </ButtonGroup>
        </div>
      </div>
      <div className="app-debug-drawer-subsection">
        <div className="app-debug-drawer-subsection-title">
          <h3 className="app-debug-drawer-subsection-title-text">Cognito / Session / User</h3>
          <span></span>
        </div>
        <div className="app-debug-drawer-subsection-body">
          <ButtonGroup
            className="app-debug-drawer-button-group"
            variant="outlined"
            orientation="vertical"
            size="small"
            disabled={!user.loggedIn}
            fullWidth={true}
          >
            <Button onClick={logStaffUser}>Complete User Object</Button>
            <Button onClick={logTokenData}>Cognito ID Token Data</Button>
            <Button onClick={logCognitoSession}>Cognito Session</Button>
            <Button onClick={logCognitoUser}>Cognito User</Button>
          </ButtonGroup>
        </div>
      </div>
      <div className="app-debug-drawer-subsection">
        <div className="app-debug-drawer-subsection-title">
          <h3 className="app-debug-drawer-subsection-title-text">
            Application Internals / Miscellaneous
          </h3>
          <span></span>
        </div>
        <div className="app-debug-drawer-subsection-body">
          <ButtonGroup
            className="app-debug-drawer-button-group"
            variant="outlined"
            orientation="vertical"
            size="small"
            fullWidth={true}
          >
            <Button onClick={logAppHelper}>Static AppHelper Class</Button>
            <Button onClick={logApiHelper}>ApiHelper Class Instance</Button>
            <Button onClick={logUserHelper}>Static UserHelper Class</Button>
            <Button onClick={logStudentUserHelper}>Static StudentUserHelper Class</Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default AppDebugInspect;
