import { Reducer } from 'redux';

import { StudentAuthState } from './authDataTypes';

import { AppReduxAction } from '../appDataTypes';
import StudentUserHelper from '../../lib/helper/StudentUserHelper';

/**
 * Initial state for student authentication store
 * @type {StudentAuthState}
 */
const initState: StudentAuthState = {
  // loggedIn: false,
  cognito: {
    userPool: null,
    cognitoUser: null,
    cognitoUserSession: null,
  },
  user: StudentUserHelper.initializeStudentUser(),
  userStatus: {
    busy: false,
    busyMessage: '',
    error: false,
    errorMessage: '',
  },
};

export const defaultStudentAuthState: StudentAuthState = {
  ...initState,
  user: {
    ...initState.user,
    info: {
      ...initState.user.info,
    },
    identity: {
      ...initState.user.identity,
    },
  },
  userStatus: {
    ...initState.userStatus,
  },
};

/**
 * Authentication reducer for student auth store
 *
 * @type {Reducer<StudentAuthState, AppReduxAction>}
 *
 * @param {StudentAuthState}   state      Initial state
 * @param {AppReduxAction}     action     Redux action
 *
 * @return {Reducer} Reducer
 */
const studentAuthReducer: Reducer<StudentAuthState, AppReduxAction> = (
  state = initState,
  action,
) => {
  switch (action.type) {
    case 'SET_STUDENT_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    // case 'SET_STUDENT_USER_INFO':
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       info: action.payload,
    //     },
    //   };
    case 'UPDATE_STUDENT_USER_STATUS':
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          ...action.payload,
        },
      };
    case 'SET_STUDENT_COGNITO_USER_POOL':
      return {
        ...state,
        cognito: {
          ...state.cognito,
          userPool: action.payload,
        },
      };
    case 'SET_STUDENT_COGNITO_USER':
      return {
        ...state,
        cognito: {
          ...state.cognito,
          cognitoUser: action.payload,
        },
      };
    case 'SET_STUDENT_COGNITO_USER_SESSION':
      return {
        ...state,
        cognito: {
          ...state.cognito,
          cognitoUserSession: action.payload,
        },
      };
    // case 'SET_STUDENT_USER_LOGGED_IN':
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       loggedIn: action.payload,
    //     },
    //   };
    default:
      return state;
  }
};

export default studentAuthReducer;
