import React from 'react';
import {
  AuthState,
  StaffAuthUser,
  StaffAuthUserUpdate,
  StudentAuthUser,
} from '../../reducers/Auth/authDataTypes';
import { AppStatusUpdate } from '../../reducers/appDataTypes';
import { RouterChildContext } from 'react-router-dom';
import LogHelper from './LogHelper';
import BaseApiHelper from './BaseApiHelper';
import { ApiRequestInstance } from './ApiHelper.d';
import { AxiosInstance } from 'axios';
import UserHelper from './UserHelper';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import StudentUserHelper from './StudentUserHelper';
import { Config } from '@elevate-ui/config';

export interface DispatchParams {
  type: string;
  payload:
    | AppStatusUpdate
    | boolean
    | string
    | StaffAuthUser
    | StaffAuthUserUpdate
    | CognitoUserSession
    | null;
}

class AppApiHelper extends BaseApiHelper {
  history: RouterChildContext['router']['history'] | undefined = undefined;
  dispatch: React.Dispatch<DispatchParams> | undefined = undefined;
  cognitoSession: CognitoUserSession | null = null;
  authState: AuthState | null = null;
  sessionType = '';

  constructor(
    config: Config,
    authState: AuthState,
    sessionType: string,
    dispatch: React.Dispatch<DispatchParams>,
    history: RouterChildContext['router']['history'],
    activeRequestsPointer?: ApiRequestInstance[],
  ) {
    super(config, activeRequestsPointer);
    this.config = config;
    this.cognitoSession =
      sessionType === 'student'
        ? authState.studentAuth.cognito.cognitoUserSession
        : authState.staffAuth.cognito.cognitoUserSession;
    this.sessionType = sessionType;
    this.authState = authState;
    if (dispatch) {
      this.dispatch = dispatch;
    }
    if (history) {
      this.history = history;
    }
  }

  getAuthorizeHeaderValue(): string {
    let idToken = '';
    if (this.cognitoSession !== null) {
      idToken = this.cognitoSession.getIdToken().getJwtToken();
    }
    return idToken;
  }

  async _getAxiosInstance(skipAuth = false): Promise<AxiosInstance> {
    if (!skipAuth) {
      if (typeof this?.dispatch !== 'undefined' && this.authState !== null) {
        if (this.sessionType === 'staff') {
          const user: StaffAuthUser = this.authState?.staffAuth.user;
          const session = this.cognitoSession;

          if (session !== null && !session.isValid()) {
            LogHelper.warning(
              this.config,
              'Staff Cognito idToken has expired, refreshing...',
              'Helper|ApiHelper',
            );
            await UserHelper.refreshStaffUser(this.config, user, this.dispatch, true);

            if (typeof this.dispatch !== 'undefined') {
              const payload: AppStatusUpdate = {
                appBusy: false,
              };
              this.dispatch({ type: 'UPDATE_APP_STATUS', payload });
            }
            this.cognitoSession = await UserHelper.getCognitoSession(this.config);
          }
        } else if (this.sessionType === 'student') {
          const user: StudentAuthUser = this.authState.studentAuth.user;
          const session = this.cognitoSession;
          if (session !== null && !session.isValid()) {
            LogHelper.warning(
              this.config,
              'Student Cognito idToken has expired, refreshing...',
              'Helper|ApiHelper',
            );
            await StudentUserHelper.refreshStudentUser(user, this.dispatch, true, this.config);

            if (typeof this.dispatch !== 'undefined') {
              const payload: AppStatusUpdate = {
                appBusy: false,
              };
              this.dispatch({ type: 'UPDATE_APP_STATUS', payload });
            }
            this.cognitoSession = await StudentUserHelper.getCognitoSession(this.config);
          }
        }
      } else {
        LogHelper.error('Requrements not met for updating session', 'Helper|ApiHelper');
      }
    } else {
      LogHelper.log(this.config, 'Skipping auth due to call arg', 'Helper|ApiHelper');
    }

    const instance = await super._getAxiosInstance(true);

    return instance;
  }
}
export default AppApiHelper;
