import { useEffect, useState } from 'react';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import useAppLogger from '../../hooks/useAppLogger';
import {
  BooleanFlags,
  BooleanFlagsEnabled,
  BooleanFlagState,
  BooleanFlagWithConfigResult,
} from './useBooleanFlagWithConfig.types';

import useFlagAttributes from '../useFlagAttributes/useFlagAttributes';

/**
 * Given a name for a boolean (enabled/disabled) flag in split.io, return the status of that flag
 * and any configuration (defined by T) associated with the flag.
 *
 * @param {BooleanFlags} flagName - the identifier for the flag to retrieve
 * @returns {BooleanFlagWithConfigResult<T>} ready/enabled/configuration for the flag
 */

const FLAG_REQUEST_TIMEOUT = 3000;

const useBooleanFlagWithConfig = <T>(flagName: BooleanFlags): BooleanFlagWithConfigResult<T> => {
  const logger = useAppLogger(`HOOK|useBooleanFlagWithConfig|${flagName}`);
  const [config, setConfig] = useState<T | null>(null);
  const [enabled, setEnabled] = useState<BooleanFlagState>('control');
  const [isReady, setIsReady] = useState(false);

  const { attributes, splitKey } = useFlagAttributes();
  const treatmentResult = useSplitTreatments({ names: [flagName], attributes, splitKey });

  const parseConfig = (config: string): T | null => {
    try {
      return JSON.parse(config);
    } catch (e) {
      logger.error(e);
    }
    return null;
  };

  useEffect(() => {
    const booleanFlag = treatmentResult.treatments[flagName];
    if (treatmentResult && treatmentResult.isReady) {
      const currentTreatment =
        booleanFlag && booleanFlag.treatment ? booleanFlag.treatment : 'control';
      const enabled = currentTreatment === BooleanFlagsEnabled.get(flagName);
      logger.debug(`Current Treatment: ${currentTreatment}|enabled=${enabled}`);
      setEnabled(enabled ? 'enabled' : 'disabled');
      setConfig(booleanFlag && booleanFlag.config ? parseConfig(booleanFlag.config) : null);
      setIsReady(treatmentResult.isReady);
    }
    if (treatmentResult?.isTimedout) {
      // If the request times out, we set isReady to true to prevent the hook from being stuck in a loading state.
      setIsReady(true);
      logger.error(`Timeout returned for flag ${flagName}`);
    }
  }, [
    treatmentResult.isReady,
    treatmentResult.isTimedout,
    treatmentResult.treatments[flagName],
    attributes,
  ]);

  // If Split takes longer than 3 seconds to return a result, we consider it timed out and
  // set isReady to true to prevent the hook from being stuck in a loading state.
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isReady) {
        setIsReady(true);
        logger.error(
          `Timeout reached for flag ${flagName}. Timeout Duration: ${FLAG_REQUEST_TIMEOUT}ms`,
        );
      }
    }, FLAG_REQUEST_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [isReady]);

  return { config, enabled, isEnabled: enabled === 'enabled', isReady };
};

export default useBooleanFlagWithConfig;
