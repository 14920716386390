import { useState, useEffect } from 'react';
import { faker } from '@faker-js/faker';

var tocChars = '0ABCDEFGHIJKLMNOPQRSTUVWXYZ';
var tocCharsArray = function () { return tocChars.split(''); };
/**
 * Given a Table of Contents object, this helper function will return
 * letters in the ToC with no associated items and are thereby unavailable for selection in the ToC
 *
 * @param {PaginatedTOC} toc - the Table of Contents object
 * @returns {string[]} - the list of unavailable character options
 */
var tocCharsWithNoOptions = function (_a) {
    var data = _a.data;
    return tocCharsArray().filter(function (filterChar) {
        var match = data.find(function (_a) {
            var character = _a.character;
            return character === filterChar;
        });
        return !match || match.count === 0;
    });
};
/**
 * Given a Table of Contents object, this helper function will return
 * the first character in the ToC with available items
 * @param {PaginatedTOC} toc - the Table of Contents object
 * @returns {string} the first character with available options
 */
var tocFirstOption = function (_a) {
    var data = _a.data;
    return tocCharsArray().find(function (filterChar) {
        var match = data.find(function (_a) {
            var character = _a.character;
            return character === filterChar;
        });
        return match && match.count > 0;
    });
};
/**
 * Given a Table of Contents object, this helper function will return the toc entry for a
 * provided character
 * @param {PaginatedTOC} toc - the full ToC
 * @param {string} filterChar - the character to return the ToC entry for
 * @returns {PaginatedTOCData} - the matching ToC data
 */
var tocForChar = function (_a, filterChar) {
    var data = _a.data;
    return data.find(function (_a) {
        var character = _a.character;
        return character === filterChar;
    });
};
/**
 * Given a Table of Contents object, this helper function will return a boolean value indicating
 * if the ToC indicates the presence of matching data entries for that character
 *
 * @param {PaginatedTOC} toc - the full ToC
 * @param {string} filterChar - the character to query for
 * @returns {boolean} - true indicates the char has selectable options
 */
var tocDoOptionsExistForChar = function (toc, filterChar) {
    var tocForFilterChar = tocForChar(toc, filterChar);
    return tocForFilterChar ? tocForFilterChar.count > 0 : false;
};
/**
 * Given a Table of Contents object, this helper function will return the page where options for
 * a particular character start, based on a lookup in the ToC data.
 *
 * @param {PaginatedTOC} toc - the full ToC
 * @param {string} filterChar - the character to return the page for
 * @returns {number} - the page number where items for the given char start
 */
var tocPageForChar = function (toc, filterChar) {
    if (!toc) {
        return -1;
    }
    var tocForFilterChar = tocForChar(toc, filterChar);
    return tocForFilterChar ? tocForFilterChar.page : -1;
};
/**
 * Given a ToC object and a filter char, will return the count of items that exist for that ToC character
 * @param {PaginatedTOC} toc - the full ToC
 * @param {string} filterChar - the character to return a count for
 * @returns {number} - the number of available items for the given char
 */
var tocCountForChar = function (toc, filterChar) {
    if (!toc) {
        return 0;
    }
    var tocForFilterChar = tocForChar(toc, filterChar);
    return tocForFilterChar ? tocForFilterChar.count : 0;
};

var chunk = function (arr, size) {
    if (size === void 0) { size = 10; }
    return Array.from({ length: Math.ceil(arr.length / size) }, function (v, i) {
        return arr.slice(i * size, i * size + size);
    });
};
/**
 * This helper will generate a PaginatedTOC object based on a set of paginated fake data
 * The PaginatedTOC type contains a mapping of response items to characters
 *
 * @param {T} pages - a set of pages of fake data
 * @param {FakeDataConfig} config - configuration for this fake data
 * @returns {PaginatedTOC} - the generated ToC object
 */
var fakeTableOfContents = function (pages, config) {
    // Adding ToC data to the fake pagination service is optional
    // If filterCharFunc is undefined, populate with a function that returns an empty set
    var filter = config.filterCharFunc ? config.filterCharFunc : function () { return []; };
    var data = tocCharsArray().map(function (character) {
        var count = filter(pages.flat(), character).length;
        var page = pages.findIndex(function (page, index) { return filter(page, character).length > 0; });
        return { character: character, count: count, page: page };
    });
    return { data: data };
};
var fakePaginatedResponse = function (allOptions, pages, currentPage, pageSize, config) {
    if (pageSize === void 0) { pageSize = 10; }
    return {
        data: pages[currentPage],
        currentPage: currentPage + 1,
        totalPages: pages.length,
        totalItems: allOptions.length,
        pageSize: pageSize,
        toc: fakeTableOfContents(pages, config),
    };
};

var useFakePaginatedData = function (config, pageSize, filterText) {
    if (pageSize === void 0) { pageSize = 25; }
    var _a = useState(0), currentPage = _a[0], setCurrentPage = _a[1];
    var _b = useState(0), totalPages = _b[0], setTotalPages = _b[1];
    var _c = useState([]), toc = _c[0], setToc = _c[1];
    var _d = useState(true), isLoading = _d[0], setIsLoading = _d[1];
    var _e = useState(null), response = _e[0], setResponse = _e[1];
    var _f = useState([]), fakeData = _f[0], setFakeData = _f[1];
    var debug = function (message) { return console.log("useFakePaginatedData | ".concat(message)); };
    var loadMore = function () {
        var nextPage = currentPage + 1;
        if (nextPage < totalPages) {
            setCurrentPage(nextPage);
            debug("current page set to ".concat(nextPage));
        }
        else {
            debug("All data has been loaded");
        }
    };
    var loadPage = function (newPage) {
        debug("loadPage request: ".concat(newPage));
        if (newPage < totalPages) {
            setCurrentPage(newPage);
            debug("current page set to ".concat(newPage));
        }
        else {
            debug("Invalid page");
        }
    };
    var _g = useState({
        isLoading: isLoading,
        response: response,
        loadMore: loadMore,
        loadPage: loadPage,
        hasMore: currentPage + 1 < totalPages,
        currentPage: currentPage,
    }), hookState = _g[0], setHookState = _g[1];
    useEffect(function () {
        if (filterText !== '') {
            setIsLoading(true);
            debug("Loading new source data: filterText [".concat(filterText, "]"));
            setIsLoading(true);
            setCurrentPage(0);
        }
        // any time filter text changes, TOC is reset
        setToc([]);
    }, [filterText]);
    useEffect(function () {
        // setup the base data and paginating it
        var data = config.createFakeData(config.numItems);
        debug("Setup source fake data with ".concat(data.length, " items"));
        setFakeData(data);
    }, []);
    var loadFilteredData = function () {
        var filterResults = config.filterFunc(fakeData, filterText);
        var pages = chunk(filterResults, pageSize);
        setTotalPages(pages.length);
        debug("Returning filtered results (filterText ".concat(filterText, "): ").concat(filterResults.length, " items (Page ").concat(currentPage, "/").concat(pages.length, ")"));
        var response = fakePaginatedResponse(fakeData, pages, currentPage, pageSize, config);
        setResponse(response);
        // we only reset the TOC if it has been explicitly cleared
        if (toc.length === 0) {
            setToc(response.toc.data);
        }
    };
    var loadAllData = function () {
        var pages = chunk(fakeData, pageSize);
        setTotalPages(pages.length);
        debug("Loading page: ".concat(currentPage, "/").concat(pages.length - 1));
        var response = fakePaginatedResponse(fakeData, pages, currentPage, pageSize, config);
        setResponse(response);
        // we only reset the TOC if it has been explicitly cleared
        if (toc.length === 0) {
            setToc(response.toc.data);
        }
    };
    useEffect(function () {
        setIsLoading(true);
        var timer = setTimeout(function () {
            if (filterText !== '') {
                loadFilteredData();
            }
            else {
                loadAllData();
            }
            setIsLoading(false);
        }, config.delay);
        return function () { return clearTimeout(timer); };
    }, [fakeData, currentPage, filterText]);
    useEffect(function () {
        setHookState({
            isLoading: isLoading,
            response: response,
            loadMore: loadMore,
            loadPage: loadPage,
            hasMore: currentPage + 1 < totalPages,
            currentPage: currentPage,
        });
    }, [response, isLoading, currentPage, totalPages]);
    return hookState;
};

var createFakeSchool = function (schoolName) { return ({
    id: faker.string.uuid(),
    externalId: faker.string.uuid(),
    schoolName: "".concat(schoolName, " school"),
}); };
var createSortedFakeSchools = function (count) {
    var names = faker.helpers.uniqueArray(faker.company.name, count);
    names.sort();
    return names.map(function (name) { return createFakeSchool(name); });
};
var filterFakeSchools = function (schools, filterText) {
    return schools.filter(function (school) { return school.schoolName.toLowerCase().includes(filterText.toLowerCase()); });
};

var createFakeSection = function () { return ({
    id: faker.string.uuid(),
    externalId: faker.string.uuid(),
    sectionName: faker.lorem.sentence(),
    grade: faker.number.int(12),
}); };
var createFakeSortedSections = function (count) {
    var sections = faker.helpers.multiple(createFakeSection, { count: count });
    sections.sort(function (a, b) {
        return a.sectionName > b.sectionName ? 1 : b.sectionName > a.sectionName ? -1 : 0;
    });
    return sections;
};

var filterSections = function (sections, filterText) {
    return sections.filter(function (section) { return section.sectionName.includes(filterText); });
};
var filterSectionsByChar = function (options, char) {
    return char === '0'
        ? options.filter(function (_a) {
            var sectionName = _a.sectionName;
            return sectionName.match('[_0-9](.*)');
        })
        : options.filter(function (_a) {
            var sectionName = _a.sectionName;
            return sectionName.toLocaleLowerCase().startsWith(char.toLocaleLowerCase());
        });
};

export { createFakeSortedSections, createSortedFakeSchools, filterFakeSchools, filterSections, filterSectionsByChar, tocCharsWithNoOptions, tocCountForChar, tocDoOptionsExistForChar, tocFirstOption, tocForChar, tocPageForChar, useFakePaginatedData };
