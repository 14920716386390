import HelperBase from '../HelperBase';
import React from 'react';
import { AppNotification } from '../../reducers/appDataTypes';
import { StaffAuthUser, StudentAuthUser, StudentInfo } from '../../reducers/Auth/authDataTypes';

class AppHelper extends HelperBase {
  static async wait(duration = 0): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, duration);
    });
  }

  static escapeRegexString(regex: string): string {
    return regex.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  /**
   * Escapes regex string and returns RegExp object instance for it
   *
   * @param {string} regex      String to use as regex
   * @param {string} regexFlags RegExp constructor flags
   *
   * @returns {RegExp} RegExp object
   */
  static createRegex(regex: string, regexFlags = ''): RegExp {
    const escapedRegex = regex !== '' ? AppHelper.escapeRegexString(regex) : '';
    const re = new RegExp(escapedRegex, regexFlags);
    return re;
  }

  static getCookieValue(name: string): string {
    let value = '';
    const cookies = Object.fromEntries(document.cookie.split(/;\s?/).map((cp) => cp.split('=')));
    if (cookies && cookies[name]) {
      value = cookies[name];
    }
    return value;
  }

  static setCookieValue(name: string, value: string): void {
    document.cookie = `${name}=${value};path=/;secure`;
  }

  static removeCookie(name: string, secure = true, path = '/', domain = ''): void {
    let cookieString = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    if (secure) {
      cookieString += 'secure;';
    }
    if (path) {
      cookieString += `path=${path};`;
    }
    if (domain) {
      cookieString += `domain=${domain};`;
    }
    cookieString = cookieString.replace(/;$/, '');
    document.cookie = cookieString;
  }

  /**
   * Sets student user in sessionStorage
   *
   * @static
   * @deprecated Use StudentUserHelper instead
   * @see StudentUserHelper.setStudentUser
   *
   * @param {StudentAuthUser} identity Student user
   *
   * @returns {void}
   */
  static setStudentUser = (identity: StudentAuthUser): void => {
    const value = JSON.stringify(identity, null, 4);
    sessionStorage.setItem('studentUser', value);
  };

  /**
   * Clears student user from cookies and sessionStorage
   *
   * @static
   * @deprecated Use StudentUserHelper instead
   * @see StudentUserHelper.clearStudentUser
   *
   * @returns {void}
   */
  static clearStudentUser(): void {
    sessionStorage.removeItem('studentUser');
  }

  static serveCSV = (csv: string, filename: string): void => {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  static getUUID = (): string => {
    let d = new Date().getTime();
    let d2 = (performance && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
  };

  static addNotification = (
    message: string | React.FunctionComponent | React.ReactNode | React.ReactElement | Element,
    type: string,
    duration = 10000,
    icon: React.ReactNode | React.ReactElement | Element | null = null,
    sticky = false,
    dispatch: React.Dispatch<{ type: string; payload: AppNotification }>,
  ): AppNotification => {
    const notification = AppHelper.createAppNotification(message, type, duration, icon, sticky);
    dispatch({ type: 'ADD_APP_NOTIFICATION', payload: notification });
    return notification;
  };

  static createAppNotification = (
    message: string | React.FunctionComponent | React.ReactNode | React.ReactElement | Element,
    type = 'info',
    duration = 0,
    icon: React.ReactNode | React.ReactElement | Element | null = null,
    sticky = false,
    persistOnLocationChange = false,
    uuid = '',
  ): AppNotification => {
    if (!uuid) {
      uuid = AppHelper.getUUID();
    }
    const notification: AppNotification = {
      type,
      message,
      created: new Date(),
      duration,
      sticky,
      icon,
      seen: false,
      persistOnLocationChange,
      uuid,
    };
    return notification;
  };

  static parseStudentToken = (token: string): StudentInfo | null => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } catch (ex) {
      // LogHelper.error(ex, 'AppHelper');
      // console.error(ex);
      return null;
    }
  };

  static getStringTimestamp = (includeTime = false): string => {
    const date = new Date();
    const year = `${date.getFullYear()}`;
    let month = `${date.getMonth() + 1}`;
    let day = `${date.getDate()}`;
    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }
    let timestamp = `${year}-${month}-${day}`;

    if (includeTime) {
      let hour = `${date.getHours()}`;
      let minute = `${date.getMinutes()}`;
      let second = `${date.getSeconds()}`;
      if (hour.length < 2) {
        hour = `0${hour}`;
      }
      if (minute.length < 2) {
        minute = `0${minute}`;
      }
      if (second.length < 2) {
        second = `0${second}`;
      }
      timestamp += `_${hour}:${minute}:${second}`;
    }
    return timestamp;
  };

  static setCookie = (
    name: string,
    value: string,
    ttlHours = 0,
    secure = true,
    path = '/',
    domain = '',
  ): void => {
    let cookieString = `${name} = ${encodeURIComponent(value)};`;
    if (ttlHours !== 0) {
      if (ttlHours > 0) {
        const date = new Date();
        date.setTime(date.getTime() + ttlHours * 60 * 60 * 1000);
        cookieString += ` expires = ${date.toUTCString()};`;
      } else {
        cookieString += ' expires = -1;';
      }
    }
    if (secure) {
      cookieString += ' secure;';
    }
    if (path) {
      cookieString += ` path = ${path};`;
    }
    if (domain) {
      cookieString += ` domain = ${domain};`;
    }
    cookieString = cookieString.replace(/;$/, '');
    document.cookie = cookieString;
  };

  static reloadStaffUserRoles = async (staffUser: StaffAuthUser): Promise<StaffAuthUser> => {
    return Promise.resolve(staffUser);
  };
}

export default AppHelper;
