import { usePDF } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import TestTakerTicketsPDF, {
  TestTakerTicketsPDFProps,
} from './TestTakerTicketsPDF/TestTakerTicketsPDF';
import styles from './DownloadTicketsLink.module.scss';
import { OverlayLoader } from '@riversideinsights/elevate-react-lib';

export interface DownloadTicketsLinkProps extends TestTakerTicketsPDFProps {
  onDocumentDownload?: () => void;
}

const DownloadTicketsLink = ({ onDocumentDownload, ...rest }: DownloadTicketsLinkProps) => {
  const [instance] = usePDF({ document: <TestTakerTicketsPDF {...rest} /> });
  const [downloadLinkRef, setDownloadLinkRef] = useState<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (downloadLinkRef) {
      downloadLinkRef.click();
      onDocumentDownload?.();
    }
  }, [downloadLinkRef]);

  if (instance.loading || !instance.blob) {
    return (
      <div className={styles['download-tickets-link']}>
        <OverlayLoader title="Preparing document, please wait." />
      </div>
    );
  }

  if (instance.error) {
    return <div className={styles['download-tickets-link']}>Failed to build document.</div>;
  }

  return (
    <div className={styles['download-tickets-link']}>
      <span>Document downloaded.</span>

      <a
        download="TestTakerTickets-1.PDF"
        href={instance.url ? instance.url : undefined}
        ref={setDownloadLinkRef}
      >
        Download it again
      </a>
    </div>
  );
};

export default DownloadTicketsLink;
