import React from 'react';

import './PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
  const scrollTo = (id: string) => {
    const el: HTMLElement | null = document.getElementById(id) as HTMLElement;
    if (el && el.scrollIntoView) {
      el.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  return (
    <div className="elevate-privacy">
      <h2 className="article-title" id="privacy-modal-title">
        RIVERSIDE INSIGHTS
        <br />
        ASSESSMENT PRIVACY POLICY
      </h2>
      <p className="last-content-update">
        <strong>
          <span>LAST UPDATED: December 19, 2023</span>
        </strong>
      </p>
      <h3>
        <span>Introduction</span>
      </h3>
      <p>
        Riverside Assessments, LLC dba Riverside Insights (“Riverside,” “we,” “us,” or “our”)
        respects your privacy and makes the security and integrity of the data we collect a top
        priority. As part of our commitment to data privacy, we developed this Assessment Privacy
        Policy (“Policy”) to explain how Riverside collects, uses, shares, and protects the
        information you provide to us in the course of our assessment services and your rights with
        respect to such information.
      </p>
      <p>
        This Policy applies to information we collect or receive through assessment products and
        platforms. If you would like data privacy information about our websites, please see our{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://info.riversideinsights.com/privacy-website_policy?hsLang=en"
        >
          Website Privacy Policy
        </a>
        .
      </p>
      <p>
        If you are a resident of the European Union (“EU”), the United Kingdom (“UK”), Illinois,
        California, or New York, you may have additional rights with respect to your Personal
        Information, as outlined below.
      </p>
      <h3>
        <span>Summary</span>
      </h3>
      <ul>
        <li>
          We only use the data collected through our platforms and products to deliver and improve
          our assessment services.
        </li>
        <li>We do not sell any student/examinee data or use such data for targeted advertising.</li>
        <li>
          We comply with applicable federal, state, and international laws governing data privacy,
          including the Family Educational Rights and Privacy Act (<em>FERPA</em>), the Children’s
          Online Privacy Protection Act (<em>COPPA</em>), the Health Insurance Portability and
          Accountability Act (<em>HIPAA</em>), the Illinois Student Online Personal Protection Act,
          the California Consumer Privacy Act (<em>CCPA</em>), the General Data Protection
          Regulation (<em>GDPR</em>), and the New York Parents’ Bill of RightsEducation Law 2-d (
          <em>Privacy Bill of RightsNY Ed Law 2-d</em>), the EU General Data Protection Regulation (
          <em>EU GDPR</em>), and the UK General Data Protection Regulation (<em>UK GDPR</em>).
        </li>
        <li>We protect your data using industry-standard security tools and processes.</li>
      </ul>
      <div className="privacy-toc-wrapper">
        <div className="privacy-toc-title">Jump to a Section</div>
        <ul className="privacy-toc">
          <li>
            <button
              className="link-button toc-item"
              onClick={() => scrollTo('privacy_definitions')}
            >
              Definitions
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('privacy_types')}>
              Types of Personal Information We Collect and Use
            </button>
          </li>
          <li>
            <button
              className="link-button toc-item"
              onClick={() => scrollTo('privacy_personalinfocollect')}
            >
              How We Collect Personal Information
            </button>
          </li>
          <li>
            <button
              className="link-button toc-item"
              onClick={() => scrollTo('privacy_personalinfouse')}
            >
              How We Use Personal Information
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('privacy_disclosure')}>
              Disclosure of Personal Information
            </button>
          </li>
          <li>
            <button
              className="link-button toc-item"
              onClick={() => scrollTo('privacy_personalinfoprotect')}
            >
              How We Protect Personal Information
            </button>
          </li>
          <li>
            <button
              className="link-button toc-item"
              onClick={() => scrollTo('privacy_accesspersonalinfo')}
            >
              Accessing, Updating, or Deleting Personal Information
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('privacy_coppaferpa')}>
              Compliance with COPPA and FERPA
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('privacy_hipaa')}>
              Compliance with HIPAA
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('privacy_children')}>
              Children’s Privacy
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('privacy_rights')}>
              Your California Privacy Rights
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('privacy_gdpr')}>
              Your Rights under the EU GDPR and the UK GDPR
            </button>
          </li>
          <li>
            <button
              className="link-button toc-item"
              onClick={() => scrollTo('privacy_parentsrights')}
            >
              NY Education Law 2-d
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('SOPPA')}>
              Illinois Student Online Personal Protection Act (“SOPPA”)
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('privacy_other')}>
              Other Websites and Services
            </button>
          </li>
          <li>
            <button
              className="link-button toc-item"
              onClick={() => scrollTo('privacy_deintentified')}
            >
              De-Identified Information
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('privacy_changes')}>
              Changes to Our Privacy Policy
            </button>
          </li>
          <li>
            <button
              className="link-button toc-item"
              onClick={() => scrollTo('privacy_personalinfotransfer')}
            >
              Personal Information Transferred from the U.S.
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('privacy_dnt')}>
              Do Not Track
            </button>
          </li>
          <li>
            <button className="link-button toc-item" onClick={() => scrollTo('privacy_contact')}>
              How to Contact Us
            </button>
          </li>
        </ul>
      </div>

      <h3 id="privacy_definitions">
        <span>Definitions</span>
      </h3>
      <ul className="terms-definitions">
        <li>
          <strong>“COPPA“</strong>
          <span>
            means the Children’s Online Privacy Protection Act, 15 U.S.C. §§ 6501-6505, and the
            regulations promulgated thereunder, each as amended.
          </span>
        </li>
        <li>
          <strong>“Customer”</strong>
          <span>
            means an institution or professional who licenses Services, such as school districts,
            educational agencies, universities, hospitals, clinical psychologists, and healthcare
            systems.
          </span>
        </li>
        <li>
          <strong>“Customer Personnel”</strong>
          <span>
            means employees, staff, contractors, agents, and other authorized representatives of our
            Customers, such as administrators, authorized account holders, staff, teachers, and
            psychologists.
          </span>
        </li>
        <li>
          <strong>“FERPA”</strong>
          <span>
            means the Family Educational Rights and Privacy Act, 20 U.S.C. § 1232g, and the
            regulations promulgated thereunder, each as amended.
          </span>
        </li>
        <li>
          <strong>“HIPAA”</strong>
          <span>
            means the Health Insurance Portability and Accountability Act of 1996, 42 U.S.C.
            §&nbsp;1320d <em>et seq.</em>, and the regulations promulgated thereunder, each as
            amended.
          </span>
        </li>
        <li>
          <strong>“Personal Information”</strong>
          <span>
            means information that, either alone or in combination with other information,
            identifies or relates to an individual.
          </span>
        </li>
        <li>
          <strong>“Platforms”</strong>
          <span>means Riverside’s web-based platforms for assessment, scoring, and reporting.</span>
        </li>
        <li>
          <strong>“Products”</strong>
          <span>means Riverside’s educational, clinical, and special needs assessments.</span>
        </li>
        <li>
          <strong>“Protected Health Information” or “PHI”</strong>
          <span>has the definition provided under HIPAA.</span>
        </li>
        <li>
          <strong>“Services”</strong>
          <span>means the Platforms and Products.</span>
        </li>
        <li>
          <strong>“Students / Examinees”</strong>
          <span>
            means individuals who either directly use Riverside’s Services or whose information
            Riverside collects in the course of providing Services.
          </span>
        </li>
      </ul>
      <p>Additional defined terms are identified throughout the rest of this Policy.</p>
      <h3 id="privacy_types">
        <span>Types of Personal Information We Collect and Use</span>
      </h3>
      <p>
        In providing the Services, we may request Personal Information from you or other individuals
        affiliated with the Customer sponsoring your use of the Services, including
        Students/Examinees. The exact Personal Information we need to collect depends on which
        Service you are using and the optional data fields you or the Customer sponsoring your use
        of our Services chooses to provide. Our Services may collect four broad categories of
        Personal Information:
      </p>
      <ul>
        <li>
          <strong>
            <em>Student/Examinee information</em>
          </strong>
          , including assessment responses, first name, middle name, last name, email, date of
          birth, place of birth, gender, grade, race, ethnicity, language, district/area,
          school/building name, school/building code, class name, class code, student identification
          number, IEP/IFSP status, 504 program status, Title I status, free or reduced lunch status,
          gifted and talented status, English language learner status, migrant status, country,
          enrollment date, disability, funding, additional identification number, braille user, home
          reporting, program code, and active user status.
        </li>
        <li>
          <strong>
            <em>Customer and Customer Personnel information</em>
          </strong>
          , including organization name, program association, member type, customer address (city,
          state, zip code, and country), first name, middle name, last name, title, email address,
          phone number, fax, employee identification number, username/ID, grade, gender,
          district/area, school/building, class name/code, role/permissions, and active user status.
        </li>
        <li>
          <strong>
            <em>Parent/guardian information</em>
          </strong>{' '}
          may be collected in performance of our clinical Services, including first name, last name,
          email address, address (city, state, zip code, and country), phone, date of birth, number
          of adults in the family, number of children in the family, educational attainment level,
          and employment status.
        </li>
        <li>
          <strong>
            <em>Usage information</em>
          </strong>
          , including IP addresses, and device, browser, and operating system identifiers.
        </li>
      </ul>
      <h3 id="privacy_personalinfocollect">
        <span>How We Collect Personal Information</span>
      </h3>
      <p>
        We may collect the foregoing types of Personal Information through your use of the Services
        in the following manner:
      </p>
      <ul>
        <li>
          Submitting or importing files for the purposes of completing assessment enrollments.
        </li>
        <li>
          Submitting files for the purposes of obtaining bar code labels that can be applied to
          student answer documents or consumable test books.
        </li>
        <li>
          Receiving roster data and related information from third-party service providers for the
          purposes of completing assessment enrollments.
        </li>
        <li>
          Received data from third-party distributors or other partners from whom you purchased
          licenses to our Services.
        </li>
        <li>
          Hand keying data for the purposes of completing assessment enrollments and evaluation
          data.
        </li>
        <li>
          Hand keying item responses and test raw scores for the purposes of creating derived test
          statistics.
        </li>
        <li>
          Collection of answer strings – for tests administered online, we collect submitted
          assessment data electronically throughout the course of an assessment.
        </li>
        <li>
          Audio and video recordings of assessment sessions for Services that support such features.
        </li>
        <li>
          Collection of usage data – on some of our webpages, we collect usage information relating
          to computer, browser, and internet specifications through technology such as temporary
          cookies, persistent identifiers, and Google Analytics. The collection of usage information
          takes place while assessments are in progress and on some webpages when Customer Personnel
          engage with our Platforms’ assessment management activities (e.g., test administration and
          reporting). Most browsers provide you with the ability to block, delete, or disable
          cookies, and your mobile device may allow you to disable transmission of unique
          identifiers and location data. Please note, however, that disabling cookies in certain
          cases may prevent you from accessing the Services because, as detailed in{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://info.riversideinsights.com/privacy-assessment_policy#de-identified"
          >
            De-Identified Information
          </a>
          , temporary cookies allow us to validate testing sessions. Cookies in place during an
          assessment session are deleted once you close your browser. For information regarding how
          Google Analytics collects, uses, and shares your personal information, please visit:{' '}
          <span>http:///www.google.com/policies/privacy/partners/</span>
          <span>
            . To prevent data from being used by Google Analytics, you can download the opt-out
            browser add-on at:{' '}
          </span>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
          >
            https://tools.google.com/dlpage/gaoptout?hl=en
          </a>
          <span>.</span>
        </li>
        <li>
          Some of our third-party service providers may use cookies or other methods to gather usage
          information regarding your use of the Services. The use of such tracking information by a
          third party depends on the privacy policy of that third party. We review these third-party
          privacy policies and strive to only engage with third parties whose data privacy practices
          are consistent with our own. When possible, we encourage you to also review their privacy
          policies.
        </li>
        <li>
          Central scanning – scanning of enrollment data and submitted assessment data gridded onto
          answer sheets or consumable test booklets.
        </li>
        <li>
          Local scanning – localized scanning by Customer Personnel of enrollment data and of
          submitted assessment data gridded onto answer sheets or consumable test booklets.
        </li>
      </ul>
      <h3 id="privacy_personalinfouse">
        <span>How We Use Personal Information</span>
      </h3>
      <p>
        The primary use of any Personal Information we collect from you is to communicate assessment
        measurements, evaluations, and reports that are based on our psychometrically sound
        assessment results.
      </p>
      <p>In addition, we may use Personal Information for the following purposes:</p>
      <ul>
        <li>
          To communicate with Customer Personnel about the assessments and assessment management
          features available through our Platforms, including improvements to these features.
        </li>
        <li>
          To communicate with Customer Personnel about subscription accounts or transactions with
          us.
        </li>
        <li>To communicate with Customer Personnel about Service availability and downtime.</li>
        <li>
          To provide data and feedback on the functionality and features of our Services and where
          applicable:
        </li>
        <ul>
          <li>
            To personalize our Platform content and experiences for users according to their roles
            on the Platform.
          </li>
          <li>To provide maintenance and support upon request.</li>
          <li>
            To detect, investigate, and prevent activities that may violate laws or this Policy.
          </li>
          <li>
            To administer and make improvements to the Services, enhance security, and conduct
            statistical analysis.
          </li>
        </ul>
      </ul>
      <p>
        Under no circumstances will Riverside sell any Personal Information of a Student/Examinee or
        use such Student/Examinee Personal Information for targeted marketing. Any sale of Personal
        Information would only occur in the context of a transaction described below in{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://info.riversideinsights.com/privacy-assessment_policy#disclosure"
        >
          Organizations Involved in Mergers and Acquisitions Transactions
        </a>
        .
      </p>
      <h3 id="privacy_disclosure">
        <span>Disclosure of Personal Information</span>
      </h3>
      <p>
        We will not disclose Personal Information except as set forth in this Policy or with your
        consent. This section describes to whom we disclose Personal Information and for what
        purposes:
      </p>
      <h5 id="our_service_providers">Our Service Providers</h5>
      <p>
        We employ reputable service providers to assist us in providing aspects of our Services. A
        service provider is a third party engaged by Riverside who has or may have access to
        Personal Information for the purpose of helping us provide our Services to you. For example,
        we may engage third parties to provide hosting services, website and app security, remote
        proctoring services, customer communications, user and data subject rostering, user
        onboarding, and customer service (including online chat). These service providers may have
        access to some of your Personal Information only if they are performing specific tasks on
        our behalf. We take commercially reasonable steps to interact or contractually engage with
        service providers that have adopted a privacy policy governing their processing of Personal
        Information that is consistent with this Policy.
      </p>
      <p>
        If your use of our Services involves remote proctoring, please see our{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://info.riversideinsights.com/privacy-remote_proctoring?hsLang=en"
        >
          Remote Proctoring Privacy Statement
        </a>
        .
      </p>
      <p>
        If you would like to review a list of our current service providers, please see our{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://riversideinsights.com/support/subprocessors"
        >
          List of Third-Party Subprocessors
        </a>
        .
      </p>
      <h5>Organizations Involved in Mergers and Acquisitions Transactions.</h5>
      <p>
        If we sell or otherwise transfer some or all of our business or assets to another
        organization (e.g., in the course of a merger, acquisition, sale of assets, bankruptcy,
        dissolution, liquidation), any information collected through our Services, including
        Personal Information, may be among the items sold or transferred. If the rights and
        obligations with respect to your Personal Information are assigned to a third-party
        successor entity, then, with respect to your Personal Information, the successor entity will
        be subject to the terms of the then-applicable privacy policy or will adopt a privacy policy
        that is substantially similar in all material respects with this Policy.
      </p>
      <h5>Law Enforcement, Government Agencies, and Courts.</h5>
      <p>
        We may disclose Personal Information at the request of law enforcement or government
        agencies or in response to subpoenas, court orders, or other legal processes in order to
        establish, protect, or exercise our rights; to defend against a legal claim; to protect the
        rights, property, or safety of another person; or as otherwise required by law. We may also
        disclose Personal Information to investigate or prevent a violation by you of any
        contractual or other relationship with us or any alleged illegal or harmful activity by you.
      </p>
      <h3 id="privacy_personalinfoprotect">
        <span>How We Protect Personal Information</span>
      </h3>
      <p>
        We use commercially reasonable safeguards that comply with accepted industry practice in
        protecting the confidentiality and security of Personal Information, including adherence to
        standards issued by the National Institute of Standards and Technology (“NIST”). Examples of
        how we protect your Personal Information include:
      </p>
      <ul>
        <li>
          Keeping and maintaining all Personal Information in strict confidence and using such
          degree of care as is appropriate to avoid unauthorized access, use, modification, or
          disclosure;
        </li>
        <li>Deploying industry-standard encryption technology;</li>
        <li>
          Collecting, using, and disclosing Personal Information solely and exclusively for the
          purposes for which you provided the Personal Information; and
        </li>
        <li>
          Except as otherwise specified in this Policy, or with your consent, not disclosing
          Personal Information to any person other than our employees, agents, and service providers
          who have a need to know.
        </li>
      </ul>
      <p>
        Despite these efforts to store Personal Information in a secure operating environment, we
        cannot guarantee the security of Personal Information during its transmission or storage in
        our systems. Further, while we attempt to ensure the integrity and security of Personal
        Information, we cannot guarantee that our security measures will prevent third parties, such
        as hackers, from illegally obtaining access to Personal Information. We do not represent or
        warrant that Personal Information about you will be protected against loss, misuse, or
        alteration by third parties.
      </p>
      <h3 id="privacy_accesspersonalinfo">
        <span>Accessing, Updating, or Deleting Personal Information</span>
      </h3>
      <p>
        Properly authorized Customer Personnel may log into the Platforms to access, update, and
        delete Personal Information collected by the Services. If you would like to otherwise
        access, update, or delete Personal Information about the data associated with your account,
        or to have us complete any of the tasks described in this section on your behalf, you may
        submit a request to{' '}
        <a target="_blank" rel="noreferrer" href="mailto:inquiry@service.riversideinsights.com">
          inquiry@service.riversideinsights.com
        </a>{' '}
        or call us toll-free at (800) 323-9540 (in the US) or (630) 467-7000 (outside the US). We
        will promptly review all such requests in accordance with applicable law.
      </p>
      <h3 id="privacy_coppaferpa">
        <span>Compliance With COPPA and FERPA</span>
      </h3>
      <p>
        Many of our Services are designed for Customer Personnel working with K-12 students. We
        recognize the sensitive nature of Personal Information contained in educational records
        concerning children under age 13 and K-12 students generally. This Personal Information is
        protected under either or both of the following federal statutes: COPPA and FERPA. Our
        privacy practices comply with both COPPA and FERPA.
      </p>
      <h5>COPPA</h5>
      <p>
        COPPA permits a school, acting in the role of “parent,” to provide required consent
        regarding Personal Information of students who are under the age of 13. Where a school is
        the user of or subscriber to our Services, we rely on this form of COPPA consent. We provide
        the school with this Policy, to ensure that the school, in providing its COPPA consent, has
        relevant information and assurance that our practices comply with COPPA.
      </p>
      <h5>FERPA</h5>
      <p>
        FERPA permits a school to provide educational records (including those that contain
        students’ Personal Information) to certain service providers without requiring the school to
        obtain specific parental/guardian consent. FERPA permits this disclosure where the service
        provider acts as a type of “school official” by performing services, for example, that would
        otherwise be performed by the school’s own employees. We fulfill FERPA requirements for
        qualifying as a school official by, among other steps, giving our school district Customers
        control with respect to the use and maintenance of the education records at issue (including
        associated Personal Information) and refraining from re-disclosing or using this Personal
        Information except as provided under this Policy.
      </p>
      <h3 id="privacy_hipaa">
        <span>Compliance With HIPAA</span>
      </h3>
      <p>
        To the extent that information qualifies as PHI under HIPAA, and HIPAA affords greater
        privacy protections than those set forth in this Policy, Riverside will comply with the
        relevant HIPAA requirements regarding privacy for that information.
      </p>
      <h3 id="privacy_children">
        <span>Children&apos;s Privacy</span>
      </h3>
      <p>
        Except as necessary to provide our Services, we do not knowingly collect or solicit Personal
        Information directly from anyone under the age of 18 without a parent’s or guardian’s prior
        consent. The information collected from children under 18 through assessments are intended
        only with the consent and under the supervision of a parent or guardian, or, in the case of
        use through an institutional user, with the consent and supervision of such institutional
        user acting with authority and consent from the parent or guardian. This information is not
        used for, sold to, or shared with third parties for marketing or commercial purposes.
      </p>
      <h3 id="privacy_rights">
        <span>Your California Privacy Rights</span>
      </h3>
      <p>
        The State of California provides its residents with certain rights concerning their Personal
        Information. This section describes how you may exercise your rights with respect to
        Personal Information collected through our Services.
      </p>
      <h5>Categories of Personal Information Subject to California Privacy Rights</h5>
      <p>
        Through our Services, we collected the following categories of Personal Information during
        the past 12 months:
      </p>
      <ul>
        <li>Identifiers such as your name, email address, and online identifiers;</li>
        <li>
          Characteristics of protected classifications under federal and California law, such as
          race, gender, ethnicity, and disability;
        </li>
        <li>
          Internet or other electronic network activity information, such as IP addresses, device
          identifiers, and information on your interaction with of our Services;
        </li>
        <li>
          Professional or employment-related information, such as your institutional affiliation;
          and
        </li>
        <li>
          Information used to create a profile reflecting your preferences or behavior while using
          our Services.
        </li>
      </ul>
      <p>
        We collect these categories of Personal Information to the extent necessary to provide the
        Services and as otherwise described in the section{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://info.riversideinsights.com/privacy-assessment_policy#how-use"
        >
          How We Use Personal Information
        </a>
        .
      </p>
      <h5>California Privacy Rights</h5>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>Privacy Right</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Description</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Access</strong>
              </p>
            </td>
            <td>
              <p>
                You have the right to request information on the categories of Personal Information
                that we collected in the previous twelve (12) months, the categories of sources from
                which the Personal Information was collected, the specific pieces of Personal
                Information we have collected about you, and the business purposes for which such
                Personal Information is collected and shared. You also have the right to request
                information on the categories of Personal Information that were disclosed for
                business purposes and the categories of third parties with whom such information was
                shared in the twelve (12) months preceding your request. You can also access certain
                of your Personal Information by contacting us at{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:inquiry@service.riversideinsights.com"
                >
                  inquiry@service.riversideinsights.com
                </a>{' '}
                or calling us toll-free at (800) 323-9540 (in the US) or (630) 467-7000 (outside the
                US) to make such corrections.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Deletion</strong>
              </p>
            </td>
            <td>
              <p>
                You have a right to request us to delete Personal Information that we collected from
                you. However, please be aware that we may not fulfill your request for deletion if
                we (or our service provider(s)) are required to retain your Personal Information for
                one or more of the following categories of purposes: (1) to complete a transaction
                for which the Personal Information was collected, provide a good or service
                requested by you, or complete a contract between us and you; (2) to ensure our
                website integrity, security, and functionality; (3) to comply with applicable law or
                a legal obligation or to exercise rights under the law; or (4) to otherwise use your
                Personal Information, internally, in a lawful manner that is compatible with the
                context in which you provided the information.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Opt-Out</strong>
              </p>
            </td>
            <td>
              <p>
                <em>
                  As noted above, we do not use Personal Information to market or advertise directly
                  to Students/Examinees and do not otherwise sell Personal Information.
                </em>
              </p>
              <p>
                <br />
                Nonetheless, we wish to inform you of your general right to opt-out of certain
                disclosures of Personal Information to third parties if such disclosures constitute
                a “sale” under California law. You may opt-out of interest-based advertising by
                visiting the Network Advertising Initiative’s{' '}
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://optout.networkadvertising.org/?c=1#!%2F"
                >
                  Opt-out
                </a>{' '}
                page or{' '}
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://youradchoices.com/control"
                >
                  YourAdChoices
                </a>{' '}
                , provided by the Digital Advertising Alliance.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        Additionally, you may be able to exercise the following supplemental rights under California
        law upon verification of your identity:
        <ul>
          <li>
            right to request that Riverside correct inaccurate Personal Information about you,{' '}
          </li>
          <li>right to restrict how we use your Personal Information, </li>
          <li>right to object to or limit the processing of your Personal Information, and</li>
          <li>
            right to request that we not reach decisions affecting you using automated processing or
            profiling.
          </li>
        </ul>
      </p>
      <p>
        If you would like to exercise your rights listed above, please send (or have your authorized
        agent send) an email to{' '}
        <a target="_blank" rel="noreferrer" href="mailto:inquiry@service.riversideinsights.com">
          inquiry@service.riversideinsights.com
        </a>{' '}
        or call us toll-free at (800) 323-9540 (in the US) or (630) 467-7000 (outside the US). We
        will not use discriminatory practices against you for exercising your California privacy
        rights.
      </p>
      <p>
        While we take measures to ensure that those responsible for receiving and responding to your
        request are informed of your rights and how to help you exercise those rights, when
        contacting us to exercise your rights, we ask you to please adhere to the following
        guidelines:
      </p>
      <ul>
        <li>
          <strong>
            <em>Tell Us Which Right You Are Exercising</em>
          </strong>
          : Specify which right you want to exercise and the Personal Information to which your
          request relates (if not to you). If you are acting on behalf of a Customer, please clearly
          indicate this fact and your authority to act on such Customer’s behalf.
        </li>
        <li>
          <strong>
            <em>Help Us Verify Your Identity</em>
          </strong>
          : Provide us with information to verify your identity. For example, we may ask you to
          provide (at a minimum) your name, phone number, or email address. If your phone number or
          email address appears in our records, we may contact you to help confirm that you are
          making the request. Please note that if we cannot initially verify your identity, we may
          request additional information to complete the verification process. Any Personal
          Information you disclose to us for purposes of verifying your identity will solely be used
          for the purpose of verification.
        </li>
        <li>
          <strong>
            <em>Direct Our Response Delivery</em>
          </strong>
          : Please provide us with an email or mailing address through which we can provide our
          response. If you make the request by email, unless otherwise requested, we will assume
          that we can respond to the email address from which you made the request.
        </li>
      </ul>
      <h3 id="privacy_gdpr">
        <span>Your Rights Under the EU GDPR and the UK GDPR</span>
      </h3>
      <p>
        This GDPR section applies to individuals who are in the European Union (“EU”) and the United
        Kingdom (“UK”). For the purposes of this Policy, references to the GDPR include both the EU
        GDPR and the UK GDPR, and references to the EU also include Switzerland, and the European
        Economic Area countries of Iceland, Liechtenstein, and Norway.
      </p>
      <p>
        For this GDPR section, we use the terms “Personal Data” and “processing” as they are defined
        in the GDPR. “Personal Data” generally means information that relates to an identified or
        identifiable person, and “processing” generally covers actions that can be performed in
        connection with data such as collection, use, storage, and disclosure.
      </p>
      <p>
        If you have any questions about this section or whether any of the following applies to you,
        please contact us at{' '}
        <a target="_blank" rel="noreferrer" href="mailto:inquiry@service.riversideinsights.com">
          inquiry@service.riversideinsights.com
        </a>{' '}
        or call us toll-free at (800) 323-9540 (in the US) or (630) 467-7000 (outside the US).
      </p>
      <h5>What Personal Data do we collect from you?</h5>
      <p>
        Please see the section{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://info.riversideinsights.com/privacy-assessment_policy#information-types"
        >
          Types of Personal Information We Collect and Use
        </a>{' '}
        above for details about the Personal Data we collect.
      </p>
      <h5>How do we use your Personal Data?</h5>
      <p>
        Please refer to the section{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://info.riversideinsights.com/privacy-assessment_policy#how-use"
        >
          How We Use Personal Information
        </a>{' '}
        above for details about how we use and process your Personal Data.
      </p>
      <h5>Lawful Basis for Processing.</h5>
      <p>
        We will only process your Personal Data if we have a lawful basis for doing so. Lawful bases
        for processing include consent, contractual necessity, and our “legitimate interests,” as
        further described below:
      </p>
      <ul>
        <li>
          <em>Contractual Necessity</em>. We may have a contractual necessity to process your
          Personal Data when you use our Services. When we process Personal Data due to contractual
          necessity, failure to provide such Personal Data will result in your inability to use some
          or all portions of the Services that require the data.
        </li>
        <li>
          <em>Legitimate Interests</em>. We may also process your Personal Data in connection with
          our legitimate business interests, for example:
          <ul>
            <li>Operating and improving our business and Services;</li>
            <li>Providing customer support;</li>
            <li>Protecting against fraud or security threats;</li>
            <li>Protecting the security of your account with us;</li>
            <li>Providing you with a sign-in method;</li>
            <li>Complying with our legal obligations; and</li>
            <li>Completing corporate transactions.</li>
          </ul>
        </li>
        <li>
          <em>Consent</em>. In some cases, we may process Personal Data based on the your consent
          you expressly grant to us at the time we collect such Personal Data. When we process
          Personal Data based on your consent, it will be expressly indicated towe will notify you
          at the point and time of collection.
        </li>
      </ul>
      <h5>How and with whom do we share your Personal Data?</h5>
      <p>
        We share Personal Data with service providers; organizations involved in mergers and
        acquisitions transactions; and law enforcement, government agencies, and courts. Please
        refer to the section{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://info.riversideinsights.com/privacy-assessment_policy#disclosure"
        >
          Disclosure of Personal Information
        </a>{' '}
        above.
      </p>
      <h5>How long do we retain your Personal Data?</h5>
      <p>
        We retain Personal Data of users of our Services: (1) for as long as reasonably necessary to
        permit use of our Services and (2) as required by law or contractual commitment. After this
        period has expired, we will return or delete the Personal Data from our systems according to
        your written instruction; provided, we will maintain Personal Data in accordance with our
        backup or other disaster recovery policies and procedures. These deletion periods apply to
        Personal Data and do not apply to de-identified information. We retain de-identified
        information in accordance with our standard practices for similar information.
      </p>
      <p>
        In addition, and subject to any data retention required under applicable law, if requested
        and as directed by a user of our Site, we will delete a user’s Personal Data collected via
        our Services. Deleting this information may limit some or all features of our Services.
        Where required by local law, we will delete such information and provide a certification of
        such deletion.
      </p>
      <h5>What security measures do we use?</h5>
      <p>
        Please refer to the section{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://info.riversideinsights.com/privacy-assessment_policy#how-protect"
        >
          How We Protect Personal Information
        </a>{' '}
        above for more information on the security measures we use to protect your Personal Data.
      </p>
      <h5>What rights do you have regarding your Personal Data?</h5>
      <p>
        You may have certain rights with respect to your Personal Data, including those set forth
        below. For more information about these rights or to submit a request, please email{' '}
        <a target="_blank" rel="noreferrer" href="mailto:inquiry@service.riversideinsights.com">
          inquiry@service.riversideinsights.com
        </a>{' '}
        or call us toll-free at (800) 323-9540 (in the US) or (630) 467-7000 (outside the US).
        Please note that in some circumstances, we may not be able to fully comply with your
        request, such as if it is impractical, if it jeopardizes the rights of others, or if it is
        not required by law. In those circumstances, we will still respond to notify you of such a
        decision. In some cases, we may also need you to provide us with additional information,
        which may include Personal Data, if necessary, to verify your identity and the nature of
        your request.
      </p>
      <p>Your rights under the GDPR include:</p>
      <p>
        <em>Access</em>. You can request more information about the Personal Data we hold about you
        and request a copy of your Personal Data.
      </p>
      <p>
        <em>Rectification</em>. If you believe that any Personal Data we process about you is
        incorrect or incomplete, you can request that we correct or supplement such data.
      </p>
      <p>
        <em>Erasure</em>. You can request that we erase some or all of your Personal Data from our
        systems.
      </p>
      <p>
        <em>Withdrawal of Consent</em>. If we are processing your Personal Data based on your
        consent (as indicated at the time of collection of such Personal Data), you have the right
        to withdraw your consent at any time. Please note, however, that if you exercise this right,
        you may have to provide express consent on a case-by-case basis for the use or disclosure of
        certain Personal Data when such use or disclosure is necessary to enable you to use some or
        all features of a Site.
      </p>
      <p>
        <em>Portability</em>. You can ask for a copy of your Personal Data in a machine-readable
        format. You can also request that we transmit the Personal Data to another entity where
        technically feasible.
      </p>
      <p>
        <em>Objection</em>. You can contact us to let us know that you object to the further use or
        disclosure of your Personal Data for certain purposes.
      </p>
      <p>
        <em>Restriction of Processing</em>. You can ask us to restrict further processing of your
        Personal Data.
      </p>
      <p>
        <em>Right to File Complaint</em>. You have the right to lodge a complaint about Riverside’s
        practices with respect to your Personal Data with the supervisory authority of your country
        or EU Member State.
      </p>
      <h3 id="privacy_parentsrights">
        <span>NY Education Law 2-d</span>
      </h3>
      <p>
        New York’s Education Law 2-d and the New York Parents’ Bill of Rights for Data Privacy and
        Security (collectively, “Ed Law 2-d”) addresses the relationship between schools and their
        third-party contractors, in addition to the schools’ relationships with parents/guardians.
        The only elements of Ed Law 2-d that are incorporated herein are those provisions directed
        to third-party contractors (“Contractor Ed Law 2-d Provisions”). We agree to comply with the
        Contractor Ed Law 2-d Provisions for schools in the State of New York. If there is a direct
        conflict between this Privacy Policy and the Contractor Ed Law 2-d Provisions, the
        Contractor Ed Law 2-d Provisions will control. The full text of Ed Law 2-d is available at
        the New York State Education Department website (as of the date of this publication:{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.nysenate.gov/legislation/laws/EDN/2-D"
        >
          https://www.nysenate.gov/legislation/laws/EDN/2-D
        </a>
        ).
      </p>
      <h3 id="SOPPA">
        <span>Illinois Student Online Personal Protection Act (“SOPPA”)</span>
      </h3>
      <p>
        The Illinois Student Online Personal Protection Act (“SOPPA”) requires school districts to
        take certain precautions with respect to the student data collected by educational
        technology companies. SOPPA applies to all Illinois school districts, the Illinois State
        Board of Education, and operators of online services and applications. The only elements of
        SOPPA that are incorporated herein are those provisions directed to third-party contractors
        (“Contractor SOPPA Provisions”). We agree to comply with the Contractor SOPPA Provisions for
        schools in Illinois. If there is a direct conflict between this Privacy Policy and the
        Contractor SOPPA Provisions, the Contractor SOPPA Provisions will control. As of the date of
        this publication: the full text of SOPPA is available at the following website:{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.ilga.gov/legislation/ilcs/ilcs3.asp?ActID=3806&ChapterID=17"
        >
          https://www.ilga.gov/legislation/ilcs/ilcs3.asp?ActID=3806&ChapterID=17
        </a>
        .
      </p>
      <h3 id="privacy_other">
        <span>Other Websites and Services</span>
      </h3>
      <p>
        Our Services may direct you to or integrate with third-party services or websites. As noted
        above in the subsection <a href="#our_service_providers">Our Service Providers</a>, we
        exercise commercially reasonable efforts to partner with service providers whose privacy
        policies governing their processing of Personal Information align with this Policy. We
        understand that you may want to examine and inquire about our service providers’ privacy
        practices, and we encourage you to do so.
      </p>
      <h3 id="privacy_deintentified">
        <span>De-Identified Information</span>
      </h3>
      <p>
        The specific definition of “de-identified information” applicable to you depends on the laws
        applicable to your data. In general, however, de-identified information is information from
        which all personal identifiers have been removed or obscured such that it does not identify
        an individual and there is no reasonable basis to believe that the information can identify
        an individual.
      </p>
      <p>
        Riverside collects and uses aggregated, de-identified information to assess the quality of
        and improve our Services and for purposes of assessment development, research, and
        publications relevant to our services and industry. As part of our assessment development
        efforts, we may share aggregated, de-identified information with reputable third-party
        development partners, who are considered experts in the field of assessments and subject to
        strict obligations of security and confidentiality with respect to information they receive
        from us. These development partners only use the de-identified information we share with
        them for analysis on our behalf and for purposes permitted under this Policy.
      </p>
      <p>
        Finally, while assessments are in progress, we use de-identified information in order to
        authenticate a user’s identity, maintain links between Students/Examinees and their
        respective proctors during assessment sessions, and update certain features of our Services.
      </p>
      <h3 id="privacy_changes">
        <span>Changes to Our Privacy Policy</span>
      </h3>
      <p>
        We reserve the right to update this Policy at any time. We will post the revised Policy on
        our main Site (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.riversideinsights.com/support/policies"
        >
          https://www.riversideinsights.com/support/policies
        </a>
        ), and such changes will be effective immediately unless otherwise stated. If these changes
        are material, we will provide notice to you through email notifications and/or prominent
        statements on our website and, where required by applicable law, we will obtain your
        consent.
      </p>
      <h3 id="privacy_personalinfotransfer">
        <span>Personal Information Transferred from the U.S.</span>
      </h3>
      <p>
        If you are located outside of the United States, please be aware that information we
        collect, including Personal Information, may be transferred to, and processed, stored, and
        used in the United States. The data protection laws in the United States may differ from
        those of the country in which you are located.
      </p>
      <h3 id="privacy_dnt">
        <span>Do Not Track</span>
      </h3>
      <p>
        Your browser may offer you a “Do Not Track” option, which allows you to signal to operators
        of websites, web applications, and services (including behavioral advertising services) that
        you do not wish such operators to track certain of your online activities over time and
        across different websites. Our Services currently do not support Do Not Track requests.
      </p>
      <h3 id="privacy_contact">
        <span>How to Contact Us</span>
      </h3>
      <p>
        If you have any questions about this Policy, please email us at
        contracts@riversideinsights.com or call us toll-free at (800) 323-9540 (in the US) or (630)
        467-7000 (outside the US).
      </p>
    </div>
  );
};

export default PrivacyPolicy;
