import {
  // useMemo,
  useState,
  useEffect,
} from 'react';

import {
  useSelector,
  // useDispatch,
} from 'react-redux';

import {
  // AppNotification,
  AppState,
  AppStatus,
} from '../reducers/appDataTypes';

const useAppStatus = (): AppStatus => {
  const storeStatus = useSelector((state: AppState) => {
    return state.appStatus;
  });

  const [appStatus, setAppStatus] = useState<AppStatus>(storeStatus);

  useEffect(() => {
    setAppStatus(storeStatus);
  }, [storeStatus]);

  return appStatus;
};

export default useAppStatus;
