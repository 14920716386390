import React, { useState } from 'react';

import { Button } from '@mui/material';
import AppApiDebugRequestModal from './AppApiDebugRequestModal';
import AppDebugApi from './AppDebugApi';
import { TbExternalLink } from 'react-icons/tb';
import { FaTimes } from 'react-icons/fa';
import useCachedUser from '../../../../hooks/useCachedUser';

const AppApiDebugSection: React.FC = () => {
  const user = useCachedUser();
  const [apiRequestModalVisible, setApiRequestModalVisible] = useState(false);

  const hideApiRequestModal = () => {
    setApiRequestModalVisible(false);
  };

  const toggleApiRequestModalVisible = () => {
    setApiRequestModalVisible(!apiRequestModalVisible);
  };
  if (!user.loggedIn) {
    return (
      <div className="app-debug-api-section-root">
        <p className="app-debug-section-unavailable">
          API debug requires valid staff user session.
        </p>
      </div>
    );
  }
  return (
    <div className="app-debug-api-section-root">
      <AppApiDebugRequestModal
        modalVisible={apiRequestModalVisible}
        hideModal={hideApiRequestModal}
      />
      {apiRequestModalVisible && (
        <div className="app-api-debug-row-modal-button">
          <Button
            onClick={toggleApiRequestModalVisible}
            variant="outlined"
            size="small"
            color="secondary"
            endIcon={<FaTimes />}
          >
            Close modal
          </Button>
        </div>
      )}
      {!apiRequestModalVisible && (
        <React.Fragment>
          <div className="app-api-debug-row-modal-button">
            <Button
              onClick={toggleApiRequestModalVisible}
              variant="outlined"
              size="small"
              color="primary"
              endIcon={<TbExternalLink />}
            >
              Open in modal dialog
            </Button>
          </div>
          <AppDebugApi />
        </React.Fragment>
      )}
    </div>
  );
};

export default AppApiDebugSection;
